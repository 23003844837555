import { SignUpStatus } from '../interfaces/SignUpStatus';

export const getSignUpStatusText = (signUpStatus: SignUpStatus): string => {
  switch (signUpStatus) {
    case SignUpStatus.COMPLETE:
      return 'Completed';
    case SignUpStatus.CURRENTLY_DUE:
      return 'Currently Due';
    case SignUpStatus.FAILURE:
      return 'Failed';
    case SignUpStatus.IN_PROGRESS:
      return 'In Progress';
    case SignUpStatus.NOT_STARTED:
      return 'Not Started';
    default:
      return 'Unknown';
  }
};
