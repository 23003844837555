export interface EngagedDistancePayBreakdown {
  locationName: string;
  engagedMiles: number;
  engagedDistanceRate: number;
  amount: number;
}

export const engagedDistancePayBreakdownFromResponse = (
  responseData: any,
): EngagedDistancePayBreakdown => ({
  locationName: responseData.location_name ?? '',
  engagedMiles: responseData.engaged_miles ?? 0,
  engagedDistanceRate: responseData.engaged_distance_rate ?? 0,
  amount: responseData.amount ?? 0,
});
