import React, { useState } from 'react';
import { parseInt } from 'lodash';
import { TextValidator } from 'react-material-ui-form-validator';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  isIntegerErrorMessage,
  isPositiveErrorMessage,
  max2DecimalErrorMessage,
  max2DecimalsRegexp,
  requiredErrorMessage,
} from '../../../../utilities/Constants';
import { isNumber } from '../../../../utilities/Misc';
import { sharedColors } from '../../../../utilities/Styles';

interface MissionNumberInputProps {
  value: number;
  onChange: (newValue: number) => void;
  label: string;
  placeholder?: string;
  minimumValue?: number;
  nonZero?: boolean;
  disabled?: boolean;
  required?: boolean;
  acceptFloat?: boolean;
  endAdornment?: JSX.Element;
}

const MissionNumberInput = (props: MissionNumberInputProps) => {
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = ['isPositive'];
  const errorMessages = [isPositiveErrorMessage];

  if (props.acceptFloat) {
    validators.push(`matchRegexp:${max2DecimalsRegexp}`);
    errorMessages.push(max2DecimalErrorMessage);
  } else {
    validators.push('isNumber');
    errorMessages.push(isIntegerErrorMessage);
  }

  if (props.minimumValue !== undefined) {
    validators.push(`minNumber:${props.minimumValue}`);
    errorMessages.push(`Minimum value: ${props.minimumValue}`);
  }

  if (props.required) {
    validators.push('required');
    errorMessages.push(requiredErrorMessage);
  }

  if (props.nonZero) {
    validators.push('nonZero');
    errorMessages.push(isPositiveErrorMessage);
  }

  const handleChange = (e: any) => {
    props.onChange(
      props.acceptFloat
        ? parseFloat(e.target.value)
        : parseInt(e.target.value, 10),
    );
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (props.disabled) {
      return '';
    }
    if (isFocused) {
      return plainTextValue;
    }
    if (isNumber(props.value) && props.value > 0) {
      return props.acceptFloat
        ? props.value.toFixed(2)
        : props.value.toString();
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextValidator
      label={props.placeholder ?? props.label}
      variant='outlined'
      type='number'
      size='small'
      InputProps={{
        endAdornment: props.endAdornment,
      }}
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      validators={validators}
      errorMessages={isFocused ? errorMessages : validators.map((_) => '')}
      name={props.label}
      disabled={props.disabled}
      className={classes.textField}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      display: 'flex',
      flexGrow: 1,
      backgroundColor: sharedColors.white,
    },
  }),
);

export default MissionNumberInput;
