import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useRecoilValue } from 'recoil';
import React from 'react';
import {
  GenericPayRate,
  GenericPayRateOverviewProps,
} from '../../../interfaces/PayRate';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { locations as locationsAtom } from '../../../atoms/Location';
import {
  filterLocationById,
  getCurrencySymbolFromLocation,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import { multiClass } from '../../../utilities/Extensions';
import { formatDateTime } from '../../../utilities/Dates';

export const GenericPayRateOverview = (props: GenericPayRateOverviewProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const genericPayRate = props.payRate as GenericPayRate;

  const locationId = parseInt(genericPayRate.locationID, 10);
  const location = filterLocationById(locations, locationId);
  const timeZone =
    getTimeZoneByLocationId(
      locations,
      parseInt(genericPayRate.locationID, 10),
    ) || 'UTC';

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
            component={'div'}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1} component={'div'}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            component={'div'}
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start At
          </Typography>
          <Typography component='div' className={sharedClasses.body1}>
            {formatDateTime(genericPayRate.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='amount' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            component={'div'}
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Amount
          </Typography>
          <Typography
            component={'div'}
            className={multiClass([
              sharedClasses.body1,
              classes.fieldValueWithUnit,
            ])}
          >
            {genericPayRate.amount &&
              `${getCurrencySymbolFromLocation(
                location,
              )}${genericPayRate.amount.toFixed(2)}`}
            {props.amountUnit && (
              <Box
                component='div'
                className={multiClass([sharedClasses.body2, classes.fieldUnit])}
              >
                {props.amountUnit}
              </Box>
            )}{' '}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    fieldValueWithUnit: {
      display: 'flex',
      flexDirection: 'row',
    },
    fieldUnit: {
      color: sharedColors.gray5,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
