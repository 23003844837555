import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import UploadCSV from '../UploadCSV';
import { downloadCsvTemplate } from '../../utilities/Csv';
import { settingsCsvTemplate } from '../../variables/CsvTemplate';
import { TimeRatePipelineSettings } from '../../interfaces/Settings/TimeRatePipelineSettings';
import { parsePipelineSettingsFromCsv } from '../../utilities/Settings/TimeRatePipelineSettings';
import { uploadTimeRatePipelineSettings } from '../../services/settings';
import Waiting from '../Waiting';

interface UploadSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: () => void;
}

const UploadSettingsDialog = (props: UploadSettingsDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [file, setFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [parsedSettings, setParsedSettings] = useState<
    TimeRatePipelineSettings[]
  >([]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = (e.target?.result ?? '') as string;
        const csvRows = text
          .split('\n')
          .slice(1)
          .filter((line) => line.trim().length > 0)
          .map((line) => line.split(',').map((cell) => cell.trim()));

        try {
          setParsedSettings(parsePipelineSettingsFromCsv(csvRows));
        } catch (err: any) {
          toast.error(err?.message ?? 'Could not read the input file');
          setParsedSettings([]);
        }
      };
      reader.onerror = () => toast.error('Could not read the input file');

      reader.readAsText(file);
    } else {
      setParsedSettings([]);
    }
  });

  const handleUpload = () => {
    if (isEmpty(parsedSettings)) {
      return;
    }
    setLoading(true);
    uploadTimeRatePipelineSettings(parsedSettings)
      .then(() => {
        toast.success('Successfully uploaded settings');
        props.onUpload();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Waiting open={loading} />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Upload Settings
      </DialogTitle>
      <DialogContent>
        <UploadCSV setParentFile={setFile} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box component='div' className={classes.dialogActionsSpacer}>
          <Button
            onClick={() => downloadCsvTemplate(settingsCsvTemplate)}
            className={sharedClasses.buttonText}
          >
            Download CSV Template for Settings
          </Button>
        </Box>
        <Button
          variant='contained'
          color='inherit'
          disabled={loading}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          disabled={loading || isEmpty(parsedSettings)}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  dialogActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
  },
}));

export default UploadSettingsDialog;
