import { SortableTableEntry } from './Common';

export interface AdjustmentFilter {
  requireTotalCount?: boolean;
  driverId?: string;
  status?: AdjustmentStatus;
  adjustmentType?: string;
  createdBy?: string;
  workweekStart?: Date;
  workweekEnd?: Date;
}

export interface AdjustmentParams {
  adjustmentSubtypeId: string;
  driverId: string;
  workweek: string;
  amount: number;
  currency: string;
  notes?: string;
  textInput?: string;
  dateInput?: Date;
  caseLink?: string;
}

export interface AdjustmentResponseWrapper {
  totalCount: number;
  adjustments: AdjustmentEntity[];
}

export interface AdjustmentEntity extends SortableTableEntry {
  id: string;
  driverId: string;
  driverEmail: string;
  amount: number;
  workweek: string;
  notes?: string;
  textInput?: string;
  dateInput?: Date;
  currency: string;
  createdAt: Date;
  createdBy: string;
  adjustmentType: string;
  adjustmentSubtype: string;
  adjustmentSubtypeId: string;
  caseLink?: string;
  status: AdjustmentStatus;
  managerNotes?: string;
  approvalStatusSetAt?: Date;
  timePaidOut?: Date;
}

export enum AdjustmentStatus {
  PaidOut = 'PAID OUT',
  Pending = 'PENDING',
  PendingApproval = 'PENDING APPROVAL',
  Denied = 'DENIED',
  Cancelled = 'CANCELLED',
  Unknown = 'UNKNOWN',
}

export enum AdjustmentApprovalStatus {
  Approved = 'approved',
  Denied = 'denied',
}

export interface AdjustmentApprovalParams {
  adjustmentId: string;
  status: AdjustmentApprovalStatus;
  managerNotes?: string;
}
