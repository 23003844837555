import { OrderBreakdown, orderBreakdownFromResponse } from './OrderBreakdown';
import { TripBreakdown, tripBreakdownFromResponse } from './TripBreakdown';
import {
  WaitPayBreakdown,
  waitPayBreakdownFromResponse,
} from './WaitPayBreakdown';
import {
  HolidayPayBreakdown,
  holidayPayBreakdownFromResponse,
} from './HolidayPayBreakdown';

export interface DailyPayoutBreakdown {
  hours: number;
  totalAmount: number;
  orderCount: number;
  scheduledOrderCount: number;
  unscheduledOrderCount: number;
  basePay: number;
  boostPay: number;
  doXGetY: number;
  hazardPay: number;
  tier2DeliveryPay: number;
  tobaccoPay: number;
  returnCocPay: number;
  seattleEngagedPay?: number;
  tips: number;
  totalWaitPay: number;
  waitPayBreakdowns: WaitPayBreakdown[];
  orderBreakdowns: OrderBreakdown[];
  tripBreakdowns: TripBreakdown[];
  holidayPayBreakdown: HolidayPayBreakdown;
}

export const dailyPayoutBreakdownFromResponse = (
  responseData: any,
): DailyPayoutBreakdown => ({
  hours: responseData.hours ?? 0,
  totalAmount: responseData.total_amount ?? 0,
  orderCount: responseData.order_count ?? 0,
  scheduledOrderCount: responseData.scheduled_order_count ?? 0,
  unscheduledOrderCount: responseData.unscheduled_order_count ?? 0,
  basePay: responseData.base_pay ?? 0,
  boostPay: responseData.boost_pay ?? 0,
  doXGetY: responseData.do_x_get_y ?? 0,
  hazardPay: responseData.hazard_pay ?? 0,
  tier2DeliveryPay: responseData.tier2_delivery_pay ?? 0,
  tobaccoPay: responseData.tobacco_pay ?? 0,
  returnCocPay: responseData.return_coc_pay ?? 0,
  seattleEngagedPay: responseData.seattle_engaged_pay,
  tips: responseData.tips ?? 0,
  totalWaitPay: responseData.total_wait_pay ?? 0,
  waitPayBreakdowns: responseData.wait_pay_breakdowns.map((breakdown: any) =>
    waitPayBreakdownFromResponse(breakdown),
  ),
  orderBreakdowns: responseData.order_breakdowns.map((breakdown: any) =>
    orderBreakdownFromResponse(breakdown),
  ),
  tripBreakdowns: responseData.trip_breakdowns.map((breakdown: any) =>
    tripBreakdownFromResponse(breakdown),
  ),
  holidayPayBreakdown:
    responseData.holiday_pay_breakdown &&
    holidayPayBreakdownFromResponse(responseData.holiday_pay_breakdown),
});
