import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { NumberRange } from '../../../interfaces/NumberRange';
import NumberRangeInput from './NumberRangeInput';
import { multiClass } from '../../../utilities/Extensions';

interface StratificationByDriverHoursInputProps {
  driverHours: NumberRange[];
  onChange: (newDriverHours: NumberRange[]) => void;
}

const StratificationByDriverHoursInput = (
  props: StratificationByDriverHoursInputProps,
) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const handleDeleteDriverHours = (index: number) => {
    props.onChange([
      ...props.driverHours.slice(0, index),
      ...props.driverHours.slice(index + 1),
    ]);
  };

  const handleAddDriverHours = () => {
    if (props.driverHours.length === 0) {
      props.onChange([{ min: 0, max: 10 }]);
    } else {
      const lastItem = props.driverHours[props.driverHours.length - 1];
      const newItem = {
        min: lastItem.max,
        max: Math.min(lastItem.max + (lastItem.max - lastItem.min), 100),
      };
      props.onChange([...props.driverHours, newItem]);
    }
  };

  const handleChangeDriverHours = (
    newDriverHours: NumberRange,
    index: number,
  ) => {
    props.onChange([
      ...props.driverHours.slice(0, index),
      newDriverHours,
      ...props.driverHours.slice(index + 1),
    ]);
  };

  return (
    <Box component='div'>
      {props.driverHours.map((driverHours, i) => (
        <NumberRangeInput
          numberRange={driverHours}
          onChange={(newDriverHours) =>
            handleChangeDriverHours(newDriverHours, i)
          }
          onDelete={() => handleDeleteDriverHours(i)}
        />
      ))}
      <Box component='div' className={classes.body}>
        <Button
          onClick={handleAddDriverHours}
          className={multiClass([sharedClasses.buttonText, classes.button])}
        >
          + Add Driver Hours Range
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingBottom: theme.spacing(1),
      backgroundColor: sharedColors.gray1,
    },
    button: {
      marginLeft: theme.spacing(5),
    },
  }),
);

export default StratificationByDriverHoursInput;
