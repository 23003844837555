import { makeStyles } from 'tss-react/mui';
import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { TripBreakdown } from '../../interfaces/PayHistory/TripBreakdown';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { toTwoDecimalsMax } from '../../utilities/Misc';
import { parseCentsAsCurrencyString } from '../../utilities/Currency';
import { formatDeliveryTime } from '../../utilities/Dates';

interface TripBreakdownCardProps {
  timezone: string;
  tripBreakdown: TripBreakdown;
  currency: string;
  roundedTopBorder: boolean;
  onHover: () => void;
}

const TripBreakdownCard = (props: TripBreakdownCardProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' onMouseEnter={props.onHover}>
      <Box
        component='div'
        className={multiClass([
          classes.cardHeader,
          props.roundedTopBorder && classes.topBorderRadius,
        ])}
      >
        <Box
          component='div'
          className={multiClass([classes.column, classes.verticalCenter])}
        >
          <Typography className={sharedClasses.subtitle1}>
            {`${props.tripBreakdown.orderBreakdowns.length.toString()} Delivery Trip`}
          </Typography>
          <Typography
            className={multiClass([sharedClasses.body2, classes.subtitle])}
          >
            {`${toTwoDecimalsMax(
              props.tripBreakdown.tripTravelMetrics
                .estimatedTravelTimeInMinutes,
            )} minutes (est.)`}
          </Typography>
        </Box>
        <Box component='div' className={classes.horizontalSpacer} />
        <Typography
          className={multiClass([sharedClasses.h6, classes.headerTotalAmount])}
        >
          {parseCentsAsCurrencyString(
            props.tripBreakdown.totalAmount,
            props.currency,
          )}
        </Typography>
      </Box>
      <Box component='div' className={classes.cardContent}>
        {props.tripBreakdown.orderBreakdowns.map((orderBreakdown) => (
          <Box component='div' className={classes.orderContainer}>
            <Box component='div' className={classes.column}>
              <Typography className={sharedClasses.body1}>
                {`${
                  orderBreakdown.isCancelled ? 'Cancelled' : 'Delivered'
                } at ${
                  orderBreakdown.endAt
                    ? formatDeliveryTime(orderBreakdown.endAt, props.timezone)
                    : '-'
                }`}
              </Typography>
              <Typography
                className={multiClass([
                  classes.subtitle,
                  sharedClasses.caption,
                ])}
              >{`Order ${orderBreakdown.orderID}`}</Typography>
            </Box>
            <Box component='div' className={classes.horizontalSpacer} />
            <Box
              component='div'
              className={multiClass([classes.column, classes.rightAlign])}
            >
              {orderBreakdown.basePay > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Base Pay: ${parseCentsAsCurrencyString(
                    orderBreakdown.basePay,
                    props.currency,
                  )}`}
                </Typography>
              )}
              {orderBreakdown.boostPay > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Boost Pay: ${parseCentsAsCurrencyString(
                    orderBreakdown.boostPay,
                    props.currency,
                  )}`}
                </Typography>
              )}
              {orderBreakdown.hazardPay > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Hazard Pay: ${parseCentsAsCurrencyString(
                    orderBreakdown.hazardPay,
                    props.currency,
                  )}`}
                </Typography>
              )}
              {orderBreakdown.tier2DeliveryPay > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Tier 2 Delivery Pay: ${parseCentsAsCurrencyString(
                    orderBreakdown.tier2DeliveryPay,
                    props.currency,
                  )}`}
                </Typography>
              )}
              {orderBreakdown.tobaccoPay > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Tobacco Pay: ${parseCentsAsCurrencyString(
                    orderBreakdown.tobaccoPay,
                    props.currency,
                  )}`}
                </Typography>
              )}
              {orderBreakdown.returnCocPay > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Return Pay: ${parseCentsAsCurrencyString(
                    orderBreakdown.returnCocPay,
                    props.currency,
                  )}`}
                </Typography>
              )}
              {orderBreakdown.tips > 0 && (
                <Typography className={sharedClasses.body1}>
                  {`Tips: ${parseCentsAsCurrencyString(
                    orderBreakdown.tips,
                    props.currency,
                  )}`}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
        <Divider className={classes.dashedDivider} />
        <Box component='div' className={classes.tripDetailsContainer}>
          <Grid container>
            <Grid item xs={5}>
              <Box component='div' className={classes.column}>
                <Typography
                  className={multiClass([
                    sharedClasses.caption,
                    classes.tripDateTime,
                  ])}
                >
                  Trip Started
                </Typography>
                <Typography className={sharedClasses.body2}>
                  {formatDeliveryTime(
                    props.tripBreakdown.startAt,
                    props.timezone,
                  )}
                </Typography>
                <Box component='div' className={classes.tripDateTimeSpacing} />
                <Typography
                  className={multiClass([
                    sharedClasses.caption,
                    classes.tripDateTime,
                  ])}
                >
                  Trip Completed
                </Typography>
                <Typography className={sharedClasses.body2}>
                  {formatDeliveryTime(
                    props.tripBreakdown.endAt,
                    props.timezone,
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box component='div' className={classes.column}>
                <Box component='div' className={classes.earningsComponentRow}>
                  <Typography className={sharedClasses.body2}>
                    Base Pay
                  </Typography>
                  <Box component='div' className={classes.horizontalSpacer} />
                  <Typography className={sharedClasses.body2}>
                    {parseCentsAsCurrencyString(
                      props.tripBreakdown.basePay,
                      props.currency,
                    )}
                  </Typography>
                </Box>
                {props.tripBreakdown.boostPay > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Boost Pay
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.boostPay,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                {props.tripBreakdown.hazardPay > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Hazard Pay
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.hazardPay,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                {props.tripBreakdown.tier2DeliveryPay > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Tier 2 Delivery Pay
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.tier2DeliveryPay,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                {props.tripBreakdown.tobaccoPay > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Tobacco Pay
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.tobaccoPay,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                {props.tripBreakdown.returnCocPay > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Return Pay
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.returnCocPay,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                {(props.tripBreakdown.seattleEngagedPay ?? 0) > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Engaged Pay
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.seattleEngagedPay!,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                {props.tripBreakdown.tips > 0 && (
                  <Box component='div' className={classes.earningsComponentRow}>
                    <Typography className={sharedClasses.body2}>
                      Tips
                    </Typography>
                    <Box component='div' className={classes.horizontalSpacer} />
                    <Typography className={sharedClasses.body2}>
                      {parseCentsAsCurrencyString(
                        props.tripBreakdown.tips,
                        props.currency,
                      )}
                    </Typography>
                  </Box>
                )}
                <Divider className={classes.horizontalDivider} />
                <Box component='div' className={classes.earningsComponentRow}>
                  <Typography className={sharedClasses.body1}>
                    Trip Earnings
                  </Typography>
                  <Box component='div' className={classes.horizontalSpacer} />
                  <Typography className={sharedClasses.body1}>
                    {parseCentsAsCurrencyString(
                      props.tripBreakdown.totalAmount,
                      props.currency,
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: sharedColors.gray2,
  },
  cardHeader: {
    padding: theme.spacing(1.5),
    backgroundColor: sharedColors.white,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalSpacer: {
    flexGrow: 1,
  },
  column: {
    flexDirection: 'column',
  },
  rightAlign: {
    alignContent: 'end',
    textAlign: 'end',
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  verticalSpacing: {
    paddingTop: theme.spacing(1.75),
  },
  subtitle: {
    color: sharedColors.gray5,
  },
  headerTotalAmount: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  orderContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  tripDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  tripDateTime: {
    color: sharedColors.gray5,
  },
  tripDateTimeSpacing: {
    paddingTop: theme.spacing(1.5),
  },
  earningsComponentRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  horizontalDivider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.25),
    color: sharedColors.gray3,
  },
  dashedDivider: {
    marginBottom: theme.spacing(1.5),
    borderStyle: 'dashed',
  },
  topBorderRadius: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
}));

export default TripBreakdownCard;
