export enum AccrualEvent {
  EARNED = 'earned',
  USED = 'used',
  EXPIRED = 'expired',
}

export interface HolidayAccrualHistory {
  eventType: AccrualEvent;
  eventTime: Date;
  accruedDays: number;
  accruedDaysBefore: number;
  accruedDaysAfter: number;
  accrualWorkday?: string;
  amountPaid?: number;
  currency?: string;
}

export const holidayAccrualHistoryFromResponse = (
  responseData: any,
): HolidayAccrualHistory => ({
  eventType: responseData.event_type,
  eventTime: new Date(responseData.event_time),
  accruedDays: responseData.accrued_days,
  accruedDaysBefore: responseData.accrued_days_before,
  accruedDaysAfter: responseData.accrued_days_after,
  accrualWorkday: responseData.accrual_workday,
  amountPaid: responseData.amount_paid,
  currency: responseData.currency,
});
