import makeStyles from '@mui/styles/makeStyles';
import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useRecoilValue } from 'recoil';
import React from 'react';
import { locations as locationsAtom } from '../../../../atoms/Location';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  GenericPayRate,
  GenericPayRateFormProps,
  GenericPayRateType,
} from '../../../../interfaces/PayRate';
import { generateNumberInput } from '../../../../utilities/PayRates';
import { filterLocationById } from '../../../../utilities/Locations';
import EngagedPayInfoBox from './EngagedPayInfoBox';

export const GenericPayRateForm = (props: GenericPayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const selectedLocationId = parseInt(props.currentState.locationID, 10);
  const selectedLocation = filterLocationById(locations, selectedLocationId);

  const genericPayRate = props.currentState as GenericPayRate;

  return (
    <Box component='div' className={classes.formWrapper}>
      {selectedLocation &&
        props.genericPayType === GenericPayRateType.ENGAGED && (
          <EngagedPayInfoBox selectedLocation={selectedLocation} />
        )}
      <Typography className={sharedClasses.subtitle2}>Set pay rate</Typography>
      <Box component='div' className={classes.row}>
        <Box component='div' className={classes.numberField}>
          {generateNumberInput<GenericPayRate>(
            true,
            true,
            genericPayRate,
            props.setter,
            'amount',
            props.isDisabled,
            'Amount',
            undefined,
            undefined,
            undefined,
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginTop: theme.spacing(1.5),
    },
    numberField: {
      width: 210,
      marginRight: theme.spacing(2),
    },
  }),
);
