import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { MinGuaranteeAdjustmentBreakdown } from '../../../interfaces/PayHistory/MinGuaranteeAdjustmentBreakdown';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import CustomTooltip from './CustomTooltip';
import { multiClass } from '../../../utilities/Extensions';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';

interface MinGuaranteeAdjustmentTooltipProps {
  minGuaranteeAdjustment: MinGuaranteeAdjustmentBreakdown;
  currency: string;
  children: JSX.Element;
}

const MinGuaranteeAdjustmentTooltip = (
  props: MinGuaranteeAdjustmentTooltipProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  let numericCalculation: string = '';
  let symbolicCalculation: string;

  switch (props.minGuaranteeAdjustment.version) {
    case 3: {
      props.minGuaranteeAdjustment.subsidizableHoursWorked.forEach(
        (hour, i) => {
          if (i !== 0) {
            numericCalculation += `+ `;
          }
          numericCalculation += `(${parseCentsAsCurrencyString(
            hour.rate,
            props.currency,
          )} * ${hour.amount.toFixed(2)}) `;
        },
      );
      numericCalculation += `- (${parseCentsAsCurrencyString(
        props.minGuaranteeAdjustment.basePay,
        props.currency,
      )} + ${parseCentsAsCurrencyString(
        props.minGuaranteeAdjustment.boostPay,
        props.currency,
      )}${
        props.minGuaranteeAdjustment.amount === 0 ? ') < ' : ') = '
      }${parseCentsAsCurrencyString(
        props.minGuaranteeAdjustment.amount,
        props.currency,
      )}`;
      symbolicCalculation =
        '(Minimum Guarantee Rate * Scheduled Hours) - (Base Pay + Boost Pay) = Minimum Guarantee Adjustment';
      break;
    }
    case 2: {
      props.minGuaranteeAdjustment.subsidizableHoursWorked.forEach(
        (hour, i) => {
          if (i !== 0) {
            numericCalculation += `+ `;
          }
          numericCalculation += `(${parseCentsAsCurrencyString(
            hour.rate,
            props.currency,
          )} * ${hour.amount.toFixed(2)}) `;
        },
      );

      numericCalculation += `- ${parseCentsAsCurrencyString(
        props.minGuaranteeAdjustment.basePay,
        props.currency,
      )}${
        props.minGuaranteeAdjustment.amount === 0 ? ' < ' : ' = '
      }${parseCentsAsCurrencyString(
        props.minGuaranteeAdjustment.amount,
        props.currency,
      )}`;
      symbolicCalculation =
        '(Minimum Guarantee Rate * Scheduled Hours) - Base Pay = Minimum Guarantee Adjustment';
      break;
    }
    default: {
      numericCalculation = parseCentsAsCurrencyString(
        props.minGuaranteeAdjustment.amount,
        props.currency,
      );
      symbolicCalculation = 'Minimum Guarantee Adjustment';
    }
  }

  return (
    <CustomTooltip
      title={
        <Box className={classes.mainContainer}>
          <Typography
            className={multiClass([
              classes.numericCalculation,
              sharedClasses.subtitle1,
            ])}
          >
            {numericCalculation}
          </Typography>
          <Box component='div' className={classes.divider} />
          <Typography
            className={multiClass([
              classes.symbolicCalculation,
              sharedClasses.caption,
            ])}
          >
            {symbolicCalculation}
          </Typography>
        </Box>
      }
    >
      {props.children}
    </CustomTooltip>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  divider: {
    height: 1,
    display: 'flex',
    backgroundColor: sharedColors.gray6,
    flexGrow: 1,
  },
  numericCalculation: {
    color: sharedColors.white,
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(0.8),
  },
  symbolicCalculation: {
    marginTop: theme.spacing(0.8),
    color: sharedColors.white,
    marginBottom: theme.spacing(1.2),
  },
}));

export default MinGuaranteeAdjustmentTooltip;
