import { MissionSegment, missionSegmentFromResponse } from './MissionSegment';

export interface MissionSegmentCreationResult {
  invitedDriverCount: number;
  scheduledFor?: Date;
}

export const missionSegmentCreationResultFromResponse = (
  responseData: any,
): MissionSegmentCreationResult => ({
  invitedDriverCount: responseData.data
    .map((segment: any) => missionSegmentFromResponse(segment))
    .filter((segment: MissionSegment) => !segment.isControlSegment)
    .reduce(
      (total: number, segment: MissionSegment) => total + segment.stats.invited,
      0,
    ),
  scheduledFor: responseData.scheduled_for
    ? new Date(responseData.scheduled_for)
    : undefined,
});
