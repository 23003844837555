import { isArray } from 'lodash';
import {
  Mission,
  missionFromResponse,
  MissionsList,
  MissionType,
} from '../interfaces/Mission/Mission';
import api from './api';
import { MissionFilters } from '../interfaces/Mission/MissionFilters';
import {
  MissionSegment,
  MissionSegmentationKind,
  missionSegmentFromResponse,
  MissionSegmentToCreate,
} from '../interfaces/Mission/MissionSegment';
import {
  MissionParticipant,
  missionParticipantFromResponse,
} from '../interfaces/Mission/MissionParticipant';
import {
  convertDateByTimeZone,
  parseDate,
  removeTimezone,
} from '../utilities/Dates';
import { defaultMissionSegmentOutputTransformer } from '../utilities/MissionSegment';
import {
  MissionSegmentCreationResult,
  missionSegmentCreationResultFromResponse,
} from '../interfaces/Mission/MissionSegmentCreationResult';

const missionsUrl = '/missions';

export const getMissions = async (
  filters: MissionFilters,
): Promise<MissionsList> => {
  const response = await api.get(missionsUrl, {
    params: {
      'active-after':
        filters.activeAfter == null
          ? null
          : convertDateByTimeZone(
              'UTC',
              parseDate(removeTimezone(filters.activeAfter)),
            ),
      'active-before':
        filters.activeBefore == null
          ? null
          : convertDateByTimeZone(
              'UTC',
              parseDate(removeTimezone(filters.activeBefore)),
            ),
      driver: filters.driverID,
      location: filters.locationIDs,
      metro: filters.metroIDs,
      status: filters.statuses,
      type: filters.types,
      page: filters.pagination.page,
      'page-size': filters.pagination.rowsPerPage,
      'only-allowing-active-dp': filters.onlyAllowingActiveDP,
      'only-experiments': filters.onlyExperiments,
    },
    paramsSerializer: {
      indexes: null,
    },
  });

  const responseData = response?.data;
  if (!isArray(responseData?.data)) {
    throw new Error('Could not get missions: Response data is not an array');
  }

  const totalCount = responseData?.pagination?.total_count;
  if (totalCount === undefined) {
    throw new Error(
      'Could not get total mission count: Pagination is unavailable',
    );
  }

  return {
    missions: responseData.data.map((mission: any) =>
      missionFromResponse(mission),
    ),
    totalCount,
  };
};

export const getMissionByID = async (missionID: number): Promise<Mission> => {
  const response = await api.get(`${missionsUrl}/${missionID.toString()}`);
  const responseData = response.data;
  if (!responseData) {
    throw new Error('Could not get mission by ID: Response data is empty');
  }

  return missionFromResponse(responseData);
};

export const createMission = async (
  mission: Mission,
  missionType: MissionType,
): Promise<number> => {
  const missionToPost = missionType.outputTransformer(mission);

  const response = await api.post(missionsUrl, missionToPost);
  const responseData = response.data;
  if (!responseData) {
    throw new Error('Could not create mission: response data is empty');
  }

  return missionFromResponse(responseData).id;
};

export const updateMission = async (
  mission: Mission,
  missionType: MissionType,
): Promise<void> => {
  const missionToPut = missionType.outputTransformer(mission);

  return api.put(`${missionsUrl}/${mission.id.toString()}`, missionToPut);
};

export const deleteMission = async (missionID: number): Promise<void> => {
  try {
    await api.delete(`${missionsUrl}/${missionID.toString()}`);
  } catch (error: any) {
    throw new Error(`Could not delete mission: ${error.message}`);
  }
};

export const createMissionSegment = async (
  missionID: number,
  segmentToCreate: MissionSegmentToCreate,
  segmentationTypes: MissionSegmentationKind[],
  isSandbox: boolean,
): Promise<MissionSegmentCreationResult> => {
  const segmentToPost = defaultMissionSegmentOutputTransformer(
    segmentToCreate,
    isSandbox,
    segmentationTypes,
  );

  const response = await api.post(
    `${missionsUrl}/${missionID.toString()}/segments`,
    segmentToPost,
  );
  const responseData = response.data;
  if (!responseData) {
    throw new Error('Could not create segment: response data is empty');
  }

  if (!isArray(responseData.data)) {
    throw new Error('Could not create segment: response data is not an array');
  }

  const result = missionSegmentCreationResultFromResponse(responseData);

  if (!result.invitedDriverCount && !result.scheduledFor) {
    throw new Error(
      'Could not create segment: response data is an empty array',
    );
  }

  return result;
};

export const getMissionSegments = async (
  missionID: number,
): Promise<MissionSegment[]> => {
  const response = await api.get(`${missionsUrl}/${missionID}/segments`);
  const responseData = response.data?.data ?? [];

  return responseData.map((segment: any) =>
    missionSegmentFromResponse(segment),
  );
};

export const getMissionParticipants = async (
  missionID: number,
  segmentID?: number,
): Promise<MissionParticipant[]> => {
  const response = await api.get(
    `${missionsUrl}/${missionID.toString()}/participants`,
    { params: { 'segment-id': segmentID } },
  );
  const responseData = response.data?.data ?? [];

  return responseData.map((participant: any) =>
    missionParticipantFromResponse(participant),
  );
};
