import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { sharedColors } from '../../../utilities/Styles';
import { MissionSegmentFormProps } from '../../../interfaces/Mission/MissionSegment';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../../atoms/Location';
import MultiLocationAutocomplete from '../../MultiLocationAutocomplete';
import { multiClass } from '../../../utilities/Extensions';
import MultiMetroAutocomplete from '../../MultiMetroAutocomplete';

const LocationInput = (props: MissionSegmentFormProps) => {
  const classes = useStyles();

  const locations = useRecoilValue(locationsAtom);
  const metros = useRecoilValue(metrosAtom);

  const handleChangeLocations = (newLocationIDs: string[]) => {
    props.setCurrentState({
      ...props.currentState,
      locationIDs: newLocationIDs,
    });
  };

  const handleChangeMetros = (newMetroIDs: string[]) => {
    props.setCurrentState({
      ...props.currentState,
      metroIDs: newMetroIDs,
    });
  };

  const isInvalid =
    isEmpty(props.currentState.locationIDs) &&
    isEmpty(props.currentState.metroIDs);

  return (
    <Box component='div' className={classes.body}>
      <Box
        component='div'
        className={multiClass([classes.dropdown, classes.locationAutocomplete])}
      >
        <MultiLocationAutocomplete
          label='Locations'
          locations={locations.filter(
            (location) =>
              location.countryCode === props.currentState.countryCode,
          )}
          selectedIDs={props.currentState.locationIDs}
          onSetSelectedIDs={handleChangeLocations}
          error={isInvalid}
        />
      </Box>
      <Box component='div' className={classes.dropdown}>
        <MultiMetroAutocomplete
          label='Metros'
          metros={metros.filter(
            (metro) => metro.countryCode === props.currentState.countryCode,
          )}
          selectedIDs={props.currentState.metroIDs}
          onSetSelectedIDs={handleChangeMetros}
          error={isInvalid}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    dropdown: {
      marginLeft: theme.spacing(5),
    },
    locationAutocomplete: {
      marginBottom: theme.spacing(1.5),
    },
  }),
);

export default LocationInput;
