import React, { useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { MinMaxFares } from '../../interfaces/Settings/MinMaxFares';
import {
  isPositiveErrorMessage,
  max2DecimalErrorMessage,
  max2DecimalsRegexp,
  requiredErrorMessage,
} from '../../utilities/Constants';
import { isNumber } from '../../utilities/Misc';

interface MinMaxFaresNumberInputProps {
  currentState: MinMaxFares;
  setter: (newState: MinMaxFares) => void;
  fieldName: keyof MinMaxFares;
  disabled: boolean;
  placeholder: string;
  minValue: number;
  maxValue: number;
}

const MinMaxFaresNumberInput = (props: MinMaxFaresNumberInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = [
    'isPositive',
    `matchRegexp:${max2DecimalsRegexp}`,
    `minNumber:${props.minValue}`,
    `maxNumber:${props.maxValue}`,
    'required',
  ];
  const errorMessages = [
    isPositiveErrorMessage,
    max2DecimalErrorMessage,
    `Minimum value: ${props.minValue}`,
    `Maximum value: ${props.maxValue}`,
    requiredErrorMessage,
  ];

  const handleChange = (e: any) => {
    props.setter({
      ...props.currentState,
      [props.fieldName]: parseFloat(e.target.value),
    });
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (isFocused) {
      return plainTextValue;
    }
    const value = props.currentState[props.fieldName] as unknown as number;
    if (isNumber(value)) {
      return value.toFixed(2);
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextValidator
      label={props.placeholder}
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
      validators={validators}
      errorMessages={errorMessages}
      name={props.placeholder}
      disabled={props.disabled}
    />
  );
};

export default MinMaxFaresNumberInput;
