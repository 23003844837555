import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useState } from 'react';
import { isFinite } from 'lodash';
import { TextValidator } from 'react-material-ui-form-validator';
import { sharedColors } from '../../../../utilities/Styles';
import {
  isIntegerErrorMessage,
  isPositiveErrorMessage,
} from '../../../../utilities/Constants';
import { isNumber } from '../../../../utilities/Misc';

interface MaxDriverCountFieldProps {
  value?: number;
  onChange: (newValue?: number) => void;
  disabled?: boolean;
}

const MaxDriverCountField = (props: MaxDriverCountFieldProps) => {
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = ['isPositive', 'isNumber'];
  const errorMessages = [isPositiveErrorMessage, isIntegerErrorMessage];

  const handleChange = (e: any) => {
    const newValue = parseInt(e.target.value, 10);
    props.onChange(isFinite(newValue) ? newValue : undefined);
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (props.disabled) {
      return '';
    }
    if (isFocused) {
      return plainTextValue;
    }
    if (props.value !== undefined && isNumber(props.value) && props.value > 0) {
      return props.value.toString();
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextValidator
      label='Max DP Count'
      variant='outlined'
      type='number'
      size='small'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      validators={validators}
      errorMessages={isFocused ? errorMessages : validators.map((_) => '')}
      name='Max DP Count'
      disabled={props.disabled}
      className={classes.textField}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      backgroundColor: sharedColors.white,
    },
  }),
);

export default MaxDriverCountField;
