import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from 'material-react-toastify';

import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import {
  BoostPay,
  PayRate,
  PayRateKind,
  PayRateStatus,
  PayRateType,
} from '../../../interfaces/PayRate';
import {
  locations as locationsAtom,
  selectedLocationIDs as selectedLocationIDsAtom,
} from '../../../atoms/Location';
import { payRateToEdit as payRateToEditAtom } from '../../../atoms/PayRate';
import {
  getOverlappingBoostPayRateList,
  getReplacedPayRateByLocationID,
} from '../../../services/payRates';
import { formatSingleDay, isToday } from '../../../utilities/Dates';
import Waiting from '../../Waiting';
import { multiClass } from '../../../utilities/Extensions';
import {
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import { multiPayRateKinds } from '../../../variables/PayRate';
import { multiLocationBoostPayTransformer } from '../../../utilities/PayRates';

type ConfirmPayRateProps = {
  payRate: PayRate;
  selectedRateType: PayRateType;
};

export const confirmPayRateContainerTestId = 'confirmPayRateContainer';
export const confirmPayRateWarningBoxTestId = 'confirmPayRateWarningBox';
export const confirmOverLappingWarningBoxTestId =
  'confirmOverLappingWarningBox';

const ConfirmWarningBox = (props: { messageContent: JSX.Element }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Paper
      elevation={0}
      className={classes.replacementWarningBox}
      data-testid={confirmPayRateWarningBoxTestId}
    >
      <InfoOutlinedIcon fontSize='large' className={classes.infoBoxIcon} />
      <Typography
        className={multiClass([
          sharedClasses.subtitle2,
          classes.replacementWarningText,
        ])}
      >
        {props.messageContent}
      </Typography>
    </Paper>
  );
};

export const ConfirmPayRate = (props: ConfirmPayRateProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [activePayRate, setActivePayRate] = useState<PayRate | null>(null);
  const [loading, setLoading] = useState(false);
  const [overLappingPayRateList, setOverLappingPayRateList] = useState<
    BoostPay[]
  >([]);

  const locations = useRecoilValue(locationsAtom);
  const selectedLocationIDs = useRecoilValue(selectedLocationIDsAtom);
  const payRateToEdit = useRecoilValue(payRateToEditAtom);

  const locationId = parseInt(props.payRate.locationID, 10);
  const timeZone = getTimeZoneByLocationId(locations, locationId) || 'UTC';

  const { selectedRateType } = props;

  const refreshActivePayRate = () => {
    if (!payRateToEdit && !multiPayRateKinds.includes(selectedRateType.kind)) {
      setLoading(true);
      getReplacedPayRateByLocationID(props.payRate.locationID, selectedRateType)
        .then((payRate) => setActivePayRate(payRate))
        .catch((error) => toast.error(error.message))
        .finally(() => setLoading(false));
    } else if (payRateToEdit?.payRate.status === PayRateStatus.ACTIVE) {
      setActivePayRate(payRateToEdit.payRate);
    }
    if (selectedRateType.kind === PayRateKind.BOOST_PAY) {
      setLoading(true);
      const boostPays = multiLocationBoostPayTransformer(
        selectedLocationIDs,
        locations,
        props.payRate as BoostPay,
      );
      getOverlappingBoostPayRateList(
        selectedLocationIDs,
        boostPays,
        selectedRateType,
        payRateToEdit,
      )
        .then((payRateList) => {
          if (payRateToEdit) {
            setOverLappingPayRateList(
              payRateList.filter(
                (payRate) => payRate.id !== payRateToEdit.payRate.id,
              ),
            );
          } else {
            setOverLappingPayRateList(payRateList);
          }
        })
        .catch((error) => toast.error(error.message))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    refreshActivePayRate();
  }, []);

  const isImmediate =
    isToday(props.payRate.startAt) ||
    payRateToEdit?.payRate.status === PayRateStatus.ACTIVE;

  return (
    <Box
      component='div'
      className={classes.contentWrapper}
      data-testid={confirmPayRateContainerTestId}
    >
      <Waiting open={loading} />
      {(activePayRate ||
        payRateToEdit?.payRate.status === PayRateStatus.ACTIVE) &&
        !multiPayRateKinds.includes(selectedRateType.kind) && (
          <ConfirmWarningBox
            messageContent={
              <>
                {isImmediate
                  ? 'Saving a new pay rate will replace the existing pay rate effective the new start date.'
                  : [
                      'Saving this new pay rate will replace the existing pay rate on ',
                      <Box
                        component='div'
                        className={classes.replacementWarningBold}
                      >
                        {formatSingleDay(props.payRate.startAt, timeZone)}
                      </Box>,
                    ]}
              </>
            }
          />
        )}
      {selectedRateType.kind === PayRateKind.BOOST_PAY &&
        !isEmpty(overLappingPayRateList) && (
          <ConfirmWarningBox
            messageContent={
              <Typography data-testid={confirmOverLappingWarningBoxTestId}>
                Warning - there are live boosts at this MFC during this time.
                Please make sure that you want to continue adding this new
                boost:
                {overLappingPayRateList.map((boost) => (
                  <Typography>
                    Location: &nbsp;
                    <Box component='span' fontWeight='fontWeightMedium'>
                      {
                        filterLocationById(
                          locations,
                          parseInt(boost.locationID, 10),
                        )?.name
                      }
                    </Box>
                    &nbsp; Boost Name: &nbsp;
                    <Box component='span' fontWeight='fontWeightMedium'>
                      {boost.name}
                    </Box>
                  </Typography>
                ))}
              </Typography>
            }
          />
        )}
      {selectedRateType.kind === PayRateKind.BOOST_PAY &&
        selectedLocationIDs.length > 1 && (
          <ConfirmWarningBox
            messageContent={
              <>
                You are about to change the pay rate for multiple sites. All pay
                settings below will apply to all sites selected. Are you sure
                you want to make these changes?
              </>
            }
          />
        )}
      {payRateToEdit?.payRate.status === PayRateStatus.FUTURE ? (
        <Box component='div' className={classes.row}>
          <Card
            className={multiClass([
              classes.differenceCard,
              classes.leftDifferenceCard,
            ])}
          >
            <CardHeader
              title={
                <Typography className={sharedClasses.subtitle2}>
                  {`Current Future ${selectedRateType.name}`}
                </Typography>
              }
              className={multiClass([
                classes.cardHeaderContainer,
                classes.futureRateColor,
              ])}
            />
            <Divider />
            <CardContent className={classes.cardContentContainer}>
              {selectedRateType.overViewGenerator(payRateToEdit.payRate)}
            </CardContent>
          </Card>
          <Card className={classes.differenceCard}>
            <CardHeader
              title={
                <Typography className={sharedClasses.subtitle2}>
                  {`New Future ${selectedRateType.name}`}
                </Typography>
              }
              className={multiClass([
                classes.cardHeaderContainer,
                classes.futureRateColor,
              ])}
            />
            <Divider />
            <CardContent className={classes.cardContentContainer}>
              {selectedRateType.overViewGenerator(props.payRate)}
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box component='div' className={classes.row}>
          {activePayRate &&
            !multiPayRateKinds.includes(selectedRateType.kind) && (
              <Card
                className={multiClass([
                  classes.differenceCard,
                  classes.leftDifferenceCard,
                ])}
              >
                <CardHeader
                  title={
                    <Typography className={sharedClasses.subtitle2}>
                      {`Current ${selectedRateType.name}`}
                    </Typography>
                  }
                  className={multiClass([
                    classes.cardHeaderContainer,
                    classes.currentRateColor,
                  ])}
                />
                <Divider />
                <CardContent className={classes.cardContentContainer}>
                  {selectedRateType.overViewGenerator(activePayRate)}
                </CardContent>
              </Card>
            )}
          <Card className={classes.differenceCard}>
            <CardHeader
              title={
                <Typography className={sharedClasses.subtitle2}>
                  {`${isImmediate ? 'New' : 'Future'} ${selectedRateType.name}`}
                </Typography>
              }
              className={multiClass([
                classes.cardHeaderContainer,
                isImmediate
                  ? classes.immediateRateColor
                  : classes.futureRateColor,
              ])}
            />
            <Divider />
            <CardContent className={classes.cardContentContainer}>
              {selectedRateType.overViewGenerator(props.payRate)}
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginTop: theme.spacing(1.5),
    },
    infoBoxIcon: {
      color: sharedColors.blue4,
    },
    replacementWarningBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
      backgroundColor: sharedColors.blue1,
    },
    replacementWarningText: {
      display: 'flex',
      flexDirection: 'row',
      color: sharedColors.blue7,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(0.5),
    },
    replacementWarningBold: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(0.5),
    },
    differenceCard: {
      width: '50%',
    },
    cardHeaderContainer: {
      padding: theme.spacing(1),
    },
    cardContentContainer: {
      padding: theme.spacing(1),
    },
    leftDifferenceCard: {
      marginRight: theme.spacing(2),
    },
    currentRateColor: {
      backgroundColor: sharedColors.gray1,
    },
    immediateRateColor: {
      backgroundColor: sharedColors.blue1,
    },
    futureRateColor: {
      backgroundColor: sharedColors.purple1,
    },
  }),
);
