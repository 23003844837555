export const parseCurrencyStringAsCents = (amount: string): number =>
  Math.ceil(100 * Number(amount.replace(/[^0-9.-]+/g, '')));

const currencyFormatter = (currency: string, precision?: number) => {
  return precision
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })
    : Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      });
};

export const parseCentsAsCurrencyStringAmount = (amount: number): string => {
  const str = parseCentsAsCurrencyString(amount);
  return str.replace('$', '');
};

export const parseCentsAsCurrencyString = (
  amount: number,
  currency?: string,
  precision?: number,
): string => {
  return currencyFormatter(currency?.toUpperCase() ?? 'USD', precision).format(
    amount / (precision ? 10 ** precision : 100),
  );
};
