import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Chip,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  Mission,
  MissionsList,
  MissionType,
} from '../../interfaces/Mission/Mission';
import { TablePaginationOptions } from '../../interfaces/Common';
import { multiClass } from '../../utilities/Extensions';
import { payRateStatusFromTimeRange } from '../../utilities/PayRates';
import { PayRateStatus } from '../../interfaces/PayRate';
import StringField from '../StringField';
import { formatLocalTime } from '../../utilities/Dates';
import StatusField from '../StatusField';
import { missionPageUrl } from '../../variables/Pages';
import { missionTypes } from '../../variables/Mission';
import { getGeographicRequirementValue } from '../../utilities/Mission';

interface MissionTypeChipProps {
  type?: MissionType;
  disabled: boolean;
}

const MissionTypeChip = (props: MissionTypeChipProps) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.typeChip}
      disabled={props.disabled}
      label={
        <Typography className={classes.typeChipText}>
          {props.type?.name ?? 'Unknown'}
        </Typography>
      }
    />
  );
};

interface MissionTableProps {
  missionsList: MissionsList;
  pagination: TablePaginationOptions;
  onPagination: (newPagination: TablePaginationOptions) => void;
}

interface MenuState {
  mouseX?: number;
  mouseY?: number;
  clickedMissionID?: number;
}

const MissionTable = (props: MissionTableProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();

  const [menuState, setMenuState] = useState<MenuState>({});

  const getLocationsMetrosField = (mission: Mission): string => {
    if (
      isEmpty(mission.requirements.locations) &&
      isEmpty(mission.requirements.metros)
    ) {
      return (
        getGeographicRequirementValue([], true, mission.requirements.country) ??
        '-'
      );
    }

    return `${
      getGeographicRequirementValue(
        mission.requirements.locations,
        true,
        mission.requirements.country,
      ) ?? '-'
    } / ${
      getGeographicRequirementValue(
        mission.requirements.metros,
        true,
        mission.requirements.country,
      ) ?? '-'
    }`;
  };

  const handleShowDetails = (missionID: number) => {
    if (navigate) {
      navigate(`${missionPageUrl}/details/${missionID.toString()}`);
    }
  };

  const handleRightClick = (
    e: React.MouseEvent<HTMLTableRowElement>,
    row: Mission,
  ) => {
    e.preventDefault();
    setMenuState({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
      clickedMissionID: row.id,
    });
  };

  const handleCloseMenu = () => {
    setMenuState({});
  };

  const handleOpenInNewTab = () => {
    if (menuState.clickedMissionID === undefined) {
      return;
    }

    window.open(
      `${
        window.location.origin
      }${missionPageUrl}/details/${menuState.clickedMissionID.toString()}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <Box component='div' className={classes.outerContainer}>
      <Typography className={multiClass([sharedClasses.h5, classes.title])}>
        Missions
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Start Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  End Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Name</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Type</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Reward</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Locations / Metros
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Max DP Count
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Expected Costs
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Actual Costs
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Last Updated By
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.missionsList.missions.map((mission) => {
              const type = missionTypes.find(
                (missionType) => missionType.kind === mission.type,
              );

              const status = payRateStatusFromTimeRange(
                mission.startAtOverall,
                mission.endAtOverall,
              );
              const isDisabled = status === PayRateStatus.EXPIRED;

              return (
                <TableRow
                  key={mission.id}
                  onClick={() => handleShowDetails(mission.id)}
                  onContextMenu={(e) => handleRightClick(e, mission)}
                  className={classes.editableRow}
                >
                  <TableCell>
                    <StringField
                      value={formatLocalTime(mission.startAtLocal)}
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StringField
                      value={
                        mission.endAtLocal
                          ? formatLocalTime(mission.endAtLocal)
                          : '-'
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StringField value={mission.title} disabled={isDisabled} />
                  </TableCell>
                  <TableCell>
                    <MissionTypeChip type={type} disabled={isDisabled} />
                  </TableCell>
                  <TableCell>
                    <StringField
                      value={type?.rewardValueExtractor(mission) ?? '-'}
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StringField
                      value={getLocationsMetrosField(mission)}
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StringField
                      value={mission.maxDriverCount?.toString() ?? '-'}
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StringField
                      value={
                        getSymbolFromCurrency(
                          mission.reward.currency ?? 'USD',
                        ) +
                        (mission.expectedCosts / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>

                  <TableCell>
                    <StringField
                      value={
                        getSymbolFromCurrency(
                          mission.reward.currency ?? 'USD',
                        ) +
                        (mission.actualCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StringField
                      value={
                        mission.audit.lastUpdatedBy ?? mission.audit.createdBy
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                  <TableCell>
                    <StatusField status={status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={props.missionsList.totalCount}
          page={props.pagination.page - 1}
          onPageChange={(_, newPage) =>
            props.onPagination({ ...props.pagination, page: newPage + 1 })
          }
          rowsPerPage={props.pagination.rowsPerPage}
          onRowsPerPageChange={(e) =>
            props.onPagination({
              page: 1,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
        />
      </TableContainer>
      <Menu
        keepMounted
        disableScrollLock
        open={menuState.clickedMissionID !== undefined}
        onClose={handleCloseMenu}
        anchorReference='anchorPosition'
        anchorPosition={
          menuState.mouseX !== undefined && menuState.mouseY !== undefined
            ? { top: menuState.mouseY, left: menuState.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleOpenInNewTab}>Open in New Tab</MenuItem>
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typeChip: {
      backgroundColor: sharedColors.blue1,
      color: sharedColors.blue6,
    },
    typeChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    editableRow: {
      cursor: 'pointer',
    },
  }),
);

export default MissionTable;
