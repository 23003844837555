import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import { TreeItem } from '@mui/lab';
import { ReturnCocPaySummary } from '../../../../interfaces/PayHistory/ReturnCocPaySummary';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  returnCocPayAmountNodeID,
  returnCocPayReturnLocationCountNodeID,
} from '../../../../variables/PayHistory';
import SinglePayoutNode from '../SinglePayoutNode';

interface ReturnCocPayNodeProps {
  returnCocPaySummary: ReturnCocPaySummary;
  currency: string;
  id: string;
  name: string;
}

const ReturnCocPayNode = (props: ReturnCocPayNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${props.name} {${Object.keys(
          props.returnCocPaySummary,
        ).length.toString()}}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, returnCocPayReturnLocationCountNodeID])}
        name='Return Location Count'
        value={props.returnCocPaySummary.returnLocationCount.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, returnCocPayAmountNodeID])}
        name='Amount'
        amount={props.returnCocPaySummary.amount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default ReturnCocPayNode;
