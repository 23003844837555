import { atom } from 'recoil';
import { Location, Metro } from '../interfaces/Location';

export const locations = atom({
  key: 'locations',
  default: [] as Location[],
});

export const metros = atom({
  key: 'metros',
  default: [] as Metro[],
});

export const selectedLocationIDs = atom({
  key: 'selectedLocationIDs',
  default: [] as string[],
});

export const filteredLocationIDs = atom({
  key: 'filteredLocationIDs',
  default: [] as string[],
});
