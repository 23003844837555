import { makeStyles } from 'tss-react/mui';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { InclusionListInsertionOptions } from '../../interfaces/FeatureToggle';
import { useSharedStyles } from '../../utilities/Styles';

interface InsertFeatureToggleFormProps {
  isLocationType: boolean;
  options: InclusionListInsertionOptions;
  onOptionsChange: (newOptions: InclusionListInsertionOptions) => void;
}

const InsertFeatureToggleForm = (props: InsertFeatureToggleFormProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const handleSetStartAt = (newStartAt: Date | null) =>
    props.onOptionsChange({
      ...props.options,
      startAt: newStartAt ?? new Date(),
    });

  const handleSetEndAt = (newEndAt: Date | null) =>
    props.onOptionsChange({
      ...props.options,
      endAt: newEndAt ?? new Date(),
    });

  const handleToggleEndAtSwitch = () =>
    props.onOptionsChange({
      ...props.options,
      setEndAt: !props.options.setEndAt,
    });

  const handleSetTimezone = (e: any) =>
    props.onOptionsChange({
      ...props.options,
      isLocalTime: e.target.value === 'local',
    });

  return (
    <Box component='div' className={classes.body}>
      <Box component='div' className={classes.dateTimePicker}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            onChange={handleSetStartAt}
            value={props.options.startAt}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField {...params} size='small' label='Start At' />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box component='div' className={classes.row}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            onChange={handleSetEndAt}
            value={props.options.endAt}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField {...params} size='small' label='End At' />
            )}
            disabled={!props.options.setEndAt}
          />
        </LocalizationProvider>
        <Switch
          checked={props.options.setEndAt}
          onChange={handleToggleEndAtSwitch}
          inputProps={{ 'aria-label': 'controlled' }}
          color={'success'}
        />
      </Box>
      <FormControl component='fieldset' className={classes.timezoneRadioGroup}>
        <FormLabel>
          <Typography className={sharedClasses.caption}>Timezone</Typography>
        </FormLabel>
        <RadioGroup
          name='timezone-radio-group'
          value={props.options.isLocalTime ? 'local' : 'utc'}
          onChange={handleSetTimezone}
        >
          <FormControlLabel
            key='utc'
            value='utc'
            control={<Radio />}
            label={<Typography className={sharedClasses.body1}>UTC</Typography>}
          />
          {props.isLocationType && (
            <FormControlLabel
              key='local'
              value='local'
              control={<Radio />}
              label={
                <Typography className={sharedClasses.body1}>
                  MFC Local
                </Typography>
              }
            />
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  body: {
    marginTop: theme.spacing(1.5),
  },
  dateTimePicker: {
    marginBottom: theme.spacing(1.5),
  },
  row: {
    marginTop: theme.spacing(1.5),
    flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  endAtSwitch: {
    marginLeft: theme.spacing(1.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  timezoneRadioGroup: {
    marginTop: theme.spacing(1.5),
  },
}));

export default InsertFeatureToggleForm;
