import { BatchNormalizationSettings } from '../../interfaces/Settings/BatchNormalizationSettings';
import { getNextTuesday } from '../Dates';

export const getDefaultBatchNormalizationSettings =
  (): BatchNormalizationSettings => ({
    id: 0,
    locationID: '',
    timeRateAdjustmentByBatchSize: {
      '1': 0.11,
      '2': 0,
      '3': -0.08,
      '4': -0.13,
      '5': -0.18,
    },
    startAt: getNextTuesday(),
    lastUpdatedBy: '',
  });
