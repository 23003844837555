import { DateTime } from 'luxon';
import { MinMaxFares, minMaxFaresFromResponse } from './MinMaxFares';
import { convertLocalToDeliveryZone } from '../../utilities/Dates';

export interface TimeRatePipelineSettings {
  id: number;
  locationID: string;
  targetP90DeliveryTime: number;
  targetP90DeliveryDriverControllableTime: number;
  maxDeltaOrdersPerDriverPercent: number;
  maxDeltaCommission: number;
  timeRateLowerBound: number;
  timeRateUpperBound: number;
  minTimeRateMissingData: number;
  dcpoNeutralOverWeek: boolean;
  noCommissionIncrease: boolean;
  minMaxFares?: MinMaxFares;
  startAt: Date;
  endAt?: Date;
  lastUpdatedBy: string;
}

export const timeRatePipelineSettingsFromResponse = (
  responseData: any,
): TimeRatePipelineSettings => ({
  id: responseData.id,
  locationID: responseData.location_id,
  targetP90DeliveryTime: responseData.target_p_90_delivery_time_minutes,
  targetP90DeliveryDriverControllableTime:
    responseData.target_p_90_delivery_driver_controllable_time_minutes,
  maxDeltaOrdersPerDriverPercent:
    responseData.max_delta_orders_per_driver_hour_percent,
  maxDeltaCommission: responseData.max_delta_commission,
  timeRateLowerBound: responseData.time_rate_lower_bound,
  timeRateUpperBound: responseData.time_rate_upper_bound,
  minTimeRateMissingData: responseData.min_time_rate_missing_data,
  dcpoNeutralOverWeek: responseData.dcpo_neutral_over_week,
  noCommissionIncrease: responseData.no_commission_increase,
  minMaxFares: responseData.min_max_fares
    ? minMaxFaresFromResponse(responseData.min_max_fares)
    : undefined,
  startAt: new Date(responseData.start_at),
  endAt: responseData.end_at ? new Date(responseData.end_at) : undefined,
  lastUpdatedBy: responseData.last_updated_by ?? responseData.created_by,
});

export const timeRatePipelineSettingsOutputTransformer = (
  locationIDs: string[],
  settings: TimeRatePipelineSettings,
) => {
  const startAt = convertLocalToDeliveryZone(
    'America/Los_Angeles',
    DateTime.fromJSDate(settings.startAt),
    9,
    30,
  );
  const endAt = settings.endAt
    ? convertLocalToDeliveryZone(
        'America/Los_Angeles',
        DateTime.fromJSDate(settings.endAt),
        9,
        30,
      )
    : undefined;
  const metadata = {
    target_p_90_delivery_time_minutes: settings.targetP90DeliveryTime,
    target_p_90_delivery_driver_controllable_time_minutes:
      settings.targetP90DeliveryDriverControllableTime,
    max_delta_orders_per_driver_hour_percent:
      settings.maxDeltaOrdersPerDriverPercent,
    max_delta_commission: settings.maxDeltaCommission,
    time_rate_lower_bound: settings.timeRateLowerBound,
    time_rate_upper_bound: settings.timeRateUpperBound,
    min_time_rate_missing_data: settings.minTimeRateMissingData,
    dcpo_neutral_over_week: settings.dcpoNeutralOverWeek,
    no_commission_increase: settings.noCommissionIncrease,
    min_max_fares: settings.minMaxFares
      ? {
          min_fare_first_order: settings.minMaxFares.minFareFirstOrder,
          min_fare_subsequent_order:
            settings.minMaxFares.minFareSubsequentOrder,
          max_fare_per_order: settings.minMaxFares.maxFarePerOrder,
          min_fare_first_order_lower_bound:
            settings.minMaxFares.minFareFirstOrderLowerBound,
          min_fare_first_order_upper_bound:
            settings.minMaxFares.minFareFirstOrderUpperBound,
        }
      : undefined,
  };

  return locationIDs.map((locationID) => ({
    point_in_time_unique_key: locationID,
    start_at: startAt,
    end_at: endAt,
    metadata,
  }));
};
