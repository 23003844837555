import { FieldInfo, Parser } from 'json2csv';
import { CsvTemplate } from '../interfaces/CsvTemplate';

export const parseCSVFile = (
  fields: (string | FieldInfo<unknown>)[],
  data: Readonly<unknown> | readonly unknown[],
): string => {
  const parser = new Parser({ fields, quote: '"' });
  const csv = parser.parse(data);
  return URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
};

export const csvMimeTypes =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, ' +
  'application/x-csv, text/comma-separated-values, text/x-comma-separated-values';

export const downloadCsvTemplate = (csvTemplate: CsvTemplate) => {
  const blob = new Blob([csvTemplate.content], {
    type: 'text/csv;charset=utf-8;',
  });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${csvTemplate.name}.csv`);
    document.body.appendChild(link);
    link.click();
  }
};
