export interface BasePayBreakdown {
  basePay: number;
  realTimeAdjustment: number;
  isVariableBasePay: boolean;
}

export const basePayBreakdownFromResponse = (
  responseData: any,
): BasePayBreakdown => ({
  basePay: responseData.base_pay ?? 0,
  realTimeAdjustment: responseData.real_time_adjustment ?? 0,
  isVariableBasePay: responseData.is_variable_base_pay ?? false,
});
