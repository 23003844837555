import { PushNotificationCategory } from './PushNotification';

export enum PushNotificationRequirementKind {
  DRIVER_ID = 'DRIVER_ID',
  LOCATION = 'LOCATION',
}

export enum PushNotificationEmployeeType {
  EMPLOYEE_TYPE_ALL = 'All',
  EMPLOYEE_TYPE_W2 = 'W2',
  EMPLOYEE_TYPE_1099 = '1099',
}

export interface PushNotificationToCreate {
  title: string;
  message: string;
  category: PushNotificationCategory;
  requirementKind: PushNotificationRequirementKind;
  employeeType: PushNotificationEmployeeType;
  locationIDs: string[];
  metroIDs: string[];
  driverIDs: string[];
  includeAlreadyActiveDrivers: boolean;
}

export const pushNotificationToCreateOutputTransformer = (
  pushNotificationToCreate: PushNotificationToCreate,
): any => {
  let req: any = {
    title: pushNotificationToCreate.title,
    message: pushNotificationToCreate.message,
    category: pushNotificationToCreate.category,
    include_already_active_drivers:
      pushNotificationToCreate.includeAlreadyActiveDrivers,
    employee_type:
      pushNotificationToCreate.employeeType ===
      PushNotificationEmployeeType.EMPLOYEE_TYPE_ALL
        ? undefined
        : pushNotificationToCreate.employeeType,
  };

  switch (pushNotificationToCreate.requirementKind) {
    case PushNotificationRequirementKind.LOCATION: {
      req = {
        ...req,
        location_ids: pushNotificationToCreate.locationIDs,
        metro_ids: pushNotificationToCreate.metroIDs,
      };
      break;
    }
    case PushNotificationRequirementKind.DRIVER_ID: {
      req = { ...req, driver_ids: pushNotificationToCreate.driverIDs };
      break;
    }
    default:
      break;
  }

  return req;
};

export interface PushNotificationFormProps {
  currentState: PushNotificationToCreate;
  setCurrentState: (newState: PushNotificationToCreate) => void;
}
