import getSymbolFromCurrency from 'currency-symbol-map';
import { DateTime } from 'luxon';
import {
  Mission,
  MissionCategory,
  MissionCategoryKind,
  MissionKind,
  MissionType,
} from '../interfaces/Mission/Mission';
import BonusMissionForm from '../components/Missions/NewMission/InputForms/BonusMissionForm';
import { MissionRewardTierType } from '../interfaces/Mission/MissionReward';
import BoostMissionForm from '../components/Missions/NewMission/InputForms/BoostMissionForm';
import { defaultMissionOutputTransformer } from '../utilities/Mission';
import { MissionFilters } from '../interfaces/Mission/MissionFilters';
import TieredBonusDetails from '../components/Missions/TieredBonusDetails';
import boostMissionIcon from '../asset/boostMission.png';
import bonusMissionIcon from '../asset/bonusMission.png';
import {
  missionMetricsOutputTransformer,
  MissionMetricType,
} from '../interfaces/Mission/MissionMetric';

const boostMissionIconGenerator = () => (
  <img src={boostMissionIcon} alt='' width={32} />
);

const bonusMissionIconGenerator = () => (
  <img src={bonusMissionIcon} alt='' width={32} />
);

const allMissionTypes: MissionType[] = [
  {
    kind: MissionKind.ORDER_BONUS,
    name: 'Tiered Order Bonus',
    disabled: false,
    allowIndefiniteEndAt: true,
    inputGenerator: (currentState, setter, applyPadding) => (
      <BonusMissionForm
        currentState={currentState}
        setCurrentState={setter}
        applyPadding={applyPadding}
      />
    ),
    templateGenerator: (currentState) => ({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        metrics: {},
      },
      reward: {
        tierType: MissionRewardTierType.ORDER,
        tiers: [
          {
            count: 0,
            pay: 0,
          },
        ],
      },
    }),
    iconGenerator: bonusMissionIconGenerator,
    outputTransformer: (currentState) => {
      const defaultContract = defaultMissionOutputTransformer(currentState);

      return {
        ...defaultContract,
        type: MissionCategoryKind.DO_X_GET_Y,
        reward: {
          tier_type: MissionRewardTierType.ORDER,
          tiers: currentState.reward.tiers.map((tier) => ({
            ...tier,
            pay: Math.round(tier.pay * 100),
          })),
        },
        requirements: {
          ...defaultContract.requirements,
          metrics:
            currentState.reward.tiers.length === 1
              ? missionMetricsOutputTransformer(
                  currentState.requirements.metrics,
                )
              : [],
        },
      };
    },
    rewardValueExtractor: (currentState) =>
      `${currentState.reward.tiers.length} Tier${
        currentState.reward.tiers.length > 1 ? 's' : ''
      }`,
    rewardDescriptionExtractor: (currentState) => (
      <TieredBonusDetails mission={currentState} />
    ),
  },
  {
    kind: MissionKind.TRIP_BONUS,
    name: 'Tiered Trip Bonus',
    disabled: false,
    allowIndefiniteEndAt: true,
    inputGenerator: (currentState, setter, applyPadding) => (
      <BonusMissionForm
        currentState={currentState}
        setCurrentState={setter}
        applyPadding={applyPadding}
      />
    ),
    templateGenerator: (currentState) => ({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        metrics: {},
      },
      reward: {
        tierType: MissionRewardTierType.TRIP,
        tiers: [
          {
            count: 0,
            pay: 0,
          },
        ],
      },
    }),
    iconGenerator: bonusMissionIconGenerator,
    outputTransformer: (currentState) => {
      const defaultContract = defaultMissionOutputTransformer(currentState);

      return {
        ...defaultContract,
        type: MissionCategoryKind.DO_X_GET_Y,
        reward: {
          tier_type: MissionRewardTierType.TRIP,
          tiers: currentState.reward.tiers.map((tier) => ({
            ...tier,
            pay: Math.round(tier.pay * 100),
          })),
        },
        requirements: {
          ...defaultContract.requirements,
          metrics:
            currentState.reward.tiers.length === 1
              ? missionMetricsOutputTransformer(
                  currentState.requirements.metrics,
                )
              : [],
        },
      };
    },
    rewardValueExtractor: (currentState) =>
      `${currentState.reward.tiers.length} Tier${
        currentState.reward.tiers.length > 1 ? 's' : ''
      }`,
    rewardDescriptionExtractor: (currentState) => (
      <TieredBonusDetails mission={currentState} />
    ),
  },
  {
    kind: MissionKind.PER_ORDER_BOOST,
    name: 'Per Order Bonus',
    disabled: false,
    allowIndefiniteEndAt: false,
    inputGenerator: (currentState, setter, applyPadding) => (
      <BoostMissionForm
        currentState={currentState}
        setCurrentState={setter}
        applyPadding={applyPadding}
      />
    ),
    templateGenerator: (currentState) => ({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        metrics: {},
      },
      endAtLocal: DateTime.fromJSDate(currentState.startAtLocal)
        .plus({
          hour: 1,
        })
        .toJSDate(),
      reward: {
        tiers: [],
        pay: 0,
      },
    }),
    iconGenerator: boostMissionIconGenerator,
    outputTransformer: (currentState) => ({
      ...defaultMissionOutputTransformer(currentState),
      type: MissionCategoryKind.PER_ORDER_BOOST,
      reward: {
        pay: Math.round((currentState.reward.pay ?? 0) * 100),
      },
    }),
    rewardValueExtractor: (currentState) =>
      currentState.reward.pay !== undefined
        ? getSymbolFromCurrency(currentState.reward.currency ?? 'USD') +
          currentState.reward.pay.toFixed(2)
        : '-',
  },
  {
    kind: MissionKind.PER_TRIP_BOOST,
    name: 'Per Trip Bonus',
    disabled: false,
    allowIndefiniteEndAt: false,
    inputGenerator: (currentState, setter, applyPadding) => (
      <BoostMissionForm
        currentState={currentState}
        setCurrentState={setter}
        applyPadding={applyPadding}
      />
    ),
    templateGenerator: (currentState) => ({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        metrics: {},
      },
      endAtLocal: DateTime.fromJSDate(currentState.startAtLocal)
        .plus({
          hour: 1,
        })
        .toJSDate(),
      reward: {
        tiers: [],
        pay: 0,
      },
    }),
    iconGenerator: boostMissionIconGenerator,
    outputTransformer: (currentState) => ({
      ...defaultMissionOutputTransformer(currentState),
      type: MissionCategoryKind.PER_TRIP_BOOST,
      reward: {
        pay: Math.round((currentState.reward.pay ?? 0) * 100),
      },
    }),
    rewardValueExtractor: (currentState) =>
      currentState.reward.pay !== undefined
        ? getSymbolFromCurrency(currentState.reward.currency ?? 'USD') +
          currentState.reward.pay.toFixed(2)
        : '-',
  },
];

export const missionTypes = allMissionTypes.filter(
  (missionType) => !missionType.disabled,
);

const allMissionCategories: MissionCategory[] = [
  {
    kind: MissionCategoryKind.DO_X_GET_Y,
    name: 'Tiered Bonus',
    disabled: false,
  },
  {
    kind: MissionCategoryKind.PER_ORDER_BOOST,
    name: 'Per Order Bonus',
    disabled: false,
  },
  {
    kind: MissionCategoryKind.PER_TRIP_BOOST,
    name: 'Per Trip Bonus',
    disabled: false,
  },
];

export const missionCategories = allMissionCategories.filter(
  (missionCategory) => !missionCategory.disabled,
);

export const getDefaultFilters = (): MissionFilters => ({
  activeAfter: null,
  activeBefore: null,
  driverID: null,
  locationIDs: [],
  metroIDs: [],
  statuses: [],
  types: [],
  onlyAllowingActiveDP: false,
  onlyExperiments: false,
  pagination: {
    page: 1,
    rowsPerPage: 10,
  },
});

export const possibleOperators: string[] = ['>', '>=', '<=', '<'];

export const possibleMetricTypes: MissionMetricType[] = [
  MissionMetricType.ACCEPTANCE,
  MissionMetricType.COMPLETION,
  MissionMetricType.DID_NOT_RECEIVE,
  MissionMetricType.ON_TIME,
];

export const isCompletionCriteriaConfigurable = (
  currentState: Mission,
): boolean => {
  if (
    currentState.type !== MissionKind.TRIP_BONUS &&
    currentState.type !== MissionKind.ORDER_BONUS
  ) {
    return false;
  }

  return currentState.reward.tiers.length === 1;
};
