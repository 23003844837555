import { Chip, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  payRateStatusActive,
  payRateStatusExpired,
  payRateStatusFuture,
} from './PayRates/PayRateTable';
import { PayRateStatus } from '../interfaces/PayRate';
import { sharedColors } from '../utilities/Styles';

interface StatusFieldProps {
  status: PayRateStatus;
}

const StatusField = (props: StatusFieldProps) => {
  const classes = useStyles();

  const className = {
    [PayRateStatus.ACTIVE]: classes.statusActiveChip,
    [PayRateStatus.EXPIRED]: classes.statusExpiredChip,
    [PayRateStatus.FUTURE]: classes.statusFutureChip,
  };

  const text = {
    [PayRateStatus.ACTIVE]: payRateStatusActive,
    [PayRateStatus.EXPIRED]: payRateStatusExpired,
    [PayRateStatus.FUTURE]: payRateStatusFuture,
  };

  return (
    <Chip
      className={className[props.status]}
      disabled={!props.status}
      label={
        <Typography id='status' className={classes.statusChipText}>
          {text[props.status]}
        </Typography>
      }
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    statusActiveChip: {
      backgroundColor: sharedColors.statusGreenLightest,
      color: sharedColors.statusGreen,
    },
    statusExpiredChip: {
      backgroundColor: sharedColors.statusRedLightest,
      color: sharedColors.statusRed,
    },
    statusFutureChip: {
      backgroundColor: sharedColors.blue1,
      color: sharedColors.blue7,
    },
    statusChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
    },
  }),
);

export default StatusField;
