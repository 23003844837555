import { Chip, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import {
  AdjustmentStatus,
  AdjustmentEntity,
} from '../../../interfaces/Adjustment';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { permissions as permissionsAtom } from '../../../atoms/Users';
import { Permission } from '../../../interfaces/Users';
import { isPermitted } from '../../../variables/Users';

interface AdjustmentStatusCellProps {
  adjustment: AdjustmentEntity;
  onShowApprovalDialog?: () => void;
}

const AdjustmentStatusCell = (props: AdjustmentStatusCellProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const permissions = useRecoilValue(permissionsAtom);
  const canApprovalDialog =
    props.adjustment.status === AdjustmentStatus.PendingApproval &&
    isPermitted(permissions, Permission.SET_ADJUSTMENT_APPROVALS);

  return (
    <>
      <Chip
        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
        className={statusClass(props.adjustment.status)}
        sx={{
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        label={
          <Typography className={sharedClasses.buttonText}>
            {props.adjustment.status}
          </Typography>
        }
        clickable={canApprovalDialog && !!props.onShowApprovalDialog}
        onClick={canApprovalDialog ? props.onShowApprovalDialog : undefined}
      />
    </>
  );
};

const statusClass = (status: AdjustmentStatus): string => {
  const { classes } = useStyles();
  if (status === AdjustmentStatus.PaidOut) {
    return classes.paidOut;
  }
  if (
    status === AdjustmentStatus.PendingApproval ||
    status === AdjustmentStatus.Denied
  ) {
    return classes.needsApproval;
  }
  // There are other possible statuses, but we default them to the same color as pending
  return classes.pending;
};

export default AdjustmentStatusCell;

const useStyles = makeStyles()(() => ({
  paidOut: {
    background: sharedColors.statusGreenLightest,
    color: sharedColors.statusGreen,
  },
  needsApproval: {
    background: sharedColors.statusRedLightest,
    color: sharedColors.statusRed,
  },
  pending: {
    background: sharedColors.blue1,
    color: sharedColors.blue5,
  },

  chipRoot: {
    height: '100%',
  },

  chipLabel: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
}));
