import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { MissionSegmentFormProps } from '../../../interfaces/Mission/MissionSegment';
import { sharedColors } from '../../../utilities/Styles';

const CompletedFirstDeliveryInput = (props: MissionSegmentFormProps) => {
  const classes = useStyles();

  const handleChangeOption = (e: any) => {
    props.setCurrentState({
      ...props.currentState,
      completedFirstDelivery: !!e?.target?.value,
    });
  };

  return (
    <Box component='div' className={classes.body}>
      <FormControl size='small' variant='outlined' className={classes.dropdown}>
        <Select
          value={props.currentState.completedFirstDelivery ? 1 : 0}
          onChange={handleChangeOption}
        >
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    dropdown: {
      marginLeft: theme.spacing(5),
    },
  }),
);

export default CompletedFirstDeliveryInput;
