import { Box, Dialog, DialogTitle, Tab, Tabs, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UploadDialogTabKind } from '../../../interfaces/Common';
import { getAdjustmentCategoriesAndSubtypes } from '../../../services/adjustmentSubtypes';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import Waiting from '../../Waiting';
import ManualAdjustmentForm from './ManualAdjustmentForm';
import { adjustmentSubtypes as adjustmentSubtypesAtom } from '../../../atoms/Adjustment';
import AdjustmentCSVForm from './AdjustmentCSVForm';
import { Permission } from '../../../interfaces/Users';
import { permissions as permissionsAtom } from '../../../atoms/Users';
import { isPermitted } from '../../../variables/Users';

interface NewAdjustmentDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpload: () => void;
  uuid: string;
}

const NewAdjustmentDialog = (props: NewAdjustmentDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const permissions = useRecoilValue(permissionsAtom);
  const [loading, setLoading] = useState(true);
  const [adjustmentSubtypes, setAdjustmentSubtypes] = useRecoilState(
    adjustmentSubtypesAtom,
  );
  useEffect(() => {
    getAdjustmentCategoriesAndSubtypes()
      .then((categoriesAndSubtypes) => {
        setAdjustmentSubtypes(categoriesAndSubtypes.subtypes);
      })
      .catch((error) => {
        toast.error(
          `Could not load adjustment types for selection: ${error.message}`,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const tabs = [
    {
      kind: UploadDialogTabKind.MANUAL_ADDITION,
      name: 'Add Adjustment',
      component: (
        <ManualAdjustmentForm
          adjustmentSubtypes={adjustmentSubtypes}
          onClose={props.onClose}
          onCreate={props.onCreate}
          uuid={props.uuid}
        />
      ),
    },
  ];
  if (isPermitted(permissions, Permission.UPLOAD_BULK_ADJUSTMENTS)) {
    tabs.push({
      kind: UploadDialogTabKind.BULK_UPLOAD,
      name: 'Upload CSV File',
      component: (
        <AdjustmentCSVForm onClose={props.onClose} onUpload={props.onUpload} />
      ),
    });
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Add Adjustments
      </DialogTitle>
      <Waiting open={loading} />
      <AddAdjustmentTabs
        initialTab={UploadDialogTabKind.MANUAL_ADDITION}
        tabs={tabs}
      />
    </Dialog>
  );
};

interface AdjustmentTab {
  kind: UploadDialogTabKind;
  name: string;
  component: JSX.Element;
}

interface AddAdjustmentTabsProps {
  initialTab: UploadDialogTabKind;
  tabs: AdjustmentTab[];
}

const AddAdjustmentTabs = (props: AddAdjustmentTabsProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [selectedTab, setSelectedTab] = useState(props.initialTab);
  const handleTabChange = (_: any, newTab: UploadDialogTabKind) => {
    setSelectedTab(newTab);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        className={classes.tabsContainer}
      >
        {props.tabs.map((tab) => (
          <Tab
            label={
              <Typography
                className={multiClass([sharedClasses.body1, classes.tabLabel])}
              >
                {tab.name}
              </Typography>
            }
            key={tab.name}
          />
        ))}
      </Tabs>
      <Box role='tabpanel' sx={{ p: 2 }}>
        {props.tabs.find((tab) => tab.kind === selectedTab)?.component}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  tabsContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    background: sharedColors.gray1,
  },
  tabLabel: {
    textTransform: 'none',
    fontWeight: 500,
  },
}));

export default NewAdjustmentDialog;
