import { Button, Chip, Typography } from '@mui/material';
import React from 'react';
import { SortableTableHeadCell } from '../interfaces/Common';
import {
  ManualRestrictedAccountToAdd,
  RestrictedAccount,
} from '../interfaces/RestrictedAccount';
import { formatLocalTime } from '../utilities/Dates';

export const enableStripeButtonTestIdPrefix = 'enableStripeButton';

export const generateHeadCells = (
  classes: any,
  onEnableStripe: (account: RestrictedAccount) => void,
  allowEnableStripe: boolean,
): SortableTableHeadCell<RestrictedAccount>[] => [
  {
    id: 'DriverID',
    label: 'Driver ID',
    sortable: true,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.DriverID}
      </Typography>
    ),
  },
  {
    id: 'Name',
    label: 'Name',
    sortable: true,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>{row.Name}</Typography>
    ),
  },
  {
    id: 'Email',
    label: 'Email',
    sortable: true,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>{row.Email}</Typography>
    ),
  },
  {
    id: 'RestrictionType',
    label: 'Restriction Type',
    sortable: true,
    extractor: (row) => (
      <Chip label={row.RestrictionType} className={classes?.typeChip} />
    ),
  },
  {
    id: 'LastUpdatedAt',
    label: 'Restriction Added',
    sortable: true,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {formatLocalTime(row.LastUpdatedAt)}
      </Typography>
    ),
  },
  {
    id: 'LastUpdatedBy',
    label: 'Added By',
    sortable: true,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.LastUpdatedBy}
      </Typography>
    ),
  },
  {
    id: 'enableAccount',
    label: 'Enable Account',
    sortable: false,
    extractor: (row) => (
      <Button
        variant='outlined'
        color='primary'
        onClick={() => onEnableStripe(row)}
        disabled={!allowEnableStripe}
        data-testid={`${enableStripeButtonTestIdPrefix}_${row.id}`}
        className={classes?.stripeButton}
      >
        Enable Stripe
      </Button>
    ),
  },
];

export const defaultRestrictedAccountToAdd = (
  restrictionTypes: string[],
): ManualRestrictedAccountToAdd => ({
  DriverID: '',
  RestrictionType: restrictionTypes[0] || '',
});
