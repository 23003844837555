import api from './api';

const driverAccountEndpoint = '/admin/driver-accounts';

export const resetStripeAccount = async (
  stripeAccountID: string,
): Promise<void> => {
  try {
    await api.delete(`${driverAccountEndpoint}/${stripeAccountID}`);
  } catch (error: any) {
    throw new Error(`Could not reset Stripe account: ${error.message}`);
  }
};
