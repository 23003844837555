import { DateTime } from 'luxon';
import api from './api';

const dppTimeRatesEndpointUrl = '/time-rate';

export const uploadDppTimeRates = async (
  file: File,
  startAt: DateTime,
  endAt: DateTime,
  timeBlockCount: number,
): Promise<void> => {
  const url = `${dppTimeRatesEndpointUrl}/csv`;
  const format = 'y-MM-dd';

  const bodyFormData = new FormData();
  bodyFormData.set('file', file, file.name);
  await api.put(url, bodyFormData, {
    params: {
      start_at: startAt.toFormat(format),
      end_at: endAt.toFormat(format),
      time_block_count: timeBlockCount,
    },
  });
};
