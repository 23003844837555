import { formatMomentAsFilePostfix } from './Dates';

export const isNumber = (x: any) => Number(x) === x;

export const isValidDriverId = (driverId: string): boolean =>
  !driverId.length || !!driverId.match(/^[0-9]+$/);

export const downloadExportedCsv = (
  csvContent: string,
  fileNamePrefix: string,
): void => {
  const url = window.URL.createObjectURL(new Blob([csvContent]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${fileNamePrefix}_${formatMomentAsFilePostfix()}.csv`,
  );
  document.body.appendChild(link);
  link.click();
};

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const toTwoDecimalsMax = (n: number): string =>
  (Math.round(n * 100) / 100).toString();

export const toFixedIfNecessary = (
  n: number,
  maxPrecision: number = 2,
): string => (+n.toFixed(maxPrecision)).toString();
