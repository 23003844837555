export interface MissionSegmentInvitationRequirements {
  countryCode?: string;
  metroIDs: string[];
  locationIDs: string[];
  mustBeActiveAt: string[];
  mustBeInactive: boolean;
}

export const missionSegmentInvitationRequirementsFromResponse = (
  responseData: any,
): MissionSegmentInvitationRequirements => ({
  countryCode: responseData.country_code,
  metroIDs: responseData.metro_ids ?? [],
  locationIDs: responseData.location_ids ?? [],
  mustBeActiveAt: responseData.must_be_active_at ?? [],
  mustBeInactive: responseData.must_be_inactive,
});
