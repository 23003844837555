import { useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { isNumber } from '../../../../utilities/Misc';
import { max2DecimalsRegexp } from '../../../../utilities/Constants';

export interface SpanRateInputProps {
  name: string;
  onChange: (newValue: number) => void;
  value?: number;
  isDisabled: boolean;
}

const SpanRateInput = (props: SpanRateInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = [
    'isPositive',
    `matchRegexp:${max2DecimalsRegexp}`,
    'required',
  ];
  const errorMessages = ['', '', ''];

  const handleChange = (e: any) => {
    props.onChange(parseFloat(e.target.value));
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = () => {
    if (isFocused) {
      return plainTextValue;
    }

    if (isNumber(props.value)) {
      return props.value!.toFixed(2);
    }

    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <TextValidator
      size='small'
      name={props.name}
      value={getFormattedValue()}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      validators={validators}
      errorMessages={errorMessages}
      disabled={props.isDisabled}
    />
  );
};

export default SpanRateInput;
