import { ListSubheader, MenuItem } from '@mui/material';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { SelectValidator } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';
import { AdjustmentSubtype } from '../../../interfaces/AdjustmentSubtype';
import { requiredErrorMessage } from '../../../utilities/Constants';

interface AdjustmentTypeSelectProps {
  adjustmentSubtypes: AdjustmentSubtype[];
  selectedSubtype?: AdjustmentSubtype;
  disabled: boolean;
  onChange: (subtype: AdjustmentSubtype) => void;
}

export const subtypeSelectorTestId = `adjustment-subtype-selector-test-id`;
export const subtypeSelectionTestId = `adjustment-subtype-selection-test-id`;
export const subtypeGroupingTestId = `adjustment-subtype-grouping-test-id`;
export const adjustmentTypeLabel = `Adjustment Type`;

const AdjustmentTypeSelect = (props: AdjustmentTypeSelectProps) => {
  const { classes } = useStyles();

  const typesToSubtypeMap = Object.entries(
    groupBy(props.adjustmentSubtypes, (subtype) => subtype.adjustmentType),
  );

  const [description, setDescription] = useState(
    props.selectedSubtype?.description || '',
  );

  const handleAdjustmentTypeChange = (event: any) => {
    const selectedDescription = event.target.value;
    props.adjustmentSubtypes
      .filter((subtype) => subtype.description === selectedDescription)
      .forEach((subtype) => props.onChange(subtype));

    setDescription(selectedDescription);
  };

  // Need to keep `MenuItem` components as direct children of the SelectValidator, otherwise
  // selection won't work
  const listItems: JSX.Element[] = [];
  typesToSubtypeMap.forEach(([type, subtypes]) => {
    listItems.push(
      <ListSubheader
        data-testid={subtypeGroupingTestId}
        key={`type-header-${type}`}
      >
        {type}
      </ListSubheader>,
    );
    subtypes.forEach((subtype) => {
      listItems.push(
        <MenuItem
          data-testid={subtypeSelectionTestId}
          key={subtype.id}
          value={subtype.description}
          selected={subtype.id === props.selectedSubtype?.id}
        >
          {subtype.description}
        </MenuItem>,
      );
    });
  });

  return (
    <SelectValidator
      id='adjustment-type-label'
      data-testid={subtypeSelectorTestId}
      value={description}
      className={classes.input}
      fullWidth
      variant='outlined'
      size='small'
      onChange={handleAdjustmentTypeChange}
      label={adjustmentTypeLabel}
      name={'AdjustmentType'}
      validators={['required']}
      errorMessages={[requiredErrorMessage]}
      inputProps={{
        disabled: props.disabled,
      }}
    >
      {listItems}
    </SelectValidator>
  );
};

const useStyles = makeStyles()(() => ({
  input: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default AdjustmentTypeSelect;
