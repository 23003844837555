import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { HolidayPayBreakdown } from '../../../../interfaces/PayHistory/HolidayPayBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  holidayPayBreakdownsNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import HolidayPayBreakdownNode from './HolidayPayBreakdownNode';
import { parseCentsAsCurrencyString } from '../../../../utilities/Currency';

interface HolidayPayBreakdownListNodeProps {
  holidayPayBreakdown: HolidayPayBreakdown;
  currency: string;
}

const HolidayPayBreakdownListNode = (
  props: HolidayPayBreakdownListNodeProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return props.holidayPayBreakdown != null ? (
    <TreeItem
      nodeId={holidayPayBreakdownsNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Holiday Pay : ${parseCentsAsCurrencyString(
            props.holidayPayBreakdown.totalAmount,
          )}`}
        </Typography>
      }
    >
      {props.holidayPayBreakdown.singleHolidayPayBreakdowns.map(
        (singleHolidayPayBreakdown, i) => (
          <HolidayPayBreakdownNode
            id={composeNodeID([holidayPayBreakdownsNodeID, i.toString()])}
            name={`${
              singleHolidayPayBreakdown.holidayTypeName
            } : ${parseCentsAsCurrencyString(
              singleHolidayPayBreakdown.amount,
            )}`}
            singleHolidayPayBreakdown={singleHolidayPayBreakdown}
            currency={props.currency}
          />
        ),
      )}
    </TreeItem>
  ) : null;
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default HolidayPayBreakdownListNode;
