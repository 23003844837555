import React from 'react';
import { flatten, isEmpty, parseInt } from 'lodash';
import { DateTime } from 'luxon';
import { FirstShiftBonusForm } from '../components/NewPayRateDialog/PayRateForms/FirstShiftBonus';
import { WeeklyBonusForm } from '../components/NewPayRateDialog/PayRateForms/WeeklyBonus';
import { FirstShiftBonusOverview } from '../components/NewPayRateDialog/PayRateOverviews/FirstShiftBonus';
import { WeeklyBonusOverview } from '../components/NewPayRateDialog/PayRateOverviews/WeeklyBonus';
import {
  alcoholPays as mockAlcoholPays,
  batchNormalizationRules as mockBatchNormalizationRules,
  commissions as mockCommissions,
  engagedPayRates as mockEngagedPayRates,
  firstShiftBonuses as mockFirstShiftBonuses,
  hazardPays as mockHazardPays,
  overtimePays as mockOvertimePays,
  specialPays as mockSpecialPays,
  subsidies as mockSubsidies,
  tier2DeliveryPays as mockTier2DeliveryPays,
  tobaccoPays as mockTobaccoPays,
  waitPays as mockWaitPays,
  weeklyBonuses as mockWeeklyBonuses,
} from '../mocks/PayRate';

import { isNumber } from '../utilities/Misc';

import {
  calculateTimeDiff,
  getTimeString,
  handleEndDate,
} from '../utilities/Dates';

import {
  BatchNormalizationRules,
  BoostPay,
  DelayedDeliveryPay,
  DelayedDeliveryType,
  FirstShiftBonus,
  GenericPayRate,
  GenericPayRateType,
  NewPayRateStepKind,
  OvertimePay,
  PayRate,
  PayRateField,
  PayRateKind,
  PayRateSchedule,
  PayRateScheduleSpan,
  PayRateScheduleSpanRange,
  PayRateScheduleSpanRequest,
  PayRateStatus,
  PayRateType,
  PercentagePerBatchSize,
  ReimbursementPay,
  ReimbursementRate,
  ReturnCoc,
  ReturnCocPaySetting,
  VariableBasePayCheck,
  WeeklyBonus,
} from '../interfaces/PayRate';
import {
  areSameIndexesNullOrNotNull,
  isArraySorted,
} from '../services/Validators';
import { OvertimePayForm } from '../components/NewPayRateDialog/PayRateForms/OvertimePay';
import { OvertimePayOverview } from '../components/NewPayRateDialog/PayRateOverviews/OvertimePay';
import { BoostPayForm } from '../components/NewPayRateDialog/PayRateForms/BoostPay';
import { BoostPayOverview } from '../components/NewPayRateDialog/PayRateOverviews/BoostPay';
import {
  filterLocationById,
  getCurrencySymbolFromLocation,
} from '../utilities/Locations';
import { Permission } from '../interfaces/Users';
import { parseCentsAsCurrencyString } from '../utilities/Currency';
import { PayRateScheduleForm } from '../components/NewPayRateDialog/PayRateForms/PayRateSchedule/PayRateSchedule';
import {
  defaultOutputTransformer,
  delayedDeliveryMetadataOutputTransformer,
  extractMultiplierField,
  extractNumberField,
  extractPaymentField,
  getReimbursementRateName,
  mergeAdjacentEqualRateSpans,
  parseDelayedDeliveryIncentiveFromResponse,
  parseNumberFieldFromResponse,
  parsePayRateMetadataFromResponse,
  parsePayRateScheduleSpansFromResponse,
  payRateMetadataOutputTransformer,
  payRateStatusFromTimeRange,
} from '../utilities/PayRates';
import { BatchNormalizationRulesForm } from '../components/NewPayRateDialog/PayRateForms/BatchNormalizationRules';
import { BatchNormalizationRulesOverview } from '../components/NewPayRateDialog/PayRateOverviews/BatchNormalizationRules';
import { GenericPayRateForm } from '../components/NewPayRateDialog/PayRateForms/GenericPayRate/GenericPayRate';
import { GenericPayRateOverview } from '../components/NewPayRateDialog/PayRateOverviews/GenericPayRate';
import StatusField from '../components/StatusField';
import StringField from '../components/StringField';
import { ReturnCocForm } from '../components/NewPayRateDialog/PayRateForms/ReturnCoc';
import { ReimbursementPayForm } from '../components/NewPayRateDialog/PayRateForms/ReimbursementPay';
import { DelayedDeliveryFrom } from '../components/NewPayRateDialog/PayRateForms/DelayedDelivery';

export const payRateScheduleSpanRanges: PayRateScheduleSpanRange[] = [
  {
    name: 'Morning',
    timeStart: 5,
    timeEnd: 12,
  },
  {
    name: 'Midday',
    timeStart: 12,
    timeEnd: 17,
  },
  {
    name: 'Peak',
    timeStart: 17,
    timeEnd: 21,
  },
  {
    name: 'Late night',
    timeStart: 21,
    timeEnd: 29,
  },
];

const commonPayRateFields: PayRateField[] = [
  {
    name: 'Location Name',
    responseParser: (payRate, responseData) => ({
      ...payRate,
      locationID: responseData.location_id,
    }),
    extractor: (payRate, locations) => {
      const location = filterLocationById(
        locations,
        parseInt(payRate.locationID, 10),
      );

      return (
        <StringField
          value={location?.name || '-'}
          disabled={payRate.status === PayRateStatus.EXPIRED}
        />
      );
    },
  },
  {
    name: 'Last Updated By',
    responseParser: (payRate, responseData) => ({
      ...payRate,
      lastUpdatedBy: responseData.last_updated_by ?? responseData.created_by,
    }),
    extractor: (payRate) => (
      <StringField
        value={payRate.lastUpdatedBy}
        disabled={payRate.status === PayRateStatus.EXPIRED}
      />
    ),
  },
  {
    name: 'Status',
    responseParser: (payRate, responseData) => {
      let { status } = responseData;
      if (!status) {
        const endDate = handleEndDate(responseData.end_at);
        status = payRateStatusFromTimeRange(
          responseData.start_at,
          endDate.time,
        );
      }

      return {
        ...payRate,
        status,
      };
    },
    extractor: (payRate) => <StatusField status={payRate.status} />,
  },
];

// Multi pay rates are pay rates which are defined in a way that at any time, more than one rule can be active for
// that pay rate kind. In other words, adding a new rule for these pay rates does not override existing rules
export const multiPayRateKinds = [
  PayRateKind.BOOST_PAY,
  PayRateKind.FIRST_SHIFT_BONUS,
];

export const waitPayRateType: PayRateType = {
  kind: PayRateKind.WAIT_PAY,
  name: 'Wait Pay',
  shortName: 'Wait Pay',
  description:
    'Wait Pay is paid to scheduled delivery partners only; While in an active workday, ' +
    'delivery partners earn Wait Pay when they are in the MFC geofence waiting for orders.',
  fields: [
    {
      name: 'Wait Pay Rate',
      responseParser: (payRate, responseData) =>
        parsePayRateScheduleSpansFromResponse(
          payRate as PayRateSchedule,
          responseData,
        ),
      extractor: (payRate, locations) => {
        const location = filterLocationById(
          locations,
          parseInt(payRate.locationID, 10),
        );

        const { spans } = payRate as PayRateSchedule;

        if (isEmpty(spans)) {
          return (
            <StringField
              value='-'
              disabled={payRate.status === PayRateStatus.EXPIRED}
            />
          );
        }

        let minRate = spans[0].rate ?? 0;
        let maxRate = spans[0].rate ?? 0;
        spans.forEach((span) => {
          if (!!span.rate && span.rate > maxRate) {
            maxRate = span.rate;
          }
          if (!!span.rate && span.rate < minRate) {
            minRate = span.rate;
          }
        });

        return (
          <StringField
            value={`${parseCentsAsCurrencyString(
              (minRate ?? 0) * 100,
              location?.currency,
            )} - ${parseCentsAsCurrencyString(
              (maxRate ?? 0) * 100,
              location?.currency,
            )}`}
            disabled={payRate.status === PayRateStatus.EXPIRED}
          />
        );
      },
    },
    ...commonPayRateFields,
  ],
  disabled: false,
  template: {
    kind: PayRateKind.WAIT_PAY,
    id: 0,
    startAt: new Date(),
    endAt: {
      valid: false,
    },
    locationID: '',
    spans: flatten(
      [0, 1, 2, 3, 4, 5, 6].map((weekDay) =>
        payRateScheduleSpanRanges.map(
          (range): PayRateScheduleSpan => ({
            dayStart: weekDay,
            dayEnd: weekDay,
            timeStart: range.timeStart,
            timeEnd: range.timeEnd,
          }),
        ),
      ),
    ),
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: '',
  } as PayRate,
  apiUrl: '/pay-rate-schedules',
  responseKey: 'schedules',
  isNullableStatusParam: false,
  isInstantExpireAllowed: false,
  isCreationAllowed: true,
  mocks: mockWaitPays,
  inputFormGenerator: (currentState, setter, isDisabled) => (
    <PayRateScheduleForm
      title='Wait Pay'
      currentState={currentState}
      setter={setter}
      isDisabled={isDisabled}
    />
  ),
  outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
    ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
    end_at: payRateToTransform.endAt.valid
      ? payRateToTransform.endAt.time
      : undefined,
    spans: mergeAdjacentEqualRateSpans(
      (payRateToTransform as PayRateSchedule).spans,
    ).map(
      (span): PayRateScheduleSpanRequest => ({
        rate: Math.round((span.rate ?? 0) * 60 * 100),
        day_start: span.dayStart + Math.floor(span.timeStart / 24),
        day_end: span.dayEnd + Math.floor(span.timeEnd / 24),
        time_start: span.timeStart % 24,
        time_end: span.timeEnd % 24,
      }),
    ),
    pay_type: 'subsidy',
  }),
  overViewGenerator: (_) => <></>,
  shouldRenderComparison: false,
  validator: (payRate) => {
    if (payRate.endAt.time && new Date(payRate.startAt) > payRate.endAt.time) {
      throw new Error('Invalid date range');
    }
  },
  editPermissions: Permission.SET_BASE_RATE,
  variableBasePayCheck: VariableBasePayCheck.NONE,
};

export const payRateTypes: PayRateType[] = (
  [
    {
      kind: PayRateKind.COMMISSION,
      name: 'Base Pay',
      shortName: 'Base Pay',
      description:
        'Base Pay is set by location and includes the commission rate per order.',
      fields: [
        {
          name: 'Commission Rate',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.COMMISSION,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: false,
      isCreationAllowed: true,
      mocks: mockCommissions,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.COMMISSION}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : 'commission',
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          payRate={payRate}
          amountUnit='/order'
          genericPayType={GenericPayRateType.COMMISSION}
        />
      ),
      shouldRenderComparison: true,
      validator: (payRate) => {
        const commission = payRate as GenericPayRate;
        if (
          commission.endAt.time &&
          new Date(commission.startAt) > commission.endAt.time
        ) {
          throw new Error('Invalid date range');
        }
      },
      editPermissions: Permission.SET_BASE_RATE,
      variableBasePayCheck: VariableBasePayCheck.WARNING,
    },
    {
      kind: PayRateKind.SUBSIDY,
      name: 'Minimum Guarantee',
      shortName: 'Minimum Guarantee',
      description: 'Set the minimum guarantee rate per hour.',
      fields: [
        {
          name: 'Minimum Guarantee Rate',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            featureFlags: responseData.feature_flags ?? [],
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.SUBSIDY,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: mockSubsidies,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.SUBSIDY}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        location_id: payRateToTransform.locationID.toString(),
        start_at: payRateToTransform.startAt,
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : 'subsidy',
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          payRate={payRate}
          amountUnit='/hour'
          genericPayType={GenericPayRateType.SUBSIDY}
        />
      ),
      shouldRenderComparison: true,
      validator: (payRate) => {
        const commission = payRate as GenericPayRate;
        if (
          commission.endAt.time &&
          new Date(commission.startAt) > commission.endAt.time
        ) {
          throw new Error('Invalid date range');
        }
      },
      editPermissions: Permission.SET_BASE_RATE,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    {
      kind: PayRateKind.ENGAGED,
      name: 'Engaged Pay (CA only)',
      shortName: 'Engaged Pay',
      description:
        'Engaged Pay Rate defines the engaged pay rate amount ' +
        'for California based locations.',
      fields: [
        {
          name: 'Prop 22 Engaged Rate',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.ENGAGED,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: false,
      isCreationAllowed: true,
      mocks: mockEngagedPayRates,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.ENGAGED}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        location_id: payRateToTransform.locationID.toString(),
        start_at: payRateToTransform.startAt,
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : 'engaged_pay',
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          payRate={payRate}
          amountUnit='/hour'
          genericPayType={GenericPayRateType.ENGAGED}
        />
      ),
      shouldRenderComparison: true,
      validator: (payRate) => {
        const commission = payRate as GenericPayRate;
        if (
          commission.endAt.time &&
          new Date(commission.startAt) > commission.endAt.time
        ) {
          throw new Error('Invalid date range');
        }
      },
      editPermissions: Permission.SET_BASE_RATE,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    {
      kind: PayRateKind.DELAYED_DELIVERY,
      name: 'Delayed Delivery Pay',
      shortName: 'Delayed Delivery',
      description:
        'Delayed Delivery adds an extra amount to the commission after an ' +
        'adjusted time passes since the creation of the order',
      fields: [
        {
          name: 'Delay Minutes (Standard)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.STANDARD,
            );

            return {
              ...payRate,
              standardDelayMinutes: incentive?.delayMinutes ?? 0,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, _) =>
            extractNumberField<DelayedDeliveryPay>(
              payRate,
              'standardDelayMinutes',
            ),
        },
        {
          name: 'Offer Denials (Standard)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.STANDARD,
            );

            return {
              ...payRate,
              standardOfferDenials: incentive?.offerDenials ?? 0,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, _) =>
            extractNumberField<DelayedDeliveryPay>(
              payRate,
              'standardOfferDenials',
            ),
        },
        {
          name: 'Amount (Standard)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.STANDARD,
            );

            return {
              ...payRate,
              standardAmount: (incentive?.amount ?? 0) / 100,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<DelayedDeliveryPay>(
              payRate,
              'standardAmount',
              location,
            );
          },
        },
        {
          name: 'Delay Minutes (FAM20)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.FAM_20,
            );

            return {
              ...payRate,
              fam20DelayMinutes: incentive?.delayMinutes ?? 0,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, _) =>
            extractNumberField<DelayedDeliveryPay>(
              payRate,
              'fam20DelayMinutes',
            ),
        },
        {
          name: 'Amount (FAM20)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.FAM_20,
            );

            return {
              ...payRate,
              fam20Amount: (incentive?.amount ?? 0) / 100,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<DelayedDeliveryPay>(
              payRate,
              'fam20Amount',
              location,
            );
          },
        },
        {
          name: 'Delay Minutes (Starbucks)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.STARBUCKS,
            );

            return {
              ...payRate,
              starbucksDelayMinutes: incentive?.delayMinutes ?? 0,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, _) =>
            extractNumberField<DelayedDeliveryPay>(
              payRate,
              'starbucksDelayMinutes',
            ),
        },
        {
          name: 'Amount (Starbucks)',
          responseParser: (payRate, responseData) => {
            const incentive = parseDelayedDeliveryIncentiveFromResponse(
              responseData,
              DelayedDeliveryType.STARBUCKS,
            );

            return {
              ...payRate,
              starbucksAmount: (incentive?.amount ?? 0) / 100,
            } as DelayedDeliveryPay;
          },
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<DelayedDeliveryPay>(
              payRate,
              'starbucksAmount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.DELAYED_DELIVERY,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        standardAmount: 0,
        standardDelayMinutes: 0,
        standardOfferDenials: 0,
        fam20Amount: 0,
        fam20DelayMinutes: 0,
        starbucksAmount: 0,
        starbucksDelayMinutes: 0,
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: [],
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <DelayedDeliveryFrom
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        location_id: payRateToTransform.locationID.toString(),
        start_at: payRateToTransform.startAt,
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        pay_type: isEditing ? undefined : 'delayed_delivery',
        metadata: delayedDeliveryMetadataOutputTransformer(payRateToTransform),
      }),
      overViewGenerator: (_) => <></>,
      shouldRenderComparison: false,
      validator: (payRate) => {
        const delayedDelivery = payRate as DelayedDeliveryPay;
        if (
          delayedDelivery.standardAmount <= 0 &&
          delayedDelivery.fam20Amount <= 0 &&
          delayedDelivery.starbucksAmount <= 0
        ) {
          throw new Error('At least one amount should be greater than 0');
        }
      },
      editPermissions: Permission.SET_BASE_RATE,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    {
      kind: PayRateKind.BATCH_NORMALIZATION_RULES,
      name: 'DPP Batch Normalization Rules',
      shortName: 'DPP Batch Normalization Rules',
      description: 'Batch normalization rules for DPP Adjustments',
      fields: [
        {
          name: 'Singleton',
          responseParser: (payRate, responseData) => ({
            ...payRate,
            percentagesPerBatchSizes: (
              responseData.batch_normalization_metadata
                ?.percentage_per_batch_sizes ?? []
            ).map(
              (percentages: any) =>
                ({
                  batchSize: percentages.batch_size,
                  percentage: percentages.percentage,
                } as PercentagePerBatchSize),
            ),
          }),
          extractor: (payRate, _) => {
            const batchNormalizationRules = payRate as BatchNormalizationRules;
            const singletonPercentage =
              batchNormalizationRules.percentagesPerBatchSizes.find(
                (percentage) => percentage.batchSize === 1,
              );

            return (
              <StringField
                value={
                  singletonPercentage
                    ? `${singletonPercentage.percentage.toFixed(2)}%`
                    : '-'
                }
                disabled={
                  batchNormalizationRules.status === PayRateStatus.EXPIRED
                }
              />
            );
          },
        },
        {
          name: 'Batch of 2',
          responseParser: (payRate, _) => payRate,
          extractor: (payRate, _) => {
            const batchNormalizationRules = payRate as BatchNormalizationRules;
            const singletonPercentage =
              batchNormalizationRules.percentagesPerBatchSizes.find(
                (percentage) => percentage.batchSize === 2,
              );

            return (
              <StringField
                value={
                  singletonPercentage
                    ? `${singletonPercentage.percentage.toFixed(2)}%`
                    : '-'
                }
                disabled={
                  batchNormalizationRules.status === PayRateStatus.EXPIRED
                }
              />
            );
          },
        },
        {
          name: 'Batch of 3',
          responseParser: (payRate, _) => payRate,
          extractor: (payRate, _) => {
            const batchNormalizationRules = payRate as BatchNormalizationRules;
            const singletonPercentage =
              batchNormalizationRules.percentagesPerBatchSizes.find(
                (percentage) => percentage.batchSize === 3,
              );

            return (
              <StringField
                value={
                  singletonPercentage
                    ? `${singletonPercentage.percentage.toFixed(2)}%`
                    : '-'
                }
                disabled={
                  batchNormalizationRules.status === PayRateStatus.EXPIRED
                }
              />
            );
          },
        },
        {
          name: 'Batch of 4',
          responseParser: (payRate, _) => payRate,
          extractor: (payRate, _) => {
            const batchNormalizationRules = payRate as BatchNormalizationRules;
            const singletonPercentage =
              batchNormalizationRules.percentagesPerBatchSizes.find(
                (percentage) => percentage.batchSize === 4,
              );

            return (
              <StringField
                value={
                  singletonPercentage
                    ? `${singletonPercentage.percentage.toFixed(2)}%`
                    : '-'
                }
                disabled={
                  batchNormalizationRules.status === PayRateStatus.EXPIRED
                }
              />
            );
          },
        },
        {
          name: 'Batch of 5+',
          responseParser: (payRate, _) => payRate,
          extractor: (payRate, _) => {
            const batchNormalizationRules = payRate as BatchNormalizationRules;
            const singletonPercentage =
              batchNormalizationRules.percentagesPerBatchSizes.find(
                (percentage) => percentage.batchSize >= 5,
              );

            return (
              <StringField
                value={
                  singletonPercentage
                    ? `${singletonPercentage.percentage.toFixed(2)}%`
                    : '-'
                }
                disabled={
                  batchNormalizationRules.status === PayRateStatus.EXPIRED
                }
              />
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.BATCH_NORMALIZATION_RULES,
        id: 0,
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        locationID: '',
        percentagesPerBatchSizes: [
          {
            batchSize: 1,
            percentage: 0,
          },
          {
            batchSize: 2,
            percentage: 0,
          },
          {
            batchSize: 3,
            percentage: 0,
          },
          {
            batchSize: 4,
            percentage: 0,
          },
          {
            batchSize: 5,
            percentage: 0,
          },
        ],
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
      } as BatchNormalizationRules,
      apiUrl: '/dpp-adjustment-rules',
      responseKey: 'rules',
      isNullableStatusParam: true,
      isInstantExpireAllowed: false,
      isCreationAllowed: true,
      mocks: mockBatchNormalizationRules,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <BatchNormalizationRulesForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        type: 'batch-normalization',
        metadata: {
          percentage_per_batch_sizes: (
            payRateToTransform as BatchNormalizationRules
          ).percentagesPerBatchSizes.map((percentagePerBatchSize) => ({
            batch_size: percentagePerBatchSize.batchSize,
            percentage: percentagePerBatchSize.percentage,
          })),
        },
      }),
      overViewGenerator: (payRate) => (
        <BatchNormalizationRulesOverview payRate={payRate} />
      ),
      shouldRenderComparison: true,
      validator: (payRate) => {
        const batchNormalizationRules = payRate as BatchNormalizationRules;
        if (batchNormalizationRules.percentagesPerBatchSizes.length !== 5) {
          throw new Error(
            `Expected 5 percentages by batch sizes but found ${batchNormalizationRules.percentagesPerBatchSizes.length}`,
          );
        }
      },
      editPermissions: Permission.SET_DPP_RULE,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    waitPayRateType,
    {
      kind: PayRateKind.WEEKLY_BONUS,
      name: 'Weekly Bonus',
      shortName: 'Weekly Bonus',
      description:
        'Weekly Bonus Pay rewards delivery partners with an extra bonus depending on the number of orders they ' +
        'deliver on a weekly basis. There are 4 tiers to the weekly bonus.',
      fields: [
        ...flatten(
          ['1', '2', '3', '4'].map((index): PayRateField[] => [
            {
              name: `Tier ${index}`,
              responseParser: (payRate, responseData) => {
                const payRateWithOrder =
                  parseNumberFieldFromResponse<WeeklyBonus>(
                    payRate,
                    responseData.metadata,
                    `tier${index}Orders` as keyof WeeklyBonus,
                    `tier_${index}_orders`,
                    false,
                  );
                return {
                  ...parseNumberFieldFromResponse<WeeklyBonus>(
                    payRateWithOrder,
                    responseData.metadata,
                    `tier${index}Amount` as keyof WeeklyBonus,
                    `tier_${index}_amount`,
                    true,
                  ),
                  legacyID: responseData.metadata.legacy_id,
                };
              },
              extractor: (payRate, locations) => {
                const location = filterLocationById(
                  locations,
                  parseInt(payRate.locationID, 10),
                );
                const amount = (payRate as WeeklyBonus)[
                  `tier${index}Amount` as keyof WeeklyBonus
                ] as number;
                const orders = (payRate as WeeklyBonus)[
                  `tier${index}Orders` as keyof WeeklyBonus
                ] as number;

                return (
                  <StringField
                    value={
                      isNumber(amount)
                        ? `${getCurrencySymbolFromLocation(
                            location,
                          )}${amount.toFixed(2)}`
                        : '-'
                    }
                    unit={orders ? `${orders.toString()} orders` : undefined}
                    disabled={payRate.status === PayRateStatus.EXPIRED}
                  />
                );
              },
            },
          ]),
        ),
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.WEEKLY_BONUS,
        id: 0,
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        locationID: '',
        status: PayRateStatus.FUTURE,
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: false,
      mocks: mockWeeklyBonuses,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <WeeklyBonusForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        amount: 0,
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        metadata: {
          legacy_id: payRateToTransform.legacyID,
          tier_1_orders: isNumber(payRateToTransform.tier1Orders)
            ? Math.floor(payRateToTransform.tier1Orders)
            : Number.NaN,
          tier_1_amount: isNumber(payRateToTransform.tier1Amount)
            ? Math.round(payRateToTransform.tier1Amount * 100)
            : Number.NaN,
          tier_2_orders: isNumber(payRateToTransform.tier2Orders)
            ? Math.floor(payRateToTransform.tier2Orders)
            : Number.NaN,
          tier_2_amount: isNumber(payRateToTransform.tier2Amount)
            ? Math.round(payRateToTransform.tier2Amount * 100)
            : Number.NaN,
          tier_3_orders: isNumber(payRateToTransform.tier3Orders)
            ? Math.floor(payRateToTransform.tier3Orders)
            : Number.NaN,
          tier_3_amount: isNumber(payRateToTransform.tier3Amount)
            ? Math.round(payRateToTransform.tier3Amount * 100)
            : Number.NaN,
          tier_4_orders: isNumber(payRateToTransform.tier4Orders)
            ? Math.floor(payRateToTransform.tier4Orders)
            : Number.NaN,
          tier_4_amount: isNumber(payRateToTransform.tier4Amount)
            ? Math.round(payRateToTransform.tier4Amount * 100)
            : Number.NaN,
        },
        pay_type: isEditing ? undefined : GenericPayRateType.WEEKLY_BONUS,
      }),
      overViewGenerator: (payRate) => <WeeklyBonusOverview payRate={payRate} />,
      shouldRenderComparison: true,
      validator: (payRate) => {
        const weeklyBonus = payRate as WeeklyBonus;

        const tierOrdersArray = [
          weeklyBonus.tier1Orders,
          weeklyBonus.tier2Orders,
          weeklyBonus.tier3Orders,
          weeklyBonus.tier4Orders,
        ];
        const tierAmountsArray = [
          weeklyBonus.tier1Amount,
          weeklyBonus.tier2Amount,
          weeklyBonus.tier3Amount,
          weeklyBonus.tier4Amount,
        ];

        if (!areSameIndexesNullOrNotNull(tierOrdersArray, tierAmountsArray)) {
          throw new Error(
            'Tier Order And Tier Amount should have null or not null value for same index',
          );
        }
        if (!isArraySorted(tierOrdersArray)) {
          throw new Error(
            'Value of tier orders should be sorted ascended and tier orders should be not null',
          );
        }
        if (!isArraySorted(tierAmountsArray)) {
          throw new Error(
            'Value of tier amounts should be sorted ascended and tier amounts should be not null',
          );
        }
      },
      editPermissions: Permission.SET_WEEKLY_BONUSES,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
  ] as PayRateType[]
).filter((payRateType) => !payRateType.disabled);

export const boostPayRateType: PayRateType = {
  kind: PayRateKind.BOOST_PAY,
  name: 'Boost Pay',
  shortName: 'Boost Pay',
  description:
    'Boost Pay is used for giving extra incentive to drivers for a specified period time at a given location.' +
    ' Boost pay is a supplemental bonus that is paid out based on orders delivered',
  fields: [
    {
      name: 'Hours',
      responseParser: (payRate, responseData) =>
        ({
          ...payRate,
          startTime: new Date(responseData.start_at),
          endTime: new Date(responseData.end_at),
        } as BoostPay),
      extractor: (payRate) => (
        <StringField
          value={calculateTimeDiff(
            (payRate as BoostPay).startTime,
            (payRate as BoostPay).endTime,
          )}
          disabled={payRate.status === PayRateStatus.EXPIRED}
        />
      ),
    },
    {
      name: 'Event Name',
      responseParser: (payRate, responseData) =>
        ({
          ...payRate,
          name: responseData.metadata.name,
          legacyID: responseData.metadata.legacy_id,
        } as PayRate),
      extractor: (payRate) => (
        <StringField
          value={(payRate as BoostPay).name}
          disabled={payRate.status === PayRateStatus.EXPIRED}
        />
      ),
    },
    {
      name: 'Bonus',
      responseParser: (payRate, responseData) =>
        parseNumberFieldFromResponse<BoostPay>(
          payRate as BoostPay,
          responseData,
          'amount',
          'amount',
          true,
        ),
      extractor: (payRate, locations) => {
        const location = filterLocationById(
          locations,
          parseInt(payRate.locationID, 10),
        );

        return extractPaymentField<BoostPay>(payRate, 'amount', location);
      },
    },
    ...commonPayRateFields,
  ],
  disabled: false,
  template: {
    kind: PayRateKind.BOOST_PAY,
    id: 0,
    startAt: new Date(new Date().setMinutes(0)),
    endAt: {
      time: new Date(new Date().setMinutes(0)),
      valid: true,
    },
    locationID: '',
    amount: 0,
    name: '',
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: '',
    startTime: new Date(new Date().setMinutes(0)),
    endTime: new Date(new Date().setMinutes(0)),
  } as PayRate,
  apiUrl: '/pay-rates',
  responseKey: 'pay_rates',
  isNullableStatusParam: true,
  isInstantExpireAllowed: true,
  isCreationAllowed: false,
  mocks: mockSpecialPays,
  inputFormGenerator: (currentState, setter, isDisabled) => (
    <BoostPayForm
      currentState={currentState}
      setter={setter}
      isDisabled={isDisabled}
    />
  ),
  outputTransformer: (payRateToTransform, timeZone, isEditing) => {
    const startTime = DateTime.fromJSDate(
      (payRateToTransform as BoostPay).startTime,
    ).setZone(timeZone || 'UTC');
    const endTime = DateTime.fromJSDate(
      (payRateToTransform as BoostPay).endTime,
    ).setZone(timeZone || 'UTC');

    return {
      ...defaultOutputTransformer(
        payRateToTransform,
        timeZone,
        isEditing,
        getTimeString(payRateToTransform.startTime.toTimeString()),
      ),
      start_at: DateTime.fromJSDate(payRateToTransform.startAt)
        .setZone(timeZone || 'UTC')
        .set({
          hour: startTime.hour,
          minute: startTime.minute,
          second: 0,
          millisecond: 0,
        })
        .toJSDate(),
      end_at: DateTime.fromJSDate(payRateToTransform.endAt.time)
        .setZone(timeZone || 'UTC')
        .set({
          hour: endTime.hour,
          minute: endTime.minute,
          second: 0,
          millisecond: 0,
        })
        .toJSDate(),
      amount: Math.round(payRateToTransform.amount * 100),
      metadata: {
        legacy_id: payRateToTransform.legacyID,
        name: payRateToTransform.name,
      },
      pay_type: isEditing ? undefined : 'special_event',
    };
  },
  overViewGenerator: (payRate) => <BoostPayOverview payRate={payRate} />,
  shouldRenderComparison: true,
  validator: (_) => {},
  editPermissions: Permission.SET_BOOST_PAY | Permission.SET_SPECIAL_EVENTS,
  variableBasePayCheck: VariableBasePayCheck.INFO,
};

export const specialPayRateTypes: PayRateType[] = (
  [
    {
      kind: PayRateKind.OVERTIME_PAY,
      name: 'Overtime Pay',
      shortName: 'Overtime Pay',
      description:
        'Overtime pay is specific to sites that use W2 employees for delivery partners.',
      fields: [
        {
          name: '8+ Hour Day',
          responseParser: (payRate, responseData) => {
            const parsedOvertime = parseNumberFieldFromResponse<OvertimePay>(
              payRate,
              responseData.metadata,
              'eightHoursMultiplier',
              'eight_hours_multiplier',
              false,
            ) as OvertimePay;

            const isValid = (parsedOvertime.eightHoursMultiplier ?? 0) > 0;

            return {
              ...parsedOvertime,
              eightHoursMultiplier: isValid
                ? parsedOvertime.eightHoursMultiplier
                : undefined,
              eightHoursChecked: isValid,
              legacyID: responseData.metadata.legacy_id,
            };
          },
          extractor: (payRate) =>
            extractMultiplierField<OvertimePay>(
              payRate,
              'eightHoursMultiplier',
            ),
        },
        {
          name: '12+ Hour Day',
          responseParser: (payRate, responseData) => {
            const parsedOvertime = parseNumberFieldFromResponse<OvertimePay>(
              payRate,
              responseData.metadata,
              'twelveHoursMultiplier',
              'twelve_hours_multiplier',
              false,
            ) as OvertimePay;

            const isValid = (parsedOvertime.twelveHoursMultiplier ?? 0) > 0;

            return {
              ...parsedOvertime,
              twelveHoursMultiplier: isValid
                ? parsedOvertime.twelveHoursMultiplier
                : undefined,
              twelveHoursChecked: isValid,
            };
          },
          extractor: (payRate) =>
            extractMultiplierField<OvertimePay>(
              payRate,
              'twelveHoursMultiplier',
            ),
        },
        {
          name: '40+ Hour Week',
          responseParser: (payRate, responseData) => {
            const parsedOvertime = parseNumberFieldFromResponse<OvertimePay>(
              payRate,
              responseData.metadata,
              'fortyHoursMultiplier',
              'forty_hours_multiplier',
              false,
            ) as OvertimePay;

            const isValid = (parsedOvertime.fortyHoursMultiplier ?? 0) > 0;

            return {
              ...parsedOvertime,
              fortyHoursMultiplier: isValid
                ? parsedOvertime.fortyHoursMultiplier
                : undefined,
              fortyHoursChecked: isValid,
            };
          },
          extractor: (payRate) =>
            extractMultiplierField<OvertimePay>(
              payRate,
              'fortyHoursMultiplier',
            ),
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.OVERTIME_PAY,
        id: 0,
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: false,
        },
        locationID: '',
        status: PayRateStatus.FUTURE,
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: false,
      isCreationAllowed: true,
      mocks: mockOvertimePays,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <OvertimePayForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: 0,
        pay_type: isEditing ? undefined : GenericPayRateType.OVERTIME_PAY,
        metadata: {
          eight_hours_multiplier: payRateToTransform.eightHoursMultiplier,
          twelve_hours_multiplier: payRateToTransform.twelveHoursMultiplier,
          forty_hours_multiplier: payRateToTransform.fortyHoursMultiplier,
          legacy_id: payRateToTransform.legacyID,
        },
      }),
      overViewGenerator: (payRate) => <OvertimePayOverview payRate={payRate} />,
      shouldRenderComparison: true,
      validator: (_) => {},
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    boostPayRateType,
    {
      kind: PayRateKind.TIER2_DELIVERIES,
      name: 'Tier 2 Deliveries',
      shortName: 'Tier 2 Deliveries',
      description:
        'Tier 2 Deliveries are a flat rate commission added to eligible orders that are delivered in a Tier 2 zone.',
      fields: [
        {
          name: 'Amount',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.TIER2_DELIVERIES,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: mockTier2DeliveryPays,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.TIER2_DELIVERIES}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : GenericPayRateType.TIER2_DELIVERIES,
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          genericPayType={GenericPayRateType.TIER2_DELIVERIES}
          payRate={payRate}
        />
      ),
      shouldRenderComparison: true,
      validator: (_) => {},
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    {
      kind: PayRateKind.HAZARD_PAY,
      name: 'Hazard Pay',
      shortName: 'Hazard Pay',
      description:
        'Hazard pay is a flat rate commission that is added to the standard commission on every order for a given delivery location.',
      fields: [
        {
          name: 'Amount',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.HAZARD_PAY,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: mockHazardPays,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.HAZARD_PAY}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : GenericPayRateType.HAZARD_PAY,
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          genericPayType={GenericPayRateType.HAZARD_PAY}
          payRate={payRate}
        />
      ),
      shouldRenderComparison: true,
      validator: (_) => {},
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.INFO,
    },
    {
      kind: PayRateKind.TOBACCO_BONUS,
      name: 'Tobacco Bonus',
      shortName: 'Tobacco Bonus',
      description:
        'Tobacco Bonus rates are a flat commission added to an order payout when the order contains a tobacco ' +
        'product(i.e. cigarettes, cigars, chewing tobacco, etc.)',
      fields: [
        {
          name: 'Amount',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.TOBACCO_BONUS,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: mockTobaccoPays,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.TOBACCO_BONUS}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : GenericPayRateType.TOBACCO_BONUS,
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          genericPayType={GenericPayRateType.TOBACCO_BONUS}
          payRate={payRate}
        />
      ),
      shouldRenderComparison: true,
      validator: (_) => {},
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.INFO,
    },
    {
      kind: PayRateKind.ALCOHOL_PAY,
      name: 'Alcohol Pay',
      shortName: 'Alcohol Pay',
      description:
        'Alcohol Pay rates are a flat commission added to an order payout when the order contains an alcohol ' +
        'product',
      fields: [
        {
          name: 'Amount',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<GenericPayRate>(
              payRate as GenericPayRate,
              responseData,
              'amount',
              'amount',
              true,
            ),
            metadata: parsePayRateMetadataFromResponse(responseData),
          }),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<GenericPayRate>(
              payRate,
              'amount',
              location,
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.ALCOHOL_PAY,
        id: 0,
        locationID: '',
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: false,
        },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        amount: 0,
        featureFlags: [],
        metadata: {},
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: mockAlcoholPays,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <GenericPayRateForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
          genericPayType={GenericPayRateType.ALCOHOL_PAY}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : GenericPayRateType.ALCOHOL_PAY,
        metadata: payRateMetadataOutputTransformer(payRateToTransform.metadata),
      }),
      overViewGenerator: (payRate) => (
        <GenericPayRateOverview
          genericPayType={GenericPayRateType.ALCOHOL_PAY}
          payRate={payRate}
        />
      ),
      shouldRenderComparison: true,
      validator: (_) => {},
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.INFO,
    },
    {
      kind: PayRateKind.FIRST_SHIFT_BONUS,
      name: 'First Shift Bonus Program',
      shortName: 'First Shift Bonus',
      description:
        'First shift bonus is used for defining bonus payment for first week of drivers.',
      fields: [
        {
          name: 'Bonus',
          responseParser: (payRate, responseData) =>
            parseNumberFieldFromResponse<FirstShiftBonus>(
              payRate as FirstShiftBonus,
              responseData,
              'amount',
              'amount',
              true,
            ),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<FirstShiftBonus>(
              payRate,
              'amount',
              location,
            );
          },
        },
        {
          name: 'Minimum Orders',
          responseParser: (payRate, responseData) => ({
            ...parseNumberFieldFromResponse<FirstShiftBonus>(
              payRate as FirstShiftBonus,
              responseData.metadata,
              'minOrders',
              'min_orders',
              false,
            ),
            legacyID: responseData.metadata?.legacy_id,
          }),

          extractor: (payRate) =>
            extractNumberField<FirstShiftBonus>(payRate, 'minOrders'),
        },
        {
          name: 'Minimum Hours',
          responseParser: (payRate, responseData) =>
            parseNumberFieldFromResponse<FirstShiftBonus>(
              payRate as FirstShiftBonus,
              responseData.metadata,
              'minHours',
              'min_hours',
              false,
            ),
          extractor: (payRate) =>
            extractNumberField<FirstShiftBonus>(payRate, 'minHours'),
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.FIRST_SHIFT_BONUS,
        id: 0,
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: true,
        },
        locationID: '',
        commission: 0,
        subsidy: 0,
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: false,
      isCreationAllowed: false,
      mocks: mockFirstShiftBonuses,
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <FirstShiftBonusForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.time,
        amount: Math.round(payRateToTransform.amount * 100),
        pay_type: isEditing ? undefined : 'first_shift_bonus',
        metadata: {
          legacy_id: payRateToTransform.legacyID,
          min_hours: payRateToTransform.minHours,
          min_orders: payRateToTransform.minOrders,
        },
      }),
      overViewGenerator: (payRate) => (
        <FirstShiftBonusOverview payRate={payRate} />
      ),
      shouldRenderComparison: true,
      validator: (payRate) => {
        const firstShiftBonus = payRate as FirstShiftBonus;
        if (
          firstShiftBonus.endAt.time &&
          new Date(firstShiftBonus.startAt) > firstShiftBonus.endAt.time
        ) {
          throw new Error('Invalid date range');
        }
      },
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    {
      kind: PayRateKind.RETURN_COC,
      name: 'Return CoC Pay',
      shortName: 'Return CoC',
      description:
        'Return CoC pay defines the rates to be paid to DPs returning items in cancelled orders',
      fields: [
        {
          name: 'Tiers',
          responseParser: (payRate, responseData) => ({
            ...payRate,
            pay: (responseData.metadata?.pay ?? []).map(
              (setting: any): ReturnCocPaySetting => ({
                dropOffs: setting.drop_offs,
                amount: setting.amount / 100,
              }),
            ),
          }),
          extractor: (payRate) => {
            const returnCoc = payRate as ReturnCoc;
            return (
              <StringField
                value={`${returnCoc.pay.length} Tier${
                  returnCoc.pay.length > 1 ? 's' : ''
                }`}
                disabled={returnCoc.status === PayRateStatus.EXPIRED}
              />
            );
          },
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.RETURN_COC,
        id: 0,
        startAt: new Date(),
        endAt: {
          valid: false,
        },
        locationID: '',
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        pay: [
          {
            dropOffs: 1,
            amount: 0,
          },
        ] as ReturnCocPaySetting[],
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: false,
      isCreationAllowed: true,
      mocks: [],
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <ReturnCocForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => ({
        ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
        end_at: payRateToTransform.endAt.valid
          ? payRateToTransform.endAt.time
          : undefined,
        amount: 0,
        pay_type: isEditing ? undefined : GenericPayRateType.RETURN_COC,
        metadata: {
          pay: (payRateToTransform as ReturnCoc).pay.map((setting) => ({
            drop_offs: setting.dropOffs,
            amount: Math.round(setting.amount * 100),
          })),
        },
      }),
      overViewGenerator: (_) => <></>,
      shouldRenderComparison: false,
      validator: (payRate) => {
        if (
          payRate.endAt.time &&
          new Date(payRate.startAt) > payRate.endAt.time
        ) {
          throw new Error('Invalid date range');
        }
      },
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
    {
      kind: PayRateKind.REIMBURSEMENT_PAY,
      name: 'Reimbursement Pay',
      shortName: 'Reimbursement Pay',
      description:
        'Reimbursement pay is used for defining non taxable payments to W2 employees.',
      fields: [
        {
          name: 'Name',
          responseParser: (payRate, responseData) => ({
            ...payRate,
            name: responseData.metadata.name,
          }),

          extractor: (payRate) => (
            <StringField
              value={(payRate as ReimbursementPay).name}
              disabled={payRate.status === PayRateStatus.EXPIRED}
            />
          ),
        },
        {
          name: 'Amount',
          responseParser: (payRate, responseData) =>
            parseNumberFieldFromResponse<ReimbursementPay>(
              payRate as ReimbursementPay,
              responseData,
              'amount',
              'amount',
              true,
            ),
          extractor: (payRate, locations) => {
            const location = filterLocationById(
              locations,
              parseInt(payRate.locationID, 10),
            );

            return extractPaymentField<ReimbursementPay>(
              payRate,
              'amount',
              location,
            );
          },
        },
        {
          name: 'Rate',
          responseParser: (payRate, responseData) => ({
            ...payRate,
            rate: responseData.metadata.rate,
          }),

          extractor: (payRate) => (
            <StringField
              value={getReimbursementRateName(
                (payRate as ReimbursementPay).rate,
              )}
              disabled={payRate.status === PayRateStatus.EXPIRED}
            />
          ),
        },
        {
          name: 'Is Taxable',
          responseParser: (payRate, responseData) => ({
            ...payRate,
            isTaxable: responseData.metadata.is_taxable,
          }),

          extractor: (payRate) => (
            <StringField
              value={(payRate as ReimbursementPay).isTaxable ? 'Yes' : 'No'}
              disabled={payRate.status === PayRateStatus.EXPIRED}
            />
          ),
        },
        ...commonPayRateFields,
      ],
      disabled: false,
      template: {
        kind: PayRateKind.REIMBURSEMENT_PAY,
        id: 0,
        startAt: new Date(),
        endAt: {
          time: new Date(),
          valid: true,
        },
        locationID: '',
        name: '',
        amount: 0,
        rate: ReimbursementRate.PER_HOUR,
        isTaxable: false,
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
      } as PayRate,
      apiUrl: '/pay-rates',
      responseKey: 'pay_rates',
      isNullableStatusParam: true,
      isInstantExpireAllowed: true,
      isCreationAllowed: true,
      mocks: [],
      inputFormGenerator: (currentState, setter, isDisabled) => (
        <ReimbursementPayForm
          currentState={currentState}
          setter={setter}
          isDisabled={isDisabled}
        />
      ),
      outputTransformer: (payRateToTransform, timeZone, isEditing) => {
        const reimbursementPay = payRateToTransform as ReimbursementPay;

        return {
          ...defaultOutputTransformer(payRateToTransform, timeZone, isEditing),
          end_at: payRateToTransform.endAt.valid
            ? payRateToTransform.endAt.time
            : undefined,
          amount: Math.round(payRateToTransform.amount * 100),
          pay_type: isEditing
            ? undefined
            : GenericPayRateType.REIMBURSEMENT_PAY,
          metadata: {
            rate: reimbursementPay.rate,
            is_taxable: reimbursementPay.isTaxable,
            name: reimbursementPay.name,
          },
        };
      },
      overViewGenerator: (_) => <></>,
      shouldRenderComparison: false,
      validator: (payRate) => {
        if (
          payRate.endAt.time &&
          new Date(payRate.startAt) > payRate.endAt.time
        ) {
          throw new Error('Invalid date range');
        }
      },
      editPermissions: Permission.SET_SPECIAL_EVENTS,
      variableBasePayCheck: VariableBasePayCheck.NONE,
    },
  ] as PayRateType[]
).filter((specialPayRateType) => !specialPayRateType.disabled);

export const newPayRateStepKinds: NewPayRateStepKind[] = [
  NewPayRateStepKind.SELECT_RATE_TYPE,
  NewPayRateStepKind.SET_RULES,
  NewPayRateStepKind.CONFIRM,
];

export const payRateStatusPriorities: { [key in PayRateStatus]: number } = {
  [PayRateStatus.FUTURE]: 3,
  [PayRateStatus.ACTIVE]: 2,
  [PayRateStatus.EXPIRED]: 1,
};
