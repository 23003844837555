import { Box, Tooltip } from '@mui/material';

interface SegmentFilterTooltipProps {
  title: string;
  children: (JSX.Element | false | undefined)[] | JSX.Element;
}

const SegmentFilterTooltip = (props: SegmentFilterTooltipProps) => (
  <Tooltip placement='left' title={props.title}>
    <Box component='div' sx={{ display: 'flex', flexDirection: 'column' }}>
      {props.children}
    </Box>
  </Tooltip>
);

export default SegmentFilterTooltip;
