import { isArray } from 'lodash';
import {
  ManualRestrictedAccountToAdd,
  RestrictedAccount,
} from '../interfaces/RestrictedAccount';
import api from './api';
import { validateCSV, validateRestrictedAccount } from './Validators';

const apiUrl = '/restricted-driver';

export const getRestrictedAccounts = async (): Promise<RestrictedAccount[]> => {
  const response = await api.get(apiUrl);
  if (!response.data) {
    return [];
  }

  return response.data.map(
    (restrictedAccount: any) =>
      <RestrictedAccount>{
        id: restrictedAccount.DriverID,
        DriverID: parseInt(restrictedAccount.DriverID, 10),
        Email: restrictedAccount.Email,
        Name: restrictedAccount.Name,
        RestrictionType: restrictedAccount.RestrictionType,
        LastUpdatedAt: restrictedAccount.LastUpdatedAt,
        LastUpdatedBy: restrictedAccount.LastUpdatedBy,
      },
  );
};

export const getRestrictionTypes = async (): Promise<string[]> => {
  const url = `${apiUrl}/restriction-types`;
  const response = await api.get(url);
  if (!response.data) {
    return [];
  }

  if (!isArray(response.data)) {
    throw new Error(
      'Could not get adjustment types: invalid response data (not array)',
    );
  }

  return response.data as string[];
};

export const addRestrictedAccount = async (
  restrictedAccount: ManualRestrictedAccountToAdd,
): Promise<void> => {
  try {
    validateRestrictedAccount(restrictedAccount);
  } catch (error: any) {
    throw new Error(
      `Could not create restricted account due to validation error: ${error.message}`,
    );
  }
  try {
    await api.post(apiUrl, restrictedAccount);
  } catch (error: any) {
    throw new Error(`Could not create restricted account: ${error.message}`);
  }
};

export const uploadRestrictedAccountCSV = async (
  file: File | undefined,
): Promise<void> => {
  try {
    validateCSV(file);
  } catch (error: any) {
    throw new Error(
      `Could not send csv request due to validation error: ${error.message}`,
    );
  }
  try {
    const bodyFormData = new FormData();
    if (file) {
      bodyFormData.set('file', file, file?.name);
    }
    await api.post(`${apiUrl}/csv-upload`, bodyFormData);
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const enableStripeAccountByDriverId = async (
  driverId: string,
): Promise<void> => {
  try {
    await api.delete(`${apiUrl}/${driverId}`);
  } catch (error: any) {
    throw new Error(`Could not enable Stripe account: ${error.message}`);
  }
};
