import { DateTime } from 'luxon';
import { convertLocalToDeliveryZone } from '../../utilities/Dates';

export interface BatchNormalizationSettings {
  id: number;
  locationID: string;
  timeRateAdjustmentByBatchSize: { [key: string]: number };
  startAt: Date;
  endAt?: Date;
  lastUpdatedBy: string;
}

export const batchNormalizationSettingsFromResponse = (
  responseData: any,
): BatchNormalizationSettings => ({
  id: responseData.id,
  locationID: responseData.location_id,
  timeRateAdjustmentByBatchSize:
    responseData.time_rate_adjustment_by_batch_size ?? {},
  startAt: new Date(responseData.start_at),
  endAt: responseData.end_at ? new Date(responseData.end_at) : undefined,
  lastUpdatedBy: responseData.last_updated_by ?? responseData.created_by,
});

export const batchNormalizationSettingsOutputTransformer = (
  locationIDs: string[],
  settings: BatchNormalizationSettings,
) => {
  const startAt = convertLocalToDeliveryZone(
    'America/Los_Angeles',
    DateTime.fromJSDate(settings.startAt),
    9,
    30,
  );
  const endAt = settings.endAt
    ? convertLocalToDeliveryZone(
        'America/Los_Angeles',
        DateTime.fromJSDate(settings.endAt),
        9,
        30,
      )
    : undefined;

  return locationIDs.map((locationID) => ({
    point_in_time_unique_key: locationID,
    start_at: startAt,
    end_at: endAt,
    metadata: settings.timeRateAdjustmentByBatchSize,
  }));
};
