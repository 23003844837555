import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { MissionBreakdown } from '../../../../interfaces/PayHistory/MissionBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  missionAcceptedAtNodeID,
  missionEndAtLocalNodeID,
  missionInvitedAtNodeID,
  missionNameNodeID,
  missionOrdersMustContainNodeID,
  missionProgressNodeID,
  missionRewardNodeID,
  missionStartAtLocalNodeID,
  missionTypeNodeID,
} from '../../../../variables/PayHistory';
import { formatDateTime } from '../../../../utilities/Dates';
import MissionRewardSummaryNode from './MissionRewardSummaryNode';
import MissionProgressSummaryNode from './MissionProgressSummaryNode';

interface MissionBreakdownNodeProps {
  id: string;
  index: number;
  missionBreakdown: MissionBreakdown;
  currency: string;
  timezone: string;
}

const MissionBreakdownNode = (props: MissionBreakdownNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.index.toString()} {${Object.keys(
            props.missionBreakdown,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, missionNameNodeID])}
        name='Name'
        value={props.missionBreakdown.name}
      />
      <SingleTextNode
        id={composeNodeID([props.id, missionTypeNodeID])}
        name='Type'
        value={props.missionBreakdown.type}
      />
      <SingleTextNode
        id={composeNodeID([props.id, missionInvitedAtNodeID])}
        name='Invited At'
        value={formatDateTime(props.missionBreakdown.invitedAt, props.timezone)}
      />
      <SingleTextNode
        id={composeNodeID([props.id, missionAcceptedAtNodeID])}
        name='Accepted At'
        value={
          props.missionBreakdown.acceptedAt
            ? formatDateTime(props.missionBreakdown.acceptedAt, props.timezone)
            : '-'
        }
      />
      <SingleTextNode
        id={composeNodeID([props.id, missionStartAtLocalNodeID])}
        name='Start At (Local)'
        value={formatDateTime(
          props.missionBreakdown.startAtLocal,
          props.timezone,
        )}
      />
      <SingleTextNode
        id={composeNodeID([props.id, missionEndAtLocalNodeID])}
        name='End At (Local)'
        value={
          props.missionBreakdown.endAtLocal
            ? formatDateTime(props.missionBreakdown.endAtLocal, props.timezone)
            : '-'
        }
      />
      {!isEmpty(props.missionBreakdown.ordersMustContain) && (
        <SingleTextNode
          id={composeNodeID([props.id, missionOrdersMustContainNodeID])}
          name='Orders Must Contain'
          value={`[${props.missionBreakdown.ordersMustContain.join(', ')}]`}
        />
      )}
      <MissionRewardSummaryNode
        id={composeNodeID([props.id, missionRewardNodeID])}
        missionRewardSummary={props.missionBreakdown.reward}
        currency={props.currency}
      />
      <MissionProgressSummaryNode
        id={composeNodeID([props.id, missionProgressNodeID])}
        name='All Time Progress'
        missionProgressSummary={props.missionBreakdown.allTimeProgress}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MissionBreakdownNode;
