import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { ModifierDialogTabKind } from '../../interfaces/Common';

interface ConfirmInclusionListModificationDialogProps {
  open: boolean;
  includedValues: string[];
  modificationKind: ModifierDialogTabKind;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmInclusionListModificationDialog = (
  props: ConfirmInclusionListModificationDialogProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const getOperationName = (): string => {
    switch (props.modificationKind) {
      case ModifierDialogTabKind.INSERT:
        return 'insert';
      case ModifierDialogTabKind.REMOVE:
        return 'expire';
      case ModifierDialogTabKind.UPDATE:
        return 'update';
      default:
        return 'modify';
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Modify Inclusion List
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography className={classes.messageText}>
            {`Are you sure you want to ${getOperationName()}`}
            <Box className={classes.boldMessageText}>
              {props.includedValues.length === 1
                ? `the included value ${props.includedValues[0]}`
                : `${props.includedValues.length} values`}
            </Box>
            ?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          onClick={props.onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  messageText: {
    fontSize: 14,
    color: sharedColors.gray7,
  },
  boldMessageText: {
    fontWeight: 'bold',
    display: 'inline',
    marginLeft: theme.spacing(0.5),
  },
}));

export default ConfirmInclusionListModificationDialog;
