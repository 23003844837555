import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { createRef, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  getPushNotificationCategoryName,
  getPushNotificationTemplate,
} from '../../utilities/PushNotification';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { requiredErrorMessage } from '../../utilities/Constants';
import { PushNotificationCategory } from '../../interfaces/PushNotification/PushNotification';
import {
  PushNotificationEmployeeType,
  PushNotificationRequirementKind,
} from '../../interfaces/PushNotification/PushNotificationToCreate';
import DriverInput from './DriverInput';
import LocationInput from './LocationInput';
import { createPushNotification } from '../../services/pushNotifications';
import { ConfirmDialog } from '../NewPayRateDialog/ConfirmDialog';
import BlockingDriverIDsDialog from './BlockingDriverIDsDialog';

interface NewPushNotificationSidebarProps {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}

const NewPushNotificationSidebar = (props: NewPushNotificationSidebarProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const validatorRef = createRef<ValidatorForm>();

  const [currentState, setCurrentState] = useState(
    getPushNotificationTemplate(),
  );
  const [invalidForm, setInvalidForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [blockingDriverIDs, setBlockingDriverIDs] = useState<string[]>([]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidForm(!isValid);
    });
  }, [currentState]);

  useEffect(() => {
    setCurrentState(getPushNotificationTemplate());
  }, [props.open]);

  const getRequirementKindName = (
    requirementKind: PushNotificationRequirementKind,
  ): string => {
    switch (requirementKind) {
      case PushNotificationRequirementKind.LOCATION:
        return 'Location / Metros';
      case PushNotificationRequirementKind.DRIVER_ID:
        return 'DP IDs';
      default:
        return 'Unknown';
    }
  };

  const getRequirementForm = (
    requirementKind: PushNotificationRequirementKind,
  ): JSX.Element => {
    switch (requirementKind) {
      case PushNotificationRequirementKind.LOCATION:
        return (
          <LocationInput
            currentState={currentState}
            setCurrentState={setCurrentState}
          />
        );
      case PushNotificationRequirementKind.DRIVER_ID:
        return (
          <DriverInput
            currentState={currentState}
            setCurrentState={setCurrentState}
          />
        );
      default:
        return <></>;
    }
  };

  const handleSubmit = () => {
    setShowConfirmDialog(false);
    setLoading(true);
    createPushNotification(currentState)
      .then((driverIDs) => {
        if (isEmpty(driverIDs)) {
          toast.success('Successfully pushed notification');
          props.onDone();
        } else {
          setBlockingDriverIDs(driverIDs);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeTitle = (e: any) => {
    setCurrentState({ ...currentState, title: e.target.value ?? '' });
  };

  const handleChangeMessage = (e: any) => {
    setCurrentState({ ...currentState, message: e.target.value ?? '' });
  };

  const handleSelectEmployeeType = (
    e: SelectChangeEvent<PushNotificationEmployeeType>,
  ) => {
    setCurrentState({
      ...currentState,
      employeeType: e.target.value as PushNotificationEmployeeType,
    });
  };

  const handleSelectCategory = (
    e: SelectChangeEvent<PushNotificationCategory>,
  ) => {
    setCurrentState({
      ...currentState,
      category: e.target.value as PushNotificationCategory,
    });
  };

  const handleChangeRequirementKind = (e: any) => {
    setCurrentState({
      ...currentState,
      requirementKind:
        e.target.value ?? PushNotificationRequirementKind.DRIVER_ID,
    });
  };

  const invalidRequirements =
    (currentState.requirementKind ===
      PushNotificationRequirementKind.DRIVER_ID &&
      isEmpty(currentState.driverIDs)) ||
    (currentState.requirementKind ===
      PushNotificationRequirementKind.LOCATION &&
      isEmpty(currentState.locationIDs) &&
      isEmpty(currentState.metroIDs));

  return (
    <Drawer open={props.open} onClose={props.onClose} anchor='right'>
      <Waiting open={loading} />
      <BlockingDriverIDsDialog
        driverIDs={blockingDriverIDs}
        onClose={() => setBlockingDriverIDs([])}
      />
      <ConfirmDialog
        title='Are you sure?'
        content={
          currentState.category === PushNotificationCategory.GENERIC
            ? 'REMINDER: Delivery partners cannot opt out of these notifications, so they should only be used for important, time sensitive communications, such as site closures or severe weather. Do you want to continue?'
            : 'Push notification will be sent to the applicable DPs. Do you want to proceed?'
        }
        okText='Confirm'
        cancelText='Cancel'
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleSubmit}
        color='primary'
      />
      <ValidatorForm
        instantValidate
        onSubmit={() => setShowConfirmDialog(true)}
        onError={() => setInvalidForm(true)}
        ref={validatorRef}
      >
        <Box component='div' className={classes.body}>
          <Typography className={multiClass([sharedClasses.h6, classes.title])}>
            New Push Notification
          </Typography>
          <Divider />
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([
                classes.sectionLabel,
                sharedClasses.subtitle1,
              ])}
            >
              Set Title, Message and Category
            </Typography>
            <Link
              rel='noopener noreferrer'
              href='https://docs.google.com/document/d/1j730w-qyVBWD_8Smkm6DPFpJuq3KOzL5cCv8JwP4i8s/edit'
              target='_blank'
              className={classes.templateLink}
            >
              Link to templates ↗
            </Link>
            <TextValidator
              label='Title'
              variant='outlined'
              fullWidth
              name='title'
              value={currentState.title}
              onChange={handleChangeTitle}
              validators={['required']}
              errorMessages={[requiredErrorMessage]}
              className={classes.titleMessageTextField}
            />
            <TextValidator
              label='Message'
              variant='outlined'
              fullWidth
              InputProps={{
                multiline: true,
              }}
              name='message'
              value={currentState.message}
              onChange={handleChangeMessage}
              validators={['required']}
              errorMessages={[requiredErrorMessage]}
              className={classes.titleMessageTextField}
            />
            <FormControl
              size='small'
              variant='outlined'
              className={classes.menuDropdown}
            >
              <InputLabel className={classes.dropDownLabel}>
                Category
              </InputLabel>
              <Select
                id='category-select'
                onChange={handleSelectCategory}
                value={currentState.category}
              >
                {Object.keys(PushNotificationCategory).map((category) => (
                  <MenuItem
                    value={
                      PushNotificationCategory[
                        category as keyof typeof PushNotificationCategory
                      ]
                    }
                  >
                    {getPushNotificationCategoryName(
                      PushNotificationCategory[
                        category as keyof typeof PushNotificationCategory
                      ],
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size='small'
              variant='outlined'
              className={classes.menuDropdown}
            >
              <InputLabel className={classes.dropDownLabel}>
                Employee Type
              </InputLabel>
              <Select
                id='employee-type-select'
                onChange={handleSelectEmployeeType}
                value={currentState.employeeType}
              >
                {Object.keys(PushNotificationEmployeeType).map(
                  (employeeTypeName) => (
                    <MenuItem
                      value={
                        PushNotificationEmployeeType[
                          employeeTypeName as keyof typeof PushNotificationEmployeeType
                        ]
                      }
                    >
                      {
                        PushNotificationEmployeeType[
                          employeeTypeName as keyof typeof PushNotificationEmployeeType
                        ]
                      }
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([
                classes.sectionLabel,
                sharedClasses.subtitle1,
              ])}
            >
              Set Requirements
            </Typography>
            <FormControl component='fieldset'>
              <RadioGroup
                value={currentState.requirementKind}
                onChange={handleChangeRequirementKind}
              >
                {Object.keys(PushNotificationRequirementKind).map(
                  (kindName) => {
                    const requirementKind =
                      PushNotificationRequirementKind[
                        kindName as keyof typeof PushNotificationRequirementKind
                      ];

                    return [
                      <FormControlLabel
                        key={kindName}
                        value={requirementKind}
                        control={<Radio id={kindName} color='primary' />}
                        label={
                          <Typography className={sharedClasses.body1}>
                            {getRequirementKindName(requirementKind)}
                          </Typography>
                        }
                      />,
                      currentState.requirementKind === requirementKind ? (
                        getRequirementForm(requirementKind)
                      ) : (
                        <></>
                      ),
                    ];
                  },
                )}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box component='div' className={classes.verticalSpacer} />
          <Box component='div' className={classes.actionsRow}>
            <Box component='div' className={classes.horizontalSpacer} />
            <Button
              variant='contained'
              color='inherit'
              onClick={props.onClose}
              className={multiClass([
                sharedClasses.buttonText,
                classes.actionButton,
              ])}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={invalidForm || invalidRequirements}
              type='submit'
              className={multiClass([
                sharedClasses.buttonText,
                classes.actionButton,
              ])}
            >
              Push Now
            </Button>
          </Box>
        </Box>
      </ValidatorForm>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    title: {
      color: sharedColors.gray6,
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3.5),
      background: sharedColors.gray2,
    },
    body: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(3.5),
    },
    sectionLabel: {
      marginBottom: theme.spacing(2.5),
    },
    templateLink: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(1),
    },
    titleMessageTextField: {
      minWidth: 428,
      marginBottom: theme.spacing(1.5),
    },
    menuDropdown: {
      minWidth: 250,
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    actionsRow: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    actionButton: {
      marginLeft: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    verticalSpacer: {
      display: 'flex',
      flexGrow: 1,
    },
    overlineTitle: {
      color: sharedColors.gray5,
      marginTop: theme.spacing(1),
      fontSize: 12,
    },
    dropDownLabel: {
      backgroundColor: sharedColors.white,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  });
});

export default NewPushNotificationSidebar;
