import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { MissionFormProps } from '../../../../interfaces/Mission/Mission';
import { sharedColors, useSharedStyles } from '../../../../utilities/Styles';
import { MissionRewardTierType } from '../../../../interfaces/Mission/MissionReward';
import MissionNumberInput from './MissionNumberInput';
import { multiClass } from '../../../../utilities/Extensions';

const BonusMissionForm = (props: MissionFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const handleChangeTierCount = (newCount: number, index: number) => {
    const newTiers = props.currentState.reward.tiers.slice();
    newTiers[index].count = newCount;

    props.setCurrentState({
      ...props.currentState,
      reward: { ...props.currentState.reward, tiers: newTiers },
    });
  };

  const handleChangeTierAmount = (newAmount: number, index: number) => {
    const newTiers = props.currentState.reward.tiers.slice();
    newTiers[index].pay = newAmount;

    props.setCurrentState({
      ...props.currentState,
      reward: { ...props.currentState.reward, tiers: newTiers },
    });
  };

  const handleAddNewTier = () => {
    props.setCurrentState({
      ...props.currentState,
      requirements: {
        ...props.currentState.requirements,
        metrics: {},
      },
      reward: {
        ...props.currentState.reward,
        tiers: [...props.currentState.reward.tiers, { count: 0, pay: 0 }],
      },
    });
  };

  const handleDeleteTier = (index: number) => {
    const newTiers = [
      ...props.currentState.reward.tiers.slice(0, index),
      ...props.currentState.reward.tiers.slice(index + 1),
    ];

    props.setCurrentState({
      ...props.currentState,
      requirements: {
        ...props.currentState.requirements,
        metrics: {},
      },
      reward: { ...props.currentState.reward, tiers: newTiers },
    });
  };

  const tierName =
    props.currentState.reward.tierType === MissionRewardTierType.TRIP
      ? 'Trips'
      : 'Orders';

  return (
    <Box
      component='div'
      className={multiClass(
        props.applyPadding
          ? [classes.body, classes.extraPadding]
          : [classes.body],
      )}
    >
      <Grid container spacing={2} columns={9} className={classes.gridContainer}>
        {props.currentState.reward.tiers.map((tier, i) => [
          <Grid item xs={2} className={classes.gridItem}>
            <Typography className={classes.tierLabel}>
              {`Tier ${(i + 1).toString()}`}
            </Typography>
          </Grid>,
          <Grid item xs={3} className={classes.gridItem}>
            <MissionNumberInput
              value={tier.count}
              onChange={(newCount) => handleChangeTierCount(newCount, i)}
              required
              minimumValue={1}
              label={tierName}
            />
          </Grid>,
          <Grid item xs={3} className={classes.gridItem}>
            <MissionNumberInput
              value={tier.pay}
              onChange={(newAmount) => handleChangeTierAmount(newAmount, i)}
              required
              acceptFloat
              minimumValue={0}
              label='Bonus'
            />
          </Grid>,
          <Grid item xs={1} className={classes.gridItem}>
            {props.currentState.reward.tiers.length > 1 && (
              <IconButton
                onClick={() => handleDeleteTier(i)}
                className={classes.deleteButton}
              >
                <CancelIcon fontSize='small' />
              </IconButton>
            )}
          </Grid>,
        ])}
        <Grid item xs={3}>
          <Button
            color='primary'
            startIcon={<AddCircleIcon />}
            onClick={handleAddNewTier}
            className={multiClass([
              sharedClasses.buttonText,
              classes.addButton,
            ])}
          >
            Add tier
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      backgroundColor: sharedColors.gray1,
      flexGrow: 1,
    },
    extraPadding: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(-3.5),
    },
    gridContainer: {
      width: 350,
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    tierLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '11px',
      lineHeight: '13px',
    },
    addButton: {
      padding: 0,
    },
    deleteButton: {
      color: sharedColors.statusRed,
      padding: 0,
      marginLeft: theme.spacing(-0.75),
    },
  }),
);

export default BonusMissionForm;
