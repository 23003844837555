import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { DailyPayoutBreakdown } from '../../../interfaces/PayHistory/DailyPayoutBreakdown';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';
import CustomTooltip from './CustomTooltip';
import { multiClass } from '../../../utilities/Extensions';

interface DailyTotalAmountTooltipProps {
  dailyPayoutBreakdown: DailyPayoutBreakdown;
  currency: string;
  children: JSX.Element;
}

const DailyTotalAmountTooltip = (props: DailyTotalAmountTooltipProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  let numericCalculation = '';
  let symbolicCalculation = '';

  const concatToNumericCalculation = (amount: number) => {
    if (numericCalculation === '') {
      numericCalculation = parseCentsAsCurrencyString(amount, props.currency);
    } else {
      numericCalculation += ` + ${parseCentsAsCurrencyString(
        amount,
        props.currency,
      )}`;
    }
  };

  const concatToSymbolicCalculation = (term: string) => {
    if (symbolicCalculation === '') {
      symbolicCalculation = term;
    } else {
      symbolicCalculation += ` + ${term}`;
    }
  };

  if (props.dailyPayoutBreakdown.totalAmount === 0) {
    numericCalculation = parseCentsAsCurrencyString(0, props.currency);
    symbolicCalculation = 'No daily earnings yet';
  } else {
    if (props.dailyPayoutBreakdown.basePay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.basePay);
      concatToSymbolicCalculation('Base Pay');
    }
    if (props.dailyPayoutBreakdown.boostPay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.boostPay);
      concatToSymbolicCalculation('Boost Pay');
    }
    if (props.dailyPayoutBreakdown.doXGetY > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.doXGetY);
      concatToSymbolicCalculation('Do X Get Y');
    }
    if (props.dailyPayoutBreakdown.hazardPay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.hazardPay);
      concatToSymbolicCalculation('Hazard Pay');
    }
    if (props.dailyPayoutBreakdown.tier2DeliveryPay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.tier2DeliveryPay);
      concatToSymbolicCalculation('Tier 2 Delivery Pay');
    }
    if (props.dailyPayoutBreakdown.tobaccoPay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.tobaccoPay);
      concatToSymbolicCalculation('Tobacco Pay');
    }
    if (props.dailyPayoutBreakdown.returnCocPay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.returnCocPay);
      concatToSymbolicCalculation('Return Pay');
    }
    if ((props.dailyPayoutBreakdown.seattleEngagedPay ?? 0) > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.seattleEngagedPay!);
      concatToSymbolicCalculation('Engaged Pay');
    }
    if (props.dailyPayoutBreakdown.tips > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.tips);
      concatToSymbolicCalculation('Tips');
    }
    if (props.dailyPayoutBreakdown.totalWaitPay > 0) {
      concatToNumericCalculation(props.dailyPayoutBreakdown.totalWaitPay);
      concatToSymbolicCalculation('Wait Pay');
    }
    if (props.dailyPayoutBreakdown.holidayPayBreakdown != null) {
      concatToNumericCalculation(
        props.dailyPayoutBreakdown.holidayPayBreakdown.totalAmount,
      );
      concatToSymbolicCalculation('Holiday Pay');
    }

    numericCalculation += ` = ${parseCentsAsCurrencyString(
      props.dailyPayoutBreakdown.totalAmount,
      props.currency,
    )}`;
    symbolicCalculation += ' = Payout';
  }

  return (
    <CustomTooltip
      title={
        <Box className={classes.mainContainer}>
          <Typography
            className={multiClass([
              classes.numericCalculation,
              sharedClasses.subtitle1,
            ])}
          >
            {numericCalculation}
          </Typography>
          <Box component='div' className={classes.divider} />
          <Typography
            className={multiClass([
              classes.symbolicCalculation,
              sharedClasses.caption,
            ])}
          >
            {symbolicCalculation}
          </Typography>
        </Box>
      }
    >
      {props.children}
    </CustomTooltip>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  divider: {
    height: 1,
    display: 'flex',
    backgroundColor: sharedColors.gray6,
    flexGrow: 1,
  },
  numericCalculation: {
    color: sharedColors.white,
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(0.8),
  },
  symbolicCalculation: {
    marginTop: theme.spacing(0.8),
    color: sharedColors.white,
    marginBottom: theme.spacing(1.2),
  },
}));

export default DailyTotalAmountTooltip;
