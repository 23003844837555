import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import { useRecoilState } from 'recoil';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { FeatureToggleConfigWithInclusionList } from '../../interfaces/FeatureToggle';
import Waiting from '../Waiting';
import {
  expireFeatureToggles,
  getFeatureToggleConfigWithInclusionList,
} from '../../services/featureToggle';
import { locations as locationsAtom } from '../../atoms/Location';
import { TablePaginationOptions } from '../../interfaces/Common';
import { multiClass } from '../../utilities/Extensions';
import InclusionListTable from './InclusionListTable';
import { featureTogglePageUrl } from '../../variables/Pages';
import { searchTextFieldTestId } from '../RestrictedAccounts/RestrictedAccounts';
import { getLocations } from '../../services/locations';
import ModifyInclusionListDialog from './ModifyInclusionListDialog';
import { featureToggleTypeLocation } from '../../utilities/Constants';

const FeatureToggleConfigDetails = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();

  const [locations, setLocations] = useRecoilState(locationsAtom);

  const [loadingConfigDetails, setLoadingConfigDetails] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [configDetails, setConfigDetails] =
    useState<FeatureToggleConfigWithInclusionList | null>(null);
  const [typedSearchedValue, setTypedSearchedValue] = useState('');
  const [submittedSearchedValue, setSubmittedSearchedValue] = useState('');
  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 25,
  });
  const [onlyActive, setOnlyActive] = useState(true);
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  const { name } = useParams<{ name: string }>();

  const refreshFeatureToggleDetails = () => {
    setLoadingConfigDetails(true);
    getFeatureToggleConfigWithInclusionList(
      name!,
      onlyActive,
      pagination.page,
      pagination.rowsPerPage,
      submittedSearchedValue,
    )
      .then((details) => setConfigDetails(details))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingConfigDetails(false));
  };

  useEffect(() => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));
  }, []);

  useEffect(() => {
    refreshFeatureToggleDetails();
  }, [name, onlyActive, pagination]);

  const handleToggleActiveSwitch = () => setOnlyActive(!onlyActive);

  const handlePagination = (pageNumber: number, pageSize: number) =>
    setPagination({ page: pageNumber, rowsPerPage: pageSize });
  const handleGoBack = () => {
    if (navigate) {
      navigate(featureTogglePageUrl);
    }
  };

  const handleChangeSearchedValue = (e: any) => {
    setTypedSearchedValue(e.target.value);
  };

  const handleSubmitSearchedValue = () => {
    setPagination({ page: 1, rowsPerPage: pagination.rowsPerPage });
    setSubmittedSearchedValue(typedSearchedValue);
  };

  const handleSearchedValueFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmitSearchedValue();
    }
  };

  const handleExpireSingleInclusion = (includedValue: string) => {
    setLoadingConfigDetails(true);
    expireFeatureToggles([
      {
        featureName: name!,
        includedValue,
        expireAt: new Date(),
        expireNow: true,
      },
    ])
      .then(() => {
        toast.success(`Successfully expired inclusion '${includedValue}'`);
        refreshFeatureToggleDetails();
      })
      .catch((err) => {
        toast.error(err.message);
        setLoadingConfigDetails(false);
      });
  };

  const handleModifyInclusionList = () => {
    setShowModifyDialog(false);
    refreshFeatureToggleDetails();
  };

  const loading = loadingConfigDetails || loadingLocations;

  const isLocationType = configDetails?.type === featureToggleTypeLocation;

  return configDetails ? (
    <Box component='div'>
      <Waiting open={loading} />
      <ModifyInclusionListDialog
        open={showModifyDialog}
        locations={locations}
        featureName={configDetails.name}
        isLocationType={isLocationType}
        onClose={() => setShowModifyDialog(false)}
        onDone={handleModifyInclusionList}
      />
      <Paper className={classes.header}>
        <Box component='div' className={classes.titleContainer}>
          <IconButton
            onClick={handleGoBack}
            className={sharedClasses.iconColor}
          >
            <ChevronLeftIcon fontSize='large' />
          </IconButton>
          <Typography
            className={multiClass([classes.verticalCenter, sharedClasses.h2])}
          >
            Feature Toggle Config Details
          </Typography>
        </Box>
      </Paper>
      <Box component='div' className={classes.body}>
        <Typography className={sharedClasses.h3}>{name}</Typography>
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Type:
          </Typography>
          <Typography className={sharedClasses.body1}>
            {configDetails.type}
          </Typography>
        </Box>
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Inclusion Rate:
          </Typography>
          <Typography className={sharedClasses.body1}>
            {`${configDetails.inclusionRate.toFixed(2)}%`}
          </Typography>
        </Box>
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Dependency:
          </Typography>
          <Typography className={sharedClasses.body1}>
            {configDetails.dependency ?? '-'}
          </Typography>
        </Box>
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            {onlyActive ? 'Active inclusions count:' : 'All inclusions count:'}
          </Typography>
          <Typography className={sharedClasses.body1}>
            {configDetails.allInclusionCount}
          </Typography>
        </Box>
        <Box component='div'>
          <FormControlLabel
            control={
              <Switch
                checked={onlyActive}
                onChange={handleToggleActiveSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                color={'success'}
              />
            }
            label={
              <Typography
                className={multiClass([sharedClasses.body1, classes.fieldName])}
              >
                Only Active Inclusions:
              </Typography>
            }
            labelPlacement='start'
            className={classes.activeToggleSwitch}
          />
        </Box>
        <Typography
          className={multiClass([
            sharedClasses.overline,
            classes.filterByLabel,
          ])}
        >
          FILTER BY
        </Typography>
        <Box component='div' className={classes.filtersRow}>
          <TextField
            variant='outlined'
            size='small'
            label={
              configDetails?.type === featureToggleTypeLocation
                ? 'Location ID'
                : 'Driver ID'
            }
            onChange={handleChangeSearchedValue}
            onKeyDown={handleSearchedValueFieldKeyDown}
            onBlur={handleSubmitSearchedValue}
            value={typedSearchedValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleSubmitSearchedValue}>
                    <SearchIcon className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': searchTextFieldTestId,
            }}
            className={classes.textField}
          />
          <Box component='div' className={classes.horizontalSpacer} />
          <Button
            onClick={() => setShowModifyDialog(true)}
            variant='contained'
            color='primary'
            className={multiClass([
              sharedClasses.buttonText,
              classes.editButton,
            ])}
          >
            Edit Values
          </Button>
        </Box>
        <InclusionListTable
          configDetails={configDetails}
          pageNumber={pagination.page}
          pageSize={pagination.rowsPerPage}
          onPagination={handlePagination}
          onExpire={handleExpireSingleInclusion}
          onUpdate={refreshFeatureToggleDetails}
        />
      </Box>
    </Box>
  ) : (
    <Waiting open={loading} />
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(3.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1.25),
  },
  fieldName: {
    color: sharedColors.gray5,
    marginRight: theme.spacing(0.5),
  },
  activeToggleSwitch: {
    marginLeft: 0,
  },
  filterByLabel: {
    color: sharedColors.gray5,
    marginTop: theme.spacing(1),
  },
  filtersRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: sharedColors.white,
    width: '260px',
  },
  searchIcon: {
    color: sharedColors.gray4,
    cursor: 'pointer',
  },
  horizontalSpacer: {
    flexGrow: 1,
  },
  editButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'fit-content',
  },
}));

export default FeatureToggleConfigDetails;
