import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { ShiftBreakdown } from '../../../../interfaces/PayHistory/ShiftBreakdown';
import { multiClass } from '../../../../utilities/Extensions';
import {
  composeNodeID,
  shiftHoursWorkedNodeID,
  shiftsEndTimeNodeID,
  shiftsScheduledEndNodeID,
  shiftsScheduledStartNodeID,
  shiftsStartTimeNodeID,
} from '../../../../variables/PayHistory';
import SingleTextNode from '../SingleTextNode';
import { formatDateTime } from '../../../../utilities/Dates';

interface MinGuaranteeShiftNodeProps {
  id: string;
  shiftBreakdowns: ShiftBreakdown[];
  timezone: string;
}

const MinGuaranteeShiftsNode = (props: MinGuaranteeShiftNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`Shifts [${props.shiftBreakdowns.length.toString()}]`}</Typography>
      }
    >
      {props.shiftBreakdowns.map((shiftBreakdown, i) => (
        <TreeItem
          nodeId={composeNodeID([props.id, i.toString()])}
          label={
            <Typography
              className={multiClass([classes.label, sharedClasses.body1])}
            >
              {`${i.toString()} {${Object.keys(
                shiftBreakdown,
              ).length.toString()}}`}
            </Typography>
          }
        >
          <SingleTextNode
            id={composeNodeID([
              props.id,
              i.toString(),
              shiftsScheduledStartNodeID,
            ])}
            name='Scheduled Start'
            value={formatDateTime(
              shiftBreakdown.scheduledStart,
              props.timezone,
            )}
          />
          <SingleTextNode
            id={composeNodeID([
              props.id,
              i.toString(),
              shiftsScheduledEndNodeID,
            ])}
            name='Scheduled End'
            value={formatDateTime(shiftBreakdown.scheduledEnd, props.timezone)}
          />
          {shiftBreakdown.actualScheduledHoursWorked > 0 && [
            <SingleTextNode
              id={composeNodeID([
                props.id,
                i.toString(),
                shiftsStartTimeNodeID,
              ])}
              name='Actual Start'
              value={formatDateTime(shiftBreakdown.actualStart, props.timezone)}
            />,
            <SingleTextNode
              id={composeNodeID([props.id, i.toString(), shiftsEndTimeNodeID])}
              name='Actual End'
              value={formatDateTime(shiftBreakdown.actualEnd, props.timezone)}
            />,
          ]}
          <SingleTextNode
            id={composeNodeID([props.id, i.toString(), shiftHoursWorkedNodeID])}
            name='Actual Scheduled Hours Worked'
            value={shiftBreakdown.actualScheduledHoursWorked.toString()}
          />
        </TreeItem>
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MinGuaranteeShiftsNode;
