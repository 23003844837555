import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import {
  composeNodeID,
  engagedMilesAmountNodeID,
  engagedMilesRateNodeID,
} from '../../../../variables/PayHistory';
import SingleTextNode from '../SingleTextNode';
import { AppliedRate } from '../../../../interfaces/PayHistory/AppliedRate';

interface EngagedMilesWithRateNodeProps {
  id: string;
  miles: AppliedRate[];
}

const EngagedMilesWithRateNode = (props: EngagedMilesWithRateNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`Engaged Miles [${props.miles.length.toString()}]`}</Typography>
      }
    >
      {props.miles.map((miles, i) => (
        <TreeItem
          nodeId={composeNodeID([props.id, i.toString()])}
          label={
            <Typography
              className={multiClass([classes.label, sharedClasses.body1])}
            >
              {`${i.toString()} {${Object.keys(miles).length.toString()}}`}
            </Typography>
          }
        >
          <SingleTextNode
            id={composeNodeID([props.id, i.toString(), engagedMilesRateNodeID])}
            name='Engaged Distance Rate'
            value={(miles.rate / 100).toString()}
          />
          <SingleTextNode
            id={composeNodeID([
              props.id,
              i.toString(),
              engagedMilesAmountNodeID,
            ])}
            name='Engaged Miles'
            value={miles.amount.toString()}
          />
        </TreeItem>
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default EngagedMilesWithRateNode;
