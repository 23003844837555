import { useState } from 'react';
import { isNumber } from 'lodash';
import { TextValidator } from 'react-material-ui-form-validator';
import { InputAdornment } from '@mui/material';
import { requiredErrorMessage } from '../../utilities/Constants';
import {
  TripFareConstants,
  TripFareLimits,
} from '../../interfaces/TripFareLimits';
import { getTripFareLimitsValidationErrorMessages } from '../../variables/TripFareLimits';

interface FareLimitsInputFieldProps {
  currentState: TripFareLimits;
  setter: (newState: TripFareLimits) => void;
  fieldName: keyof TripFareLimits;
  tripFareConstants: TripFareConstants;
  placeholder: string;
  currencySymbol: string;
  endAdornment: string;
  customValidate: boolean;
}

const FareLimitsInputField = (props: FareLimitsInputFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = ['required'];
  const errorMessages = [requiredErrorMessage];

  if (props.customValidate) {
    const tripFareLimitsValidationErrorMessages =
      getTripFareLimitsValidationErrorMessages(props.tripFareConstants);

    validators.push(props.fieldName);
    errorMessages.push(tripFareLimitsValidationErrorMessages[props.fieldName]!);
  }

  const handleChange = (e: any) => {
    props.setter({
      ...props.currentState,
      [props.fieldName]: parseFloat(e.target.value),
    });
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (isFocused) {
      return plainTextValue;
    }

    const value = props.currentState[props.fieldName] as unknown as number;
    if (isNumber(value)) {
      return value.toFixed(2);
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  const formattedValue = getFormattedValue();

  return (
    <TextValidator
      label={props.placeholder}
      size='small'
      variant='outlined'
      type='number'
      value={formattedValue}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{ display: 'flex', flexGrow: 1 }}
      validators={validators}
      errorMessages={errorMessages}
      name={props.fieldName}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            {props.currencySymbol}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>{props.endAdornment}</InputAdornment>
        ),
      }}
    />
  );
};

export default FareLimitsInputField;
