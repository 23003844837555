import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSharedStyles } from '../../../utilities/Styles';
import { multiClass } from '../../../utilities/Extensions';

interface SingleTextNodeProps {
  id: string;
  name: string;
  value: string;
}

const SingleTextNode = (props: SingleTextNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.name} : ${props.value}`}
        </Typography>
      }
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default SingleTextNode;
