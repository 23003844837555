import { atom } from 'recoil';
import { FeatureToggleHistoryMap } from '../interfaces/FeatureToggle';

export const waitAtMfcFeatureHistoryMap = atom({
  key: 'waitAtMfcFeatureHistoryMap',
  default: {} as FeatureToggleHistoryMap,
});

export const availableWaitPayLocations = atom({
  key: 'availableWaitPayLocations',
  default: null as string[] | null,
});
