import { Permission, UserType } from '../interfaces/Users';
import { getUserTypesFromToken } from '../services/okta';

const rolePermissions: { [key: string]: number } = {
  [UserType.ADMINISTRATOR]: Permission.ALL,
  [UserType.SET_BASE_PAY]:
    Permission.SET_BASE_RATE |
    Permission.SET_WEEKLY_BONUSES |
    Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.SET_SPECIAL_PAY]:
    Permission.SET_SPECIAL_EVENTS |
    Permission.SET_MISSIONS |
    Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.ADD_ADJUSTMENTS]: Permission.SET_ADJUSTMENTS,
  [UserType.STRIPE_MANAGEMENT]: Permission.RESTRICT_ACCOUNTS,
  [UserType.EXPORT_PAYOUT]: Permission.EXPORT_PAYOUT,
  [UserType.SET_BOOST_PAY]:
    Permission.SET_BOOST_PAY |
    Permission.SET_MISSIONS |
    Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.ADJUSTMENT_MANAGER]:
    Permission.SET_ADJUSTMENTS |
    Permission.SET_ADJUSTMENT_APPROVALS |
    Permission.UPLOAD_BULK_ADJUSTMENTS |
    Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.ADJUSTMENT_ADMIN]:
    Permission.SET_ADJUSTMENTS |
    Permission.SET_ADJUSTMENT_APPROVALS |
    Permission.UPLOAD_BULK_ADJUSTMENTS |
    Permission.SET_ADJUSTMENT_TYPES |
    Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.HAS_DPP_TIME_RATES_ACCESS]:
    Permission.HAS_DPP_TIME_RATES_ACCESS | Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.SET_DPP_RULE]:
    Permission.SET_DPP_RULE | Permission.SEND_PUSH_NOTIFICATIONS,
  [UserType.SET_TARGET_COMMISSION]:
    Permission.SET_TARGET_COMMISSION | Permission.SEND_PUSH_NOTIFICATIONS,
};

const getPermissionsForRoles = (roles: UserType[]): number =>
  roles.reduce((acc, role) => acc | rolePermissions[role], 0);

export const isPermitted = (
  userPermissions: number,
  requestedPermission: Permission,
): boolean => !!(userPermissions & requestedPermission);

export const getUserPermissions = (): number =>
  getPermissionsForRoles(getUserTypesFromToken());
