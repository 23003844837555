import { Route, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import FeatureToggleConfigs from './FeatureToggleConfigs';
import FeatureToggleConfigDetails from './FeatureToggleConfigDetails';
import { getUserPermissions } from '../../variables/Users';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';

const FeatureTogglePageRoot = () => {
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);

  useEffect(() => {
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.FEATURE_TOGGLES) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  return (
    <Routes>
      <Route path={'/'} element={<FeatureToggleConfigs />}></Route>
      <Route path={'/:name'} element={<FeatureToggleConfigDetails />}></Route>
    </Routes>
  );
};

export default FeatureTogglePageRoot;
