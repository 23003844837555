import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PayoutBreakdown } from '../../../interfaces/PayHistory/PayoutBreakdown';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { multiClass } from '../../../utilities/Extensions';
import CustomTooltip from './CustomTooltip';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';

interface WeeklyTotalAmountTooltipProps {
  payoutBreakdown: PayoutBreakdown;
  currency: string;
  children: JSX.Element;
}

const WeeklyTotalAmountTooltip = (props: WeeklyTotalAmountTooltipProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  let numericCalculation = '';
  let symbolicCalculation = '';

  const concatToNumericCalculation = (amount: number) => {
    if (numericCalculation === '') {
      numericCalculation = parseCentsAsCurrencyString(amount, props.currency);
    } else {
      numericCalculation += ` + ${parseCentsAsCurrencyString(
        amount,
        props.currency,
      )}`;
    }
  };

  const concatToSymbolicCalculation = (term: string) => {
    if (symbolicCalculation === '') {
      symbolicCalculation = term;
    } else {
      symbolicCalculation += ` + ${term}`;
    }
  };

  if (props.payoutBreakdown.totalAmount === 0) {
    numericCalculation = parseCentsAsCurrencyString(0, props.currency);
    symbolicCalculation = 'No weekly earnings yet';
  } else {
    if (props.payoutBreakdown.weeklyBonusBreakdown?.amount) {
      concatToNumericCalculation(
        props.payoutBreakdown.weeklyBonusBreakdown.amount,
      );
      concatToSymbolicCalculation('Weekly Bonus');
    }
    if (props.payoutBreakdown.firstShiftBonus > 0) {
      concatToNumericCalculation(props.payoutBreakdown.firstShiftBonus);
      concatToSymbolicCalculation('First Shift Bonus');
    }
    if (props.payoutBreakdown.overtimePay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.overtimePay);
      concatToSymbolicCalculation('Overtime Pay');
    }
    if (props.payoutBreakdown.prop22PayBreakdown?.amount) {
      concatToNumericCalculation(
        props.payoutBreakdown.prop22PayBreakdown.amount,
      );
      concatToSymbolicCalculation('Prop 22 Pay');
    }
    if (props.payoutBreakdown.totalAdjustment > 0) {
      concatToNumericCalculation(props.payoutBreakdown.totalAdjustment);
      concatToSymbolicCalculation('Adjustment');
    }
    if (props.payoutBreakdown.basePay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.basePay);
      concatToSymbolicCalculation('Base Pay');
    }
    if (props.payoutBreakdown.boostPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.boostPay);
      concatToSymbolicCalculation('Boost Pay');
    }
    if (props.payoutBreakdown.doXGetY > 0) {
      concatToNumericCalculation(props.payoutBreakdown.doXGetY);
      concatToSymbolicCalculation('Do X Get Y');
    }
    if (props.payoutBreakdown.hazardPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.hazardPay);
      concatToSymbolicCalculation('Hazard Pay');
    }
    if (props.payoutBreakdown.tier2DeliveryPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.tier2DeliveryPay);
      concatToSymbolicCalculation('Tier 2 Delivery Pay');
    }
    if (props.payoutBreakdown.tobaccoPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.tobaccoPay);
      concatToSymbolicCalculation('Tobacco Pay');
    }
    if (props.payoutBreakdown.returnCocPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.returnCocPay);
      concatToSymbolicCalculation('Return Pay');
    }
    if ((props.payoutBreakdown.seattleEngagedPay ?? 0) > 0) {
      concatToNumericCalculation(props.payoutBreakdown.seattleEngagedPay!);
      concatToSymbolicCalculation('Engaged Pay');
    }
    if (props.payoutBreakdown.tips > 0) {
      concatToNumericCalculation(props.payoutBreakdown.tips);
      concatToSymbolicCalculation('Tips');
    }
    if (props.payoutBreakdown.totalMinGuaranteeAdjustment > 0) {
      concatToNumericCalculation(
        props.payoutBreakdown.totalMinGuaranteeAdjustment,
      );
      concatToSymbolicCalculation('Minimum Guarantee Adjustment');
    }
    if (props.payoutBreakdown.engagedSubsidyPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.engagedSubsidyPay);
      concatToSymbolicCalculation('Engaged Subsidy Pay');
    }
    if (props.payoutBreakdown.engagedDistancePay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.engagedDistancePay);
      concatToSymbolicCalculation('Engaged Distance Pay');
    }
    if (props.payoutBreakdown.totalWeeklyWaitPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.totalWeeklyWaitPay);
      concatToSymbolicCalculation('Weekly Wait Pay');
    }
    if (props.payoutBreakdown.totalShiftBasedWaitPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.totalShiftBasedWaitPay);
      concatToSymbolicCalculation('Wait Pay');
    }
    if (props.payoutBreakdown.holidayPayBreakdown != null) {
      concatToNumericCalculation(
        props.payoutBreakdown.holidayPayBreakdown.totalAmount,
      );
      concatToSymbolicCalculation('Holiday Pay');
    }
    if (props.payoutBreakdown.reimbursementPay > 0) {
      concatToNumericCalculation(props.payoutBreakdown.reimbursementPay);
      concatToSymbolicCalculation('Reimbursement Pay');
    }
    numericCalculation += ` = ${parseCentsAsCurrencyString(
      props.payoutBreakdown.totalAmount,
      props.currency,
    )}`;
    symbolicCalculation += ' = Payout';
  }

  return (
    <CustomTooltip
      title={
        <Box className={classes.mainContainer}>
          <Typography
            className={multiClass([
              classes.numericCalculation,
              sharedClasses.subtitle1,
            ])}
          >
            {numericCalculation}
          </Typography>
          <Box component='div' className={classes.divider} />
          <Typography
            className={multiClass([
              classes.symbolicCalculation,
              sharedClasses.caption,
            ])}
          >
            {symbolicCalculation}
          </Typography>
        </Box>
      }
    >
      {props.children}
    </CustomTooltip>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  divider: {
    height: 1,
    display: 'flex',
    backgroundColor: sharedColors.gray6,
    flexGrow: 1,
  },
  numericCalculation: {
    color: sharedColors.white,
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(0.8),
  },
  symbolicCalculation: {
    marginTop: theme.spacing(0.8),
    color: sharedColors.white,
    marginBottom: theme.spacing(1.2),
  },
}));

export default WeeklyTotalAmountTooltip;
