import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { MinGuaranteeAdjustmentBreakdown } from '../../../../interfaces/PayHistory/MinGuaranteeAdjustmentBreakdown';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  amountNodeID,
  basePayNodeID,
  boostPayNodeID,
  composeNodeID,
  minGuaranteeAdjustmentLocationNodeID,
  scheduledHoursNodeID,
  shiftsNodeID,
  subsidizableHoursWorkedNodeID,
  subsidyVersionNodeID,
} from '../../../../variables/PayHistory';
import MinGuaranteeShiftsNode from './MinGuaranteeShiftsNode';
import SinglePayoutNode from '../SinglePayoutNode';
import MinGuaranteeSubsidizableHoursWorkedNode from './MinGuaranteeSubsidizableHoursWorkedNode';

interface MinGuaranteeAdjustmentNodeProps {
  id: string;
  index: number;
  minGuaranteeAdjustment: MinGuaranteeAdjustmentBreakdown;
  currency: string;
  timezone: string;
}

const MinGuaranteeAdjustmentNode = (props: MinGuaranteeAdjustmentNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${props.index.toString()} {${Object.keys(
          props.minGuaranteeAdjustment,
        ).length.toString()}}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, minGuaranteeAdjustmentLocationNodeID])}
        name='Location Name'
        value={props.minGuaranteeAdjustment.locationName}
      />
      <MinGuaranteeShiftsNode
        id={composeNodeID([props.id, shiftsNodeID])}
        shiftBreakdowns={props.minGuaranteeAdjustment.shifts}
        timezone={props.timezone}
      />
      <MinGuaranteeSubsidizableHoursWorkedNode
        id={composeNodeID([props.id, subsidizableHoursWorkedNodeID])}
        hoursWorked={props.minGuaranteeAdjustment.subsidizableHoursWorked}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, basePayNodeID])}
        name='Base Pay'
        amount={props.minGuaranteeAdjustment.basePay}
        currency={props.currency}
      />
      {props.minGuaranteeAdjustment.version === 3 && (
        <SinglePayoutNode
          id={composeNodeID([props.id, boostPayNodeID])}
          name='Boost Pay'
          amount={props.minGuaranteeAdjustment.boostPay}
          currency={props.currency}
        />
      )}
      <SingleTextNode
        id={composeNodeID([props.id, scheduledHoursNodeID])}
        name='Scheduled Hours'
        value={props.minGuaranteeAdjustment.scheduledHours.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, amountNodeID])}
        name='Amount'
        amount={props.minGuaranteeAdjustment.amount}
        currency={props.currency}
      />
      <SingleTextNode
        id={composeNodeID([props.id, subsidyVersionNodeID])}
        name='Version'
        value={props.minGuaranteeAdjustment.version.toString()}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MinGuaranteeAdjustmentNode;
