import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'material-react-toastify';
import {
  PushNotificationCategory,
  PushNotificationsList,
} from '../../interfaces/PushNotification/PushNotification';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../atoms/Location';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  getPushNotificationCategoryName,
  getShortenedRequirementValue,
} from '../../utilities/PushNotification';
import { TablePaginationOptions } from '../../interfaces/Common';
import { multiClass } from '../../utilities/Extensions';
import { locationIdToName, metroIDToName } from '../../utilities/Locations';
import StringField from '../StringField';
import {
  formatLocalTime,
  formatMomentAsFilePostfix,
} from '../../utilities/Dates';
import Waiting from '../Waiting';
import { getPushNotifications } from '../../services/pushNotifications';
import { int32Max } from '../../utilities/Constants';
import { parseCSVFile } from '../../utilities/Csv';

interface PushNotificationCategoryChipProps {
  category: PushNotificationCategory;
}

const PushNotificationCategoryChip = (
  props: PushNotificationCategoryChipProps,
) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.typeChip}
      label={
        <Typography className={classes.typeChipText}>
          {getPushNotificationCategoryName(props.category)}
        </Typography>
      }
    />
  );
};

interface PushNotificationTableProps {
  pushNotificationsList: PushNotificationsList;
  pagination: TablePaginationOptions;
  onPagination: (newPagination: TablePaginationOptions) => void;
}

const PushNotificationTable = (props: PushNotificationTableProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const metros = useRecoilValue(metrosAtom);

  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    setLoading(true);
    getPushNotifications({ page: 1, rowsPerPage: int32Max })
      .then((pushNotificationsList) => {
        const url = parseCSVFile(
          [
            {
              label: 'ID',
              value: 'id',
            },
            {
              label: 'Title',
              value: 'title',
            },
            {
              label: 'Message',
              value: 'message',
            },
            {
              label: 'category',
              value: 'category',
            },
            {
              label: 'Locations',
              value: 'locations',
            },
            {
              label: 'metros',
              value: 'metros',
            },
            {
              label: 'DP IDs',
              value: 'driverIDs',
            },
            {
              label: 'DP Must Be Inactive',
              value: 'mustBeInactive',
            },
            {
              label: 'Created By',
              value: 'createdBy',
            },
            {
              label: 'Created At',
              value: 'createdAt',
            },
          ],
          pushNotificationsList.pushNotifications.map((pushNotification) => ({
            id: pushNotification.id.toString(),
            title: pushNotification.title,
            message: pushNotification.message,
            category: pushNotification.category.toString(),
            locations: pushNotification.requirements.locationIDs
              .map((locationID) =>
                locationIdToName(locations, parseInt(locationID, 10)),
              )
              .join(';'),
            metros: pushNotification.requirements.metroIDs
              .map((metroID) => metroIDToName(metros, metroID))
              .join(';'),
            driverIDs: pushNotification.requirements.driverIDs.join(';'),
            mustBeInactive: pushNotification.requirements.mustBeInactive
              ? 'Yes'
              : 'No',
            createdBy: pushNotification.audit.createdBy,
            createdAt: formatLocalTime(pushNotification.audit.createdAt),
          })),
        );

        const link = document.createElement('a');
        link.download = `push_notifications${formatMomentAsFilePostfix()}`;
        link.href = url;
        link.click();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Box component='div' className={classes.outerContainer}>
      <Waiting open={loading} />
      <Box component='div' className={classes.row}>
        <Typography className={multiClass([sharedClasses.h5, classes.title])}>
          Push Notifications
        </Typography>
        <Box component='div' className={classes.horizontalSpacer} />
        <Button
          color='primary'
          variant='contained'
          startIcon={<DownloadIcon />}
          onClick={handleExport}
          className={multiClass([
            classes.verticalCenter,
            sharedClasses.buttonText,
            classes.exportButton,
          ])}
        >
          Export CSV
        </Button>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Title</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Message</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Category
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Employee Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Locations
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Metros</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>DP IDs</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  DP Must Be Inactive
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Created By
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Created At
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.pushNotificationsList.pushNotifications.map(
              (pushNotification) => {
                const locationNames =
                  pushNotification.requirements.locationIDs.map((locationID) =>
                    locationIdToName(locations, parseInt(locationID, 10)),
                  );
                const metroNames = pushNotification.requirements.metroIDs.map(
                  (metroID) => metroIDToName(metros, metroID),
                );

                let dpMustBeInactive = '-';
                if (
                  !isEmpty(pushNotification.requirements.metroIDs) ||
                  !isEmpty(pushNotification.requirements.locationIDs)
                ) {
                  dpMustBeInactive = pushNotification.requirements
                    .mustBeInactive
                    ? 'Yes'
                    : 'No';
                }

                return (
                  <TableRow key={pushNotification.id}>
                    <TableCell>
                      <StringField value={pushNotification.id.toString()} />
                    </TableCell>
                    <TableCell>
                      <StringField value={pushNotification.title} />
                    </TableCell>
                    <TableCell>
                      <StringField value={pushNotification.message} />
                    </TableCell>
                    <TableCell>
                      <PushNotificationCategoryChip
                        category={pushNotification.category}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          pushNotification.requirements.employeeType ?? 'All'
                        }
                      />
                    </TableCell>
                    <TableCell
                      title={
                        isEmpty(locationNames)
                          ? undefined
                          : locationNames.join(', ')
                      }
                    >
                      <StringField
                        value={getShortenedRequirementValue(locationNames)}
                      />
                    </TableCell>
                    <TableCell
                      title={
                        isEmpty(metroNames) ? undefined : metroNames.join(', ')
                      }
                    >
                      <StringField
                        value={getShortenedRequirementValue(metroNames)}
                      />
                    </TableCell>
                    <TableCell
                      title={
                        isEmpty(pushNotification.requirements.driverIDs)
                          ? undefined
                          : pushNotification.requirements.driverIDs.join(', ')
                      }
                    >
                      <StringField
                        value={getShortenedRequirementValue(
                          pushNotification.requirements.driverIDs,
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField value={dpMustBeInactive} />
                    </TableCell>
                    <TableCell>
                      <StringField value={pushNotification.audit.createdBy} />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={formatLocalTime(
                          pushNotification.audit.createdAt,
                        )}
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
        <TablePagination
          count={props.pushNotificationsList.totalCount}
          page={props.pagination.page - 1}
          onPageChange={(_, newPage) =>
            props.onPagination({ ...props.pagination, page: newPage + 1 })
          }
          rowsPerPage={props.pagination.rowsPerPage}
          onRowsPerPageChange={(e) =>
            props.onPagination({
              page: 1,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typeChip: {
      backgroundColor: sharedColors.blue1,
      color: sharedColors.blue6,
    },
    typeChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginBottom: theme.spacing(1.5),
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    verticalCenter: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    exportButton: {
      textTransform: 'none',
    },
  }),
);

export default PushNotificationTable;
