import { Card } from '@mui/material';

const NotFound = () => (
  <Card
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    }}
  >
    The URL you are looking for does not exist
  </Card>
);

export default NotFound;
