export interface AdjustmentSummary {
  amount: number;
  type: string;
  subtype: string;
  note: string;
}

export const adjustmentSummaryFromResponse = (
  responseData: any,
): AdjustmentSummary => ({
  amount: responseData.amount ?? 0,
  type: responseData.type ?? '',
  subtype: responseData.subtype ?? '',
  note: responseData.note ?? '',
});
