import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import { TimeRatePipelineSettings } from '../../interfaces/Settings/TimeRatePipelineSettings';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { Location } from '../../interfaces/Location';
import { multiClass } from '../../utilities/Extensions';
import { payRateStatusFromTimeRange } from '../../utilities/PayRates';
import { filterLocationById } from '../../utilities/Locations';
import { mapSettingsLocationIDToInt } from '../../utilities/Settings/Settings';
import StringField from '../StringField';
import { PayRateStatus } from '../../interfaces/PayRate';
import { formatDate } from '../../utilities/Dates';
import { toTwoDecimalsMax } from '../../utilities/Misc';
import StatusField from '../StatusField';
import Waiting from '../Waiting';
import { deleteSettingsByID } from '../../services/settings';
import { ConfirmDialog } from '../NewPayRateDialog/ConfirmDialog';

interface TimeRateSettingsTableProps {
  rows: TimeRatePipelineSettings[];
  locations: Location[];
  onChange: () => void;
}

const TimeRateSettingsTable = (props: TimeRateSettingsTableProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [settingsIDToDelete, setSettingsIDToDelete] = useState<number | null>(
    null,
  );

  useEffect(() => {
    setPage(0);
  }, [props.rows]);

  const getBooleanField = (value: boolean) => {
    return value ? (
      <CheckCircleIcon sx={{ color: sharedColors.statusGreen }} />
    ) : (
      <CancelIcon sx={{ color: sharedColors.statusRed }} />
    );
  };

  const handleDelete = () => {
    if (!isFinite(settingsIDToDelete)) {
      return;
    }

    setLoading(true);
    deleteSettingsByID(settingsIDToDelete!)
      .then(() => {
        toast.success('Settings successfully deleted');
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoading(false);
      });
    setSettingsIDToDelete(null);
  };

  const timezone = 'America/Los_Angeles';

  return (
    <Box component='div' className={classes.outerContainer}>
      <Waiting open={loading} />
      <ConfirmDialog
        title='Are you sure?'
        content='Do you really want to delete the selected settings?'
        okText='Confirm'
        cancelText='Cancel'
        open={isFinite(settingsIDToDelete)}
        onClose={() => setSettingsIDToDelete(null)}
        onConfirm={handleDelete}
        color='error'
      />
      <Typography className={multiClass([sharedClasses.h5, classes.title])}>
        Time Rate Pipeline Settings
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Location Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Start Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  End Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Target P90 Delivery Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Target P90 Delivery Driver Controllable Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Max Delta Orders Per Driver Hour
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Max Delta Commission
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Time Rate Lower Bound
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Time Rate Upper Bound
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Min Time Rate Missing Data
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  DCPO Neutral Over Week
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  No Commission Increase
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Last Updated By
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Status</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography className={classes.columnLabel}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row) => {
                const status = payRateStatusFromTimeRange(
                  row.startAt,
                  row.endAt,
                );
                const isExpired = status === PayRateStatus.EXPIRED;

                const location = filterLocationById(
                  props.locations,
                  mapSettingsLocationIDToInt(row.locationID),
                );

                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <StringField
                        value={location?.name || '-'}
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={formatDate(row.startAt, timezone)}
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          row.endAt ? formatDate(row.endAt, timezone) : '-'
                        }
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={toTwoDecimalsMax(row.targetP90DeliveryTime)}
                        unit='Minutes'
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={toTwoDecimalsMax(
                          row.targetP90DeliveryDriverControllableTime,
                        )}
                        unit='Minutes'
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={toTwoDecimalsMax(
                          100 * row.maxDeltaOrdersPerDriverPercent,
                        )}
                        unit='%'
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          getSymbolFromCurrency(location?.currency ?? 'USD') +
                          toTwoDecimalsMax(row.maxDeltaCommission)
                        }
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          getSymbolFromCurrency(location?.currency ?? 'USD') +
                          toTwoDecimalsMax(row.timeRateLowerBound)
                        }
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          getSymbolFromCurrency(location?.currency ?? 'USD') +
                          toTwoDecimalsMax(row.timeRateUpperBound)
                        }
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          getSymbolFromCurrency(location?.currency ?? 'USD') +
                          toTwoDecimalsMax(row.minTimeRateMissingData)
                        }
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      {getBooleanField(row.dcpoNeutralOverWeek)}
                    </TableCell>
                    <TableCell align='center'>
                      {getBooleanField(row.noCommissionIncrease)}
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={row.lastUpdatedBy}
                        disabled={isExpired}
                      />
                    </TableCell>
                    <TableCell>
                      <StatusField status={status} />
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        className={sharedClasses.buttonText}
                        disabled={status !== PayRateStatus.FUTURE}
                        onClick={
                          status === PayRateStatus.FUTURE
                            ? () => setSettingsIDToDelete(row.id)
                            : undefined
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          count={props.rows.length}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[4, 6, 8, 10]}
          component={'div'}
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typeChip: {
      backgroundColor: sharedColors.blue1,
      color: sharedColors.blue6,
    },
    typeChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    editableRow: {
      cursor: 'pointer',
    },
  }),
);

export default TimeRateSettingsTable;
