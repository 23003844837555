import { isArray, isEmpty } from 'lodash';
import {
  TripFareConstants,
  TripFareLimits,
} from '../interfaces/TripFareLimits';
import api from './api';
import {
  tripFareConstantsInputParser,
  tripFareLimitsInputParser,
  tripFareLimitsOutputTransformer,
} from '../variables/TripFareLimits';

const tripFareLimitsEndpointUrl = '/trip-fare-limits';

export const getTripFareLimits = async (
  locationIDs: string[],
): Promise<TripFareLimits[]> => {
  const response = await api.get(tripFareLimitsEndpointUrl, {
    params: {
      location: isEmpty(locationIDs) ? undefined : locationIDs,
      status: isEmpty(locationIDs) ? 'ACTIVE' : undefined,
    },
    paramsSerializer: { indexes: null },
  });

  const responseData = response.data;
  if (!responseData) {
    throw new Error('Unexpected error: Server responded with empty body');
  }
  const tripFareLimitsResponse = responseData.fare_limits;

  if (!isArray(tripFareLimitsResponse)) {
    throw new Error('Unexpected error: Responded value is not an array');
  }

  return tripFareLimitsResponse.map((fareLimits) =>
    tripFareLimitsInputParser(fareLimits),
  );
};

export const getTripFareConstants = async (): Promise<TripFareConstants> => {
  const response = await api.get(`${tripFareLimitsEndpointUrl}/constants`);

  const responseData = response.data;

  return tripFareConstantsInputParser(responseData.constants);
};

export const updateTripFareLimits = async (
  tripFareLimits: TripFareLimits,
): Promise<void> => {
  const tripFareLimitsToPut = tripFareLimitsOutputTransformer(tripFareLimits);
  try {
    await api.put(tripFareLimitsEndpointUrl, tripFareLimitsToPut);
  } catch (err: any) {
    throw new Error(`Could not update trip fare limits: ${err.message}`);
  }
};
