export interface WeeklyBonusLocation {
  locationName: string;
  deliveryCount: number;
}

const weeklyBonusLocationFromResponse = (
  responseData: any,
): WeeklyBonusLocation => ({
  locationName: responseData.location_name ?? '',
  deliveryCount: responseData.delivery_count ?? 0,
});

export interface WeeklyBonusBreakdown {
  applicableWeeklyBonusLocation: WeeklyBonusLocation;
  allWeeklyBonusLocations: WeeklyBonusLocation[];
  tier1Orders: number;
  tier1Amount: number;
  tier2Orders: number;
  tier2Amount: number;
  tier3Orders: number;
  tier3Amount: number;
  tier4Orders: number;
  tier4Amount: number;
  amount: number;
}

export const weeklyBonusBreakdownFromResponse = (
  responseData: any,
): WeeklyBonusBreakdown => ({
  applicableWeeklyBonusLocation: weeklyBonusLocationFromResponse(
    responseData.applicable_weekly_bonus_location,
  ),
  allWeeklyBonusLocations: responseData.all_weekly_bonus_locations.map(
    (location: any) => weeklyBonusLocationFromResponse(location),
  ),
  tier1Orders: responseData.tier1_orders ?? 0,
  tier1Amount: responseData.tier1_amount ?? 0,
  tier2Orders: responseData.tier2_orders ?? 0,
  tier2Amount: responseData.tier2_amount ?? 0,
  tier3Orders: responseData.tier3_orders ?? 0,
  tier3Amount: responseData.tier3_amount ?? 0,
  tier4Orders: responseData.tier4_orders ?? 0,
  tier4Amount: responseData.tier4_amount ?? 0,
  amount: responseData.amount ?? 0,
});
