import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  shiftBasedWaitPayWeeklyBreakdownsNodeID,
  waitPayBreakdownsNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import { WaitPayBreakdown } from '../../../../interfaces/PayHistory/WaitPayBreakdown';
import WaitPayBreakdownNode from './WaitPayBreakdownNode';
import { parseCentsAsCurrencyString } from '../../../../utilities/Currency';

interface WaitPayBreakdownListNodeProps {
  waitPayBreakdowns: WaitPayBreakdown[];
  weekly: boolean;
  displayShifts: boolean;
  amount: number;
  currency: string;
  timezone: string;
}

const WaitPayBreakdownListNode = (props: WaitPayBreakdownListNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={
        props.weekly
          ? waitPayBreakdownsNodeID
          : shiftBasedWaitPayWeeklyBreakdownsNodeID
      }
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${
            props.weekly ? 'Weekly ' : ''
          }Wait Pay : ${parseCentsAsCurrencyString(
            props.amount,
            props.currency,
          )}`}
        </Typography>
      }
    >
      {props.waitPayBreakdowns.map((breakdown, i) => (
        <WaitPayBreakdownNode
          id={composeNodeID([
            props.weekly
              ? waitPayBreakdownsNodeID
              : shiftBasedWaitPayWeeklyBreakdownsNodeID,
            i.toString(),
          ])}
          waitPayBreakdown={breakdown}
          displayShifts={props.displayShifts}
          currency={props.currency}
          timezone={props.timezone}
        />
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default WaitPayBreakdownListNode;
