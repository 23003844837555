import api from './api';

import {
  ActivePayRates,
  BoostPay,
  GenericPayRate,
  PayRate,
  PayRateKind,
  PayRateStatus,
  PayRateToEdit,
  PayRateType,
} from '../interfaces/PayRate';
import { boostPayRateType } from '../variables/PayRate';
import {
  extractPossiblePayType,
  parsePayRateFromResponse,
} from '../utilities/PayRates';
import { activePayRates as mockActivePayRates } from '../mocks/PayRate';
import { waitAtMfcMinimumFeatureName } from '../variables/FeatureToggle';

export const getPayRateByLocationId = async (
  locationIds: string[],
  payRateType: PayRateType,
): Promise<PayRate[]> => {
  if (!payRateType.apiUrl) {
    return payRateType.mocks;
  }

  const url = payRateType.apiUrl;

  const response = await api.get(url, {
    params: {
      location: locationIds,
      type: extractPossiblePayType(payRateType),
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  const responseData = response.data[payRateType.responseKey];

  const payRates = (responseData ?? []).map((receivedPayRate: any) =>
    parsePayRateFromResponse(payRateType, receivedPayRate),
  );

  return payRateType.kind === PayRateKind.SUBSIDY
    ? payRates.filter(
        (payRate: GenericPayRate) =>
          !(payRate.featureFlags ?? []).includes(waitAtMfcMinimumFeatureName),
      )
    : payRates;
};

export const getReplacedPayRateByLocationID = async (
  locationId: string,
  payRateType: PayRateType,
): Promise<PayRate | null> => {
  const payRates = await getPayRateByLocationId([locationId], payRateType);
  return (
    payRates.filter((payRate) => payRate.status === PayRateStatus.FUTURE)[0] ||
    payRates.filter((payRate) => payRate.status === PayRateStatus.ACTIVE)[0] ||
    null
  );
};

export const getOverlappingBoostPayRateList = async (
  locationIds: string[],
  boostPays: BoostPay[],
  payRateType: PayRateType,
  payRateToEdit: PayRateToEdit | null,
): Promise<BoostPay[]> => {
  const existingPayRates = await getPayRateByLocationId(
    locationIds,
    payRateType,
  );
  const overLappingPayRates: BoostPay[] = [];
  existingPayRates.forEach((existingPayRate) => {
    boostPays.forEach((boostPay) => {
      if (
        existingPayRate.status !== PayRateStatus.EXPIRED &&
        existingPayRate.locationID === boostPay.locationID &&
        existingPayRate.id !== payRateToEdit?.payRate.id &&
        // @ts-ignore
        existingPayRate.startAt.getTime() < boostPay.endAt.getTime() &&
        // @ts-ignore
        existingPayRate.endAt.time?.getTime() > boostPay.startAt.getTime()
      ) {
        overLappingPayRates.push(existingPayRate as BoostPay);
      }
    });
  });
  return overLappingPayRates;
};

export const createNewPayRate = async (
  payRate: PayRate,
  payRateType: PayRateType,
  timeZone: string | null,
): Promise<void> => {
  const payRateToPost = payRateType.outputTransformer(payRate, timeZone, false);
  try {
    payRateType.validator(payRate);
  } catch (error: any) {
    throw new Error(
      `Could not create pay rate due to validation error: ${error.message}`,
    );
  }
  if (payRateType.apiUrl) {
    try {
      await api.post(payRateType.apiUrl, payRateToPost);
    } catch (error: any) {
      throw new Error(`Could not create pay rate: ${error.message}`);
    }
  }
};

export const createBoostPays = async (boostPays: BoostPay[]): Promise<void> => {
  try {
    boostPays.forEach((boostPay) => boostPayRateType.validator(boostPay));
  } catch (error: any) {
    throw new Error(
      `Could not create pay rate due to validation error: ${error.message}`,
    );
  }

  if (boostPayRateType.apiUrl) {
    try {
      await api.post(`${boostPayRateType.apiUrl}/bulk`, {
        pay_rates: boostPays,
      });
    } catch (error: any) {
      throw new Error(`Could not create boost pays: ${error.message}`);
    }
  }
};

export const deletePayRateById = async (
  id: number,
  payRateType: PayRateType,
): Promise<void> => {
  try {
    await api.delete(`${payRateType.apiUrl}/${id}`);
  } catch (error: any) {
    throw new Error(`Could not delete pay rate: ${error.message}`);
  }
};

export const updatePayRate = async (
  payRate: PayRate,
  payRateType: PayRateType,
  timezone: string | null,
): Promise<void> => {
  const { id } = payRate;
  const payRateToPut = payRateType.outputTransformer(payRate, timezone, true);
  try {
    payRateType.validator(payRate);
  } catch (error: any) {
    throw new Error(
      `Could not update pay rate due to validation error: ${error.message}`,
    );
  }
  if (payRateType.apiUrl) {
    try {
      await api.put(`${payRateType.apiUrl}/${id.toString()}`, payRateToPut);
    } catch (error: any) {
      throw new Error(`Could not update pay rate: ${error.message}`);
    }
  }
};

export const expirePayRate = async (
  payRate: PayRate,
  expireAt: Date,
  payRateType: PayRateType,
  timezone: string | null,
): Promise<void> => {
  const { id } = payRate;
  const payRateToPut = payRateType.outputTransformer(payRate, timezone, true);
  payRateToPut.end_at = expireAt;

  try {
    payRateType.validator(payRate);
  } catch (error: any) {
    throw new Error(
      `Could not expire pay rate due to validation error: ${error.message}`,
    );
  }
  if (payRateType.apiUrl) {
    try {
      await api.put(`${payRateType.apiUrl}/${id.toString()}`, payRateToPut);
    } catch (error: any) {
      throw new Error(`Could not expire pay rate: ${error.message}`);
    }
  }
};

export const getActivePayRatesAndLocationIds = async (
  payRateType: PayRateType,
): Promise<ActivePayRates> => {
  if (!payRateType.apiUrl) {
    return mockActivePayRates;
  }
  const url = payRateType.apiUrl;

  const response = await api.get(url, {
    params: {
      status: payRateType.isNullableStatusParam
        ? `"${PayRateStatus.ACTIVE}"`
        : PayRateStatus.ACTIVE,
      type: extractPossiblePayType(payRateType),
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  const responseData = response.data[payRateType.responseKey];
  const locationsOfActivePayRates: string[] = [];

  const rates = responseData.map((receivedPayRate: any) => {
    locationsOfActivePayRates.push(receivedPayRate.location_id);
    return parsePayRateFromResponse(payRateType, receivedPayRate);
  });

  return { rates, locations: locationsOfActivePayRates };
};
