import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { HolidayRequest } from '../../interfaces/HolidayPay';
import {
  TablePaginationOptions,
  TablePaginationResponse,
} from '../../interfaces/Common';
import StringField from '../StringField';
import HolidayTypeChip from './HolidayTypeChip';
import { formatLocalTime } from '../../utilities/Dates';

interface HolidayRequestsTableProps {
  holidayRequests: HolidayRequest[];
  pagination: TablePaginationResponse;
  onPagination: (newPagination: TablePaginationOptions) => void;
}

const HolidayRequestsTable = (props: HolidayRequestsTableProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' className={classes.outerContainer}>
      <Typography className={multiClass([sharedClasses.h5, classes.title])}>
        Holiday Requests
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>DP ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Holiday Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Created At
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Holiday Start
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Holiday End
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Accrued Days
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Rate</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Amount Paid
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.holidayRequests.map((request, i) => (
              <TableRow key={i}>
                <TableCell>
                  <StringField value={request.driverID} />
                </TableCell>
                <TableCell>
                  <HolidayTypeChip type={request.holidayType} />
                </TableCell>
                <TableCell>
                  <StringField value={formatLocalTime(request.createdAt)} />
                </TableCell>
                <TableCell>
                  <StringField value={formatLocalTime(request.holidayStart)} />
                </TableCell>
                <TableCell>
                  <StringField value={formatLocalTime(request.holidayEnd)} />
                </TableCell>
                <TableCell>
                  <StringField value={request.accruedDays.toFixed(2)} />
                </TableCell>
                <TableCell>
                  <StringField
                    value={
                      getSymbolFromCurrency(request.currency) +
                      (request.rate / 100).toFixed(2)
                    }
                  />
                </TableCell>
                <TableCell>
                  <StringField
                    value={
                      getSymbolFromCurrency(request.currency) +
                      (request.amountPaid / 100).toFixed(2)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={props.pagination.totalCount}
          page={props.pagination.page - 1}
          onPageChange={(_, newPage) =>
            props.onPagination({ ...props.pagination, page: newPage + 1 })
          }
          rowsPerPage={props.pagination.rowsPerPage}
          onRowsPerPageChange={(e) =>
            props.onPagination({
              page: 1,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(5),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
  }),
);

export default HolidayRequestsTable;
