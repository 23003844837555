import SingleTextNode from './SingleTextNode';

interface SingleBooleanNodeProps {
  id: string;
  name: string;
  value: boolean;
}

const SingleBooleanNode = (props: SingleBooleanNodeProps) => {
  return (
    <SingleTextNode
      id={props.id}
      name={props.name}
      value={props.value ? 'True' : 'False'}
    />
  );
};

export default SingleBooleanNode;
