import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { BasePayBreakdown } from '../../../../interfaces/PayHistory/BasePayBreakdown';
import { multiClass } from '../../../../utilities/Extensions';
import SinglePayoutNode from '../SinglePayoutNode';
import {
  basePayNodeID,
  composeNodeID,
  isVariableBasePayNodeID,
  realTimeAdjustmentNodeID,
} from '../../../../variables/PayHistory';
import SingleBooleanNode from '../SingleBooleanNode';

interface BasePayBreakdownNodeProps {
  basePayBreakdown: BasePayBreakdown;
  currency: string;
  id: string;
}

const BasePayBreakdownNode = (props: BasePayBreakdownNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`Base Pay Breakdown {${Object.keys(
          props.basePayBreakdown,
        ).length.toString()}}`}</Typography>
      }
    >
      <SinglePayoutNode
        id={composeNodeID([props.id, basePayNodeID])}
        name='Base Pay'
        amount={props.basePayBreakdown.basePay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, realTimeAdjustmentNodeID])}
        name='Real Time Adjustment'
        amount={props.basePayBreakdown.realTimeAdjustment}
        currency={props.currency}
      />
      <SingleBooleanNode
        id={composeNodeID([props.id, isVariableBasePayNodeID])}
        name='Is Variable Base Pay'
        value={props.basePayBreakdown.isVariableBasePay}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default BasePayBreakdownNode;
