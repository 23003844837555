import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  StyledEngineProvider,
  TextField,
  TextFieldProps,
  Theme,
  ThemeProvider,
  Typography,
  InputAdornment,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ChevronRight } from '@mui/icons-material';
import createStyles from '@mui/styles/createStyles';
import { useRecoilState, useRecoilValue } from 'recoil';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { isEmpty, isEqual } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import {
  sharedColors,
  timePickerTheme,
  useSharedStyles,
} from '../../utilities/Styles';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { isPermitted } from '../../variables/Users';
import { multiClass } from '../../utilities/Extensions';
import { Permission } from '../../interfaces/Users';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../atoms/Location';
import { getLocations, getMetros } from '../../services/locations';
import Waiting from '../Waiting';
import { getDefaultFilters, missionCategories } from '../../variables/Mission';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';
import MultiMetroAutocomplete from '../MultiMetroAutocomplete';
import { PayRateStatus } from '../../interfaces/PayRate';
import { MissionsList } from '../../interfaces/Mission/Mission';
import { getMissions } from '../../services/missions';
import { TablePaginationOptions } from '../../interfaces/Common';
import MissionTable from './MissionTable';
import { missionPageUrl } from '../../variables/Pages';
import UploadMissionsDialog from './UploadMissionsDialog';
import { MissionFilters } from '../../interfaces/Mission/MissionFilters';
import {
  getMissionFilterQueryParams,
  getMissionFiltersFromQueryParams,
} from '../../utilities/Mission';

export const Missions = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();
  const location = useLocation();

  const permissions = useRecoilValue(permissionsAtom);
  const [searchedDriverId, setSearchedDriverId] = useState('');
  const [locations, setLocations] = useRecoilState(locationsAtom);
  const [metros, setMetros] = useRecoilState(metrosAtom);

  const [appliedFilters, setAppliedFilters] = useState(getDefaultFilters());
  const [filters, setFilters] = useState(getDefaultFilters());
  const [missionsList, setMissionsList] = useState<MissionsList>({
    missions: [],
    totalCount: 0,
  });
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingMetros, setLoadingMetros] = useState(false);
  const [loadingMissions, setLoadingMissions] = useState(false);

  const refreshMissions = (
    pagination: TablePaginationOptions,
    queriedFilters?: MissionFilters,
  ) => {
    setLoadingMissions(true);
    const filtersToUse = queriedFilters ?? { ...filters, pagination };
    getMissions(filtersToUse)
      .then((fetchedMissionsList) => {
        setAppliedFilters(filtersToUse);
        setMissionsList(fetchedMissionsList);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingMissions(false));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    try {
      const parsedFilters = getMissionFiltersFromQueryParams(params);
      setSearchedDriverId(
        parsedFilters.driverID == null ? '' : parsedFilters.driverID,
      );
      setFilters(parsedFilters);
      refreshMissions(parsedFilters.pagination, parsedFilters);
      return;
    } catch (err) {
      toast.error('Failed to parse filters from URL');
    }

    refreshMissions(appliedFilters.pagination);
  }, []);

  useEffect(() => {
    const queryParams = getMissionFilterQueryParams(appliedFilters);

    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    if (!isEmpty(queryParams)) {
      newUrl += `?${queryParams
        .map((param) => `${param.key}=${encodeURIComponent(param.value)}`)
        .join('&')}`;
    }
    window.history.pushState({ path: newUrl }, '', newUrl);
  }, [appliedFilters]);

  useEffect(() => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));

    setLoadingMetros(true);
    getMetros()
      .then((fetchedMetros) => setMetros(fetchedMetros))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingMetros(false));
  }, []);

  const handleClickNewMission = () => {
    if (navigate) {
      navigate(`${missionPageUrl}/new`);
    }
  };

  const handleChangeActiveAfter = (newActiveAfter: Date | null) => {
    setFilters({ ...filters, activeAfter: newActiveAfter });
  };

  const handleChangeActiveBefore = (newActiveBefore: Date | null) => {
    setFilters({ ...filters, activeBefore: newActiveBefore });
  };

  const handleClearSearchedDriverId = () => {
    setFilters({ ...filters, driverID: null });
    setSearchedDriverId('');
  };

  const handleChangeSearchedDriverId = (e: any) => {
    const filteredValue = (e.target.value as string).replace(/[^\d]/g, '');
    setSearchedDriverId(filteredValue);
  };

  const handleSubmitSearchedDriverId = () => {
    const newDriverIdToSearch =
      searchedDriverId === '' ? null : searchedDriverId;
    if (newDriverIdToSearch !== filters.driverID) {
      setFilters({ ...filters, driverID: newDriverIdToSearch });
    }
  };

  const handleSearchedDriverIdFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmitSearchedDriverId();
    }
  };

  const handleSelectLocations = (newSelectedLocationIDs: string[]) => {
    setFilters({ ...filters, locationIDs: newSelectedLocationIDs });
  };

  const handleSelectMetros = (newSelectedMetroIDs: string[]) => {
    setFilters({ ...filters, metroIDs: newSelectedMetroIDs });
  };

  const handleSelectMissionCategories = (e: any) => {
    setFilters({ ...filters, types: e.target.value ?? [] });
  };

  const handleSelectStatuses = (e: any) => {
    setFilters({ ...filters, statuses: e.target.value ?? [] });
  };

  const handleToggleOnlyActiveDPSwitch = () => {
    setFilters({
      ...filters,
      onlyAllowingActiveDP: !filters.onlyAllowingActiveDP,
    });
  };

  const handleToggleOnlyExperimentsSwitch = () => {
    setFilters({
      ...filters,
      onlyExperiments: !filters.onlyExperiments,
    });
  };

  const handleFilter = () => {
    refreshMissions({
      page: 1,
      rowsPerPage: appliedFilters.pagination.rowsPerPage,
    });
  };

  const handlePagination = (pagination: TablePaginationOptions) => {
    refreshMissions(pagination);
  };

  const loading = loadingMetros || loadingLocations || loadingMissions;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <UploadMissionsDialog
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        onUpload={() =>
          refreshMissions({
            page: 1,
            rowsPerPage: appliedFilters.pagination.rowsPerPage,
          })
        }
      />
      <Paper className={classes.header}>
        <Typography className={multiClass([sharedClasses.h2, classes.title])}>
          GoMission Incentive Platform
        </Typography>
        <Button
          color='primary'
          variant='contained'
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.SET_MISSIONS)}
          onClick={handleClickNewMission}
        >
          + New Mission
        </Button>
        <Button
          color='primary'
          variant='contained'
          startIcon={<FileUploadIcon />}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.SET_MISSIONS)}
          onClick={() => setShowUploadDialog(true)}
        >
          Upload Missions
        </Button>
      </Paper>
      <Box component='div' className={classes.body}>
        <Box component='div' className={classes.filters}>
          <Box component='div' className={classes.driverFilter}>
            <TextField
              variant='outlined'
              size='small'
              label='Invited Delivery Partner ID'
              onChange={handleChangeSearchedDriverId}
              onKeyDown={handleSearchedDriverIdFieldKeyDown}
              value={searchedDriverId}
              InputProps={{
                startAdornment: filters.driverID && (
                  <InputAdornment position='start'>
                    <ClearIcon
                      onClick={handleClearSearchedDriverId}
                      className={classes.textFieldIcon}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon
                      onClick={handleSubmitSearchedDriverId}
                      className={classes.textFieldIcon}
                    />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
            />
          </Box>
          <Box component='div' className={classes.locationFilter}>
            <MultiLocationAutocomplete
              label='Location Name'
              width={270}
              locations={locations}
              selectedIDs={filters.locationIDs}
              onSetSelectedIDs={handleSelectLocations}
            />
          </Box>
          <Box component='div' className={classes.metroFilter}>
            <MultiMetroAutocomplete
              label='Metros'
              width={270}
              metros={metros}
              selectedIDs={filters.metroIDs}
              onSetSelectedIDs={handleSelectMetros}
            />
          </Box>

          <Box component='div'>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={timePickerTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Active After'
                    value={filters.activeAfter}
                    onChange={handleChangeActiveAfter}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps,
                    ) => (
                      <Box component='div' className={classes.dateTextField}>
                        <TextField
                          {...params}
                          size='small'
                          className={classes.datePicker}
                        />
                        {filters.activeAfter && (
                          <IconButton
                            onClick={() => handleChangeActiveAfter(null)}
                            className={classes.clearDateButton}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Box>
          <Box component='div'>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={timePickerTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Active Before'
                    value={filters.activeBefore}
                    onChange={handleChangeActiveBefore}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps,
                    ) => (
                      <Box component='div' className={classes.dateTextField}>
                        <TextField
                          {...params}
                          size='small'
                          className={classes.datePicker}
                        />
                        {filters.activeBefore && (
                          <IconButton
                            onClick={() => handleChangeActiveBefore(null)}
                            className={classes.clearDateButton}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Box>
          <FormControl
            className={multiClass([
              classes.menuDropdown,
              isEmpty(filters.types)
                ? classes.emptyFilter
                : classes.selectedFilter,
            ])}
            size='small'
            variant='outlined'
          >
            <InputLabel
              className={
                isEmpty(filters.types)
                  ? classes.emptyFilter
                  : classes.selectedFilter
              }
            >
              Mission Type
            </InputLabel>
            <Select
              id='mission-type-select'
              onChange={handleSelectMissionCategories}
              value={filters.types}
              multiple
            >
              {missionCategories.map((missionCategory) => (
                <MenuItem value={missionCategory.kind}>
                  {missionCategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={multiClass([
              classes.menuDropdown,
              isEmpty(filters.statuses)
                ? classes.emptyFilter
                : classes.selectedFilter,
            ])}
            size='small'
            variant='outlined'
          >
            <InputLabel
              className={
                isEmpty(filters.statuses)
                  ? classes.emptyFilter
                  : classes.selectedFilter
              }
            >
              Status
            </InputLabel>
            <Select
              id='status-select'
              onChange={handleSelectStatuses}
              value={filters.statuses}
              multiple
            >
              {Object.keys(PayRateStatus).map((status) => (
                <MenuItem value={status}>
                  {status[0] + status.slice(1).toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={filters.onlyAllowingActiveDP}
                onChange={handleToggleOnlyActiveDPSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                color='success'
              />
            }
            label={
              <Typography>Only missions that allow active DPs:</Typography>
            }
            labelPlacement='start'
            className={classes.onlyAllowingActiveDPSwitch}
          />
          <FormControlLabel
            control={
              <Switch
                checked={filters.onlyExperiments}
                onChange={handleToggleOnlyExperimentsSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                color='success'
              />
            }
            label={<Typography>Only experiment missions:</Typography>}
            labelPlacement='start'
            className={classes.onlyAllowingActiveDPSwitch}
          />
          {!isEqual(
            { ...filters, pagination: undefined },
            { ...appliedFilters, pagination: undefined },
          ) && (
            <Button
              color='primary'
              endIcon={<ChevronRight />}
              onClick={handleFilter}
              className={multiClass([
                sharedClasses.buttonText,
                classes.applyButton,
              ])}
            >
              Apply Filters
            </Button>
          )}
        </Box>
        <MissionTable
          missionsList={missionsList}
          pagination={appliedFilters.pagination}
          onPagination={handlePagination}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    title: {
      color: sharedColors.gray7,
      flexGrow: 1,
    },
    button: {
      textTransform: 'none',
      marginLeft: theme.spacing(1.5),
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexGrow: 1,
    },
    selectedFilter: {
      backgroundColor: sharedColors.white,
    },
    emptyFilter: {
      backgroundColor: sharedColors.gray2,
    },
    locationFilter: {
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    driverFilter: {
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    metroFilter: {
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    datePicker: {
      width: 175,
      marginRight: theme.spacing(1.5),
    },
    menuDropdown: {
      minWidth: 200,
      marginBottom: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    onlyAllowingActiveDPSwitch: {
      marginLeft: 0,
      marginTop: 'auto',
      marginBottom: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    applyButton: {
      marginBottom: theme.spacing(1.5),
    },
    textFieldIcon: {
      color: sharedColors.gray4,
      cursor: 'pointer',
    },
    textField: {
      backgroundColor: sharedColors.white,
      width: '258px',
      marginLeft: theme.spacing(1),
    },
    dateTextField: {
      position: 'relative',
      display: 'inline-block',
    },
    clearDateButton: {
      position: 'absolute',
      margin: 'auto',
      right: '2rem',
    },
  }),
);

export default Missions;
