import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { toast } from 'material-react-toastify';

import { useRecoilState } from 'recoil';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import Waiting from '../Waiting';

import { multiClass } from '../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { getPagesListByPermission } from '../../variables/Pages';

import {
  filteredLocationIDs as filteredLocationIDsAtom,
  locations as locationsAtom,
} from '../../atoms/Location';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { permissions as permissionsAtom } from '../../atoms/Users';

import { getLocations } from '../../services/locations';
import { PageType } from '../../interfaces/Page';
import { getUserPermissions } from '../../variables/Users';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';
import TargetCommissionsTable from './TargetCommissionsTable';
import {
  getTargetCommissionCsvExport,
  getTargetCommissionsByLocationId,
} from '../../services/targetCommissions';
import { TargetCommission } from '../../interfaces/TargetCommissions';
import { targetCommissionToEditAtom } from '../../atoms/TargetCommissions';
import { UpdateTargetCommissionDialog } from './UpdateTargetCommissionDialog';
import { downloadExportedCsv } from '../../utilities/Misc';
import TargetCommissionCSVUploadDialog from './TargetCommissionCSVUploadDialog';

export const targetCommissionImportCSVButtonTestId =
  'targetCommissionImportCSVButton';
export const targetCommissionExportCSVButtonTestId =
  'targetCommissionExportImportCSVButton';
export const targetCommissionHeaderTestId = 'targetCommissionHeader';

export const TargetCommissions = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;
  const location = useLocation();

  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const [locations, setLocations] = useRecoilState(locationsAtom);
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [showCSVUploadDialog, setShowCSVUploadDialog] = useState(false);
  const [targetCommissionToEdit, setTargetCommissionToEdit] = useRecoilState(
    targetCommissionToEditAtom,
  );

  const [loading, setLoading] = useState(false);
  const [targetCommissions, setTargetCommissions] = useState<
    TargetCommission[]
  >([]);

  const [filteredLocationIDs, setFilteredLocationIDs] = useRecoilState(
    filteredLocationIDsAtom,
  );

  const selectedAnyLocation = !isEmpty(filteredLocationIDs);

  const refreshTargetCommissions = async () => {
    setLoading(true);

    if (selectedAnyLocation) {
      getTargetCommissionsByLocationId(filteredLocationIDs)
        .then((fetchedTargetCommissions) => {
          setTargetCommissions(fetchedTargetCommissions);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.TARGET_COMMISSION) {
        index = i;
        break;
      }
    }
    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  useEffect(() => {
    refreshTargetCommissions();
  }, [filteredLocationIDs, locations]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const locationIdFiltersParam = params.get('location-ids');
    if (locationIdFiltersParam) {
      try {
        setFilteredLocationIDs(
          JSON.parse(decodeURIComponent(locationIdFiltersParam)),
        );
      } catch {
        toast.error('Failed to parse filters from URL');
      }
    }
  }, [selectedPage]);

  const handleSelectLocations = (newSelectedLocationIDs: string[]) => {
    setFilteredLocationIDs(newSelectedLocationIDs);

    const filtersString = encodeURIComponent(
      JSON.stringify(newSelectedLocationIDs),
    );
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?location-ids=${filtersString}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  let filteredTargetCommissions = targetCommissions;
  if (selectedAnyLocation) {
    filteredTargetCommissions = filteredTargetCommissions.filter(
      (targetCommission) =>
        filteredLocationIDs.includes(targetCommission.locationID),
    );
  } else {
    filteredTargetCommissions = [];
  }

  const handleCloseDialog = () => {
    setTargetCommissionToEdit(null);
  };

  const handleUpdate = () => {
    setTargetCommissionToEdit(null);
    refreshTargetCommissions();
  };

  const handleExport = () => {
    setLoading(true);
    getTargetCommissionCsvExport()
      .then((csvContent) => {
        downloadExportedCsv(csvContent, 'target_commission');
        toast.success('CSV download has started');
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <TargetCommissionCSVUploadDialog
        open={showCSVUploadDialog}
        onClose={() => setShowCSVUploadDialog(false)}
      />
      {!!targetCommissionToEdit && (
        <UpdateTargetCommissionDialog
          open={!!targetCommissionToEdit}
          onClose={handleCloseDialog}
          onDone={handleUpdate}
        />
      )}
      <Paper className={classes.header}>
        <Typography
          className={multiClass([sharedClasses.h2, classes.title])}
          data-testid={targetCommissionHeaderTestId}
        >
          {'Target Commissions'}
        </Typography>
        <Button
          id='target-commission-export-csv'
          color='primary'
          variant='contained'
          onClick={handleExport}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={false}
          data-testid={targetCommissionExportCSVButtonTestId}
        >
          {`Export CSV`}
        </Button>
        <Button
          id='target-commission-import-csv'
          color='primary'
          variant='contained'
          onClick={() => setShowCSVUploadDialog(true)}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={false}
          data-testid={targetCommissionImportCSVButtonTestId}
        >
          {`Import CSV`}
        </Button>
      </Paper>
      <Box component='div' className={classes.body}>
        <Box component='div' className={classes.filters}>
          <MultiLocationAutocomplete
            label='Location Name'
            locations={locations}
            selectedIDs={filteredLocationIDs}
            onSetSelectedIDs={handleSelectLocations}
            disabled={false}
          />
        </Box>
        {selectedAnyLocation ? (
          [
            !isEmpty(filteredTargetCommissions) && (
              <TargetCommissionsTable rows={filteredTargetCommissions} />
            ),
          ]
        ) : (
          <Box className={classes.warningMessageContainer}>
            <Grid
              container
              alignContent='center'
              justifyContent='center'
              className={classes.warningMessageGrid}
            >
              <Grid item xs={12}>
                <Typography className={classes.warningText}>
                  Please select a location to display target commissions
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {selectedAnyLocation && isEmpty(filteredTargetCommissions) && (
          <Box className={classes.warningMessageContainer}>
            <Grid
              container
              alignContent='center'
              justifyContent='center'
              className={classes.warningMessageGrid}
            >
              <Grid item xs={12}>
                <Typography className={classes.warningText}>
                  No target commission found for the selected location
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    title: {
      color: sharedColors.gray7,
      flexGrow: 1,
    },
    button: {
      textTransform: 'none',
      marginLeft: theme.spacing(1.5),
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    locationDropdown: {
      minWidth: 250,
      marginLeft: theme.spacing(1.5),
    },
    selectedFilter: {
      backgroundColor: sharedColors.white,
    },
    emptyFilter: {
      backgroundColor: sharedColors.gray2,
    },
    filterSpacer: {
      flexGrow: 1,
    },
    exportButton: {
      color: sharedColors.gray6,
      textTransform: 'none',
    },
    warningMessageContainer: {
      display: 'flex',
      flexGrow: 1,
      height: 600,
    },
    warningMessageGrid: {
      flexGrow: 1,
    },
    warningText: {
      flexGrow: 1,
      textAlign: 'center',
    },
  }),
);
