import { isArray, isObject } from 'lodash';
import {
  AdjustmentCategoriesAndSubtypes,
  AdjustmentSubtype,
} from '../interfaces/AdjustmentSubtype';
import api from './api';

export const getAdjustmentCategoriesAndSubtypes =
  async (): Promise<AdjustmentCategoriesAndSubtypes> => {
    const response = await api.get('/adjustments/categories-and-subtypes');

    const respData = response.data;
    if (!isArray(respData?.subtypes)) {
      throw new Error(
        'Could not get adjustment subtypes: invalid response data ("subtypes" is not array)',
      );
    }
    if (!isArray(respData?.categories)) {
      throw new Error(
        'Could not get adjustment categories: invalid response data ("categories" is not array)',
      );
    }

    return {
      subtypes: respData.subtypes,
      categories: respData.categories,
    };
  };

export const deleteAdjustmentSubtype = async (id: string): Promise<void> => {
  await api.delete(`/adjustments/subtypes/${id}`);
};

export const createAdjustmentSubtype = async (
  adjustmentType: string,
  description: string,
  requiresDate: boolean,
  requiresTextInput: boolean,
  thresholdAmount: number,
): Promise<AdjustmentSubtype> => {
  const resp = await api.post(`/adjustments/subtypes`, {
    adjustmentType,
    description,
    requiresDate,
    requiresTextInput,
    thresholdAmount,
  });

  if (!resp.data || !isObject(resp.data)) {
    throw new Error('could not create adjustment subtype: invalid response');
  }

  return resp.data as AdjustmentSubtype;
};
