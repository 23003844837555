import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from 'tss-react/mui';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useOktaAuth } from '@okta/okta-react';
import { toast } from 'material-react-toastify';
import {
  selectedPage as selectedPageAtom,
  appBarHeight as appBarHeightAtom,
} from '../atoms/PageState';
import { adjustmentSubtypes as adjustmentSubtypesAtom } from '../atoms/Adjustment';
import { permissions as permissionsAtom } from '../atoms/Users';

import { sharedColors, useSharedStyles } from '../utilities/Styles';
import { getPagesListByPermission } from '../variables/Pages';
import SubtypeSettingsDialog from './Adjustments/AdjustmentSubtypes/SubtypeSettingsDialog';
import { isPermitted } from '../variables/Users';
import { Permission } from '../interfaces/Users';
import AdjustmentsSideBar from './Adjustments/Adjustments/AdjustmentsSideBar';
import { AdjustmentEntity, AdjustmentStatus } from '../interfaces/Adjustment';
import { getAdjustments } from '../services/adjustments';
import Waiting from './Waiting';
import { getAdjustmentCategoriesAndSubtypes } from '../services/adjustmentSubtypes';
import { adjustmentNotificationCount } from '../variables/Adjustment';
import { PageCategory } from '../interfaces/Page';
import { multiClass } from '../utilities/Extensions';

export const categoryLabelTestIDPrefix = 'category-label-';

const DummyScrollButton = () => <></>;

const AppHeader = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const oktaAuth = useOktaAuth()?.oktaAuth || null;

  const navigate = useNavigate();

  const ref = useRef(null);

  const setAdjustmentSubtypes = useSetRecoilState(adjustmentSubtypesAtom);
  const setAppBarHeight = useSetRecoilState(appBarHeightAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const permissions = useRecoilValue(permissionsAtom);

  const [showAdjustmentSubtypeSettings, setShowAdjustmentSubtypeSettings] =
    useState(false);
  const [showAdjustmentsSideBar, setShowAdjustmentsSideBar] = useState(false);
  const [pendingAdjustments, setPendingAdjustments] = useState<
    AdjustmentEntity[]
  >([]);
  const [settingsPopoverAnchor, setSettingsPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [categoryPopoverAnchor, setCategoryPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<PageCategory | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  const pages = getPagesListByPermission(permissions);
  const normalizedPages = pages.filter(
    (page, i) =>
      i === 0 ||
      !page.pageCategory ||
      page.pageCategory !== pages[i - 1].pageCategory,
  );

  useEffect(() => {
    if (ref && ref.current) {
      // @ts-ignore
      setAppBarHeight(ref.current.clientHeight);
    }
  });

  const getCategoryName = (pageCategory: PageCategory): string => {
    switch (pageCategory) {
      case PageCategory.PRICING:
        return 'Pricing';
      case PageCategory.HOLIDAY_PAY:
        return 'Holiday Pay';
      default:
        return 'Other';
    }
  };

  const generateTabs = (): JSX.Element[] => {
    return normalizedPages.map((page) => (
      <Tab
        label={
          <Typography
            className={classes.tabLabel}
            data-testid={
              page.pageCategory
                ? categoryLabelTestIDPrefix + page.pageCategory
                : undefined
            }
          >
            {page.pageCategory ? getCategoryName(page.pageCategory) : page.name}
            {page.pageCategory ? (
              <ArrowDropDownIcon className={classes.dropdownIcon} />
            ) : (
              <></>
            )}
          </Typography>
        }
        key={page.name}
        onClick={
          page.pageCategory
            ? (e: any) => {
                setCategoryPopoverAnchor(e.currentTarget);
                setSelectedCategory(page.pageCategory!);
              }
            : undefined
        }
      />
    ));
  };

  const generateCategoryPopoverContent = (): JSX.Element[] => {
    return pages
      .filter((page) => page.pageCategory === selectedCategory)
      .map((page) => (
        <Button
          onClick={() => {
            if (navigate) {
              setSelectedPage(pages.indexOf(page));
              navigate(page.path);
            }
          }}
          className={multiClass([classes.tabLabel, classes.categoryContent])}
        >
          {page.name}
        </Button>
      ));
  };

  const getNormalizedPageIndex = (selectedIndex: number): number | null => {
    const actualSelectedPage = pages.find(
      (page) => page.pageType === normalizedPages[selectedIndex].pageType,
    );
    if (actualSelectedPage?.pageCategory) {
      return null;
    }
    return actualSelectedPage ? pages.indexOf(actualSelectedPage) : 0;
  };

  const getTabIndex = () => {
    const normalizedIndices = [0];
    pages
      .slice(1)
      .forEach((page, i) =>
        normalizedIndices.push(
          !page.pageCategory || page.pageCategory !== pages[i].pageCategory
            ? normalizedIndices[i] + 1
            : normalizedIndices[i],
        ),
      );

    return normalizedIndices[selectedPage];
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const normalizedIndex = getNormalizedPageIndex(newValue);
    if (normalizedIndex !== null) {
      setSelectedPage(normalizedIndex);
      if (navigate) {
        navigate(pages[normalizedIndex].path);
      }
    }
  };

  const handleShowSettingsPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSettingsPopoverAnchor(event.currentTarget);
  };
  const handleCloseSettingsPopover = () => {
    setSettingsPopoverAnchor(null);
  };

  const handleLogout = () => {
    if (oktaAuth) {
      oktaAuth
        .signOut()
        .then(() => {
          if (navigate) {
            navigate('');
          }
        })
        .catch((err) => {
          toast.error(`Error while signing out: ${err.message}`);
        });
    }
  };

  const canViewPendingAdjustments = isPermitted(
    permissions,
    Permission.SET_ADJUSTMENT_APPROVALS,
  );

  useEffect(() => {
    if (canViewPendingAdjustments) {
      refreshAdjustments();
      getAdjustmentCategoriesAndSubtypes()
        .then((categoriesAndSubtypes) =>
          setAdjustmentSubtypes(categoriesAndSubtypes.subtypes),
        )
        .catch((err) => toast.error(err.message));
    }
  }, []);

  const canConfigureAdjustmentSubtypes = isPermitted(
    permissions,
    Permission.SET_ADJUSTMENT_TYPES,
  );

  const toggleSubtypeSettings = (didUpdate: boolean) => {
    setShowAdjustmentSubtypeSettings(
      canConfigureAdjustmentSubtypes && !showAdjustmentSubtypeSettings,
    );
    if (didUpdate) {
      setLoading(true);
      getAdjustmentCategoriesAndSubtypes()
        .then((categoriesAndSubtypes) =>
          setAdjustmentSubtypes(categoriesAndSubtypes.subtypes),
        )
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    }
  };

  const toggleAdjustmentsSideBar = () => {
    refreshAdjustments();
    setShowAdjustmentsSideBar(!showAdjustmentsSideBar);
  };

  const refreshAdjustments = () => {
    getAdjustments(
      { status: AdjustmentStatus.PendingApproval },
      { page: 1, rowsPerPage: adjustmentNotificationCount + 1 },
    )
      .then((adjs) => {
        setPendingAdjustments(adjs.adjustments);
      })
      .catch(() => {
        toast.error('unable to load pending adjustments');
      });
  };

  const showSettingsPopover = Boolean(settingsPopoverAnchor);
  const settingsPopoverId = showSettingsPopover
    ? 'settings-popover'
    : undefined;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <AppBar
        className={classes.appBar}
        position='fixed'
        elevation={1}
        ref={ref}
      >
        <Box component='div' className={classes.toolbar}>
          <Box component='div' className={classes.tabNavigationContainer}>
            <Tabs
              variant='scrollable'
              value={getTabIndex()}
              onChange={handleTabChange}
              ScrollButtonComponent={DummyScrollButton}
            >
              {generateTabs()}
            </Tabs>
            <Popover
              id='category-popover'
              open={!!categoryPopoverAnchor && !!selectedCategory}
              anchorEl={categoryPopoverAnchor}
              onClose={() => {
                setCategoryPopoverAnchor(null);
                setSelectedCategory(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box className={classes.popoverContainer}>
                {generateCategoryPopoverContent()}
              </Box>
            </Popover>
          </Box>
          {canViewPendingAdjustments && (
            <IconButton
              edge='end'
              className={sharedClasses.iconColor}
              onClick={toggleAdjustmentsSideBar}
              size='large'
            >
              <Badge
                overlap='circular'
                badgeContent={
                  pendingAdjustments.length > adjustmentNotificationCount
                    ? `${adjustmentNotificationCount}+`
                    : pendingAdjustments.length
                }
                color='error'
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          <IconButton
            edge='end'
            className={sharedClasses.iconColor}
            onClick={handleShowSettingsPopover}
            size='large'
          >
            <SettingsIcon />
          </IconButton>
          <Popover
            id={settingsPopoverId}
            open={showSettingsPopover}
            anchorEl={settingsPopoverAnchor}
            onClose={handleCloseSettingsPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box className={classes.popoverContainer}>
              {canConfigureAdjustmentSubtypes && (
                <Button
                  onClick={() => toggleSubtypeSettings(false)}
                  className={classes.popoverButton}
                >
                  Settings
                </Button>
              )}
              <Button onClick={handleLogout} className={classes.popoverButton}>
                Sign Out
              </Button>
            </Box>
          </Popover>
        </Box>
      </AppBar>
      <SubtypeSettingsDialog
        open={showAdjustmentSubtypeSettings}
        onClose={toggleSubtypeSettings}
      />
      {canViewPendingAdjustments && (
        <AdjustmentsSideBar
          open={showAdjustmentsSideBar}
          pendingAdjustments={pendingAdjustments}
          onClose={toggleAdjustmentsSideBar}
          onApprovalUpdate={refreshAdjustments}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: '#FFFFFF',
    overflowX: 'auto',
  },
  title: {
    fontFamily: 'Roboto',
    color: 'black',
    flexGrow: 1,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
  },
  icon: {
    margin: theme.spacing(1),
  },
  iconButtonMargin: {
    marginRight: theme.spacing(1),
  },
  popoverButton: {
    padding: '20px',
    textTransform: 'none',
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabLabel: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: sharedColors.gray6,
    display: 'flex',
    alignItems: 'center',
  },
  tabNavigationContainer: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: 0,
    marginTop: theme.spacing(1.5),
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  dropdownIcon: {
    marginLeft: theme.spacing(0.5),
  },
  categoryContent: {
    textAlign: 'start',
    justifyContent: 'start',
    padding: theme.spacing(1.5),
  },
}));

export default AppHeader;
