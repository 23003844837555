import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';

import { locations as locationsAtom } from '../../../atoms/Location';
import {
  FirstShiftBonus,
  PayRateOverviewProps,
} from '../../../interfaces/PayRate';
import {
  getCurrencySymbolFromLocation,
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import { formatDateTime } from '../../../utilities/Dates';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';

export const FirstShiftBonusOverview = (props: PayRateOverviewProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const firstShiftBonus = props.payRate as FirstShiftBonus;

  const location = filterLocationById(
    locations,
    parseInt(firstShiftBonus.locationID, 10),
  );
  const timeZone =
    getTimeZoneByLocationId(
      locations,
      parseInt(firstShiftBonus.locationID, 10),
    ) || 'UTC';

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start Date
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(firstShiftBonus.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='endDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            End Date
          </Typography>
          <Typography className={sharedClasses.body1}>
            {firstShiftBonus.endAt.valid && firstShiftBonus.endAt.time
              ? formatDateTime(firstShiftBonus.endAt.time, timeZone)
              : '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='bonusRate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Bonus Rate
          </Typography>
          <Typography className={sharedClasses.body1}>
            {firstShiftBonus.amount &&
              `${getCurrencySymbolFromLocation(
                location,
              )}${firstShiftBonus.amount.toFixed(2)}`}
          </Typography>
        </Box>
      </Grid>
      <Grid key='minimumOrders' item xs={6}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Minimum Orders
          </Typography>
          <Typography className={sharedClasses.body1}>
            {firstShiftBonus.minOrders || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='minimumHours' item xs={6}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Minimum Hours
          </Typography>
          <Typography className={sharedClasses.body1}>
            {firstShiftBonus.minHours || '-'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
