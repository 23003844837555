import { SignUpStatus } from '../SignUpStatus';

export interface PayoutProviderSummary {
  name: string;
  enabled: boolean;
  signUpStatus: SignUpStatus;
  instantCashoutEligible: boolean;
  dueRequirements: string[];
}

export const payoutProviderSummaryFromResponse = (
  responseData: any,
): PayoutProviderSummary => ({
  name: responseData.name ?? 'Unknown provider',
  enabled: !!responseData.enabled,
  signUpStatus: responseData.sign_up_status ?? SignUpStatus.UNKNOWN,
  instantCashoutEligible: !!responseData.instant_cashout_eligible,
  dueRequirements: responseData.due_requirements ?? [],
});
