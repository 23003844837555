import { isEmpty } from 'lodash';
import { Location } from '../../interfaces/Location';
import { QueryParameter } from '../../interfaces/Url';
import { TimeRatePipelineSettings } from '../../interfaces/Settings/TimeRatePipelineSettings';
import { payRateStatusFromTimeRange } from '../PayRates';
import { payRateStatusPriorities } from '../../variables/PayRate';

export const getCountryLocations = (): Location[] => [
  {
    id: -1,
    name: 'US Default',
    friendlyName: 'US Default',
    countryCode: 'US',
    timeZone: 'America/Los_Angeles',
    state: 'US',
    city: 'Country Defaults',
    currency: 'USD',
    isW2Location: false,
    isEngagedLocation: false,
  },
  {
    id: -2,
    name: 'GB Default',
    friendlyName: 'GB Default',
    countryCode: 'GB',
    timeZone: 'UTC',
    state: 'GB',
    city: 'Country Defaults',
    currency: 'GBP',
    isW2Location: false,
    isEngagedLocation: false,
  },
];

export const mapSettingsLocationIDToInt = (locationIDAsStr: string): number => {
  if (locationIDAsStr === 'us_default') {
    return -1;
  }
  if (locationIDAsStr === 'gb_default') {
    return -2;
  }

  return parseInt(locationIDAsStr, 10);
};

export const mapSettingsLocationIDToStr = (locationIDAsInt: number): string => {
  if (locationIDAsInt === -1) {
    return 'us_default';
  }
  if (locationIDAsInt === -2) {
    return 'gb_default';
  }

  return locationIDAsInt.toString();
};

export const handleCountryLocationID = (locationID: string): string => {
  if (locationID === '-1') {
    return 'us_default';
  }
  if (locationID === '-2') {
    return 'gb_default';
  }

  return locationID;
};

export const getSettingsFilterQueryParams = (
  filteredLocationIDs: string[],
  filteredMetroIDs: string[],
  activeSettingsChecked: boolean,
): QueryParameter[] => {
  const params: QueryParameter[] = [];
  if (activeSettingsChecked) {
    params.push({
      key: 'all-active',
      value: '1',
    });
  } else {
    if (!isEmpty(filteredLocationIDs)) {
      params.push({
        key: 'location-ids',
        value: filteredLocationIDs.join(','),
      });
    }
    if (!isEmpty(filteredMetroIDs)) {
      params.push({
        key: 'metro-ids',
        value: filteredMetroIDs.join(','),
      });
    }
  }

  return params;
};

export const isCountrySettings = (
  settings: TimeRatePipelineSettings,
): boolean => settings.locationID.includes('_default');

export const settingsComparator = (
  a: TimeRatePipelineSettings,
  b: TimeRatePipelineSettings,
): number => {
  const isCountryA = isCountrySettings(a);
  const isCountryB = isCountrySettings(b);
  if (isCountryA && !isCountryB) {
    return -1;
  }
  if (!isCountryA && isCountryB) {
    return 1;
  }

  const statusA = payRateStatusFromTimeRange(a.startAt, a.endAt);
  const statusB = payRateStatusFromTimeRange(b.startAt, b.endAt);
  if (payRateStatusPriorities[statusA] > payRateStatusPriorities[statusB]) {
    return -1;
  }
  if (payRateStatusPriorities[statusA] < payRateStatusPriorities[statusB]) {
    return 1;
  }

  return a.locationID < b.locationID ? -1 : 1;
};
