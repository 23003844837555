import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from '@mui/lab';
import React from 'react';
import { isFinite } from 'lodash';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { DailyPayoutBreakdown } from '../../../interfaces/PayHistory/DailyPayoutBreakdown';
import {
  basePayNodeID,
  boostPayNodeID,
  doXGetYNodeID,
  composeNodeID,
  dailyBreakdownNodeID,
  dailyNodePrefix,
  hazardPayNodeID,
  hoursNodeID,
  orderCountNodeID,
  ordersNodeID,
  returnCocPayNodeID,
  scheduledOrderCountNodeID,
  seattleEngagedPayNodeID,
  tier2DeliveryPayNodeID,
  timezoneNodeID,
  tipsNodeID,
  tobaccoPayNodeID,
  totalAmountNodeID,
  tripsNodeID,
  unscheduledOrderCountNodeID,
} from '../../../variables/PayHistory';
import { multiClass } from '../../../utilities/Extensions';
import SingleTextNode from './SingleTextNode';
import SinglePayoutNode from './SinglePayoutNode';
import OrderBreakdownNode from './OrderBreakdown/OrderBreakdownNode';
import TripBreakdownNode from './TripBreakdown/TripBreakdownNode';
import WaitPayBreakdownListNode from './WaitPay/WaitPayBreakdownListNode';
import HolidayPayListNode from './HolidayPay/HolidayPayBreakdownListNode';

interface DailyPayoutTreeProps {
  dailyPayoutBreakdown: DailyPayoutBreakdown;
  currency: string;
  timezone: string;
  highlightedNodes: string[];
  expandedNodes: string[];
  onExpand: (nodeIDs: string[]) => void;
}

const DailyPayoutTree = (props: DailyPayoutTreeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const handleToggle = (event: React.SyntheticEvent, nodeIDs: string[]) => {
    props.onExpand(nodeIDs);
  };

  return (
    <Box component='div' className={classes.treeContainer}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        selected={props.highlightedNodes}
        expanded={props.expandedNodes}
        onNodeToggle={handleToggle}
        multiSelect
      >
        <TreeItem
          nodeId={dailyBreakdownNodeID}
          label={
            <Typography
              className={multiClass([classes.nodeLabel, sharedClasses.body1])}
            >{`Daily Breakdown {${Object.keys(
              props.dailyPayoutBreakdown,
            ).length.toString()}}`}</Typography>
          }
        >
          <SingleTextNode
            id={timezoneNodeID}
            name='Timezone'
            value={props.timezone}
          />
          <SingleTextNode
            id={composeNodeID([dailyNodePrefix, orderCountNodeID])}
            name='Order Count'
            value={props.dailyPayoutBreakdown.orderCount.toString()}
          />
          <SingleTextNode
            id={composeNodeID([dailyNodePrefix, scheduledOrderCountNodeID])}
            name='Scheduled Order Count'
            value={props.dailyPayoutBreakdown.scheduledOrderCount.toString()}
          />
          <SingleTextNode
            id={composeNodeID([dailyNodePrefix, unscheduledOrderCountNodeID])}
            name='Unscheduled Order Count'
            value={props.dailyPayoutBreakdown.unscheduledOrderCount.toString()}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, totalAmountNodeID])}
            name='Total Amount'
            amount={props.dailyPayoutBreakdown.totalAmount}
            currency={props.currency}
          />
          <SingleTextNode
            id={composeNodeID([dailyNodePrefix, hoursNodeID])}
            name='Hours'
            value={props.dailyPayoutBreakdown.hours.toString()}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, tipsNodeID])}
            name='Tips'
            amount={props.dailyPayoutBreakdown.tips}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, basePayNodeID])}
            name='Base Pay'
            amount={props.dailyPayoutBreakdown.basePay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, boostPayNodeID])}
            name='Boost Pay'
            amount={props.dailyPayoutBreakdown.boostPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, doXGetYNodeID])}
            name='Do X Get Y'
            amount={props.dailyPayoutBreakdown.doXGetY}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, hazardPayNodeID])}
            name='Hazard Pay'
            amount={props.dailyPayoutBreakdown.hazardPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, tier2DeliveryPayNodeID])}
            name='Tier 2 Delivery Pay'
            amount={props.dailyPayoutBreakdown.tier2DeliveryPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, tobaccoPayNodeID])}
            name='Tobacco Pay'
            amount={props.dailyPayoutBreakdown.tobaccoPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([dailyNodePrefix, returnCocPayNodeID])}
            name='Return Pay'
            amount={props.dailyPayoutBreakdown.returnCocPay}
            currency={props.currency}
          />
          {isFinite(props.dailyPayoutBreakdown.seattleEngagedPay) && (
            <SinglePayoutNode
              id={composeNodeID([dailyNodePrefix, seattleEngagedPayNodeID])}
              name='Engaged Pay'
              amount={props.dailyPayoutBreakdown.seattleEngagedPay!}
              currency={props.currency}
            />
          )}
          <WaitPayBreakdownListNode
            waitPayBreakdowns={props.dailyPayoutBreakdown.waitPayBreakdowns}
            weekly={false}
            displayShifts
            amount={props.dailyPayoutBreakdown.totalWaitPay}
            currency={props.currency}
            timezone={props.timezone}
          />
          <TreeItem
            nodeId={ordersNodeID}
            label={
              <Typography
                className={multiClass([classes.nodeLabel, sharedClasses.body1])}
              >
                {`Order Breakdowns [${props.dailyPayoutBreakdown.orderBreakdowns.length.toString()}]`}
              </Typography>
            }
          >
            {props.dailyPayoutBreakdown.orderBreakdowns.map(
              (orderBreakdown, i) => (
                <OrderBreakdownNode
                  orderBreakdown={orderBreakdown}
                  currency={props.currency}
                  index={i}
                  timezone={props.timezone}
                  prefix={dailyNodePrefix}
                  isTripOrder={false}
                />
              ),
            )}
          </TreeItem>
          <TreeItem
            nodeId={tripsNodeID}
            label={
              <Typography
                className={multiClass([classes.nodeLabel, sharedClasses.body1])}
              >{`Trip Breakdowns [${props.dailyPayoutBreakdown.tripBreakdowns.length.toString()}]`}</Typography>
            }
          >
            {props.dailyPayoutBreakdown.tripBreakdowns.map(
              (tripBreakdown, i) => (
                <TripBreakdownNode
                  tripBreakdown={tripBreakdown}
                  currency={props.currency}
                  index={i}
                  timezone={props.timezone}
                />
              ),
            )}
          </TreeItem>
          <HolidayPayListNode
            holidayPayBreakdown={props.dailyPayoutBreakdown.holidayPayBreakdown}
            currency={props.currency}
          />
        </TreeItem>
      </TreeView>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  treeContainer: {
    borderRadius: 4,
    border: 'groove',
    borderColor: sharedColors.purple4,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: sharedColors.white,
  },
  nodeLabel: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default DailyPayoutTree;
