import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { parseCentsAsCurrencyString } from '../../utilities/Currency';
import { multiClass } from '../../utilities/Extensions';

interface SummaryDetailRowProps {
  name: string;
  secondaryText?: string;
  additionalDetailsComponent?: JSX.Element;
  amount: number;
  currency: string;
  onHover: () => void;
}

const SummaryDetailRow = (props: SummaryDetailRowProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box
      component='div'
      onMouseEnter={props.onHover}
      className={classes.outerWrapper}
    >
      <Box component='div' className={classes.mainContainer}>
        <Box
          component='div'
          className={multiClass([classes.column, classes.verticalCenter])}
        >
          <Typography className={sharedClasses.body1}>{props.name}</Typography>
          <Typography
            className={multiClass([
              classes.secondaryText,
              sharedClasses.caption,
            ])}
          >
            {props.secondaryText}
          </Typography>
        </Box>
        <Box component='div' className={classes.horizontalSpacer} />
        <Typography
          className={multiClass([sharedClasses.body1, classes.verticalCenter])}
        >
          {parseCentsAsCurrencyString(props.amount, props.currency)}
        </Typography>
      </Box>
      {props.additionalDetailsComponent}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  outerWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
  },
  mainContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalSpacer: {
    display: 'flex',
    flexGrow: 1,
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  column: {
    flexDirection: 'column',
  },
  secondaryText: {
    color: sharedColors.gray5,
  },
}));

export default SummaryDetailRow;
