import { ShiftBreakdown, shiftBreakdownFromResponse } from './ShiftBreakdown';
import { AppliedRate, appliedRateFromResponse } from './AppliedRate';

export interface MinGuaranteeAdjustmentBreakdown {
  locationName: string;
  shifts: ShiftBreakdown[];
  subsidizableHoursWorked: AppliedRate[];
  basePay: number;
  boostPay: number;
  scheduledHours: number;
  amount: number;
  version: number;
}

export const minGuaranteeAdjustmentBreakdownFromResponse = (
  responseData: any,
): MinGuaranteeAdjustmentBreakdown => ({
  locationName: responseData.location_name ?? '',
  shifts: responseData.shifts.map((shift: any) =>
    shiftBreakdownFromResponse(shift),
  ),
  subsidizableHoursWorked: responseData.subsidizable_hours_worked.map(
    (hoursWorked: any) => appliedRateFromResponse(hoursWorked),
  ),
  basePay: responseData.base_pay ?? 0,
  boostPay: responseData.boost_pay ?? 0,
  scheduledHours: responseData.scheduled_hours ?? 0,
  amount: responseData.amount ?? 0,
  version: responseData.version ?? 0,
});
