import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { multiClass } from '../../../utilities/Extensions';

export const adjustmentDenialWarningTestId = 'adjustmentDenialWarningTestId';

interface AdjustmentDenialConfirmDialogProps {
  open: boolean;
  managerNotes: string;
  onChangeManagerNotes: (newManagerNotes: string) => void;
  onConfirm: () => void;
  onClose: () => void;
}

const AdjustmentDenialConfirmDialog = (
  props: AdjustmentDenialConfirmDialogProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [checkedWarning, setCheckedWarning] = useState(false);

  useEffect(() => {
    setCheckedWarning(false);
  }, [props.open]);

  const handleCheckWarning = (e: any) => {
    setCheckedWarning(e.target.checked as boolean);
  };

  const handleChangeAdditionalNotes = (e: any) => {
    props.onChangeManagerNotes(e.target.value as string);
  };

  return (
    <Dialog open={props.open} maxWidth='xs' onClose={props.onClose}>
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Are you sure?
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography
          className={sharedClasses.subtitle1}
          data-testid={adjustmentDenialWarningTestId}
        >
          When denying a request, a manager should respond to the delivery
          partner via the Kustomer case linked to the request.
        </Typography>
        <TextField
          id='additional-notes'
          label={props.managerNotes === '' ? '' : 'Add Additional Notes'}
          placeholder='Add additional notes...'
          value={props.managerNotes}
          onChange={handleChangeAdditionalNotes}
          multiline
          variant='outlined'
          size='small'
          rows={3}
          fullWidth
          className={classes.additionalNotes}
        />
        <FormControlLabel
          control={
            <Checkbox checked={checkedWarning} onChange={handleCheckWarning} />
          }
          label={
            <Typography className={sharedClasses.body1}>
              I have updated the Kustomer case associated with this request
            </Typography>
          }
          className={classes.warningCheck}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='contained'
          color='inherit'
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={props.onConfirm}
          variant='contained'
          color='error'
          disabled={!checkedWarning}
          className={sharedClasses.buttonText}
        >
          Deny Adjustment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  dialogContent: {},
  warningCheck: {
    marginTop: theme.spacing(1.75),
  },
  additionalNotes: {
    marginTop: theme.spacing(1),
  },
}));

export default AdjustmentDenialConfirmDialog;
