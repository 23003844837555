import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DriverSupportSummary } from '../../interfaces/PayHistory/DriverSupportSummary';
import { formatDateAmericanReadable } from '../../utilities/Dates';
import { PayoutProvider } from '../../interfaces/PayHistory/PayoutProvider';

interface PayoutTableProps {
  driverSupportSummary: DriverSupportSummary;
}

const PayoutTable = (props: PayoutTableProps) => {
  const { classes } = useStyles();

  const getPayoutProviderName = (payoutProvider: PayoutProvider): string => {
    switch (payoutProvider) {
      case PayoutProvider.STRIPE:
        return 'Stripe';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box component='div'>
      <Typography className={classes.tableTitle}>
        Successful Transactions
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell key='transaction-type'>
              <Typography className={classes.tableFieldName}>
                Transaction Type
              </Typography>
            </TableCell>
            <TableCell key='time'>
              <Typography className={classes.tableFieldName}>Time</Typography>
            </TableCell>
            <TableCell key='payout-provider'>
              <Typography className={classes.tableFieldName}>
                Payout Provider
              </Typography>
            </TableCell>
            <TableCell key='account'>
              <Typography className={classes.tableFieldName}>
                Account
              </Typography>
            </TableCell>
            <TableCell key='subtotal'>
              <Typography className={classes.tableFieldName}>
                Subtotal
              </Typography>
            </TableCell>
            <TableCell key='fee'>
              <Typography className={classes.tableFieldName}>Fee</Typography>
            </TableCell>
            <TableCell key='total'>
              <Typography className={classes.tableFieldName}>Total</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.driverSupportSummary?.payouts
            .sort((p1, p2) => {
              if (!p1.startedAt) {
                return -1;
              }
              if (!p2.startedAt) {
                return 1;
              }
              return p1.startedAt > p2.startedAt ? -1 : 1;
            })
            .map((payoutSummary, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {payoutSummary.type}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {payoutSummary.startedAt
                      ? formatDateAmericanReadable(payoutSummary.startedAt)
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {getPayoutProviderName(payoutSummary.payoutProvider)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {payoutSummary.externalAccount}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {`$${(payoutSummary.weeklySubtotalAmount / 100).toFixed(
                      2,
                    )}${
                      payoutSummary.subtotalAmount ===
                      payoutSummary.weeklySubtotalAmount
                        ? ''
                        : ` ($${(payoutSummary.subtotalAmount / 100).toFixed(
                            2,
                          )})`
                    }`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {payoutSummary.feeIncluded &&
                      `$${(payoutSummary.feeAmount / 100).toFixed(2)}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableField}>
                    {`$${(payoutSummary.weeklyAmount / 100).toFixed(2)}`}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  tableTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
  },
  tableContainer: {
    display: 'table',
    flexDirection: 'column',
    width: '100%',
  },
  tableFieldName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  tableField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export default PayoutTable;
