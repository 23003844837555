import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  PayRateSchedule,
  PayRateScheduleFormProps,
  PayRateScheduleSpan,
} from '../../../../interfaces/PayRate';
import { sharedColors, useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import { weekdayAbbreviationsStartingMonday } from '../../../../variables/Dates';
import { payRateScheduleSpanRanges } from '../../../../variables/PayRate';
import { formatTimeCapital } from '../../../../utilities/Dates';
import SpanRateInput from './SpanRateInput';

export const PayRateScheduleForm = (props: PayRateScheduleFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const payRateSchedule = props.currentState as PayRateSchedule;

  const handleRateChange = (index: number, newValue: number) => {
    const newSpans = payRateSchedule.spans.slice();
    newSpans[index] = { ...newSpans[index], rate: newValue };

    const newPayRateSchedule: PayRateSchedule = {
      ...payRateSchedule,
      spans: newSpans,
    };
    props.setter(newPayRateSchedule);
  };

  const handleAutofill = (spanRangeIndex: number, rateToFill: number) => {
    const newSpans = payRateSchedule.spans.slice();
    for (
      let i = spanRangeIndex;
      i < newSpans.length;
      i += payRateScheduleSpanRanges.length
    ) {
      newSpans[i] = { ...newSpans[i], rate: rateToFill };
    }

    const newPayRateSchedule: PayRateSchedule = {
      ...payRateSchedule,
      spans: newSpans,
    };
    props.setter(newPayRateSchedule);
  };

  return (
    <Box component='div' className={classes.formWrapper}>
      <Divider className={classes.dashedDivider} />
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([
            sharedClasses.subtitle2,
            classes.verticalCenter,
          ])}
        >
          {`${props.isDisabled ? '' : 'Set the '}${
            props.title
          } time rates (per minute) for scheduled hours`}
        </Typography>
      </Box>
      <Grid container columns={26} className={classes.weekdayGrid}>
        <Grid item xs={5} />
        {weekdayAbbreviationsStartingMonday.map((weekday) => (
          <Grid item xs={3}>
            <Typography
              className={multiClass([
                sharedClasses.overline,
                classes.weekdayName,
              ])}
            >
              {weekday.toUpperCase()}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box component='div' className={classes.inputGridContainer}>
        <Grid container columns={26}>
          {payRateScheduleSpanRanges.map((spanRange, spanRangeIndex) => {
            const spans: PayRateScheduleSpan[] = [];
            for (
              let i = spanRangeIndex;
              i < payRateSchedule.spans.length;
              i += payRateScheduleSpanRanges.length
            ) {
              if (payRateSchedule.spans[i].rate) {
                spans.push(payRateSchedule.spans[i]);
              }
            }
            return [
              <Grid item xs={5}>
                <Box component='div' className={classes.columnContainer}>
                  <Box
                    component='div'
                    className={multiClass([
                      classes.column,
                      classes.verticalCenter,
                    ])}
                  >
                    <Typography className={sharedClasses.subtitle2}>
                      {spanRange.name}
                    </Typography>
                    <Typography className={classes.timeRangeText}>
                      {`${formatTimeCapital(
                        spanRange.timeStart,
                        0,
                      )} - ${formatTimeCapital(spanRange.timeEnd - 1, 59)}`}
                    </Typography>
                  </Box>
                  <Box component='div' className={classes.horizontalSpacer} />
                  <IconButton
                    size='small'
                    color='primary'
                    disabled={spans.length !== 1 || props.isDisabled}
                    onClick={() =>
                      handleAutofill(spanRangeIndex, spans[0].rate ?? 0)
                    }
                  >
                    <AutorenewIcon />
                  </IconButton>
                </Box>
              </Grid>,
              ...[0, 1, 2, 3, 4, 5, 6].map((weekDay) => {
                const index =
                  payRateScheduleSpanRanges.length * weekDay + spanRangeIndex;

                return (
                  <Grid item xs={3}>
                    <Box component='div' className={classes.textField}>
                      <SpanRateInput
                        name={`${weekDay}_${spanRangeIndex}`}
                        value={payRateSchedule.spans[index].rate}
                        onChange={(newValue) =>
                          handleRateChange(index, newValue)
                        }
                        isDisabled={props.isDisabled}
                      />
                    </Box>
                  </Grid>
                );
              }),
            ];
          })}
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
    },
    numberField: {
      width: 210,
      marginRight: theme.spacing(2),
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    verticalCenter: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    dashedDivider: {
      marginBottom: theme.spacing(0.75),
      marginTop: theme.spacing(0.5),
      borderStyle: 'dashed',
    },
    weekdayGrid: {
      backgroundColor: sharedColors.gray2,
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    weekdayName: {
      color: sharedColors.gray5,
      flexGrow: 1,
      textAlign: 'center',
    },
    columnContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    textField: {
      margin: theme.spacing(0.5),
    },
    timeRangeText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '12px',
      color: sharedColors.gray5,
      marginTop: theme.spacing(0.5),
    },
    inputGridContainer: {
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
);
