import { Tooltip, Typography } from '@mui/material';
import { AdjustmentStatus, AdjustmentEntity } from '../interfaces/Adjustment';
import { SortableTableHeadCell } from '../interfaces/Common';
import {
  easternTimezone,
  formatDateAmericanReadable,
  formatSingleDay,
  getNextPayoutTimeAsString,
} from '../utilities/Dates';
import { parseWorkweekApiString } from '../utilities/Workweek';
import { parseCentsAsCurrencyString } from '../utilities/Currency';
import AdjustmentStatusCell from '../components/Adjustments/Adjustments/AdjustmentStatusCell';

export const adjustmentNotificationCount = 50;

export const generateHeadCells = (
  classes: any,
  onShowApprovalDialog: (adj: AdjustmentEntity) => void,
): readonly SortableTableHeadCell<AdjustmentEntity>[] => [
  {
    id: 'workweek',
    label: 'Work Week',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {formatSingleDay(parseWorkweekApiString(row.workweek))}
      </Typography>
    ),
  },
  {
    id: 'driverId',
    label: 'Partner ID',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.driverId}
      </Typography>
    ),
  },
  {
    id: 'amount',
    label: 'Amount',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {parseCentsAsCurrencyString(row.amount, row.currency)}
      </Typography>
    ),
  },
  {
    id: 'adjustmentType',
    label: 'Adjustment Type',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.adjustmentType}
      </Typography>
    ),
  },
  {
    id: 'adjustmentSubtype',
    label: 'Adjustment Subtype',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.adjustmentSubtype}
      </Typography>
    ),
  },
  {
    id: 'createdAt',
    label: 'Upload Timestamp',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {formatDateAmericanReadable(row.createdAt, easternTimezone)}
      </Typography>
    ),
  },
  {
    id: 'approvalStatusSetAt',
    label: 'Approval/Denial Timestamp',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.approvalStatusSetAt
          ? formatDateAmericanReadable(row.approvalStatusSetAt, easternTimezone)
          : '-'}
      </Typography>
    ),
  },
  {
    id: 'createdBy',
    label: 'User',
    sortable: false,
    extractor: (row) => (
      <Typography className={classes?.tableStringField}>
        {row.createdBy}
      </Typography>
    ),
  },
  {
    id: 'note',
    label: 'Notes',
    sortable: false,
    extractor: (row) => {
      let fullNotes = row.notes;
      if (row.managerNotes) {
        fullNotes = `${row.notes} (Manager: ${row.managerNotes})`;
      }

      return (
        <Tooltip title={fullNotes || ''}>
          <Typography
            noWrap={true}
            maxWidth={300}
            className={classes?.tableStringFieldTruncated}
          >
            {fullNotes}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    id: 'timePaidOut',
    label: 'Time Paid Out (est.)',
    sortable: false,
    extractor: (row) => {
      if (row.timePaidOut) {
        return (
          <Typography className={classes?.tableStringField}>
            {formatDateAmericanReadable(
              new Date(row.timePaidOut),
              easternTimezone,
            )}
          </Typography>
        );
      }
      if (
        row.status === AdjustmentStatus.Pending ||
        row.status === AdjustmentStatus.PendingApproval
      ) {
        return (
          <Typography className={classes?.tableItalicStringField}>
            {getNextPayoutTimeAsString()}
          </Typography>
        );
      }
      return <Typography className={classes?.tableStringField}>-</Typography>;
    },
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    extractor: (row) => (
      <AdjustmentStatusCell
        adjustment={row}
        onShowApprovalDialog={() => onShowApprovalDialog(row)}
      />
    ),
  },
];
