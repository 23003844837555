import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  Typography,
} from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { locations as locationsAtom } from '../../atoms/Location';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import {
  getCurrencySymbolFromLocation,
  filterLocationById,
} from '../../utilities/Locations';
import Waiting from '../Waiting';
import FareLimitsInputField from './FareLimitsInputField';
import {
  TripFareConstants,
  TripFareLimits,
} from '../../interfaces/TripFareLimits';
import { getTripFareLimitsValidations } from '../../variables/TripFareLimits';

interface EditTripFareLimitsDialogProps {
  tripFareLimitsToEdit?: TripFareLimits;
  tripFareConstants: TripFareConstants;
  onClose: () => void;
  onEdit: (newFareLimits: TripFareLimits) => void;
  loading: boolean;
}

const EditTripFareLimitsDialog = (props: EditTripFareLimitsDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const generateEmptyFareLimits = (): TripFareLimits => ({
    id: 0,
    firstOrderMinFare: props.tripFareConstants.defaultFirstOrderMinFare,
    subsequentOrderMinFare:
      props.tripFareConstants.defaultSubsequentOrderMinFare,
    firstOrderMaxFare: props.tripFareConstants.defaultFirstOrderMaxFare,
    subsequentOrderMaxFare:
      props.tripFareConstants.defaultSubsequentOrderMaxFare,
    locationID: '',
    startAt: new Date(),
    lastUpdatedBy: '',
  });

  const validatorRef = createRef<ValidatorForm>();

  const locations = useRecoilValue(locationsAtom);

  const [invalidRules, setInvalidRules] = useState(false);
  const [currentFareLimits, setCurrentFareLimits] = useState(
    generateEmptyFareLimits(),
  );

  useEffect(() => {
    const tripFareLimitsValidations = getTripFareLimitsValidations(
      props.tripFareConstants,
    );

    ValidatorForm.addValidationRule('nonZero', (value) => !!parseFloat(value));
    ValidatorForm.addValidationRule(
      'maxOneDollar',
      (value) => parseFloat(value) <= 1 || !parseFloat(value),
    );
    Object.keys(tripFareLimitsValidations).forEach((fieldName) =>
      ValidatorForm.addValidationRule(
        fieldName,
        tripFareLimitsValidations[fieldName as keyof TripFareLimits]!,
      ),
    );
  }, []);

  useEffect(() => {
    setCurrentFareLimits(
      props.tripFareLimitsToEdit
        ? {
            ...props.tripFareLimitsToEdit,
            firstOrderMinFare:
              props.tripFareLimitsToEdit.firstOrderMinFare / 100,
            subsequentOrderMinFare:
              props.tripFareLimitsToEdit.subsequentOrderMinFare / 100,
            firstOrderMaxFare:
              props.tripFareLimitsToEdit.firstOrderMaxFare / 100,
            subsequentOrderMaxFare:
              props.tripFareLimitsToEdit.subsequentOrderMaxFare / 100,
          }
        : generateEmptyFareLimits(),
    );
  }, [props.tripFareLimitsToEdit]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidRules(!isValid);
    });
  }, [currentFareLimits]);

  const handleValidationError = () => setInvalidRules(true);

  const handleSubmit = () =>
    props.onEdit({
      ...currentFareLimits,
      firstOrderMinFare: 100 * currentFareLimits.firstOrderMinFare,
      subsequentOrderMinFare: 100 * currentFareLimits.subsequentOrderMinFare,
      firstOrderMaxFare: 100 * currentFareLimits.firstOrderMaxFare,
      subsequentOrderMaxFare: 100 * currentFareLimits.subsequentOrderMaxFare,
    });

  const location = props.tripFareLimitsToEdit?.locationID
    ? filterLocationById(
        locations,
        parseInt(props.tripFareLimitsToEdit.locationID, 10),
      )
    : null;

  const locationName = location?.name;
  const currencySymbol = getCurrencySymbolFromLocation(location);

  return (
    <Dialog
      open={!!props.tripFareLimitsToEdit}
      onClose={props.onClose}
      fullWidth
      maxWidth='sm'
    >
      <Waiting open={props.loading} />
      <ValidatorForm
        instantValidate
        onSubmit={handleSubmit}
        onError={handleValidationError}
        ref={validatorRef}
      >
        <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
          {`Edit Trip Fare Limits${locationName ? ` for ${locationName}` : ''}`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([sharedClasses.subtitle2, classes.note])}
            >
              {`Edit the DPP pay rates${
                locationName ? ` for ${locationName}` : ''
              }`}
              <Box component='div' className={classes.boldNoteMessage}>
                (persistent rates)
              </Box>
            </Typography>
            <Box component='div' className={classes.row}>
              <Box component='div' className={classes.numberField}>
                <FareLimitsInputField
                  currentState={currentFareLimits}
                  setter={(newLivePayRate) =>
                    setCurrentFareLimits(newLivePayRate)
                  }
                  fieldName='firstOrderMinFare'
                  tripFareConstants={props.tripFareConstants}
                  placeholder='Min first fare'
                  currencySymbol={currencySymbol}
                  endAdornment=''
                  customValidate
                />
              </Box>
              <Box component='div' className={classes.numberField}>
                <FareLimitsInputField
                  currentState={currentFareLimits}
                  setter={(newLivePayRate) =>
                    setCurrentFareLimits(newLivePayRate)
                  }
                  fieldName='subsequentOrderMinFare'
                  tripFareConstants={props.tripFareConstants}
                  placeholder='Min additional'
                  currencySymbol={currencySymbol}
                  endAdornment=''
                  customValidate
                />
              </Box>
            </Box>
            <Box component='div' className={classes.row}>
              <Box component='div' className={classes.numberField}>
                <FareLimitsInputField
                  currentState={currentFareLimits}
                  setter={(newLivePayRate) =>
                    setCurrentFareLimits(newLivePayRate)
                  }
                  fieldName='firstOrderMaxFare'
                  tripFareConstants={props.tripFareConstants}
                  placeholder='Max first fare'
                  currencySymbol={currencySymbol}
                  endAdornment=''
                  customValidate
                />
              </Box>
              <Box component='div' className={classes.numberField}>
                <FareLimitsInputField
                  currentState={currentFareLimits}
                  setter={(newLivePayRate) =>
                    setCurrentFareLimits(newLivePayRate)
                  }
                  fieldName='subsequentOrderMaxFare'
                  tripFareConstants={props.tripFareConstants}
                  placeholder='Max additional'
                  currencySymbol={currencySymbol}
                  endAdornment=''
                  customValidate
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            id='cancel-button'
            variant='contained'
            color='inherit'
            onClick={props.onClose}
            className={sharedClasses.buttonText}
          >
            Cancel
          </Button>
          <Button
            id='submit-button'
            variant='contained'
            color='primary'
            disabled={invalidRules}
            type='submit'
            className={sharedClasses.buttonText}
          >
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  errorText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: sharedColors.black,
  },
  title: {
    color: sharedColors.gray6,
  },
  dialogContent: {
    flexDirection: 'column',
  },
  note: {},
  boldNoteMessage: {
    fontWeight: 'bold',
    display: 'inline',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  numberField: {
    width: 160,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dashedDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderStyle: 'dashed',
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
}));

export default EditTripFareLimitsDialog;
