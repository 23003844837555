import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { isEmpty, isNumber } from 'lodash';
import { useSharedStyles } from '../../../../utilities/Styles';
import { MissionRewardSummary } from '../../../../interfaces/PayHistory/MissionRewardSummary';
import { multiClass } from '../../../../utilities/Extensions';
import {
  composeNodeID,
  missionRewardSummaryPayNodeID,
  missionRewardSummaryTiersNodeID,
  missionRewardSummaryTierTypeNodeID,
} from '../../../../variables/PayHistory';
import SinglePayoutNode from '../SinglePayoutNode';
import SingleTextNode from '../SingleTextNode';
import MissionRewardTierSummaryNode from './MissionRewardTierSummaryNode';

interface MissionRewardSummaryNodeProps {
  id: string;
  missionRewardSummary: MissionRewardSummary;
  currency: string;
}

const MissionRewardSummaryNode = (props: MissionRewardSummaryNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography className={multiClass([props.id, sharedClasses.body1])}>
          {`Reward {${props.missionRewardSummary.tierType ? '2' : '1'}}`}
        </Typography>
      }
    >
      {isNumber(props.missionRewardSummary.pay) && (
        <SinglePayoutNode
          id={composeNodeID([props.id, missionRewardSummaryPayNodeID])}
          name='Pay'
          amount={props.missionRewardSummary.pay}
          currency={props.currency}
        />
      )}
      {props.missionRewardSummary.tierType && (
        <SingleTextNode
          id={composeNodeID([props.id, missionRewardSummaryTierTypeNodeID])}
          name='Tier Type'
          value={props.missionRewardSummary.tierType}
        />
      )}
      {!isEmpty(props.missionRewardSummary.tiers) && (
        <TreeItem
          nodeId={composeNodeID([props.id, missionRewardSummaryTiersNodeID])}
          label={
            <Typography
              className={multiClass([classes.label, sharedClasses.body1])}
            >
              {`Tiers [${props.missionRewardSummary.tiers.length.toString()}]`}
            </Typography>
          }
        >
          {props.missionRewardSummary.tiers.map((tier, i) => (
            <MissionRewardTierSummaryNode
              id={composeNodeID([
                props.id,
                missionRewardSummaryTiersNodeID,
                i.toString(),
              ])}
              name={`tier_${(i + 1).toString()}`}
              missionRewardTierSummary={tier}
              currency={props.currency}
            />
          ))}
        </TreeItem>
      )}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MissionRewardSummaryNode;
