import { DateTime } from 'luxon';
import {
  FeatureToggleHistoryWithIncludedValue,
  FeatureToggleInclusionListEntry,
  InclusionListExpirationOptions,
  InclusionListInsertionOptions,
  InclusionValueUpdateOptions,
} from '../interfaces/FeatureToggle';
import { getCurrentWorkweek } from '../utilities/Workweek';
import { payRateStatusOrderPriority } from '../utilities/PayRates';

export const variableBasePayFeatureName = 'variable-base-pay';
export const waitAtMfcMinimumFeatureName = 'wait-at-mfc-minimum';

export const listedFeatureToggleHistoryComparator = (
  a: FeatureToggleHistoryWithIncludedValue,
  b: FeatureToggleHistoryWithIncludedValue,
): number => {
  const priorityOfA = payRateStatusOrderPriority(a.status);
  const priorityOfB = payRateStatusOrderPriority(b.status);

  if (priorityOfA !== priorityOfB) {
    return priorityOfA - priorityOfB;
  }

  return a.startAt < b.startAt ? -1 : 1;
};

export const getDefaultInclusionListInsertionOptions = (
  isLocationType: boolean,
): InclusionListInsertionOptions => {
  const nextWorkweekStart = DateTime.fromJSDate(getCurrentWorkweek())
    .set({
      hour: 5,
      minute: 0,
      second: 0,
    })
    .plus({ days: 7 });

  return {
    startAt: isLocationType ? nextWorkweekStart.toJSDate() : new Date(),
    endAt: isLocationType
      ? nextWorkweekStart.plus({ hour: 1 }).toJSDate()
      : new Date(),
    setEndAt: false,
    isLocalTime: isLocationType,
  };
};

export const getDefaultInclusionListExpirationOptions =
  (): InclusionListExpirationOptions => ({
    expireAt: new Date(),
    isLocalTime: false,
    expireNow: true,
  });

export const getDefaultInclusionValueUpdateOptions =
  (): InclusionValueUpdateOptions => ({
    startAt: new Date(),
    endAt: new Date(),
    setEndAt: false,
    isLocalTime: false,
  });

export const isExpiredInclusionEntry = (
  inclusionEntry: FeatureToggleInclusionListEntry,
): boolean => !!inclusionEntry.endAt && inclusionEntry.endAt < new Date();
