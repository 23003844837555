import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Typography } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { MissionSegmentFormProps } from '../../../interfaces/Mission/MissionSegment';
import { multiClass } from '../../../utilities/Extensions';

const CountryInput = (props: MissionSegmentFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' className={classes.body}>
      <Typography
        className={multiClass([sharedClasses.subtitle2, classes.text])}
      >
        {props.currentState.countryCode === 'US' ? 'All US' : 'All UK'}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    text: {
      marginLeft: theme.spacing(5),
      fontWeight: 'bold',
    },
  }),
);

export default CountryInput;
