import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { PayRateFormProps, WeeklyBonus } from '../../../interfaces/PayRate';
import { useSharedStyles } from '../../../utilities/Styles';
import { generateNumberInput } from '../../../utilities/PayRates';

export const WeeklyBonusForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' className={classes.formWrapper}>
      <Typography component='div' className={sharedClasses.subtitle2}>
        Set bonus rates and order thresholds.
      </Typography>
      <Grid container spacing={1} className={classes.grid}>
        <Grid key='space' item xs={4} />
        <Grid key='ordersLabel' item xs={4}>
          <Typography className={classes.gridLabel}>Orders</Typography>
        </Grid>
        <Grid key='bonusRateLabel' item xs={4}>
          <Typography className={classes.gridLabel}>Bonus Rate</Typography>
        </Grid>
        {
          //-----------------------------------------------------------------
        }
        {[1, 2, 3, 4].map((index) => [
          <Grid
            key={`tier${index.toString()}Bonus`}
            item
            xs={4}
            className={classes.gridItem}
          >
            <Typography className={classes.gridLabel}>
              {`Tier ${index.toString()} Bonus`}
            </Typography>
          </Grid>,
          <Grid key={`tier${index.toString()}Orders`} item xs={4}>
            {generateNumberInput<WeeklyBonus>(
              false,
              false,
              props.currentState,
              props.setter,
              `tier${index.toString()}Orders` as keyof WeeklyBonus,
              props.isDisabled,
              'Orders',
            )}
          </Grid>,
          <Grid key={`tier${index.toString()}Rate`} item xs={4}>
            {generateNumberInput<WeeklyBonus>(
              false,
              true,
              props.currentState,
              props.setter,
              `tier${index.toString()}Amount` as keyof WeeklyBonus,
              props.isDisabled,
              'Bonus',
            )}
          </Grid>,
        ])}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    boldText: {
      display: 'inline',
      fontWeight: 'bold',
    },
    grid: {
      marginTop: theme.spacing(1),
      width: '50%',
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    gridLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '13px',
      flexGrow: 1,
      textAlign: 'center',
    },
  }),
);
