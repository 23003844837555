import { useRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { toast } from 'material-react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { restrictionTypes as restrictionTypesAtom } from '../../atoms/RestrictedAccounts';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { getUserPermissions, isPermitted } from '../../variables/Users';
import { Permission } from '../../interfaces/Users';
import { permissions as permissionsAtom } from '../../atoms/Users';
import {
  enableStripeAccountByDriverId,
  getRestrictedAccounts,
  getRestrictionTypes,
} from '../../services/restrictedAccounts';
import Waiting from '../Waiting';
import { RestrictedAccount } from '../../interfaces/RestrictedAccount';
import {
  SortableTableOrder,
  TablePaginationOptions,
} from '../../interfaces/Common';
import { Order } from '../../utilities/Sorting';
import SortableTable from '../SortableTable';
import { generateHeadCells } from '../../variables/RestrictedAccount';
import AddRestrictedAccountDialog from './AddRestrictedAccountDialog/AddRestrictedAccountDialog';
import ConfirmEnableStripeDialog from './ConfirmEnableStripeDialog';

export const restrictedAccountsTestId = 'restrictedAccounts';
export const addDeliveryPartnerButtonTestId = 'addDeliveryPartnerButton';
export const searchTextFieldTestId = 'driverIdTextField';
export const restrictionTypesTestId = 'restrictionTypes';

const allRestrictionTypes = 'ALL';

const RestrictedAccounts = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;
  const location = useLocation();

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [restrictionTypes, setRestrictionTypes] =
    useRecoilState(restrictionTypesAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [restrictedAccounts, setRestrictedAccounts] = useState(
    [] as RestrictedAccount[],
  );
  const [order, setOrder] = useState<SortableTableOrder<RestrictedAccount>>({
    order: 'desc',
    orderBy: 'LastUpdatedAt',
  });
  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 10,
  });
  const [searchedDriverId, setSearchedDriverId] = useState('');
  const [selectedRestrictionType, setSelectedRestrictionType] =
    useState(allRestrictionTypes);
  const [accountToEnable, setAccountToEnable] =
    useState<RestrictedAccount | null>(null);

  const refreshRestrictedAccounts = () => {
    setLoading(true);
    getRestrictedAccounts()
      .then((receivedRestrictedAccounts) => {
        setRestrictedAccounts(receivedRestrictedAccounts);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRestrictionTypes()
      .then((types) => {
        setRestrictionTypes(types);
      })
      .catch((error) => {
        toast.error(`Could not get restriction types: ${error.message}`);
      });
    setPermissions(getUserPermissions());
    refreshRestrictedAccounts();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const driverIdFiltersParam = params.get('driver-id');
    const restrictionFiltersParam = params.get('restriction-type');
    if (driverIdFiltersParam) {
      try {
        setSearchedDriverId(
          JSON.parse(decodeURIComponent(driverIdFiltersParam)),
        );
      } catch {
        toast.error('Failed to parse Driver ID filters from URL');
      }
    }
    if (restrictionFiltersParam) {
      try {
        setSelectedRestrictionType(
          JSON.parse(decodeURIComponent(restrictionFiltersParam)),
        );
      } catch {
        toast.error('Failed to parse Restriction Type filters from URL');
      }
    }
  }, []);

  useEffect(() => {
    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const additionalParams: string[] = [];

    if (selectedRestrictionType !== allRestrictionTypes) {
      const restrictionTypeFiltersString = encodeURIComponent(
        JSON.stringify(selectedRestrictionType),
      );
      additionalParams.push(`restriction-type=${restrictionTypeFiltersString}`);
    }

    if (searchedDriverId) {
      const driverIdFiltersString = encodeURIComponent(
        JSON.stringify(searchedDriverId),
      );
      additionalParams.push(`driver-id=${driverIdFiltersString}`);
    }

    if (!isEmpty(additionalParams)) {
      newUrl += `?${additionalParams.join('&')}`;
    }

    window.history.pushState({ path: newUrl }, '', newUrl);
  }, [selectedRestrictionType, searchedDriverId]);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.RESTRICTED_ACCOUNTS) {
        index = i;
        break;
      }
    }
    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  const handleSetOrder = (
    newOrder: Order,
    newOrderBy: keyof RestrictedAccount,
  ) => {
    setOrder({ order: newOrder, orderBy: newOrderBy });
    setPagination({ page: 1, rowsPerPage: pagination.rowsPerPage });
  };

  const handlePagination = (newPage: number, newRowsPerPage: number) => {
    setPagination({ page: newPage, rowsPerPage: newRowsPerPage });
  };

  const handleSetSelectedRestrictionType = (event: SelectChangeEvent) => {
    setSelectedRestrictionType(event.target.value as string);
    setPagination({ page: 1, rowsPerPage: pagination.rowsPerPage });
  };

  const handleChangeSearchedDriverId = (e: any) => {
    setSearchedDriverId(e.target.value);
    setPagination({ page: 1, rowsPerPage: pagination.rowsPerPage });
  };

  const handleAddDeliveryPartnerButton = () => {
    setShowDialog(!showDialog);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleAddRestrictedAccount = () => {
    setShowDialog(false);
    refreshRestrictedAccounts();
  };

  const handleEnableStripe = () => {
    setLoading(true);
    enableStripeAccountByDriverId(accountToEnable?.DriverID.toString() || '-1')
      .then(() => {
        toast.success('Successfully enabled Stripe account');
        setAccountToEnable(null);
        refreshRestrictedAccounts();
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const handleCloseConfirmDialog = () => {
    setAccountToEnable(null);
  };

  const handleClickEnableStripe = (account: RestrictedAccount) => {
    setAccountToEnable(account);
  };

  const filteredRestrictedAccounts = restrictedAccounts.filter(
    (account) =>
      (account.DriverID.toString()
        .toLowerCase()
        .includes(searchedDriverId.toLowerCase()) ||
        account.Name.toLowerCase().includes(searchedDriverId.toLowerCase())) &&
      (selectedRestrictionType === allRestrictionTypes ||
        selectedRestrictionType === account.RestrictionType),
  );

  const restrictedAccountsToDisplay = filteredRestrictedAccounts
    .sort((a, b) => {
      if (a[order.orderBy] === b[order.orderBy]) {
        return 0;
      }
      if (order.order === 'asc') {
        return a[order.orderBy] < b[order.orderBy] ? -1 : 1;
      }
      return a[order.orderBy] < b[order.orderBy] ? 1 : -1;
    })
    .slice(
      (pagination.page - 1) * pagination.rowsPerPage,
      pagination.page * pagination.rowsPerPage,
    );

  return (
    <Box component='div' data-testid={restrictedAccountsTestId}>
      <Waiting open={loading} />
      <ConfirmEnableStripeDialog
        open={!!accountToEnable}
        account={accountToEnable}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleEnableStripe}
      />
      <AddRestrictedAccountDialog
        open={showDialog}
        onClose={handleCloseDialog}
        onAdd={handleAddRestrictedAccount}
      />
      <Paper className={classes.header}>
        <Typography className={multiClass([sharedClasses.h2, classes.title])}>
          Delivery Partners with Restricted Accounts
        </Typography>
        <Button
          id='add-delivery-partner-button'
          color='primary'
          variant='contained'
          onClick={handleAddDeliveryPartnerButton}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.RESTRICT_ACCOUNTS)}
          data-testid={addDeliveryPartnerButtonTestId}
        >
          Add Delivery Partners
        </Button>
      </Paper>
      <Box component='div' className={classes.body}>
        <Typography
          className={multiClass([
            sharedClasses.overline,
            classes.filterByLabel,
          ])}
        >
          FILTER BY
        </Typography>
        <Box component='div' className={classes.filtersRow}>
          <TextField
            variant='outlined'
            size='small'
            label='Search'
            onChange={handleChangeSearchedDriverId}
            value={searchedDriverId}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': searchTextFieldTestId,
            }}
            className={classes.textField}
          />

          <FormControl
            size='small'
            variant='outlined'
            className={classes.restrictionTypeDropdown}
          >
            <InputLabel className={classes.dropdownLabel}>
              Restriction Type
            </InputLabel>
            <Select
              onChange={handleSetSelectedRestrictionType}
              value={selectedRestrictionType}
              data-testid={restrictionTypesTestId}
            >
              <MenuItem value={allRestrictionTypes}>All</MenuItem>
              <Divider />
              {restrictionTypes.map((restrictionType) => (
                <MenuItem
                  id={restrictionType}
                  value={restrictionType}
                  key={restrictionType}
                >
                  {restrictionType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <SortableTable
          rows={restrictedAccountsToDisplay}
          order={order.order}
          orderBy={order.orderBy}
          onOrder={handleSetOrder}
          page={pagination.page - 1}
          rowsPerPage={pagination.rowsPerPage}
          onPagination={handlePagination}
          totalCount={filteredRestrictedAccounts.length}
          headCells={generateHeadCells(
            classes,
            handleClickEnableStripe,
            isPermitted(permissions, Permission.RESTRICT_ACCOUNTS),
          )}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  title: {
    color: sharedColors.gray7,
    flexGrow: 1,
  },
  button: {
    textTransform: 'none',
  },
  filterByLabel: {
    color: sharedColors.gray5,
  },
  searchIcon: {
    color: sharedColors.gray4,
  },
  textField: {
    backgroundColor: sharedColors.white,
    width: '218px',
  },
  restrictionTypeDropdown: {
    minWidth: 260,
    marginLeft: theme.spacing(1.5),
    backgroundColor: sharedColors.white,
  },
  dropdownLabel: {
    backgroundColor: sharedColors.white,
  },
  filtersRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginTop: theme.spacing(2),
  },
  tableStringField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    flexDirection: 'row',
    display: 'flex',
    color: sharedColors.gray6,
  },
  stripeButton: {
    textTransform: 'none',
  },
  typeChip: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 12,
    textTransform: 'capitalize',
    color: sharedColors.gray6,
  },
}));

export default RestrictedAccounts;
