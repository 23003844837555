import { makeStyles } from 'tss-react/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty } from 'lodash';
import { DriverSupportSummary } from '../../interfaces/PayHistory/DriverSupportSummary';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import {
  parseWorkweekISOString,
  workweekRangeString,
} from '../../utilities/Workweek';
import PayoutTable from './PayoutTable';
import WeeklyPayoutBreakdown from './WeeklyPayoutBreakdown';
import DailyPayoutAccordion from './DailyPayoutAccordion';

interface WeeklyPayoutAccordionProps {
  workweek: string;
  driverSupportSummary: DriverSupportSummary | null;
  onClick: () => void;
}

const WeeklyPayoutAccordion = (props: WeeklyPayoutAccordionProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const expanded = !!props.driverSupportSummary;

  const paymentDetailsColumn = (name: string, amount?: number) => (
    <Box className={classes.amountColumn}>
      <Typography className={sharedClasses.h3}>
        {amount === undefined ? '-' : `$${(amount / 100).toFixed(2)}`}
      </Typography>
      <Typography
        className={multiClass([
          sharedClasses.subtitle2,
          classes.amountFieldName,
        ])}
      >
        {name}
      </Typography>
    </Box>
  );

  const parsedWorkweek = parseWorkweekISOString(props.workweek);

  return (
    <Box component='div' className={classes.accordionContainer}>
      <Accordion
        expanded={expanded}
        disableGutters
        className={classes.accordion}
      >
        <AccordionSummary
          onClick={props.onClick}
          expandIcon={
            <Box
              component='div'
              className={classes.accordionExpandIconBackground}
            >
              <ExpandMoreIcon className={classes.accordionExpandIcon} />
            </Box>
          }
        >
          <Typography
            className={multiClass([sharedClasses.h5, classes.workweekText])}
          >
            {workweekRangeString(parsedWorkweek)}
          </Typography>
        </AccordionSummary>
        {props.driverSupportSummary && (
          <AccordionDetails>
            <Paper square component='div' className={classes.accordionDetails}>
              {!isEmpty(props.driverSupportSummary.payoutProviders) && (
                <Box component='div' className={classes.payoutSummaryContainer}>
                  <Box className={classes.row}>
                    {paymentDetailsColumn(
                      'Earned',
                      props.driverSupportSummary.totalEarned,
                    )}
                    {paymentDetailsColumn(
                      'Paid',
                      props.driverSupportSummary.totalPaid,
                    )}
                    {paymentDetailsColumn(
                      'Cash Out Fees',
                      props.driverSupportSummary.totalFee,
                    )}
                  </Box>
                  <PayoutTable
                    driverSupportSummary={props.driverSupportSummary}
                  />
                </Box>
              )}
              {props.driverSupportSummary.payoutBreakdown && (
                <WeeklyPayoutBreakdown
                  workweek={parsedWorkweek}
                  payoutBreakdown={props.driverSupportSummary.payoutBreakdown}
                  currency={props.driverSupportSummary.currency}
                />
              )}
              <Box component='div' className={classes.dailyAccordionWrapper}>
                {props.driverSupportSummary.payoutBreakdown?.dailyPayoutBreakdowns.map(
                  (dailyBreakdown, i) => (
                    <DailyPayoutAccordion
                      workweek={parsedWorkweek}
                      workday={i}
                      timezone={
                        props.driverSupportSummary!.payoutBreakdown!.timezone
                      }
                      dailyBreakdown={dailyBreakdown}
                      currency={props.driverSupportSummary!.currency}
                    />
                  ),
                )}
              </Box>
            </Paper>
          </AccordionDetails>
        )}
      </Accordion>
      {expanded && <Box component='div' className={classes.verticalSpacer} />}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  accordionContainer: {
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(0.25),
  },
  accordion: {
    backgroundColor: sharedColors.gray6,
  },
  accordionExpandIcon: {
    color: sharedColors.gray1,
  },
  accordionDetails: {
    padding: theme.spacing(3),
    backgroundColor: sharedColors.gray1,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-2.25),
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  accordionExpandIconBackground: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    borderRadius: 4,
    backgroundColor: sharedColors.gray7,
  },
  dailyAccordionWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  workweekText: {
    color: sharedColors.white,
  },
  amountColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(10),
  },
  amountFieldName: {
    color: sharedColors.gray5,
    marginTop: theme.spacing(0.5),
  },
  verticalSpacer: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  payoutSummaryContainer: {
    marginBottom: theme.spacing(6),
  },
}));

export default WeeklyPayoutAccordion;
