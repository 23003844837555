import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { sharedColors } from '../../../utilities/Styles';
import { MissionSegmentFormProps } from '../../../interfaces/Mission/MissionSegment';

const NotActiveSinceInput = (props: MissionSegmentFormProps) => {
  const classes = useStyles();

  const handleChangeNotActiveSince = (date: Date | null) => {
    props.setCurrentState({
      ...props.currentState,
      notActiveSince: date ?? moment().subtract(1, 'days').toDate(),
    });
  };

  return (
    <Box component='div' className={classes.body}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          onChange={handleChangeNotActiveSince}
          value={props.currentState.notActiveSince}
          maxDateTime={new Date(moment().subtract(1, 'days').toDate())}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField {...params} size='small' label='Not Active Since' />
          )}
          className={classes.datePicker}
        />
      </LocalizationProvider>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    datePicker: {
      marginRight: theme.spacing(1.5),
      marginLeft: theme.spacing(5),
    },
  }),
);

export default NotActiveSinceInput;
