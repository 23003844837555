import { isFinite } from 'lodash';
import { DateTime } from 'luxon';
import { Location } from '../interfaces/Location';
import {
  PayRateKind,
  PayRateSchedule,
  PayRateStatus,
} from '../interfaces/PayRate';
import { filterLocationById } from './Locations';
import { payRateScheduleSpanRanges } from '../variables/PayRate';
import { easternTimezone } from './Dates';

export const parsePayRateScheduleCsv = (
  csvRows: string[][],
  locations: Location[],
  startAt: Date,
): PayRateSchedule[] => {
  const payRateSchedules: PayRateSchedule[] = [];
  for (let i = 0; i < csvRows.length; i += 1) {
    try {
      const row = csvRows[i];
      if (row.length !== 29) {
        throw new Error(`Expected 29 columns, found ${row.length.toString()}`);
      }

      const locationID = row[0];
      const location = filterLocationById(locations, parseInt(locationID, 10));
      if (!location) {
        throw new Error(`Cannot find a location with ID ${locationID}`);
      }

      const rates: number[] = [];
      for (let j = 0; j < 28; j += 1) {
        const rate = parseFloat(row[j + 1]);
        if (!isFinite(rate)) {
          throw new Error(
            `Cannot parse '${row[j + 1]}' as a number in column ${(
              j + 2
            ).toString()}`,
          );
        }

        rates.push(rate);
      }

      payRateSchedules.push({
        kind: PayRateKind.WAIT_PAY,
        id: 0,
        locationID,
        startAt,
        endAt: { valid: false },
        status: PayRateStatus.FUTURE,
        lastUpdatedBy: '',
        spans: rates.map((rate, j) => ({
          rate,
          dayStart: Math.floor(j / 4),
          dayEnd: Math.floor(j / 4),
          timeStart: payRateScheduleSpanRanges[j % 4].timeStart,
          timeEnd: payRateScheduleSpanRanges[j % 4].timeEnd,
        })),
      });
    } catch (e: any) {
      throw new Error(
        `Parsing error in line ${(i + 1).toString()}: ${
          e?.message ?? 'Unexpected error'
        }`,
      );
    }
  }

  return payRateSchedules;
};

export const getEarliestWaitPayDate = (): Date => {
  const now = DateTime.now().setZone(easternTimezone);
  let resultDate = now.startOf('day').set({ hour: 5 });

  const dayOfWeek = now.weekday;
  let daysToAdd = 8 - dayOfWeek;

  if (dayOfWeek <= 3) {
    const isBeforeWednesdayCutoff =
      dayOfWeek < 3 ||
      (dayOfWeek === 3 &&
        (now.hour < 16 || (now.hour === 16 && now.minute < 59)));
    if (!isBeforeWednesdayCutoff) {
      daysToAdd += 7;
    }
  } else {
    daysToAdd += 7;
  }

  resultDate = resultDate.plus({ days: daysToAdd });
  return resultDate.toJSDate();
};
