import { Audit, auditFromResponse } from '../Audit';

export enum PushNotificationCategory {
  EARNINGS_RADAR = 'earnings_radar',
  GENERIC = 'generic',
}

interface PushNotificationRequirements {
  employeeType?: string;
  locationIDs: string[];
  metroIDs: string[];
  driverIDs: string[];
  mustBeInactive: boolean;
}

export const pushNotificationRequirementsFromResponse = (
  responseData: any,
): PushNotificationRequirements => ({
  employeeType: responseData.employee_type,
  locationIDs: responseData.location_ids ?? [],
  metroIDs: responseData.metro_ids ?? [],
  driverIDs: responseData.driver_ids ?? [],
  mustBeInactive: !!responseData.must_be_inactive,
});

export interface PushNotification {
  id: number;
  title: string;
  message: string;
  category: PushNotificationCategory;
  requirements: PushNotificationRequirements;
  audit: Audit;
}

export const pushNotificationFromResponse = (
  responseData: any,
): PushNotification => ({
  id: responseData.id,
  title: responseData.title,
  message: responseData.message,
  category: responseData.category,
  requirements: pushNotificationRequirementsFromResponse(
    responseData.requirements ?? {},
  ),
  audit: auditFromResponse(responseData.audit ?? {}),
});

export interface PushNotificationsList {
  pushNotifications: PushNotification[];
  totalCount: number;
}
