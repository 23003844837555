export interface ReturnCocPaySummary {
  returnLocationCount: number;
  amount: number;
}

export const returnCocPaySummaryFromResponse = (
  responseData: any,
): ReturnCocPaySummary => ({
  returnLocationCount: responseData.return_location_count ?? 0,
  amount: responseData.amount ?? 0,
});
