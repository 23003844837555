import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { MissionBreakdown } from '../../../../interfaces/PayHistory/MissionBreakdown';
import {
  composeNodeID,
  missionBreakdownsNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import MissionBreakdownNode from './MissionBreakdownNode';

interface MissionBreakdownListNodeProps {
  missionBreakdowns: MissionBreakdown[];
  currency: string;
  timezone: string;
}

const MissionBreakdownListNode = (props: MissionBreakdownListNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={missionBreakdownsNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Mission Breakdowns [${props.missionBreakdowns.length.toString()}]`}
        </Typography>
      }
    >
      {props.missionBreakdowns.map((mission, i) => (
        <MissionBreakdownNode
          id={composeNodeID([missionBreakdownsNodeID, i.toString()])}
          index={i}
          missionBreakdown={mission}
          currency={props.currency}
          timezone={props.timezone}
        />
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MissionBreakdownListNode;
