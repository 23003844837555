export interface MissionParticipant {
  missionID: number;
  driverID: string;
  segmentID: number;
  segmentName: string;
  invitedAt: Date;
  acceptedAt?: Date;
  completedAt?: Date;
  orderCount: number;
  tripCount: number;
  expectedEarnings: number;
  actualEarnings: number;
}

export const missionParticipantFromResponse = (
  responseData: any,
): MissionParticipant => ({
  missionID: responseData.mission_id,
  driverID: responseData.driver_id,
  segmentID: responseData.segment_id,
  segmentName: responseData.segment_name,
  invitedAt: new Date(responseData.invited_at),
  acceptedAt: responseData.accepted_at
    ? new Date(responseData.accepted_at)
    : undefined,
  completedAt: responseData.completed_at
    ? new Date(responseData.completed_at)
    : undefined,
  orderCount: responseData.order_count,
  tripCount: responseData.trip_count,
  expectedEarnings: responseData.expected_earnings,
  actualEarnings: responseData.actual_earnings,
});
