import { isEmpty, isFinite } from 'lodash';
import { DateTime } from 'luxon';
import { TimeRatePipelineSettings } from '../../interfaces/Settings/TimeRatePipelineSettings';
import { getNextTuesday } from '../Dates';

export const getDefaultTimeRatePipelineSettings =
  (): TimeRatePipelineSettings => ({
    id: 0,
    locationID: '',
    targetP90DeliveryTime: 57,
    targetP90DeliveryDriverControllableTime: 50,
    maxDeltaOrdersPerDriverPercent: 0,
    maxDeltaCommission: 0,
    timeRateLowerBound: 0.1,
    timeRateUpperBound: 0.45,
    minTimeRateMissingData: 0.2,
    dcpoNeutralOverWeek: false,
    noCommissionIncrease: false,
    startAt: getNextTuesday(),
    lastUpdatedBy: '',
  });

export const parsePipelineSettingsFromCsv = (
  csvRows: string[][],
): TimeRatePipelineSettings[] => {
  const parsedSettings: TimeRatePipelineSettings[] = [];
  const expectedColumns = 11;
  for (let i = 0; i < csvRows.length; i += 1) {
    try {
      const row = csvRows[i];
      if (row.length !== expectedColumns) {
        throw new Error(
          `Expected ${expectedColumns.toString()} found ${row.length.toString()} columns`,
        );
      }

      const settings = getDefaultTimeRatePipelineSettings();

      settings.locationID = row[0].trim();
      if (isEmpty(settings.locationID)) {
        throw new Error('Location ID cannot be empty');
      }

      settings.startAt = DateTime.fromFormat(
        row[1].toUpperCase(),
        'MM/dd/yyyy',
      ).toJSDate();

      settings.targetP90DeliveryTime = parseFloat(row[2]);
      if (!isFinite(settings.targetP90DeliveryTime)) {
        throw new Error(
          `Cannot parse P90 Delivery Time. '${row[2]}' is not a number`,
        );
      }

      settings.targetP90DeliveryDriverControllableTime = parseFloat(row[3]);
      if (!isFinite(settings.targetP90DeliveryDriverControllableTime)) {
        throw new Error(
          `Cannot parse P90 Delivery Driver Controllable Time. '${row[3]}' is not a number`,
        );
      }

      settings.maxDeltaOrdersPerDriverPercent = parseFloat(row[4]);
      if (!isFinite(settings.maxDeltaOrdersPerDriverPercent)) {
        throw new Error(
          `Cannot parse Max Delta Orders Per Driver Hour. '${row[4]}' is not a number`,
        );
      }

      settings.maxDeltaCommission = parseFloat(row[5]);
      if (!isFinite(settings.maxDeltaCommission)) {
        throw new Error(
          `Cannot parse Max Delta Commission. '${row[5]}' is not a number`,
        );
      }

      settings.timeRateLowerBound = parseFloat(row[6]);
      if (!isFinite(settings.timeRateLowerBound)) {
        throw new Error(
          `Cannot parse Time Rate Lower Bound. '${row[6]}' is not a number`,
        );
      }

      settings.timeRateUpperBound = parseFloat(row[7]);
      if (!isFinite(settings.timeRateUpperBound)) {
        throw new Error(
          `Cannot parse Time Rate Upper Bound. '${row[7]}' is not a number`,
        );
      }

      settings.minTimeRateMissingData = parseFloat(row[8]);
      if (!isFinite(settings.minTimeRateMissingData)) {
        throw new Error(
          `Cannot parse Min Time Rate Missing Data. '${row[8]}' is not a number`,
        );
      }

      const dcpoNeutralOverWeekOption = row[9].toUpperCase();
      if (dcpoNeutralOverWeekOption === 'Y') {
        settings.dcpoNeutralOverWeek = true;
      } else if (dcpoNeutralOverWeekOption === 'N') {
        settings.dcpoNeutralOverWeek = false;
      } else {
        throw new Error(
          `Cannot determine DCPO Neutral Over Week option. Expected 'Y' or 'N', found '${row[9]}'`,
        );
      }

      const noCommissionIncreaseOption = row[10].toUpperCase();
      if (noCommissionIncreaseOption === 'Y') {
        settings.noCommissionIncrease = true;
      } else if (noCommissionIncreaseOption === 'N') {
        settings.noCommissionIncrease = false;
      } else {
        throw new Error(
          `Cannot determine No Commission Increase option. Expected 'Y' or 'N', found '${row[10]}'`,
        );
      }

      parsedSettings.push(settings);
    } catch (err: any) {
      throw new Error(
        `Could not parse the given CSV file. Error in line ${(
          i + 1
        ).toString()}. Reason: ${err.message}`,
      );
    }
  }

  return parsedSettings;
};
