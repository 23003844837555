import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseInt } from 'lodash';
import { DateTime } from 'luxon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ContentCopy } from '@mui/icons-material';
import {
  locations as locationsAtom,
  selectedLocationIDs,
} from '../../../atoms/Location';
import { payRateToEdit as payRateToEditAtom } from '../../../atoms/PayRate';
import {
  BoostPay,
  BoostPayTimeInterval,
  PayRateFormProps,
  PayRateStatus,
} from '../../../interfaces/PayRate';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import {
  getCurrencySymbolFromLocation,
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import {
  convertLocalToDeliveryZone,
  datesAreOnSameDay,
  formatDateTime,
  formatTime,
  parseDate,
} from '../../../utilities/Dates';
import { isNumber } from '../../../utilities/Misc';
import { multiClass } from '../../../utilities/Extensions';
import { generateNumberInput } from '../../../utilities/PayRates';

export const BoostPayForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const payRateToEdit = useRecoilValue(payRateToEditAtom);
  const selectedLocationIds = useRecoilValue(selectedLocationIDs);

  const [timeIntervals, setTimeIntervals] = useState<BoostPayTimeInterval[]>(
    [],
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const boostPay = props.currentState as BoostPay;
  const areAllInputsFilled =
    boostPay.startAt &&
    boostPay.startTime &&
    boostPay.endTime &&
    boostPay.endAt &&
    isNumber(boostPay.amount) &&
    boostPay.name;

  const selectedFirstLocation = filterLocationById(
    locations,
    parseInt(selectedLocationIds[0], 10),
  );

  const timeZone = payRateToEdit
    ? getTimeZoneByLocationId(
        locations,
        parseInt(props.currentState.locationID, 10),
      ) || 'UTC'
    : 'UTC';

  const generateTimeIntervalByHourMin = (
    hours: number,
    minutes: number,
  ): BoostPayTimeInterval => ({
    value: 60 * hours + minutes,
    hours,
    minutes,
    label: formatTime(hours, minutes),
  });

  const generateTimeIntervalByValue = (value: number): BoostPayTimeInterval =>
    generateTimeIntervalByHourMin(Math.floor(value / 60), value % 60);

  const generateTimeIntervals = (): BoostPayTimeInterval[] => {
    const intervals: BoostPayTimeInterval[] = [];
    for (let i = 0; i < 24; i += 1) {
      for (let j = 0; j < 60; j += 15) {
        intervals.push(generateTimeIntervalByHourMin(i, j));
      }
    }

    return intervals;
  };

  useEffect(() => {
    setTimeIntervals(generateTimeIntervals());
  }, [props.currentState.locationID]);

  const handleStartAtChange = (date: Date | null) => {
    props.setter({
      ...props.currentState,
      startAt: convertLocalToDeliveryZone(
        timeZone,
        DateTime.fromJSDate(date || new Date()),
      ).toJSDate(),
    });
  };

  const handleEndAtChange = (date: Date | null) => {
    props.setter({
      ...props.currentState,
      endAt: {
        valid: true,
        time: convertLocalToDeliveryZone(
          timeZone,
          DateTime.fromJSDate(date || new Date()),
        ).toJSDate(),
      },
    });
  };

  const handleStartTimeChange = (event: SelectChangeEvent) => {
    const interval = generateTimeIntervalByValue(
      parseInt(event.target.value, 10),
    );
    props.setter({
      ...props.currentState,
      startTime: DateTime.fromJSDate((props.currentState as BoostPay).startTime)
        .setZone(timeZone)
        .set({
          hour: interval.hours,
          minute: interval.minutes,
          second: 0,
          millisecond: 0,
        })
        .toJSDate(),
    } as BoostPay);
  };

  const handleEndTimeChange = (event: SelectChangeEvent) => {
    const interval = generateTimeIntervalByValue(
      parseInt(event.target.value, 10),
    );
    props.setter({
      ...props.currentState,
      endTime: DateTime.fromJSDate((props.currentState as BoostPay).endTime)
        .setZone(timeZone)
        .set({
          hour: interval.hours,
          minute: interval.minutes,
          second: 0,
          millisecond: 0,
        })
        .toJSDate(),
    } as BoostPay);
  };

  const formattedStartAt = DateTime.fromJSDate(props.currentState.startAt)
    .setZone(timeZone)
    .toJSDate();

  const formattedEndAt = DateTime.fromJSDate(
    props.currentState.endAt.time || new Date(),
  )
    .setZone(timeZone)
    .toJSDate();

  const formattedStartTime = DateTime.fromJSDate(
    (props.currentState as BoostPay).startTime,
  ).setZone(timeZone);

  const currentStartTimeInterval = generateTimeIntervalByHourMin(
    formattedStartTime.hour,
    formattedStartTime.minute,
  );

  const formattedEndTime = DateTime.fromJSDate(
    (props.currentState as BoostPay).endTime,
  ).setZone(timeZone);

  const currentEndTimeInterval = generateTimeIntervalByHourMin(
    formattedEndTime.hour,
    formattedEndTime.minute,
  );

  const invalidStartEndDateTime =
    parseDate(formattedEndAt.toISOString()) <
      parseDate(formattedStartAt.toISOString()) ||
    (parseDate(formattedEndAt.toISOString()) ===
      parseDate(formattedStartAt.toISOString()) &&
      formattedEndTime <= formattedStartTime);

  let timeIntervalOptions = timeIntervals.slice();
  if (
    !timeIntervalOptions
      .map((interval) => interval.value)
      .includes(currentStartTimeInterval.value)
  ) {
    timeIntervalOptions.push(currentStartTimeInterval);
  }
  if (
    !timeIntervalOptions
      .map((interval) => interval.value)
      .includes(currentEndTimeInterval.value)
  ) {
    timeIntervalOptions.push(currentEndTimeInterval);
  }
  timeIntervalOptions = timeIntervalOptions.sort((a, b) =>
    a.value < b.value ? -1 : 1,
  );

  const smsMessage = `${boostPay.name} Alert  ${getCurrencySymbolFromLocation(
    selectedFirstLocation,
  )}${boostPay.amount}/extra per order ${formatDateTime(
    DateTime.fromJSDate(boostPay.startAt)
      .setZone(timeZone)
      .set({
        hour: formattedStartTime.hour,
        minute: formattedStartTime.minute,
        second: 0,
        millisecond: 0,
      })
      .toJSDate(),
    timeZone,
  )} - ${
    datesAreOnSameDay(formattedStartAt, formattedEndAt)
      ? formatTime(formattedEndTime.hour, formattedEndTime.minute)
      : formatDateTime(
          DateTime.fromJSDate(boostPay.endAt.time as Date)
            .setZone(timeZone)
            .set({
              hour: formattedEndTime.hour,
              minute: formattedEndTime.minute,
              second: 0,
              millisecond: 0,
            })
            .toJSDate(),
          timeZone,
        )
  }. Grab a delivery block in Gopuff Driver${
    selectedFirstLocation?.isW2Location ? '!' : ' or deliver on-demand!'
  }`;

  return (
    <Box component='div' className={classes.formWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={sharedClasses.subtitle2}>
            Set start date/time to end date/time
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Start Date'
              minDate={
                payRateToEdit?.payRate.status === PayRateStatus.ACTIVE
                  ? undefined
                  : DateTime.now().setZone(timeZone).toJSDate()
              }
              value={formattedStartAt}
              onChange={handleStartAtChange}
              disabled={
                payRateToEdit?.payRate.status === PayRateStatus.ACTIVE ||
                props.isDisabled
              }
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} size='small' />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            size='small'
            variant='outlined'
            className={classes.timeDropDown}
          >
            <InputLabel className={classes.timeDropDownLabel}>
              Start Time
            </InputLabel>
            <Select
              onChange={handleStartTimeChange}
              value={currentStartTimeInterval.value.toString()}
              disabled={
                payRateToEdit?.payRate.status === PayRateStatus.ACTIVE ||
                props.isDisabled
              }
            >
              {timeIntervalOptions.map((interval) => (
                <MenuItem value={interval.value} key={interval.label}>
                  {interval.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='End Date'
              minDate={formattedStartAt}
              value={formattedEndAt}
              onChange={handleEndAtChange}
              disabled={props.isDisabled}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} size='small' />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            size='small'
            variant='outlined'
            className={classes.timeDropDown}
            error={invalidStartEndDateTime}
          >
            <InputLabel className={classes.timeDropDownLabel}>
              End Time
            </InputLabel>
            <Select
              onChange={handleEndTimeChange}
              value={currentEndTimeInterval.value.toString()}
              disabled={props.isDisabled}
            >
              {timeIntervalOptions.map((interval) => (
                <MenuItem value={interval.value} key={interval.label}>
                  {interval.label}
                </MenuItem>
              ))}
            </Select>
            {invalidStartEndDateTime && (
              <FormHelperText>
                End time should be after start time
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography className={sharedClasses.subtitle2}>
            Set boost bonus rate
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<BoostPay>(
            true,
            true,
            props.currentState as BoostPay,
            props.setter,
            'amount',
            payRateToEdit?.payRate.status === PayRateStatus.ACTIVE ||
              props.isDisabled,
            'Amount',
            undefined,
            undefined,
            undefined,
            true,
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Paper elevation={0} className={classes.messageBoxContainer}>
        <Box component='div' className={classes.messageBoxTextWrapper}>
          <Typography
            className={
              !areAllInputsFilled
                ? multiClass([
                    sharedClasses.overline,
                    classes.messageBoxDefaultText,
                  ])
                : classes.messageBoxText
            }
            align={'center'}
          >
            {areAllInputsFilled
              ? smsMessage
              : 'BOOST MESSAGE GENERATES WHEN ALL FIELDS ARE FILLED'}
          </Typography>
          {areAllInputsFilled && (
            <CopyToClipboard
              text={smsMessage}
              onCopy={() => setShowTooltip(true)}
            >
              <Tooltip
                open={showTooltip}
                title={'Copied!'}
                placement='top'
                leaveDelay={1000}
                onClose={() => setShowTooltip(false)}
              >
                <IconButton>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    grid: {
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginTop: theme.spacing(1.5),
    },
    timeDropDown: {
      flexGrow: 1,
      width: '100%',
    },
    timeDropDownLabel: {
      backgroundColor: sharedColors.white,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    messageBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginTop: 'auto',
      marginBottom: '16px',
      backgroundColor: sharedColors.gray1,
    },
    messageBoxTextWrapper: {
      display: 'flex',
      width: 400,
      flexGrow: 1,
    },
    messageBoxDefaultText: {
      color: sharedColors.gray5,
      marginTop: 'auto',
      marginBottom: 'auto',
      flexGrow: 1,
      textAlign: 'center',
    },
    messageBoxText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: sharedColors.gray7,
      marginTop: 'auto',
      marginBottom: 'auto',
      flexGrow: 1,
      textAlign: 'center',
      height: '32px',
      width: '472px',
    },
  }),
);
