import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box } from '@mui/material';
import { sharedColors } from '../../../../utilities/Styles';
import { MissionFormProps } from '../../../../interfaces/Mission/Mission';
import MissionNumberInput from './MissionNumberInput';
import { multiClass } from '../../../../utilities/Extensions';

const BoostMissionForm = (props: MissionFormProps) => {
  const classes = useStyles();

  const handleChangeAmount = (newAmount: number) => {
    props.setCurrentState({
      ...props.currentState,
      reward: { ...props.currentState.reward, pay: newAmount },
    });
  };

  return (
    <Box
      component='div'
      className={multiClass(
        props.applyPadding
          ? [classes.body, classes.extraPadding]
          : [classes.body],
      )}
    >
      <Box
        component='div'
        className={multiClass(
          props.applyPadding
            ? [classes.textField, classes.textFieldExtraPadding]
            : [classes.textField],
        )}
      >
        <MissionNumberInput
          value={props.currentState.reward.pay ?? 0}
          onChange={handleChangeAmount}
          required
          acceptFloat
          label='Bonus Amount'
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      backgroundColor: sharedColors.gray1,
    },
    extraPadding: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    textField: {
      width: 150,
    },
    textFieldExtraPadding: {
      marginLeft: theme.spacing(5),
    },
  }),
);

export default BoostMissionForm;
