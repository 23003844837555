import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { cloneDeep, isEmpty, last } from 'lodash';
import { TextValidator } from 'react-material-ui-form-validator';
import DeleteIcon from '@mui/icons-material/Delete';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import {
  PayRate,
  PayRateFormProps,
  ReturnCoc,
  ReturnCocPaySetting,
} from '../../../interfaces/PayRate';
import {
  isPositiveErrorMessage,
  max2DecimalErrorMessage,
  max2DecimalsRegexp,
  requiredErrorMessage,
} from '../../../utilities/Constants';
import { isNumber } from '../../../utilities/Misc';

interface PaySettingAmountInputProps {
  value: number;
  onChange: (newValue: number) => void;
  disabled: boolean;
}

const PaySettingAmountInput = (props: PaySettingAmountInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = [
    'isPositive',
    `matchRegexp:${max2DecimalsRegexp}`,
    'required',
  ];
  const errorMessages = [
    isPositiveErrorMessage,
    max2DecimalErrorMessage,
    requiredErrorMessage,
  ];

  const handleChange = (e: any) => {
    props.onChange(parseFloat(e.target.value));
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (isFocused) {
      return plainTextValue;
    }
    if (isNumber(props.value)) {
      return props.value.toFixed(2);
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextValidator
      label='Amount'
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
      validators={validators}
      errorMessages={errorMessages}
      name='amount'
      disabled={props.disabled}
    />
  );
};

interface PaySettingRowProps {
  currentSetting: ReturnCocPaySetting;
  minAllowedDropOffs: number;
  maxAllowedDropOffs: number;
  onChange: (newSetting: ReturnCocPaySetting) => void;
  onDelete?: () => void;
  disabled: boolean;
}

const PaySettingRow = (props: PaySettingRowProps) => {
  const classes = useStyles();

  const handleChangeDropOffs = (e: SelectChangeEvent<number>) => {
    props.onChange({
      ...props.currentSetting,
      dropOffs: e.target.value as number,
    });
  };

  const dropOffsOptions: JSX.Element[] = [];
  for (
    let i = props.minAllowedDropOffs;
    i <= props.maxAllowedDropOffs;
    i += 1
  ) {
    dropOffsOptions.push(<MenuItem value={i}>{i.toString()}</MenuItem>);
  }

  const handleChangeAmount = (newAmount: number) => {
    props.onChange({
      ...props.currentSetting,
      amount: newAmount,
    });
  };

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item xs={3} className={classes.gridItem}>
        <FormControl
          disabled={props.disabled}
          size='small'
          variant='outlined'
          className={classes.dropOffsDropdown}
        >
          <InputLabel className={classes.dropdownLabel}>Drop-Offs</InputLabel>
          <Select
            id='drop-offs-select'
            value={props.currentSetting.dropOffs}
            onChange={handleChangeDropOffs}
          >
            {dropOffsOptions}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} className={classes.gridItem}>
        <PaySettingAmountInput
          value={props.currentSetting.amount}
          onChange={handleChangeAmount}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={5} className={classes.gridItem}>
        <IconButton
          size='small'
          color='error'
          disabled={!props.onDelete || props.disabled}
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const ReturnCocForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const returnCoc = props.currentState as ReturnCoc;

  const minDropOffs = 1;
  const maxDropOffs = 3;

  const handleChangeTier = (newSetting: ReturnCocPaySetting, i: number) => {
    const newTiers = cloneDeep(returnCoc.pay);
    newTiers[i] = cloneDeep(newSetting);
    props.setter({
      ...returnCoc,
      pay: newTiers,
    } as ReturnCoc as PayRate);
  };

  const handleDeleteTier = (i: number) => {
    const newTiers = cloneDeep(returnCoc.pay);
    newTiers.splice(i, 1);
    props.setter({
      ...returnCoc,
      pay: newTiers,
    } as ReturnCoc as PayRate);
  };

  const handleNewTier = () => {
    props.setter({
      ...returnCoc,
      pay: [
        ...returnCoc.pay,
        { dropOffs: (last(returnCoc.pay)?.dropOffs ?? 0) + 1, amount: 0 },
      ],
    } as ReturnCoc as PayRate);
  };

  return (
    <Box component='div' className={classes.formWrapper}>
      <Typography component='div' className={sharedClasses.subtitle2}>
        {props.isDisabled
          ? 'Return pay rates for selected number of drop-offs'
          : 'Set return pay rates for selected number of drop-offs'}
      </Typography>
      <Box component='div' className={classes.tiersContainer}>
        {returnCoc.pay.map((paySetting, i) => {
          let minAllowedDropOffs = minDropOffs;
          if (i > 0) {
            minAllowedDropOffs = returnCoc.pay[i - 1].dropOffs + 1;
          }

          let maxAllowedDropOffs = maxDropOffs;
          if (i < returnCoc.pay.length - 1) {
            maxAllowedDropOffs = returnCoc.pay[i + 1].dropOffs - 1;
          }

          return (
            <PaySettingRow
              currentSetting={paySetting}
              disabled={props.isDisabled}
              minAllowedDropOffs={minAllowedDropOffs}
              maxAllowedDropOffs={maxAllowedDropOffs}
              onChange={(newSetting) => handleChangeTier(newSetting, i)}
              onDelete={
                returnCoc.pay.length > 1 ? () => handleDeleteTier(i) : undefined
              }
            />
          );
        })}
        {isEmpty(returnCoc.pay) ||
        (last(returnCoc.pay)?.dropOffs ?? 0) < maxDropOffs ? (
          <Button
            disabled={props.isDisabled}
            onClick={handleNewTier}
            className={classes.newTierButton}
          >
            + New Tier
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    grid: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexGrow: 1,
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    dropOffsDropdown: {
      display: 'flex',
      flexGrow: 1,
    },
    dropdownLabel: {
      backgroundColor: sharedColors.white,
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    tiersContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    newTierButton: {
      textTransform: 'none',
      width: 'fit-content',
    },
  }),
);
