import { makeStyles } from 'tss-react/mui';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import {
  FeatureToggleConfigWithInclusionList,
  FeatureToggleInclusionListEntry,
} from '../../interfaces/FeatureToggle';
import { sharedColors } from '../../utilities/Styles';
import { featureToggleTypeLocation } from '../../utilities/Constants';
import {
  easternTimezone,
  formatDateTime,
  formatLocalTime,
} from '../../utilities/Dates';
import ConfirmInclusionListModificationDialog from './ConfirmInclusionListModificationDialog';
import { ModifierDialogTabKind } from '../../interfaces/Common';
import UpdateInclusionValueDialog from './UpdateInclusionValueDialog';

interface InclusionListTableProps {
  configDetails: FeatureToggleConfigWithInclusionList;
  pageNumber: number;
  pageSize: number;
  onPagination: (pageNumber: number, pageSize: number) => void;
  onExpire: (includedValue: string) => void;
  onUpdate: () => void;
}

const InclusionListTable = (props: InclusionListTableProps) => {
  const { classes } = useStyles();

  const [valueToExpire, setValueToExpire] = useState<string | null>(null);
  const [valueToUpdate, setValueToUpdate] =
    useState<FeatureToggleInclusionListEntry | null>(null);

  const isLocationConfig =
    props.configDetails.type === featureToggleTypeLocation;

  const handleExpire = () => {
    if (valueToExpire) {
      props.onExpire(valueToExpire);
      setValueToExpire(null);
    }
  };

  const handleUpdate = () => {
    setValueToUpdate(null);
    props.onUpdate();
  };

  return (
    <Box component='div'>
      <ConfirmInclusionListModificationDialog
        open={!!valueToExpire}
        includedValues={valueToExpire ? [valueToExpire] : []}
        modificationKind={ModifierDialogTabKind.REMOVE}
        onClose={() => setValueToExpire(null)}
        onConfirm={handleExpire}
      />
      <UpdateInclusionValueDialog
        featureName={props.configDetails.name}
        isLocationType={props.configDetails.type === featureToggleTypeLocation}
        inclusionEntry={valueToUpdate}
        onClose={() => setValueToUpdate(null)}
        onDone={handleUpdate}
      />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell key='included-value'>
                <Typography className={classes.columnLabel}>
                  {props.configDetails.type === featureToggleTypeLocation
                    ? 'Location ID'
                    : 'Driver ID'}
                </Typography>
              </TableCell>
              <TableCell key='start-at'>
                <Typography className={classes.columnLabel}>
                  Start At (EST)
                </Typography>
              </TableCell>
              <TableCell key='end-at'>
                <Typography className={classes.columnLabel}>
                  End At (EST)
                </Typography>
              </TableCell>
              {isLocationConfig && [
                <TableCell key='start-at-local'>
                  <Typography className={classes.columnLabel}>
                    Start At (MFC Local)
                  </Typography>
                </TableCell>,
                <TableCell key='end-at-local'>
                  <Typography className={classes.columnLabel}>
                    End At (MFC Local)
                  </Typography>
                </TableCell>,
              ]}
              <TableCell key='last-updated-at'>
                <Typography className={classes.columnLabel}>
                  Last Updated At
                </Typography>
              </TableCell>
              <TableCell key='last-updated-by'>
                <Typography className={classes.columnLabel}>
                  Last Updated By
                </Typography>
              </TableCell>
              <TableCell key='remove'>
                <Typography className={classes.columnLabel}>Remove</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.configDetails.inclusionList.map((entry, i) => {
              const isFutureEntry = entry.startAt > new Date();
              const key = `${entry.includedValue}-${i.toString()}`;

              return (
                <TableRow key={key}>
                  <TableCell
                    key={`${key}-included-value`}
                    onClick={
                      isFutureEntry ? () => setValueToUpdate(entry) : undefined
                    }
                    className={
                      isFutureEntry ? classes.clickableCell : undefined
                    }
                  >
                    <Typography>{entry.includedValue}</Typography>
                  </TableCell>
                  <TableCell
                    key={`${key}-start-at`}
                    onClick={
                      isFutureEntry ? () => setValueToUpdate(entry) : undefined
                    }
                    className={
                      isFutureEntry ? classes.clickableCell : undefined
                    }
                  >
                    <Typography>
                      {formatDateTime(entry.startAt, easternTimezone)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    key={`${key}-end-at`}
                    onClick={
                      isFutureEntry ? () => setValueToUpdate(entry) : undefined
                    }
                    className={
                      isFutureEntry ? classes.clickableCell : undefined
                    }
                  >
                    <Typography>
                      {entry.endAt
                        ? formatDateTime(entry.endAt, easternTimezone)
                        : '-'}
                    </Typography>
                  </TableCell>
                  {isLocationConfig && [
                    <TableCell
                      key={`${key}-start-at-local`}
                      onClick={
                        isFutureEntry
                          ? () => setValueToUpdate(entry)
                          : undefined
                      }
                      className={
                        isFutureEntry ? classes.clickableCell : undefined
                      }
                    >
                      <Typography>
                        {entry.timezone
                          ? formatDateTime(entry.startAt, entry.timezone)
                          : '-'}
                      </Typography>
                    </TableCell>,
                    <TableCell
                      key={`${key}-end-at-local`}
                      onClick={
                        isFutureEntry
                          ? () => setValueToUpdate(entry)
                          : undefined
                      }
                      className={
                        isFutureEntry ? classes.clickableCell : undefined
                      }
                    >
                      <Typography>
                        {entry.timezone && entry.endAt
                          ? formatDateTime(entry.endAt, entry.timezone)
                          : '-'}
                      </Typography>
                    </TableCell>,
                  ]}
                  <TableCell
                    key={`${key}-last-updated-at`}
                    onClick={
                      isFutureEntry ? () => setValueToUpdate(entry) : undefined
                    }
                    className={
                      isFutureEntry ? classes.clickableCell : undefined
                    }
                  >
                    <Typography>
                      {formatLocalTime(entry.lastUpdatedAt)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    key={`${key}-last-updated-by`}
                    onClick={
                      isFutureEntry ? () => setValueToUpdate(entry) : undefined
                    }
                    className={
                      isFutureEntry ? classes.clickableCell : undefined
                    }
                  >
                    <Typography>{entry.lastUpdatedBy}</Typography>
                  </TableCell>
                  <TableCell key={`${key}-remove`}>
                    <IconButton
                      onClick={() => setValueToExpire(entry.includedValue)}
                    >
                      <CancelIcon className={classes.removeButton} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={props.configDetails.allInclusionCount}
          page={props.pageNumber - 1}
          rowsPerPage={props.pageSize}
          onPageChange={(_, newPage) =>
            props.onPagination(newPage + 1, props.pageSize)
          }
          onRowsPerPageChange={(e) =>
            props.onPagination(1, parseInt(e.target.value, 10))
          }
          rowsPerPageOptions={[10, 15, 25, 50, 100, 250]}
          component='div'
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  removeButton: {
    color: sharedColors.statusRed,
  },
  clickableCell: {
    cursor: 'pointer',
  },
}));

export default InclusionListTable;
