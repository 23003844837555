import { useRecoilValue } from 'recoil';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import {
  BatchNormalizationRules,
  PayRateOverviewProps,
} from '../../../interfaces/PayRate';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { locations as locationsAtom } from '../../../atoms/Location';
import {
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import { multiClass } from '../../../utilities/Extensions';
import { formatDateTime } from '../../../utilities/Dates';

export const BatchNormalizationRulesOverview = (
  props: PayRateOverviewProps,
) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const batchNormalizationRules = props.payRate as BatchNormalizationRules;

  const getPercentageTextByBatchSize = (batchSize: number) => {
    const percentage = batchNormalizationRules.percentagesPerBatchSizes.find(
      (percentagePerBatchSize) =>
        percentagePerBatchSize.batchSize === batchSize,
    )?.percentage;

    if (percentage) {
      return `${percentage.toFixed(2)}%`;
    }
    return '-';
  };

  const location = filterLocationById(
    locations,
    parseInt(batchNormalizationRules.locationID, 10),
  );
  const timeZone =
    getTimeZoneByLocationId(
      locations,
      parseInt(batchNormalizationRules.locationID, 10),
    ) || 'UTC';

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start Date
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(batchNormalizationRules.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Singleton
          </Typography>
          <Typography className={sharedClasses.body1}>
            {getPercentageTextByBatchSize(1)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Batch of 2
          </Typography>
          <Typography className={sharedClasses.body1}>
            {getPercentageTextByBatchSize(2)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Batch of 3
          </Typography>
          <Typography className={sharedClasses.body1}>
            {getPercentageTextByBatchSize(3)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Batch of 4
          </Typography>
          <Typography className={sharedClasses.body1}>
            {getPercentageTextByBatchSize(4)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Batch of 5+
          </Typography>
          <Typography className={sharedClasses.body1}>
            {getPercentageTextByBatchSize(5)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
