import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';
import {
  AdjustmentApprovalStatus,
  AdjustmentEntity,
} from '../../../interfaces/Adjustment';
import { updateAdjustmentApproval } from '../../../services/adjustments';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { undoToast } from '../../../utilities/Toasters';
import AdjustmentDisplayForm from './AdjustmentDisplayForm';
import AdjustmentDenialConfirmDialog from './AdjustmentDenialConfirmDialog';

export const approvalDialogDataTestId = `approval-dialog-test-id`;
export const approveButtonTestId = `approve-button-test-id`;
export const denyButtonTestId = `deny-button-test-id`;

interface AdjustmentApprovalDialogProps {
  onClose: () => void;
  open: boolean;
  adjustment: AdjustmentEntity;
  onApprove: () => void;
  undoDelay?: number;
}

const AdjustmentApprovalDialog = (props: AdjustmentApprovalDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [showDenialConfirmDialog, setShowDenialConfirmDialog] = useState(false);
  const [managerNotes, setManagerNotes] = useState('');
  const handleNoteChange = (event: any) => {
    const fullNotes = event.target.value as string;
    // Only allow the manager to append his notes - not update preexisting notes
    if (fullNotes.startsWith(props.adjustment.notes || '')) {
      const managerNotesSegment = fullNotes.slice(
        props.adjustment.notes?.length || 0,
      );
      setManagerNotes(managerNotesSegment);
    }
  };

  const updateApproval = (status: AdjustmentApprovalStatus) => {
    const type =
      status === AdjustmentApprovalStatus.Approved ? 'success' : 'warning';
    props.onClose();
    undoToast(
      `Updating adjustment...`,
      type,
      () => {
        updateAdjustmentApproval({
          adjustmentId: props.adjustment.id,
          managerNotes,
          status,
        })
          .then(() => {
            props.onApprove();
          })
          .catch((err) => {
            toast.error(`failed to approve adjustment ${err.message}`);
          });
      },
      props.undoDelay,
    );
  };

  const handleDeny = () => {
    updateApproval(AdjustmentApprovalStatus.Denied);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <AdjustmentDenialConfirmDialog
        open={showDenialConfirmDialog}
        onConfirm={handleDeny}
        managerNotes={managerNotes}
        onChangeManagerNotes={(newManagerNotes) =>
          setManagerNotes(newManagerNotes)
        }
        onClose={() => setShowDenialConfirmDialog(false)}
      />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Adjustment Approval
      </DialogTitle>
      <Box data-testid={approvalDialogDataTestId} className={classes.container}>
        <ValidatorForm onSubmit={() => {}}>
          <FormControl component='fieldset'>
            <AdjustmentDisplayForm
              title='Review the adjustment details below for approval'
              adj={props.adjustment}
              readOnly={true}
              managerNotes={managerNotes}
              driverEmail={props.adjustment.driverEmail}
              handleCaseLinkChange={() => {}}
              handleDateInputChange={() => {}}
              handleTextInputChange={() => {}}
              handleNoteChange={handleNoteChange}
              handleSubtypeChange={() => {}}
              handleAmountChange={() => {}}
              handleWorkweekChange={() => {}}
              handleDriverIdChange={() => {}}
            />
            <Box
              component='span'
              m={1}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Button
                variant='contained'
                color={'error'}
                onClick={() => setShowDenialConfirmDialog(true)}
                className={multiClass([
                  sharedClasses.buttonText,
                  classes.denyButton,
                ])}
                data-testid={denyButtonTestId}
              >
                Deny
              </Button>
              <DialogActions>
                <Button
                  variant='contained'
                  color={'inherit'}
                  onClick={props.onClose}
                  className={sharedClasses.buttonText}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  className={sharedClasses.buttonText}
                  onClick={() => {
                    updateApproval(AdjustmentApprovalStatus.Approved);
                  }}
                  data-testid={approveButtonTestId}
                >
                  Approve
                </Button>
              </DialogActions>
            </Box>
          </FormControl>
        </ValidatorForm>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  denyButton: {
    display: 'flex',
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    background: sharedColors.gray1,
  },
}));

export default AdjustmentApprovalDialog;
