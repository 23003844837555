import { isArray } from 'lodash';
import api from './api';
import {
  locationModalitiesFromResponse,
  LocationModality,
} from '../interfaces/LocationModality';

const locationModalityEndpointUrl = '/location-modalities';

export const getLocationModalities = async (): Promise<LocationModality[]> => {
  const response = await api.get(locationModalityEndpointUrl);

  const responseData = response.data;
  if (!isArray(responseData?.location_modalities)) {
    throw new Error(
      `Error while getting location modalities: response data is not an array`,
    );
  }

  return locationModalitiesFromResponse(responseData);
};

export const uploadLocationModalities = async (file: File): Promise<void> => {
  const bodyFormData = new FormData();
  bodyFormData.set('file', file, file.name);
  await api.put(locationModalityEndpointUrl, bodyFormData);
};
