import { isArray, isObject } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import {
  AdjustmentApprovalParams,
  AdjustmentFilter,
  AdjustmentParams,
  AdjustmentEntity,
  AdjustmentResponseWrapper,
} from '../interfaces/Adjustment';
import api from './api';
import { validateCSV } from './Validators';
import { TablePaginationOptions } from '../interfaces/Common';
import { convertLocalToDeliveryZone } from '../utilities/Dates';

export const getAdjustments = async (
  filters: AdjustmentFilter,
  pagination?: TablePaginationOptions,
): Promise<AdjustmentResponseWrapper> => {
  const response = await api.get('/adjustments', {
    params: {
      ...filters,
      workweekStart: filters.workweekStart
        ? convertLocalToDeliveryZone(
            'UTC',
            DateTime.fromJSDate(filters.workweekStart),
            0,
          ).toISO()
        : filters.workweekStart,
      workweekEnd: filters.workweekEnd
        ? convertLocalToDeliveryZone(
            'UTC',
            DateTime.fromJSDate(filters.workweekEnd),
            0,
          ).toISO()
        : filters.workweekEnd,
      ...(pagination ?? {}),
    },
  });
  if (!response.data) {
    return { totalCount: 0, adjustments: [] };
  }

  const respData = response.data as AdjustmentResponseWrapper;
  if (!isArray(respData.adjustments)) {
    throw new Error('could not parse /adjustments endpoint');
  }

  return respData;
};

export const updateAdjustmentApproval = async (
  approval: AdjustmentApprovalParams,
): Promise<AdjustmentEntity> => {
  const resp = await api.put(`/adjustments/${approval.adjustmentId}/approval`, {
    status: approval.status,
    managerNotes: approval.managerNotes,
    uuid: uuidv4(),
  });
  if (!resp.data || !isObject(resp.data)) {
    throw new Error('could not update adjustment: invalid response');
  }
  return resp.data as AdjustmentEntity;
};

export const createAdjustment = async (
  adjustment: AdjustmentParams,
  uuid: string,
): Promise<AdjustmentEntity> => {
  const resp = await api.post(`/adjustments`, {
    ...adjustment,
    uuid,
  });
  if (!resp.data || !isObject(resp.data)) {
    throw new Error('could not create adjustment: invalid response');
  }
  return resp.data as AdjustmentEntity;
};

export const createAdjustmentCSV = async (file: File): Promise<void> => {
  try {
    validateCSV(file);
  } catch (error: any) {
    throw new Error(
      `Could not send csv request due to validation error: ${error.message}`,
    );
  }
  const bodyFormData = new FormData();
  bodyFormData.set('file', file, file.name);
  const uri = `/adjustments/csv/${uuidv4()}`;
  await api.post(uri, bodyFormData);
};
