import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { toast } from 'material-react-toastify';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { DateTime } from 'luxon';
import { selectedPage as selectedPageAtom } from '../atoms/PageState';
import { permissions as permissionsAtom } from '../atoms/Users';

import { sharedColors, useSharedStyles } from '../utilities/Styles';
import { multiClass } from '../utilities/Extensions';
import {
  convertLocalToDeliveryZone,
  easternTimezone,
  getCurrentYear,
  getLastMonth,
  getNamesOfMonths,
} from '../utilities/Dates';
import { getUserPermissions } from '../variables/Users';
import { getPagesListByPermission } from '../variables/Pages';
import { PageType } from '../interfaces/Page';
import { stripePayoutReleaseYear } from '../variables/Payout';
import Waiting from './Waiting';
import {
  getAccruedAmountCsvExport,
  getPayoutCsvExport,
} from '../services/payout';
import { downloadExportedCsv } from '../utilities/Misc';
import { PayoutCsvExportType } from '../interfaces/PayoutCsvExport';

export const payoutCsvExportContainerTestId = 'payoutCsvExportContainer';
export const payoutCsvExportButtonTestId = 'payoutCsvExportButton';
export const payoutCsvExportMonthTestId = 'payoutCsvExportMonth';
export const payoutCsvExportYearTestId = 'payoutCsvExportYear';

export const payoutCsvExportAccruedButtonTestId =
  'payoutCsvExportAccruedButton';

const PayoutCsvExport = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(getLastMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [selectedPayoutProvider, setSelectedPayoutProvider] =
    useState('stripe');
  const [exportType, setExportType] = useState(
    PayoutCsvExportType.FINANCIAL_TIME,
  );

  const [selectedAccruedTime, setSelectedAccruedTime] = useState(new Date());

  const monthNames = getNamesOfMonths();

  useEffect(() => {
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.CSV_EXPORT) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  const getYearsList = (): number[] => {
    const nextYear = new Date().getFullYear() + 1;
    const years: number[] = [];
    for (let i = stripePayoutReleaseYear; i <= nextYear; i += 1) {
      years.push(i);
    }
    return years;
  };

  const handleExportPayout = () => {
    setLoading(true);
    getPayoutCsvExport(
      selectedMonth,
      selectedYear,
      selectedPayoutProvider,
      exportType,
    )
      .then((csvContent) => {
        downloadExportedCsv(csvContent, 'stripe_payouts');
        toast.success('CSV download has started');
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetSelectedAccruedTime = (
    newSelectedAccruedTime: Date | null,
  ) => {
    setSelectedAccruedTime(newSelectedAccruedTime || new Date());
  };

  const handleExportAccruedAmount = () => {
    setLoading(true);
    const selectedTimeAsDateTime = DateTime.fromJSDate(selectedAccruedTime);
    const convertedDateTime = convertLocalToDeliveryZone(
      easternTimezone,
      selectedTimeAsDateTime,
      selectedTimeAsDateTime.hour,
      selectedTimeAsDateTime.minute,
    );
    getAccruedAmountCsvExport(convertedDateTime)
      .then((csvContent) => {
        downloadExportedCsv(csvContent, 'accrued_amounts');
        toast.success('CSV download has started');
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectMonth = (event: SelectChangeEvent<number>) => {
    const newSelectedMonths = event.target.value as number;
    setSelectedMonth(newSelectedMonths);
  };

  const handleSelectYear = (event: SelectChangeEvent<number>) => {
    const newSelectedYears = event.target.value as number;
    setSelectedYear(newSelectedYears);
  };

  const handleSelectPayoutProvider = (event: SelectChangeEvent<string>) => {
    setSelectedPayoutProvider(event.target.value);
  };

  const handleSelectExportType = (event: SelectChangeEvent) => {
    setExportType(event.target.value as PayoutCsvExportType);
  };

  return (
    <Box component='div' data-testid={payoutCsvExportContainerTestId}>
      <Waiting open={loading} />
      <Paper className={classes.header}>
        <Box component='div' className={classes.textContainer}>
          <Typography className={multiClass([sharedClasses.h2, classes.title])}>
            1099 Accounting Export
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.subtitle1,
              classes.description,
            ])}
          >
            This CSV export will contain all delivery partner payouts that have
            been
            <br />
            completed via Stripe within the selected timeframe.
          </Typography>
          <Typography
            className={multiClass([classes.note, sharedClasses.body1])}
          >
            *This currently only includes 1099 partners working in the US.
          </Typography>
        </Box>
        <Box component='div' className={classes.spacer} />
        <Box component='div' className={classes.row}>
          <FormControl
            size='small'
            variant='outlined'
            className={classes.dropdown}
          >
            <InputLabel className={classes.dropdownLabel}>Month</InputLabel>
            <Select
              onChange={handleSelectMonth}
              value={selectedMonth}
              data-testid={payoutCsvExportMonthTestId}
            >
              {monthNames.map((name, index) => (
                <MenuItem id={name} value={index} key={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            size='small'
            variant='outlined'
            className={classes.dropdown}
          >
            <InputLabel className={classes.dropdownLabel}>Year</InputLabel>
            <Select
              onChange={handleSelectYear}
              value={selectedYear}
              data-testid={payoutCsvExportYearTestId}
            >
              {getYearsList().map((year) => (
                <MenuItem id={year.toString()} value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            size='small'
            variant='outlined'
            className={classes.dropdown}
          >
            <InputLabel className={classes.dropdownLabel}>
              Payout Provider
            </InputLabel>
            <Select
              onChange={handleSelectPayoutProvider}
              value={selectedPayoutProvider}
              disabled
            >
              <MenuItem id='stripe' value='stripe' key='stripe'>
                Stripe
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            size='small'
            variant='outlined'
            className={classes.versionDropdown}
          >
            <InputLabel className={classes.dropdownLabel}>Version</InputLabel>
            <Select onChange={handleSelectExportType} value={exportType}>
              <MenuItem
                id={PayoutCsvExportType.FINANCIAL_TIME}
                value={PayoutCsvExportType.FINANCIAL_TIME}
                key={PayoutCsvExportType.FINANCIAL_TIME}
              >
                Financial Time
              </MenuItem>
              <MenuItem
                id={PayoutCsvExportType.ORIGINAL}
                value={PayoutCsvExportType.ORIGINAL}
                key={PayoutCsvExportType.ORIGINAL}
              >
                Original
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            id='export-csv-button'
            color='primary'
            variant='contained'
            onClick={handleExportPayout}
            className={multiClass([sharedClasses.buttonText, classes.button])}
            data-testid={payoutCsvExportButtonTestId}
          >
            Export
          </Button>
        </Box>
      </Paper>
      <Divider />
      <Paper className={classes.header}>
        <Box component='div' className={classes.textContainer}>
          <Typography className={multiClass([sharedClasses.h2, classes.title])}>
            Accrued 1099 US Delivery Partner Pay
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.subtitle1,
              classes.description,
            ])}
          >
            This CSV export shows, by location, how much Delivery Partner Pay
            has been
            <br />
            accrued as of the selected date but is not yet paid out (all times
            are in ET).
          </Typography>
          <Typography
            className={multiClass([classes.note, sharedClasses.body1])}
          >
            *This currently only includes 1099 partners working in the US.
          </Typography>
        </Box>
        <Box component='div' className={classes.spacer} />
        <Box component='div' className={classes.row}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              onChange={handleSetSelectedAccruedTime}
              value={selectedAccruedTime}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} size='small' />}
            />
          </LocalizationProvider>
          <Button
            id='export-accrued-csv-button'
            color='primary'
            variant='contained'
            onClick={handleExportAccruedAmount}
            className={multiClass([sharedClasses.buttonText, classes.button])}
            data-testid={payoutCsvExportAccruedButtonTestId}
          >
            Export
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1.5),
  },
  description: {
    fontWeight: '400',
    fontSize: 14,
  },
  note: {
    color: sharedColors.gray5,
    marginTop: theme.spacing(1.5),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  dropdown: {
    minWidth: 120,
    marginLeft: theme.spacing(1.5),
    backgroundColor: sharedColors.white,
  },
  versionDropdown: {
    minWidth: 200,
    marginLeft: theme.spacing(1.5),
    backgroundColor: sharedColors.white,
  },
  dropdownLabel: {
    backgroundColor: sharedColors.white,
  },
}));

export default PayoutCsvExport;
