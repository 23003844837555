import React, { createRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'material-react-toastify';

import { payRateToEdit as payRateToEditAtom } from '../../atoms/PayRate';
import { multiClass } from '../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';

import { NewPayRateStep, NewPayRateStepKind } from '../../interfaces/PayRate';
import Waiting from '../Waiting';

import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { targetCommissionToEditAtom } from '../../atoms/TargetCommissions';
import { updateTargetCommission } from '../../services/targetCommissions';
import { ConfirmTargetCommission } from './ConfirmTargetCommission';
import { SetTargetCommissionRules } from './SetTargetCommissionRules';
import { TargetCommission } from '../../interfaces/TargetCommissions';
import { ConfirmDialog } from '../NewPayRateDialog/ConfirmDialog';

type UpdateTargetCommissionDialogProps = {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
};

export const newTargetCommissionDialogHeaderTestId =
  'newTargetCommissionDialogHeader';
export const newTargetCommissionDialogNextButtonTestId =
  'newTargetCommissionDialogNextButton';
export const newTargetCommissionDialogBackButtonTestId =
  'newTargetCommissionDialogBackButton';
export const targetCommissionDialogRevertButtonTestId =
  'targetCommissionDialogRevertButtonTestId';

export const UpdateTargetCommissionDialog = (
  props: UpdateTargetCommissionDialogProps,
) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const selectedPage = useRecoilValue(selectedPageAtom);
  const payRateToEdit = useRecoilValue(payRateToEditAtom);
  const targetCommissionToEdit = useRecoilValue(targetCommissionToEditAtom);

  const validatorRef = createRef<ValidatorForm>();

  const [loading, setLoading] = useState(false);
  const [showConfirmRevertDialog, setShowConfirmRevertDialog] = useState(false);

  const [currentTargetCommission, setCurrentTargetCommission] = useState(
    targetCommissionToEdit as TargetCommission,
  );

  const [invalidRules, setInvalidRules] = useState(false);

  useEffect(() => {
    resetState();
  }, [selectedPage]);

  const resetState = () => {
    setCurrentStep(steps[0]);

    setCurrentTargetCommission(targetCommissionToEdit as TargetCommission);
    setInvalidRules(false);
  };

  useEffect(() => {
    resetState();
  }, [payRateToEdit]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidRules(!isValid);
    });
  }, [currentTargetCommission]);

  const handleClose = () => {
    resetState();
    props.onClose();
  };

  const handleSetRules = (editedCommission: number) => {
    setCurrentTargetCommission({
      ...currentTargetCommission,
      targetCommissionUnscheduledEdited: editedCommission,
    });
  };

  const handleValidationError = () => setInvalidRules(true);

  const handleNext = () => {
    if (currentStep.kind !== NewPayRateStepKind.CONFIRM) {
      setCurrentStep(steps[1]);
    } else {
      setLoading(true);

      updateTargetCommission(currentTargetCommission!)
        .then(() => {
          toast.success('Successfully updated target commission');
          resetState();
          props.onDone();
        })
        .catch((error: Error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleRevert = () => {
    setLoading(true);
    updateTargetCommission({
      ...currentTargetCommission,
      targetCommissionUnscheduledEdited: undefined,
    })
      .then(() => {
        toast.success('Successfully reverted target commission');
        resetState();
        props.onDone();
      })
      .catch((error: Error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    setCurrentStep(steps[0]);
  };

  const steps: NewPayRateStep[] = [
    {
      kind: NewPayRateStepKind.SET_RULES,
      title: 'EDIT TARGET COMMISSION',
      label: 'SET RULES',
      content: (
        <SetTargetCommissionRules
          currentState={currentTargetCommission!}
          setter={handleSetRules}
        />
      ),
    },
    {
      kind: NewPayRateStepKind.CONFIRM,
      title: 'Confirm Changes',
      label: 'CONFIRM',
      content: (
        <ConfirmTargetCommission
          editedTargetCommission={currentTargetCommission!}
        />
      ),
    },
  ];

  const [currentStep, setCurrentStep] = useState(steps[0]);

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth='md'>
      <div style={{ width: 500 }}>
        <Waiting open={loading} />
        <ValidatorForm
          instantValidate={true}
          onSubmit={handleNext}
          onError={handleValidationError}
          ref={validatorRef}
        >
          <DialogTitle
            className={multiClass([sharedClasses.h6, classes.title])}
            data-testid={newTargetCommissionDialogHeaderTestId}
          >
            {currentStep.title}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box component='div' className={classes.dialogContent}>
              {currentStep.content}
            </Box>
          </DialogContent>
          <DialogActions>
            {currentStep.kind === NewPayRateStepKind.SET_RULES ? (
              <Button
                variant='contained'
                color={'error'}
                onClick={() => setShowConfirmRevertDialog(true)}
                className={multiClass([
                  sharedClasses.buttonText,
                  classes.revertButton,
                ])}
                data-testid={targetCommissionDialogRevertButtonTestId}
              >
                {'Revert'}
              </Button>
            ) : (
              <></>
            )}
            <Box component='div' className={classes.actionButtonDivider} />
            <Button
              id='back-cancel-button'
              variant='contained'
              color={'inherit'}
              onClick={
                currentStep.kind === NewPayRateStepKind.CONFIRM
                  ? handleBack
                  : handleClose
              }
              className={sharedClasses.buttonText}
              data-testid={newTargetCommissionDialogBackButtonTestId}
            >
              {currentStep.kind === NewPayRateStepKind.CONFIRM
                ? 'Back'
                : 'Cancel'}
            </Button>
            <Button
              id='save-continue-button'
              variant='contained'
              color='primary'
              disabled={
                currentStep.kind !== NewPayRateStepKind.CONFIRM && invalidRules
              }
              type='submit'
              className={sharedClasses.buttonText}
              data-testid={newTargetCommissionDialogNextButtonTestId}
            >
              {currentStep.kind === NewPayRateStepKind.CONFIRM
                ? 'Save'
                : 'Continue'}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </div>
      <ConfirmDialog
        onClose={() => setShowConfirmRevertDialog(false)}
        onConfirm={handleRevert}
        open={showConfirmRevertDialog}
        cancelText='Cancel'
        okText={'REVERT'}
        content={`Do you want to revert override target commission? This action will also revert override of next weeks target commissions.`}
        title='Are you sure?'
        color='primary'
      />
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: sharedColors.gray6,
    },
    dialogContent: {
      flexDirection: 'column',
    },
    actionButtonDivider: {
      flex: '1 0 0',
    },
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      backgroundColor: sharedColors.statusYellowLightest,
    },
    infoBoxIcon: {
      color: sharedColors.statusYellow,
    },
    infoBoxTextWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.7),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.statusYellowDark,
    },
    infoBoxMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '18px',
      color: sharedColors.statusYellowDark,
      marginTop: theme.spacing(1),
    },
    revertButton: {
      color: sharedColors.statusRed,
      backgroundColor: sharedColors.statusRedLightest,
    },
  }),
);
