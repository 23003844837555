import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { AdjustmentEntity } from '../../../interfaces/Adjustment';
import PendingAdjustment from './PendingAdjustment';

interface AdjustmentsSideBarProps {
  open: boolean;
  pendingAdjustments: AdjustmentEntity[];
  onApprovalUpdate: () => void;
  onClose: () => void;
}

const AdjustmentsSideBar = (props: AdjustmentsSideBarProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Drawer open={props.open} onClose={props.onClose} anchor='right'>
      <Box component='div' className={classes.tabsContainer}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={10}>
            <Typography variant='h6' align='left'>
              Notifications
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              edge='end'
              className={sharedClasses.iconColor}
              onClick={props.onClose}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.subtitle}>
        <Typography variant='subtitle1' align='center'>
          Adjustments Pending Approval
        </Typography>
      </Box>

      {props.pendingAdjustments.map((adjustment) => (
        <PendingAdjustment
          key={adjustment.id}
          adjustment={adjustment}
          onApprove={props.onApprovalUpdate}
        />
      ))}
    </Drawer>
  );
};

const useStyles = makeStyles()(() => ({
  tabsContainer: {
    width: '460px',
    height: '56px',
    left: '980px',
    top: '53px',
    background: sharedColors.gray2,
  },
  gridContainer: {
    flexGrow: 1,
    marginTop: '12px',
    marginLeft: '20px',
  },
  subtitle: {
    display: 'flex',
    margin: '10px',
  },
}));

export default AdjustmentsSideBar;
