import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { AdjustmentSummary } from '../../../../interfaces/PayHistory/AdjustmentSummary';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  adjustmentsNodeID,
  composeNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import AdjustmentNode from './AdjustmentNode';

interface AdjustmentsListNodeProps {
  adjustments: AdjustmentSummary[];
  currency: string;
}

const AdjustmentsListNode = (props: AdjustmentsListNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={adjustmentsNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Adjustments [${props.adjustments.length.toString()}]`}
        </Typography>
      }
    >
      {props.adjustments.map((adjustment, i) => (
        <AdjustmentNode
          id={composeNodeID([adjustmentsNodeID, i.toString()])}
          index={i}
          adjustment={adjustment}
          currency={props.currency}
        />
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default AdjustmentsListNode;
