export interface LocationModality {
  locationID: string;
  locationName: string;
  modality: string;
  completedOrderCount: number;
}

export const locationModalityFromResponse = (
  response: any,
): LocationModality => ({
  locationID: response.location_id,
  locationName: response.location_name,
  modality: response.modality,
  completedOrderCount: response.completed_order_count,
});

export const locationModalitiesFromResponse = (
  response: any,
): LocationModality[] =>
  (response.location_modalities ?? []).map((locationModality: any) =>
    locationModalityFromResponse(locationModality),
  );
