import { atom } from 'recoil';
import { PayRateToEdit } from '../interfaces/PayRate';

export const payRateToEdit = atom({
  key: 'payRateToEdit',
  default: null as PayRateToEdit | null,
});

export const activeWaitPayExists = atom({
  key: 'activeWaitPayExists',
  default: false,
});
