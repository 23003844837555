import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useRecoilValue } from 'recoil';
import { toast } from 'material-react-toastify';
import { isEmpty } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { downloadCsvTemplate } from '../../utilities/Csv';
import { waitPayRatesTemplate } from '../../variables/CsvTemplate';
import UploadCSV from '../UploadCSV';
import { PayRateSchedule } from '../../interfaces/PayRate';
import { locations as locationsAtom } from '../../atoms/Location';
import {
  getEarliestWaitPayDate,
  parsePayRateScheduleCsv,
} from '../../utilities/PayRateSchedule';
import { Location } from '../../interfaces/Location';
import { filterLocationById } from '../../utilities/Locations';
import { PayRateScheduleForm } from '../NewPayRateDialog/PayRateForms/PayRateSchedule/PayRateSchedule';
import { createBatchPayRateSchedules } from '../../services/payRateSchedule';
import Waiting from '../Waiting';

interface UploadWaitPayRatesDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: () => void;
}

const UploadWaitPayRatesDialog = (props: UploadWaitPayRatesDialogProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const earliestDate = getEarliestWaitPayDate();

  const locations = useRecoilValue(locationsAtom);

  const [loading, setLoading] = useState(false);
  const [startAt, setStartAt] = useState(earliestDate);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [waitPayRates, setWaitPayRates] = useState<PayRateSchedule[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );

  useEffect(() => {
    setFile(undefined);
  }, [props.open]);

  useEffect(() => {
    if (!file) {
      setWaitPayRates([]);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const text = (e.target?.result ?? '') as string;
      const csvRows = text
        .split('\n')
        .slice(1)
        .filter((line) => line.trim().length > 0)
        .map((line) => line.split(',').map((cell) => cell.trim()));

      try {
        setWaitPayRates(parsePayRateScheduleCsv(csvRows, locations, startAt));
      } catch (err: any) {
        toast.error(err.message);
        setWaitPayRates([]);
      }
    };
    reader.onerror = () => toast.error('Could not read the input file');

    reader.readAsText(file);
  }, [file]);

  useEffect(() => {
    if (isEmpty(waitPayRates)) {
      setSelectedLocation(null);
      return;
    }

    setSelectedLocation(
      filterLocationById(locations, parseInt(waitPayRates[0].locationID, 10)),
    );
  }, [waitPayRates]);

  const handleChangeStartAt = (newStartAt: Date | null) => {
    setStartAt(newStartAt ?? earliestDate);
    setWaitPayRates(
      waitPayRates.map((waitPayRate) => ({
        ...waitPayRate,
        startAt: newStartAt ?? earliestDate,
      })),
    );
  };

  const handleChangeLocation = (_: any, newLocation: Location | null) => {
    setSelectedLocation(newLocation);
  };

  const handleUpload = () => {
    if (isEmpty(waitPayRates)) {
      props.onClose();
      return;
    }

    setLoading(true);
    createBatchPayRateSchedules(waitPayRates, locations)
      .then(() => {
        toast.success('Successfully uploaded wait pay rates');
        props.onUpload();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoading(false);
      });
  };

  const presentLocationIDs = waitPayRates.map((waitPayRate) =>
    parseInt(waitPayRate.locationID, 10),
  );

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='md'>
      <Waiting open={loading} />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Upload Wait Pay Rates
      </DialogTitle>
      <DialogContent>
        <Typography className={sharedClasses.subtitle2}>
          Set start date
        </Typography>
        <Box component='div' className={classes.row}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={earliestDate}
              onChange={handleChangeStartAt}
              className={classes.datePicker}
              value={startAt}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => (
                <TextField
                  {...params}
                  size='small'
                  className={classes.datePicker}
                />
              )}
            />
            {file && (
              <Button
                color='error'
                variant='contained'
                startIcon={<DeleteForeverIcon />}
                onClick={() => setFile(undefined)}
                className={multiClass([
                  sharedClasses.buttonText,
                  classes.clearButton,
                ])}
              >
                Clear File
              </Button>
            )}
          </LocalizationProvider>
        </Box>
        {!file && <UploadCSV setParentFile={setFile} />}
        {isEmpty(presentLocationIDs) ? (
          <></>
        ) : (
          <Box component='div' className={classes.verticalSpacer}>
            <Typography className={sharedClasses.subtitle2}>
              Browse wait pay rates
            </Typography>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Location Name'
                  variant='outlined'
                  size='small'
                  className={classes.locationAutocomplete}
                />
              )}
              options={locations.filter((location) =>
                presentLocationIDs.includes(location.id),
              )}
              getOptionLabel={(location) => location.name}
              value={selectedLocation}
              onChange={handleChangeLocation}
            />
            {selectedLocation && (
              <ValidatorForm onSubmit={() => {}}>
                <PayRateScheduleForm
                  title='Wait Pay'
                  currentState={
                    waitPayRates.find(
                      (waitPayRate) =>
                        waitPayRate.locationID ===
                        selectedLocation.id.toString(),
                    )!
                  }
                  setter={() => {}}
                  isDisabled
                />
              </ValidatorForm>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box component='div' className={classes.dialogActionsSpacer}>
          <Button
            onClick={() => downloadCsvTemplate(waitPayRatesTemplate)}
            className={sharedClasses.buttonText}
          >
            Download CSV Template for Wait Pay
          </Button>
        </Box>
        <Button
          variant='contained'
          color='inherit'
          disabled={loading}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          variant='contained'
          color='primary'
          className={sharedClasses.buttonText}
          disabled={isEmpty(waitPayRates) || loading}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: sharedColors.gray6,
    },
    dialogContent: {
      flexDirection: 'column',
    },
    dialogActions: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2.5),
      paddingBottom: theme.spacing(1.5),
    },
    dialogActionsSpacer: {
      flexGrow: 1,
      display: 'flex',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(1.5),
    },
    datePicker: {
      width: 180,
    },
    locationAutocomplete: {
      width: 300,
      marginTop: theme.spacing(1.5),
    },
    dropzone: {
      height: 150,
    },
    verticalSpacer: {
      marginTop: theme.spacing(1.5),
    },
    clearButton: {
      marginLeft: theme.spacing(1.5),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  }),
);

export default UploadWaitPayRatesDialog;
