import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { sharedColors, useSharedStyles } from '../utilities/Styles';
import { errorMessageSeparator } from '../services/api';

type BulkErrorDialogProps = {
  open: boolean;
  message: string;
  onClose: () => void;
};

export const BulkErrorDialog = (props: BulkErrorDialogProps) => {
  const sharedClasses = useSharedStyles().classes;
  const { classes } = useStyles();

  const errorMessages = props.message.split(errorMessageSeparator);
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>Error(s)</DialogTitle>
      <DialogContent>
        {errorMessages.map((errorMessage) => (
          <Paper className={classes.paperBody} key={errorMessage}>
            {errorMessage.split('\n').map((e) => (
              <Typography className={classes.errorText} key={e}>
                {e}
              </Typography>
            ))}
          </Paper>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='contained'
          color='inherit'
          className={sharedClasses.buttonText}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  paperBody: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: sharedColors.statusRedLightest,
  },
  errorText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: sharedColors.black,
  },
}));
