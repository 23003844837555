import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { EarningRadarSummary } from '../../interfaces/EarningRadar';
import { multiClass } from '../../utilities/Extensions';
import { locations as locationsAtom } from '../../atoms/Location';
import { filterLocationById } from '../../utilities/Locations';
import { PayRateStatus } from '../../interfaces/PayRate';
import StringField from '../StringField';
import { easternTimezone, formatDateTime } from '../../utilities/Dates';
import StatusField from '../StatusField';
import { TablePaginationOptions } from '../../interfaces/Common';

interface EarningRadarTableProps {
  earningRadarSummaries: EarningRadarSummary[];
}

const EarningRadarTable = (props: EarningRadarTableProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 50,
  });

  useEffect(() => {
    setPagination({ ...pagination, page: 1 });
  }, [props.earningRadarSummaries]);

  const handlePagination = (newPagination: TablePaginationOptions) => {
    setPagination(newPagination);
  };

  const paginatedSummaries = props.earningRadarSummaries.slice(
    (pagination.page - 1) * pagination.rowsPerPage,
    pagination.page * pagination.rowsPerPage,
  );

  return (
    <Box component='div' className={classes.outerContainer}>
      <Typography className={multiClass([sharedClasses.h5, classes.title])}>
        Earning Radar Summaries
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Location Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Hot</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>ATA P90</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  ATA Reference Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  AOPD Latest
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  AOPD Reference Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Min-Max Wait Times
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Wait Times Reference Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Virtual Queue Size
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Virtual Queue Reference Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty(props.earningRadarSummaries) ? (
              <TableRow key='no-summaries'>
                <TableCell colSpan={11} align='center'>
                  <Typography
                    className={multiClass([
                      sharedClasses.subtitle2,
                      classes.noSummariesText,
                    ])}
                  >
                    No summaries
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedSummaries.map((summary) => {
                const location = filterLocationById(
                  locations,
                  parseInt(summary.locationID, 10),
                );

                const status = summary.isActive
                  ? PayRateStatus.ACTIVE
                  : PayRateStatus.EXPIRED;
                const isDisabled = status === PayRateStatus.EXPIRED;
                return (
                  <TableRow key={summary.locationID}>
                    <TableCell>
                      <StringField
                        value={location?.name ?? summary.locationID}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={summary.isHot ? '🔥' : ''}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={summary.ataP90 ? summary.ataP90.toFixed(2) : '-'}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.ataP90ReferenceTime
                            ? formatDateTime(
                                summary.ataP90ReferenceTime,
                                location?.timeZone ?? easternTimezone,
                              )
                            : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.aopdLatest
                            ? summary.aopdLatest.toFixed(2)
                            : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.aopdReferenceTime
                            ? formatDateTime(
                                summary.aopdReferenceTime,
                                location?.timeZone ?? easternTimezone,
                              )
                            : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.maxWaitTimeInMin
                            ? `${summary.minWaitTimeInMin.toString()} - ${summary.maxWaitTimeInMin.toString()}`
                            : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.minMaxWaitTimeReferenceTime
                            ? formatDateTime(
                                summary.minMaxWaitTimeReferenceTime,
                                location?.timeZone ?? easternTimezone,
                              )
                            : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.vqLength ? summary.vqLength.toString() : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          summary.virtualQueueSizeReferenceTime
                            ? formatDateTime(
                                summary.virtualQueueSizeReferenceTime,
                                location?.timeZone ?? easternTimezone,
                              )
                            : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <StatusField status={status} />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          count={props.earningRadarSummaries.length}
          page={pagination.page - 1}
          onPageChange={(_, newPage) =>
            handlePagination({ ...pagination, page: newPage + 1 })
          }
          rowsPerPage={pagination.rowsPerPage}
          onRowsPerPageChange={(e) =>
            handlePagination({
              page: 1,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[15, 25, 50, 100, 250]}
          component='div'
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    editableRow: {
      cursor: 'pointer',
    },
    noSummariesText: {
      flexGrow: 1,
      textAlign: 'center',
      lineHeight: 2,
    },
  }),
);

export default EarningRadarTable;
