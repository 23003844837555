import React from 'react';
import { Box, Grid, Theme, Tooltip, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useRecoilValue } from 'recoil';

import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import {
  locations as locationsAtom,
  selectedLocationIDs as selectedLocationIDsAtom,
} from '../../../atoms/Location';
import { payRateToEdit as payRateToEditAtom } from '../../../atoms/PayRate';
import { BoostPay, PayRateOverviewProps } from '../../../interfaces/PayRate';
import {
  getCurrencySymbolFromLocation,
  filterLocationById,
  locationIdToName,
} from '../../../utilities/Locations';
import { calculateTimeDiff, formatDateTime } from '../../../utilities/Dates';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';

export const BoostPayOverview = (props: PayRateOverviewProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const payRateToEdit = useRecoilValue(payRateToEditAtom);
  const selectedLocationIDs = useRecoilValue(selectedLocationIDsAtom);

  const boostPay = props.payRate as BoostPay;

  const selectedLocationNames = selectedLocationIDs.map((id) =>
    locationIdToName(locations, parseInt(id, 10)),
  );

  const isMultipleLocation = !payRateToEdit && selectedLocationIDs.length > 1;

  const locationID = payRateToEdit
    ? parseInt(boostPay.locationID, 10)
    : parseInt(selectedLocationIDs[0], 10);

  const location = filterLocationById(locations, locationID);
  const timeZone = payRateToEdit ? location?.timeZone ?? 'UTC' : 'UTC';

  const startTime = DateTime.fromJSDate(boostPay.startTime).setZone(timeZone);
  const endTime = DateTime.fromJSDate(boostPay.endTime).setZone(timeZone);

  const startAt = DateTime.fromJSDate(boostPay.startAt)
    .setZone(timeZone)
    .set({
      hour: startTime.hour,
      minute: startTime.minute,
      second: 0,
      millisecond: 0,
    })
    .toJSDate();

  const endAt = DateTime.fromJSDate(boostPay.endAt.time!)
    .setZone(timeZone)
    .set({
      hour: endTime.hour,
      minute: endTime.minute,
      second: 0,
      millisecond: 0,
    })
    .toJSDate();

  const getLocationFieldValue = (): string => {
    if (payRateToEdit) {
      return location?.name || '-';
    }

    if (isEmpty(selectedLocationIDs)) {
      return '-';
    }
    if (selectedLocationIDs.length === 1) {
      return locationIdToName(locations, parseInt(selectedLocationIDs[0], 10));
    }
    return `${selectedLocationIDs.length} locations`;
  };

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            component={'div'}
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Tooltip
            title={isMultipleLocation ? selectedLocationNames.toString() : ''}
          >
            <Box component='div' className={classes.row}>
              <Typography
                component={'div'}
                className={multiClass([
                  sharedClasses.body1,
                  classes.verticalCenter,
                ])}
              >
                {getLocationFieldValue()}
              </Typography>
              {isMultipleLocation && (
                <InfoOutlinedIcon
                  fontSize='small'
                  className={classes.infoIcon}
                />
              )}
            </Box>
          </Tooltip>
        </Box>
      </Grid>

      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            component={'div'}
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Starts
          </Typography>
          <Typography component={'div'} className={sharedClasses.body1}>
            {formatDateTime(startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>

      <Grid key='endDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            component={'div'}
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Ends
          </Typography>
          <Box component='div' className={classes.row}>
            <Typography component='div' className={sharedClasses.body1}>
              {formatDateTime(endAt, timeZone)}
            </Typography>
            <Typography
              className={multiClass([sharedClasses.body2, classes.fieldUnit])}
            >
              {calculateTimeDiff(startAt, endAt)}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid key='boostPay' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            component={'div'}
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Boost Pay
          </Typography>
          <Typography component={'div'} className={sharedClasses.body1}>
            {boostPay.amount &&
              `${getCurrencySymbolFromLocation(
                location,
              )}${boostPay.amount.toFixed(2)}`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    fieldUnit: {
      color: sharedColors.gray5,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(0.75),
    },
    grid: {
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    infoIcon: {
      color: sharedColors.blue4,
      marginLeft: theme.spacing(1),
    },
    verticalCenter: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  }),
);
