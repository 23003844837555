import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Theme,
  Typography,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  OvertimePay,
  PayRate,
  PayRateFormProps,
  RateMultiplier,
} from '../../../interfaces/PayRate';
import { useSharedStyles } from '../../../utilities/Styles';
import { generateNumberInput } from '../../../utilities/PayRates';

export const OvertimePayForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const rateMultipliers: RateMultiplier[] = [
    {
      checkboxLabel: (
        <Typography component={'div'} className={classes.checkboxLabel}>
          Enable overtime for
          <Box id='overtime-checkbox' fontWeight='bold' display='inline'>
            {' daily '}
          </Box>
          work
          <Box fontWeight='bold' display='inline'>
            {' over 8 hours '}
          </Box>
        </Typography>
      ),
      fieldName: 'eightHoursMultiplier',
      checkboxFieldName: 'eightHoursChecked' as keyof OvertimePay,
    },
    {
      checkboxLabel: (
        <Typography component={'div'} className={classes.checkboxLabel}>
          Enable overtime for
          <Box id='overtime-checkbox' fontWeight='bold' display='inline'>
            {' daily '}
          </Box>
          work
          <Box fontWeight='bold' display='inline'>
            {' over 12 hours '}
          </Box>
        </Typography>
      ),
      fieldName: 'twelveHoursMultiplier',
      checkboxFieldName: 'twelveHoursChecked' as keyof OvertimePay,
    },
    {
      checkboxLabel: (
        <Typography component={'div'} className={classes.checkboxLabel}>
          Enable overtime for
          <Box id='overtime-checkbox' fontWeight='bold' display='inline'>
            {' weekly '}
          </Box>
          work
          <Box fontWeight='bold' display='inline'>
            {' over 40 hours '}
          </Box>
        </Typography>
      ),
      fieldName: 'fortyHoursMultiplier',
      checkboxFieldName: 'fortyHoursChecked' as keyof OvertimePay,
    },
  ];

  const handleChange = (e: any, rateMultiplier: RateMultiplier) => {
    props.setter({
      ...props.currentState,
      [rateMultiplier.fieldName]: undefined,
      [rateMultiplier.checkboxFieldName]: e.target.checked,
    } as PayRate);
  };

  return (
    <Box component='div' className={classes.formWrapper}>
      <Typography component={'div'} className={sharedClasses.subtitle2}>
        Select overtime thresholds and set overtime pay multiplier
      </Typography>

      <Grid container spacing={1} className={classes.grid}>
        {rateMultipliers.map((rateMultiplier) => [
          <Grid
            key={`${rateMultiplier.checkboxFieldName}`}
            item
            xs={8}
            className={classes.gridItem}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color={'primary'}
                    checked={
                      ((props.currentState as OvertimePay)[
                        rateMultiplier.checkboxFieldName
                      ] as boolean) ?? false
                    }
                    onChange={(event) => {
                      handleChange(event, rateMultiplier);
                    }}
                    disabled={props.isDisabled}
                  />
                }
                label={
                  <Typography
                    component={'div'}
                    className={classes.checkboxLabel}
                  >
                    {rateMultiplier.checkboxLabel}
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>,
          <Grid key={`${rateMultiplier.fieldName}`} item xs={4}>
            {generateNumberInput<OvertimePay>(
              (props.currentState as OvertimePay)[
                rateMultiplier.checkboxFieldName
              ] as boolean,
              true,
              props.currentState,
              props.setter,
              rateMultiplier.fieldName as keyof OvertimePay,
              !(props.currentState as OvertimePay)[
                rateMultiplier.checkboxFieldName
              ] || props.isDisabled,
              'Rate Multiplier',
              1,
            )}
          </Grid>,
        ])}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    grid: {
      marginTop: theme.spacing(1),
      width: '85%',
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    checkboxLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '15px',
      textAlign: 'center',
      letterSpacing: '0.1px',
    },
  }),
);
