import { OrderBreakdown, orderBreakdownFromResponse } from './OrderBreakdown';
import {
  TripTravelMetrics,
  tripTravelMetricsFromResponse,
} from './TripTravelMetrics';
import {
  BasePayBreakdown,
  basePayBreakdownFromResponse,
} from './BasePayBreakdown';

export interface TripBreakdown {
  tripID: string;
  locationName: string;
  startAt: Date;
  endAt: Date;
  orderBreakdowns: OrderBreakdown[];
  tripTravelMetrics: TripTravelMetrics;
  basePay: number;
  basePayBreakdown: BasePayBreakdown;
  boostPay: number;
  hazardPay: number;
  tier2DeliveryPay: number;
  tobaccoPay: number;
  returnCocPay: number;
  seattleEngagedPay?: number;
  tips: number;
  totalAmount: number;
}

export const tripBreakdownFromResponse = (
  responseData: any,
): TripBreakdown => ({
  tripID: responseData.trip_id ?? '',
  locationName: responseData.location_name ?? '',
  startAt: new Date(responseData.start_at),
  endAt: new Date(responseData.end_at),
  orderBreakdowns: responseData.order_breakdowns.map((breakdown: any) =>
    orderBreakdownFromResponse(breakdown),
  ),
  tripTravelMetrics: tripTravelMetricsFromResponse(
    responseData.trip_travel_metrics ?? {},
  ),
  basePay: responseData.base_pay ?? 0,
  basePayBreakdown: basePayBreakdownFromResponse(
    responseData.base_pay_breakdown ?? {},
  ),
  boostPay: responseData.boost_pay ?? 0,
  hazardPay: responseData.hazard_pay ?? 0,
  tier2DeliveryPay: responseData.tier2_delivery_pay ?? 0,
  tobaccoPay: responseData.tobacco_pay ?? 0,
  returnCocPay: responseData.return_coc_pay ?? 0,
  seattleEngagedPay: responseData.seattle_engaged_pay,
  tips: responseData.tips ?? 0,
  totalAmount: responseData.total_amount ?? 0,
});
