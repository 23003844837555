export enum MissionRewardTierType {
  TRIP = 'trip',
  ORDER = 'order',
}

export interface MissionRewardTier {
  count: number;
  pay: number;
}

const missionRewardTierFromResponse = (
  responseData: any,
): MissionRewardTier => ({
  count: responseData.count,
  pay: (responseData.pay ?? 0) / 100,
});

export interface MissionReward {
  currency?: string;
  pay?: number;
  tierType?: MissionRewardTierType;
  tiers: MissionRewardTier[];
}

export const missionRewardFromResponse = (
  responseData: any,
): MissionReward => ({
  currency: responseData.currency,
  pay: responseData.pay !== undefined ? responseData.pay / 100 : undefined,
  tierType: responseData.tier_type,
  tiers: (responseData.tiers ?? []).map((tier: any) =>
    missionRewardTierFromResponse(tier),
  ),
});
