import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { ClassNameMap } from '@mui/styles';
import { NumberWithOperator } from '../../../interfaces/NumberWithOperator';
import { possibleOperators } from '../../../variables/Mission';
import MissionNumberInput from '../NewMission/InputForms/MissionNumberInput';

interface NumberWithOperatorInputProps {
  endAdornment?: string;
  label: string;
  numberWithOperator: NumberWithOperator;
  onChange: (newNumberWithOperator: NumberWithOperator) => void;
  onDelete?: () => void;
  isOperatorSelectionDisabled: boolean;
  classes: ClassNameMap;
}

const NumberWithOperatorInput = (props: NumberWithOperatorInputProps) => {
  const handleChangeOperator = (e: SelectChangeEvent) => {
    props.onChange({ ...props.numberWithOperator, operator: e.target.value });
  };

  const handleChangeNumber = (newNumber: number) => {
    props.onChange({ ...props.numberWithOperator, amount: newNumber });
  };

  return (
    <Box component='div' className={props.classes.body}>
      <Box component='div' className={props.classes.row}>
        <FormControl
          size='small'
          variant='outlined'
          className={props.classes.dropdown}
        >
          <Select
            value={props.numberWithOperator.operator}
            onChange={handleChangeOperator}
            disabled={props.isOperatorSelectionDisabled}
          >
            {possibleOperators.map((operator) => (
              <MenuItem value={operator}>{operator}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box component='div' className={props.classes.numberInput}>
          <MissionNumberInput
            value={props.numberWithOperator.amount}
            onChange={handleChangeNumber}
            label={props.label}
            required
            endAdornment={
              props.endAdornment ? (
                <InputAdornment position='end'>
                  {props.endAdornment}
                </InputAdornment>
              ) : undefined
            }
          />
        </Box>
        {props.onDelete && (
          <IconButton
            size='small'
            color='error'
            onClick={props.onDelete}
            className={props.classes.deleteButton}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
export default NumberWithOperatorInput;
