import { HolidayRequestToCreate, HolidayType } from '../interfaces/HolidayPay';

export const getHolidayRequestToCreateTemplate =
  (): HolidayRequestToCreate => ({
    driverID: '',
    holidayType: HolidayType.PSST,
    accruedDaysToGetPaid: 1,
    startDate: new Date(),
  });

export const getHolidayTypeName = (holidayType: HolidayType): string => {
  switch (holidayType) {
    case HolidayType.PSST:
      return 'Paid Sick and Safe Time';
    default:
      return 'Unknown';
  }
};
