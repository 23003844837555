import React, { createRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'material-react-toastify';

import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { SelectPayType } from './Steps/SelectPayType';
import { SetRules } from './Steps/SetRules';
import { ConfirmPayRate } from './Steps/ConfirmPayRate';
import {
  activeWaitPayExists as activeWaitPayExistsAtom,
  payRateToEdit as payRateToEditAtom,
} from '../../atoms/PayRate';
import {
  createBoostPays,
  createNewPayRate,
  deletePayRateById,
  expirePayRate,
  updatePayRate,
} from '../../services/payRates';
import { multiClass } from '../../utilities/Extensions';
import {
  getTimeZoneByLocationId,
  locationIdToName,
} from '../../utilities/Locations';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { newPayRateStepKinds } from '../../variables/PayRate';

import {
  BoostPay,
  NewPayRateStep,
  NewPayRateStepKind,
  PayRate,
  PayRateKind,
  PayRateStatus,
  PayRateType,
  VariableBasePayCheck,
} from '../../interfaces/PayRate';
import Waiting from '../Waiting';

import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import {
  locations as locationsAtom,
  selectedLocationIDs as selectedLocationIDsAtom,
} from '../../atoms/Location';
import { ConfirmDialog } from './ConfirmDialog';
import { Location } from '../../interfaces/Location';
import { earliestByHour, easternTimezone } from '../../utilities/Dates';
import {
  getFeatureToggleConfigWithInclusionList,
  getFeatureToggleHistoryMap,
} from '../../services/featureToggle';
import { InfoBox } from '../InfoBox';
import {
  isExpiredInclusionEntry,
  variableBasePayFeatureName,
  waitAtMfcMinimumFeatureName,
} from '../../variables/FeatureToggle';
import {
  availableWaitPayLocations as availableWaitPayLocationsAtom,
  waitAtMfcFeatureHistoryMap as waitAtMfcFeatureHistoryMapAtom,
} from '../../atoms/FeatureToggle';
import {
  getEndOfSubsidyLockTime,
  getSetRuleTitle,
  multiLocationBoostPayTransformer,
} from '../../utilities/PayRates';
import { int32Max } from '../../utilities/Constants';
import { getCurrentWorkweekInTimezone } from '../../utilities/Workweek';

type NewPayRateDialogProps = {
  categoryName: string;
  payRateTypes: PayRateType[];
  selectedLocation?: Location;
  open: boolean;
  onClose: () => void;
  onDone: () => void;
};

export const newPayRateDialogHeaderTestId = 'newPayRateDialogHeader';
export const newPayRateDialogNextButtonTestId = 'newPayRateDialogNextButton';
export const newPayRateDialogBackButtonTestId = 'newPayRateDialogBackButton';
export const newPayRateDialogDeleteButtonTestId =
  'newPayRateDialogDeleteButton';

export const NewPayRateDialog = (props: NewPayRateDialogProps) => {
  const classes = useStyles();
  const blueInfoBoxClasses = useBlueInfoBoxStyles();
  const sharedClasses = useSharedStyles().classes;

  const validatorRef = createRef<ValidatorForm>();

  const [loadingPayRates, setLoadingPayRates] = useState(false);
  const [loadingWaitAtMfcFeatureToggle, setLoadingWaitAtMfcFeatureToggle] =
    useState(false);
  const [
    loadingAvailableWaitPayLocations,
    setLoadingAvailableWaitPayLocations,
  ] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [currentStep, setCurrentStep] = useState(newPayRateStepKinds[0]);
  const [currentPayRate, setCurrentPayRate] = useState({
    ...props.payRateTypes[0].template,
    locationID: props.selectedLocation?.id.toString() || '',
  });
  const [selectedRateType, setSelectedRateType] = useState(
    props.payRateTypes[0],
  );
  const [invalidRules, setInvalidRules] = useState(false);
  const [variableBasePayLocationIDs, setVariableBasePayLocationIDs] = useState<
    string[]
  >([]);

  const [selectedLocationIDs, setSelectedLocationIDs] = useRecoilState(
    selectedLocationIDsAtom,
  );

  const activeWaitPayExists = useRecoilValue(activeWaitPayExistsAtom);
  const locations = useRecoilValue(locationsAtom);
  const selectedPage = useRecoilValue(selectedPageAtom);
  const payRateToEdit = useRecoilValue(payRateToEditAtom);
  const [waitAtMfcFeatureHistoryMap, setWaitAtMfcFeatureHistoryMap] =
    useRecoilState(waitAtMfcFeatureHistoryMapAtom);
  const setAvailableWaitPayLocations = useSetRecoilState(
    availableWaitPayLocationsAtom,
  );

  const isLastStep =
    (!selectedRateType.shouldRenderComparison &&
      currentStep === NewPayRateStepKind.SET_RULES) ||
    currentStep === NewPayRateStepKind.CONFIRM;

  const editableStatusList =
    selectedRateType.kind !== PayRateKind.BATCH_NORMALIZATION_RULES &&
    selectedRateType.kind !== PayRateKind.BOOST_PAY
      ? [PayRateStatus.FUTURE]
      : [PayRateStatus.FUTURE, PayRateStatus.ACTIVE];

  useEffect(() => {
    ValidatorForm.addValidationRule('nonZero', (value) => !!parseFloat(value));
  }, []);

  useEffect(() => {
    resetState();
  }, [selectedPage, props.selectedLocation]);

  useEffect(() => {
    if (props.open) {
      setLoadingWaitAtMfcFeatureToggle(true);
      getFeatureToggleHistoryMap(waitAtMfcMinimumFeatureName, [], true)
        .then((historyMap) => setWaitAtMfcFeatureHistoryMap(historyMap))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingWaitAtMfcFeatureToggle(false));
      setLoadingAvailableWaitPayLocations(true);
      getFeatureToggleConfigWithInclusionList(
        waitAtMfcMinimumFeatureName,
        false,
        1,
        int32Max,
        '',
      )
        .then((config) =>
          setAvailableWaitPayLocations(
            config.inclusionList
              .filter((entry) => !isExpiredInclusionEntry(entry))
              .map((entry) => entry.includedValue),
          ),
        )
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingAvailableWaitPayLocations(false));
    }
  }, [props.open, locations]);

  useEffect(() => {
    if (!payRateToEdit) {
      setCurrentPayRate({
        ...selectedRateType.template,
        locationID: props.selectedLocation?.id.toString() || '',
      });
    }
    if (
      selectedRateType.kind !== PayRateKind.BOOST_PAY &&
      selectedLocationIDs.length > 0
    ) {
      setSelectedLocationIDs([]);
    }
  }, [selectedRateType]);

  useEffect(() => {
    if (selectedRateType.kind !== PayRateKind.BOOST_PAY) {
      const newTimeZone =
        getTimeZoneByLocationId(
          locations,
          parseInt(currentPayRate.locationID, 10),
        ) || 'UTC';

      const newStartAt = activeWaitPayExists
        ? DateTime.fromJSDate(getEndOfSubsidyLockTime(newTimeZone))
            .plus({ second: 1 })
            .toJSDate()
        : earliestByHour(5, newTimeZone).toJSDate();

      setCurrentPayRate(
        payRateToEdit
          ? {
              ...payRateToEdit.payRate,
              startAt: newStartAt,
            }
          : {
              ...selectedRateType.template,
              locationID: currentPayRate.locationID,
              startAt: newStartAt,
            },
      );
      if (!payRateToEdit) {
        setInvalidRules(false);
      }
    }
  }, [currentPayRate.locationID]);

  useEffect(() => {
    let locationIDsToFetch: string[] = [];
    if (payRateToEdit) {
      locationIDsToFetch = [payRateToEdit.payRate.locationID];
    } else if (selectedRateType.kind === PayRateKind.BOOST_PAY) {
      locationIDsToFetch = [...selectedLocationIDs];
    } else if (currentPayRate.locationID !== '') {
      locationIDsToFetch = [currentPayRate.locationID];
    }

    if (locationIDsToFetch.length === 0) {
      setVariableBasePayLocationIDs([]);
      return;
    }

    const now = new Date();
    setLoadingPayRates(true);
    getFeatureToggleHistoryMap(
      variableBasePayFeatureName,
      locationIDsToFetch,
      false,
    )
      .then((featureToggleHistoryMap) => {
        setVariableBasePayLocationIDs(
          locationIDsToFetch.filter((locationID) => {
            if (!featureToggleHistoryMap[locationID]) {
              return false;
            }
            return (
              featureToggleHistoryMap[locationID].filter(
                (history) =>
                  history.startAt <= now &&
                  (!history.endAt || history.endAt >= now),
              ).length > 0
            );
          }),
        );
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingPayRates(false));
  }, [selectedRateType, currentPayRate.locationID, selectedLocationIDs]);

  const resetState = () => {
    setCurrentStep(
      payRateToEdit ? newPayRateStepKinds[1] : newPayRateStepKinds[0],
    );

    setSelectedRateType(
      payRateToEdit ? payRateToEdit.payRateType : props.payRateTypes[0],
    );

    setSelectedLocationIDs(
      props.selectedLocation ? [props.selectedLocation.id.toString()] : [],
    );

    setCurrentPayRate(
      payRateToEdit
        ? payRateToEdit.payRate
        : {
            ...props.payRateTypes[0].template,
            locationID: props.selectedLocation?.id.toString() || '',
          },
    );
    setInvalidRules(false);
  };

  useEffect(() => {
    resetState();
  }, [payRateToEdit]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidRules(!isValid);
    });
  }, [currentPayRate, selectedLocationIDs]);

  useEffect(() => {
    if (
      isEmpty(selectedLocationIDs) &&
      currentPayRate.kind === PayRateKind.BOOST_PAY &&
      !payRateToEdit
    ) {
      setCurrentPayRate({
        ...selectedRateType.template,
        locationID: props.selectedLocation?.id.toString() || '',
      });
      setInvalidRules(false);
    }
  }, [selectedLocationIDs]);

  const handleClose = () => {
    resetState();
    props.onClose();
  };

  const handleSelectRateType = (e: any) => {
    const rateKind = e.target.value as PayRateKind;
    const newSelectedRateType = props.payRateTypes.filter(
      (payRateType) => payRateType.kind === rateKind,
    )[0];
    if (newSelectedRateType) {
      setSelectedRateType(newSelectedRateType);
    }
  };

  const handleSetRules = (newPayRate: PayRate) => {
    setCurrentPayRate(newPayRate);
  };

  const handleValidationError = () => setInvalidRules(true);

  const handleNext = () => {
    if (!isLastStep) {
      setCurrentStep(newPayRateStepKinds[currentStepIndex + 1]);
    } else {
      setLoadingPayRates(true);

      if (payRateToEdit) {
        const payRateToUpdate = {
          ...currentPayRate,
          startAt:
            payRateToEdit.payRate.status === PayRateStatus.ACTIVE
              ? payRateToEdit.payRate.startAt
              : currentPayRate.startAt,
        };
        updatePayRate(
          payRateToUpdate,
          selectedRateType,
          getTimeZoneByLocationId(
            locations,
            parseInt(currentPayRate.locationID, 10),
          ),
        )
          .then(() => {
            toast.success('Successfully updated pay rate');
            resetState();
            props.onDone();
          })
          .catch((error: Error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setLoadingPayRates(false);
          });
      } else if (selectedRateType.kind === PayRateKind.BOOST_PAY) {
        const boostPays = multiLocationBoostPayTransformer(
          selectedLocationIDs,
          locations,
          currentPayRate as BoostPay,
        );
        createBoostPays(boostPays)
          .then(() => {
            toast.success(
              `Successfully created ${boostPays.length} boost pay${
                boostPays.length > 1 ? 's' : ''
              }`,
            );
            resetState();
            props.onDone();
          })
          .catch((error: Error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setLoadingPayRates(false);
          });
      } else {
        createNewPayRate(
          currentPayRate,
          selectedRateType,
          getTimeZoneByLocationId(
            locations,
            parseInt(currentPayRate.locationID, 10),
          ),
        )
          .then(() => {
            toast.success('Successfully created new pay rate');
            resetState();
            props.onDone();
          })
          .catch((error: Error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setLoadingPayRates(false);
          });
      }
    }
  };

  const renderWaitAtMfcMinGuaranteeWarning = (): JSX.Element => {
    if (
      currentStepIndex === 0 ||
      selectedRateType.kind !== PayRateKind.SUBSIDY ||
      !Object.keys(waitAtMfcFeatureHistoryMap).includes(
        currentPayRate.locationID,
      )
    ) {
      return <></>;
    }

    return (
      <InfoBox
        title='Wait-at-Facility Minimum Guarantee is enabled for this location'
        message={<></>}
        classes={blueInfoBoxClasses}
        severity='info'
      />
    );
  };

  const renderAlcoholPayWarning = (): JSX.Element => {
    if (
      currentStepIndex === 0 ||
      selectedRateType.kind !== PayRateKind.ALCOHOL_PAY
    ) {
      return <></>;
    }

    return (
      <InfoBox
        title='Alcohol Pay is only applicable to those sites having DPP enabled'
        message={<></>}
        classes={blueInfoBoxClasses}
        severity='info'
      />
    );
  };

  const renderVariableBasePayWarning = (): JSX.Element => {
    if (
      currentStepIndex === 0 ||
      selectedRateType.variableBasePayCheck === VariableBasePayCheck.NONE ||
      variableBasePayLocationIDs.length === 0
    ) {
      return <></>;
    }

    let title = 'This location is currently using Variable Base Pay';
    if (
      selectedRateType.kind === PayRateKind.BOOST_PAY &&
      !payRateToEdit &&
      selectedLocationIDs.length > 1
    ) {
      const locationNames = variableBasePayLocationIDs.map((locationID) =>
        locationIdToName(locations, parseInt(locationID, 10)),
      );
      title = '';
      locationNames.forEach((locationName, i) => {
        if (i > 0 && i === locationNames.length - 1) {
          title += ' and ';
        } else if (i > 0) {
          title += ', ';
        }
        title += locationName;
      });
      title += ` ${
        locationNames.length > 1 ? 'are' : 'is'
      } currently using Variable Base Pay`;
    }

    if (selectedRateType.variableBasePayCheck === VariableBasePayCheck.INFO) {
      return (
        <InfoBox
          title={title}
          message={<></>}
          classes={blueInfoBoxClasses}
          severity='info'
        />
      );
    }

    return (
      <InfoBox
        title={title}
        message={
          <Box>
            Pay Rates set in this tool are only in affect when Variable Base Pay
            is not being used
          </Box>
        }
        classes={classes}
        severity='info'
      />
    );
  };

  const handleDelete = () => {
    handleConfirmDialogClose();
    if (!payRateToEdit) {
      return;
    }

    if (payRateToEdit.payRate.status === PayRateStatus.FUTURE) {
      setLoadingPayRates(true);
      deletePayRateById(payRateToEdit.payRate.id, payRateToEdit.payRateType)
        .then(() => {
          props.onDone();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoadingPayRates(false);
        });
    } else {
      let expireAt = DateTime.now().plus({ minute: 1 }).toJSDate();
      if (payRateToEdit.payRateType.kind === PayRateKind.WEEKLY_BONUS) {
        const timezone = getTimeZoneByLocationId(
          locations,
          parseInt(payRateToEdit.payRate.locationID, 10),
        );
        const currentWorkweek = getCurrentWorkweekInTimezone(
          timezone ?? easternTimezone,
        );
        expireAt = DateTime.fromJSDate(currentWorkweek)
          .plus({ day: 7, millisecond: -1 })
          .toJSDate();
      }

      setLoadingPayRates(true);
      expirePayRate(
        payRateToEdit.payRate,
        expireAt,
        payRateToEdit.payRateType,
        getTimeZoneByLocationId(
          locations,
          parseInt(payRateToEdit.payRate.locationID, 10),
        ),
      )
        .then(() => {
          props.onDone();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoadingPayRates(false);
        });
    }
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmDeleteDialog(false);
  };

  const handleConfirmDialogOpen = () => {
    setShowConfirmDeleteDialog(true);
  };

  const handleBack = () => {
    setCurrentStep(newPayRateStepKinds[currentStepIndex - 1]);
  };

  const currentStepIndex = newPayRateStepKinds.indexOf(currentStep);

  const steps: NewPayRateStep[] = [
    {
      kind: NewPayRateStepKind.SELECT_RATE_TYPE,
      title: `Add New ${props.categoryName}`,
      label: `${props.categoryName} Type`.toUpperCase(),
      content: (
        <SelectPayType
          handleSelectRateType={handleSelectRateType}
          payRateTypes={props.payRateTypes}
          selectedRateType={selectedRateType}
        />
      ),
    },
    {
      kind: NewPayRateStepKind.SET_RULES,
      title: getSetRuleTitle(
        payRateToEdit,
        selectedRateType,
        editableStatusList,
      ),
      label: 'SET RULES',
      content: (
        <SetRules
          currentState={currentPayRate}
          setter={handleSetRules}
          selectedRateType={selectedRateType}
          isDisabled={!editableStatusList.includes(currentPayRate.status)}
        />
      ),
    },
    {
      kind: NewPayRateStepKind.CONFIRM,
      title:
        payRateToEdit?.payRate.status === PayRateStatus.FUTURE
          ? `Confirm Future ${selectedRateType.name} Changes`
          : `Confirm New ${selectedRateType.name}`,
      label: 'CONFIRM',
      content: (
        <ConfirmPayRate
          payRate={currentPayRate}
          selectedRateType={selectedRateType}
        />
      ),
    },
  ];

  const loading =
    loadingPayRates ||
    loadingWaitAtMfcFeatureToggle ||
    loadingAvailableWaitPayLocations;

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth='md'>
      <div style={{ width: 700 }}>
        <Waiting open={loading} />
        <ValidatorForm
          instantValidate={true}
          onSubmit={handleNext}
          onError={handleValidationError}
          ref={validatorRef}
        >
          <DialogTitle
            className={multiClass([sharedClasses.h6, classes.title])}
            data-testid={newPayRateDialogHeaderTestId}
          >
            {steps[currentStepIndex].title}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box component='div' className={classes.dialogContent}>
              {renderVariableBasePayWarning()}
              {renderWaitAtMfcMinGuaranteeWarning()}
              {renderAlcoholPayWarning()}
              {steps[currentStepIndex].content}
            </Box>
          </DialogContent>
          <DialogActions>
            {payRateToEdit?.payRate.status === PayRateStatus.FUTURE ||
            (payRateToEdit?.payRate.status === PayRateStatus.ACTIVE &&
              payRateToEdit?.payRateType.isInstantExpireAllowed) ? (
              <Button
                variant='contained'
                color={'error'}
                onClick={handleConfirmDialogOpen}
                className={multiClass([
                  sharedClasses.buttonText,
                  classes.deleteButton,
                ])}
                data-testid={newPayRateDialogDeleteButtonTestId}
              >
                {payRateToEdit.payRate.status === PayRateStatus.ACTIVE
                  ? 'Expire'
                  : 'Delete'}
              </Button>
            ) : (
              <></>
            )}
            <Box component='div' className={classes.actionButtonDivider} />
            <Button
              id='back-cancel-button'
              variant='contained'
              color={'inherit'}
              onClick={
                (!payRateToEdit && currentStepIndex > 0) || currentStepIndex > 1
                  ? handleBack
                  : handleClose
              }
              className={sharedClasses.buttonText}
              data-testid={newPayRateDialogBackButtonTestId}
            >
              {(!payRateToEdit && currentStepIndex > 0) || currentStepIndex > 1
                ? 'Back'
                : 'Cancel'}
            </Button>
            <Button
              id='save-continue-button'
              variant='contained'
              color='primary'
              disabled={
                (currentStep === NewPayRateStepKind.SET_RULES &&
                  ((!currentPayRate.locationID &&
                    isEmpty(selectedLocationIDs)) ||
                    invalidRules)) ||
                !editableStatusList.includes(currentPayRate.status)
              }
              type='submit'
              className={sharedClasses.buttonText}
              data-testid={newPayRateDialogNextButtonTestId}
            >
              {isLastStep ? 'Save' : 'Continue'}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </div>
      <ConfirmDialog
        onClose={handleConfirmDialogClose}
        onConfirm={handleDelete}
        open={showConfirmDeleteDialog}
        cancelText='Cancel'
        okText={
          payRateToEdit?.payRate.status === PayRateStatus.ACTIVE
            ? 'Expire'
            : 'Delete'
        }
        content={`Do you really want to ${
          payRateToEdit?.payRate.status === PayRateStatus.ACTIVE
            ? 'expire'
            : 'delete'
        } this pay rate config?`}
        title='Are you sure?'
        color='error'
      />
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: sharedColors.gray6,
    },
    dialogContent: {
      flexDirection: 'column',
    },
    actionButtonDivider: {
      flex: '1 0 0',
    },
    deleteButton: {
      color: sharedColors.statusRed,
      backgroundColor: sharedColors.statusRedLightest,
    },
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      backgroundColor: sharedColors.statusYellowLightest,
    },
    infoBoxIcon: {
      color: sharedColors.statusYellow,
    },
    infoBoxTextWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.7),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.statusYellowDark,
    },
    infoBoxMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '18px',
      color: sharedColors.statusYellowDark,
      marginTop: theme.spacing(1),
    },
  }),
);

const useBlueInfoBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      backgroundColor: sharedColors.blue1,
    },
    infoBoxIcon: {
      color: sharedColors.blue6,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    infoBoxTextWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.7),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.blue6,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    infoBoxMessage: {},
  }),
);
