import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import {
  Mission,
  MissionKind,
  OutputTransformerFn,
} from '../interfaces/Mission/Mission';
import { convertLocalToDeliveryZone, getNextWorkweekStart } from './Dates';
import { getEmptyAudit } from './Audit';
import {
  GeographicMissionRequirement,
  MissionRequirements,
} from '../interfaces/Mission/MissionRequirements';
import { Location, Metro } from '../interfaces/Location';
import { filterLocationById, filterMetroById } from './Locations';
import { MissionFilters } from '../interfaces/Mission/MissionFilters';
import { QueryParameter } from '../interfaces/Url';
import { getDefaultFilters } from '../variables/Mission';

export const getMissionTemplate = (): Mission => ({
  id: 0,
  title: '',
  type: MissionKind.PER_TRIP_BOOST,
  isExperiment: false,
  countryCode: 'US',
  reward: {
    tiers: [],
    pay: 0,
  },
  startAtLocal: getNextWorkweekStart(),
  startAtOverall: getNextWorkweekStart(),
  endAtLocal: DateTime.fromJSDate(getNextWorkweekStart())
    .plus({ hour: 1 })
    .toJSDate(),
  endAtOverall: DateTime.fromJSDate(getNextWorkweekStart())
    .plus({ hour: 1 })
    .toJSDate(),
  requirements: {
    locations: [],
    metros: [],
    ordersMustContain: [],
    metrics: {},
  },
  expectedCosts: 0,
  actualCosts: 0,
  audit: getEmptyAudit(),
});

export const defaultMissionOutputTransformer: OutputTransformerFn = (
  currentState: Mission,
) => {
  const startAtLocal = DateTime.fromJSDate(currentState.startAtLocal);
  const endAtLocal = currentState.endAtLocal
    ? DateTime.fromJSDate(currentState.endAtLocal)
    : undefined;

  return {
    title: currentState.title,
    description: currentState.description,
    start_at_local: convertLocalToDeliveryZone(
      'UTC',
      startAtLocal,
      startAtLocal.hour,
      startAtLocal.minute,
    ).toISO(),
    end_at_local: endAtLocal
      ? convertLocalToDeliveryZone(
          'UTC',
          endAtLocal,
          endAtLocal.hour,
          endAtLocal.minute,
        ).toISO()
      : undefined,
    max_driver_count: currentState.maxDriverCount,
    requirements: {
      country_code: currentState.requirements.country,
      metro_ids: currentState.requirements.metros.map((metro) => metro.id),
      location_ids: currentState.requirements.locations.map(
        (location) => location.id,
      ),
      orders_must_contain: isEmpty(currentState.requirements.ordersMustContain)
        ? undefined
        : currentState.requirements.ordersMustContain,
    },
    idempotency_key: currentState.idempotencyKey,
  };
};

export const getGeographicRequirementValue = (
  geographicRequirements: GeographicMissionRequirement[],
  shorten: boolean,
  countryCode?: string,
): string | undefined => {
  if (isEmpty(geographicRequirements)) {
    if (countryCode?.toUpperCase() === 'US') {
      return 'All US';
    }
    if (countryCode?.toUpperCase() === 'GB') {
      return 'All UK';
    }
    return undefined;
  }
  if (shorten) {
    const value =
      geographicRequirements[0].name ?? geographicRequirements[0].id;
    return geographicRequirements.length > 1
      ? `${value} + ${(geographicRequirements.length - 1).toString()}`
      : value;
  }
  return geographicRequirements
    .map(
      (geographicRequirement) =>
        geographicRequirement.name ?? geographicRequirement.id,
    )
    .join(', ');
};

export const getCurrencyFromMissionRequirements = (
  requirements: MissionRequirements,
  locations: Location[],
  metros: Metro[],
): string => {
  if (requirements.country === 'GB') {
    return 'GBP';
  }

  if (!isEmpty(requirements.locations)) {
    return (
      filterLocationById(locations, parseInt(requirements.locations[0].id, 10))
        ?.currency ?? 'USD'
    );
  }

  if (!isEmpty(requirements.metros)) {
    return filterMetroById(metros, requirements.metros[0].id)?.countryCode ===
      'GB'
      ? 'GBP'
      : 'USD';
  }

  return 'USD';
};

export const getMissionFilterQueryParams = (
  filters: MissionFilters,
): QueryParameter[] => {
  const defaultFilters = getDefaultFilters();
  const params: QueryParameter[] = [];

  if (filters.activeAfter) {
    params.push({
      key: 'active-after',
      value: JSON.stringify(filters.activeAfter),
    });
  }

  if (filters.activeBefore) {
    params.push({
      key: 'active-before',
      value: JSON.stringify(filters.activeBefore),
    });
  }

  if (filters.driverID) {
    params.push({
      key: 'driver-id',
      value: JSON.stringify(filters.driverID),
    });
  }

  if (!isEmpty(filters.locationIDs)) {
    params.push({
      key: 'location-ids',
      value: JSON.stringify(filters.locationIDs),
    });
  }

  if (!isEmpty(filters.metroIDs)) {
    params.push({
      key: 'metro-ids',
      value: JSON.stringify(filters.metroIDs),
    });
  }

  if (!isEmpty(filters.statuses)) {
    params.push({
      key: 'statuses',
      value: JSON.stringify(filters.statuses),
    });
  }

  if (!isEmpty(filters.types)) {
    params.push({
      key: 'types',
      value: JSON.stringify(filters.types),
    });
  }

  if (filters.onlyAllowingActiveDP) {
    params.push({
      key: 'only-allowing-active-dp',
      value: JSON.stringify(true),
    });
  }

  if (filters.onlyExperiments) {
    params.push({
      key: 'only-experiments',
      value: JSON.stringify(true),
    });
  }

  if (filters.pagination.page !== defaultFilters.pagination.page) {
    params.push({
      key: 'page',
      value: filters.pagination.page.toString(),
    });
  }

  if (
    filters.pagination.rowsPerPage !== defaultFilters.pagination.rowsPerPage
  ) {
    params.push({
      key: 'page-size',
      value: filters.pagination.rowsPerPage.toString(),
    });
  }

  return params;
};

export const getMissionFiltersFromQueryParams = (
  params: URLSearchParams,
): MissionFilters => {
  const filters = getDefaultFilters();

  const activeAfterParam = params.get('active-after');
  if (activeAfterParam) {
    filters.activeAfter = new Date(
      JSON.parse(decodeURIComponent(activeAfterParam)),
    );
  }

  const activeBeforeParam = params.get('active-before');
  if (activeBeforeParam) {
    filters.activeBefore = new Date(
      JSON.parse(decodeURIComponent(activeBeforeParam)),
    );
  }

  const driverIDParam = params.get('driver-id');
  if (driverIDParam) {
    filters.driverID = JSON.parse(decodeURIComponent(driverIDParam));
  }

  const locationIDsParam = params.get('location-ids');
  if (locationIDsParam) {
    filters.locationIDs = JSON.parse(decodeURIComponent(locationIDsParam));
  }

  const metroIDsParam = params.get('metro-ids');
  if (metroIDsParam) {
    filters.metroIDs = JSON.parse(decodeURIComponent(metroIDsParam));
  }

  const statusesParam = params.get('statuses');
  if (statusesParam) {
    filters.statuses = JSON.parse(decodeURIComponent(statusesParam));
  }

  const typesParam = params.get('types');
  if (typesParam) {
    filters.types = JSON.parse(decodeURIComponent(typesParam));
  }

  const onlyAllowingActiveDPParam = params.get('only-allowing-active-dp');
  if (onlyAllowingActiveDPParam) {
    filters.onlyAllowingActiveDP = JSON.parse(
      decodeURIComponent(onlyAllowingActiveDPParam),
    );
  }

  const onlyExperimentsParam = params.get('only-experiments');
  if (onlyExperimentsParam) {
    filters.onlyExperiments = JSON.parse(
      decodeURIComponent(onlyExperimentsParam),
    );
  }

  const pageParam = params.get('page');
  if (pageParam) {
    filters.pagination.page = parseInt(decodeURIComponent(pageParam), 10);
  }

  const rowsPerPageParam = params.get('page-size');
  if (rowsPerPageParam) {
    filters.pagination.rowsPerPage = parseInt(
      decodeURIComponent(rowsPerPageParam),
      10,
    );
  }

  return filters;
};

export const requirementsIncludeCountry = (
  locations: Location[],
  metros: Metro[],
  requirements: MissionRequirements,
  countryCode: string,
): boolean => {
  if (requirements.country?.toUpperCase() === countryCode.toUpperCase()) {
    return true;
  }

  const selectedLocationCountries = requirements.locations.map((location) =>
    filterLocationById(
      locations,
      parseInt(location.id, 10),
    )?.countryCode?.toUpperCase(),
  );

  const selectedMetroCountries = requirements.metros.map((metro) =>
    filterMetroById(metros, metro.id)?.countryCode?.toUpperCase(),
  );

  return [...selectedLocationCountries, ...selectedMetroCountries].includes(
    countryCode.toUpperCase(),
  );
};
