import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';

interface BlockingDriverIDsDialogProps {
  driverIDs: string[];
  onClose: () => void;
}

const BlockingDriverIDsDialog = (props: BlockingDriverIDsDialogProps) => {
  const sharedClasses = useSharedStyles().classes;
  const { classes } = useStyles();

  return (
    <Dialog open={!isEmpty(props.driverIDs)} onClose={props.onClose}>
      <DialogTitle>Push Notification Sending Failed</DialogTitle>
      <DialogContent>
        <Typography
          className={multiClass([sharedClasses.body1, classes.description])}
        >
          Push notification sending is blocked due to following DP(s) receiving
          a push notification too recently
        </Typography>
        <Paper className={classes.paperBody}>
          <Typography className={classes.errorText}>
            {props.driverIDs.join(', ')}
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='contained'
          color='inherit'
          className={sharedClasses.buttonText}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  paperBody: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: sharedColors.statusRedLightest,
  },
  description: {},
  errorText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: sharedColors.black,
  },
}));

export default BlockingDriverIDsDialog;
