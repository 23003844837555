import {
  TripFareConstants,
  TripFareLimits,
  TripFareLimitsValidationErrorMessages,
  TripFareLimitsValidations,
} from '../interfaces/TripFareLimits';
import { preciseCentsToAmount } from '../utilities/AmountWithPrecision';

export const tripFareLimitsInputParser = (
  responseData: any,
): TripFareLimits => ({
  id: responseData.id,
  firstOrderMinFare: responseData.first_order_min_fare,
  subsequentOrderMinFare: responseData.subsequent_order_min_fare,
  firstOrderMaxFare: responseData.first_order_max_fare,
  subsequentOrderMaxFare: responseData.subsequent_order_max_fare,
  locationID: responseData.location_id,
  startAt: new Date(responseData.start_at),
  endAt: responseData.end_at ? new Date(responseData.end_at) : undefined,
  lastUpdatedBy: responseData.last_updated_by,
});

export const tripFareLimitsOutputTransformer = (
  tripFareLimits: TripFareLimits,
) => ({
  first_order_min_fare: Math.floor(tripFareLimits.firstOrderMinFare),
  subsequent_order_min_fare: Math.floor(tripFareLimits.subsequentOrderMinFare),
  first_order_max_fare: Math.floor(tripFareLimits.firstOrderMaxFare),
  subsequent_order_max_fare: Math.floor(tripFareLimits.subsequentOrderMaxFare),
  location_id: tripFareLimits.locationID,
});

export const tripFareConstantsInputParser = (
  responseData: any,
): TripFareConstants => ({
  defaultFirstOrderMinFare: responseData.default_first_order_min_fare,
  defaultSubsequentOrderMinFare: responseData.default_subsequent_order_min_fare,
  defaultFirstOrderMaxFare: responseData.default_first_order_max_fare,
  defaultSubsequentOrderMaxFare: responseData.default_subsequent_order_max_fare,
  minAllowedFirstOrderMinFare: responseData.min_allowed_first_order_min_fare,
  maxAllowedFirstOrderMinFare: responseData.max_allowed_first_order_min_fare,
  minAllowedSubsequentOrderMinFare:
    responseData.min_allowed_subsequent_order_min_fare,
  maxAllowedSubsequentOrderMinFare:
    responseData.max_allowed_subsequent_order_min_fare,
  minAllowedFirstOrderMaxFare: responseData.min_allowed_first_order_max_fare,
  maxAllowedFirstOrderMaxFare: responseData.max_allowed_first_order_max_fare,
  minAllowedSubsequentOrderMaxFare:
    responseData.min_allowed_subsequent_order_max_fare,
  maxAllowedSubsequentOrderMaxFare:
    responseData.max_allowed_subsequent_order_max_fare,
});

export const getTripFareLimitsValidations = (
  constants: TripFareConstants,
): TripFareLimitsValidations => ({
  firstOrderMinFare: (value) => {
    const parsedAmount = parseFloat(value);

    return (
      100 * parsedAmount <= constants.maxAllowedFirstOrderMinFare &&
      100 * parsedAmount >= constants.minAllowedFirstOrderMinFare
    );
  },
  subsequentOrderMinFare: (value) => {
    const parsedAmount = parseFloat(value);

    return (
      100 * parsedAmount <= constants.maxAllowedSubsequentOrderMinFare &&
      100 * parsedAmount >= constants.minAllowedSubsequentOrderMinFare
    );
  },
  firstOrderMaxFare: (value) => {
    const parsedAmount = parseFloat(value);

    return (
      100 * parsedAmount <= constants.maxAllowedFirstOrderMaxFare &&
      100 * parsedAmount >= constants.minAllowedFirstOrderMaxFare
    );
  },
  subsequentOrderMaxFare: (value) => {
    const parsedAmount = parseFloat(value);

    return (
      100 * parsedAmount <= constants.maxAllowedSubsequentOrderMaxFare &&
      100 * parsedAmount >= constants.minAllowedSubsequentOrderMaxFare
    );
  },

  id: (_) => true,
  locationID: (_) => true,
  startAt: (_) => true,
  endAt: (_) => true,
  lastUpdatedBy: (_) => true,
});

export const getTripFareLimitsValidationErrorMessages = (
  constants: TripFareConstants,
): TripFareLimitsValidationErrorMessages => ({
  firstOrderMinFare: `Value must be between ${preciseCentsToAmount(
    constants.minAllowedFirstOrderMinFare,
    2,
  ).toFixed(2)} and ${preciseCentsToAmount(
    constants.maxAllowedFirstOrderMinFare,
    2,
  ).toFixed(2)}`,
  subsequentOrderMinFare: `Value must be between ${preciseCentsToAmount(
    constants.minAllowedSubsequentOrderMinFare,
    2,
  ).toFixed(2)} and ${preciseCentsToAmount(
    constants.maxAllowedSubsequentOrderMinFare,
    2,
  ).toFixed(2)}`,
  firstOrderMaxFare: `Value must be between ${preciseCentsToAmount(
    constants.minAllowedFirstOrderMaxFare,
    2,
  ).toFixed(2)} and ${preciseCentsToAmount(
    constants.maxAllowedFirstOrderMaxFare,
    2,
  ).toFixed(2)}`,
  subsequentOrderMaxFare: `Value must be between ${preciseCentsToAmount(
    constants.minAllowedSubsequentOrderMaxFare,
    2,
  ).toFixed(2)} and ${preciseCentsToAmount(
    constants.maxAllowedSubsequentOrderMaxFare,
    2,
  ).toFixed(2)}`,

  id: '',
  locationID: '',
  startAt: '',
  endAt: '',
  lastUpdatedBy: '',
});
