export interface EngagedSubsidyBreakdown {
  locationName: string;
  engagedHours: number;
  subsidyRate: number;
  basePay: number;
  specialPay: number;
  weeklyBonusPay: number;
  amount: number;
}

export const engagedSubsidyBreakdownFromResponse = (
  responseData: any,
): EngagedSubsidyBreakdown => ({
  locationName: responseData.location_name ?? '',
  engagedHours: responseData.engaged_hours ?? 0,
  subsidyRate: responseData.subsidy_rate ?? 0,
  basePay: responseData.base_pay ?? 0,
  specialPay: responseData.special_pay ?? 0,
  weeklyBonusPay: responseData.weekly_bonus_pay ?? 0,
  amount: responseData.amount ?? 0,
});
