import { isEmpty } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Location } from '../interfaces/Location';
import { compareByCity, filterLocationById } from '../utilities/Locations';

interface MultiLocationAutocompleteProps {
  label: string;
  pinnedLocations?: Location[];
  locations: Location[];
  selectedIDs: string[];
  onSetSelectedIDs: (ids: string[]) => void;
  disabled?: boolean;
  error?: boolean;
  width?: number;
}

export const multiLocationChipLimit = 1;
export const multiLocationAutocompleteTestId = 'multiLocationAutocomplete';

const MultiLocationAutocomplete = (props: MultiLocationAutocompleteProps) => {
  const handleSelect = (_: any, selectedLocations: Location[]) => {
    if (isEmpty(selectedLocations)) {
      props.onSetSelectedIDs([]);
      return;
    }
    props.onSetSelectedIDs(
      selectedLocations.map((location) => location.id.toString()),
    );
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={multiLocationChipLimit}
      size={'small'}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant='outlined'
          sx={{ width: props.width ?? 350 }}
          data-testid={multiLocationAutocompleteTestId}
          error={props.error}
        />
      )}
      options={[
        ...(props.pinnedLocations ?? []),
        ...[...props.locations].sort(compareByCity),
      ]}
      groupBy={(location) => location.city}
      getOptionLabel={(location) => location.name}
      onChange={handleSelect}
      value={
        props.selectedIDs
          .map((id) =>
            filterLocationById(
              [...(props.pinnedLocations ?? []), ...props.locations],
              parseInt(id, 10),
            ),
          )
          .filter((location) => !!location) as Location[]
      }
      disabled={props.disabled}
    />
  );
};

export default MultiLocationAutocomplete;
