import { PayRateSchedule } from '../interfaces/PayRate';
import { Location } from '../interfaces/Location';
import api from './api';
import { waitPayRateType } from '../variables/PayRate';
import { filterLocationById } from '../utilities/Locations';

const payRateSchedulesUrl = '/pay-rate-schedules';

export const createBatchPayRateSchedules = async (
  payRateSchedules: PayRateSchedule[],
  locations: Location[],
): Promise<void> =>
  api.post(`${payRateSchedulesUrl}/batch`, {
    pay_rate_schedules: payRateSchedules.map((schedule) =>
      waitPayRateType.outputTransformer(
        schedule,
        filterLocationById(locations, parseInt(schedule.locationID, 10))!
          .timeZone,
        false,
      ),
    ),
  });
