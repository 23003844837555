import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { RestrictedAccount } from '../../interfaces/RestrictedAccount';

interface ConfirmEnableStripeDialogProps {
  open: boolean;
  account: RestrictedAccount | null;
  onClose: () => void;
  onConfirm: () => void;
}

export const confirmEnableStripeDialogTestId = 'confirmEnableStripeDialog';

const ConfirmEnableStripeDialog = (props: ConfirmEnableStripeDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      data-testid={confirmEnableStripeDialogTestId}
    >
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Enable Stripe Account
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography className={classes.messageText}>
            Are you sure you want to enable the Stripe account for Delivery
            Partner
            <Box
              className={classes.boldMessageText}
            >{`${props.account?.id} (${props.account?.Name})`}</Box>
            and remove them from the list?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          onClick={props.onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  messageText: {
    fontSize: 14,
    color: sharedColors.gray7,
  },
  boldMessageText: {
    fontWeight: 'bold',
    display: 'inline',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

export default ConfirmEnableStripeDialog;
