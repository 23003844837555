export interface TripTravelMetrics {
  estimatedTravelTimeInMinutes: number;
  estimatedTravelDistanceInMiles: number;
  estimatedTravelDistanceInKilometers: number;
  engagedTravelTimeInMinutes: number;
  engagedTravelDistanceInMiles: number;
  engagedTravelDistanceInKilometers: number;
  showDistanceInMiles: boolean;
}

export const tripTravelMetricsFromResponse = (
  responseData: any,
): TripTravelMetrics => ({
  estimatedTravelTimeInMinutes:
    responseData.estimated_travel_time_in_minutes ?? 0,
  estimatedTravelDistanceInMiles:
    responseData.estimated_travel_distance_in_miles ?? 0,
  estimatedTravelDistanceInKilometers:
    responseData.estimated_travel_distance_in_kilometers ?? 0,
  engagedTravelTimeInMinutes: responseData.engaged_travel_time_in_minutes ?? 0,
  engagedTravelDistanceInMiles:
    responseData.engaged_travel_distance_in_miles ?? 0,
  engagedTravelDistanceInKilometers:
    responseData.engaged_travel_distance_in_kilometers ?? 0,
  showDistanceInMiles: responseData.show_distance_in_miles !== false,
});
