import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Theme,
  Typography,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import UploadIcon from '@mui/icons-material/Upload';
import { toast } from 'material-react-toastify';

import { useRecoilState } from 'recoil';
import { flatten, isEmpty } from 'lodash';

import { useLocation } from 'react-router-dom';
import { NewPayRateDialog } from '../NewPayRateDialog/NewPayRateDialog';
import PayRateTable from './PayRateTable';
import Waiting from '../Waiting';

import {
  getActivePayRatesAndLocationIds,
  getPayRateByLocationId,
} from '../../services/payRates';

import { multiClass } from '../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { getPagesListByPermission } from '../../variables/Pages';

import {
  filteredLocationIDs as filteredLocationIDsAtom,
  locations as locationsAtom,
} from '../../atoms/Location';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { payRateToEdit as payRateToEditAtom } from '../../atoms/PayRate';
import { permissions as permissionsAtom } from '../../atoms/Users';

import {
  PayRate,
  PayRateKind,
  PayRateStatus,
  PayRateType,
} from '../../interfaces/PayRate';

import { getLocations } from '../../services/locations';
import { PageType } from '../../interfaces/Page';
import { getUserPermissions, isPermitted } from '../../variables/Users';
import { filterLocationById } from '../../utilities/Locations';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';
import { VariableBasePayHistoryTable } from './VariableBasePay/VariableBasePayHistoryTable';
import {
  getPayRateFilterQueryParams,
  listedPayRateComparator,
  variableBasePayTableRequiredForPayRateKind,
} from '../../utilities/PayRates';
import TripFareLimitsTable from './VariableBasePay/TripFareLimitsTable';
import { Permission } from '../../interfaces/Users';
import UploadWaitPayRatesDialog from './UploadWaitPayRatesDialog';

export const newPayRateButtonTestId = 'newPayRateButton';
export const payRatesHeaderTestId = 'payRatesHeader';
export const payRateTypeSelectTestId = 'payRateTypeSelect';
type PayRatesProps = {
  categoryName: string;
  payRateTypes: PayRateType[];
  title: string;
  pageType: PageType;
};

export const PayRates = (props: PayRatesProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const location = useLocation();

  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const [locations, setLocations] = useRecoilState(locationsAtom);
  const [payRateToEdit, setPayRateToEdit] = useRecoilState(payRateToEditAtom);
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);

  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingPayRates, setLoadingPayRates] = useState(false);
  const [payRates, setPayRates] = useState<PayRate[]>([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [activePayRatesChecked, setActivePayRatesChecked] = useState(false);
  const [fareLimitsExists, setFareLimitsExists] = useState(false);

  const [filteredLocationIDs, setFilteredLocationIDs] = useRecoilState(
    filteredLocationIDsAtom,
  );
  const [selectedPayRateKind, setSelectedPayRateKind] = useState<PayRateKind>(
    PayRateKind.ALL_KINDS,
  );

  const selectedAnyLocation =
    !isEmpty(filteredLocationIDs) || activePayRatesChecked;

  const refreshPayRates = async () => {
    setLoadingPayRates(true);
    let newPayRates: PayRate[] = [];

    if (selectedAnyLocation) {
      try {
        newPayRates = flatten(
          await Promise.all(
            props.payRateTypes.map(async (payRateType) =>
              getPayRateByLocationId(filteredLocationIDs, payRateType),
            ),
          ),
        );
      } catch (error: any) {
        toast.error(`Error while fetching pay rates: ${error.message}`);
        setLoadingPayRates(false);
      }
    }
    setPayRates(newPayRates);
    setLoadingPayRates(false);
  };

  const refreshLocations = () => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));
  };

  const getActivePayRates = async () => {
    setLoadingPayRates(true);
    const newPayRates: PayRate[] = [];

    if (activePayRatesChecked) {
      try {
        await Promise.allSettled(
          props.payRateTypes.map(async (payRateType) =>
            getActivePayRatesAndLocationIds(payRateType),
          ),
        ).then((results) =>
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              newPayRates.push(...result.value.rates);
            } else {
              throw new Error(result.reason);
            }
          }),
        );
      } catch (error: any) {
        toast.error(`Error while fetching pay rates: ${error.message}`);
        setLoadingPayRates(false);
        setActivePayRatesChecked(false);
      }
    }

    setPayRates(newPayRates);
    setFilteredLocationIDs([]);
    setLoadingPayRates(false);
  };

  const setFiltersFromQueryParams = () => {
    const params = new URLSearchParams(location.search);

    const allActiveParam = params.get('all-active');
    const locationIDsParam = params.get('location-ids');
    if (allActiveParam && allActiveParam === '1') {
      setActivePayRatesChecked(true);
    } else if (locationIDsParam) {
      setFilteredLocationIDs(locationIDsParam.split(','));
    }

    const payRateKindParam = params.get('pay-rate-kind');
    if (payRateKindParam) {
      setSelectedPayRateKind(payRateKindParam as PayRateKind);
    }
  };

  useEffect(() => {
    refreshLocations();
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === props.pageType) {
        index = i;
        break;
      }
    }
    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  useEffect(() => {
    if (!activePayRatesChecked) {
      refreshPayRates();
    }
  }, [filteredLocationIDs, locations]);

  useEffect(() => {
    setFiltersFromQueryParams();
  }, [locations]);

  useEffect(() => {
    setFilteredLocationIDs([]);
    setSelectedPayRateKind(PayRateKind.ALL_KINDS);
  }, [selectedPage]);

  useEffect(() => {
    getActivePayRates();
  }, [activePayRatesChecked]);

  const updateQueryParams = (
    newFilteredLocationIDs: string[],
    newSelectedPayRateKind: PayRateKind,
    newActivePayRatesChecked: boolean,
  ) => {
    const queryParams = getPayRateFilterQueryParams(
      newFilteredLocationIDs,
      newSelectedPayRateKind,
      newActivePayRatesChecked,
    );

    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    if (!isEmpty(queryParams)) {
      newUrl += `?${queryParams
        .map((param) => `${param.key}=${param.value}`)
        .join('&')}`;
    }
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const handleNewPayRateButton = () => {
    setShowCreateDialog(!showCreateDialog);
  };

  const handleActivePayRatesSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateQueryParams(
      filteredLocationIDs,
      selectedPayRateKind,
      event.target.checked,
    );
    setActivePayRatesChecked(event.target.checked);
  };

  const handleCloseDialog = () => {
    setShowCreateDialog(false);
    setPayRateToEdit(null);
  };

  const handleCreatePayRate = () => {
    setShowCreateDialog(false);
    setPayRateToEdit(null);
    refreshPayRates();
  };

  const handleSelectLocations = (newSelectedLocationIDs: string[]) => {
    updateQueryParams(
      newSelectedLocationIDs,
      selectedPayRateKind,
      activePayRatesChecked,
    );
    setFilteredLocationIDs(newSelectedLocationIDs);
  };

  const handleSelectPayRate = (event: SelectChangeEvent) => {
    const newPayRateKind = event.target.value as PayRateKind;
    updateQueryParams(
      filteredLocationIDs,
      newPayRateKind,
      activePayRatesChecked,
    );
    setSelectedPayRateKind(newPayRateKind);
  };

  let filteredPayRates = payRates;
  if (selectedAnyLocation) {
    filteredPayRates = filteredPayRates.filter(
      (payRate) =>
        filteredLocationIDs.includes(payRate.locationID) ||
        activePayRatesChecked,
    );
    if (selectedPayRateKind !== PayRateKind.ALL_KINDS) {
      filteredPayRates = filteredPayRates.filter(
        (payRate) => payRate.kind === selectedPayRateKind,
      );
    }
    if (activePayRatesChecked) {
      filteredPayRates = filteredPayRates.filter(
        (payRate) => payRate.status === PayRateStatus.ACTIVE,
      );
    }
  } else {
    filteredPayRates = [];
  }
  filteredPayRates = filteredPayRates.sort(listedPayRateComparator);

  const permittedForVariableBasePay = isPermitted(
    permissions,
    Permission.HAS_DPP_TIME_RATES_ACCESS,
  );

  const filteredPayRateTypes = props.payRateTypes.filter((type) =>
    isPermitted(permissions, type.editPermissions),
  );

  return (
    <Box component='div'>
      <Waiting open={loadingPayRates || loadingLocations} />
      {!isEmpty(filteredPayRateTypes) && (
        <NewPayRateDialog
          categoryName={props.categoryName}
          payRateTypes={filteredPayRateTypes}
          selectedLocation={
            (selectedAnyLocation &&
              filterLocationById(
                locations,
                parseInt(filteredLocationIDs[0], 10),
              )) ||
            undefined
          }
          open={showCreateDialog || !!payRateToEdit}
          onClose={handleCloseDialog}
          onDone={handleCreatePayRate}
        />
      )}
      <UploadWaitPayRatesDialog
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        onUpload={() => {
          setShowUploadDialog(false);
          refreshPayRates();
        }}
      />
      <Paper className={classes.header}>
        <Typography
          className={multiClass([sharedClasses.h2, classes.title])}
          data-testid={payRatesHeaderTestId}
        >
          {props.title}
        </Typography>
        <Button
          id='new-payrate-button'
          color='primary'
          variant='contained'
          onClick={handleNewPayRateButton}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={
            !props.payRateTypes.some((payRate) =>
              isPermitted(permissions, payRate.editPermissions),
            )
          }
          data-testid={newPayRateButtonTestId}
        >
          {`+ New ${props.categoryName}`}
        </Button>
        {props.pageType === PageType.BASE_PAY && (
          <Button
            color='primary'
            variant='contained'
            startIcon={<UploadIcon />}
            disabled={!isPermitted(permissions, Permission.SET_BASE_RATE)}
            onClick={() => setShowUploadDialog(true)}
            className={multiClass([sharedClasses.buttonText, classes.button])}
          >
            Upload Wait Pay
          </Button>
        )}
      </Paper>
      <Box component='div' className={classes.body}>
        <Box component='div' className={classes.filters}>
          <MultiLocationAutocomplete
            label='Location Name'
            locations={locations}
            selectedIDs={filteredLocationIDs}
            onSetSelectedIDs={handleSelectLocations}
            disabled={activePayRatesChecked}
          />
          <FormControl
            className={multiClass([
              classes.payRateDropdown,
              selectedPayRateKind
                ? classes.selectedFilter
                : classes.emptyFilter,
            ])}
            size='small'
            variant='outlined'
          >
            <InputLabel
              className={
                selectedPayRateKind
                  ? classes.selectedFilter
                  : classes.emptyFilter
              }
            >
              Rate Type
            </InputLabel>
            <Select
              id='pay_rate_select'
              onChange={handleSelectPayRate}
              value={selectedPayRateKind}
              data-testid={payRateTypeSelectTestId}
            >
              <MenuItem value={PayRateKind.ALL_KINDS}>All</MenuItem>
              <Divider />
              {permittedForVariableBasePay && (
                <MenuItem value={PayRateKind.TRIP_FARE_LIMITS}>
                  Trip Fare Limits (DPP)
                </MenuItem>
              )}
              {props.payRateTypes.map((payRateType) => (
                <MenuItem
                  id={payRateType.kind}
                  value={payRateType.kind}
                  key={payRateType.kind}
                >
                  {payRateType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            className={classes.activePayRatesSwitch}
            control={
              <Switch
                checked={activePayRatesChecked}
                onChange={handleActivePayRatesSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                color={'success'}
              />
            }
            label='All Active Pay Rates'
            labelPlacement='start'
          />
        </Box>
        {variableBasePayTableRequiredForPayRateKind(
          selectedPayRateKind,
          props.payRateTypes,
        ) && (
          <VariableBasePayHistoryTable allLocations={activePayRatesChecked} />
        )}
        {selectedAnyLocation ? (
          [
            (selectedPayRateKind === PayRateKind.ALL_KINDS ||
              selectedPayRateKind === PayRateKind.TRIP_FARE_LIMITS) &&
              permittedForVariableBasePay && (
                <TripFareLimitsTable
                  selectedLocationIDs={filteredLocationIDs}
                  getAllActive={activePayRatesChecked}
                  setTripFareLimitsExists={setFareLimitsExists}
                />
              ),
            props.payRateTypes.map((payRateType) => {
              const rows = filteredPayRates.filter(
                (payRate) => payRate.kind === payRateType.kind,
              );

              return (
                !isEmpty(rows) && (
                  <PayRateTable
                    key={payRateType.kind}
                    payRateType={payRateType}
                    rows={rows}
                    editable={isPermitted(
                      permissions,
                      payRateType.editPermissions,
                    )}
                  />
                )
              );
            }),
          ]
        ) : (
          <Box className={classes.warningMessageContainer}>
            <Grid
              container
              alignContent='center'
              justifyContent='center'
              className={classes.warningMessageGrid}
            >
              <Grid item xs={12}>
                <Typography className={classes.warningText}>
                  Please select a location to display tables
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {selectedAnyLocation &&
          isEmpty(filteredPayRates) &&
          !fareLimitsExists && (
            <Box className={classes.warningMessageContainer}>
              <Grid
                container
                alignContent='center'
                justifyContent='center'
                className={classes.warningMessageGrid}
              >
                <Grid item xs={12}>
                  <Typography className={classes.warningText}>
                    No pay rate found for the selected location
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    title: {
      color: sharedColors.gray7,
      flexGrow: 1,
    },
    button: {
      textTransform: 'none',
      marginLeft: theme.spacing(1.5),
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    locationDropdown: {
      minWidth: 250,
      marginLeft: theme.spacing(1.5),
    },
    payRateDropdown: {
      minWidth: 200,
      marginLeft: theme.spacing(1.5),
    },
    selectedFilter: {
      backgroundColor: sharedColors.white,
    },
    emptyFilter: {
      backgroundColor: sharedColors.gray2,
    },
    filterSpacer: {
      flexGrow: 1,
    },
    exportButton: {
      color: sharedColors.gray6,
      textTransform: 'none',
    },
    warningMessageContainer: {
      display: 'flex',
      flexGrow: 1,
      height: 600,
    },
    warningMessageGrid: {
      flexGrow: 1,
    },
    warningText: {
      flexGrow: 1,
      textAlign: 'center',
    },
    activePayRatesSwitch: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);
