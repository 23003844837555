import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import React from 'react';
import { OrderBreakdown } from '../../../../interfaces/PayHistory/OrderBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  basePayBreakdownNodeID,
  basePayNodeID,
  boostPayNodeID,
  boostSummariesNodeID,
  boostSummaryNodeID,
  composeNodeID,
  hazardPayNodeID,
  orderEndAtNodeID,
  orderIDNodeID,
  orderIsCancelledNodeID,
  orderIsScheduledNodeID,
  orderLocationNameNodeID,
  orderNodePrefix,
  orderStartDeliveryAtNodeID,
  returnCocPayNodeID,
  returnCocPaySummariesNodeID,
  returnCocPaySummaryNodeID,
  tier2DeliveryPayNodeID,
  tipsNodeID,
  tobaccoPayNodeID,
  totalAmountNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import SinglePayoutNode from '../SinglePayoutNode';
import SingleTextNode from '../SingleTextNode';
import { formatDateTime } from '../../../../utilities/Dates';
import BoostPayNode from './BoostPayNode';
import SingleBooleanNode from '../SingleBooleanNode';
import BasePayBreakdownNode from './BasePayBreakdownNode';
import ReturnCocPayNode from './ReturnCocPayNode';

interface OrderBreakdownNodeProps {
  orderBreakdown: OrderBreakdown;
  currency: string;
  index: number;
  timezone: string;
  prefix: string;
  isTripOrder: boolean;
}

const OrderBreakdownNode = (props: OrderBreakdownNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={composeNodeID([
        props.prefix,
        orderNodePrefix,
        props.index.toString(),
      ])}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.index.toString()} {${Object.keys(
            props.orderBreakdown,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          orderIDNodeID,
        ])}
        name='Order ID'
        value={props.orderBreakdown.orderID}
      />
      <SingleTextNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          orderLocationNameNodeID,
        ])}
        name='Location Name'
        value={props.orderBreakdown.locationName}
      />
      <SingleTextNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          orderStartDeliveryAtNodeID,
        ])}
        name='Start Delivery At'
        value={formatDateTime(
          props.orderBreakdown.startDeliveryAt,
          props.timezone,
        )}
      />
      <SingleTextNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          orderEndAtNodeID,
        ])}
        name='End At'
        value={
          props.orderBreakdown.endAt
            ? formatDateTime(props.orderBreakdown.endAt, props.timezone)
            : '-'
        }
      />
      <SingleBooleanNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          orderIsCancelledNodeID,
        ])}
        name='Is Cancelled'
        value={props.orderBreakdown.isCancelled}
      />
      <SingleBooleanNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          orderIsScheduledNodeID,
        ])}
        name='Is Scheduled'
        value={props.orderBreakdown.isScheduled}
      />
      {!props.isTripOrder && (
        <>
          <SinglePayoutNode
            id={composeNodeID([
              props.prefix,
              orderNodePrefix,
              props.index.toString(),
              basePayNodeID,
            ])}
            name='Base Pay'
            amount={props.orderBreakdown.basePay}
            currency={props.currency}
          />
          <BasePayBreakdownNode
            basePayBreakdown={props.orderBreakdown.basePayBreakdown}
            currency={props.currency}
            id={composeNodeID([
              props.prefix,
              orderNodePrefix,
              props.index.toString(),
              basePayBreakdownNodeID,
            ])}
          />
        </>
      )}
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          boostPayNodeID,
        ])}
        name='Boost Pay'
        amount={props.orderBreakdown.boostPay}
        currency={props.currency}
      />
      <TreeItem
        nodeId={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          boostSummariesNodeID,
        ])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {`Boost Pay Summaries [${props.orderBreakdown.boostPaySummaries.length.toString()}]`}
          </Typography>
        }
      >
        {props.orderBreakdown.boostPaySummaries.map((boostSummary, i) => (
          <BoostPayNode
            boostPaySummary={boostSummary}
            currency={props.currency}
            id={composeNodeID([
              props.prefix,
              orderNodePrefix,
              props.index.toString(),
              boostSummaryNodeID,
              i.toString(),
            ])}
            name={i.toString()}
            timezone={props.timezone}
          />
        ))}
      </TreeItem>
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          hazardPayNodeID,
        ])}
        name='Hazard Pay'
        amount={props.orderBreakdown.hazardPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          tier2DeliveryPayNodeID,
        ])}
        name='Tier 2 Delivery Pay'
        amount={props.orderBreakdown.tier2DeliveryPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          tobaccoPayNodeID,
        ])}
        name='Tobacco Pay'
        amount={props.orderBreakdown.tobaccoPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          returnCocPayNodeID,
        ])}
        name='Return Pay'
        amount={props.orderBreakdown.returnCocPay}
        currency={props.currency}
      />
      <TreeItem
        nodeId={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          returnCocPaySummariesNodeID,
        ])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {`Return Pay Summaries [${props.orderBreakdown.returnCocPaySummaries.length.toString()}]`}
          </Typography>
        }
      >
        {props.orderBreakdown.returnCocPaySummaries.map((summary, i) => (
          <ReturnCocPayNode
            returnCocPaySummary={summary}
            currency={props.currency}
            id={composeNodeID([
              props.prefix,
              orderNodePrefix,
              props.index.toString(),
              returnCocPaySummaryNodeID,
              i.toString(),
            ])}
            name={i.toString()}
          />
        ))}
      </TreeItem>
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          tipsNodeID,
        ])}
        name='Tips'
        amount={props.orderBreakdown.tips}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          props.prefix,
          orderNodePrefix,
          props.index.toString(),
          totalAmountNodeID,
        ])}
        name='Total Amount'
        amount={props.orderBreakdown.totalAmount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default OrderBreakdownNode;
