import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import CustomTooltip from './CustomTooltip';
import { multiClass } from '../../../utilities/Extensions';
import { Prop22PayBreakdown } from '../../../interfaces/PayHistory/Prop22PayBreakdown';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';

interface Prop22PayTooltipProps {
  prop22PayBreakdown: Prop22PayBreakdown;
  currency: string;
  children: JSX.Element;
}

const Prop22PayTooltip = (props: Prop22PayTooltipProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const nonTipEarnings = [
    props.prop22PayBreakdown.basePay,
    props.prop22PayBreakdown.doXGetY,
    props.prop22PayBreakdown.returnPay,
    props.prop22PayBreakdown.minimumGuaranteeAdjustment,
    props.prop22PayBreakdown.boostPay,
    props.prop22PayBreakdown.hazardPay,
    props.prop22PayBreakdown.tier2DeliveryPay,
    props.prop22PayBreakdown.tobaccoPay,
    props.prop22PayBreakdown.weeklyBonus,
  ];
  const nonTipEarningNames = [
    'Base Pay',
    'Do X Get Y',
    'Return Pay',
    'Minimum Guarantee Adjustment',
    'Boost Pay',
    'Hazard Pay',
    'Tier 2 Delivery Pay',
    'Tobacco Pay',
    'Weekly Bonus',
  ].filter((_, i) => nonTipEarnings[i] > 0);

  const numericCalculation = `${parseCentsAsCurrencyString(
    props.prop22PayBreakdown.totalEarningsFloor,
    props.currency,
  )} - ${nonTipEarningNames.length > 1 ? '(' : ''}${nonTipEarnings
    .filter((amount) => amount > 0)
    .map((amount) => parseCentsAsCurrencyString(amount, props.currency))
    .join(' + ')}${nonTipEarningNames.length > 1 ? ')' : ''} ${
    props.prop22PayBreakdown.amount > 0 ? ' = ' : ' < '
  }${parseCentsAsCurrencyString(
    props.prop22PayBreakdown.amount,
    props.currency,
  )}`;
  const symbolicCalculation = `Total Earnings Floor - ${
    nonTipEarningNames.length > 1 ? '(' : ''
  }${nonTipEarningNames.join(' + ')}${
    nonTipEarningNames.length > 1 ? ')' : ''
  } = Prop 22 Earnings Adjustment`;

  return (
    <CustomTooltip
      title={
        <Box className={classes.mainContainer}>
          <Typography
            className={multiClass([
              classes.numericCalculation,
              sharedClasses.subtitle1,
            ])}
          >
            {numericCalculation}
          </Typography>
          <Box component='div' className={classes.divider} />
          <Typography
            className={multiClass([
              classes.symbolicCalculation,
              sharedClasses.caption,
            ])}
          >
            {symbolicCalculation}
          </Typography>
        </Box>
      }
    >
      {props.children}
    </CustomTooltip>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  divider: {
    height: 1,
    display: 'flex',
    backgroundColor: sharedColors.gray6,
    flexGrow: 1,
  },
  numericCalculation: {
    color: sharedColors.white,
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(0.8),
  },
  symbolicCalculation: {
    marginTop: theme.spacing(0.8),
    color: sharedColors.white,
    marginBottom: theme.spacing(1.2),
  },
}));

export default Prop22PayTooltip;
