import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import {
  DelayedDeliveryPay,
  PayRateFormProps,
} from '../../../interfaces/PayRate';
import { generateNumberInput } from '../../../utilities/PayRates';

export const DelayedDeliveryFrom = (props: PayRateFormProps) => {
  const classes = useStyles();

  return (
    <Box component='div' className={classes.formWrapper}>
      <Grid container spacing={1} className={classes.grid}>
        <Grid key='space' item xs={3} />
        <Grid item xs={3}>
          <Typography className={classes.gridLabel}>Standard Orders</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.gridLabel}>FAM20 Orders</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.gridLabel}>
            Starbucks Orders
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography className={classes.rowLabel}>Amount</Typography>
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            true,
            props.currentState,
            props.setter,
            'standardAmount',
            props.isDisabled,
            '',
          )}
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            true,
            props.currentState,
            props.setter,
            'fam20Amount',
            props.isDisabled,
            '',
          )}
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            true,
            props.currentState,
            props.setter,
            'starbucksAmount',
            props.isDisabled,
            '',
          )}
        </Grid>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography className={classes.rowLabel}>Delay (Minutes)</Typography>
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            false,
            props.currentState,
            props.setter,
            'standardDelayMinutes',
            props.isDisabled,
            '',
          )}
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            false,
            props.currentState,
            props.setter,
            'fam20DelayMinutes',
            props.isDisabled,
            '',
          )}
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            false,
            props.currentState,
            props.setter,
            'starbucksDelayMinutes',
            props.isDisabled,
            '',
          )}
        </Grid>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography className={classes.rowLabel}>Offer Denials</Typography>
        </Grid>
        <Grid item xs={3}>
          {generateNumberInput<DelayedDeliveryPay>(
            false,
            false,
            props.currentState,
            props.setter,
            'standardOfferDenials',
            props.isDisabled,
            '',
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    boldText: {
      display: 'inline',
      fontWeight: 'bold',
    },
    grid: {
      marginTop: theme.spacing(1),
      width: '50%',
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    gridLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '13px',
      flexGrow: 1,
      textAlign: 'left',
    },
    rowLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '13px',
      flexGrow: 1,
      textAlign: 'right',
    },
  }),
);
