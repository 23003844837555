/**
 * maxDate is used to represent the maximum date possible
 * Value 8640000000000000 is determined according to the stack overflow thread
 * https://stackoverflow.com/questions/28425884/javascript-date-max-value/28425951
 */
export const maxDate = new Date(8640000000000000);

export const oneSecond = 1000;
export const oneMinute = 60 * oneSecond;
export const oneHour = 60 * oneMinute;
export const oneDay = 24 * oneHour;

export const weekdayAbbreviationsStartingMonday = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];
