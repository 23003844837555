import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from '@mui/lab';
import React from 'react';
import { isFinite } from 'lodash';
import { PayoutBreakdown } from '../../../interfaces/PayHistory/PayoutBreakdown';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import SinglePayoutNode from './SinglePayoutNode';
import {
  basePayNodeID,
  boostPayNodeID,
  doXGetYNodeID,
  composeNodeID,
  firstShiftNodeID,
  hazardPayNodeID,
  hoursNodeID,
  orderCountNodeID,
  overtimePayNodeID,
  reimbursementPayNodeID,
  returnCocPayNodeID,
  scheduledOrderCountNodeID,
  seattleEngagedPayNodeID,
  tier2DeliveryPayNodeID,
  timezoneNodeID,
  tipsNodeID,
  tobaccoPayNodeID,
  totalAdjustmentNodeID,
  totalAmountNodeID,
  totalEngagedDistancePayNodeID,
  totalEngagedSubsidyPayNodeID,
  totalMinGuaranteeAdjustmentNodeID,
  unscheduledOrderCountNodeID,
  weeklyBreakdownNodeID,
  weeklyNodePrefix,
} from '../../../variables/PayHistory';
import { multiClass } from '../../../utilities/Extensions';
import SingleTextNode from './SingleTextNode';
import WeeklyBonusNode from './WeeklyBonus/WeeklyBonusNode';
import Prop22PayNode from './Prop22Pay/Prop22PayNode';
import AdjustmentsListNode from './Adjustment/AdjustmentsListNode';
import MinGuaranteeAdjustmentListNode from './MinGuaranteeAdjustment/MinGuaranteeAdjustmentListNode';
import WaitPayBreakdownListNode from './WaitPay/WaitPayBreakdownListNode';
import EngagedSubsidyListNode from './EngagedSubsidy/EngagedSubsidyListNode';
import EngagedDistancePayListNode from './EngagedDistancePay/EngagedDistancePayListNode';
import MissionBreakdownListNode from './Mission/MissionBreakdownListNode';
import HolidayPayListNode from './HolidayPay/HolidayPayBreakdownListNode';
import ReimbursementPaysListNode from './ReimbursementPay/ReimbursementPaysListNode';

interface WeeklyPayoutTreeProps {
  payoutBreakdown: PayoutBreakdown;
  currency: string;
  highlightedNodes: string[];
  expandedNodes: string[];
  onExpand: (nodeIDs: string[]) => void;
}

const WeeklyPayoutTree = (props: WeeklyPayoutTreeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const handleToggle = (event: React.SyntheticEvent, nodeIDs: string[]) => {
    props.onExpand(nodeIDs);
  };

  return (
    <Box component='div' className={classes.treeContainer}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        selected={props.highlightedNodes}
        expanded={props.expandedNodes}
        onNodeToggle={handleToggle}
        multiSelect
      >
        <TreeItem
          nodeId={weeklyBreakdownNodeID}
          label={
            <Typography
              className={multiClass([classes.nodeLabel, sharedClasses.body1])}
            >
              {`Payout Breakdown {${Object.keys(
                props.payoutBreakdown,
              ).length.toString()}}`}
            </Typography>
          }
        >
          <SingleTextNode
            id={timezoneNodeID}
            name='Timezone'
            value={props.payoutBreakdown.timezone}
          />
          <SingleTextNode
            id={composeNodeID([weeklyNodePrefix, orderCountNodeID])}
            name='Order Count'
            value={props.payoutBreakdown.orderCount.toString()}
          />
          <SingleTextNode
            id={composeNodeID([weeklyNodePrefix, scheduledOrderCountNodeID])}
            name='Scheduled Order Count'
            value={props.payoutBreakdown.scheduledOrderCount.toString()}
          />
          <SingleTextNode
            id={composeNodeID([weeklyNodePrefix, unscheduledOrderCountNodeID])}
            name='Unscheduled Order Count'
            value={props.payoutBreakdown.unscheduledOrderCount.toString()}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, totalAmountNodeID])}
            name='Total Amount'
            amount={props.payoutBreakdown.totalAmount}
            currency={props.currency}
          />
          <SingleTextNode
            id={composeNodeID([weeklyNodePrefix, hoursNodeID])}
            name='Hours'
            value={props.payoutBreakdown.hours.toString()}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, tipsNodeID])}
            name='Tips'
            amount={props.payoutBreakdown.tips}
            currency={props.currency}
          />
          <WeeklyBonusNode
            weeklyBonusBreakdown={props.payoutBreakdown.weeklyBonusBreakdown}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={firstShiftNodeID}
            name='First Shift Bonus'
            amount={props.payoutBreakdown.firstShiftBonus}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={overtimePayNodeID}
            name='Overtime Pay'
            amount={props.payoutBreakdown.overtimePay}
            currency={props.currency}
          />
          <Prop22PayNode
            prop22PayBreakdown={props.payoutBreakdown.prop22PayBreakdown}
            currency={props.currency}
          />
          {props.payoutBreakdown.engagedSubsidyPay > 0 && (
            <SinglePayoutNode
              id={totalEngagedSubsidyPayNodeID}
              name='Engaged Subsidy Pay'
              amount={props.payoutBreakdown.engagedSubsidyPay}
              currency={props.currency}
            />
          )}
          <EngagedSubsidyListNode
            engagedSubsidyBreakdowns={
              props.payoutBreakdown.engagedSubsidyBreakdowns
            }
            currency={props.currency}
          />
          {props.payoutBreakdown.engagedDistancePay > 0 && (
            <SinglePayoutNode
              id={totalEngagedDistancePayNodeID}
              name='Engaged Distance Pay'
              amount={props.payoutBreakdown.engagedDistancePay}
              currency={props.currency}
            />
          )}
          <EngagedDistancePayListNode
            engagedDistancePayBreakdowns={
              props.payoutBreakdown.engagedDistancePayBreakdowns
            }
            currency={props.currency}
          />
          <SinglePayoutNode
            id={totalAdjustmentNodeID}
            name='Total Adjustment'
            amount={props.payoutBreakdown.totalAdjustment}
            currency={props.currency}
          />
          <AdjustmentsListNode
            adjustments={props.payoutBreakdown.adjustments}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, basePayNodeID])}
            name='Base Pay'
            amount={props.payoutBreakdown.basePay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, boostPayNodeID])}
            name='Boost Pay'
            amount={props.payoutBreakdown.boostPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, doXGetYNodeID])}
            name='Do X Get Y'
            amount={props.payoutBreakdown.doXGetY}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, hazardPayNodeID])}
            name='Hazard Pay'
            amount={props.payoutBreakdown.hazardPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, tier2DeliveryPayNodeID])}
            name='Tier 2 Delivery Pay'
            amount={props.payoutBreakdown.tier2DeliveryPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, tobaccoPayNodeID])}
            name='Tobacco Pay'
            amount={props.payoutBreakdown.tobaccoPay}
            currency={props.currency}
          />
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, returnCocPayNodeID])}
            name='Return Pay'
            amount={props.payoutBreakdown.returnCocPay}
            currency={props.currency}
          />
          {isFinite(props.payoutBreakdown.seattleEngagedPay) && (
            <SinglePayoutNode
              id={composeNodeID([weeklyNodePrefix, seattleEngagedPayNodeID])}
              name='Engaged Pay'
              amount={props.payoutBreakdown.seattleEngagedPay!}
              currency={props.currency}
            />
          )}
          <SinglePayoutNode
            id={composeNodeID([weeklyNodePrefix, reimbursementPayNodeID])}
            name='Reimbursement Pay'
            amount={props.payoutBreakdown.reimbursementPay}
            currency={props.currency}
          />
          <ReimbursementPaysListNode
            reimbursementPayBreakdowns={
              props.payoutBreakdown.reimbursementPayBreakdowns
            }
            currency={props.currency}
          />
          <SinglePayoutNode
            id={totalMinGuaranteeAdjustmentNodeID}
            name='Total Minimum Guarantee Adjustment'
            amount={props.payoutBreakdown.totalMinGuaranteeAdjustment}
            currency={props.currency}
          />
          <MinGuaranteeAdjustmentListNode
            minGuaranteeAdjustments={
              props.payoutBreakdown.minGuaranteeAdjustmentBreakdowns
            }
            currency={props.currency}
            timezone={props.payoutBreakdown.timezone}
          />
          {props.payoutBreakdown.weeklyWaitPayBreakdowns.length > 0 && (
            <WaitPayBreakdownListNode
              waitPayBreakdowns={props.payoutBreakdown.weeklyWaitPayBreakdowns}
              weekly
              displayShifts
              timezone={props.payoutBreakdown.timezone}
              amount={props.payoutBreakdown.totalWeeklyWaitPay}
              currency={props.currency}
            />
          )}
          {props.payoutBreakdown.shiftBasedWaitPayWeeklyBreakdowns.length >
            0 && (
            <WaitPayBreakdownListNode
              waitPayBreakdowns={
                props.payoutBreakdown.shiftBasedWaitPayWeeklyBreakdowns
              }
              weekly={false}
              displayShifts={false}
              timezone={props.payoutBreakdown.timezone}
              amount={props.payoutBreakdown.totalShiftBasedWaitPay}
              currency={props.currency}
            />
          )}
          <MissionBreakdownListNode
            missionBreakdowns={props.payoutBreakdown.missionBreakdowns}
            currency={props.currency}
            timezone={props.payoutBreakdown.timezone}
          />
          <HolidayPayListNode
            holidayPayBreakdown={props.payoutBreakdown.holidayPayBreakdown}
            currency={props.currency}
          />
        </TreeItem>
      </TreeView>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  treeContainer: {
    borderRadius: 4,
    border: 'groove',
    borderColor: sharedColors.purple4,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: sharedColors.white,
  },
  nodeLabel: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default WeeklyPayoutTree;
