import { makeStyles } from 'tss-react/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';
import { useState } from 'react';
import { flatten } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { DailyPayoutBreakdown } from '../../interfaces/PayHistory/DailyPayoutBreakdown';
import { multiClass } from '../../utilities/Extensions';
import {
  formatSingleDayWithDayName,
  formatSingleDayWithDayNameWithoutYear,
} from '../../utilities/Dates';
import { parseCentsAsCurrencyString } from '../../utilities/Currency';
import KeyStats from './KeyStats';
import SummaryDetailRow from './SummaryDetailRow';
import OrderBreakdownCard from './OrderBreakdownCard';
import DailyPayoutTree from './PayoutTree/DailyPayoutTree';
import {
  amountNodeID,
  basePayBreakdownNodeID,
  basePayNodeID,
  boostPayAmountNodeID,
  boostPayEndAtNodeID,
  boostPayNameNodeID,
  boostPayNodeID,
  doXGetYNodeID,
  boostPayStartAtNodeID,
  boostSummariesNodeID,
  boostSummaryNodeID,
  composeNodeID,
  dailyBreakdownNodeID,
  dailyNodePrefix,
  engagedTripTravelDistanceNodeID,
  engagedTripTravelMetricsNodeID,
  engagedTripTravelTimeMinutesNodeID,
  estimatedTripTravelDistanceNodeID,
  estimatedTripTravelMetricsNodeID,
  estimatedTripTravelTimeMinutesNodeID,
  hazardPayNodeID,
  holidayPayAccruedDaysNodeID,
  holidayPayBreakdownsNodeID,
  holidayPayRateNodeID,
  hoursNodeID,
  isVariableBasePayNodeID,
  orderCountNodeID,
  orderEndAtNodeID,
  orderIDNodeID,
  orderIsCancelledNodeID,
  orderIsScheduledNodeID,
  orderLocationNameNodeID,
  orderNodePrefix,
  ordersNodeID,
  orderStartDeliveryAtNodeID,
  realTimeAdjustmentNodeID,
  reimbursementPayNodeID,
  returnCocPayAmountNodeID,
  returnCocPayNodeID,
  returnCocPayReturnLocationCountNodeID,
  returnCocPaySummariesNodeID,
  returnCocPaySummaryNodeID,
  roundedTotalWaitMinutesNodeID,
  scheduledOrderCountNodeID,
  seattleEngagedPayNodeID,
  shiftBasedWaitPayWeeklyBreakdownsNodeID,
  shiftEventsNodeID,
  shiftsWithEventsNodeID,
  shiftWithEventsActualEndNodeID,
  shiftWithEventsActualStartNodeID,
  tier2DeliveryPayNodeID,
  tipsNodeID,
  tobaccoPayNodeID,
  totalAmountNodeID,
  totalWaitSecondsNodeID,
  tripEndAtNodeID,
  tripIDNodeID,
  tripLocationNameNodeID,
  tripNodePrefix,
  tripsNodeID,
  tripStartAtNodeID,
  tripTravelMetricsNodeID,
  unscheduledOrderCountNodeID,
  waitPayIsCalculatedWeeklyNodeID,
  waitPayMinGuaranteeMinuteRateNodeID,
} from '../../variables/PayHistory';
import DailyTotalAmountTooltip from './Tooltip/DailyTotalAmountTooltip';
import TripBreakdownCard from './TripBreakdownCard';
import { OrderBreakdown } from '../../interfaces/PayHistory/OrderBreakdown';
import { ShiftWithEventsBreakdown } from '../../interfaces/PayHistory/ShiftWithEventsBreakdown';
import { WaitPayBreakdown } from '../../interfaces/PayHistory/WaitPayBreakdown';
import WaitPayTooltip from './Tooltip/WaitPayTooltip';

interface DailyPayoutAccordionProps {
  workweek: Date;
  workday: number;
  timezone: string;
  dailyBreakdown: DailyPayoutBreakdown;
  currency: string;
}

const DailyPayoutAccordion = (props: DailyPayoutAccordionProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [highlightedNodes, setHighlightedNodes] = useState<string[]>([]);
  const [expandedNodes, setExpandedNodes] = useState([dailyBreakdownNodeID]);

  const handleHoverTotalAmount = () => {
    const newHighlightedNodes = [
      composeNodeID([dailyNodePrefix, totalAmountNodeID]),
      composeNodeID([dailyNodePrefix, tipsNodeID]),
      composeNodeID([dailyNodePrefix, basePayNodeID]),
      composeNodeID([dailyNodePrefix, boostPayNodeID]),
      composeNodeID([dailyNodePrefix, doXGetYNodeID]),
      composeNodeID([dailyNodePrefix, hazardPayNodeID]),
      composeNodeID([dailyNodePrefix, tier2DeliveryPayNodeID]),
      composeNodeID([dailyNodePrefix, tobaccoPayNodeID]),
      composeNodeID([dailyNodePrefix, returnCocPayNodeID]),
      composeNodeID([dailyNodePrefix, seattleEngagedPayNodeID]),
      composeNodeID([dailyNodePrefix, reimbursementPayNodeID]),
      holidayPayBreakdownsNodeID,
      shiftBasedWaitPayWeeklyBreakdownsNodeID,
    ];

    setHighlightedNodes(newHighlightedNodes);
  };

  const highlightOrder = (index: number) => {
    const getOrderBoostSummaryNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, boostPayStartAtNodeID]),
      composeNodeID([prefix, boostPayEndAtNodeID]),
      composeNodeID([prefix, boostPayNameNodeID]),
      composeNodeID([prefix, boostPayAmountNodeID]),
    ];

    const getOrderReturnCocPaySummaryNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, returnCocPayReturnLocationCountNodeID]),
      composeNodeID([prefix, returnCocPayAmountNodeID]),
    ];

    const getOrderBasePayBreakdownNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, basePayNodeID]),
      composeNodeID([prefix, realTimeAdjustmentNodeID]),
      composeNodeID([prefix, isVariableBasePayNodeID]),
    ];

    const newExpandedNodes = [
      dailyBreakdownNodeID,
      ordersNodeID,
      composeNodeID([dailyNodePrefix, orderNodePrefix, index.toString()]),
      ...expandedNodes,
    ];

    const orderBreakdown = props.dailyBreakdown.orderBreakdowns[index];

    const newHighlightedNodes = [
      ordersNodeID,
      composeNodeID([dailyNodePrefix, orderNodePrefix, index.toString()]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        orderIDNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        orderLocationNameNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        orderStartDeliveryAtNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        orderEndAtNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        orderIsCancelledNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        orderIsScheduledNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        basePayNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        boostPayNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        doXGetYNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        boostSummariesNodeID,
      ]),
      ...flatten(
        orderBreakdown.boostPaySummaries.map((_, i) =>
          getOrderBoostSummaryNodeIDs(
            composeNodeID([
              dailyNodePrefix,
              orderNodePrefix,
              index.toString(),
              boostSummaryNodeID,
              i.toString(),
            ]),
          ),
        ),
      ),
      ...getOrderBasePayBreakdownNodeIDs(
        composeNodeID([
          dailyNodePrefix,
          orderNodePrefix,
          index.toString(),
          basePayBreakdownNodeID,
        ]),
      ),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        hazardPayNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        tier2DeliveryPayNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        tobaccoPayNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        returnCocPayNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        returnCocPaySummariesNodeID,
      ]),
      ...flatten(
        orderBreakdown.returnCocPaySummaries.map((_, i) =>
          getOrderReturnCocPaySummaryNodeIDs(
            composeNodeID([
              dailyNodePrefix,
              orderNodePrefix,
              index.toString(),
              returnCocPaySummaryNodeID,
              i.toString(),
            ]),
          ),
        ),
      ),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        tipsNodeID,
      ]),
      composeNodeID([
        dailyNodePrefix,
        orderNodePrefix,
        index.toString(),
        totalAmountNodeID,
      ]),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const highlightTrip = (index: number) => {
    const getOrderBoostSummaryNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, boostPayStartAtNodeID]),
      composeNodeID([prefix, boostPayEndAtNodeID]),
      composeNodeID([prefix, boostPayNameNodeID]),
      composeNodeID([prefix, boostPayAmountNodeID]),
    ];

    const getOrderReturnCocPaySummaryNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, returnCocPayReturnLocationCountNodeID]),
      composeNodeID([prefix, returnCocPayAmountNodeID]),
    ];

    const getBasePayBreakdownNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, basePayNodeID]),
      composeNodeID([prefix, realTimeAdjustmentNodeID]),
      composeNodeID([prefix, isVariableBasePayNodeID]),
    ];

    const getOrderNodeIDs = (
      orderBreakdown: OrderBreakdown,
      prefix: string,
      orderIndex: number,
    ): string[] => [
      composeNodeID([prefix, orderNodePrefix, orderIndex.toString()]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        orderIDNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        orderLocationNameNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        orderStartDeliveryAtNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        orderEndAtNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        orderIsCancelledNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        orderIsScheduledNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        basePayNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        boostPayNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        boostSummariesNodeID,
      ]),
      ...flatten(
        orderBreakdown.boostPaySummaries.map((_, i) =>
          getOrderBoostSummaryNodeIDs(
            composeNodeID([
              prefix,
              orderNodePrefix,
              orderIndex.toString(),
              boostSummaryNodeID,
              i.toString(),
            ]),
          ),
        ),
      ),
      ...getBasePayBreakdownNodeIDs(
        composeNodeID([
          prefix,
          orderNodePrefix,
          orderIndex.toString(),
          basePayBreakdownNodeID,
        ]),
      ),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        hazardPayNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        tier2DeliveryPayNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        tobaccoPayNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        returnCocPayNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        returnCocPaySummariesNodeID,
      ]),
      ...flatten(
        orderBreakdown.returnCocPaySummaries.map((_, i) =>
          getOrderReturnCocPaySummaryNodeIDs(
            composeNodeID([
              prefix,
              orderNodePrefix,
              orderIndex.toString(),
              returnCocPaySummaryNodeID,
              i.toString(),
            ]),
          ),
        ),
      ),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        tipsNodeID,
      ]),
      composeNodeID([
        prefix,
        orderNodePrefix,
        orderIndex.toString(),
        totalAmountNodeID,
      ]),
    ];

    const getTripTravelMetricsNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, estimatedTripTravelMetricsNodeID]),
      composeNodeID([prefix, estimatedTripTravelTimeMinutesNodeID]),
      composeNodeID([prefix, estimatedTripTravelDistanceNodeID]),
      composeNodeID([prefix, engagedTripTravelMetricsNodeID]),
      composeNodeID([prefix, engagedTripTravelTimeMinutesNodeID]),
      composeNodeID([prefix, engagedTripTravelDistanceNodeID]),
    ];

    const newExpandedNodes = [
      dailyBreakdownNodeID,
      tripsNodeID,
      composeNodeID([tripNodePrefix, index.toString()]),
      ...expandedNodes,
    ];

    const tripBreakdown = props.dailyBreakdown.tripBreakdowns[index];

    const newHighlightedNodes = [
      tripsNodeID,
      composeNodeID([tripNodePrefix, index.toString()]),
      composeNodeID([tripNodePrefix, index.toString(), tripIDNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), tripLocationNameNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), tripStartAtNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), tripEndAtNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), ordersNodeID]),
      ...flatten(
        tripBreakdown.orderBreakdowns.map((orderBreakdown, i) =>
          getOrderNodeIDs(
            orderBreakdown,
            composeNodeID([
              tripNodePrefix,
              index.toString(),
              ordersNodeID,
              i.toString(),
            ]),
            i,
          ),
        ),
      ),
      ...getTripTravelMetricsNodeIDs(
        composeNodeID([
          tripNodePrefix,
          index.toString(),
          tripTravelMetricsNodeID,
        ]),
      ),
      composeNodeID([tripNodePrefix, index.toString(), basePayNodeID]),
      ...getBasePayBreakdownNodeIDs(
        composeNodeID([
          tripNodePrefix,
          index.toString(),
          basePayBreakdownNodeID,
        ]),
      ),
      composeNodeID([tripNodePrefix, index.toString(), boostPayNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), hazardPayNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), tier2DeliveryPayNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), tobaccoPayNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), returnCocPayNodeID]),
      composeNodeID([
        tripNodePrefix,
        index.toString(),
        seattleEngagedPayNodeID,
      ]),
      composeNodeID([tripNodePrefix, index.toString(), tipsNodeID]),
      composeNodeID([tripNodePrefix, index.toString(), totalAmountNodeID]),
    ];
    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const highlightWaitPay = (index: number) => {
    const getShiftWithEventsBreakdownNodeIDs = (
      shiftWithEventsBreakdown: ShiftWithEventsBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, shiftWithEventsActualStartNodeID]),
      composeNodeID([prefix, shiftWithEventsActualEndNodeID]),
      composeNodeID([prefix, totalWaitSecondsNodeID]),
      composeNodeID([prefix, roundedTotalWaitMinutesNodeID]),
      composeNodeID([prefix, shiftEventsNodeID]),
      ...shiftWithEventsBreakdown.events.map((_, i) =>
        composeNodeID([prefix, shiftEventsNodeID, i.toString()]),
      ),
    ];

    const getWaitPayBreakdownNodeIDs = (
      waitPayBreakdown: WaitPayBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, totalWaitSecondsNodeID]),
      composeNodeID([prefix, roundedTotalWaitMinutesNodeID]),
      composeNodeID([prefix, waitPayMinGuaranteeMinuteRateNodeID]),
      ...getShiftWithEventsBreakdownNodeIDs(
        waitPayBreakdown.shiftWithEvents,
        composeNodeID([prefix, shiftsWithEventsNodeID]),
      ),
      composeNodeID([prefix, waitPayIsCalculatedWeeklyNodeID]),
      composeNodeID([prefix, amountNodeID]),
    ];

    const waitPayBreakdown = props.dailyBreakdown.waitPayBreakdowns[index];

    const newExpandedNodes = [
      dailyBreakdownNodeID,
      shiftBasedWaitPayWeeklyBreakdownsNodeID,
      composeNodeID([
        shiftBasedWaitPayWeeklyBreakdownsNodeID,
        index.toString(),
      ]),
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      shiftBasedWaitPayWeeklyBreakdownsNodeID,
      ...getWaitPayBreakdownNodeIDs(
        waitPayBreakdown,
        composeNodeID([
          shiftBasedWaitPayWeeklyBreakdownsNodeID,
          index.toString(),
        ]),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverHolidayPay = () => {
    const newExpandedNodes = [
      dailyBreakdownNodeID,
      holidayPayBreakdownsNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      holidayPayBreakdownsNodeID,
      ...props.dailyBreakdown.holidayPayBreakdown.singleHolidayPayBreakdowns.flatMap(
        (holidayPayBreakdown, i) => [
          composeNodeID([holidayPayBreakdownsNodeID, i.toString()]),
          composeNodeID([
            holidayPayBreakdownsNodeID,
            i.toString(),
            holidayPayAccruedDaysNodeID,
          ]),
          composeNodeID([
            holidayPayBreakdownsNodeID,
            i.toString(),
            holidayPayRateNodeID,
          ]),
        ],
      ),
    ];

    setExpandedNodes(newExpandedNodes);
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleChangeExpandedNodes = (newExpandedNodeIDs: string[]) => {
    if (!newExpandedNodeIDs.includes(dailyBreakdownNodeID)) {
      setExpandedNodes([]);
    } else {
      setExpandedNodes(newExpandedNodeIDs);
    }
  };

  const shiftedDate = moment(props.workweek).add(props.workday, 'day').toDate();

  return (
    <Box component='div' className={classes.accordionContainer}>
      <Accordion disableGutters className={classes.accordion}>
        <AccordionSummary
          expandIcon={
            <Box
              component='div'
              className={classes.accordionExpandIconBackground}
            >
              <ExpandMoreIcon className={classes.accordionExpandIcon} />
            </Box>
          }
        >
          <Typography
            className={multiClass([sharedClasses.h5, classes.dateText])}
          >
            {formatSingleDayWithDayName(shiftedDate)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper square component='div' className={classes.accordionDetails}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box component='div' className={classes.column}>
                  <Typography
                    className={multiClass([
                      sharedClasses.h6,
                      classes.columnTitle,
                    ])}
                  >
                    Gopuff Driver App
                  </Typography>
                  <Box
                    component='div'
                    className={multiClass([
                      classes.driverAppContainer,
                      classes.driverAppTopPadding,
                    ])}
                  >
                    <Box
                      component='div'
                      className={classes.centeredTextContainer}
                    >
                      <Typography
                        className={multiClass([
                          classes.dateTitle,
                          sharedClasses.caption,
                        ])}
                      >
                        {formatSingleDayWithDayNameWithoutYear(shiftedDate)}
                      </Typography>
                    </Box>
                    <Box
                      component='div'
                      className={classes.centeredTextContainer}
                    >
                      <DailyTotalAmountTooltip
                        dailyPayoutBreakdown={props.dailyBreakdown}
                        currency={props.currency}
                      >
                        <Typography
                          onMouseEnter={handleHoverTotalAmount}
                          className={classes.totalAmount}
                        >
                          {parseCentsAsCurrencyString(
                            props.dailyBreakdown.totalAmount,
                            props.currency,
                          )}
                        </Typography>
                      </DailyTotalAmountTooltip>
                    </Box>
                    <Divider className={classes.keyStatsDivider} />
                    <Grid
                      container
                      spacing={1.5}
                      className={classes.keyStatsGrid}
                    >
                      <Grid item xs={4}>
                        <KeyStats
                          name='Deliveries'
                          value={props.dailyBreakdown.orderCount.toString()}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([
                                dailyNodePrefix,
                                orderCountNodeID,
                              ]),
                              composeNodeID([
                                dailyNodePrefix,
                                scheduledOrderCountNodeID,
                              ]),
                              composeNodeID([
                                dailyNodePrefix,
                                unscheduledOrderCountNodeID,
                              ]),
                            ])
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyStats
                          name='Hours'
                          value={props.dailyBreakdown.hours.toFixed(2)}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([dailyNodePrefix, hoursNodeID]),
                            ])
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyStats
                          name='Tips'
                          value={parseCentsAsCurrencyString(
                            props.dailyBreakdown.tips,
                            props.currency,
                          )}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([dailyNodePrefix, tipsNodeID]),
                            ])
                          }
                        />
                      </Grid>
                    </Grid>
                    <Box
                      component='div'
                      className={classes.detailedSummaryContainer}
                    >
                      <Typography className={sharedClasses.subtitle1}>
                        Daily Summary
                      </Typography>
                      <Divider className={classes.dailySummaryDivider} />
                      <SummaryDetailRow
                        name='Base Pay'
                        amount={props.dailyBreakdown.basePay}
                        currency={props.currency}
                        onHover={() =>
                          setHighlightedNodes([
                            composeNodeID([dailyNodePrefix, basePayNodeID]),
                          ])
                        }
                      />
                      {props.dailyBreakdown.boostPay > 0 && (
                        <SummaryDetailRow
                          name='Boost Pay'
                          amount={props.dailyBreakdown.boostPay}
                          currency={props.currency}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([dailyNodePrefix, boostPayNodeID]),
                            ])
                          }
                        />
                      )}
                      {props.dailyBreakdown.doXGetY > 0 && (
                          <SummaryDetailRow
                              name='Do X Get Y'
                              amount={props.dailyBreakdown.doXGetY}
                              currency={props.currency}
                              onHover={() =>
                                  setHighlightedNodes([
                                    composeNodeID([dailyNodePrefix, doXGetYNodeID]),
                                  ])
                              }
                          />
                      )}
                      {props.dailyBreakdown.hazardPay > 0 && (
                        <SummaryDetailRow
                          name='Hazard Pay'
                          amount={props.dailyBreakdown.hazardPay}
                          currency={props.currency}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([dailyNodePrefix, hazardPayNodeID]),
                            ])
                          }
                        />
                      )}
                      {props.dailyBreakdown.tier2DeliveryPay > 0 && (
                        <SummaryDetailRow
                          name='Tier 2 Delivery Pay'
                          amount={props.dailyBreakdown.tier2DeliveryPay}
                          currency={props.currency}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([
                                dailyNodePrefix,
                                tier2DeliveryPayNodeID,
                              ]),
                            ])
                          }
                        />
                      )}
                      {props.dailyBreakdown.tobaccoPay > 0 && (
                        <SummaryDetailRow
                          name='Tobacco Pay'
                          amount={props.dailyBreakdown.tobaccoPay}
                          currency={props.currency}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([
                                dailyNodePrefix,
                                tobaccoPayNodeID,
                              ]),
                            ])
                          }
                        />
                      )}
                      {props.dailyBreakdown.returnCocPay > 0 && (
                        <SummaryDetailRow
                          name='Return Pay'
                          amount={props.dailyBreakdown.returnCocPay}
                          currency={props.currency}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([
                                dailyNodePrefix,
                                returnCocPayNodeID,
                              ]),
                            ])
                          }
                        />
                      )}
                      {(props.dailyBreakdown.seattleEngagedPay ?? 0) > 0 && (
                        <SummaryDetailRow
                          name='Engaged Pay'
                          amount={props.dailyBreakdown.seattleEngagedPay!}
                          currency={props.currency}
                          onHover={() =>
                            setHighlightedNodes([
                              composeNodeID([
                                dailyNodePrefix,
                                seattleEngagedPayNodeID,
                              ]),
                            ])
                          }
                        />
                      )}
                      <SummaryDetailRow
                        name='Tips'
                        amount={props.dailyBreakdown.tips}
                        currency={props.currency}
                        onHover={() =>
                          setHighlightedNodes([
                            composeNodeID([dailyNodePrefix, tipsNodeID]),
                          ])
                        }
                      />
                      {props.dailyBreakdown.waitPayBreakdowns.map(
                        (waitPayBreakdown, i) => (
                          <WaitPayTooltip
                            waitPayBreakdown={waitPayBreakdown}
                            currency={props.currency}
                          >
                            <Box component='div'>
                              <SummaryDetailRow
                                name='Wait Pay'
                                secondaryText={waitPayBreakdown.locationName}
                                amount={waitPayBreakdown.amount}
                                currency={props.currency}
                                onHover={() => highlightWaitPay(i)}
                              />
                            </Box>
                          </WaitPayTooltip>
                        ),
                      )}
                      {props.dailyBreakdown.holidayPayBreakdown != null && (
                        <SummaryDetailRow
                          name='Holiday Pay'
                          currency={props.currency}
                          amount={
                            props.dailyBreakdown.holidayPayBreakdown.totalAmount
                          }
                          onHover={handleHoverHolidayPay}
                        />
                      )}
                    </Box>
                  </Box>
                  {props.dailyBreakdown.orderBreakdowns.length > 0 && (
                    <Box>
                      <Typography
                        className={multiClass([
                          sharedClasses.h6,
                          classes.columnTitle,
                        ])}
                      >
                        Gopuff Driver App
                      </Typography>
                      <Box
                        component='div'
                        className={classes.driverAppContainer}
                      >
                        {props.dailyBreakdown.orderBreakdowns.map(
                          (orderBreakdown, i) => (
                            <OrderBreakdownCard
                              timezone={props.timezone}
                              orderBreakdown={orderBreakdown}
                              currency={props.currency}
                              roundedTopBorder={i === 0}
                              onHover={() => highlightOrder(i)}
                            />
                          ),
                        )}
                      </Box>
                    </Box>
                  )}
                  {props.dailyBreakdown.tripBreakdowns.length > 0 && (
                    <Box>
                      <Typography
                        className={multiClass([
                          sharedClasses.h6,
                          classes.columnTitle,
                        ])}
                      >
                        Gopuff Driver App
                      </Typography>
                      <Box
                        component='div'
                        className={classes.driverAppContainer}
                      >
                        {props.dailyBreakdown.tripBreakdowns.map(
                          (tripBreakdown, i) => (
                            <TripBreakdownCard
                              timezone={props.timezone}
                              tripBreakdown={tripBreakdown}
                              currency={props.currency}
                              roundedTopBorder={i === 0}
                              onHover={() => highlightTrip(i)}
                            />
                          ),
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box component='div' className={classes.column}>
                  <Typography
                    className={multiClass([
                      sharedClasses.h6,
                      classes.columnTitle,
                    ])}
                  >
                    Raw Pay Data
                  </Typography>
                  <DailyPayoutTree
                    dailyPayoutBreakdown={props.dailyBreakdown}
                    currency={props.currency}
                    timezone={props.timezone}
                    highlightedNodes={highlightedNodes}
                    expandedNodes={expandedNodes}
                    onExpand={handleChangeExpandedNodes}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  accordionContainer: {
    marginTop: theme.spacing(0.25),
    flexGrow: 1,
    display: 'flex',
  },
  accordion: {
    backgroundColor: sharedColors.gray5,
    flexGrow: 1,
  },
  accordionExpandIcon: {
    color: sharedColors.gray1,
  },
  accordionDetails: {
    padding: theme.spacing(3),
    backgroundColor: sharedColors.white,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-2.25),
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  accordionExpandIconBackground: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    borderRadius: 4,
    backgroundColor: sharedColors.gray6,
  },
  dateText: {
    color: sharedColors.white,
  },
  verticalSpacer: {
    marginTop: theme.spacing(1),
  },
  driverAppContainer: {
    borderRadius: 4,
    border: 'groove',
    borderColor: sharedColors.blue4,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(4),
    backgroundColor: sharedColors.white,
  },
  driverAppTopPadding: {
    paddingTop: theme.spacing(1.5),
  },
  centeredTextContainer: {
    flexGrow: 1,
    textAlign: 'center',
  },
  dateTitle: {
    color: sharedColors.gray5,
    marginBottom: theme.spacing(1.5),
  },
  totalAmount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '33px',
    marginBottom: theme.spacing(1),
  },
  column: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  columnTitle: {
    marginBottom: theme.spacing(0.75),
  },
  keyStatsDivider: {
    margin: theme.spacing(2),
    color: sharedColors.gray3,
  },
  keyStatsGrid: {
    marginBottom: theme.spacing(2.5),
  },
  detailedSummaryContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: sharedColors.gray2,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dailySummaryDivider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

export default DailyPayoutAccordion;
