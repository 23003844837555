import { Box, Icon, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface IconToastContainerProps {
  text: string;
  type: 'info' | 'success' | 'warning' | 'error';
  color: string;
}

const IconToastContainer = (props: IconToastContainerProps) => {
  return (
    <Box component='span' m={1} display='flex'>
      <Icon color={props.type === 'warning' ? 'action' : 'inherit'}>
        {props.type === 'success' ? (
          <CheckCircleOutlineIcon />
        ) : (
          <ErrorOutlineIcon />
        )}
      </Icon>
      <Typography sx={{ marginLeft: '10px', color: props.color }}>
        {props.text}
      </Typography>
    </Box>
  );
};

export default IconToastContainer;
