export interface HolidayPayBreakdown {
  singleHolidayPayBreakdowns: SingleHolidayPayBreakdown[];
  totalAmount: number;
}

export interface SingleHolidayPayBreakdown {
  holidayTypeName: string;
  accruedDays: number;
  rate: number;
  amount: number;
}

export const singleHolidayPayBreakdownFromResponse = (
  responseData: any,
): SingleHolidayPayBreakdown => ({
  holidayTypeName: responseData.holiday_type_name ?? 0,
  accruedDays: responseData.accrued_days ?? 0,
  rate: responseData.rate ?? 0,
  amount: responseData.amount ?? 0,
});

export const holidayPayBreakdownFromResponse = (
  responseData: any,
): HolidayPayBreakdown => ({
  singleHolidayPayBreakdowns: responseData.single_holiday_pay_breakdowns.map(
    (singleHolidayPayResponse: any) =>
      singleHolidayPayBreakdownFromResponse(singleHolidayPayResponse),
  ),
  totalAmount: responseData.total_amount ?? 0,
});
