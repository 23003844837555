import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { EngagedSubsidyBreakdown } from '../../../../interfaces/PayHistory/EngagedSubsidyBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  engagedSubsidyPaysNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import EngagedSubsidyNode from './EngagedSubsidyNode';

interface EngagedSubsidyListNodeProps {
  engagedSubsidyBreakdowns: EngagedSubsidyBreakdown[];
  currency: string;
}

const EngagedSubsidyListNode = (props: EngagedSubsidyListNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return props.engagedSubsidyBreakdowns.length > 0 ? (
    <TreeItem
      nodeId={engagedSubsidyPaysNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Engaged Subsidy Breakdowns [${props.engagedSubsidyBreakdowns.length.toString()}]`}
        </Typography>
      }
    >
      {props.engagedSubsidyBreakdowns.map((engagedSubsidyBreakdown, i) => (
        <EngagedSubsidyNode
          id={composeNodeID([engagedSubsidyPaysNodeID, i.toString()])}
          name={i.toString()}
          engagedSubsidyBreakdown={engagedSubsidyBreakdown}
          currency={props.currency}
        />
      ))}
    </TreeItem>
  ) : null;
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default EngagedSubsidyListNode;
