const config = {
  DRIVER_PAYOUT_API: process.env.REACT_APP_DRIVER_PAYOUT_API,
  OKTA: {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
  },
};

export default config;
