import {
  payoutSummaryFromResponse,
  PayoutSupportSummary,
} from './PayoutSupportSummary';
import {
  PayoutBreakdown,
  payoutBreakdownFromResponse,
} from './PayoutBreakdown';
import {
  PayoutProviderSummary,
  payoutProviderSummaryFromResponse,
} from './PayoutProviderSummary';

export interface DriverSupportSummary {
  driverId: string;
  name: string;
  workweek: string;
  email: string;
  phoneNumber?: string;
  employmentStatus: string;
  country?: string;
  isRestrictedAccount: boolean;
  isInstantEligible: boolean;
  restrictionType?: string;
  stripeAccountID?: string;
  currency: string;
  totalEarned: number;
  totalPaid: number;
  totalFee: number;
  payoutProviders: PayoutProviderSummary[];
  payouts: PayoutSupportSummary[];
  payoutBreakdown?: PayoutBreakdown;
}

export const driverSupportSummaryFromResponse = (
  responseData: any,
  workweek: string,
): DriverSupportSummary => ({
  driverId: responseData.driver_id ?? '',
  name: responseData.name ?? '',
  workweek: responseData.workweek ?? workweek,
  email: responseData.email ?? '',
  phoneNumber: responseData.phone_number,
  employmentStatus: responseData.employment_status ?? '',
  country: responseData.country,
  isRestrictedAccount: responseData.is_restricted_account ?? false,
  isInstantEligible: responseData.is_instant_eligible ?? false,
  restrictionType: responseData.restriction_type,
  stripeAccountID: responseData.stripe_account_id,
  currency: responseData.currency ?? 'USD',
  totalEarned: responseData.total_earned ?? 0,
  totalPaid: responseData.total_paid ?? 0,
  totalFee: responseData.total_fee ?? 0,
  payoutProviders: (responseData.payout_providers ?? []).map((provider: any) =>
    payoutProviderSummaryFromResponse(provider),
  ),
  payouts: responseData.payouts.map((summary: any) =>
    payoutSummaryFromResponse(summary),
  ),
  payoutBreakdown:
    responseData.payout_breakdown &&
    payoutBreakdownFromResponse(responseData.payout_breakdown),
});

export interface WorkweekToSummaryMap {
  [key: string]: DriverSupportSummary | null;
}
