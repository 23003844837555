import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { NullableDate } from '../interfaces/PayRate';
import { getCurrentWorkweek } from './Workweek';

export const easternTimezone = 'America/New_York';

export const formatMomentAsFilePostfix = (): string =>
  moment().format('YYYYMMDDHHmmss');

export const formatSingleDay = (date: Date, timezone?: string): string =>
  (timezone ? moment.tz(date, timezone) : moment(date)).format('MMMM D, YYYY');

export const formatSingleDayWithDayName = (
  date: Date,
  timezone?: string,
): string =>
  (timezone ? moment.tz(date, timezone) : moment(date)).format(
    'dddd, MMMM D, YYYY',
  );

export const formatSingleDayWithDayNameWithoutYear = (
  date: Date,
  timezone?: string,
): string =>
  (timezone ? moment.tz(date, timezone) : moment(date)).format('dddd, MMMM D');

export const formatSingleDayWithShortMonth = (
  date: Date,
  timezone?: string,
): string =>
  (timezone ? moment.tz(date, timezone) : moment(date)).format('dddd, MMM D');

export const formatDate = (date: Date, timezone: string): string =>
  moment.tz(date, timezone).format('MMMM D, YYYY');

export const formatDateTime = (date: Date, timezone: string): string =>
  moment.tz(date, timezone).format('MMMM D, YYYY hh:mm a');

export const formatCsvDateTime = (date: Date, timezone: string): string =>
  moment.tz(date, timezone).format('MMMM D YYYY hh:mm a');

export const formatCsvLocalTime = (date: Date): string =>
  moment.utc(date).local().format('MMMM D YYYY hh:mm a');

// We can get rid of formatLocalTime below after we replace all the callers with this one.
export const formatLocalTimeV2 = (date: Date): string =>
  moment.utc(date).format('MMMM D, YYYY hh:mm a');

export const formatLocalTime = (date: Date): string =>
  moment.utc(date).local().format('MMMM D, YYYY hh:mm a');

export const formatLocalTimeWithoutYear = (date: Date): string =>
  moment.utc(date).local().format('MMMM D, hh:mm a');

export const formatDeliveryTime = (date: Date, timezone?: string): string =>
  (timezone ? moment.tz(date, timezone) : moment(date)).format('hh:mm a');

export const formatTime = (hours: number, minutes: number): string =>
  `${hours % 12 === 0 ? 12 : hours % 12}:${minutes < 10 ? '0' : ''}${minutes} ${
    hours < 12 ? 'am' : 'pm'
  }`;

export const formatTimeCapital = (hours: number, minutes: number): string =>
  moment().set({ hours, minutes }).format('h:mmA');

export const formatDateAmericanReadable = (
  date: Date,
  timezone?: string,
): string => {
  return timezone
    ? moment(date).tz(timezone).format('MM/DD/YYYY hh:mm A')
    : moment(date).format('MM/DD/YYYY hh:mm A');
};

export const handleEndDate = (receivedEndDate: any): NullableDate => {
  if (typeof receivedEndDate === 'string') {
    return {
      time: new Date(receivedEndDate),
      valid: true,
    };
  }

  return {
    time: receivedEndDate?.valid ? new Date(receivedEndDate.time) : undefined,
    valid: !!receivedEndDate?.valid,
  };
};

export const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const convertDateByTimeZone = (
  timezone: string | null,
  date: string,
  time: string = '05:00',
) => {
  const convertedDate = moment.tz(`${date} ${time}`, timezone || 'UTC');
  return convertedDate.format();
};

export const convertLocalToDeliveryZone = (
  timezone: string,
  date?: DateTime,
  hour: number = 5,
  minute: number = 0,
  second: number = 0,
  millisecond: number = 0,
): DateTime => {
  const dateToTransform = date && date.isValid ? date : DateTime.now();
  return DateTime.now()
    .setZone(timezone)
    .set({
      year: dateToTransform.get('year'),
      month: dateToTransform.get('month'),
      day: dateToTransform.get('day'),
      hour,
      minute,
      second,
      millisecond,
    });
};

export const convertToLocalZone = (
  date?: DateTime,
  hour: number = 5,
  minute: number = 0,
  second: number = 0,
  millisecond: number = 0,
): DateTime => {
  const dateToTransform = date && date.isValid ? date : DateTime.now();
  return DateTime.now().set({
    year: dateToTransform.get('year'),
    month: dateToTransform.get('month'),
    day: dateToTransform.get('day'),
    hour,
    minute,
    second,
    millisecond,
  });
};

export const earliestByHour = (hour: number, timezone: string): DateTime => {
  let now = DateTime.now().setZone(timezone);
  if (now.get('hour') >= hour) {
    now = now.plus({ day: 1 });
  }
  return now.set({ hour, minute: 0, second: 0, millisecond: 0 });
};

export const removeTimezone = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  ).toISOString();
};

export const parseDate = (date: string) => date.split('T')[0];

export const getTimeString = (date: string) => {
  const time = date.split(':');
  return `${time[0]}:${time[1]}`;
};

export const getNamesOfMonths = (): string[] => {
  const names: string[] = [];
  let t = DateTime.fromMillis(0);
  for (let i = 0; i < 12; i += 1) {
    t = t.set({ month: i + 1 });
    names.push(t.monthLong);
  }

  return names;
};

export const getLastMonth = (): number => {
  const currentMonth = new Date().getMonth();
  return currentMonth === 0 ? 11 : currentMonth - 1;
};

export const getCurrentYear = (): number => new Date().getFullYear();

export const calculateTimeDiff = (startDate: Date, endDate: Date) => {
  let diffInMilliSeconds =
    Math.abs(startDate.getTime() - endDate.getTime()) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;

  let difference = '';
  if (days > 0) {
    hours += 24 * days;
  }
  difference +=
    hours === 0 || hours === 1 ? `${hours} hour ` : `${hours} hours `;
  if (minutes > 0) {
    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} minutes`
        : `${minutes} minutes`;
  }
  return difference;
};

export const datesAreOnSameDay = (startAt: Date, endAt: Date): boolean => {
  return (
    startAt.getFullYear() === endAt.getFullYear() &&
    startAt.getMonth() === endAt.getMonth() &&
    startAt.getDate() === endAt.getDate()
  );
};

export const getNextPayoutTimeAsString = (): string => {
  let payoutTime = moment().tz(easternTimezone).day(2).hour(11).minute(0);
  if (payoutTime.isBefore(moment())) {
    payoutTime = payoutTime.day(9);
  }
  return formatDateAmericanReadable(payoutTime.toDate(), easternTimezone);
};

export const getNextWorkweekStart = (): Date =>
  DateTime.fromJSDate(getCurrentWorkweek())
    .set({
      hour: 5,
      minute: 0,
      second: 0,
    })
    .plus({ days: 7 })
    .toJSDate();

export const getNextTuesday = (): Date => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilNextTuesday = (2 - dayOfWeek + 7) % 7 || 7;
  const nextTuesday = new Date(today.getTime());
  nextTuesday.setDate(today.getDate() + daysUntilNextTuesday);
  return nextTuesday;
};

export const isFutureTuesday = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const inputDate = new Date(date);
  inputDate.setHours(0, 0, 0, 0);

  const isFutureDate = inputDate > today;
  const isTuesday = inputDate.getDay() === 2;

  return isFutureDate && isTuesday;
};
