import { makeStyles } from 'tss-react/mui';
import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { OrderBreakdown } from '../../interfaces/PayHistory/OrderBreakdown';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { formatDeliveryTime } from '../../utilities/Dates';
import { parseCentsAsCurrencyString } from '../../utilities/Currency';
import { multiClass } from '../../utilities/Extensions';

interface OrderBreakdownCardProps {
  timezone: string;
  orderBreakdown: OrderBreakdown;
  currency: string;
  roundedTopBorder: boolean;
  onHover: () => void;
}

const OrderBreakdownCard = (props: OrderBreakdownCardProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' onMouseEnter={props.onHover}>
      <Box
        component='div'
        className={multiClass([
          classes.cardHeader,
          props.roundedTopBorder && classes.topBorderRadius,
        ])}
      >
        <Box
          component='div'
          className={multiClass([classes.column, classes.verticalCenter])}
        >
          <Typography className={sharedClasses.subtitle1}>
            {`${
              props.orderBreakdown.isCancelled ? 'Cancelled' : 'Delivered'
            } at ${
              props.orderBreakdown.endAt
                ? formatDeliveryTime(props.orderBreakdown.endAt, props.timezone)
                : '-'
            }`}
          </Typography>
          <Typography
            className={multiClass([sharedClasses.body2, classes.orderNumber])}
          >{`Order ${props.orderBreakdown.orderID}`}</Typography>
        </Box>
        <Box component='div' className={classes.horizontalSpacer} />
        <Typography
          className={multiClass([sharedClasses.h6, classes.headerTotalAmount])}
        >
          {parseCentsAsCurrencyString(
            props.orderBreakdown.totalAmount,
            props.currency,
          )}
        </Typography>
      </Box>
      <Box component='div' className={classes.cardContent}>
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <Box component='div' className={classes.column}>
              <Typography
                className={multiClass([
                  sharedClasses.caption,
                  classes.tripDateTime,
                ])}
              >
                Trip Started
              </Typography>
              <Typography className={sharedClasses.body2}>
                {formatDeliveryTime(
                  props.orderBreakdown.startDeliveryAt,
                  props.timezone,
                )}
              </Typography>
              <Box component='div' className={classes.verticalSpacing} />
              <Typography
                className={multiClass([
                  sharedClasses.caption,
                  classes.tripDateTime,
                ])}
              >{`Trip ${
                props.orderBreakdown.isCancelled ? 'Cancelled' : 'Completed'
              }`}</Typography>
              <Typography className={sharedClasses.body2}>
                {props.orderBreakdown.endAt
                  ? formatDeliveryTime(
                      props.orderBreakdown.endAt,
                      props.timezone,
                    )
                  : '-'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box component='div' className={classes.earningsComponentRow}>
              <Typography className={sharedClasses.body2}>Base Pay</Typography>
              <Box component='div' className={classes.horizontalSpacer} />
              <Typography className={sharedClasses.body2}>
                {parseCentsAsCurrencyString(
                  props.orderBreakdown.basePay,
                  props.currency,
                )}
              </Typography>
            </Box>
            {props.orderBreakdown.boostPaySummaries.length > 0 && (
              <Box component='div' className={classes.earningsComponentRow}>
                <Typography className={sharedClasses.body2}>
                  Boost Pay
                </Typography>
                <Box component='div' className={classes.horizontalSpacer} />
                <Typography className={sharedClasses.body2}>
                  {parseCentsAsCurrencyString(
                    props.orderBreakdown.boostPay,
                    props.currency,
                  )}
                </Typography>
              </Box>
            )}
            {props.orderBreakdown.hazardPay > 0 && (
              <Box component='div' className={classes.earningsComponentRow}>
                <Typography className={sharedClasses.body2}>
                  Hazard Pay
                </Typography>
                <Box component='div' className={classes.horizontalSpacer} />
                <Typography className={sharedClasses.body2}>
                  {parseCentsAsCurrencyString(
                    props.orderBreakdown.hazardPay,
                    props.currency,
                  )}
                </Typography>
              </Box>
            )}
            {props.orderBreakdown.tier2DeliveryPay > 0 && (
              <Box component='div' className={classes.earningsComponentRow}>
                <Typography className={sharedClasses.body2}>
                  Tier 2 Delivery Pay
                </Typography>
                <Box component='div' className={classes.horizontalSpacer} />
                <Typography className={sharedClasses.body2}>
                  {parseCentsAsCurrencyString(
                    props.orderBreakdown.tier2DeliveryPay,
                    props.currency,
                  )}
                </Typography>
              </Box>
            )}
            {props.orderBreakdown.tobaccoPay > 0 && (
              <Box component='div' className={classes.earningsComponentRow}>
                <Typography className={sharedClasses.body2}>
                  Tobacco Pay
                </Typography>
                <Box component='div' className={classes.horizontalSpacer} />
                <Typography className={sharedClasses.body2}>
                  {parseCentsAsCurrencyString(
                    props.orderBreakdown.tobaccoPay,
                    props.currency,
                  )}
                </Typography>
              </Box>
            )}
            {props.orderBreakdown.returnCocPay > 0 && (
              <Box component='div' className={classes.earningsComponentRow}>
                <Typography className={sharedClasses.body2}>
                  Return Pay
                </Typography>
                <Box component='div' className={classes.horizontalSpacer} />
                <Typography className={sharedClasses.body2}>
                  {parseCentsAsCurrencyString(
                    props.orderBreakdown.returnCocPay,
                    props.currency,
                  )}
                </Typography>
              </Box>
            )}
            {props.orderBreakdown.tips > 0 && (
              <Box component='div' className={classes.earningsComponentRow}>
                <Typography className={sharedClasses.body2}>Tips</Typography>
                <Box component='div' className={classes.horizontalSpacer} />
                <Typography className={sharedClasses.body2}>
                  {parseCentsAsCurrencyString(
                    props.orderBreakdown.tips,
                    props.currency,
                  )}
                </Typography>
              </Box>
            )}
            <Divider className={classes.horizontalDivider} />
            <Box component='div' className={classes.earningsComponentRow}>
              <Typography className={sharedClasses.body1}>
                Trip Earnings
              </Typography>
              <Box component='div' className={classes.horizontalSpacer} />
              <Typography className={sharedClasses.body1}>
                {parseCentsAsCurrencyString(
                  props.orderBreakdown.totalAmount,
                  props.currency,
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {!props.orderBreakdown.isScheduled && (
          <Box component='div' className={classes.column}>
            <Divider className={classes.dashedDivider} />
            <Typography
              className={multiClass([
                classes.onDemandText,
                sharedClasses.body2,
              ])}
            >
              On-demand Delivery
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  cardContent: {
    padding: theme.spacing(3),
    backgroundColor: sharedColors.gray2,
  },
  cardHeader: {
    padding: theme.spacing(1.5),
    backgroundColor: sharedColors.white,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalSpacer: {
    flexGrow: 1,
  },
  column: {
    flexDirection: 'column',
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  verticalSpacing: {
    paddingTop: theme.spacing(1.75),
  },
  orderNumber: {
    color: sharedColors.gray5,
  },
  headerTotalAmount: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  tripDateTime: {
    color: sharedColors.gray5,
  },
  earningsComponentRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  horizontalDivider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.75),
    color: sharedColors.gray3,
  },
  dashedDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed',
  },
  onDemandText: {
    color: sharedColors.gray6,
  },
  topBorderRadius: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
}));

export default OrderBreakdownCard;
