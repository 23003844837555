import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { DropzoneArea } from 'react-mui-dropzone';
import { MissionSegmentFormProps } from '../../../interfaces/Mission/MissionSegment';
import { sharedColors } from '../../../utilities/Styles';
import { csvMimeTypes } from '../../../utilities/Csv';

const DriverInput = (props: MissionSegmentFormProps) => {
  const classes = useStyles();

  const [file, setCurrentFile] = useState<File | null>(null);
  const [textValues, setTextValues] = useState('');

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = (e.target?.result ?? '') as string;
        const driverIDs = text.split(/[\s,]+|\r?\n/);
        props.setCurrentState({
          ...props.currentState,
          driverIDs,
        });
        setTextValues(driverIDs.join('\n'));
      };
      reader.onerror = () => toast.error('Could not read the input file');

      reader.readAsText(file);
    } else {
      props.setCurrentState({ ...props.currentState, driverIDs: [] });
      setTextValues('');
    }
  }, [file]);

  const handleFile = (files: File[]) => {
    setCurrentFile(files.length === 0 ? null : files[0]);
  };

  const handleTextChange = (e: any) => {
    setTextValues(e.target.value);
  };

  const splitTextValues = textValues.split(/[\s,]+|\r?\n/);
  const filteredSplitTextValues = splitTextValues.filter(
    (value) => value !== '',
  );

  return (
    <Box component='div' className={classes.body}>
      <FormControl component='fieldset' className={classes.uploadForm}>
        <DropzoneArea
          dropzoneClass={classes.dropzone}
          acceptedFiles={[csvMimeTypes, '.txt', 'text/plain']}
          filesLimit={1}
          showFileNames
          onChange={handleFile}
        />
      </FormControl>
      <TextField
        label={`DP IDs (${filteredSplitTextValues.length.toString()})`}
        multiline
        rows={Math.min(5, Math.max(1, splitTextValues.length))}
        value={textValues}
        onChange={handleTextChange}
        onBlur={() =>
          props.setCurrentState({
            ...props.currentState,
            driverIDs: filteredSplitTextValues,
          })
        }
        size='small'
        disabled={!!file}
        className={classes.textField}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
      display: 'flex',
      flexDirection: 'column',
    },
    textField: {
      width: 350,
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(5),
    },
    uploadForm: {
      width: 350,
    },
    dropzone: {
      marginLeft: theme.spacing(5),
      padding: theme.spacing(2),
      minHeight: 180,
      backgroundColor: sharedColors.gray2,
      border: 'dashed',
      borderColor: sharedColors.gray3,
      cursor: 'pointer',
    },
  }),
);

export default DriverInput;
