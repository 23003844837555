import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { MissionProgressSummary } from '../../../../interfaces/PayHistory/MissionProgressSummary';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  missionProgressSummaryOrderNodeID,
  missionProgressSummaryTripNodeID,
} from '../../../../variables/PayHistory';

interface MissionProgressSummaryNodeProps {
  id: string;
  name: string;
  missionProgressSummary: MissionProgressSummary;
}

const MissionProgressSummaryNode = (props: MissionProgressSummaryNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.name} {${Object.keys(
            props.missionProgressSummary,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, missionProgressSummaryOrderNodeID])}
        name='Order Count'
        value={props.missionProgressSummary.orderCount.toString()}
      />
      <SingleTextNode
        id={composeNodeID([props.id, missionProgressSummaryTripNodeID])}
        name='Trip Count'
        value={props.missionProgressSummary.tripCount.toString()}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MissionProgressSummaryNode;
