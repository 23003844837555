import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import { isMonday } from 'date-fns';
import { useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import LinkIcon from '@mui/icons-material/Link';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { adjustmentSubtypes } from '../../../atoms/Adjustment';
import { AdjustmentParams } from '../../../interfaces/Adjustment';
import { AdjustmentSubtype } from '../../../interfaces/AdjustmentSubtype';
import { requiredErrorMessage } from '../../../utilities/Constants';
import { parseCentsAsCurrencyStringAmount } from '../../../utilities/Currency';
import { multiClass } from '../../../utilities/Extensions';
import {
  getCurrentWorkweek,
  getEarliestSelectableWorkweekForAdjustment,
  parseWorkweekApiString,
} from '../../../utilities/Workweek';
import AdjustmentTypeSelect from './AdjustmentTypeSelect';
import RecentAdjustmentHistory from './RecentAdjustmentHistory';
import { openInNewTab } from '../../../utilities/Misc';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';

interface AdjustmentDisplayFormProps {
  title: string;
  adj: AdjustmentParams;
  readOnly: boolean;
  managerNotes?: string;
  driverEmail?: string;
  handleDriverIdChange: (e: any) => void;
  handleAmountChange: (e: any) => void;
  handleWorkweekChange: (date: Date | null) => void;
  handleCaseLinkChange: (e: any) => void;
  handleSubtypeChange: (subtype: AdjustmentSubtype) => void;
  handleTextInputChange: (e: any) => void;
  handleDateInputChange: (date: Date | null) => void;
  handleNoteChange: (e: any) => void;
}

const AdjustmentDisplayForm = (props: AdjustmentDisplayFormProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const subtypes = useRecoilValue(adjustmentSubtypes);
  const selectedSubtype = subtypes.find(
    (subtype) => subtype.id === props.adj.adjustmentSubtypeId,
  );

  const [showDriversRecentAdjustments, setShowDriversRecentAdjustments] =
    useState(false);

  const showCaseLinkInput =
    selectedSubtype && selectedSubtype.thresholdAmount < props.adj.amount;

  const visibleNotes =
    props.readOnly && props.managerNotes
      ? `${props.adj.notes}${props.managerNotes}`
      : props.adj.notes;

  const [visibleAmount, setVisibleAmount] = useState(
    parseCentsAsCurrencyStringAmount(props.adj.amount),
  );
  const updateAmount = (event: any) => {
    props.handleAmountChange(event);
    setVisibleAmount(event.target.value as string);
  };

  const earliestSelectableWorkweek =
    getEarliestSelectableWorkweekForAdjustment();
  const currentWorkweek = getCurrentWorkweek('UTC');

  const additionalInformation = props.readOnly ? (
    <>
      <Grid item xs={12}>
        <Typography
          className={multiClass([
            sharedClasses.overline,
            classes.additionalInfoTitle,
          ])}
        >
          ADDITIONAL INFORMATION
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box component='div' className={classes.additionalInfoContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DriverEmailDisplay email={props.driverEmail ?? '-'} />
            </Grid>
            <Grid item xs={6}>
              <KustomerCaseLinkInput
                disabled={props.readOnly}
                caseLink={props.adj.caseLink || ''}
                handleCaseLinkChange={props.handleCaseLinkChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  ) : (
    <></>
  );

  const kustomerInput =
    showCaseLinkInput && !props.readOnly ? (
      <>
        <Grid item xs={12}>
          <Alert severity='warning'>
            {!props.readOnly && (
              <Typography className={classes.overThresholdAlert}>
                <b>Note:</b> This adjustment will require a manager's approval.
                If available, please copy + paste the Kustomer link into the
                field below and let the Delivery Partner know that they may need
                up to 24 hours to see it appear in their account.
              </Typography>
            )}
            <KustomerCaseLinkInput
              disabled={props.readOnly}
              caseLink={props.adj.caseLink || ''}
              handleCaseLinkChange={props.handleCaseLinkChange}
            />
          </Alert>
        </Grid>
      </>
    ) : (
      <></>
    );

  return (
    <Grid container spacing={2} className={classes.contentGrid}>
      <Grid item xs={12}>
        <Typography>{props.title}</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextValidator
          label='Enter Driver ID'
          size='small'
          variant='outlined'
          autoFocus={true}
          value={props.adj.driverId}
          onChange={props.handleDriverIdChange}
          onBlur={() =>
            setShowDriversRecentAdjustments(
              props.adj.driverId.length > 0 && !props.readOnly,
            )
          }
          className={classes.gridItem}
          validators={['required']}
          errorMessages={[requiredErrorMessage]}
          name={'DriverId'}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label='Select Work Week'
            shouldDisableDate={(date: number | Date) =>
              !isMonday(date) ||
              date < earliestSelectableWorkweek ||
              date > currentWorkweek
            }
            onChange={props.handleWorkweekChange}
            value={parseWorkweekApiString(props.adj.workweek)}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField {...params} size={'small'} />
            )}
            disabled={props.readOnly}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel htmlFor='adjustment-amount'>Enter Amount</InputLabel>
          <OutlinedInput
            id='adjustment-amount'
            label='Enter Amount'
            size='small'
            onChange={updateAmount}
            value={visibleAmount}
            disabled={props.readOnly}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} hidden={!showDriversRecentAdjustments}>
        <RecentAdjustmentHistory
          open={showDriversRecentAdjustments}
          driverId={props.adj.driverId}
          onClose={() => setShowDriversRecentAdjustments(false)}
        />
      </Grid>
      {kustomerInput}
      <Grid item xs={12}>
        {!props.readOnly && (
          <Typography noWrap={true}>
            Enter adjustment type and secondary information{' '}
            <em>(if applicable)</em>
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <AdjustmentTypeSelect
          selectedSubtype={selectedSubtype}
          adjustmentSubtypes={subtypes}
          onChange={props.handleSubtypeChange}
          disabled={props.readOnly}
        />
      </Grid>
      {selectedSubtype?.requiresTextInput && (
        <Grid item xs={6}>
          <TextValidator
            label={selectedSubtype.textInputLabel}
            size='small'
            variant='outlined'
            value={props.adj.textInput}
            onChange={props.handleTextInputChange}
            className={classes.input}
            name={selectedSubtype.textInputLabel}
            disabled={props.readOnly}
          />
        </Grid>
      )}
      {selectedSubtype?.requiresDate && (
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={selectedSubtype.textInputLabel}
              onChange={props.handleDateInputChange}
              value={props.adj.dateInput}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} size={'small'} />}
              disabled={props.readOnly}
            />
          </LocalizationProvider>
        </Grid>
      )}
      {additionalInformation}
      <Grid item xs={12}>
        <TextValidator
          label='Notes'
          name='Notes'
          size='small'
          variant='outlined'
          value={visibleNotes}
          onChange={props.handleNoteChange}
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          className={classes.noteBox}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.notesDisclaimer}>
          Notes are visible to delivery partners on Gopuff Driver App Earnings.
        </Typography>
      </Grid>
    </Grid>
  );
};

interface KustomerCaseLinkInputProps {
  disabled: boolean;
  caseLink: string;
  handleCaseLinkChange: (e: any) => void;
}

const KustomerCaseLinkInput = (props: KustomerCaseLinkInputProps) => {
  const { classes } = useStyles();
  const label = props.disabled
    ? 'Kustomer Case Link'
    : 'Enter Kustomer Case Link';

  return (
    <FormControl
      variant='outlined'
      fullWidth
      size='small'
      className={classes.additionalInfoTitle}
    >
      <InputLabel htmlFor='kustomer-case-link'>{label}</InputLabel>
      <OutlinedInput
        id='kustomer-case-link'
        type='text'
        value={props.caseLink}
        onChange={props.handleCaseLinkChange}
        className={multiClass([classes.input, classes.caseLinkInput])}
        disabled={props.disabled}
        endAdornment={
          props.disabled ? (
            <InputAdornment position='end'>
              <IconButton
                onClick={() => props.caseLink && openInNewTab(props.caseLink)}
                color='primary'
                edge='end'
              >
                <LinkIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
        fullWidth={true}
        label={label}
      />
    </FormControl>
  );
};

interface DriverEmailDisplayProps {
  email: string;
}

const DriverEmailDisplay = (props: DriverEmailDisplayProps) => {
  const { classes } = useStyles();
  return (
    <FormControl
      variant='outlined'
      fullWidth
      size='small'
      className={classes.additionalInfoField}
    >
      <InputLabel htmlFor='driver-email'>Driver Email Address</InputLabel>
      <OutlinedInput
        id='driver-email'
        type='text'
        value={props.email}
        disabled={true}
        label='Driver Email Address'
      />
    </FormControl>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  contentGrid: {
    flexGrow: 1,
  },
  gridItem: {
    display: 'flex',
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    flexGrow: 1,
  },
  caseLinkInput: {
    backgroundColor: 'white',
  },
  noteBox: {
    display: 'flex',
  },
  additionalInfoTitle: {
    color: sharedColors.gray5,
  },
  additionalInfoContainer: {
    backgroundColor: sharedColors.gray2,
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 7,
  },
  additionalInfoField: {
    backgroundColor: sharedColors.white,
  },
  overThresholdAlert: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.4px',
    marginBottom: '10px',
  },
  notesDisclaimer: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.4px',
    marginBottom: '10px',
  },
}));

export default AdjustmentDisplayForm;
