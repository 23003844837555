import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { WaitPayBreakdown } from '../../interfaces/PayHistory/WaitPayBreakdown';
import { multiClass } from '../../utilities/Extensions';
import { parseCentsAsCurrencyString } from '../../utilities/Currency';
import WaitPayTooltip from './Tooltip/WaitPayTooltip';

interface WaitPayLocationsProps {
  waitPayBreakdowns: WaitPayBreakdown[];
  currency: string;
}

const WaitPayLocations = (props: WaitPayLocationsProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' className={classes.outerWrapper}>
      {props.waitPayBreakdowns.map((breakdown) => (
        <WaitPayTooltip waitPayBreakdown={breakdown} currency={props.currency}>
          <Box component='div' className={classes.row}>
            <Box
              component='div'
              className={multiClass([
                classes.verticalCenter,
                classes.leftPadding,
              ])}
            >
              <Typography
                className={multiClass([
                  classes.secondaryText,
                  sharedClasses.caption,
                ])}
              >
                {`•${breakdown.locationName}`}
              </Typography>
            </Box>
            <Box component='div' className={classes.horizontalSpacer} />
            <Box component='div' className={classes.verticalCenter}>
              <Typography
                className={multiClass([
                  classes.secondaryText,
                  sharedClasses.caption,
                ])}
              >
                {parseCentsAsCurrencyString(breakdown.amount, props.currency)}
              </Typography>
            </Box>
          </Box>
        </WaitPayTooltip>
      ))}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  outerWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalSpacer: {
    display: 'flex',
    flexGrow: 1,
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  secondaryText: {
    color: sharedColors.gray5,
  },
  leftPadding: {
    paddingLeft: theme.spacing(0.5),
  },
}));

export default WaitPayLocations;
