import {
  BoostPaySummary,
  boostPaySummaryFromResponse,
} from './BoostPaySummary';
import {
  BasePayBreakdown,
  basePayBreakdownFromResponse,
} from './BasePayBreakdown';
import {
  ReturnCocPaySummary,
  returnCocPaySummaryFromResponse,
} from './ReturnCocPaySummary';

export interface OrderBreakdown {
  orderID: string;
  locationName: string;
  startDeliveryAt: Date;
  endAt?: Date;
  isCancelled: boolean;
  isScheduled: boolean;
  basePay: number;
  basePayBreakdown: BasePayBreakdown;
  boostPay: number;
  boostPaySummaries: BoostPaySummary[];
  hazardPay: number;
  tier2DeliveryPay: number;
  tobaccoPay: number;
  returnCocPay: number;
  returnCocPaySummaries: ReturnCocPaySummary[];
  tips: number;
  totalAmount: number;
}

export const orderBreakdownFromResponse = (
  responseData: any,
): OrderBreakdown => ({
  orderID: responseData.order_id ?? '',
  locationName: responseData.location_name ?? '',
  startDeliveryAt: new Date(responseData.start_delivery_at),
  endAt: responseData.end_at && new Date(responseData.end_at),
  isCancelled: !!responseData.is_cancelled,
  isScheduled: !!responseData.is_scheduled,
  basePay: responseData.base_pay ?? 0,
  basePayBreakdown: basePayBreakdownFromResponse(
    responseData.base_pay_breakdown ?? {},
  ),
  boostPay: responseData.boost_pay ?? 0,
  boostPaySummaries: responseData.boost_pay_summaries.map((summary: any) =>
    boostPaySummaryFromResponse(summary),
  ),
  hazardPay: responseData.hazard_pay ?? 0,
  tier2DeliveryPay: responseData.tier2_delivery_pay ?? 0,
  tobaccoPay: responseData.tobacco_pay ?? 0,
  returnCocPay: responseData.return_coc_pay ?? 0,
  returnCocPaySummaries: (responseData.return_coc_pay_summaries ?? []).map(
    (summary: any) => returnCocPaySummaryFromResponse(summary),
  ),
  tips: responseData.tips ?? 0,
  totalAmount: responseData.total_amount ?? 0,
});
