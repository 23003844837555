import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';

import { locations as locationsAtom } from '../../atoms/Location';
import {
  getCurrencySymbolFromLocation,
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../utilities/Locations';
import { formatDateTime } from '../../utilities/Dates';
import { multiClass } from '../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { TargetCommission } from '../../interfaces/TargetCommissions';

type TargetCommissionOverviewProps = {
  targetCommission: TargetCommission;
};

export const TargetCommissionOverview = (
  props: TargetCommissionOverviewProps,
) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const location = filterLocationById(
    locations,
    parseInt(props.targetCommission.locationID, 10),
  );
  const timeZone =
    getTimeZoneByLocationId(
      locations,
      parseInt(props.targetCommission.locationID, 10),
    ) || 'UTC';

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start Time(MFC Local)
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(props.targetCommission.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='endDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            End Time(MFC Local)
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(props.targetCommission.endAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='targetCommission' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Commission
          </Typography>
          <Typography className={sharedClasses.body1}>
            {props.targetCommission.targetCommissionUnscheduledEdited
              ? `${getCurrencySymbolFromLocation(location)}${
                  props.targetCommission.targetCommissionUnscheduledEdited
                }`
              : `${getCurrencySymbolFromLocation(location)}${
                  props.targetCommission.targetCommissionUnscheduledRaw
                }`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
