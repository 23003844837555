import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { MissionSegmentFormProps } from '../../../interfaces/Mission/MissionSegment';
import { locations as locationsAtom } from '../../../atoms/Location';
import MultiLocationAutocomplete from '../../MultiLocationAutocomplete';
import { sharedColors } from '../../../utilities/Styles';

const MustBeActiveAtInput = (props: MissionSegmentFormProps) => {
  const classes = useStyles();

  const locations = useRecoilValue(locationsAtom);

  const handleChange = (newLocationIDs: string[]) => {
    props.setCurrentState({
      ...props.currentState,
      mustBeActiveAt: newLocationIDs,
      includeAlreadyActiveDrivers: isEmpty(newLocationIDs)
        ? props.currentState.includeAlreadyActiveDrivers
        : true,
    });
  };

  return (
    <Box component='div' className={classes.body}>
      <Box component='div' className={classes.dropdown}>
        <MultiLocationAutocomplete
          label='Locations'
          locations={locations.filter(
            (location) =>
              location.countryCode === props.currentState.countryCode,
          )}
          selectedIDs={props.currentState.mustBeActiveAt}
          onSetSelectedIDs={handleChange}
          error={isEmpty(props.currentState.mustBeActiveAt)}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    dropdown: {
      marginLeft: theme.spacing(5),
    },
  }),
);

export default MustBeActiveAtInput;
