import {
  MissionSegmentInvitationRequirements,
  missionSegmentInvitationRequirementsFromResponse,
} from './MissionSegmentInvitationRequirements';
import {
  MissionSegmentStats,
  missionSegmentStatsFromResponse,
} from './MissionSegmentStats';
import { Audit, auditFromResponse } from '../Audit';
import { NumberWithOperator } from '../NumberWithOperator';
import { MissionSegmentToCreateExperimentation } from './MissionSegmentToCreateExperimentation';

export enum MissionSegmentationKind {
  COUNTRY = 'country',
  DRIVER = 'driver',
  LOCATION = 'location',
  MUST_BE_ACTIVE_AT = 'must-be-active-at',
}

export interface MissionSegmentToCreate {
  name: string;
  scheduledForLocal?: Date;
  includeAlreadyActiveDrivers: boolean;
  countryCode?: string;
  metroIDs: string[];
  locationIDs: string[];
  driverIDs: string[];
  mustBeActiveAt: string[];
  notActiveSince?: Date;
  recentDriverHours?: NumberWithOperator;
  acceptanceRate?: NumberWithOperator;
  completionRate?: NumberWithOperator;
  onTimeRate?: NumberWithOperator;
  dnrRate?: NumberWithOperator;
  scheduledAvailabilityRate?: NumberWithOperator;
  completedOrderCount?: NumberWithOperator;
  compositeScore?: NumberWithOperator;
  completedFirstDelivery?: boolean;
  autoAcceptInvitations: boolean;
  sendPushNotifications: boolean;
  experimentation: MissionSegmentToCreateExperimentation;
}

export interface MissionSegment {
  id: number;
  missionID: number;
  name: string;
  invitationRequirements: MissionSegmentInvitationRequirements;
  stats: MissionSegmentStats;
  pushNotificationsSent: boolean;
  isControlSegment: boolean;
  scheduledFor?: Date;
  audit: Audit;
}

export const missionSegmentFromResponse = (
  responseData: any,
): MissionSegment => ({
  id: responseData.id,
  missionID: responseData.mission_id,
  name: responseData.name,
  invitationRequirements: missionSegmentInvitationRequirementsFromResponse(
    responseData.invitation_requirements ?? {},
  ),
  stats: missionSegmentStatsFromResponse(responseData.stats ?? {}),
  pushNotificationsSent: responseData.push_notifications_sent,
  isControlSegment: responseData.is_control_segment,
  scheduledFor: responseData.scheduled_for
    ? new Date(responseData.scheduled_for)
    : undefined,
  audit: auditFromResponse(responseData.audit ?? {}),
});

export type OutputTransformerFn = (
  currentState: MissionSegmentToCreate,
  isSandbox: boolean,
  segmentationTypes: MissionSegmentationKind[],
) => any;

export interface MissionSegmentFormProps {
  currentState: MissionSegmentToCreate;
  setCurrentState: (newState: MissionSegmentToCreate) => void;
}
