export interface EarningRadarSummary {
  locationID: string;
  isActive: boolean;
  isHot: boolean;

  ataP90: number;
  ataP90Threshold: number;
  ataP90ReferenceTime?: Date;

  aopdLatest: number;
  aopdP70Historical: number;
  aopdReferenceTime?: Date;

  minMaxWaitTimeReferenceTime?: Date;
  minWaitTimeInMin: number;
  maxWaitTimeInMin: number;
  virtualQueueSizeReferenceTime?: Date;
  vqLength: number;
}

export const earningRadarSummaryFromResponse = (
  responseData: any,
): EarningRadarSummary => ({
  locationID: responseData.location_id,
  isActive: responseData.is_active,
  isHot: responseData.is_hot,

  ataP90: responseData.predicted_ata_p90,
  ataP90Threshold: responseData.predicted_ata_p90_threshold,
  ataP90ReferenceTime: responseData.predicted_ata_p90_threshold_reference_time,

  aopdLatest: responseData.aopd_latest,
  aopdP70Historical: responseData.aopd_p70_historical,
  aopdReferenceTime: responseData.aopd_reference_time,

  minMaxWaitTimeReferenceTime: responseData.min_max_wait_time_reference_time,
  minWaitTimeInMin: responseData.min_wait_time_in_min,
  maxWaitTimeInMin: responseData.max_wait_time_in_min,
  virtualQueueSizeReferenceTime: responseData.virtual_queue_size_reference_time,
  vqLength: responseData.vq_length,
});
