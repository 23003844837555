import { DialogActions, Button, Box } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { createAdjustmentCSV } from '../../../services/adjustments';
import { useSharedStyles } from '../../../utilities/Styles';
import { BulkErrorDialog } from '../../BulkErrorDialog';
import UploadCSV from '../../UploadCSV';
import Waiting from '../../Waiting';
import { downloadCsvTemplate } from '../../../utilities/Csv';
import { adjustmentsCsvTemplate } from '../../../variables/CsvTemplate';

export const adjustmentCSVUploadTestId = `adjustment-csv-upload-test-id`;

interface AdjustmentCSVProps {
  onClose: () => void;
  onUpload: () => void;
}

const AdjustmentCSVForm = (props: AdjustmentCSVProps) => {
  const { classes } = useStyles();

  const [bulkErrorMsg, setBulkErrorMsg] = useState<string | undefined>(
    undefined,
  );

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    setBulkErrorMsg(undefined);
  }, [file]);

  const sharedClasses = useSharedStyles().classes;

  const uploadFile = () => {
    if (file === undefined) {
      return;
    }

    setLoading(true);

    createAdjustmentCSV(file)
      .then(() => {
        toast.success(`adjustments uploaded!`);
        props.onUpload();
      })
      .catch((err) => {
        toast.error('Bulk adjustment upload failed.');
        setBulkErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box data-testid={adjustmentCSVUploadTestId}>
      <Waiting open={loading} />
      <UploadCSV setParentFile={setFile} />
      <BulkErrorDialog
        open={bulkErrorMsg !== undefined}
        message={bulkErrorMsg || ''}
        onClose={() => setBulkErrorMsg(undefined)}
      />
      <DialogActions>
        <Box component='div' className={classes.dialogActionsSpacer}>
          <Button
            onClick={() => downloadCsvTemplate(adjustmentsCsvTemplate)}
            className={sharedClasses.buttonText}
          >
            Download CSV Template for Adjustments
          </Button>
        </Box>
        <Button
          variant='contained'
          color={'inherit'}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={uploadFile}
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          disabled={file === undefined}
        >
          Add
        </Button>
      </DialogActions>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
}));

export default AdjustmentCSVForm;
