import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Card } from '@mui/material';
import { sharedColors } from '../utilities/Styles';
import Waiting from './Waiting';

const ErrorCard = ({ displayError }: { displayError: string | Error }) => (
  <Card
    style={{
      marginTop: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      color: sharedColors.statusRed,
    }}
  >
    <>{displayError}</>
  </Card>
);

const LoginCallback = (props: any): JSX.Element => {
  const { onAuthResume } = props;
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = React.useState<Error | null>(null);

  // @ts-ignore
  React.useEffect(() => {
    async function handleLoginCallback() {
      try {
        await oktaAuth.authStateManager.updateAuthState();
        if (onAuthResume && oktaAuth.idx.isInteractionRequired()) {
          onAuthResume();
          return;
        }
        // @ts-ignore
        if (authState != null && !authState.isAuthenticated) {
          await oktaAuth.handleLoginRedirect();
        }
      } catch (err) {
        // @ts-ignore
        setCallbackError(err);
      }
    }
    handleLoginCallback();
  }, [authState]);

  const displayError = callbackError?.message || authState?.error;

  return displayError ? (
    <ErrorCard displayError={displayError} />
  ) : (
    <Waiting open />
  );
};

export default LoginCallback;
