import getSymbolFromCurrency from 'currency-symbol-map';
import { Location, Metro } from '../interfaces/Location';
import { PayRateKind } from '../interfaces/PayRate';
import { FeatureToggleHistoryMap } from '../interfaces/FeatureToggle';

export const filterLocationById = (
  locations: Location[],
  id: number,
): Location | null => locations.find((location) => location.id === id) ?? null;

export const filterMetroById = (metros: Metro[], id: string): Metro | null =>
  metros.find((metro) => metro.id === id) ?? null;

export const getTimeZoneByLocationId = (
  locations: Location[],
  id: number,
): string | null => {
  const filteredLocation = filterLocationById(locations, id);
  return filteredLocation && filteredLocation.timeZone;
};

export const locationIdToName = (locations: Location[], id: number): string => {
  const location = filterLocationById(locations, id);
  return location ? location.name : id.toString();
};

export const metroIDToName = (metros: Metro[], id: string): string => {
  const metro = filterMetroById(metros, id);
  return metro?.name ?? id;
};

export const filterLocationByPayType = (
  locations: Location[],
  payType: PayRateKind,
  availableWaitPayLocations: string[] | null,
  waitAtMfcFeatureHistoryMap: FeatureToggleHistoryMap | null,
): Location[] => {
  switch (payType) {
    case PayRateKind.OVERTIME_PAY:
    case PayRateKind.REIMBURSEMENT_PAY:
      return locations.filter((location) => location.isW2Location);
    case PayRateKind.SUBSIDY:
      return locations.filter(
        (location) =>
          !Object.keys(waitAtMfcFeatureHistoryMap ?? {}).includes(
            location.id.toString(),
          ),
      );
    case PayRateKind.ENGAGED:
      return locations.filter((location) => location.isEngagedLocation);
    case PayRateKind.WAIT_PAY:
      return locations.filter((location) =>
        availableWaitPayLocations
          ? availableWaitPayLocations.includes(location.id.toString())
          : true,
      );
    default:
      return locations;
  }
};

export const getCurrencySymbolFromLocation = (
  location: Location | null,
): string => {
  const symbol = getSymbolFromCurrency(location ? location.currency : '');
  return symbol || '';
};

export const compareByCity = (a: Location, b: Location): number => {
  if (a.city) {
    if (b.city) {
      return a.city.localeCompare(b.city);
    }
    return -1;
  }
  return 1;
};

export const compareMetrosAlphabetically = (a: Metro, b: Metro): number =>
  (a.countryCode + (a.name ?? a.id)).localeCompare(
    b.countryCode + (b.name ?? b.id),
  );
