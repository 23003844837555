import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../atoms/Location';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { PushNotificationFormProps } from '../../interfaces/PushNotification/PushNotificationToCreate';
import { multiClass } from '../../utilities/Extensions';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';
import MultiMetroAutocomplete from '../MultiMetroAutocomplete';

const LocationInput = (props: PushNotificationFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const metros = useRecoilValue(metrosAtom);

  const handleChangeLocations = (newLocationIDs: string[]) => {
    props.setCurrentState({
      ...props.currentState,
      locationIDs: newLocationIDs,
    });
  };

  const handleChangeMetros = (newMetroIDs: string[]) => {
    props.setCurrentState({
      ...props.currentState,
      metroIDs: newMetroIDs,
    });
  };

  const handleToggleAlreadyActiveDrivers = () => {
    props.setCurrentState({
      ...props.currentState,
      includeAlreadyActiveDrivers:
        !props.currentState.includeAlreadyActiveDrivers,
    });
  };

  const isInvalid =
    isEmpty(props.currentState.locationIDs) &&
    isEmpty(props.currentState.metroIDs);

  return (
    <Box component='div' className={classes.body}>
      <Box
        component='div'
        className={multiClass([classes.dropdown, classes.locationAutocomplete])}
      >
        <MultiLocationAutocomplete
          label='Locations'
          locations={locations}
          selectedIDs={props.currentState.locationIDs}
          disabled={!isEmpty(props.currentState.metroIDs)}
          onSetSelectedIDs={handleChangeLocations}
          error={isInvalid}
        />
      </Box>
      <Box component='div' className={classes.dropdown}>
        <MultiMetroAutocomplete
          label='Metros'
          metros={metros}
          selectedIDs={props.currentState.metroIDs}
          disabled={!isEmpty(props.currentState.locationIDs)}
          onSetSelectedIDs={handleChangeMetros}
          error={isInvalid}
        />
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.currentState.includeAlreadyActiveDrivers}
            onChange={handleToggleAlreadyActiveDrivers}
          />
        }
        label={
          <Typography className={sharedClasses.subtitle2}>
            Include already active DPs
          </Typography>
        }
        className={classes.checkBox}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    dropdown: {
      marginLeft: theme.spacing(5),
    },
    checkBox: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
    },
    locationAutocomplete: {
      marginBottom: theme.spacing(1.5),
    },
  }),
);

export default LocationInput;
