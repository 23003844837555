import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Security } from '@okta/okta-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useRecoilState } from 'recoil';

import AppHeader from './components/AppHeader';
import LoginCallback from './components/LoginCallback';
import NotFound from './components/NotFound';

import { appBarHeight as appBarHeightAtom } from './atoms/PageState';
import { permissions as permissionsAtom } from './atoms/Users';

import oktaAuth, { getExpiredAtFromToken } from './services/okta';

import { getUserPermissions } from './variables/Users';
import { getPagesListByPermission } from './variables/Pages';

import './App.css';
import Waiting from './components/Waiting';
import { RequiredAuth } from './SecureRoute';

function App() {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [appBarHeight] = useRecoilState(appBarHeightAtom);
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setInterval(() => {
      const tokenExpiredAt = getExpiredAtFromToken();
      if (!shouldRender && tokenExpiredAt && tokenExpiredAt > new Date()) {
        setPermissions(getUserPermissions());
        setShouldRender(true);
      } else if (
        shouldRender &&
        (!tokenExpiredAt || tokenExpiredAt <= new Date())
      ) {
        setShouldRender(false);
      }
    }, 500);
  }, []);

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  const renderPage = (component: JSX.Element) => (
    <Box component='div' className={classes.app}>
      <Waiting open={!shouldRender} />
      {shouldRender && <AppHeader />}
      <Box component='div' style={{ height: appBarHeight }} />
      <Box component='div' className={classes.pageContainer}>
        {shouldRender && component}
      </Box>
    </Box>
  );

  const pages = getPagesListByPermission(permissions);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path='/login/callback' element={<LoginCallback />} />
        <Route element={<RequiredAuth />}>
          <Route path='/' element={<Navigate to={pages[0].path} />} />
          {pages.map((page) => (
            <Route
              path={`${page.path}/*`}
              element={renderPage(page.component)}
              key={page.name}
            />
          ))}
        </Route>
        <Route path='*' element={renderPage(<NotFound />)} />
      </Routes>
    </Security>
  );
}

const useStyles = makeStyles()((theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
  },
  pageContainer: {
    paddingLeft: theme.spacing(0),
  },
}));

export default App;
