import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { groupBy } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';

import { sharedColors } from '../../../utilities/Styles';
import { AdjustmentSubtype } from '../../../interfaces/AdjustmentSubtype';
import { newSubtypeSentinelId } from './NewAdjustmentSubtype';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';

interface AdjustmentSubtypeListProps {
  subtypes: AdjustmentSubtype[];
  onDelete: (subtype: AdjustmentSubtype) => void;
}

export const adjustmentTypeRowTestId = 'adjustment-type-row';
export const adjustmentSubtypeRowTestId = 'adjustment-subtype-row';
export const adjustmentSubtypeGroupTestIdFor = (type: string) =>
  `${type}-subtype-grouping`;
export const adjustmentSubtypeDeleteTestIdFor = (id: string) =>
  `${id}-subtype-delete`;

const AdjustmentSubtypeList = (props: AdjustmentSubtypeListProps) => {
  const { classes } = useStyles();

  const typesToSubtypeMap = groupBy(
    props.subtypes,
    (subtype: AdjustmentSubtype) => subtype.adjustmentType,
  );

  return (
    <List className={classes.adjustmentSubtypeList}>
      {Object.entries(typesToSubtypeMap).map(([type, subtypes]) => {
        return (
          <Box key={type} data-testid={adjustmentTypeRowTestId}>
            <ListItemButton className={classes.adjustmentTypeHeader}>
              <ListItemText primary={type} />
            </ListItemButton>
            <Collapse in={true}>
              <SubtypeGrouping
                subtypes={subtypes}
                parentType={type}
                onDelete={props.onDelete}
              />
            </Collapse>
          </Box>
        );
      })}
    </List>
  );
};

const SubtypeGrouping = (props: {
  subtypes: AdjustmentSubtype[];
  parentType: string;
  onDelete: (subtype: AdjustmentSubtype) => void;
}) => {
  const [deletedSubtypes, setDeletedSubtypes] = useState<string[]>([]);
  const onDeleteForSubtype = (subtype: AdjustmentSubtype) => {
    setDeletedSubtypes(deletedSubtypes.concat(subtype.id));
    props.onDelete(subtype);
  };

  const displayedSubtypes = props.subtypes.filter(
    (subtype) => !deletedSubtypes.includes(subtype.id),
  );

  return (
    <List data-testid={adjustmentSubtypeGroupTestIdFor(props.parentType)}>
      {displayedSubtypes.map((subtype) => {
        return (
          <ListItem
            key={subtype.id}
            secondaryAction={
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => onDeleteForSubtype(subtype)}
                data-testid={adjustmentSubtypeDeleteTestIdFor(subtype.id)}
              >
                <DeleteIcon />
              </IconButton>
            }
            data-testid={adjustmentSubtypeRowTestId}
          >
            <IconButton
              autoFocus={
                subtype.id === newSubtypeSentinelId(subtype.description)
              }
            >
              <DragIndicatorIcon />
            </IconButton>
            <ListItemText
              primary={subtype.description}
              secondary={`Requires approval over ${parseCentsAsCurrencyString(
                subtype.thresholdAmount,
              )}`}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles()(() => ({
  adjustmentTypeHeader: {
    background: sharedColors.gray2,
  },
  adjustmentSubtypeList: {
    padding: '8px 0px',
    top: '10px',
    background: sharedColors.white,
    border: `1px solid #EEEEEE`,
    borderRadius: '4px',
  },
}));

export default AdjustmentSubtypeList;
