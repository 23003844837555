import {
  ShiftWithEventsBreakdown,
  shiftWithEventsBreakdownFromResponse,
} from './ShiftWithEventsBreakdown';

export interface WaitPayBreakdown {
  locationName: string;
  totalWaitSeconds: number;
  roundedTotalWaitMinutes: number;
  minGuaranteeMinuteRate: number;
  shiftWithEvents: ShiftWithEventsBreakdown;
  isCalculatedWeekly: boolean;
  amount: number;
}

export const waitPayBreakdownFromResponse = (
  responseData: any,
): WaitPayBreakdown => ({
  locationName: responseData.location_name,
  totalWaitSeconds: responseData.total_wait_seconds ?? 0,
  roundedTotalWaitMinutes: responseData.rounded_total_wait_minutes ?? 0,
  minGuaranteeMinuteRate: responseData.min_guarantee_minute_rate ?? 0,
  shiftWithEvents: shiftWithEventsBreakdownFromResponse(
    responseData?.shift_with_events ?? {},
  ),
  isCalculatedWeekly: !!responseData.is_calculated_weekly,
  amount: responseData.amount ?? 0,
});
