import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { toast } from 'material-react-toastify';
import { DateTime } from 'luxon';
import { Location } from '../../interfaces/Location';
import {
  ModifierDialogTabKind,
  UploadDialogTab,
} from '../../interfaces/Common';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { csvMimeTypes } from '../../utilities/Csv';
import ConfirmInclusionListModificationDialog from './ConfirmInclusionListModificationDialog';
import {
  FeatureToggleExpireRequest,
  FeatureToggleInsertRequest,
  InclusionListExpirationOptions,
  InclusionListInsertionOptions,
} from '../../interfaces/FeatureToggle';
import InsertFeatureToggleForm from './InsertFeatureToggleForm';
import {
  getDefaultInclusionListExpirationOptions,
  getDefaultInclusionListInsertionOptions,
} from '../../variables/FeatureToggle';
import ExpireFeatureToggleForm from './ExpireFeatureToggleForm';
import { filterLocationById } from '../../utilities/Locations';
import { convertLocalToDeliveryZone } from '../../utilities/Dates';
import {
  expireFeatureToggles,
  insertFeatureToggles,
} from '../../services/featureToggle';

const tabs: UploadDialogTab[] = [
  {
    id: ModifierDialogTabKind.INSERT,
    name: 'Insert',
  },
  {
    id: ModifierDialogTabKind.REMOVE,
    name: 'Remove',
  },
];

interface ModifyInclusionListDialogProps {
  open: boolean;
  locations: Location[];
  featureName: string;
  isLocationType: boolean;
  onClose: () => void;
  onDone: () => void;
}

const ModifyInclusionListDialog = (props: ModifyInclusionListDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [selectedTab, setSelectedTab] = useState<ModifierDialogTabKind>(
    ModifierDialogTabKind.INSERT,
  );
  const [loading, setLoading] = useState(false);
  const [file, setCurrentFile] = useState<File | null>(null);
  const [includedValues, setIncludedValues] = useState([] as string[]);
  const [textValues, setTextValues] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [insertionOptions, setInsertionOptions] =
    useState<InclusionListInsertionOptions>(
      getDefaultInclusionListInsertionOptions(props.isLocationType),
    );
  const [expirationOptions, setExpirationOptions] =
    useState<InclusionListExpirationOptions>(
      getDefaultInclusionListExpirationOptions(),
    );

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = (e.target?.result ?? '') as string;
        setIncludedValues(text.split('\n'));
        setTextValues(text);
      };
      reader.onerror = () => toast.error('Could not read the input file');

      reader.readAsText(file);
    } else {
      setIncludedValues([]);
      setTextValues('');
    }
  }, [file]);

  useEffect(() => {
    setInsertionOptions(
      getDefaultInclusionListInsertionOptions(props.isLocationType),
    );
    setExpirationOptions(getDefaultInclusionListExpirationOptions());
  }, [props.open]);

  const handleInsertFeatureToggles = async (): Promise<void> => {
    const requests: FeatureToggleInsertRequest[] = includedValues.map(
      (includedValue) => {
        let timezone = 'UTC';
        if (props.isLocationType && insertionOptions.isLocalTime) {
          const location = filterLocationById(
            props.locations,
            parseInt(includedValue, 10),
          );

          if (location) {
            timezone = location.timeZone;
          }
        }

        const startAt = DateTime.fromJSDate(insertionOptions.startAt);
        const endAt = DateTime.fromJSDate(insertionOptions.endAt);

        return {
          featureName: props.featureName,
          includedValue,
          startAt: convertLocalToDeliveryZone(
            timezone,
            startAt,
            startAt.hour,
            startAt.minute,
          ).toJSDate(),
          endAt: insertionOptions.setEndAt
            ? convertLocalToDeliveryZone(
                timezone,
                endAt,
                endAt.hour,
                endAt.minute,
              ).toJSDate()
            : undefined,
        };
      },
    );

    return insertFeatureToggles(requests);
  };

  const handleExpireFeatureToggles = async (): Promise<void> => {
    const requests: FeatureToggleExpireRequest[] = includedValues.map(
      (includedValue) => {
        let timezone = 'UTC';
        if (
          props.isLocationType &&
          expirationOptions.isLocalTime &&
          !expirationOptions.expireNow
        ) {
          const location = filterLocationById(
            props.locations,
            parseInt(includedValue, 10),
          );

          if (location) {
            timezone = location.timeZone;
          }
        }

        const expireAt = DateTime.fromJSDate(expirationOptions.expireAt);

        return {
          featureName: props.featureName,
          includedValue,
          expireAt: expirationOptions.expireNow
            ? undefined
            : convertLocalToDeliveryZone(
                timezone,
                expireAt,
                expireAt.hour,
                expireAt.minute,
              ).toJSDate(),
          expireNow: expirationOptions.expireNow,
        };
      },
    );

    return expireFeatureToggles(requests);
  };

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: ModifierDialogTabKind,
  ) => {
    setSelectedTab(newValue);
  };

  const handleFile = (files: File[]) => {
    setCurrentFile(files.length === 0 ? null : files[0]);
  };

  const handleTextChange = (e: any) => {
    setTextValues(e.target.value);
  };

  const handleSubmit = () => {
    setShowConfirmationDialog(false);
    setLoading(true);

    let promise: Promise<void>;
    if (selectedTab === ModifierDialogTabKind.INSERT) {
      promise = handleInsertFeatureToggles();
    } else {
      promise = handleExpireFeatureToggles();
    }

    promise
      .then(() => {
        toast.success('Successfully modified the inclusion list');
        props.onDone();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const renderForm = () => {
    switch (selectedTab) {
      case ModifierDialogTabKind.INSERT:
        return (
          <InsertFeatureToggleForm
            isLocationType={props.isLocationType}
            options={insertionOptions}
            onOptionsChange={(newOptions) => setInsertionOptions(newOptions)}
          />
        );
      case ModifierDialogTabKind.REMOVE:
        return (
          <ExpireFeatureToggleForm
            isLocationType={props.isLocationType}
            options={expirationOptions}
            onOptionsChange={(newOptions) => setExpirationOptions(newOptions)}
          />
        );
      default:
        return <Box />;
    }
  };

  const splitTextValues = textValues.split(/[,\s\n]+/);
  const filteredSplitTextValues = splitTextValues.filter(
    (value) => value !== '',
  );

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <Waiting open={loading} />
      <ConfirmInclusionListModificationDialog
        open={showConfirmationDialog}
        includedValues={includedValues}
        modificationKind={selectedTab}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={handleSubmit}
      />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Modify Inclusion List
      </DialogTitle>
      <DialogContent>
        <Box className={classes.tabsContainer}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {tabs.map((tab) => (
              <Tab
                label={
                  <Typography className={classes.tabLabel}>
                    {tab.name}
                  </Typography>
                }
                key={tab.name}
              />
            ))}
          </Tabs>
        </Box>
        {tabs
          .filter((tab) => tab.id === selectedTab)
          .map((tab) => (
            <Box
              role='tabpanel'
              id={`simple-tabpanel-${tab.id}`}
              aria-labelledby={`simple-tab-${tab.id}`}
              key={tab.id}
            >
              <Box component='div' className={classes.uploadWrapper}>
                <FormControl
                  component='fieldset'
                  className={classes.uploadGrid}
                >
                  <DropzoneArea
                    dropzoneClass={classes.dropzone}
                    acceptedFiles={[csvMimeTypes, '.txt', 'text/plain']}
                    filesLimit={1}
                    showFileNames
                    onChange={handleFile}
                  />
                </FormControl>
                <TextField
                  label={`Included Values (${filteredSplitTextValues.length.toString()})`}
                  multiline
                  rows={Math.min(
                    5,
                    Math.max(1, (textValues.match(/\n/g) ?? []).length + 1),
                  )}
                  value={textValues}
                  onChange={handleTextChange}
                  onBlur={() => setIncludedValues(filteredSplitTextValues)}
                  size='small'
                  disabled={!!file}
                  className={classes.textField}
                />
              </Box>
              {renderForm()}
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Box component='div' className={classes.dialogActionsSpacer} />
        <Button
          variant='contained'
          color={'inherit'}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setShowConfirmationDialog(true)}
          className={sharedClasses.buttonText}
          disabled={includedValues.length === 0}
        >
          {tabs[selectedTab].name}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  tabsContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    background: sharedColors.gray1,
  },
  tabLabel: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  uploadWrapper: {
    paddingTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  uploadGrid: {
    display: 'flex',
    flexGrow: 1,
  },
  dropzone: {
    position: 'relative',
    padding: theme.spacing(2),
    minHeight: '80px',
    backgroundColor: sharedColors.gray2,
    border: 'dashed',
    borderColor: sharedColors.gray3,
    cursor: 'pointer',
  },
  textField: {
    marginTop: theme.spacing(1.5),
  },
}));

export default ModifyInclusionListDialog;
