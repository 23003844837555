import { useRecoilValue } from 'recoil';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { restrictionTypes as restrictionTypesAtom } from '../../../atoms/RestrictedAccounts';
import { ManualRestrictedAccountToAdd } from '../../../interfaces/RestrictedAccount';
import { defaultRestrictedAccountToAdd } from '../../../variables/RestrictedAccount';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { requiredErrorMessage } from '../../../utilities/Constants';

interface NewManualRestrictedAccountProps {
  setParentAccount: (account: ManualRestrictedAccountToAdd) => void;
}

const NewManualRestrictedAccount = (props: NewManualRestrictedAccountProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const restrictionTypes = useRecoilValue(restrictionTypesAtom);

  const [account, setAccount] = useState(
    defaultRestrictedAccountToAdd(restrictionTypes),
  );

  const handleDriverIdChange = (event: any) => {
    const id = event.target.value;
    const newAccount = { ...account, DriverID: id.trim() };
    setAccount(newAccount);
    props.setParentAccount(newAccount);
  };

  const handleSelectRestrictionType = (e: any) => {
    const selectedRestrictionType = e.target.value as string;
    const newAccount = { ...account, RestrictionType: selectedRestrictionType };
    setAccount(newAccount);
    props.setParentAccount(newAccount);
  };

  return (
    <Box component='div' className={classes.contentWrapper}>
      <DialogContent>
        <Grid container spacing={2} className={classes.contentGrid}>
          <Grid item xs={6} className={classes.gridItem}>
            <TextValidator
              label='Driver Id'
              size='small'
              variant='outlined'
              value={account.DriverID}
              onChange={handleDriverIdChange}
              className={classes.gridItem}
              validators={['required']}
              errorMessages={[requiredErrorMessage]}
              name={'DriverId'}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl component='fieldset'>
              <FormLabel>
                <Typography className={sharedClasses.caption}>
                  Restriction Type
                </Typography>
              </FormLabel>
              <RadioGroup
                value={account.RestrictionType}
                onChange={handleSelectRestrictionType}
              >
                {restrictionTypes.map((restrictionType) => (
                  <FormControlLabel
                    key={restrictionType}
                    value={restrictionType}
                    control={<Radio id={restrictionType} color='primary' />}
                    label={
                      <Typography className={sharedClasses.body1}>
                        {restrictionType}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  contentWrapper: {
    flexGrow: 1,
  },
  contentGrid: {
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    color: sharedColors.gray6,
  },
  gridItem: {
    flexGrow: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  input: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default NewManualRestrictedAccount;
