export const max2DecimalsRegexp = '^-?[0-9]+(.[0-9]{1,2})?$';
export const stringTextFieldMaxChars = 120;

export const requiredErrorMessage = 'Field is required';
export const isPositiveErrorMessage = 'Value must be positive';
export const maxStringLengthErrorMessage = `Maximum ${stringTextFieldMaxChars} characters allowed!`;
export const max2DecimalErrorMessage = 'Max 2 decimal is allowed';
export const isIntegerErrorMessage = 'Value must be integer';

export const featureToggleTypeLocation = 'location';

export const int32Max = 2147483647;
