import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { TargetCommissionOverview } from './TargetCommissionOverview';
import { targetCommissionToEditAtom } from '../../atoms/TargetCommissions';
import { TargetCommission } from '../../interfaces/TargetCommissions';

type ConfirmPayRateProps = {
  editedTargetCommission: TargetCommission;
};

export const confirmTargetCommissionContainerTestId =
  'confirmTargetCommissionContainer';

export const ConfirmTargetCommission = (props: ConfirmPayRateProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const targetCommissionToEdit = useRecoilValue(targetCommissionToEditAtom);

  return (
    <Box
      component='div'
      className={classes.contentWrapper}
      data-testid={confirmTargetCommissionContainerTestId}
    >
      <Box component='div' className={classes.row}>
        <Card
          className={multiClass([
            classes.differenceCard,
            classes.leftDifferenceCard,
          ])}
        >
          <CardHeader
            title={
              <Typography className={sharedClasses.subtitle2}>
                {`Current Target Commission`}
              </Typography>
            }
            className={multiClass([
              classes.cardHeaderContainer,
              classes.futureRateColor,
            ])}
          />
          <Divider />
          <CardContent className={classes.cardContentContainer}>
            <TargetCommissionOverview
              targetCommission={targetCommissionToEdit!}
            />
          </CardContent>
        </Card>
        <Card className={classes.differenceCard}>
          <CardHeader
            title={
              <Typography className={sharedClasses.subtitle2}>
                {`Updated Target Commission`}
              </Typography>
            }
            className={multiClass([
              classes.cardHeaderContainer,
              classes.futureRateColor,
            ])}
          />
          <Divider />
          <CardContent className={classes.cardContentContainer}>
            <TargetCommissionOverview
              targetCommission={props.editedTargetCommission}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginTop: theme.spacing(1.5),
    },
    infoBoxIcon: {
      color: sharedColors.blue4,
    },
    replacementWarningBox: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
      backgroundColor: sharedColors.blue1,
    },
    replacementWarningText: {
      display: 'flex',
      flexDirection: 'row',
      color: sharedColors.blue7,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(0.5),
    },
    replacementWarningBold: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(0.5),
    },
    differenceCard: {
      width: '50%',
    },
    cardHeaderContainer: {
      padding: theme.spacing(1),
    },
    cardContentContainer: {
      padding: theme.spacing(1),
    },
    leftDifferenceCard: {
      marginRight: theme.spacing(2),
    },
    currentRateColor: {
      backgroundColor: sharedColors.gray1,
    },
    immediateRateColor: {
      backgroundColor: sharedColors.blue1,
    },
    futureRateColor: {
      backgroundColor: sharedColors.purple1,
    },
  }),
);
