import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { AdjustmentSummary } from '../../../../interfaces/PayHistory/AdjustmentSummary';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import SinglePayoutNode from '../SinglePayoutNode';
import {
  adjustmentNoteNodeID,
  adjustmentSubtypeNodeID,
  adjustmentTypeNodeID,
  amountNodeID,
  composeNodeID,
} from '../../../../variables/PayHistory';
import SingleTextNode from '../SingleTextNode';

interface AdjustmentNodeProps {
  id: string;
  index: number;
  adjustment: AdjustmentSummary;
  currency: string;
}

const AdjustmentNode = (props: AdjustmentNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.index.toString()} {${Object.keys(
            props.adjustment,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SinglePayoutNode
        id={composeNodeID([props.id, amountNodeID])}
        name='Amount'
        amount={props.adjustment.amount}
        currency={props.currency}
      />
      <SingleTextNode
        id={composeNodeID([props.id, adjustmentTypeNodeID])}
        name='Type'
        value={props.adjustment.type}
      />
      <SingleTextNode
        id={composeNodeID([props.id, adjustmentSubtypeNodeID])}
        name='Subtype'
        value={props.adjustment.subtype}
      />
      <SingleTextNode
        id={composeNodeID([props.id, adjustmentNoteNodeID])}
        name='Note'
        value={props.adjustment.note}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default AdjustmentNode;
