import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'material-react-toastify';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'material-react-toastify/dist/ReactToastify.css';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <CacheProvider value={muiCache}>
    <React.StrictMode>
      <RecoilRoot>
        <Router>
          <ThemeProvider theme={createTheme()}>
            <App />
            <ToastContainer
              position='top-center'
              autoClose={6000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover
            />
          </ThemeProvider>
        </Router>
      </RecoilRoot>
    </React.StrictMode>
  </CacheProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
