import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { WaitPayBreakdown } from '../../../../interfaces/PayHistory/WaitPayBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  amountNodeID,
  composeNodeID,
  roundedTotalWaitMinutesNodeID,
  shiftsWithEventsNodeID,
  totalWaitSecondsNodeID,
  waitPayIsCalculatedWeeklyNodeID,
  waitPayMinGuaranteeMinuteRateNodeID,
} from '../../../../variables/PayHistory';
import SinglePayoutNode from '../SinglePayoutNode';
import ShiftWithEventsBreakdownNode from './ShiftWithEventsBreakdownNode';
import SingleBooleanNode from '../SingleBooleanNode';
import { parseCentsAsCurrencyString } from '../../../../utilities/Currency';

interface WaitPayBreakdownNodeProps {
  id: string;
  waitPayBreakdown: WaitPayBreakdown;
  displayShifts: boolean;
  currency: string;
  timezone: string;
}

const WaitPayBreakdownNode = (props: WaitPayBreakdownNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${`${
          props.waitPayBreakdown.locationName
        } : ${parseCentsAsCurrencyString(
          props.waitPayBreakdown.amount,
          props.currency,
        )}`}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, totalWaitSecondsNodeID])}
        name='Total Wait Seconds'
        value={props.waitPayBreakdown.totalWaitSeconds.toString()}
      />
      <SingleTextNode
        id={composeNodeID([props.id, roundedTotalWaitMinutesNodeID])}
        name='Rounded Total Wait Minutes'
        value={props.waitPayBreakdown.roundedTotalWaitMinutes.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, waitPayMinGuaranteeMinuteRateNodeID])}
        name='Rate'
        amount={props.waitPayBreakdown.minGuaranteeMinuteRate}
        unit='min'
        currency={props.currency}
      />

      {props.displayShifts && (
        <ShiftWithEventsBreakdownNode
          id={composeNodeID([props.id, shiftsWithEventsNodeID])}
          shiftWithEventsBreakdown={props.waitPayBreakdown.shiftWithEvents}
          timezone={props.timezone}
        />
      )}
      {props.displayShifts && props.waitPayBreakdown.isCalculatedWeekly && (
        <SingleBooleanNode
          id={composeNodeID([props.id, waitPayIsCalculatedWeeklyNodeID])}
          name='Is Calculated Weekly'
          value={true}
        />
      )}

      <SinglePayoutNode
        id={composeNodeID([props.id, amountNodeID])}
        name='Amount'
        amount={props.waitPayBreakdown.amount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default WaitPayBreakdownNode;
