import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Grid, Paper, Typography } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Mission } from '../../interfaces/Mission/Mission';
import { MissionRewardTierType } from '../../interfaces/Mission/MissionReward';
import { useSharedStyles } from '../../utilities/Styles';

interface TieredBonusDetailsProps {
  mission: Mission;
}

const TieredBonusDetails = (props: TieredBonusDetailsProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const tierName =
    props.mission.reward.tierType === MissionRewardTierType.TRIP
      ? 'Trip'
      : 'Order';

  return (
    <Paper>
      <Box component='div' className={classes.body}>
        <Grid container spacing={1.5}>
          {props.mission.reward.tiers.map((tier) => [
            <Grid item xs={6} className={classes.gridItem}>
              <Typography className={classes.tierLabel}>
                {`${tier.count} ${tierName}${tier.count > 1 ? 's' : ''}`}
              </Typography>
            </Grid>,
            <Grid item xs={6} className={classes.gridItem}>
              <Typography className={sharedClasses.subtitle2}>
                {getSymbolFromCurrency(props.mission.reward.currency ?? 'USD') +
                  tier.pay.toFixed(2)}
              </Typography>
            </Grid>,
          ])}
        </Grid>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      padding: theme.spacing(1.5),
      width: 170,
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    tierLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '11px',
      lineHeight: '13px',
    },
  }),
);

export default TieredBonusDetails;
