import { isArray } from 'lodash';
import api from './api';
import { Location, Metro } from '../interfaces/Location';

const locationsUrl = '/location';

export const getLocations = async (): Promise<Location[]> => {
  const response = await api.get(locationsUrl);
  return response.data.locations.map(
    (location: any) =>
      <Location>{
        id: location.id,
        name: location.name,
        friendlyName: location.friendly_name,
        countryCode: location.country_code,
        timeZone: location.time_zone,
        state: location.state || '',
        metroID: location.metro_id,
        city: location.city || '',
        currency: location.currency,
        isW2Location: location.is_w2_location,
        isEngagedLocation: location.is_engaged_location ?? false,
      },
  );
};

export const getMetros = async (): Promise<Metro[]> => {
  const response = await api.get(`${locationsUrl}/metros`);
  if (!isArray(response.data.metros)) {
    throw new Error('Unexpected response: metros is not an array');
  }

  return response.data.metros.map(
    (metro: any): Metro => ({
      id: metro.id,
      name: metro.name,
      countryCode: metro.country_code,
    }),
  );
};
