export const isValidNumber = (num: string) =>
  !(Number.isNaN(num) || num === '');

export const decimalCount = (num: number) => {
  if (Number.isInteger(num)) {
    return 0;
  }

  return num.toString().split('.')[1].length;
};

export const preciseCentsToAmount = (
  cents: number,
  precision: number,
): number => cents / 10 ** precision;

export const amountToPreciseCents = (
  amount: number,
  precision: number,
): number => Math.round(amount * 10 ** precision);
