import { Box, Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { selectPayTypeInfoBoxMessageTestId } from './NewPayRateDialog/Steps/SelectPayType';

type InfoBoxProps = {
  title: string;
  message: JSX.Element;
  classes: any;
  severity: 'error' | 'info';
};

export const InfoBox = (props: InfoBoxProps) => (
  <Paper elevation={0} className={props.classes.infoBoxContainer}>
    {props.severity === 'error' ? (
      <ErrorIcon fontSize='large' className={props.classes.infoBoxIcon} />
    ) : (
      <InfoOutlinedIcon
        fontSize='large'
        className={props.classes.infoBoxIcon}
      />
    )}
    <Box component='div' className={props.classes.infoBoxTextWrapper}>
      <Typography className={props.classes.infoBoxTitle}>
        {props.title}
      </Typography>
      <Typography
        className={props.classes.infoBoxMessage}
        data-testid={selectPayTypeInfoBoxMessageTestId}
      >
        {props.message}
      </Typography>
    </Box>
  </Paper>
);
