import { PayoutProvider } from './PayoutProvider';

export interface PayoutSupportSummary {
  startedAt?: Date;
  totalAmount: number;
  weeklyAmount: number;
  subtotalAmount: number;
  weeklySubtotalAmount: number;
  externalAccount: string;
  type: string;
  feeAmount: number;
  feeIncluded: boolean;
  payoutProvider: PayoutProvider;
}

export const payoutSummaryFromResponse = (
  responseData: any,
): PayoutSupportSummary => ({
  startedAt: responseData.started_at && new Date(responseData.started_at),
  totalAmount: responseData.total_amount ?? 0,
  weeklyAmount: responseData.weekly_amount ?? 0,
  subtotalAmount: responseData.subtotal_amount ?? 0,
  weeklySubtotalAmount: responseData.weekly_subtotal_amount ?? 0,
  externalAccount: responseData.external_account ?? '-',
  type: responseData.type ?? '-',
  feeAmount: responseData.fee_amount ?? 0,
  feeIncluded: responseData.fee_included ?? false,
  payoutProvider: responseData.payout_provider,
});
