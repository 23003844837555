import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';

import { locations as locationsAtom } from '../../../atoms/Location';
import { OvertimePay, PayRateOverviewProps } from '../../../interfaces/PayRate';
import {
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import { formatDateTime } from '../../../utilities/Dates';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';

export const OvertimePayOverview = (props: PayRateOverviewProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const overtimePay = props.payRate as OvertimePay;

  const location = filterLocationById(
    locations,
    parseInt(overtimePay.locationID, 10),
  );
  const timeZone =
    getTimeZoneByLocationId(locations, parseInt(overtimePay.locationID, 10)) ||
    'UTC';

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start Date
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(overtimePay.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <Typography
          className={multiClass([sharedClasses.overline, classes.fieldName])}
        >
          Hours
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          className={multiClass([sharedClasses.overline, classes.fieldName])}
        >
          Multiplier
        </Typography>
      </Grid>
      {overtimePayOverviewTable.map((row) => [
        <Grid item xs={3} key={row.firstColumn}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            {row.firstColumn}
          </Typography>
        </Grid>,
        <Grid item xs={3} key={row.hours}>
          <Typography className={sharedClasses.body1}>{row.hours}</Typography>
        </Grid>,
        <Grid item xs={4} key={row.multiplier}>
          <Typography className={sharedClasses.body1}>
            {overtimePay[row.fieldName as keyof OvertimePay]
              ? `${(
                  overtimePay[row.fieldName as keyof OvertimePay] as number
                ).toFixed(2)}x`
              : `${(1).toFixed(2)}x`}
          </Typography>
        </Grid>,
      ])}
    </Grid>
  );
};

const overtimePayOverviewTable: any[] = [
  {
    firstColumn: 'Daily',
    hours: 8,
    fieldName: 'eightHoursMultiplier',
  },
  {
    firstColumn: 'Daily',
    hours: 12,
    fieldName: 'twelveHoursMultiplier',
  },
  {
    firstColumn: 'Weekly',
    hours: 40,
    fieldName: 'fortyHoursMultiplier',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
