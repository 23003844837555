import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { multiClass } from '../utilities/Extensions';
import { sharedColors } from '../utilities/Styles';

interface StringFieldProps {
  value: string;
  unit?: string;
  italic?: boolean;
  disabled?: boolean;
}

const StringField = (props: StringFieldProps) => {
  const classes = useStyles();

  return (
    <Typography
      component={'div'}
      className={multiClass([
        classes.stringField,
        props.italic ? classes.fontStyleItalic : classes.fontStyleNormal,
        props.disabled
          ? classes.disabledStringField
          : classes.enabledStringField,
      ])}
    >
      {props.value}
      {props.unit && (
        <Box
          component='div'
          className={multiClass([
            classes.fieldUnit,
            props.disabled
              ? classes.disabledStringField
              : classes.enabledUnitField,
          ])}
        >
          {props.unit}
        </Box>
      )}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stringField: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '32px',
      flexDirection: 'row',
      display: 'flex',
    },
    fontStyleNormal: {
      fontStyle: 'normal',
    },
    fontStyleItalic: {
      fontStyle: 'italic',
    },
    fieldUnit: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(0.5),
    },
    disabledStringField: {
      color: theme.palette.text.disabled,
    },
    enabledStringField: {
      color: sharedColors.gray6,
    },
    enabledUnitField: {
      color: sharedColors.gray5,
    },
  }),
);

export default StringField;
