import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { flatten } from 'lodash';
import { WeeklyBonusBreakdown } from '../../../../interfaces/PayHistory/WeeklyBonusBreakdown';
import {
  allWeeklyBonusLocationsNodeID,
  amountNodeID,
  applicableWeeklyBonusLocationNodeID,
  composeNodeID,
  tierXAmountNodeIDGenerator,
  tierXOrdersNodeIDGenerator,
  weeklyBonusNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import { useSharedStyles } from '../../../../utilities/Styles';
import SingleTextNode from '../SingleTextNode';
import WeeklyBonusLocationNode from './WeeklyBonusLocationNode';
import SinglePayoutNode from '../SinglePayoutNode';

interface WeeklyBonusNodeProps {
  weeklyBonusBreakdown?: WeeklyBonusBreakdown;
  currency: string;
}

const WeeklyBonusNode = (props: WeeklyBonusNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return props.weeklyBonusBreakdown ? (
    <TreeItem
      nodeId={weeklyBonusNodeID}
      label={
        <Typography
          className={multiClass([sharedClasses.body1, classes.label])}
        >
          {`Weekly Bonus Breakdown {${Object.keys(
            props.weeklyBonusBreakdown,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <WeeklyBonusLocationNode
        id={composeNodeID([
          weeklyBonusNodeID,
          applicableWeeklyBonusLocationNodeID,
        ])}
        name='Applicable Weekly Bonus Location'
        weeklyBonusLocation={
          props.weeklyBonusBreakdown.applicableWeeklyBonusLocation
        }
      />
      <TreeItem
        nodeId={composeNodeID([
          weeklyBonusNodeID,
          allWeeklyBonusLocationsNodeID,
        ])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {`All Weekly Bonus Locations [${props.weeklyBonusBreakdown.allWeeklyBonusLocations.length.toString()}]`}
          </Typography>
        }
      >
        {props.weeklyBonusBreakdown.allWeeklyBonusLocations.map(
          (weeklyBonusLocation, i) => (
            <WeeklyBonusLocationNode
              id={composeNodeID([
                weeklyBonusNodeID,
                allWeeklyBonusLocationsNodeID,
                i.toString(),
              ])}
              name={i.toString()}
              weeklyBonusLocation={weeklyBonusLocation}
            />
          ),
        )}
      </TreeItem>
      {flatten(
        [1, 2, 3, 4].map((tier) => [
          <SingleTextNode
            id={composeNodeID([
              weeklyBonusNodeID,
              tierXOrdersNodeIDGenerator(tier),
            ])}
            name={`Tier ${tier.toString()} Orders`}
            value={props.weeklyBonusBreakdown![
              `tier${tier.toString()}Orders` as keyof WeeklyBonusBreakdown
            ].toString()}
          />,
          <SinglePayoutNode
            id={composeNodeID([
              weeklyBonusNodeID,
              tierXAmountNodeIDGenerator(tier),
            ])}
            name={`Tier ${tier.toString()} Amount`}
            amount={
              props.weeklyBonusBreakdown![
                `tier${tier.toString()}Amount` as keyof WeeklyBonusBreakdown
              ] as number
            }
            currency={props.currency}
          />,
        ]),
      )}
      <SinglePayoutNode
        id={composeNodeID([weeklyBonusNodeID, amountNodeID])}
        name='Amount'
        amount={props.weeklyBonusBreakdown.amount}
        currency={props.currency}
      />
    </TreeItem>
  ) : (
    <SingleTextNode
      id={weeklyBonusNodeID}
      name='Weekly Bonus Breakdown'
      value='null'
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default WeeklyBonusNode;
