import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { EngagedSubsidyBreakdown } from '../../../../interfaces/PayHistory/EngagedSubsidyBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  amountNodeID,
  basePayNodeID,
  boostPayNodeID,
  composeNodeID,
  engagedHoursNodeID,
  engagedLocationNameNodeID,
  engagedSubsidyRateNodeID,
  engagedWeeklyBonusPayNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import SinglePayoutNode from '../SinglePayoutNode';

interface EngagedSubsidyNodeProps {
  id: string;
  name: string;
  engagedSubsidyBreakdown: EngagedSubsidyBreakdown;
  currency: string;
}

const EngagedSubsidyNode = (props: EngagedSubsidyNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.name} {${Object.keys(
            props.engagedSubsidyBreakdown,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, engagedLocationNameNodeID])}
        name='Location Name'
        value={props.engagedSubsidyBreakdown.locationName}
      />
      <SingleTextNode
        id={composeNodeID([props.id, engagedHoursNodeID])}
        name='Engaged Hours'
        value={props.engagedSubsidyBreakdown.engagedHours.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, engagedSubsidyRateNodeID])}
        name='Subsidy Rate'
        amount={props.engagedSubsidyBreakdown.subsidyRate}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, basePayNodeID])}
        name='Base Pay'
        amount={props.engagedSubsidyBreakdown.basePay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, boostPayNodeID])}
        name='Special Pay'
        amount={props.engagedSubsidyBreakdown.specialPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, engagedWeeklyBonusPayNodeID])}
        name='Weekly Bonus Pay'
        amount={props.engagedSubsidyBreakdown.weeklyBonusPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, amountNodeID])}
        name='Amount'
        amount={props.engagedSubsidyBreakdown.amount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default EngagedSubsidyNode;
