import React, { useState } from 'react';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { parseInt } from 'lodash';
import { locations as locationsAtom } from '../../atoms/Location';
import { formatDateTime } from '../../utilities/Dates';
import { multiClass } from '../../utilities/Extensions';
import { PayRateStatus } from '../../interfaces/PayRate';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  filterLocationById,
  getCurrencySymbolFromLocation,
  getTimeZoneByLocationId,
} from '../../utilities/Locations';
import StringField from '../StringField';
import { TargetCommission } from '../../interfaces/TargetCommissions';
import { targetCommissionToEditAtom } from '../../atoms/TargetCommissions';
import StatusField from '../StatusField';

export const targetCommissionTableHeaderTestId = 'TargetCommissionTableHeader';

interface TargetCommissionTableProps {
  rows: TargetCommission[];
}

const TargetCommissionsTable = (
  props: TargetCommissionTableProps,
): JSX.Element => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const setTargetCommissionToEdit = useSetRecoilState(
    targetCommissionToEditAtom,
  );
  const locations = useRecoilValue(locationsAtom);

  const handleClickRow = (row: TargetCommission) => {
    setTargetCommissionToEdit(row);
  };

  return (
    <Box component='div' className={classes.outerContainer}>
      <Typography
        className={multiClass([sharedClasses.h5, classes.title])}
        data-testid={targetCommissionTableHeaderTestId}
      >
        {'Target Commissions'}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  MFC Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Start Date (MFC Local)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  End Date (MFC Local)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Suggested Commission
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Edited Commission
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Last Updated By
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row) => {
                const timeZone =
                  getTimeZoneByLocationId(
                    locations,
                    parseInt(row.locationID, 10),
                  ) || 'UTC';

                const isEditable = row.status === PayRateStatus.FUTURE;

                return (
                  <TableRow
                    key={row.id}
                    onClick={isEditable ? () => handleClickRow(row) : undefined}
                    className={isEditable ? classes.editableRow : undefined}
                  >
                    <TableCell>
                      <StringField
                        value={
                          filterLocationById(
                            locations,
                            parseInt(row.locationID, 10),
                          )?.name || '-'
                        }
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={formatDateTime(row.startAt, timeZone)}
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={formatDateTime(row.endAt, timeZone)}
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={`${getCurrencySymbolFromLocation(
                          filterLocationById(
                            locations,
                            parseInt(row.locationID, 10),
                          ),
                        )}${row.targetCommissionUnscheduledRaw.toString()}`}
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          row.targetCommissionUnscheduledEdited
                            ? `${getCurrencySymbolFromLocation(
                                filterLocationById(
                                  locations,
                                  parseInt(row.locationID, 10),
                                ),
                              )}${row.targetCommissionUnscheduledEdited.toString()}`
                            : '-'
                        }
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={row.lastUpdatedBy ? row.lastUpdatedBy : '-'}
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>
                      <StatusField status={row.status} />
                      <Chip
                        className={
                          row.isWeekend
                            ? classes.statusWeekendChip
                            : classes.statusWeekdayChip
                        }
                        label={
                          <Typography
                            id='status'
                            className={classes.statusChipText}
                          >
                            {row.isWeekend ? 'WEEKEND' : 'WEEKDAY'}
                          </Typography>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          count={props.rows.length}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20]}
          component={'div'}
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWeekendChip: {
      backgroundColor: sharedColors.statusYellowLightest,
      color: sharedColors.statusYellow,
    },
    statusWeekdayChip: {
      backgroundColor: sharedColors.purple1,
      color: sharedColors.purple4,
    },
    statusChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    editableRow: {
      cursor: 'pointer',
    },
  }),
);

export default TargetCommissionsTable;
