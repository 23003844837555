import { flatten } from 'lodash';
import { PayRateStatus } from './PayRate';
import { payRateStatusFromTimeRange } from '../utilities/PayRates';

export interface FeatureToggleHistory {
  startAt: Date;
  endAt?: Date;
}

export interface FeatureToggleHistoryMap {
  [key: string]: FeatureToggleHistory[];
}

export const featureToggleHistoryFromResponse = (
  response: any,
): FeatureToggleHistory => ({
  startAt: new Date(response.start_at),
  endAt: response.end_at ? new Date(response.end_at) : undefined,
});

export interface FeatureToggleHistoryWithIncludedValue {
  includedValue: string;
  startAt: Date;
  endAt?: Date;
  status: PayRateStatus;
}

export const featureToggleHistoryMapToList = (
  historyMap: FeatureToggleHistoryMap,
): FeatureToggleHistoryWithIncludedValue[] =>
  flatten(
    Object.keys(historyMap).map((includedValue) =>
      historyMap[includedValue].map((history) => ({
        includedValue,
        startAt: history.startAt,
        endAt: history.endAt,
        status: payRateStatusFromTimeRange(history.startAt, history.endAt),
      })),
    ),
  );

export interface FeatureToggleConfigWithActiveInclusionCount {
  name: string;
  inclusionRate: number;
  type: string;
  dependency?: string;
  activeInclusionCount: number;
}

export const featureToggleConfigWithActiveInclusionCountFromResponse = (
  response: any,
): FeatureToggleConfigWithActiveInclusionCount => ({
  name: response.name ?? '',
  inclusionRate: response.inclusion_rate ?? 0,
  type: response.type ?? '',
  dependency: response.dependency,
  activeInclusionCount: response.active_inclusion_count ?? 0,
});

export interface FeatureToggleInclusionListEntry {
  includedValue: string;
  startAt: Date;
  endAt?: Date;
  timezone?: string;
  lastUpdatedAt: Date;
  lastUpdatedBy: string;
}

export const featureToggleInclusionListEntryFromResponse = (
  response: any,
): FeatureToggleInclusionListEntry => ({
  includedValue: response.included_value ?? '',
  startAt: new Date(response.start_at),
  endAt: response.end_at && new Date(response.end_at),
  timezone: response.timezone,
  lastUpdatedAt: new Date(response.last_updated_at),
  lastUpdatedBy: response.last_updated_by ?? '',
});

export interface FeatureToggleConfigWithInclusionList {
  name: string;
  inclusionRate: number;
  type: string;
  dependency?: string;
  inclusionList: FeatureToggleInclusionListEntry[];
  allInclusionCount: number;
}

export const featureToggleConfigWithInclusionListFromResponse = (
  response: any,
): FeatureToggleConfigWithInclusionList => ({
  name: response.name ?? '',
  inclusionRate: response.inclusion_rate ?? 0,
  type: response.type ?? '',
  dependency: response.dependency,
  inclusionList: (response.inclusion_list ?? []).map((entry: any) =>
    featureToggleInclusionListEntryFromResponse(entry),
  ),
  allInclusionCount: response.all_inclusion_count,
});

export interface FeatureToggleInsertRequest {
  featureName: string;
  includedValue: string;
  startAt: Date;
  endAt?: Date;
}

export const featureToggleInsertRequestTransformer = (
  req: FeatureToggleInsertRequest,
): any => ({
  feature_name: req.featureName,
  included_value: req.includedValue,
  time_range: {
    start_at: req.startAt,
    end_at: req.endAt,
  },
});

export interface FeatureToggleExpireRequest {
  featureName: string;
  includedValue: string;
  expireAt?: Date;
  expireNow: boolean;
}

export const featureToggleExpireRequestTransformer = (
  req: FeatureToggleExpireRequest,
): any => ({
  feature_name: req.featureName,
  included_value: req.includedValue,
  expire_at: req.expireAt,
  expire_now: req.expireNow,
});

export interface FeatureToggleUpdateRequest {
  featureName: string;
  includedValue: string;
  currentStartAt: Date;
  newStartAt: Date;
  newEndAt?: Date;
}

export const featureToggleUpdateRequestTransformer = (
  req: FeatureToggleUpdateRequest,
): any => ({
  feature_name: req.featureName,
  included_value: req.includedValue,
  current_start_at: req.currentStartAt,
  new_start_at: req.newStartAt,
  new_end_at: req.newEndAt,
});

export interface InclusionListInsertionOptions {
  startAt: Date;
  endAt: Date;
  setEndAt: boolean;
  isLocalTime: boolean;
}

export interface InclusionListExpirationOptions {
  expireAt: Date;
  isLocalTime: boolean;
  expireNow: boolean;
}

export interface InclusionValueUpdateOptions {
  startAt: Date;
  endAt: Date;
  setEndAt: boolean;
  isLocalTime: boolean;
}
