import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { uploadDppTimeRates } from '../../services/dppTimeRates';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import UploadCSV from '../UploadCSV';
import { BulkErrorDialog } from '../BulkErrorDialog';
import { convertLocalToDeliveryZone } from '../../utilities/Dates';
import { downloadCsvTemplate } from '../../utilities/Csv';
import { timeRatesCsvTemplate } from '../../variables/CsvTemplate';

interface UploadDppTimeRatesDialogProps {
  open: boolean;
  onClose: () => void;
}

const UploadDppTimeRatesDialog = (props: UploadDppTimeRatesDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [loading, setLoading] = useState(false);
  const [bulkErrorMsg, setBulkErrorMsg] = useState<string | undefined>(
    undefined,
  );
  const [file, setFile] = useState<File | undefined>(undefined);
  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [timeBlockCount, setTimeBlockCount] = useState(6);

  useEffect(() => {
    setBulkErrorMsg(undefined);
  }, [file]);

  const uploadFile = () => {
    if (file === undefined) {
      return;
    }

    setLoading(true);

    const luxonStartAt = DateTime.fromJSDate(startAt);
    const luxonEndAt = DateTime.fromJSDate(endAt);

    uploadDppTimeRates(
      file,
      convertLocalToDeliveryZone(
        'UTC',
        luxonStartAt,
        luxonStartAt.hour,
        luxonStartAt.minute,
      ),
      convertLocalToDeliveryZone(
        'UTC',
        luxonEndAt,
        luxonEndAt.hour,
        luxonEndAt.minute,
      ),
      timeBlockCount,
    )
      .then(() => {
        toast.success('Time rates are successfully uploaded');
        props.onClose();
      })
      .catch((err) => {
        toast.error('Time rate upload failed.');
        setBulkErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  const handleSetStartAt = (newStartAt: Date | null) =>
    setStartAt(newStartAt ?? new Date());
  const handleSetEndAt = (newEndAt: Date | null) =>
    setEndAt(newEndAt ?? new Date());

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <Waiting open={loading} />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Upload Time Rates
      </DialogTitle>
      <Box component='div'>
        <UploadCSV setParentFile={setFile} />
        <BulkErrorDialog
          open={bulkErrorMsg !== undefined}
          message={bulkErrorMsg || ''}
          onClose={() => setBulkErrorMsg(undefined)}
        />
        <Box component='div' className={classes.row}>
          <Box component='div' className={classes.datePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startAt}
                onChange={handleSetStartAt}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => <TextField {...params} label='Start At' size='small' />}
              />
            </LocalizationProvider>
          </Box>
          <Box component='div' className={classes.datePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={endAt}
                onChange={handleSetEndAt}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => <TextField {...params} label='End At' size='small' />}
              />
            </LocalizationProvider>
          </Box>
          <Box component='div' className={classes.blockPicker}>
            <FormControl fullWidth>
              <InputLabel id='select-time-block-count'>
                Time Block Count
              </InputLabel>
              <Select
                labelId='select-time-block-count'
                onChange={(event) =>
                  setTimeBlockCount(event.target.value as number)
                }
                value={timeBlockCount}
                label='Time Block Count'
                size='small'
              >
                <MenuItem value={6}>6 Time Block</MenuItem>
                <MenuItem value={10}>10 Time Block</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <DialogActions className={classes.dialogActions}>
        <Box component='div' className={classes.dialogActionsSpacer}>
          <Button
            onClick={() => downloadCsvTemplate(timeRatesCsvTemplate)}
            className={sharedClasses.buttonText}
          >
            Download CSV Template for Time Rates
          </Button>
        </Box>
        <Button
          variant='contained'
          color={'inherit'}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={uploadFile}
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          disabled={file === undefined}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  tabsContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    background: sharedColors.gray1,
  },
  tabLabel: {
    textTransform: 'none',
    fontWeight: 500,
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  dialogActions: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
  },
  datePicker: {
    marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2.5),
  },
  blockPicker: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
}));

export default UploadDppTimeRatesDialog;
