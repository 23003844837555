import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import {
  composeNodeID,
  subsidizableHoursWorkedAmountNodeID,
  subsidizableHoursWorkedRateNodeID,
} from '../../../../variables/PayHistory';
import SingleTextNode from '../SingleTextNode';
import { AppliedRate } from '../../../../interfaces/PayHistory/AppliedRate';

interface MinGuaranteeSubsidizableHoursWorkedNodeProps {
  id: string;
  hoursWorked: AppliedRate[];
}

const MinGuaranteeSubsidizableHoursWorkedNode = (
  props: MinGuaranteeSubsidizableHoursWorkedNodeProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`Subsidizable Hours [${props.hoursWorked.length.toString()}]`}</Typography>
      }
    >
      {props.hoursWorked.map((hoursWorked, i) => (
        <TreeItem
          nodeId={composeNodeID([props.id, i.toString()])}
          label={
            <Typography
              className={multiClass([classes.label, sharedClasses.body1])}
            >
              {`${i.toString()} {${Object.keys(
                hoursWorked,
              ).length.toString()}}`}
            </Typography>
          }
        >
          <SingleTextNode
            id={composeNodeID([
              props.id,
              i.toString(),
              subsidizableHoursWorkedRateNodeID,
            ])}
            name='Minimum Guarantee Rate'
            value={(hoursWorked.rate / 100).toString()}
          />
          <SingleTextNode
            id={composeNodeID([
              props.id,
              i.toString(),
              subsidizableHoursWorkedAmountNodeID,
            ])}
            name='Worked Hours'
            value={hoursWorked.amount.toString()}
          />
        </TreeItem>
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MinGuaranteeSubsidizableHoursWorkedNode;
