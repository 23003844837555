import { isArray } from 'lodash';
import {
  FeatureToggleConfigWithInclusionList,
  FeatureToggleConfigWithActiveInclusionCount,
  featureToggleConfigWithActiveInclusionCountFromResponse,
  featureToggleHistoryFromResponse,
  FeatureToggleHistoryMap,
  featureToggleConfigWithInclusionListFromResponse,
  FeatureToggleExpireRequest,
  featureToggleExpireRequestTransformer,
  FeatureToggleInsertRequest,
  featureToggleInsertRequestTransformer,
  FeatureToggleUpdateRequest,
  featureToggleUpdateRequestTransformer,
} from '../interfaces/FeatureToggle';
import api from './api';

const featureToggleEndpoint = '/feature-flags';

export const getFeatureToggleHistoryMap = async (
  featureName: string,
  includedValues: string[],
  getAllActive: boolean,
): Promise<FeatureToggleHistoryMap> => {
  const url = `${featureToggleEndpoint}/${featureName}/inclusion-history`;
  const response = await api.get(url, {
    params: {
      'feature-name': featureName,
      'included-value': includedValues,
      status: getAllActive ? '"ACTIVE"' : undefined,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  const responseData = response.data;

  const historyMap: FeatureToggleHistoryMap = {};
  Object.keys(responseData).forEach((includedValue) => {
    const history = responseData[includedValue];
    if (!isArray(history)) {
      throw new Error(
        `Error while getting feature toggle history: field "${includedValue}" is not an array`,
      );
    }

    historyMap[includedValue] = history.map((timeRange) =>
      featureToggleHistoryFromResponse(timeRange),
    );
  });

  return historyMap;
};

export const getFeatureToggleConfigsWithActiveInclusionCount =
  async (): Promise<FeatureToggleConfigWithActiveInclusionCount[]> => {
    const response = await api.get(featureToggleEndpoint);

    const responseData = response.data;
    if (!isArray(responseData.feature_toggle_configs)) {
      throw new Error(
        `Error while getting feature toggle history: field "feature_toggle_configs" is not an array`,
      );
    }

    return responseData.feature_toggle_configs.map((config: any) =>
      featureToggleConfigWithActiveInclusionCountFromResponse(config),
    );
  };

export const getFeatureToggleConfigWithInclusionList = async (
  featureName: string,
  getOnlyActives: boolean,
  pageNumber: number,
  pageSize: number,
  searchedIncludedValue: string,
): Promise<FeatureToggleConfigWithInclusionList> => {
  const url = `${featureToggleEndpoint}/${featureName}/config-details`;
  const response = await api.get(url, {
    params: {
      status: getOnlyActives ? 'ACTIVE' : undefined,
      'page-number': pageNumber,
      'page-size': pageSize,
      'included-value':
        searchedIncludedValue === '' ? undefined : searchedIncludedValue,
    },
  });

  const responseData = response.data;

  if (responseData?.feature_toggle_config) {
    return featureToggleConfigWithInclusionListFromResponse(
      responseData?.feature_toggle_config,
    );
  }
  throw new Error(
    'Error while getting feature toggle config details: Response data is empty',
  );
};

export const insertFeatureToggles = async (
  insertRequests: FeatureToggleInsertRequest[],
): Promise<void> => {
  try {
    await api.post(
      featureToggleEndpoint,
      insertRequests.map((req) => featureToggleInsertRequestTransformer(req)),
    );
  } catch (error: any) {
    throw new Error(`Could not insert feature toggles: ${error.message}`);
  }
};

export const expireFeatureToggles = async (
  expireRequests: FeatureToggleExpireRequest[],
): Promise<void> => {
  const url = `${featureToggleEndpoint}/expire`;
  try {
    await api.post(
      url,
      expireRequests.map((req) => featureToggleExpireRequestTransformer(req)),
    );
  } catch (error: any) {
    throw new Error(`Could not expire feature toggles: ${error.message}`);
  }
};

export const updateFutureFeatureToggle = async (
  updateRequest: FeatureToggleUpdateRequest,
): Promise<void> => {
  try {
    await api.put(
      featureToggleEndpoint,
      featureToggleUpdateRequestTransformer(updateRequest),
    );
  } catch (error: any) {
    throw new Error(`Could not update inclusion value: ${error.message}`);
  }
};
