import { isArray } from 'lodash';
import { TablePaginationOptions } from '../interfaces/Common';
import {
  HolidayAccrualStatsResponse,
  holidayAccrualStatsResponseFromResponse,
  HolidayRequestsResponse,
  holidayRequestsResponseFromResponse,
  HolidayRequestToCreate,
  holidayRequestToCreateOutputTransformer,
  HolidayType,
} from '../interfaces/HolidayPay';
import api from './api';
import {
  HolidayAccrualHistory,
  holidayAccrualHistoryFromResponse,
} from '../interfaces/HolidayAccrualHistory';
import {
  HolidayRateHistory,
  holidayRateHistoryFromResponse,
} from '../interfaces/HolidayRateHistory';

const holidayPayUrl = '/holiday';

export const createHolidayRequest = async (
  holidayRequestToCreate: HolidayRequestToCreate,
): Promise<void> => {
  await api.post(
    holidayPayUrl,
    holidayRequestToCreateOutputTransformer(holidayRequestToCreate),
  );
};

export const getHolidayAccrualStats = async (
  holidayType: HolidayType,
  pagination: TablePaginationOptions,
  driverID?: string,
): Promise<HolidayAccrualStatsResponse> => {
  const response = await api.get(holidayPayUrl, {
    params: {
      'get-recent-holiday-pays': false,
      'holiday-type-id': holidayType,
      'driver-id': driverID,
      page: pagination.page,
      'page-size': pagination.rowsPerPage,
    },
  });

  const responseData = response?.data;
  if (!isArray(responseData?.data)) {
    throw new Error(
      'Could not get holiday pay accruals: Response data is not an array',
    );
  }

  return holidayAccrualStatsResponseFromResponse(responseData);
};

export const getHolidayRequests = async (
  holidayType: HolidayType,
  pagination: TablePaginationOptions,
  driverID?: string,
): Promise<HolidayRequestsResponse> => {
  const response = await api.get(`${holidayPayUrl}/requests`, {
    params: {
      'holiday-type-id': holidayType,
      'driver-id': driverID,
      page: pagination.page,
      'page-size': pagination.rowsPerPage,
    },
  });

  const responseData = response?.data;
  if (!isArray(responseData?.data)) {
    throw new Error(
      'Could not get holiday requests: Response data is not an array',
    );
  }

  return holidayRequestsResponseFromResponse(responseData);
};

export const getHolidayAccrualHistory = async (
  holidayType: HolidayType,
  driverID: string,
): Promise<HolidayAccrualHistory[]> => {
  const response = await api.get(`${holidayPayUrl}/accrual-history`, {
    params: {
      'holiday-type-id': holidayType,
      'driver-id': driverID,
    },
  });

  const responseData = response?.data;
  if (!isArray(responseData?.data)) {
    throw new Error(
      'Could not get holiday accrual history: Response data is not an array',
    );
  }

  return responseData.data.map((history: any) =>
    holidayAccrualHistoryFromResponse(history),
  );
};

export const getHolidayRateHistory = async (
  holidayType: HolidayType,
  driverID: string,
): Promise<HolidayRateHistory> => {
  const response = await api.get(`${holidayPayUrl}/rate-history`, {
    params: {
      'holiday-type-id': holidayType,
      'driver-id': driverID,
    },
  });

  const responseData = response?.data;
  if (!responseData?.data) {
    throw new Error(
      'Could not get holiday rate history: Response data is not an object',
    );
  }

  return holidayRateHistoryFromResponse(responseData.data);
};
