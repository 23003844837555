import {
  MissionRewardSummary,
  missionRewardSummaryFromResponse,
} from './MissionRewardSummary';
import {
  MissionProgressSummary,
  missionProgressSummaryFromResponse,
} from './MissionProgressSummary';

export interface MissionBreakdown {
  name: string;
  type: string;
  invitedAt: Date;
  acceptedAt?: Date;
  startAtLocal: Date;
  endAtLocal?: Date;
  ordersMustContain: string[];
  reward: MissionRewardSummary;
  allTimeProgress: MissionProgressSummary;
}

export const missionBreakdownFromResponse = (
  responseData: any,
): MissionBreakdown => ({
  name: responseData.name ?? '',
  type: responseData.type ?? '',
  invitedAt: new Date(responseData.invited_at),
  acceptedAt: responseData.accepted_at
    ? new Date(responseData.accepted_at)
    : undefined,
  startAtLocal: new Date(responseData.start_at_local),
  endAtLocal: responseData.end_at_local
    ? new Date(responseData.end_at_local)
    : undefined,
  ordersMustContain: responseData.orders_must_contain ?? [],
  reward: missionRewardSummaryFromResponse(responseData.reward ?? {}),
  allTimeProgress: missionProgressSummaryFromResponse(
    responseData.all_time_progress ?? {},
  ),
});
