import { isArray } from 'lodash';
import {
  DriverSupportSummary,
  driverSupportSummaryFromResponse,
} from '../interfaces/PayHistory/DriverSupportSummary';
import api from './api';

export const getDriverSupportSummary = async (
  searchedValue: string,
  workweek: string,
): Promise<DriverSupportSummary> => {
  const url = '/driver-support/summary';
  const response = await api.get(url, {
    params: { workweek, 'email-or-id': searchedValue },
  });
  const responseData = response.data;
  if (!responseData) {
    throw new Error('Unexpected error: Server responded with empty body');
  }
  if (!isArray(responseData.payouts)) {
    throw new Error(
      'Unexpected error: "payouts" is not a list in the response',
    );
  }

  return driverSupportSummaryFromResponse(responseData, workweek);
};
