import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { Location } from '../../interfaces/Location';
import { permissions as permissionsAtom } from '../../atoms/Users';
import {
  getBatchNormalizationSettings,
  getTimeRatePipelineSettings,
} from '../../services/settings';
import { TimeRatePipelineSettings } from '../../interfaces/Settings/TimeRatePipelineSettings';
import { getLocations, getMetros } from '../../services/locations';
import {
  getCountryLocations,
  getSettingsFilterQueryParams,
  handleCountryLocationID,
  settingsComparator,
} from '../../utilities/Settings/Settings';
import { getUserPermissions, isPermitted } from '../../variables/Users';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { payRatesHeaderTestId } from '../PayRates/PayRates';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';
import TimeRateSettingsTable from './TimeRateSettingsTable';
import { Permission } from '../../interfaces/Users';
import NewSettingsDialog from './NewSettingsDialog';
import UploadSettingsDialog from './UploadSettingsDialog';
import { InfoBox } from '../InfoBox';
import MultiMetroAutocomplete from '../MultiMetroAutocomplete';
import { metros as metrosAtom } from '../../atoms/Location';
import MinMaxFaresTable from './MinMaxFaresTable';
import { BatchNormalizationSettings } from '../../interfaces/Settings/BatchNormalizationSettings';
import BatchNormalizationSettingsTable from './BatchNormalizationSettingsTable';
import NewBatchNormalizationSettingsDialog from './NewBatchNormalizationSettingsDialog';

const SettingsPage = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;
  const location = useLocation();

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const [metros, setMetros] = useRecoilState(metrosAtom);

  const [activeSettingsChecked, setActiveSettingsChecked] = useState(false);
  const [filteredLocationIDs, setFilteredLocationIDs] = useState<string[]>([]);
  const [filteredMetroIDs, setFilteredMetroIDs] = useState<string[]>([]);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingMetros, setLoadingMetros] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [loadingBatchNormalizations, setLoadingBatchNormalizations] =
    useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [timeRateSettings, setTimeRateSettings] = useState<
    TimeRatePipelineSettings[]
  >([]);
  const [batchNormalizationSettings, setBatchNormalizationSettings] = useState<
    BatchNormalizationSettings[]
  >([]);
  const [showBatchNormalizationDialog, setShowBatchNormalizationDialog] =
    useState(false);
  const [showInsertDialog, setShowInsertDialog] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const selectedAnyLocation =
    !isEmpty(filteredLocationIDs) ||
    !isEmpty(filteredMetroIDs) ||
    activeSettingsChecked;

  const refreshSettings = () => {
    setShowInsertDialog(false);
    setShowUploadDialog(false);
    if (selectedAnyLocation) {
      setLoadingSettings(true);
      getTimeRatePipelineSettings(
        activeSettingsChecked
          ? []
          : filteredLocationIDs.map((id) => handleCountryLocationID(id)),
        activeSettingsChecked ? [] : filteredMetroIDs,
        activeSettingsChecked,
      )
        .then((settings) => setTimeRateSettings(settings))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSettings(false));
    } else {
      setTimeRateSettings([]);
    }
  };

  const refreshBatchNormalizations = () => {
    setShowBatchNormalizationDialog(false);
    if (selectedAnyLocation) {
      setLoadingBatchNormalizations(true);
      getBatchNormalizationSettings(
        activeSettingsChecked
          ? []
          : filteredLocationIDs.map((id) => handleCountryLocationID(id)),
        activeSettingsChecked ? [] : filteredMetroIDs,
        activeSettingsChecked,
      )
        .then((settings) => setBatchNormalizationSettings(settings))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingBatchNormalizations(false));
    } else {
      setBatchNormalizationSettings([]);
    }
  };

  const refreshLocations = () => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) =>
        setLocations([...getCountryLocations(), ...fetchedLocations]),
      )
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));
  };

  const refreshMetros = () => {
    setLoadingMetros(true);
    getMetros()
      .then((fetchedMetros) => setMetros(fetchedMetros))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingMetros(false));
  };

  const setFiltersFromQueryParams = () => {
    const params = new URLSearchParams(location.search);

    const allActiveParam = params.get('all-active');
    const locationIDsParam = params.get('location-ids');
    const metroIDsParam = params.get('metro-ids');
    if (allActiveParam && allActiveParam === '1') {
      setActiveSettingsChecked(true);
    } else {
      if (locationIDsParam) {
        setFilteredLocationIDs(locationIDsParam.split(','));
      }
      if (metroIDsParam) {
        setFilteredMetroIDs(metroIDsParam.split(','));
      }
    }
  };

  useEffect(() => {
    refreshLocations();
    refreshMetros();
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.PIPELINE_SETTINGS) {
        index = i;
        break;
      }
    }
    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  useEffect(() => {
    refreshSettings();
    refreshBatchNormalizations();
  }, [filteredLocationIDs, filteredMetroIDs, locations, activeSettingsChecked]);

  useEffect(() => {
    setFiltersFromQueryParams();
  }, [locations]);

  const updateQueryParams = (
    newFilteredLocationIDs: string[],
    newFilteredMetroIDs: string[],
    newActiveSettingsChecked: boolean,
  ) => {
    const queryParams = getSettingsFilterQueryParams(
      newFilteredLocationIDs,
      newFilteredMetroIDs,
      newActiveSettingsChecked,
    );

    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    if (!isEmpty(queryParams)) {
      newUrl += `?${queryParams
        .map((param) => `${param.key}=${param.value}`)
        .join('&')}`;
    }
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const handleToggleAllActive = () => {
    const newValue = !activeSettingsChecked;
    updateQueryParams(filteredLocationIDs, filteredMetroIDs, newValue);
    setActiveSettingsChecked(newValue);
  };

  const handleSelectLocations = (newSelectedLocationIDs: string[]) => {
    updateQueryParams(
      newSelectedLocationIDs,
      filteredMetroIDs,
      activeSettingsChecked,
    );
    setFilteredLocationIDs(newSelectedLocationIDs);
  };

  const handleSelectMetros = (newSelectedMetroIDs: string[]) => {
    updateQueryParams(
      filteredLocationIDs,
      newSelectedMetroIDs,
      activeSettingsChecked,
    );
    setFilteredMetroIDs(newSelectedMetroIDs);
  };

  const sortedSettings = timeRateSettings.sort(settingsComparator);
  const minMaxFareSettings = timeRateSettings.filter(
    (settings) => !!settings.minMaxFares,
  );

  return (
    <Box component='div'>
      <NewSettingsDialog
        locations={locations}
        open={showInsertDialog}
        onClose={() => setShowInsertDialog(false)}
        onDone={refreshSettings}
      />
      <NewBatchNormalizationSettingsDialog
        locations={locations}
        open={showBatchNormalizationDialog}
        onClose={() => setShowBatchNormalizationDialog(false)}
        onDone={refreshBatchNormalizations}
      />
      <UploadSettingsDialog
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        onUpload={refreshSettings}
      />
      <Waiting
        open={
          loadingSettings ||
          loadingBatchNormalizations ||
          loadingLocations ||
          loadingMetros
        }
      />
      <Paper className={classes.header}>
        <Typography
          className={multiClass([sharedClasses.h2, classes.title])}
          data-testid={payRatesHeaderTestId}
        >
          Pipeline Settings
        </Typography>
        <Box component='div' className={classes.horizontalSpacer} />
        <InfoBox
          message={<></>}
          title="The Tuesday upload date you choose ensures the configs you're uploading will be active for that Tuesday’s run date. If a Tuesday is unselectable, it means it is past that Tuesday at 9:30am PT, the run is in progress, and configs are locked for that week. Configs for future weeks can always be edited."
          classes={classes}
          severity='info'
        />
        <Box component='div' className={classes.horizontalSpacer} />
        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowInsertDialog(true)}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.SET_DPP_RULE)}
        >
          + New Pipeline Settings
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowBatchNormalizationDialog(true)}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.SET_DPP_RULE)}
        >
          + New Batch Normalization Settings
        </Button>
        <Button
          color='primary'
          variant='contained'
          startIcon={<FileUploadIcon />}
          onClick={() => setShowUploadDialog(true)}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.SET_DPP_RULE)}
        >
          Upload Settings
        </Button>
      </Paper>
      <Box component='div' className={classes.body}>
        <Box component='div' className={classes.filters}>
          <MultiLocationAutocomplete
            label='Location Name'
            width={270}
            pinnedLocations={locations.slice(0, 2)}
            locations={locations.slice(2)}
            selectedIDs={filteredLocationIDs}
            onSetSelectedIDs={handleSelectLocations}
            disabled={activeSettingsChecked}
          />
          <Box component='div' className={classes.metroFilter}>
            <MultiMetroAutocomplete
              label='Metros'
              width={270}
              metros={metros}
              selectedIDs={filteredMetroIDs}
              onSetSelectedIDs={handleSelectMetros}
              disabled={activeSettingsChecked}
            />
          </Box>
          <FormControlLabel
            className={classes.activePayRatesSwitch}
            control={
              <Switch
                checked={activeSettingsChecked}
                onChange={handleToggleAllActive}
                inputProps={{ 'aria-label': 'controlled' }}
                color={'success'}
              />
            }
            label='All Active Settings'
            labelPlacement='start'
          />
        </Box>
        {!isEmpty(minMaxFareSettings) && (
          <MinMaxFaresTable
            rows={minMaxFareSettings}
            locations={locations}
            onChange={refreshSettings}
          />
        )}
        {!isEmpty(batchNormalizationSettings) && (
          <BatchNormalizationSettingsTable
            rows={batchNormalizationSettings}
            locations={locations}
            onChange={refreshBatchNormalizations}
          />
        )}
        {!isEmpty(timeRateSettings) && (
          <TimeRateSettingsTable
            rows={sortedSettings}
            locations={locations}
            onChange={refreshSettings}
          />
        )}
        {isEmpty(timeRateSettings) && isEmpty(batchNormalizationSettings) && (
          <Box className={classes.warningMessageContainer}>
            <Grid
              container
              alignContent='center'
              justifyContent='center'
              className={classes.warningMessageGrid}
            >
              <Grid item xs={12}>
                <Typography className={classes.warningText}>
                  {selectedAnyLocation
                    ? 'No settings for the selected options'
                    : 'Please select a location to display tables'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    title: {
      color: sharedColors.gray7,
      mt: 'auto',
      mb: 'auto',
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    button: {
      textTransform: 'none',
      marginLeft: theme.spacing(1.5),
      mt: 'auto',
      mb: 'auto',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    locationDropdown: {
      minWidth: 250,
      marginLeft: theme.spacing(1.5),
    },
    selectedFilter: {
      backgroundColor: sharedColors.white,
    },
    emptyFilter: {
      backgroundColor: sharedColors.gray2,
    },
    filterSpacer: {
      flexGrow: 1,
    },
    metroFilter: {
      marginLeft: theme.spacing(1.5),
    },
    warningMessageContainer: {
      display: 'flex',
      flexGrow: 1,
      height: 600,
    },
    warningMessageGrid: {
      flexGrow: 1,
    },
    warningText: {
      flexGrow: 1,
      textAlign: 'center',
    },
    activePayRatesSwitch: {
      marginLeft: theme.spacing(1.5),
    },
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: 550,
      height: 'fit-content',
      padding: theme.spacing(2),
      backgroundColor: sharedColors.blue1,
    },
    infoBoxIcon: {
      color: sharedColors.blue6,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    infoBoxTextWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.7),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.blue6,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    infoBoxMessage: {},
  }),
);

export default SettingsPage;
