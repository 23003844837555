export interface MissionProgressSummary {
  orderCount: number;
  tripCount: number;
}

export const missionProgressSummaryFromResponse = (
  responseData: any,
): MissionProgressSummary => ({
  orderCount: responseData.order_count ?? 0,
  tripCount: responseData.trip_count ?? 0,
});
