import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'material-react-toastify';
import { DateTime } from 'luxon';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  getHolidayRequestToCreateTemplate,
  getHolidayTypeName,
} from '../../utilities/HolidayPay';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { HolidayType } from '../../interfaces/HolidayPay';
import { ConfirmDialog } from '../NewPayRateDialog/ConfirmDialog';
import { createHolidayRequest } from '../../services/holidayPay';

interface HolidayRequestDialogProps {
  driverID?: string;
  accruedDays: number;
  onCreate: () => void;
  onClose: () => void;
}

const HolidayRequestDialog = (props: HolidayRequestDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(
    getHolidayRequestToCreateTemplate(),
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    setCurrentState({
      ...getHolidayRequestToCreateTemplate(),
      driverID: props.driverID ?? '',
    });
  }, [props.driverID]);

  const handleSubmit = () => {
    setShowConfirmDialog(false);
    setLoading(true);
    createHolidayRequest(currentState)
      .then(props.onCreate)
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeStartDate = (date: Date | null) => {
    setCurrentState({ ...currentState, startDate: date ?? new Date() });
  };

  const onDatePickerKeyDown = (e: any) => {
    e.preventDefault();
  };

  const handleChangeDays = (e: SelectChangeEvent<number>) => {
    setCurrentState({
      ...currentState,
      accruedDaysToGetPaid: e.target.value as number,
    });
  };

  return (
    <Dialog
      open={!!props.driverID}
      onClose={loading ? undefined : props.onClose}
    >
      <Waiting open={loading} />
      <ConfirmDialog
        title='Are you sure?'
        content='Confirm adding new holiday request'
        okText='Confirm'
        cancelText='Cancel'
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleSubmit}
        color='primary'
      />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        New Holiday Request
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          label='DP ID'
          value={currentState.driverID}
          disabled
          size='small'
          className={classes.driverIdTextField}
        />
        <FormControl
          size='small'
          variant='outlined'
          disabled
          className={classes.typeDropdown}
        >
          <InputLabel className={classes.dropdownLabel}>
            Holiday Type
          </InputLabel>
          <Select id='holiday-type-select' value={currentState.holidayType}>
            {Object.keys(HolidayType).map((typeName) => {
              const holidayType =
                HolidayType[typeName as keyof typeof HolidayType];

              return (
                <MenuItem value={holidayType}>
                  {getHolidayTypeName(holidayType)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box component='div' className={classes.row}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Start At'
              value={currentState.startDate}
              minDate={DateTime.fromJSDate(currentState.startDate)
                .minus({
                  month: 1,
                })
                .toJSDate()}
              maxDate={DateTime.fromJSDate(currentState.startDate)
                .plus({
                  month: 1,
                })
                .toJSDate()}
              onChange={handleChangeStartDate}
              className={classes.datePicker}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => (
                <TextField
                  {...params}
                  size='small'
                  onKeyDown={onDatePickerKeyDown}
                  className={classes.datePicker}
                />
              )}
            />
          </LocalizationProvider>

          <FormControl
            size='small'
            variant='outlined'
            className={classes.daysDropdown}
          >
            <InputLabel className={classes.dropdownLabel}>Days</InputLabel>
            <Select
              id='days-select'
              value={currentState.accruedDaysToGetPaid}
              onChange={handleChangeDays}
            >
              {[
                1,
                ...Array.from(
                  Array(Math.floor(props.accruedDays) + 1).keys(),
                ).slice(2),
              ].map((day) => (
                <MenuItem value={day}>{day.toString()}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box component='div' className={classes.dialogActionsSpacer} />
        <Button
          variant='contained'
          color='inherit'
          disabled={loading}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setShowConfirmDialog(true)}
          variant='contained'
          color='primary'
          className={sharedClasses.buttonText}
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  driverIdTextField: {
    width: 350,
    marginTop: theme.spacing(1.5),
  },
  dialogActions: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
  },
  typeDropdown: {
    width: 350,
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  dropdownLabel: {
    backgroundColor: sharedColors.white,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  datePicker: {
    width: 180,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },
  row: {
    display: 'flex',
    marginTop: theme.spacing(1.5),
    flexDirection: 'row',
  },
  daysDropdown: {
    width: 150,
    marginLeft: theme.spacing(1.5),
  },
}));

export default HolidayRequestDialog;
