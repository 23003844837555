import { useState } from 'react';
import { Button, DialogActions, FormControl } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'material-react-toastify';
import { createAdjustment } from '../../../services/adjustments';
import { useSharedStyles } from '../../../utilities/Styles';
import {
  getCurrentWorkweek,
  workweekApiString,
  workweekFromDate,
} from '../../../utilities/Workweek';
import { AdjustmentSubtype } from '../../../interfaces/AdjustmentSubtype';
import { AdjustmentParams } from '../../../interfaces/Adjustment';
import { parseCurrencyStringAsCents } from '../../../utilities/Currency';
import AdjustmentDisplayForm from './AdjustmentDisplayForm';
import { undoToast } from '../../../utilities/Toasters';

interface ManualAdjustmentFormProps {
  adjustmentSubtypes: AdjustmentSubtype[];
  onClose: () => void;
  onCreate: () => void;
  uuid: string;
}

export const adjustmentFormTestId = `new-adjustment-form-test-id`;

const ManualAdjustmentForm = (props: ManualAdjustmentFormProps) => {
  const sharedClasses = useSharedStyles().classes;

  const [newAdj, setNewAdj] = useState<AdjustmentParams>({
    driverId: '',
    workweek: workweekApiString(getCurrentWorkweek('UTC')),
    amount: 0,
    adjustmentSubtypeId: '',
    notes: '',
    textInput: undefined,
    dateInput: new Date(),
    caseLink: undefined,

    // NOTE: PUFF-54190's initial Figma doesn't have a currency input, so for now hardcoded to USD.
    // When we roll out the feature internationally, we'll need to make this configurable.
    currency: 'usd',
  });

  const handleDriverIdChange = (event: any) => {
    const driverId = event.target.value as string;
    setNewAdj({ ...newAdj, driverId });
  };

  const handleWorkweekChange = (date: Date | null) => {
    if (date) {
      const workweek = workweekFromDate(date);
      setNewAdj({ ...newAdj, workweek: workweekApiString(workweek) });
    }
  };

  const handleAmountChange = (event: any) => {
    const newAmountStr = event.target.value as string;
    setNewAdj({ ...newAdj, amount: parseCurrencyStringAsCents(newAmountStr) });
  };

  const handleSubtypeChange = (subtype: AdjustmentSubtype) => {
    setNewAdj({ ...newAdj, adjustmentSubtypeId: subtype.id });
  };

  const handleNoteChange = (event: any) => {
    const notes = event.target.value as string;
    setNewAdj({ ...newAdj, notes });
  };

  // textInput and dateInput are mutually exclusive
  const handleTextInputChange = (event: any) => {
    const textInput = event.target.value as string;
    setNewAdj({ ...newAdj, textInput, dateInput: undefined });
  };
  const handleDateInputChange = (date: Date | null) => {
    if (date) {
      setNewAdj({ ...newAdj, textInput: undefined, dateInput: date });
    }
  };

  const handleCaseLinkChange = (event: any) => {
    const caseLink = event.target.value as string;
    setNewAdj({ ...newAdj, caseLink });
  };

  const handleAdd = () => {
    props.onClose();
    undoToast('Creating adjustment...', 'success', () => {
      createAdjustment(newAdj, props.uuid)
        .then(() => {
          props.onCreate();
        })
        .catch((error) => {
          toast.error(`Could not create adjustment: ${error.message}`);
        });
    });
  };

  const canSave =
    newAdj.adjustmentSubtypeId !== '' &&
    newAdj.amount !== 0 &&
    newAdj.driverId.length > 0;

  return (
    <ValidatorForm
      onSubmit={handleAdd}
      onError={() => {}}
      instantValidate={true}
      data-testid={adjustmentFormTestId}
    >
      <FormControl component='fieldset'>
        <AdjustmentDisplayForm
          title='Enter Adjustment Information'
          adj={newAdj}
          readOnly={false}
          handleCaseLinkChange={handleCaseLinkChange}
          handleDateInputChange={handleDateInputChange}
          handleTextInputChange={handleTextInputChange}
          handleNoteChange={handleNoteChange}
          handleSubtypeChange={handleSubtypeChange}
          handleAmountChange={handleAmountChange}
          handleWorkweekChange={handleWorkweekChange}
          handleDriverIdChange={handleDriverIdChange}
        />
        <DialogActions>
          <Button
            variant='contained'
            color={'inherit'}
            onClick={props.onClose}
            className={sharedClasses.buttonText}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={sharedClasses.buttonText}
            disabled={!canSave}
          >
            Add
          </Button>
        </DialogActions>
      </FormControl>
    </ValidatorForm>
  );
};

export default ManualAdjustmentForm;
