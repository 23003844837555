import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { WeeklyBonusLocation } from '../../../../interfaces/PayHistory/WeeklyBonusBreakdown';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  weeklyBonusDeliveryCountNodeID,
  weeklyBonusLocationNameNodeID,
} from '../../../../variables/PayHistory';

interface WeeklyBonusLocationNodeProps {
  id: string;
  name: string;
  weeklyBonusLocation: WeeklyBonusLocation;
}

const WeeklyBonusLocationNode = (props: WeeklyBonusLocationNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.name} {${Object.keys(
            props.weeklyBonusLocation,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, weeklyBonusLocationNameNodeID])}
        name='Location Name'
        value={props.weeklyBonusLocation.locationName}
      />
      <SingleTextNode
        id={composeNodeID([props.id, weeklyBonusDeliveryCountNodeID])}
        name='Delivery Count'
        value={props.weeklyBonusLocation.deliveryCount.toString()}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default WeeklyBonusLocationNode;
