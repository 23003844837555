import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Button,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AdjustmentEntity } from '../../../interfaces/Adjustment';
import { getAdjustments } from '../../../services/adjustments';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';
import { formatDateAmericanReadable } from '../../../utilities/Dates';
import { sharedColors } from '../../../utilities/Styles';
import Waiting from '../../Waiting';
import AdjustmentStatusCell from './AdjustmentStatusCell';

export const recentAdjustmentHistoryTestId = `recent-adjustment-history-test-id`;
export const recentAdjustmentRecordTestId = `recent-adjustment-record-test-id`;

interface RecentAdjustmentHistoryProps {
  open: boolean;
  onClose: () => void;
  driverId: string;
}

const RecentAdjustmentHistory = (props: RecentAdjustmentHistoryProps) => {
  const { classes } = useStyles();

  const [recentAdjustments, setRecentAdjustments] = useState<
    AdjustmentEntity[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      getAdjustments({ driverId: props.driverId }, { page: 1, rowsPerPage: 3 })
        .then((adjustments) => {
          setRecentAdjustments(adjustments.adjustments);
        })
        .catch((error) => {
          toast.error(
            `Could not load adjustment types for selection: ${error.message}`,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.open, props.driverId]);

  return (
    <Box
      data-testid={recentAdjustmentHistoryTestId}
      hidden={!props.open}
      className={classes.recentAdjustmentsContainer}
    >
      <Typography className={classes.recentAdjustmentHistory}>
        Recent Adjustment History
      </Typography>
      <Waiting open={loading && props.open} />
      {recentAdjustments.length === 0 ? (
        <Typography align={'center'} variant={'subtitle1'}>
          No recent adjustments for driver
        </Typography>
      ) : (
        <Table className={classes.recentAdjustmentsTable}>
          <TableBody>
            {recentAdjustments.map((adjustment) => (
              <TableRow
                data-testid={recentAdjustmentRecordTestId}
                key={`recent-adjustment-${adjustment.id}`}
              >
                <TableCell size={'small'}>
                  <Tooltip
                    title={parseCentsAsCurrencyString(adjustment.amount)}
                  >
                    <Typography variant={'body2'} noWrap maxWidth={55}>
                      {parseCentsAsCurrencyString(adjustment.amount)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell size={'small'}>
                  <Tooltip title={adjustment.adjustmentType}>
                    <Typography noWrap maxWidth={55}>
                      {adjustment.adjustmentType}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell size={'small'}>
                  {/* use a tooltip for really long emails */}
                  <Tooltip title={adjustment.createdBy}>
                    <Typography noWrap maxWidth={150}>
                      {adjustment.createdBy}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell size={'small'}>
                  {formatDateAmericanReadable(adjustment.createdAt)}
                </TableCell>
                <TableCell size={'small'}>
                  <AdjustmentStatusCell adjustment={adjustment} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Box m={1} display='flex' justifyContent='flex-end' alignItems='flex-end'>
        <Button onClick={props.onClose}>Close</Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  recentAdjustmentsContainer: {
    background: sharedColors.gray2,
  },
  recentAdjustmentsTable: {
    background: sharedColors.white,
    border: `1px solid ${sharedColors.gray8}`,
    borderRadius: '1px',
    flexGrow: 1,
  },
  recentAdjustmentHistory: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',

    display: 'flex',
    alignItems: 'center',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',

    color: sharedColors.gray5,
    padding: '10px',
  },
}));

export default RecentAdjustmentHistory;
