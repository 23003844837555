import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  StyledEngineProvider,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { createRef, useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { Location } from '../../interfaces/Location';
import {
  sharedColors,
  timePickerTheme,
  useSharedStyles,
} from '../../utilities/Styles';
import { getDefaultTimeRatePipelineSettings } from '../../utilities/Settings/TimeRatePipelineSettings';
import { createTimeRatePipelineSettings } from '../../services/settings';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';
import { getNextTuesday, isFutureTuesday } from '../../utilities/Dates';
import SettingsNumberInput from './SettingsNumberInput';
import { getDefaultMinMaxFares } from '../../utilities/Settings/MinMaxFares';
import { MinMaxFares } from '../../interfaces/Settings/MinMaxFares';
import MinMaxFaresNumberInput from './MinMaxFaresNumberInput';
import { handleCountryLocationID } from '../../utilities/Settings/Settings';

interface NewSettingsDialogProps {
  locations: Location[];
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}

const NewSettingsDialog = (props: NewSettingsDialogProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const validatorRef = createRef<ValidatorForm>();

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(
    getDefaultTimeRatePipelineSettings(),
  );
  const [filteredLocationIDs, setFilteredLocationIDs] = useState<string[]>([]);
  const [invalidSettings, setInvalidSettings] = useState(false);

  const resetState = () => {
    setCurrentState(getDefaultTimeRatePipelineSettings());
    setFilteredLocationIDs([]);
  };

  useEffect(resetState, [props.open]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidSettings(!isValid);
    });
  }, [currentState, filteredLocationIDs]);

  useEffect(() => {
    setCurrentState({
      ...currentState,
      minMaxFares:
        filteredLocationIDs.filter((locationID) => locationID.includes('-'))
          .length > 0
          ? getDefaultMinMaxFares()
          : undefined,
    });
  }, [filteredLocationIDs]);

  const handleSubmit = () => {
    setLoading(true);
    createTimeRatePipelineSettings(
      filteredLocationIDs.map((locationID) =>
        handleCountryLocationID(locationID),
      ),
      currentState,
    )
      .then(() => {
        toast.success('New settings are successfully created.');
        props.onDone();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleSelectLocation = (newLocations: string[]) => {
    const countries = newLocations.filter((locationID) =>
      locationID.includes('-'),
    );

    setFilteredLocationIDs(
      isEmpty(countries) ? newLocations : countries.slice(0, 1),
    );
  };

  const handleChangeMinMaxFares = (newValue: MinMaxFares) => {
    setCurrentState({ ...currentState, minMaxFares: newValue });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='md'>
      <div style={{ width: 700 }}>
        <Waiting open={loading} />
        <ValidatorForm
          instantValidate
          onSubmit={handleSubmit}
          onError={() => setInvalidSettings(true)}
          ref={validatorRef}
        >
          <DialogTitle
            className={multiClass([sharedClasses.h6, classes.title])}
          >
            New Pipeline Settings
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box component='div' className={classes.dialogContent}>
              <Box component='div' className={classes.row}>
                <MultiLocationAutocomplete
                  label='Location Name'
                  pinnedLocations={props.locations.slice(0, 2)}
                  locations={props.locations.slice(2)}
                  selectedIDs={filteredLocationIDs}
                  onSetSelectedIDs={handleSelectLocation}
                />
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={timePickerTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label='Start Date'
                        value={currentState.startAt}
                        disabled={isEmpty(filteredLocationIDs)}
                        shouldDisableDate={(date) => !isFutureTuesday(date)}
                        onChange={(newStartAt) =>
                          setCurrentState({
                            ...currentState,
                            startAt: newStartAt ?? getNextTuesday(),
                          })
                        }
                        renderInput={(
                          params: JSX.IntrinsicAttributes & TextFieldProps,
                        ) => (
                          <TextField
                            {...params}
                            size='small'
                            className={classes.datePicker}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Box>
              <Grid container spacing={1.5} sx={{ mt: 1.5 }}>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'targetP90DeliveryTime'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Target P90 Delivery Time'
                    minValue={20}
                    maxValue={80}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'targetP90DeliveryDriverControllableTime'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Target P90 Delivery Driver Controllable Time'
                    minValue={20}
                    maxValue={80}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'maxDeltaOrdersPerDriverPercent'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Max Delta Orders Per Driver Hour'
                    minValue={0}
                    maxValue={0.5}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'maxDeltaCommission'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Max Delta Commission'
                    minValue={0}
                    maxValue={0.5}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'timeRateLowerBound'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Time Rate Lower Bound'
                    minValue={0.05}
                    maxValue={0.8}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'timeRateUpperBound'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Time Rate Upper Bound'
                    minValue={0.05}
                    maxValue={0.8}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SettingsNumberInput
                    currentState={currentState}
                    setter={setCurrentState}
                    fieldName={'minTimeRateMissingData'}
                    disabled={isEmpty(filteredLocationIDs)}
                    placeholder='Min Time Rate Missing Data'
                    minValue={0.1}
                    maxValue={0.5}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentState.dcpoNeutralOverWeek}
                        onChange={() =>
                          setCurrentState({
                            ...currentState,
                            dcpoNeutralOverWeek:
                              !currentState.dcpoNeutralOverWeek,
                          })
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        color='primary'
                      />
                    }
                    label={
                      <Typography className={sharedClasses.body2}>
                        DCPO Neutral Over Week
                      </Typography>
                    }
                    labelPlacement='start'
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentState.noCommissionIncrease}
                        onChange={() =>
                          setCurrentState({
                            ...currentState,
                            noCommissionIncrease:
                              !currentState.noCommissionIncrease,
                          })
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        color='primary'
                      />
                    }
                    label={
                      <Typography className={sharedClasses.body2}>
                        No Commission Increase
                      </Typography>
                    }
                    labelPlacement='start'
                  />
                </Grid>
              </Grid>
              {currentState.minMaxFares && (
                <Grid container spacing={1.5} sx={{ mt: 1.5 }}>
                  <Grid item xs={4}>
                    <MinMaxFaresNumberInput
                      currentState={currentState.minMaxFares}
                      setter={handleChangeMinMaxFares}
                      fieldName={'minFareFirstOrder'}
                      placeholder='Min Fare (First Order)'
                      minValue={0}
                      maxValue={10}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MinMaxFaresNumberInput
                      currentState={currentState.minMaxFares}
                      setter={handleChangeMinMaxFares}
                      fieldName={'minFareSubsequentOrder'}
                      placeholder='Min Fare (Subsequent Order)'
                      minValue={0}
                      maxValue={10}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MinMaxFaresNumberInput
                      currentState={currentState.minMaxFares}
                      setter={handleChangeMinMaxFares}
                      fieldName={'maxFarePerOrder'}
                      placeholder='Max Fare Per Order'
                      minValue={0}
                      maxValue={10}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MinMaxFaresNumberInput
                      currentState={currentState.minMaxFares}
                      setter={handleChangeMinMaxFares}
                      fieldName={'minFareFirstOrderLowerBound'}
                      placeholder='Min Fare First Order Lower Bound'
                      minValue={0}
                      maxValue={10}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MinMaxFaresNumberInput
                      currentState={currentState.minMaxFares}
                      setter={handleChangeMinMaxFares}
                      fieldName={'minFareFirstOrderUpperBound'}
                      placeholder='Min Fare First Order Upper Bound'
                      minValue={0}
                      maxValue={10}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box component='div' className={classes.actionButtonDivider} />
            <Button
              variant='contained'
              color='inherit'
              onClick={props.onClose}
              className={sharedClasses.buttonText}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={invalidSettings || isEmpty(filteredLocationIDs)}
              type='submit'
              className={sharedClasses.buttonText}
            >
              Confirm
            </Button>
          </DialogActions>
        </ValidatorForm>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: sharedColors.gray6,
    },
    dialogContent: {
      flexDirection: 'column',
    },
    actionButtonDivider: {
      flex: '1 0 0',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    datePicker: {
      width: 170,
      marginLeft: theme.spacing(1.5),
    },
  }),
);

export default NewSettingsDialog;
