import { NumberRange, NumberRangeOutputTransformer } from '../NumberRange';

export interface MissionSegmentToCreateStratification {
  byMetro: boolean;
  byDriverHours: NumberRange[];
}

export const missionSegmentToCreateStratificationOutputTransformer = (
  stratification: MissionSegmentToCreateStratification,
) => ({
  by_metro: stratification.byMetro,
  by_driver_hours: stratification.byDriverHours.map((driverHoursRange) =>
    NumberRangeOutputTransformer(driverHoursRange),
  ),
});

export interface MissionSegmentToCreateExperimentation {
  isExperiment: boolean;
  stratification: MissionSegmentToCreateStratification;
}

export const missionSegmentToCreateExperimentationOutputTransformer = (
  experimentation: MissionSegmentToCreateExperimentation,
) => ({
  is_experiment: experimentation.isExperiment,
  stratification: missionSegmentToCreateStratificationOutputTransformer(
    experimentation.stratification,
  ),
});
