// eslint-disable-next-line import/no-extraneous-dependencies
import { OktaAuth } from '@okta/okta-auth-js';
import config from '../config';
import { UserType } from '../interfaces/Users';

// @ts-ignore
const oktaAuth = new OktaAuth(config.OKTA);

export const getUserTypesFromToken = (): UserType[] => {
  const token = localStorage.getItem('okta-token-storage');
  if (!token) {
    return [];
  }

  const parsedToken = JSON.parse(token);
  if (parsedToken?.accessToken?.claims?.userType) {
    return parsedToken.accessToken.claims.userType as UserType[];
  }

  return [];
};

export const getExpiredAtFromToken = (): Date | null => {
  const token = localStorage.getItem('okta-token-storage');
  if (!token) {
    return null;
  }

  const parsedToken = JSON.parse(token);
  if (parsedToken?.accessToken?.expiresAt) {
    return new Date(parsedToken.accessToken.expiresAt * 1000);
  }

  return null;
};

export default oktaAuth;
