import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isEmpty } from 'lodash';
import { DriverSupportSummary } from '../../interfaces/PayHistory/DriverSupportSummary';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import PayoutProviderCard from './PayoutProviderCard';

interface DriverSupportInfoProps {
  driverSupportSummary: DriverSupportSummary;
  onResetStripe: () => void;
}

const DriverSupportInfo = (props: DriverSupportInfoProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' className={classes.wrapper}>
      <Box component='div' className={classes.row}>
        <Box component='div' className={classes.column}>
          <Box component='div' className={classes.detailsRow}>
            <Typography className={sharedClasses.h3}>
              {props.driverSupportSummary.name}
            </Typography>
            {props.driverSupportSummary.isRestrictedAccount && (
              <Chip
                className={classes.restrictedChip}
                label={
                  <Typography className={classes.restrictedChipText}>
                    Restricted
                  </Typography>
                }
              />
            )}
          </Box>
          <Box component='div' className={classes.detailsRow}>
            <Typography
              className={multiClass([sharedClasses.body1, classes.fieldName])}
            >
              ID:
            </Typography>
            <Typography className={sharedClasses.body1}>
              {props.driverSupportSummary.driverId}
            </Typography>
          </Box>
          <Box component='div' className={classes.detailsRow}>
            <Typography
              className={multiClass([sharedClasses.body1, classes.fieldName])}
            >
              E-mail:
            </Typography>
            <Typography className={sharedClasses.body1}>
              {props.driverSupportSummary.email}
            </Typography>
          </Box>
          <Box component='div' className={classes.detailsRow}>
            <Typography
              className={multiClass([sharedClasses.body1, classes.fieldName])}
            >
              Phone:
            </Typography>
            <Typography className={sharedClasses.body1}>
              {props.driverSupportSummary.phoneNumber}
            </Typography>
          </Box>
          <Box component='div' className={classes.detailsRow}>
            <Typography
              className={multiClass([sharedClasses.body1, classes.fieldName])}
            >
              Employment Status:
            </Typography>
            <Typography className={sharedClasses.body1}>
              {props.driverSupportSummary.employmentStatus}
            </Typography>
          </Box>
        </Box>
        {!isEmpty(props.driverSupportSummary.payoutProviders) && (
          <Box component='div' className={classes.payoutColumn}>
            <Box component='div' className={classes.row}>
              {props.driverSupportSummary.payoutProviders.map((provider) => (
                <PayoutProviderCard
                  payoutProviderSummary={provider}
                  onReset={
                    !!props.driverSupportSummary.stripeAccountID &&
                    provider.name === 'stripe'
                      ? props.onResetStripe
                      : undefined
                  }
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(7),
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  },
  verticalSpacer: {
    marginTop: theme.spacing(1),
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  statusIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  fieldName: {
    color: sharedColors.gray5,
    marginRight: theme.spacing(0.5),
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1.25),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  payoutColumn: {
    marginTop: theme.spacing(1.25),
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
  },
  resetStripeButton: {
    width: 'fit-content',
    padding: 0,
    marginTop: theme.spacing(0.5),
  },
  restrictedChip: {
    marginLeft: theme.spacing(1),
    backgroundColor: sharedColors.statusRed,
    color: sharedColors.statusRedLightest,
  },
  restrictedChipText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export default DriverSupportInfo;
