import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { WaitPayBreakdown } from '../../../interfaces/PayHistory/WaitPayBreakdown';
import CustomTooltip from './CustomTooltip';
import { multiClass } from '../../../utilities/Extensions';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';

interface WaitPayTooltipProps {
  waitPayBreakdown: WaitPayBreakdown;
  currency: string;
  children: JSX.Element;
}

const WaitPayTooltip = (props: WaitPayTooltipProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <CustomTooltip
      title={
        <Box className={classes.mainContainer}>
          <Typography
            className={multiClass([
              classes.numericCalculation,
              sharedClasses.subtitle1,
            ])}
          >
            {`${props.waitPayBreakdown.roundedTotalWaitMinutes.toString()} * ${parseCentsAsCurrencyString(
              props.waitPayBreakdown.minGuaranteeMinuteRate,
              props.currency,
            )} = ${parseCentsAsCurrencyString(
              props.waitPayBreakdown.amount,
              props.currency,
            )}`}
          </Typography>
          <Box component='div' className={classes.divider} />
          <Typography
            className={multiClass([
              classes.symbolicCalculation,
              sharedClasses.caption,
            ])}
          >
            Rounded Total Wait Minutes * Wait Pay Rate = Wait Pay
          </Typography>
        </Box>
      }
    >
      {props.children}
    </CustomTooltip>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  divider: {
    height: 1,
    display: 'flex',
    backgroundColor: sharedColors.gray6,
    flexGrow: 1,
  },
  numericCalculation: {
    color: sharedColors.white,
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(0.8),
  },
  symbolicCalculation: {
    marginTop: theme.spacing(0.8),
    color: sharedColors.white,
    marginBottom: theme.spacing(1.2),
  },
}));

export default WaitPayTooltip;
