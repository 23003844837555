import { parseCentsAsCurrencyString } from '../../../utilities/Currency';
import SingleTextNode from './SingleTextNode';

interface SinglePayoutNodeProps {
  id: string;
  name: string;
  amount: number;
  currency: string;
  unit?: string;
}

const SinglePayoutNode = (props: SinglePayoutNodeProps) => {
  return (
    <SingleTextNode
      id={props.id}
      name={props.name}
      value={
        parseCentsAsCurrencyString(props.amount, props.currency) +
        (props.unit ? `/${props.unit}` : '')
      }
    />
  );
};

export default SinglePayoutNode;
