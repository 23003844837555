import {
  WeeklyBonusBreakdown,
  weeklyBonusBreakdownFromResponse,
} from './WeeklyBonusBreakdown';
import {
  Prop22PayBreakdown,
  prop22PayBreakdownFromResponse,
} from './Prop22PayBreakdown';
import {
  AdjustmentSummary,
  adjustmentSummaryFromResponse,
} from './AdjustmentSummary';
import {
  MinGuaranteeAdjustmentBreakdown,
  minGuaranteeAdjustmentBreakdownFromResponse,
} from './MinGuaranteeAdjustmentBreakdown';
import {
  DailyPayoutBreakdown,
  dailyPayoutBreakdownFromResponse,
} from './DailyPayoutBreakdown';
import {
  WaitPayBreakdown,
  waitPayBreakdownFromResponse,
} from './WaitPayBreakdown';
import {
  EngagedSubsidyBreakdown,
  engagedSubsidyBreakdownFromResponse,
} from './EngagedSubsidyBreakdown';
import {
  EngagedDistancePayBreakdown,
  engagedDistancePayBreakdownFromResponse,
} from './EngagedDistancePayBreakdown';
import { easternTimezone } from '../../utilities/Dates';
import {
  MissionBreakdown,
  missionBreakdownFromResponse,
} from './MissionBreakdown';
import {
  HolidayPayBreakdown,
  holidayPayBreakdownFromResponse,
} from './HolidayPayBreakdown';
import {
  ReimbursementPayBreakdown,
  reimbursementPayBreakdownFromResponse,
} from './ReimbursementPayBreakdown';

export interface PayoutBreakdown {
  timezone: string;
  orderCount: number;
  scheduledOrderCount: number;
  unscheduledOrderCount: number;
  totalAmount: number;
  hours: number;
  weeklyBonusBreakdown?: WeeklyBonusBreakdown;
  firstShiftBonus: number;
  overtimePay: number;
  prop22PayBreakdown?: Prop22PayBreakdown;
  engagedSubsidyPay: number;
  engagedSubsidyBreakdowns: EngagedSubsidyBreakdown[];
  engagedDistancePay: number;
  engagedDistancePayBreakdowns: EngagedDistancePayBreakdown[];
  totalAdjustment: number;
  adjustments: AdjustmentSummary[];
  basePay: number;
  boostPay: number;
  doXGetY: number;
  hazardPay: number;
  tier2DeliveryPay: number;
  tobaccoPay: number;
  returnCocPay: number;
  seattleEngagedPay?: number;
  reimbursementPay: number;
  tips: number;
  minGuaranteeAdjustmentBreakdowns: MinGuaranteeAdjustmentBreakdown[];
  totalMinGuaranteeAdjustment: number;
  weeklyWaitPayBreakdowns: WaitPayBreakdown[];
  totalWeeklyWaitPay: number;
  totalShiftBasedWaitPay: number;
  shiftBasedWaitPayWeeklyBreakdowns: WaitPayBreakdown[];
  missionBreakdowns: MissionBreakdown[];
  holidayPayBreakdown: HolidayPayBreakdown;
  reimbursementPayBreakdowns: ReimbursementPayBreakdown[];
  dailyPayoutBreakdowns: DailyPayoutBreakdown[];
}

export const payoutBreakdownFromResponse = (
  responseData: any,
): PayoutBreakdown => ({
  timezone: responseData.timezone ?? easternTimezone,
  orderCount: responseData.order_count ?? 0,
  scheduledOrderCount: responseData.scheduled_order_count ?? 0,
  unscheduledOrderCount: responseData.unscheduled_order_count ?? 0,
  totalAmount: responseData.total_amount ?? 0,
  hours: responseData.hours ?? 0,
  weeklyBonusBreakdown:
    responseData.weekly_bonus_breakdown &&
    weeklyBonusBreakdownFromResponse(responseData.weekly_bonus_breakdown),
  firstShiftBonus: responseData.first_shift_bonus ?? 0,
  overtimePay: responseData.overtime_pay ?? 0,
  prop22PayBreakdown:
    responseData.prop22_pay_breakdown &&
    prop22PayBreakdownFromResponse(responseData.prop22_pay_breakdown),
  engagedSubsidyPay: responseData.engaged_subsidy_pay ?? 0,
  engagedSubsidyBreakdowns: responseData.engaged_subsidy_breakdowns.map(
    (breakdown: any) => engagedSubsidyBreakdownFromResponse(breakdown),
  ),
  engagedDistancePay: responseData.engaged_distance_pay ?? 0,
  engagedDistancePayBreakdowns:
    responseData.engaged_distance_pay_breakdowns.map((breakdown: any) =>
      engagedDistancePayBreakdownFromResponse(breakdown),
    ),
  totalAdjustment: responseData.total_adjustment ?? 0,
  adjustments: responseData.adjustments.map((adjustment: any) =>
    adjustmentSummaryFromResponse(adjustment),
  ),
  basePay: responseData.base_pay ?? 0,
  boostPay: responseData.boost_pay ?? 0,
  doXGetY: responseData.do_x_get_y ?? 0,
  hazardPay: responseData.hazard_pay ?? 0,
  tier2DeliveryPay: responseData.tier2_delivery_pay ?? 0,
  tobaccoPay: responseData.tobacco_pay ?? 0,
  returnCocPay: responseData.return_coc_pay ?? 0,
  seattleEngagedPay: responseData.seattle_engaged_pay,
  reimbursementPay: responseData.reimbursement_pay ?? 0,
  tips: responseData.tips ?? 0,
  minGuaranteeAdjustmentBreakdowns:
    responseData.min_guarantee_adjustment_breakdowns.map((breakdown: any) =>
      minGuaranteeAdjustmentBreakdownFromResponse(breakdown),
    ),
  totalMinGuaranteeAdjustment: responseData.total_min_guarantee_adjustment ?? 0,
  weeklyWaitPayBreakdowns: responseData.weekly_wait_pay_breakdowns.map(
    (breakdown: any) => waitPayBreakdownFromResponse(breakdown),
  ),
  totalWeeklyWaitPay: responseData.total_weekly_wait_pay ?? 0,
  totalShiftBasedWaitPay: responseData.total_shift_based_wait_pay ?? 0,
  shiftBasedWaitPayWeeklyBreakdowns: (
    responseData.shift_based_wait_pay_weekly_breakdowns ?? []
  ).map((breakdown: any) => waitPayBreakdownFromResponse(breakdown)),
  missionBreakdowns: (responseData.mission_breakdowns ?? []).map(
    (mission: any) => missionBreakdownFromResponse(mission),
  ),
  holidayPayBreakdown:
    responseData.holiday_pay_breakdown &&
    holidayPayBreakdownFromResponse(responseData.holiday_pay_breakdown),
  reimbursementPayBreakdowns: (
    responseData.reimbursement_pay_breakdowns ?? []
  ).map((breakdown: any) => reimbursementPayBreakdownFromResponse(breakdown)),
  dailyPayoutBreakdowns: responseData.daily_payout_breakdowns.map(
    (breakdown: any) => dailyPayoutBreakdownFromResponse(breakdown),
  ),
});
