import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';
import { flatten, isEmpty } from 'lodash';
import { PayoutBreakdown } from '../../interfaces/PayHistory/PayoutBreakdown';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { workweekRangeStringWithoutYear } from '../../utilities/Workweek';
import { multiClass } from '../../utilities/Extensions';
import { parseCentsAsCurrencyString } from '../../utilities/Currency';
import KeyStats from './KeyStats';
import SummaryDetailRow from './SummaryDetailRow';
import WeeklyPayoutTree from './PayoutTree/WeeklyPayoutTree';
import {
  adjustmentNoteNodeID,
  adjustmentsNodeID,
  adjustmentSubtypeNodeID,
  adjustmentTypeNodeID,
  allWeeklyBonusLocationsNodeID,
  amountNodeID,
  applicableWeeklyBonusLocationNodeID,
  basePayNodeID,
  boostPayNodeID,
  composeNodeID,
  doXGetYNodeID,
  engagedDistancePaysNodeID,
  engagedDistanceRateNodeID,
  engagedHoursNodeID,
  engagedHoursWorkedAmountNodeID,
  engagedHoursWorkedRateNodeID,
  engagedHoursWorkedWithRateNodeID,
  engagedLocationNameNodeID,
  engagedLocationNodeID,
  engagedLocationsNodeID,
  engagedMilesAmountNodeID,
  engagedMilesNodeID,
  engagedMilesRateNodeID,
  engagedMilesWithRateNodeID,
  engagedSubsidyPaysNodeID,
  engagedSubsidyRateNodeID,
  engagedWeeklyBonusPayNodeID,
  firstShiftNodeID,
  hazardPayNodeID,
  holidayPayAccruedDaysNodeID,
  holidayPayBreakdownsNodeID,
  holidayPayRateNodeID,
  hoursNodeID,
  minGuaranteeAdjustmentLocationNodeID,
  minGuaranteeAdjustmentNodeID,
  minGuaranteeAdjustmentsNodeID,
  minGuaranteeRateNodeID,
  missionAcceptedAtNodeID,
  missionBreakdownsNodeID,
  missionEndAtLocalNodeID,
  missionInvitedAtNodeID,
  missionNameNodeID,
  missionOrdersMustContainNodeID,
  missionProgressNodeID,
  missionProgressSummaryOrderNodeID,
  missionProgressSummaryTripNodeID,
  missionRewardNodeID,
  missionRewardSummaryPayNodeID,
  missionRewardSummaryTierCountNodeID,
  missionRewardSummaryTierPayNodeID,
  missionRewardSummaryTiersNodeID,
  missionRewardSummaryTierTypeNodeID,
  missionStartAtLocalNodeID,
  missionTypeNodeID,
  orderCountNodeID,
  overtimePayNodeID,
  prop22PayNodeID,
  reimbursementPayNodeID,
  reimbursementPaysNodeID,
  returnCocPayNodeID,
  roundedTotalWaitMinutesNodeID,
  scheduledHoursNodeID,
  scheduledOrderCountNodeID,
  seattleEngagedPayNodeID,
  shiftBasedWaitPayWeeklyBreakdownsNodeID,
  shiftEventsNodeID,
  shiftHoursWorkedNodeID,
  shiftsEndTimeNodeID,
  shiftsNodeID,
  shiftsScheduledEndNodeID,
  shiftsScheduledStartNodeID,
  shiftsStartTimeNodeID,
  shiftsWithEventsNodeID,
  shiftWithEventsActualEndNodeID,
  shiftWithEventsActualStartNodeID,
  subsidizableHoursWorkedAmountNodeID,
  subsidizableHoursWorkedNodeID,
  subsidizableHoursWorkedRateNodeID,
  subsidyVersionNodeID,
  tier2DeliveryPayNodeID,
  tierXAmountNodeIDGenerator,
  tierXOrdersNodeIDGenerator,
  tipsNodeID,
  tobaccoPayNodeID,
  totalAdjustmentNodeID,
  totalAmountNodeID,
  totalEarningsFloorNodeID,
  totalEngagedDistancePayNodeID,
  totalEngagedSubsidyPayNodeID,
  totalMinGuaranteeAdjustmentNodeID,
  totalWaitSecondsNodeID,
  unscheduledOrderCountNodeID,
  waitPayBreakdownsNodeID,
  waitPayIsCalculatedWeeklyNodeID,
  waitPayMinGuaranteeMinuteRateNodeID,
  weeklyBonusDeliveryCountNodeID,
  weeklyBonusLocationNameNodeID,
  weeklyBonusNodeID,
  weeklyBreakdownNodeID,
  weeklyNodePrefix,
} from '../../variables/PayHistory';
import { ShiftBreakdown } from '../../interfaces/PayHistory/ShiftBreakdown';
import { MinGuaranteeAdjustmentBreakdown } from '../../interfaces/PayHistory/MinGuaranteeAdjustmentBreakdown';
import WeeklyTotalAmountTooltip from './Tooltip/WeeklyTotalAmountTooltip';
import MinGuaranteeAdjustmentTooltip from './Tooltip/MinGuaranteeAdjustmentTooltip';
import Prop22PayTooltip from './Tooltip/Prop22PayTooltip';
import { ShiftWithEventsBreakdown } from '../../interfaces/PayHistory/ShiftWithEventsBreakdown';
import { WaitPayBreakdown } from '../../interfaces/PayHistory/WaitPayBreakdown';
import WaitPayTooltip from './Tooltip/WaitPayTooltip';
import WaitPayLocations from './WaitPayLocations';
import SummaryTextRow from './SummaryTextRow';
import { MissionRewardSummary } from '../../interfaces/PayHistory/MissionRewardSummary';
import { MissionBreakdown } from '../../interfaces/PayHistory/MissionBreakdown';
import { AppliedRate } from '../../interfaces/PayHistory/AppliedRate';

interface WeeklyPayoutBreakdownProps {
  workweek: Date;
  payoutBreakdown: PayoutBreakdown;
  currency: string;
}
const WeeklyPayoutBreakdown = (props: WeeklyPayoutBreakdownProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [highlightedNodes, setHighlightedNodes] = useState<string[]>([]);
  const [expandedNodes, setExpandedNodes] = useState([weeklyBreakdownNodeID]);

  const handleHoverTotalAmount = () => {
    let newExpandedNodes = [...expandedNodes];
    let newHighlightedNodes: string[] = [
      composeNodeID([weeklyNodePrefix, totalAmountNodeID]),
      composeNodeID([weeklyNodePrefix, tipsNodeID]),
      firstShiftNodeID,
      overtimePayNodeID,
      totalAdjustmentNodeID,
      composeNodeID([weeklyNodePrefix, basePayNodeID]),
      composeNodeID([weeklyNodePrefix, boostPayNodeID]),
      composeNodeID([weeklyNodePrefix, doXGetYNodeID]),
      composeNodeID([weeklyNodePrefix, hazardPayNodeID]),
      composeNodeID([weeklyNodePrefix, tier2DeliveryPayNodeID]),
      composeNodeID([weeklyNodePrefix, tobaccoPayNodeID]),
      composeNodeID([weeklyNodePrefix, returnCocPayNodeID]),
      composeNodeID([weeklyNodePrefix, seattleEngagedPayNodeID]),
      composeNodeID([weeklyNodePrefix, reimbursementPayNodeID]),
      waitPayBreakdownsNodeID,
      shiftBasedWaitPayWeeklyBreakdownsNodeID,
      totalMinGuaranteeAdjustmentNodeID,
      totalEngagedSubsidyPayNodeID,
      totalEngagedDistancePayNodeID,
      holidayPayBreakdownsNodeID,
    ];

    if (props.payoutBreakdown.weeklyBonusBreakdown) {
      newExpandedNodes = [...newExpandedNodes, weeklyBonusNodeID];

      newHighlightedNodes = [
        ...newHighlightedNodes,
        composeNodeID([weeklyBonusNodeID, amountNodeID]),
      ];
    }

    if (props.payoutBreakdown.prop22PayBreakdown) {
      newExpandedNodes = [...newExpandedNodes, prop22PayNodeID];

      newHighlightedNodes = [
        ...newHighlightedNodes,
        composeNodeID([prop22PayNodeID, amountNodeID]),
      ];
    }

    if (props.payoutBreakdown.holidayPayBreakdown) {
      newExpandedNodes = [...newExpandedNodes, holidayPayBreakdownsNodeID];

      newHighlightedNodes = [
        ...newHighlightedNodes,
        composeNodeID([holidayPayBreakdownsNodeID, amountNodeID]),
      ];
    }

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverWeeklyBonus = () => {
    const getWeeklyBonusLocationNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, weeklyBonusLocationNameNodeID]),
      composeNodeID([prefix, weeklyBonusDeliveryCountNodeID]),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      weeklyBonusNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes: string[] = [
      weeklyBonusNodeID,
      ...getWeeklyBonusLocationNodeIDs(
        composeNodeID([weeklyBonusNodeID, applicableWeeklyBonusLocationNodeID]),
      ),
      composeNodeID([weeklyBonusNodeID, allWeeklyBonusLocationsNodeID]),
      ...flatten(
        props.payoutBreakdown.weeklyBonusBreakdown?.allWeeklyBonusLocations.map(
          (_, i) =>
            getWeeklyBonusLocationNodeIDs(
              composeNodeID([
                weeklyBonusNodeID,
                allWeeklyBonusLocationsNodeID,
                i.toString(),
              ]),
            ),
        ),
      ),
      ...flatten(
        [1, 2, 3, 4].map((tier) => [
          composeNodeID([weeklyBonusNodeID, tierXOrdersNodeIDGenerator(tier)]),
          composeNodeID([weeklyBonusNodeID, tierXAmountNodeIDGenerator(tier)]),
        ]),
      ),
      composeNodeID([weeklyBonusNodeID, amountNodeID]),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverProp22Pay = () => {
    const getEngagedHoursNodeIDs = (
      hours: AppliedRate[],
      prefix: string,
    ): string[] => [
      prefix,
      ...flatten(
        hours.map((_, i) => [
          composeNodeID([prefix, i.toString()]),
          composeNodeID([prefix, i.toString(), engagedHoursWorkedAmountNodeID]),
          composeNodeID([prefix, i.toString(), engagedHoursWorkedRateNodeID]),
        ]),
      ),
    ];

    const getEngagedMilesNodeIDs = (
      miles: AppliedRate[],
      prefix: string,
    ): string[] => [
      prefix,
      ...flatten(
        miles.map((_, i) => [
          composeNodeID([prefix, i.toString()]),
          composeNodeID([prefix, i.toString(), engagedMilesAmountNodeID]),
          composeNodeID([prefix, i.toString(), engagedMilesRateNodeID]),
        ]),
      ),
    ];

    const getEngagedLocationNodeIDs = (
      engagedHours: AppliedRate[],
      engagedMiles: AppliedRate[],
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, engagedLocationNameNodeID]),
      composeNodeID([prefix, engagedHoursWorkedWithRateNodeID]),
      ...getEngagedHoursNodeIDs(
        engagedHours,
        composeNodeID([prefix, engagedHoursWorkedWithRateNodeID]),
      ),
      composeNodeID([prefix, engagedMilesWithRateNodeID]),
      ...getEngagedMilesNodeIDs(
        engagedMiles,
        composeNodeID([prefix, engagedMilesWithRateNodeID]),
      ),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      prop22PayNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      prop22PayNodeID,
      composeNodeID([prop22PayNodeID, engagedLocationsNodeID]),
      ...flatten(
        props.payoutBreakdown.prop22PayBreakdown?.engagedLocationContributions.map(
          (locations, i) =>
            getEngagedLocationNodeIDs(
              locations.engagedHoursWithRate,
              locations.engagedMilesWithRate,
              composeNodeID([
                prop22PayNodeID,
                engagedLocationNodeID,
                i.toString(),
              ]),
            ),
        ),
      ),
      composeNodeID([prop22PayNodeID, totalEarningsFloorNodeID]),
      composeNodeID([prop22PayNodeID, basePayNodeID]),
      composeNodeID([prop22PayNodeID, doXGetYNodeID]),
      composeNodeID([prop22PayNodeID, returnCocPayNodeID]),
      composeNodeID([prop22PayNodeID, minGuaranteeAdjustmentNodeID]),
      composeNodeID([prop22PayNodeID, boostPayNodeID]),
      composeNodeID([prop22PayNodeID, hazardPayNodeID]),
      composeNodeID([prop22PayNodeID, tier2DeliveryPayNodeID]),
      composeNodeID([prop22PayNodeID, tobaccoPayNodeID]),
      composeNodeID([prop22PayNodeID, weeklyBonusNodeID]),
      composeNodeID([prop22PayNodeID, amountNodeID]),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverAdjustment = () => {
    const getAdjustmentNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, amountNodeID]),
      composeNodeID([prefix, adjustmentTypeNodeID]),
      composeNodeID([prefix, adjustmentSubtypeNodeID]),
      composeNodeID([prefix, adjustmentNoteNodeID]),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      adjustmentsNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      totalAdjustmentNodeID,
      adjustmentsNodeID,
      ...flatten(
        props.payoutBreakdown.adjustments.map((_, i) =>
          getAdjustmentNodeIDs(
            composeNodeID([adjustmentsNodeID, i.toString()]),
          ),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverMinGuaranteeAdjustment = (index: number) => {
    const getMinGuaranteeShiftsNodeIDs = (
      shifts: ShiftBreakdown[],
      prefix: string,
    ): string[] => [
      prefix,
      ...flatten(
        shifts.map((shift, i) => [
          composeNodeID([prefix, i.toString()]),
          composeNodeID([prefix, i.toString(), shiftsScheduledStartNodeID]),
          composeNodeID([prefix, i.toString(), shiftsScheduledEndNodeID]),
          composeNodeID([prefix, i.toString(), shiftsStartTimeNodeID]),
          composeNodeID([prefix, i.toString(), shiftsEndTimeNodeID]),
          composeNodeID([prefix, i.toString(), shiftHoursWorkedNodeID]),
        ]),
      ),
    ];

    const getMinGuaranteeHoursNodeIDs = (
      hours: AppliedRate[],
      prefix: string,
    ): string[] => [
      prefix,
      ...flatten(
        hours.map((hour, i) => [
          composeNodeID([prefix, i.toString()]),
          composeNodeID([
            prefix,
            i.toString(),
            subsidizableHoursWorkedAmountNodeID,
          ]),
          composeNodeID([
            prefix,
            i.toString(),
            subsidizableHoursWorkedRateNodeID,
          ]),
        ]),
      ),
    ];

    const getMinGuaranteeAdjustmentNodeIDs = (
      minGuaranteeAdjustment: MinGuaranteeAdjustmentBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, minGuaranteeAdjustmentLocationNodeID]),
      ...getMinGuaranteeShiftsNodeIDs(
        minGuaranteeAdjustment.shifts,
        composeNodeID([prefix, shiftsNodeID]),
      ),
      composeNodeID([prefix, minGuaranteeRateNodeID]),
      composeNodeID([prefix, basePayNodeID]),
      composeNodeID([prefix, boostPayNodeID]),
      composeNodeID([prefix, scheduledHoursNodeID]),
      composeNodeID([prefix, amountNodeID]),
      composeNodeID([prefix, subsidyVersionNodeID]),
      composeNodeID([prefix, subsidizableHoursWorkedNodeID]),
      ...getMinGuaranteeHoursNodeIDs(
        minGuaranteeAdjustment.subsidizableHoursWorked,
        composeNodeID([prefix, subsidizableHoursWorkedNodeID]),
      ),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      minGuaranteeAdjustmentsNodeID,
      composeNodeID([minGuaranteeAdjustmentNodeID, index.toString()]),
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      totalMinGuaranteeAdjustmentNodeID,
      minGuaranteeAdjustmentsNodeID,
      ...flatten(
        getMinGuaranteeAdjustmentNodeIDs(
          props.payoutBreakdown.minGuaranteeAdjustmentBreakdowns[index],
          composeNodeID([minGuaranteeAdjustmentNodeID, index.toString()]),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverEngagedSubsidy = () => {
    const getEngagedSubsidyNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, engagedLocationNameNodeID]),
      composeNodeID([prefix, engagedHoursNodeID]),
      composeNodeID([prefix, engagedSubsidyRateNodeID]),
      composeNodeID([prefix, basePayNodeID]),
      composeNodeID([prefix, boostPayNodeID]),
      composeNodeID([prefix, engagedWeeklyBonusPayNodeID]),
      composeNodeID([prefix, amountNodeID]),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      engagedSubsidyPaysNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      totalEngagedSubsidyPayNodeID,
      engagedSubsidyPaysNodeID,
      ...flatten(
        props.payoutBreakdown.engagedSubsidyBreakdowns.map((_, i) =>
          getEngagedSubsidyNodeIDs(
            composeNodeID([engagedSubsidyPaysNodeID, i.toString()]),
          ),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverEngagedDistancePay = () => {
    const getEngagedDistancePayNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, engagedLocationNameNodeID]),
      composeNodeID([prefix, engagedMilesNodeID]),
      composeNodeID([prefix, engagedDistanceRateNodeID]),
      composeNodeID([prefix, amountNodeID]),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      engagedDistancePaysNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      totalEngagedDistancePayNodeID,
      engagedDistancePaysNodeID,
      ...flatten(
        props.payoutBreakdown.engagedDistancePayBreakdowns.map((_, i) =>
          getEngagedDistancePayNodeIDs(
            composeNodeID([engagedDistancePaysNodeID, i.toString()]),
          ),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverWeeklyWaitPay = () => {
    const getShiftWithEventsBreakdownNodeIDs = (
      shiftWithEventsBreakdown: ShiftWithEventsBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, shiftWithEventsActualStartNodeID]),
      composeNodeID([prefix, shiftWithEventsActualEndNodeID]),
      composeNodeID([prefix, totalWaitSecondsNodeID]),
      composeNodeID([prefix, roundedTotalWaitMinutesNodeID]),
      composeNodeID([prefix, shiftEventsNodeID]),
      ...shiftWithEventsBreakdown.events.map((_, i) =>
        composeNodeID([prefix, shiftEventsNodeID, i.toString()]),
      ),
    ];

    const getWaitPayBreakdownNodeIDs = (
      waitPayBreakdown: WaitPayBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, totalWaitSecondsNodeID]),
      composeNodeID([prefix, roundedTotalWaitMinutesNodeID]),
      composeNodeID([prefix, waitPayMinGuaranteeMinuteRateNodeID]),
      ...getShiftWithEventsBreakdownNodeIDs(
        waitPayBreakdown.shiftWithEvents,
        composeNodeID([prefix, shiftsWithEventsNodeID]),
      ),
      composeNodeID([prefix, waitPayIsCalculatedWeeklyNodeID]),
      composeNodeID([prefix, amountNodeID]),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      waitPayBreakdownsNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      waitPayBreakdownsNodeID,
      ...flatten(
        props.payoutBreakdown.weeklyWaitPayBreakdowns.map((breakdown, i) =>
          getWaitPayBreakdownNodeIDs(
            breakdown,
            composeNodeID([waitPayBreakdownsNodeID, i.toString()]),
          ),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverShiftBasedWaitPay = () => {
    const getWaitPayBreakdownNodeIDs = (
      waitPayBreakdown: WaitPayBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, totalWaitSecondsNodeID]),
      composeNodeID([prefix, roundedTotalWaitMinutesNodeID]),
      composeNodeID([prefix, waitPayMinGuaranteeMinuteRateNodeID]),
      composeNodeID([prefix, amountNodeID]),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      shiftBasedWaitPayWeeklyBreakdownsNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      shiftBasedWaitPayWeeklyBreakdownsNodeID,
      ...flatten(
        props.payoutBreakdown.shiftBasedWaitPayWeeklyBreakdowns.map(
          (breakdown, i) =>
            getWaitPayBreakdownNodeIDs(
              breakdown,
              composeNodeID([
                shiftBasedWaitPayWeeklyBreakdownsNodeID,
                i.toString(),
              ]),
            ),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverMissionBreakdowns = () => {
    const getMissionProgressSummaryNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, missionProgressSummaryOrderNodeID]),
      composeNodeID([prefix, missionProgressSummaryTripNodeID]),
    ];

    const getMissionRewardTierSummaryNodeIDs = (prefix: string): string[] => [
      prefix,
      composeNodeID([prefix, missionRewardSummaryTierCountNodeID]),
      composeNodeID([prefix, missionRewardSummaryTierPayNodeID]),
    ];

    const getMissionRewardSummaryNodeIDs = (
      missionRewardSummary: MissionRewardSummary,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, missionRewardSummaryPayNodeID]),
      composeNodeID([prefix, missionRewardSummaryTierTypeNodeID]),
      composeNodeID([prefix, missionRewardSummaryTiersNodeID]),
      ...flatten(
        missionRewardSummary.tiers.map((_, i) =>
          getMissionRewardTierSummaryNodeIDs(
            composeNodeID([
              prefix,
              missionRewardSummaryTiersNodeID,
              i.toString(),
            ]),
          ),
        ),
      ),
    ];

    const getMissionBreakdownNodeIDs = (
      missionBreakdown: MissionBreakdown,
      prefix: string,
    ): string[] => [
      prefix,
      composeNodeID([prefix, missionNameNodeID]),
      composeNodeID([prefix, missionTypeNodeID]),
      composeNodeID([prefix, missionInvitedAtNodeID]),
      composeNodeID([prefix, missionAcceptedAtNodeID]),
      composeNodeID([prefix, missionStartAtLocalNodeID]),
      composeNodeID([prefix, missionEndAtLocalNodeID]),
      composeNodeID([prefix, missionOrdersMustContainNodeID]),
      ...getMissionRewardSummaryNodeIDs(
        missionBreakdown.reward,
        composeNodeID([prefix, missionRewardNodeID]),
      ),
      ...getMissionProgressSummaryNodeIDs(
        composeNodeID([prefix, missionProgressNodeID]),
      ),
    ];

    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      missionBreakdownsNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      missionBreakdownsNodeID,
      ...flatten(
        props.payoutBreakdown.missionBreakdowns.map((missionBreakdown, i) =>
          getMissionBreakdownNodeIDs(
            missionBreakdown,
            composeNodeID([missionBreakdownsNodeID, i.toString()]),
          ),
        ),
      ),
    ];

    setExpandedNodes(Array.from(new Set(newExpandedNodes)));
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverHolidayPay = () => {
    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      holidayPayBreakdownsNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      holidayPayBreakdownsNodeID,
      ...props.payoutBreakdown.holidayPayBreakdown.singleHolidayPayBreakdowns.flatMap(
        (holidayPayBreakdown, i) => [
          composeNodeID([holidayPayBreakdownsNodeID, i.toString()]),
          composeNodeID([
            holidayPayBreakdownsNodeID,
            i.toString(),
            holidayPayAccruedDaysNodeID,
          ]),
          composeNodeID([
            holidayPayBreakdownsNodeID,
            i.toString(),
            holidayPayRateNodeID,
          ]),
        ],
      ),
    ];

    setExpandedNodes(newExpandedNodes);
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleHoverReimbursementPay = (index: number) => {
    const newExpandedNodes = [
      weeklyBreakdownNodeID,
      reimbursementPaysNodeID,
      ...expandedNodes,
    ];

    const newHighlightedNodes = [
      composeNodeID([weeklyNodePrefix, reimbursementPayNodeID]),
      composeNodeID([reimbursementPaysNodeID, index.toString()]),
    ];

    setExpandedNodes(newExpandedNodes);
    setHighlightedNodes(newHighlightedNodes);
  };

  const handleChangeExpandedNodes = (newExpandedNodeIDs: string[]) => {
    if (!newExpandedNodeIDs.includes(weeklyBreakdownNodeID)) {
      setExpandedNodes([]);
    } else {
      setExpandedNodes(newExpandedNodeIDs);
    }
  };

  return (
    <Box component='div'>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography className={sharedClasses.h6}>
            Gopuff Driver App
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={sharedClasses.h6}>Raw Pay Data</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box component='div' className={classes.driverAppContainer}>
            <Box component='div' className={classes.centeredTextContainer}>
              <Typography
                className={multiClass([
                  classes.workweekRange,
                  sharedClasses.caption,
                ])}
              >
                {workweekRangeStringWithoutYear(props.workweek)}
              </Typography>
            </Box>
            <Box component='div' className={classes.centeredTextContainer}>
              <WeeklyTotalAmountTooltip
                payoutBreakdown={props.payoutBreakdown}
                currency={props.currency}
              >
                <Typography
                  onMouseEnter={handleHoverTotalAmount}
                  className={classes.totalAmount}
                >
                  {parseCentsAsCurrencyString(
                    props.payoutBreakdown.totalAmount,
                    props.currency,
                  )}
                </Typography>
              </WeeklyTotalAmountTooltip>
            </Box>
            <Divider className={classes.keyStatsDivider} />
            <Grid container spacing={1.5} className={classes.keyStatsGrid}>
              <Grid item xs={4}>
                <KeyStats
                  name='Deliveries'
                  value={props.payoutBreakdown.orderCount.toString()}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, orderCountNodeID]),
                      composeNodeID([
                        weeklyNodePrefix,
                        scheduledOrderCountNodeID,
                      ]),
                      composeNodeID([
                        weeklyNodePrefix,
                        unscheduledOrderCountNodeID,
                      ]),
                    ])
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <KeyStats
                  name='Hours'
                  value={props.payoutBreakdown.hours.toFixed(2)}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, hoursNodeID]),
                    ])
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <KeyStats
                  name='Tips'
                  value={parseCentsAsCurrencyString(
                    props.payoutBreakdown.tips,
                    props.currency,
                  )}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, tipsNodeID]),
                    ])
                  }
                />
              </Grid>
            </Grid>
            <Box component='div' className={classes.detailedSummaryContainer}>
              <Typography className={sharedClasses.subtitle1}>
                Weekly Summary
              </Typography>
              <Divider className={classes.weeklySummaryDivider} />
              {props.payoutBreakdown.weeklyBonusBreakdown && (
                <SummaryDetailRow
                  name='Weekly Bonus'
                  amount={props.payoutBreakdown.weeklyBonusBreakdown.amount}
                  currency={props.currency}
                  onHover={handleHoverWeeklyBonus}
                />
              )}
              {props.payoutBreakdown.firstShiftBonus > 0 && (
                <SummaryDetailRow
                  name='First Shift Bonus'
                  amount={props.payoutBreakdown.firstShiftBonus}
                  currency={props.currency}
                  onHover={() => setHighlightedNodes([firstShiftNodeID])}
                />
              )}
              {props.payoutBreakdown.overtimePay > 0 && (
                <SummaryDetailRow
                  name='Overtime Pay'
                  amount={props.payoutBreakdown.overtimePay}
                  currency={props.currency}
                  onHover={() => setHighlightedNodes([overtimePayNodeID])}
                />
              )}
              {props.payoutBreakdown.prop22PayBreakdown && (
                <Prop22PayTooltip
                  prop22PayBreakdown={props.payoutBreakdown.prop22PayBreakdown}
                  currency={props.currency}
                >
                  <Box component='div'>
                    <SummaryDetailRow
                      name='Prop 22 Earnings Adjustment'
                      amount={props.payoutBreakdown.prop22PayBreakdown.amount}
                      currency={props.currency}
                      onHover={() => handleHoverProp22Pay()}
                    />
                  </Box>
                </Prop22PayTooltip>
              )}
              {props.payoutBreakdown.engagedSubsidyPay > 0 && (
                <SummaryDetailRow
                  name='Engaged Subsidy Pay'
                  amount={props.payoutBreakdown.engagedSubsidyPay}
                  currency={props.currency}
                  onHover={handleHoverEngagedSubsidy}
                />
              )}
              {props.payoutBreakdown.engagedDistancePay > 0 && (
                <SummaryDetailRow
                  name='Engaged Distance Pay'
                  amount={props.payoutBreakdown.engagedDistancePay}
                  currency={props.currency}
                  onHover={handleHoverEngagedDistancePay}
                />
              )}
              {props.payoutBreakdown.adjustments.length > 0 && (
                <SummaryDetailRow
                  name='Adjustments'
                  amount={props.payoutBreakdown.totalAdjustment}
                  currency={props.currency}
                  onHover={handleHoverAdjustment}
                />
              )}
              <SummaryDetailRow
                name='Base Pay'
                amount={props.payoutBreakdown.basePay}
                currency={props.currency}
                onHover={() =>
                  setHighlightedNodes([
                    composeNodeID([weeklyNodePrefix, basePayNodeID]),
                  ])
                }
              />
              {props.payoutBreakdown.boostPay > 0 && (
                <SummaryDetailRow
                  name='Boost Pay'
                  amount={props.payoutBreakdown.boostPay}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, boostPayNodeID]),
                    ])
                  }
                />
              )}
              {props.payoutBreakdown.doXGetY > 0 && (
                <SummaryDetailRow
                  name='Do X Get Y'
                  amount={props.payoutBreakdown.doXGetY}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, doXGetYNodeID]),
                    ])
                  }
                />
              )}
              {props.payoutBreakdown.hazardPay > 0 && (
                <SummaryDetailRow
                  name='Hazard Pay'
                  amount={props.payoutBreakdown.hazardPay}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, hazardPayNodeID]),
                    ])
                  }
                />
              )}
              {props.payoutBreakdown.tier2DeliveryPay > 0 && (
                <SummaryDetailRow
                  name='Tier 2 Delivery Pay'
                  amount={props.payoutBreakdown.tier2DeliveryPay}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, tier2DeliveryPayNodeID]),
                    ])
                  }
                />
              )}
              {props.payoutBreakdown.tobaccoPay > 0 && (
                <SummaryDetailRow
                  name='Tobacco Pay'
                  amount={props.payoutBreakdown.tobaccoPay}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, tobaccoPayNodeID]),
                    ])
                  }
                />
              )}
              {props.payoutBreakdown.returnCocPay > 0 && (
                <SummaryDetailRow
                  name='Return Pay'
                  amount={props.payoutBreakdown.returnCocPay}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([weeklyNodePrefix, returnCocPayNodeID]),
                    ])
                  }
                />
              )}
              {(props.payoutBreakdown.seattleEngagedPay ?? 0) > 0 && (
                <SummaryDetailRow
                  name='Engaged Pay'
                  amount={props.payoutBreakdown.seattleEngagedPay!}
                  currency={props.currency}
                  onHover={() =>
                    setHighlightedNodes([
                      composeNodeID([
                        weeklyNodePrefix,
                        seattleEngagedPayNodeID,
                      ]),
                    ])
                  }
                />
              )}
              {props.payoutBreakdown.reimbursementPayBreakdowns.map(
                (breakdown, i) => (
                  <SummaryDetailRow
                    name={
                      isEmpty(breakdown.name)
                        ? 'Reimbursement Pay'
                        : breakdown.name
                    }
                    amount={breakdown.amount}
                    currency={props.currency}
                    onHover={() => handleHoverReimbursementPay(i)}
                  />
                ),
              )}
              <SummaryDetailRow
                name='Tips'
                amount={props.payoutBreakdown.tips}
                currency={props.currency}
                onHover={() =>
                  setHighlightedNodes([
                    composeNodeID([weeklyNodePrefix, tipsNodeID]),
                  ])
                }
              />
              {props.payoutBreakdown.minGuaranteeAdjustmentBreakdowns.map(
                (minGuarantee, i) => (
                  <MinGuaranteeAdjustmentTooltip
                    minGuaranteeAdjustment={minGuarantee}
                    currency={props.currency}
                  >
                    <Box component='div'>
                      <SummaryDetailRow
                        name='Minimum Guarantee Adjustment'
                        secondaryText={minGuarantee.locationName}
                        amount={minGuarantee.amount}
                        currency={props.currency}
                        onHover={() => handleHoverMinGuaranteeAdjustment(i)}
                      />
                    </Box>
                  </MinGuaranteeAdjustmentTooltip>
                ),
              )}
              {props.payoutBreakdown.totalWeeklyWaitPay > 0 && (
                <WaitPayTooltip
                  waitPayBreakdown={
                    props.payoutBreakdown.weeklyWaitPayBreakdowns[0]
                  }
                  currency={props.currency}
                >
                  <Box component='div'>
                    <SummaryDetailRow
                      name='Weekly Wait Pay'
                      amount={props.payoutBreakdown.totalWeeklyWaitPay}
                      currency={props.currency}
                      onHover={handleHoverWeeklyWaitPay}
                    />
                  </Box>
                </WaitPayTooltip>
              )}
              {props.payoutBreakdown.totalShiftBasedWaitPay > 0 && (
                <SummaryDetailRow
                  name='Wait Pay'
                  amount={props.payoutBreakdown.totalShiftBasedWaitPay}
                  additionalDetailsComponent={
                    <WaitPayLocations
                      waitPayBreakdowns={
                        props.payoutBreakdown.shiftBasedWaitPayWeeklyBreakdowns
                      }
                      currency={props.currency}
                    />
                  }
                  currency={props.currency}
                  onHover={handleHoverShiftBasedWaitPay}
                />
              )}
              {props.payoutBreakdown.missionBreakdowns.length > 0 && (
                <SummaryTextRow
                  name='Missions'
                  value={`[${props.payoutBreakdown.missionBreakdowns.length.toString()}]`}
                  onHover={handleHoverMissionBreakdowns}
                />
              )}
              {props.payoutBreakdown.holidayPayBreakdown != null && (
                <SummaryDetailRow
                  name='Holiday Pay'
                  currency={props.currency}
                  amount={props.payoutBreakdown.holidayPayBreakdown.totalAmount}
                  onHover={handleHoverHolidayPay}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <WeeklyPayoutTree
            payoutBreakdown={props.payoutBreakdown}
            currency={props.currency}
            highlightedNodes={highlightedNodes}
            expandedNodes={expandedNodes}
            onExpand={handleChangeExpandedNodes}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  driverAppContainer: {
    borderRadius: 4,
    border: 'groove',
    borderColor: sharedColors.blue4,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(1.5),
    marginRight: theme.spacing(6),
    backgroundColor: sharedColors.white,
  },
  centeredTextContainer: {
    flexGrow: 1,
    textAlign: 'center',
  },
  workweekRange: {
    color: sharedColors.gray5,
    marginBottom: theme.spacing(1.5),
  },
  totalAmount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '33px',
    marginBottom: theme.spacing(1),
  },
  keyStatsDivider: {
    margin: theme.spacing(2),
    color: sharedColors.gray3,
  },
  keyStatsGrid: {
    marginBottom: theme.spacing(2.5),
  },
  detailedSummaryContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: sharedColors.gray2,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  weeklySummaryDivider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

export default WeeklyPayoutBreakdown;
