import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import {
  PayRateFormProps,
  ReimbursementPay,
  ReimbursementRate,
} from '../../../interfaces/PayRate';
import {
  maxStringLengthErrorMessage,
  requiredErrorMessage,
  stringTextFieldMaxChars,
} from '../../../utilities/Constants';
import {
  generateNumberInput,
  getReimbursementRateName,
} from '../../../utilities/PayRates';

export const ReimbursementPayForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const reimbursementPay = props.currentState as ReimbursementPay;

  const handleChangeName = (e: any) => {
    props.setter({
      ...props.currentState,
      name: e.target.value,
    } as ReimbursementPay);
  };

  const handleChangeRate = (e: SelectChangeEvent<ReimbursementRate>) => {
    props.setter({
      ...props.currentState,
      rate: e.target.value,
    } as ReimbursementPay);
  };

  return (
    <Box component='div' className={classes.formWrapper}>
      <Typography className={sharedClasses.subtitle2}>
        {props.isDisabled
          ? 'Reimbursement name and pay rate'
          : 'Set reimbursement name and pay rate'}
      </Typography>
      <Box component='div' className={classes.row}>
        <TextValidator
          label='Name'
          name='Name'
          size='small'
          variant='outlined'
          type='string'
          value={reimbursementPay.name}
          disabled={props.isDisabled}
          onChange={handleChangeName}
          className={classes.nameTextField}
          validators={[
            'required',
            `maxStringLength:${stringTextFieldMaxChars}`,
          ]}
          errorMessages={[requiredErrorMessage, maxStringLengthErrorMessage]}
        />
        <FormControl
          disabled={props.isDisabled}
          size='small'
          variant='outlined'
          className={classes.rateDropdown}
        >
          <InputLabel className={classes.dropdownLabel}>Rate</InputLabel>
          <Select
            id='rate-select'
            value={reimbursementPay.rate}
            onChange={handleChangeRate}
          >
            {Object.keys(ReimbursementRate).map((key) => {
              const rate =
                ReimbursementRate[key as keyof typeof ReimbursementRate];

              return (
                <MenuItem value={rate}>
                  {getReimbursementRateName(rate)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Typography className={sharedClasses.subtitle2}>
        {props.isDisabled ? 'Pay amount' : 'Set pay amount'}
      </Typography>
      <Box className={classes.row}>
        <Box component='div' className={classes.numberField}>
          {generateNumberInput<ReimbursementPay>(
            true,
            true,
            reimbursementPay,
            props.setter,
            'amount',
            props.isDisabled,
            'Amount',
          )}
        </Box>
        <FormControlLabel
          className={classes.isTaxableSwitch}
          control={<Switch checked={reimbursementPay.isTaxable} disabled />}
          label={
            <Typography className={classes.isTaxableLabel}>
              Is Taxable
            </Typography>
          }
          labelPlacement='start'
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    rateDropdown: {
      width: 200,
    },
    dropdownLabel: {
      backgroundColor: sharedColors.white,
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    nameTextField: {
      width: 300,
      marginRight: theme.spacing(2),
    },
    isTaxableSwitch: {
      marginLeft: theme.spacing(1.5),
    },
    isTaxableLabel: {
      color: sharedColors.gray5,
    },
  }),
);
