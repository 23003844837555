export interface MissionSegmentStats {
  invited: number;
  accepted: number;
  completed: number;
  started: number;
  expectedCosts: number;
  actualCosts: number;
}

export const missionSegmentStatsFromResponse = (
  responseData: any,
): MissionSegmentStats => ({
  invited: responseData.invited,
  accepted: responseData.accepted,
  completed: responseData.completed,
  started: responseData.started,
  expectedCosts: responseData.expected_costs ?? 0,
  actualCosts: responseData.actual_costs ?? 0,
});
