import { makeStyles } from 'tss-react/mui';
import { Box, Button, Divider, Paper, Theme, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { PayoutProviderSummary } from '../../interfaces/PayHistory/PayoutProviderSummary';
import { multiClass } from '../../utilities/Extensions';
import { getSignUpStatusText } from '../../utilities/SignUpStatus';
import { SignUpStatus } from '../../interfaces/SignUpStatus';

interface PayoutProviderCardProps {
  payoutProviderSummary: PayoutProviderSummary;
  onReset?: () => void;
}

const PayoutProviderCard = (props: PayoutProviderCardProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const getProviderName = (): string => {
    switch (props.payoutProviderSummary.name) {
      case 'stripe':
        return 'Stripe';
      default:
        return props.payoutProviderSummary.name;
    }
  };

  const getEligibilityIcon = (isEligible: boolean) =>
    isEligible ? (
      <CheckCircleIcon
        fontSize='small'
        style={{ color: sharedColors.statusGreen }}
        className={classes.statusIcon}
      />
    ) : (
      <CancelIcon
        fontSize='small'
        style={{ color: sharedColors.statusRed }}
        className={classes.statusIcon}
      />
    );

  return (
    <Paper className={classes.cardContent}>
      <Box component='div' className={classes.cardHeader}>
        <Typography
          className={multiClass([sharedClasses.body1, classes.title])}
        >
          {getProviderName()}
        </Typography>
      </Box>
      <Divider className={classes.dashedDivider} />
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([sharedClasses.body1, classes.fieldName])}
        >
          Enabled:
        </Typography>
        {getEligibilityIcon(props.payoutProviderSummary.enabled)}
      </Box>
      {props.payoutProviderSummary.signUpStatus !==
        SignUpStatus.NOT_REQUIRED && (
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Sign Up Status:
          </Typography>
          <Typography className={sharedClasses.body1}>
            {getSignUpStatusText(props.payoutProviderSummary.signUpStatus)}
          </Typography>
        </Box>
      )}
      {!isEmpty(props.payoutProviderSummary.dueRequirements) && (
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Due Requirements:
          </Typography>
          <Typography className={sharedClasses.body1}>
            {props.payoutProviderSummary.dueRequirements.join(', ')}
          </Typography>
        </Box>
      )}
      {props.payoutProviderSummary.enabled && (
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Instant Cash Out:
          </Typography>
          {getEligibilityIcon(
            props.payoutProviderSummary.instantCashoutEligible,
          )}
        </Box>
      )}
      {props.onReset && (
        <Box component='div' className={classes.resetStripeButtonContainer}>
          <Button
            color='primary'
            onClick={props.onReset}
            className={multiClass([
              classes.resetStripeButton,
              sharedClasses.buttonText,
            ])}
          >
            Reset Account
          </Button>
        </Box>
      )}
    </Paper>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  cardContent: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: sharedColors.white,
    marginRight: theme.spacing(1.5),
    maxWidth: 350,
  },
  cardHeader: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    color: sharedColors.black,
    fontWeight: 'bold',
  },
  column: {
    flexDirection: 'column',
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(0.75),
  },
  fieldName: {
    color: sharedColors.gray5,
    marginRight: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  statusIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  dashedDivider: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
    borderStyle: 'dashed',
  },
  resetStripeButton: {
    padding: 0,
  },
  resetStripeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
  },
}));

export default PayoutProviderCard;
