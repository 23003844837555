import { NumberWithOperator } from '../NumberWithOperator';

export enum MissionMetricType {
  ACCEPTANCE = 'acceptance',
  COMPLETION = 'completion',
  DID_NOT_RECEIVE = 'did_not_receive',
  ON_TIME = 'on_time',
}

export type MissionMetrics = {
  [key in MissionMetricType]?: NumberWithOperator;
};

export const missionMetricsOutputTransformer = (
  missionMetrics: MissionMetrics,
) =>
  Object.keys(missionMetrics).reduce((acc, key) => {
    const metricType = key as MissionMetricType;
    return missionMetrics[metricType]
      ? [...acc, { type: key, required: missionMetrics[metricType]!.amount }]
      : acc;
  }, [] as any[]);
