import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { sharedColors } from '../utilities/Styles';
import { Order } from '../utilities/Sorting';
import {
  SortableTableEntry,
  SortableTableHeadCell,
} from '../interfaces/Common';

export const sortableTableTestId = 'sortableTable';
export const sortableTableRowTestId = 'sortableTableRow';
export const sortableHeadCellTestIdPrefix = 'sortableHeadCell';

export type SortableTableProps<T> = {
  rows: T[];
  order: Order;
  orderBy: keyof T;
  onOrder: (order: Order, orderBy: keyof T) => void;
  page: number;
  rowsPerPage: number;
  onPagination: (page: number, rowsPerPage: number) => void;
  totalCount: number;
  headCells: readonly SortableTableHeadCell<T>[];
};

const SortableTable = <T extends SortableTableEntry>(
  props: SortableTableProps<T>,
): JSX.Element => {
  const { classes } = useStyles();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T,
  ) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    props.onOrder(isAsc ? 'desc' : 'asc', property);
  };

  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return (
    <Box
      component='div'
      className={classes.outerContainer}
      data-testid={sortableTableTestId}
    >
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {props.headCells.map((headCell) => (
                <TableCell
                  key={headCell.id as string}
                  sortDirection={
                    props.orderBy === headCell.id ? props.order : false
                  }
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={props.orderBy === headCell.id}
                      direction={
                        props.orderBy === headCell.id ? props.order : 'asc'
                      }
                      onClick={createSortHandler(headCell.id as keyof T)}
                      data-testid={sortableHeadCellTestIdPrefix + headCell.id}
                    >
                      <Typography className={classes.columnLabel}>
                        {headCell.label}
                      </Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography className={classes.columnLabel}>
                      {headCell.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <TableRow key={row.id} data-testid={sortableTableRowTestId}>
                {props.headCells.map((headCell) => (
                  <TableCell key={`${row.id}-${headCell.id}`}>
                    {headCell.extractor(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={props.totalCount}
          page={props.page}
          onPageChange={(_, newPage) =>
            props.onPagination(newPage + 1, props.rowsPerPage)
          }
          onRowsPerPageChange={(e) =>
            props.onPagination(1, parseInt(e.target.value, 10))
          }
          rowsPerPage={props.rowsPerPage}
          rowsPerPageOptions={[10, 15, 25, 50]}
          component={'div'}
        />
      </TableContainer>
    </Box>
  );
};

export const useStyles = makeStyles()((theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export default SortableTable;
