import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { getUserPermissions } from '../../variables/Users';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';
import UploadModalitiesDialog from './UploadModalitiesDialog';
import UploadDppTimeRatesDialog from './UploadDppTimeRatesDialog';
import { LocationModality } from '../../interfaces/LocationModality';
import { getLocationModalities } from '../../services/locationModality';
import Waiting from '../Waiting';
import { TablePaginationOptions } from '../../interfaces/Common';

const DppTimeRates = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [locationModalities, setLocationModalities] = useState(
    [] as LocationModality[],
  );
  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 25,
  });
  const [showModalityDialog, setShowModalityDialog] = useState(false);
  const [showTimeRatesDialog, setShowTimeRatesDialog] = useState(false);

  const refreshLocationModalities = () => {
    setLoading(true);
    getLocationModalities()
      .then((modalities) => setLocationModalities(modalities))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setPermissions(getUserPermissions());

    refreshLocationModalities();
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.DPP_TIME_RATES) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  const handleUploadLocationModalities = () => {
    setShowModalityDialog(false);
    refreshLocationModalities();
  };

  return (
    <Box component='div'>
      <UploadModalitiesDialog
        open={showModalityDialog}
        onClose={() => setShowModalityDialog(false)}
        onUpload={handleUploadLocationModalities}
      />
      <Waiting open={loading} />
      <UploadDppTimeRatesDialog
        open={showTimeRatesDialog}
        onClose={() => setShowTimeRatesDialog(false)}
      />
      <Paper className={classes.header}>
        <Box component='div' className={classes.textContainer}>
          <Typography className={multiClass([sharedClasses.h2, classes.title])}>
            DPP Time Rates
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.subtitle1,
              classes.description,
            ])}
          >
            You can upload modalities and time rates for locations as a CSV file
            in this page.
          </Typography>
        </Box>
        <Box component='div' className={classes.spacer} />
        <Box component='div' className={classes.row}>
          <Button
            color='primary'
            variant='contained'
            onClick={() => setShowModalityDialog(true)}
            className={multiClass([sharedClasses.buttonText, classes.button])}
          >
            Upload Modalities
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => setShowTimeRatesDialog(true)}
            className={multiClass([sharedClasses.buttonText, classes.button])}
          >
            Upload Time Rates
          </Button>
        </Box>
      </Paper>
      <Box component='div' className={classes.body}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size='small'>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell key='location-id'>
                  <Typography className={classes.columnLabel}>
                    Location ID
                  </Typography>
                </TableCell>
                <TableCell key='location-name'>
                  <Typography className={classes.columnLabel}>
                    Location Name
                  </Typography>
                </TableCell>
                <TableCell key='modality'>
                  <Typography className={classes.columnLabel}>
                    Modality
                  </Typography>
                </TableCell>
                <TableCell key='completed-order-count'>
                  <Typography className={classes.columnLabel}>
                    Completed Order Count
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationModalities
                .slice(
                  (pagination.page - 1) * pagination.rowsPerPage,
                  pagination.page * pagination.rowsPerPage,
                )
                .map((locationModality) => {
                  const key = `${locationModality.locationID}_${locationModality.modality}`;
                  return (
                    <TableRow key={key}>
                      <TableCell key={`${key}-location-id`}>
                        <Typography>{locationModality.locationID}</Typography>
                      </TableCell>
                      <TableCell key={`${key}-location-name`}>
                        <Typography>{locationModality.locationName}</Typography>
                      </TableCell>
                      <TableCell key={`${key}-modality`}>
                        <Typography>{locationModality.modality}</Typography>
                      </TableCell>
                      <TableCell key={`${key}-completed-order-count`}>
                        <Typography>
                          {locationModality.completedOrderCount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            count={locationModalities.length}
            page={pagination.page - 1}
            rowsPerPage={pagination.rowsPerPage}
            onPageChange={(_, newPage) =>
              setPagination({ ...pagination, page: newPage + 1 })
            }
            onRowsPerPageChange={(e) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(e.target.value, 10),
              })
            }
            rowsPerPageOptions={[10, 15, 25, 50, 100, 250]}
            component='div'
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1.5),
  },
  description: {
    fontWeight: '400',
    fontSize: 14,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export default DppTimeRates;
