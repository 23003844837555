import { Chip, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { HolidayType } from '../../interfaces/HolidayPay';
import { sharedColors } from '../../utilities/Styles';
import { getHolidayTypeName } from '../../utilities/HolidayPay';

interface HolidayTypeChipProps {
  type: HolidayType;
}

const HolidayTypeChip = (props: HolidayTypeChipProps) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.typeChip}
      label={
        <Typography className={classes.typeChipText}>
          {getHolidayTypeName(props.type)}
        </Typography>
      }
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    typeChip: {
      backgroundColor: sharedColors.blue1,
      color: sharedColors.blue6,
    },
    typeChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
  }),
);

export default HolidayTypeChip;
