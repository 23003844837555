import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toast } from 'material-react-toastify';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import { getUserPermissions, isPermitted } from '../../variables/Users';
import { Permission } from '../../interfaces/Users';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../atoms/Location';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';
import { getLocations, getMetros } from '../../services/locations';
import Waiting from '../Waiting';
import NewPushNotificationSidebar from './NewPushNotificationSidebar';
import { TablePaginationOptions } from '../../interfaces/Common';
import { PushNotificationsList } from '../../interfaces/PushNotification/PushNotification';
import { getPushNotifications } from '../../services/pushNotifications';
import PushNotificationTable from './PushNotificationTable';

export const PushNotifications = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const setLocations = useSetRecoilState(locationsAtom);
  const setMetros = useSetRecoilState(metrosAtom);

  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingMetros, setLoadingMetros] = useState(false);
  const [loadingPushNotifications, setLoadingPushNotifications] =
    useState(false);
  const [showNewPushNotificationDrawer, setShowNewPushNotificationDrawer] =
    useState(false);
  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 10,
  });
  const [pushNotificationsList, setPushNotificationsList] =
    useState<PushNotificationsList>({ pushNotifications: [], totalCount: 0 });

  const refreshPushNotifications = () => {
    setLoadingPushNotifications(true);
    getPushNotifications(pagination)
      .then((fetchedPushNotificationsList) => {
        setPushNotificationsList(fetchedPushNotificationsList);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingPushNotifications(false));
  };

  useEffect(() => {
    setPermissions(getUserPermissions());

    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));

    setLoadingMetros(true);
    getMetros()
      .then((fetchedMetros) => setMetros(fetchedMetros))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingMetros(false));

    refreshPushNotifications();
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.PUSH_NOTIFICATIONS) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  useEffect(refreshPushNotifications, [pagination]);

  const handleClickNewPushNotification = () => {
    setShowNewPushNotificationDrawer(true);
  };

  const handleCreateNewPushNotification = () => {
    setShowNewPushNotificationDrawer(false);
    refreshPushNotifications();
  };

  const loading = loadingLocations || loadingMetros || loadingPushNotifications;

  return (
    <Box component='div'>
      <NewPushNotificationSidebar
        open={showNewPushNotificationDrawer}
        onClose={() => setShowNewPushNotificationDrawer(false)}
        onDone={handleCreateNewPushNotification}
      />
      <Waiting open={loading} />
      <Paper className={classes.header}>
        <Typography className={multiClass([sharedClasses.h2, classes.title])}>
          Push Notifications
        </Typography>
        <Button
          color='primary'
          variant='contained'
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={
            !isPermitted(permissions, Permission.SEND_PUSH_NOTIFICATIONS)
          }
          onClick={handleClickNewPushNotification}
        >
          + New Push Notification
        </Button>
      </Paper>
      <Box component='div' className={classes.body}>
        <PushNotificationTable
          pushNotificationsList={pushNotificationsList}
          pagination={pagination}
          onPagination={(newPagination) => setPagination(newPagination)}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    title: {
      color: sharedColors.gray7,
      flexGrow: 1,
    },
    button: {
      textTransform: 'none',
      marginLeft: theme.spacing(1.5),
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5),
    },
  }),
);
