import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { isEmpty, last } from 'lodash';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useRecoilValue } from 'recoil';
import { Mission, MissionKind } from '../../../interfaces/Mission/Mission';
import { missionTypes } from '../../../variables/Mission';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { multiClass } from '../../../utilities/Extensions';
import { getCurrencyFromMissionRequirements } from '../../../utilities/Mission';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../../atoms/Location';
import {
  datesAreOnSameDay,
  formatLocalTimeWithoutYear,
  formatTime,
} from '../../../utilities/Dates';
import { filterLocationById } from '../../../utilities/Locations';

interface MissionAppPreviewProps {
  currentState: Mission;
}

const MissionAppPreview = (props: MissionAppPreviewProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const metros = useRecoilValue(metrosAtom);

  const currentMissionType = missionTypes.find(
    (type) => type.kind === props.currentState.type,
  );

  const currency = getCurrencyFromMissionRequirements(
    props.currentState.requirements,
    locations,
    metros,
  );
  const currencySymbol = getSymbolFromCurrency(currency);

  const getRewardValue = () => {
    switch (props.currentState.type) {
      case MissionKind.PER_ORDER_BOOST:
        return `${
          currencySymbol + (props.currentState.reward.pay ?? 0).toFixed(2)
        }/order`;
      case MissionKind.PER_TRIP_BOOST:
        return `${
          currencySymbol + (props.currentState.reward.pay ?? 0).toFixed(2)
        }/trip`;
      case MissionKind.ORDER_BONUS:
      case MissionKind.TRIP_BONUS:
        return props.currentState.reward.tiers.length > 1
          ? `up to ${currencySymbol}${last(
              props.currentState.reward.tiers.map((tier) =>
                tier.pay.toFixed(2),
              ),
            )}`
          : currencySymbol +
              (props.currentState.reward.tiers[0]?.pay ?? 0).toFixed(2);
      default:
        return '';
    }
  };

  const getTimeRangeValue = () => {
    const startTimeValue = formatLocalTimeWithoutYear(
      props.currentState.startAtLocal,
    );

    if (!props.currentState.endAtLocal) {
      return startTimeValue;
    }

    return `${startTimeValue} - ${
      datesAreOnSameDay(
        props.currentState.startAtLocal,
        props.currentState.endAtLocal,
      )
        ? formatTime(
            props.currentState.endAtLocal.getHours(),
            props.currentState.endAtLocal.getMinutes(),
          )
        : formatLocalTimeWithoutYear(props.currentState.endAtLocal)
    }`;
  };

  const getLocationValue = () => {
    if (props.currentState.requirements.country === 'US') {
      return 'All US';
    }
    if (props.currentState.requirements.country === 'GB') {
      return 'All UK';
    }

    const locationIDs = props.currentState.requirements.locations.map(
      (location) => location.id,
    );
    const metroLocationIDs = locations
      .filter(
        (location) =>
          location.metroID &&
          props.currentState.requirements.metros
            .map((metro) => metro.id)
            .includes(location.metroID),
      )
      .map((location) => location.id.toString());

    const allLocationIDs = [...locationIDs, ...metroLocationIDs];

    if (isEmpty(allLocationIDs)) {
      return '[Location Name]';
    }

    const firstLocation = filterLocationById(
      locations,
      parseInt(allLocationIDs[0], 10),
    );

    const firstLocationName =
      firstLocation?.friendlyName ?? firstLocation?.name ?? '[Location Name]';

    return (
      firstLocationName +
      (allLocationIDs.length > 1
        ? ` + ${(allLocationIDs.length - 1).toString()}`
        : '')
    );
  };

  const getDescriptionValue = () => {
    const lines = props.currentState.description?.split('\n') ?? [];

    return lines.map((line) => (
      <Typography className={sharedClasses.goDriveBody1}>{line}</Typography>
    ));
  };

  return currentMissionType ? (
    <Paper className={classes.appPaper}>
      <Box component='div' className={classes.appContent}>
        <Box component='div' className={classes.footerContainer}>
          <Box component='div' className={classes.footer} />
        </Box>
        <Box component='div' className={classes.headerRow}>
          <Typography
            className={multiClass([
              sharedClasses.goDriveHeadline2,
              classes.verticalCenter,
            ])}
          >
            {isEmpty(props.currentState.title)
              ? '[Mission Name]'
              : props.currentState.title}
          </Typography>
          <Box component='div' className={classes.horizontalSpacer} />
          <Box component='div' className={classes.verticalCenter}>
            {currentMissionType.iconGenerator()}
          </Box>
        </Box>
        <Box component='div' className={classes.contentRow}>
          <PaidOutlinedIcon className={classes.contentIcon} />
          <Typography
            className={multiClass([
              sharedClasses.goDriveBody1,
              classes.contentLabel,
              classes.rewardLabel,
            ])}
          >
            {getRewardValue()}
          </Typography>
        </Box>
        <Box component='div' className={classes.contentRow}>
          <CalendarTodayOutlinedIcon className={classes.contentIcon} />
          <Typography
            className={multiClass([
              sharedClasses.goDriveBody1,
              classes.contentLabel,
            ])}
          >
            {getTimeRangeValue()}
          </Typography>
        </Box>
        <Box component='div' className={classes.contentRow}>
          <LocationOnOutlinedIcon className={classes.contentIcon} />
          <Typography
            className={multiClass([
              sharedClasses.goDriveBody1,
              classes.contentLabel,
            ])}
          >
            {getLocationValue()}
          </Typography>
        </Box>
        {props.currentState.description && (
          <Box component='div' className={classes.contentRow}>
            <InfoOutlinedIcon className={classes.contentIcon} />
            <Typography
              className={multiClass([
                sharedClasses.goDriveBody1,
                classes.contentLabel,
              ])}
            >
              {getDescriptionValue()}
            </Typography>
          </Box>
        )}
        <Box component='div' className={classes.startMissionButton}>
          <Typography className={classes.startMissionButtonText}>
            Start Mission
          </Typography>
        </Box>
      </Box>
    </Paper>
  ) : (
    <></>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    footerContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    footer: {
      display: 'block',
      backgroundColor: sharedColors.gray2,
      borderRadius: '12px',
      width: 36,
      height: 6,
    },
    appPaper: {
      height: 'fit-content',
      width: 360,
      borderRadius: '12px 12px 0px 0px',
      marginTop: theme.spacing(2.5),
      marginRight: theme.spacing(3.5),
    },
    appContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(4),
    },
    headerRow: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(1.75),
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    verticalCenter: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    contentRow: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginBottom: theme.spacing(1.5),
    },
    contentIcon: {
      color: sharedColors.gray4,
      width: 20,
    },
    contentLabel: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: theme.spacing(1),
    },
    rewardLabel: {
      fontWeight: 'bold',
    },
    startMissionButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      gap: '10px',
      backgroundColor: sharedColors.blue5,
      borderRadius: '30px',
      marginTop: theme.spacing(3),
    },
    startMissionButtonText: {
      fontFamily: 'ABC Ginto Normal',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '16px',
      color: sharedColors.white,
      cursor: 'default',
    },
  });
});

export default MissionAppPreview;
