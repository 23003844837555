export interface MissionRewardTierSummary {
  count: number;
  pay: number;
}

export const missionRewardTierSummaryFromResponse = (
  responseData: any,
): MissionRewardTierSummary => ({
  count: responseData.count ?? 0,
  pay: responseData.pay ?? 0,
});
