import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextValidator } from 'react-material-ui-form-validator';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import {
  activeWaitPayExists as activeWaitPayExistsAtom,
  payRateToEdit as payRateToEditAtom,
} from '../../../atoms/PayRate';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import {
  compareByCity,
  filterLocationById,
  filterLocationByPayType,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import {
  BoostPay,
  PayRate,
  PayRateKind,
  PayRateStatus,
  PayRateType,
} from '../../../interfaces/PayRate';
import { Location } from '../../../interfaces/Location';

import {
  locations as locationsAtom,
  selectedLocationIDs as selectedLocationIDsAtom,
} from '../../../atoms/Location';
import {
  availableWaitPayLocations as availableWaitPayLocationsAtom,
  waitAtMfcFeatureHistoryMap as waitAtMfcFeatureHistoryMapAtom,
} from '../../../atoms/FeatureToggle';

import MultiLocationAutocomplete from '../../MultiLocationAutocomplete';
import {
  maxStringLengthErrorMessage,
  requiredErrorMessage,
  stringTextFieldMaxChars,
} from '../../../utilities/Constants';
import {
  convertLocalToDeliveryZone,
  earliestByHour,
} from '../../../utilities/Dates';
import { InfoBox } from '../../InfoBox';
import { getPayRateByLocationId } from '../../../services/payRates';
import Waiting from '../../Waiting';
import { getEndOfSubsidyLockTime } from '../../../utilities/PayRates';

type SetRulesProps = {
  currentState: PayRate;
  setter: (payRate: PayRate) => void;
  selectedRateType: PayRateType;
  isDisabled: boolean;
};

export const singleLocationAutocompleteTestId = 'singleLocationAutocomplete';
export const setRulesTitleTestId = 'setRulesTitle';

export const SetRules = (props: SetRulesProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const payRateToEdit = useRecoilValue(payRateToEditAtom);
  const availableWaitPayLocations = useRecoilValue(
    availableWaitPayLocationsAtom,
  );
  const waitAtMfcFeatureHistoryMap = useRecoilValue(
    waitAtMfcFeatureHistoryMapAtom,
  );

  const [selectedLocationIDs, setSelectedLocationIDs] = useRecoilState(
    selectedLocationIDsAtom,
  );

  const [activeWaitPayExists, setActiveWaitPayExists] = useRecoilState(
    activeWaitPayExistsAtom,
  );
  const [loading, setLoading] = useState(false);

  const timeZone =
    getTimeZoneByLocationId(
      locations,
      parseInt(props.currentState.locationID, 10),
    ) || 'UTC';

  const checkActiveWaitPay = () => {
    if (
      !!payRateToEdit ||
      props.selectedRateType.kind !== PayRateKind.WAIT_PAY ||
      props.currentState.locationID === ''
    ) {
      if (activeWaitPayExists) {
        setActiveWaitPayExists(false);
      }
      return;
    }

    setLoading(true);
    getPayRateByLocationId(
      [props.currentState.locationID],
      props.selectedRateType,
    )
      .then((payRates) =>
        setActiveWaitPayExists(
          !isEmpty(
            payRates.filter(
              (payRate) => payRate.status === PayRateStatus.ACTIVE,
            ),
          ),
        ),
      )
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleLocationChange = (event: any, newValue: Location | null) => {
    props.setter({
      ...props.currentState,
      locationID: newValue ? newValue.id.toString() : '',
    });
  };

  const handleSelectedLocationIDsChange = (ids: string[]) => {
    setSelectedLocationIDs(ids);
  };

  const handleStartAtChange = (date: Date | null) => {
    const newStartAt = convertLocalToDeliveryZone(
      timeZone,
      DateTime.fromJSDate(date || new Date()),
    );

    props.setter({
      ...props.currentState,
      startAt: newStartAt.toJSDate(),
    });
  };

  const handleSetStartAtPicker = (date: DateTime | null) => {
    const newStartAt = date ?? DateTime.fromJSDate(new Date());

    props.setter({
      ...props.currentState,
      startAt: convertLocalToDeliveryZone(
        timeZone,
        newStartAt,
        newStartAt.hour,
        newStartAt.minute,
      ).toJSDate(),
    });
  };

  useEffect(() => {
    if (
      !filteredLocations
        .map((location) => location.id)
        .includes(parseInt(props.currentState.locationID, 10))
    ) {
      props.setter({
        ...props.currentState,
        locationID: '',
      });
    }
  }, []);

  useEffect(checkActiveWaitPay, [
    props.currentState.locationID,
    props.selectedRateType,
  ]);

  useEffect(() => {
    if (activeWaitPayExists) {
      const newState = {
        ...props.currentState,
        startAt: DateTime.fromJSDate(getEndOfSubsidyLockTime(timeZone))
          .plus({ second: 1 })
          .toJSDate(),
      };
      props.setter(newState);
    }
  }, [activeWaitPayExists]);

  const renderW2EligibilityInfo = () => {
    if (
      props.selectedRateType.kind !== PayRateKind.REIMBURSEMENT_PAY &&
      props.selectedRateType.kind !== PayRateKind.OVERTIME_PAY
    ) {
      return <></>;
    }

    const payRateName =
      props.selectedRateType.kind === PayRateKind.REIMBURSEMENT_PAY
        ? 'Reimbursement'
        : 'Overtime';

    return (
      <InfoBox
        title={`${payRateName} Eligibility`}
        message={
          <>
            {`Only sites that employ W2 drivers are eligible for ${payRateName.toLowerCase()} pay rates.`}
          </>
        }
        severity='info'
        classes={classes}
      />
    );
  };

  const handleEndAtChange = (date: Date | null) => {
    if (!date) {
      props.setter({ ...props.currentState, endAt: { valid: false } });
      return;
    }

    const newEndAt = convertLocalToDeliveryZone(
      timeZone,
      DateTime.fromJSDate(date),
    );

    props.setter({
      ...props.currentState,
      endAt: {
        time: newEndAt.toJSDate(),
        valid: true,
      },
    });
  };

  const handleEventNameChange = (event: any) => {
    props.setter({
      ...props.currentState,
      name: event.target.value,
    } as BoostPay);
  };

  const getMinimumAllowedStartTime = (): Date | undefined => {
    if (payRateToEdit?.payRate.status === PayRateStatus.ACTIVE) {
      return undefined;
    }
    return activeWaitPayExists
      ? getEndOfSubsidyLockTime(timeZone)
      : earliestByHour(5, timeZone).toJSDate();
  };

  const formattedStartAt = DateTime.fromJSDate(props.currentState.startAt)
    .setZone(timeZone)
    .toJSDate();

  const formattedStartAtAsDateTime = DateTime.fromJSDate(
    props.currentState.startAt,
  ).setZone(timeZone);

  const formattedEndAt = DateTime.fromJSDate(
    props.currentState.endAt.time || new Date(),
  )
    .setZone(timeZone)
    .toJSDate();

  const isDisabled =
    (props.selectedRateType.kind !== PayRateKind.BOOST_PAY &&
      props.currentState.locationID === '') ||
    (props.selectedRateType.kind === PayRateKind.BOOST_PAY &&
      isEmpty(selectedLocationIDs) &&
      props.currentState.locationID === '') ||
    props.isDisabled;

  const filteredLocations = filterLocationByPayType(
    locations,
    props.selectedRateType.kind,
    availableWaitPayLocations,
    waitAtMfcFeatureHistoryMap,
  );

  return (
    <Box component='div' className={classes.contentWrapper}>
      <Waiting open={loading} />
      {renderW2EligibilityInfo()}
      {!props.isDisabled && [
        <Typography
          className={sharedClasses.subtitle2}
          data-testid={setRulesTitleTestId}
        >
          Select location and set start date
        </Typography>,
        <Box component='div' className={classes.row}>
          {props.selectedRateType.kind === PayRateKind.BOOST_PAY &&
          !payRateToEdit ? (
            <MultiLocationAutocomplete
              label='Location Name'
              locations={filteredLocations}
              selectedIDs={selectedLocationIDs}
              onSetSelectedIDs={handleSelectedLocationIDsChange}
              disabled={props.isDisabled}
            />
          ) : (
            <Autocomplete
              id='payrate-location-autocomplete'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Location Name'
                  variant='outlined'
                  size='small'
                  className={classes.locationAutocomplete}
                  data-testid={singleLocationAutocompleteTestId}
                  disabled={!!payRateToEdit || props.isDisabled}
                />
              )}
              options={[...filteredLocations].sort(compareByCity)}
              groupBy={(location) => location.city}
              getOptionLabel={(location) => location.name}
              value={filterLocationById(
                filteredLocations,
                parseInt(props.currentState.locationID, 10),
              )}
              onChange={handleLocationChange}
              disabled={!!payRateToEdit || props.isDisabled}
            />
          )}
          {![
            PayRateKind.BOOST_PAY,
            PayRateKind.ENGAGED,
            PayRateKind.SUBSIDY,
            PayRateKind.DELAYED_DELIVERY,
          ].includes(props.selectedRateType.kind) && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='Start At'
                minDate={getMinimumAllowedStartTime()}
                value={formattedStartAt}
                onChange={handleStartAtChange}
                className={classes.datePicker}
                disabled={
                  payRateToEdit?.payRate.status === PayRateStatus.ACTIVE ||
                  isDisabled
                }
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => (
                  <TextField
                    {...params}
                    size='small'
                    className={classes.datePicker}
                  />
                )}
              />
            </LocalizationProvider>
          )}
          {[
            PayRateKind.ENGAGED,
            PayRateKind.SUBSIDY,
            PayRateKind.DELAYED_DELIVERY,
          ].includes(props.selectedRateType.kind) && (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                onChange={handleSetStartAtPicker}
                value={formattedStartAtAsDateTime}
                minDate={earliestByHour(5, timeZone)}
                disabled={
                  payRateToEdit?.payRate.status === PayRateStatus.ACTIVE ||
                  isDisabled
                }
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => <TextField {...params} size='small' label='Start At' />}
                className={classes.startAtDatePicker}
              />
            </LocalizationProvider>
          )}
          {(props.selectedRateType.kind === PayRateKind.FIRST_SHIFT_BONUS ||
            props.selectedRateType.kind === PayRateKind.REIMBURSEMENT_PAY) && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='End At'
                minDate={DateTime.max(
                  DateTime.fromJSDate(formattedStartAt),
                  DateTime.now().setZone(timeZone),
                ).toJSDate()}
                value={props.currentState.endAt.valid ? formattedEndAt : null}
                onChange={handleEndAtChange}
                disabled={isDisabled}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => (
                  <Box component='div' className={classes.dateTextField}>
                    <TextField
                      {...params}
                      size='small'
                      className={classes.datePicker}
                    />
                    {props.selectedRateType.kind ===
                      PayRateKind.REIMBURSEMENT_PAY &&
                      props.currentState.endAt.valid && (
                        <IconButton
                          disabled={isDisabled}
                          onClick={() => handleEndAtChange(null)}
                          className={classes.clearDateButton}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                  </Box>
                )}
              />
            </LocalizationProvider>
          )}
          {![
            PayRateKind.ENGAGED,
            PayRateKind.SUBSIDY,
            PayRateKind.DELAYED_DELIVERY,
          ].includes(props.selectedRateType.kind) &&
            (props.selectedRateType.kind === PayRateKind.BOOST_PAY ? (
              <TextValidator
                label='Event Name'
                name={'Name'}
                size='small'
                variant='outlined'
                type='string'
                value={(props.currentState as BoostPay).name}
                onChange={handleEventNameChange}
                className={classes.eventNameTextField}
                validators={[
                  'required',
                  `maxStringLength:${stringTextFieldMaxChars}`,
                ]}
                errorMessages={[
                  requiredErrorMessage,
                  maxStringLengthErrorMessage,
                ]}
                disabled={!!payRateToEdit || isDisabled}
              />
            ) : (
              <Tooltip
                title={
                  props.selectedRateType.kind ===
                    PayRateKind.FIRST_SHIFT_BONUS ||
                  props.selectedRateType.kind === PayRateKind.REIMBURSEMENT_PAY
                    ? 'Start date and end date are effective at 5:00AM local time for the selected location'
                    : 'Start date is effective at 5:00AM local time for the selected location'
                }
              >
                <InfoOutlinedIcon
                  fontSize='large'
                  className={classes.tooltipIcon}
                />
              </Tooltip>
            ))}
        </Box>,
      ]}
      {props.selectedRateType.inputFormGenerator(
        props.currentState,
        props.setter,
        isDisabled,
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginTop: theme.spacing(1.5),
    },
    locationAutocomplete: {
      width: 220,
    },
    multiLocationAutocomplete: {
      width: 420,
    },
    datePicker: {
      width: 180,
      marginLeft: theme.spacing(1),
    },
    dateTextField: {
      position: 'relative',
      display: 'inline-block',
    },
    clearDateButton: {
      position: 'absolute',
      margin: 'auto',
      right: '2rem',
    },
    tooltipIcon: {
      color: sharedColors.blue4,
      marginLeft: theme.spacing(1),
    },
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginTop: 'auto',
      marginBottom: '16px',
      backgroundColor: sharedColors.yellowLightest,
    },
    infoBoxIcon: {
      color: sharedColors.infoYellow,
    },
    infoBoxTextWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.yellowDark,
    },
    infoBoxMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: sharedColors.yellowDark,
      marginTop: theme.spacing(1),
    },
    startAtDatePicker: {
      marginLeft: theme.spacing(1.5),
    },
    eventNameTextField: {
      width: 300,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  }),
);
