import { isEmpty } from 'lodash';
import {
  PushNotificationEmployeeType,
  PushNotificationRequirementKind,
  PushNotificationToCreate,
} from '../interfaces/PushNotification/PushNotificationToCreate';
import { PushNotificationCategory } from '../interfaces/PushNotification/PushNotification';

export const getPushNotificationTemplate = (): PushNotificationToCreate => ({
  title: '',
  message: '',
  category: PushNotificationCategory.EARNINGS_RADAR,
  requirementKind: PushNotificationRequirementKind.DRIVER_ID,
  employeeType: PushNotificationEmployeeType.EMPLOYEE_TYPE_ALL,
  locationIDs: [],
  metroIDs: [],
  driverIDs: [],
  includeAlreadyActiveDrivers: false,
});

export const getPushNotificationCategoryName = (
  category: PushNotificationCategory,
): string => {
  switch (category) {
    case PushNotificationCategory.EARNINGS_RADAR:
      return 'High Demand';
    case PushNotificationCategory.GENERIC:
      return 'Urgent';
    default:
      return 'Unknown';
  }
};

export const getShortenedRequirementValue = (values: string[]): string => {
  if (isEmpty(values)) {
    return '-';
  }

  return (
    values[0] +
    (values.length > 1 ? ` + ${(values.length - 1).toString()}` : '')
  );
};
