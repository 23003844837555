import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { TripTravelMetrics } from '../../../../interfaces/PayHistory/TripTravelMetrics';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  engagedTripTravelDistanceNodeID,
  engagedTripTravelMetricsNodeID,
  engagedTripTravelTimeMinutesNodeID,
  estimatedTripTravelDistanceNodeID,
  estimatedTripTravelMetricsNodeID,
  estimatedTripTravelTimeMinutesNodeID,
} from '../../../../variables/PayHistory';

interface TripTravelMetricsNodeProps {
  id: string;
  tripTravelMetrics: TripTravelMetrics;
}

const TripTravelMetricsNode = (props: TripTravelMetricsNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const getTravelDistanceText = (kilometers: number, miles: number) => {
    const textValue = props.tripTravelMetrics.showDistanceInMiles
      ? miles.toFixed(2)
      : kilometers.toFixed(2);
    return (
      textValue +
      (props.tripTravelMetrics.showDistanceInMiles ? ' miles' : ' kilometers')
    );
  };

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {'Trip Travel Metrics {2}'}
        </Typography>
      }
    >
      <TreeItem
        nodeId={composeNodeID([props.id, estimatedTripTravelMetricsNodeID])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {'Estimated {2}'}
          </Typography>
        }
      >
        <SingleTextNode
          id={composeNodeID([props.id, estimatedTripTravelTimeMinutesNodeID])}
          name='Travel Time'
          value={`${props.tripTravelMetrics.estimatedTravelTimeInMinutes.toString()} minutes`}
        />
        <SingleTextNode
          id={composeNodeID([props.id, estimatedTripTravelDistanceNodeID])}
          name='Travel Distance'
          value={getTravelDistanceText(
            props.tripTravelMetrics.estimatedTravelDistanceInKilometers,
            props.tripTravelMetrics.estimatedTravelDistanceInMiles,
          )}
        />
      </TreeItem>
      <TreeItem
        nodeId={composeNodeID([props.id, engagedTripTravelMetricsNodeID])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {'Actual {2}'}
          </Typography>
        }
      >
        <SingleTextNode
          id={composeNodeID([props.id, engagedTripTravelTimeMinutesNodeID])}
          name='Travel Time'
          value={`${props.tripTravelMetrics.engagedTravelTimeInMinutes.toString()} minutes`}
        />
        <SingleTextNode
          id={composeNodeID([props.id, engagedTripTravelDistanceNodeID])}
          name='Travel Distance'
          value={getTravelDistanceText(
            props.tripTravelMetrics.engagedTravelDistanceInKilometers,
            props.tripTravelMetrics.engagedTravelDistanceInMiles,
          )}
        />
      </TreeItem>
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default TripTravelMetricsNode;
