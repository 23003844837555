import { useRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  StyledEngineProvider,
  TextField,
  TextFieldProps,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { uniq } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import {
  sharedColors,
  timePickerTheme,
  useSharedStyles,
} from '../../../utilities/Styles';

import { permissions as permissionsAtom } from '../../../atoms/Users';
import { selectedPage as selectedPageAtom } from '../../../atoms/PageState';
import { adjustmentSubtypes as adjustmentSubtypesAtom } from '../../../atoms/Adjustment';
import {
  AdjustmentFilter,
  AdjustmentStatus,
  AdjustmentEntity,
} from '../../../interfaces/Adjustment';
import { getAdjustments } from '../../../services/adjustments';
import { getAdjustmentCategoriesAndSubtypes } from '../../../services/adjustmentSubtypes';
import { getUserPermissions, isPermitted } from '../../../variables/Users';
import { PageType } from '../../../interfaces/Page';
import { getPagesListByPermission } from '../../../variables/Pages';
import Waiting from '../../Waiting';
import NewAdjustmentDialog from './NewAdjustmentDialog';
import { Permission } from '../../../interfaces/Users';
import { multiClass } from '../../../utilities/Extensions';
import { generateHeadCells } from '../../../variables/Adjustment';
import SortableTable from '../../SortableTable';
import { Order } from '../../../utilities/Sorting';
import {
  SortableTableOrder,
  TablePaginationOptions,
} from '../../../interfaces/Common';
import { parseCSVFile } from '../../../utilities/Csv';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';
import AdjustmentApprovalDialog from './AdjustmentApprovalDialog';

const allSentinel = 'ALL';
const searchableStatuses = [
  AdjustmentStatus.Pending,
  AdjustmentStatus.PendingApproval,
  AdjustmentStatus.Denied,
  AdjustmentStatus.PaidOut,
];
export const adjustmentsTestId = 'adjustments-test-id';
export const newAdjustmentButtonTestId = 'new-adjustments-test-id';

const Adjustments = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);
  const [adjustmentSubtypes, setAdjustmentSubtypes] = useRecoilState(
    adjustmentSubtypesAtom,
  );
  const [uuid, setUUID] = useState(uuidv4());

  const [adjustments, setAdjustments] = useState<AdjustmentEntity[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 10,
  });
  const [order, setOrder] = useState<SortableTableOrder<AdjustmentEntity>>({
    order: 'desc',
    orderBy: 'createdAt',
  });
  const [searchedDriverId, setSearchedDriverId] = useState('');
  const [searchedUser, setSearchedUser] = useState('');
  const [filters, setFilters] = useState<AdjustmentFilter>({
    requireTotalCount: true,
    workweekStart: moment().subtract(10, 'days').toDate(),
    workweekEnd: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [showNewAdjustmentDialog, setShowNewAdjustmentDialog] = useState(false);
  const [adjustmentToApprove, setAdjustmentToApprove] =
    useState<AdjustmentEntity | null>(null);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.ADJUSTMENT) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  const handleSetOrder = (
    newOrder: Order,
    newOrderBy: keyof AdjustmentEntity,
  ) => {
    setOrder({ order: newOrder, orderBy: newOrderBy });
    handlePagination(1, pagination.rowsPerPage);
  };

  const adjustmentTypes = uniq(
    adjustmentSubtypes.map((subtype) => subtype.adjustmentType),
  );

  const updateFilters = (newFilters: AdjustmentFilter) => {
    setFilters({ ...filters, ...newFilters });
    handlePagination(1, pagination.rowsPerPage);
  };

  const handlePagination = (newPage: number, newRowsPerPage: number) => {
    setPagination({ page: newPage, rowsPerPage: newRowsPerPage });
  };

  const handleSetSelectedAdjustmentType = (event: SelectChangeEvent) => {
    const type = event.target.value as string;
    const adjustmentType = type === allSentinel ? undefined : type;
    updateFilters({ adjustmentType });
  };

  const handleClearSearchedDriverId = () => {
    updateFilters({ driverId: undefined });
    setSearchedDriverId('');
  };

  const handleChangeSearchedDriverId = (e: any) => {
    setSearchedDriverId(e.target.value as string);
  };

  const handleSubmitSearchedDriverId = () => {
    const newDriverIdToSearch =
      searchedDriverId === '' ? undefined : searchedDriverId;
    if (newDriverIdToSearch !== filters.driverId) {
      updateFilters({ driverId: newDriverIdToSearch });
    }
  };

  const handleSearchedDriverIdFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmitSearchedDriverId();
    }
  };

  const handleClearSearchedUser = () => {
    updateFilters({ createdBy: undefined });
    setSearchedUser('');
  };

  const handleChangeSearchedUser = (e: any) => {
    setSearchedUser(e.target.value as string);
  };

  const handleSubmitSearchedUser = () => {
    const newUserToSearch = searchedUser === '' ? undefined : searchedUser;
    if (newUserToSearch !== filters.createdBy) {
      updateFilters({ createdBy: newUserToSearch });
    }
  };

  const handleSearchedUserFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmitSearchedUser();
    }
  };

  const handleSetSearchedStatus = (e: any) => {
    const status = e.target.value as string;
    updateFilters({
      status: status === allSentinel ? undefined : (status as AdjustmentStatus),
    });
  };

  const handleChangeSearchedWWStart = (date: Date | null) => {
    updateFilters({ workweekStart: date || undefined });
  };

  const handleChangeSearchedWWEnd = (date: Date | null) => {
    updateFilters({ workweekEnd: date || undefined });
  };

  const refreshAdjustments = () => {
    setLoading(true);
    getAdjustments(filters, pagination)
      .then((adjustmentsResp) => {
        setTotalCount(adjustmentsResp.totalCount);
        setAdjustments(adjustmentsResp.adjustments);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAdjustmentCategoriesAndSubtypes()
      .then((categoriesAndSubtypes) => {
        setAdjustmentSubtypes(categoriesAndSubtypes.subtypes);
      })
      .catch((error) => {
        toast.error(`Could not get adjustment types: ${error.message}`);
      });
    refreshAdjustments();
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    refreshAdjustments();
  }, [pagination]);

  const handleNewAdjustmentButton = () => {
    setShowNewAdjustmentDialog(!showNewAdjustmentDialog);
  };

  const handleCloseDialog = () => {
    setShowNewAdjustmentDialog(false);
  };

  const handleCreateAdjustment = () => {
    setUUID(uuidv4());
    refreshAdjustments();
  };

  const handleUploadAdjustment = () => {
    setShowNewAdjustmentDialog(false);
    refreshAdjustments();
  };

  const handleShowApprovalDialog = (selectedAdjustment: AdjustmentEntity) =>
    setAdjustmentToApprove(selectedAdjustment);

  const exportCSV = () => {
    setLoading(true);
    getAdjustments(filters)
      .then((adjustmentsResponse) => {
        const rows = adjustmentsResponse.adjustments;
        const url = parseCSVFile(
          [
            {
              label: 'Adjustment Type',
              value: 'adjustmentType',
            },
            {
              label: 'Adjustment Subtype',
              value: 'adjustmentSubtype',
            },
            {
              label: 'Driver ID',
              value: 'driverId',
            },
            {
              label: 'Amount',
              value: 'amount',
            },
            {
              label: 'Workweek',
              value: 'workweek',
            },
            {
              label: 'Notes',
              value: 'notes',
            },
            {
              label: 'Text Input',
              value: 'textInput',
            },
            {
              label: 'Date Input',
              value: 'dateInput',
            },
            {
              label: 'Status',
              value: 'status',
            },
          ],
          rows.map((adj) => {
            return {
              ...adj,
              amount: parseCentsAsCurrencyString(adj.amount, adj.currency),
              adjustmentSubtype: adj.adjustmentSubtype ?? '-',
            };
          }),
        );
        const link = document.createElement('a');
        link.download = 'adjustments';
        link.href = url;
        link.click();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Box component='div' data-testid={adjustmentsTestId}>
      <Waiting open={loading} />
      <NewAdjustmentDialog
        open={showNewAdjustmentDialog}
        onClose={handleCloseDialog}
        onCreate={handleCreateAdjustment}
        onUpload={handleUploadAdjustment}
        uuid={uuid}
      />
      {isPermitted(permissions, Permission.SET_ADJUSTMENT_APPROVALS) &&
        !!adjustmentToApprove && (
          <AdjustmentApprovalDialog
            onClose={() => setAdjustmentToApprove(null)}
            open
            adjustment={adjustmentToApprove}
            onApprove={() => refreshAdjustments()}
          />
        )}
      <Paper className={classes.header}>
        <Typography className={multiClass([sharedClasses.h2, classes.title])}>
          Adjustments
        </Typography>
        <Button
          id='new-adjustment-button'
          color='primary'
          variant='contained'
          onClick={handleNewAdjustmentButton}
          className={multiClass([sharedClasses.buttonText, classes.button])}
          disabled={!isPermitted(permissions, Permission.SET_ADJUSTMENTS)}
          data-testid={newAdjustmentButtonTestId}
        >
          + New Adjustment
        </Button>
      </Paper>
      <Box component='div' className={classes.body}>
        <Typography
          className={multiClass([
            sharedClasses.overline,
            classes.filterByLabel,
          ])}
        >
          FILTER BY
        </Typography>
        <Box component='div' className={classes.filtersRow}>
          <TextField
            variant='outlined'
            size='small'
            label='Delivery Partner ID'
            onChange={handleChangeSearchedDriverId}
            onKeyDown={handleSearchedDriverIdFieldKeyDown}
            value={searchedDriverId}
            InputProps={{
              startAdornment: filters.driverId && (
                <InputAdornment position='start'>
                  <ClearIcon
                    onClick={handleClearSearchedDriverId}
                    className={classes.textFieldIcon}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon
                    onClick={handleSubmitSearchedDriverId}
                    className={classes.textFieldIcon}
                  />
                </InputAdornment>
              ),
            }}
            className={classes.textField}
          />
          <FormControl
            size='small'
            variant='outlined'
            className={classes.adjustmentTypeDropdown}
          >
            <InputLabel className={classes.dropdownLabel}>
              Adjustment Type
            </InputLabel>
            <Select
              onChange={handleSetSelectedAdjustmentType}
              value={filters.adjustmentType || allSentinel}
            >
              <MenuItem value={allSentinel}>All</MenuItem>
              <Divider />
              {adjustmentTypes.map((adjustmentType) => (
                <MenuItem
                  id={adjustmentType}
                  value={adjustmentType}
                  key={adjustmentType}
                >
                  {adjustmentType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={timePickerTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Start Date'
                  value={filters.workweekStart}
                  onChange={handleChangeSearchedWWStart}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps,
                  ) => (
                    <TextField
                      {...params}
                      size='small'
                      className={classes.datePicker}
                    />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='End Date'
              value={filters.workweekEnd}
              onChange={handleChangeSearchedWWEnd}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => (
                <TextField
                  {...params}
                  size='small'
                  className={classes.datePicker}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            variant='outlined'
            size='small'
            label='User'
            onChange={handleChangeSearchedUser}
            onKeyDown={handleSearchedUserFieldKeyDown}
            value={searchedUser}
            InputProps={{
              startAdornment: filters.createdBy && (
                <InputAdornment position='start'>
                  <ClearIcon
                    onClick={handleClearSearchedUser}
                    className={classes.textFieldIcon}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon
                    onClick={handleSubmitSearchedUser}
                    className={classes.textFieldIcon}
                  />
                </InputAdornment>
              ),
            }}
            className={classes.textField}
          />
          <FormControl
            size='small'
            variant='outlined'
            className={classes.adjustmentTypeDropdown}
          >
            <InputLabel className={classes.dropdownLabel}>Status</InputLabel>
            <Select
              onChange={handleSetSearchedStatus}
              value={filters.status || allSentinel}
            >
              <MenuItem value={allSentinel}>All</MenuItem>
              <Divider />
              {searchableStatuses.map((status) => (
                <MenuItem id={status} value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='outlined'
            color='primary'
            type='submit'
            className={multiClass([sharedClasses.buttonText])}
            onClick={exportCSV}
          >
            Export
          </Button>
        </Box>
        <SortableTable
          rows={adjustments}
          headCells={generateHeadCells(classes, handleShowApprovalDialog)}
          order={order.order}
          orderBy={order.orderBy}
          onOrder={handleSetOrder}
          page={pagination.page - 1}
          rowsPerPage={pagination.rowsPerPage}
          onPagination={handlePagination}
          totalCount={totalCount}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  title: {
    color: sharedColors.gray7,
    flexGrow: 1,
  },
  button: {
    textTransform: 'none',
  },
  filterByLabel: {
    color: sharedColors.gray5,
  },
  datePicker: {
    width: '172px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textFieldIcon: {
    color: sharedColors.gray4,
    cursor: 'pointer',
  },
  textField: {
    backgroundColor: sharedColors.white,
    width: '218px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  adjustmentTypeDropdown: {
    minWidth: 260,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: sharedColors.white,
  },
  dropdownLabel: {
    backgroundColor: sharedColors.white,
  },
  filtersRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginTop: theme.spacing(2),
  },
  tableStringField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    flexDirection: 'row',
    display: 'flex',
  },
  tableItalicStringField: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    flexDirection: 'row',
    display: 'flex',
  },
  tableStringFieldTruncated: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export default Adjustments;
