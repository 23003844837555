import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import {
  BatchNormalizationRules,
  PayRate,
  PayRateFormProps,
} from '../../../interfaces/PayRate';
import { useSharedStyles } from '../../../utilities/Styles';
import { isNumber } from '../../../utilities/Misc';
import {
  max2DecimalErrorMessage,
  max2DecimalsRegexp,
  requiredErrorMessage,
} from '../../../utilities/Constants';

interface BatchNormalizationInputFieldProps {
  batchSize: number;
  currentState: PayRate;
  setter: (payRate: PayRate) => void;
  disabled: boolean;
}

const BatchNormalizationInputField = (
  props: BatchNormalizationInputFieldProps,
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = [`matchRegexp:${max2DecimalsRegexp}`, 'required'];
  const errorMessages = [max2DecimalErrorMessage, requiredErrorMessage];

  const handleChange = (e: any) => {
    const batchNormalizationRules =
      props.currentState as BatchNormalizationRules;

    props.setter({
      ...props.currentState,
      percentagesPerBatchSizes: [
        ...batchNormalizationRules.percentagesPerBatchSizes.filter(
          (percentagePerBatchSize) =>
            percentagePerBatchSize.batchSize !== props.batchSize,
        ),
        { batchSize: props.batchSize, percentage: parseFloat(e.target.value) },
      ],
    } as BatchNormalizationRules);
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (props.disabled) {
      return '';
    }
    if (isFocused) {
      return plainTextValue;
    }

    const batchNormalizationRules =
      props.currentState as BatchNormalizationRules;

    const value =
      batchNormalizationRules.percentagesPerBatchSizes.find(
        (percentagePerBatchSize) =>
          percentagePerBatchSize.batchSize === props.batchSize,
      )?.percentage ?? 0;
    if (isNumber(value)) {
      return value.toFixed(2);
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextValidator
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
      InputProps={{
        endAdornment: <Typography>%</Typography>,
      }}
      validators={validators}
      errorMessages={errorMessages}
      name={props.batchSize.toString()}
      disabled={props.disabled}
    />
  );
};

export const BatchNormalizationRulesForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box component='div' className={classes.formWrapper}>
      <Typography component='div' className={sharedClasses.subtitle2}>
        Set percentages by batch sizes:
      </Typography>
      <Grid container spacing={1} columns={5} className={classes.grid}>
        <Grid item xs={1}>
          <Typography className={classes.gridLabel}>Singleton</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.gridLabel}>Batch of 2</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.gridLabel}>Batch of 3</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.gridLabel}>Batch of 4</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.gridLabel}>Batch of 5+</Typography>
        </Grid>

        {[1, 2, 3, 4, 5].map((batchSize) => (
          <Grid item xs={1}>
            <BatchNormalizationInputField
              batchSize={batchSize}
              currentState={props.currentState}
              setter={props.setter}
              disabled={props.isDisabled}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    grid: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    gridLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '13px',
      flexGrow: 1,
      textAlign: 'center',
    },
  }),
);
