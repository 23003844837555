import { makeStyles } from 'tss-react/mui';
import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { useRecoilState } from 'recoil';
import { isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { EarningRadarSummary } from '../../interfaces/EarningRadar';
import { getEarningRadarSummaries } from '../../services/earningRadar';
import { locations as locationsAtom } from '../../atoms/Location';
import EarningRadarTable from './EarningRadarTable';
import { getLocations } from '../../services/locations';
import MultiLocationAutocomplete from '../MultiLocationAutocomplete';

const EarningRadar = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [locations, setLocations] = useRecoilState(locationsAtom);

  const [earningRadarSummaries, setEarningRadarSummaries] = useState<
    EarningRadarSummary[]
  >([]);
  const [selectedLocationIDs, setSelectedLocationIDs] = useState<string[]>([]);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingSummaries, setLoadingSummaries] = useState(false);

  useEffect(() => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));

    setLoadingSummaries(true);
    getEarningRadarSummaries()
      .then((summaries) => setEarningRadarSummaries(summaries))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSummaries(false));
  }, []);

  const handleSelectLocations = (newSelectedLocationIDs: string[]) => {
    setSelectedLocationIDs(newSelectedLocationIDs);
  };

  const filteredSummaries = earningRadarSummaries.filter(
    (summary) =>
      isEmpty(selectedLocationIDs) ||
      selectedLocationIDs.includes(summary.locationID),
  );

  const loading = loadingLocations || loadingSummaries;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Paper className={classes.header}>
        <Box component='div' className={classes.textContainer}>
          <Typography className={multiClass([sharedClasses.h2, classes.title])}>
            Earning Radar
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.subtitle1,
              classes.description,
            ])}
          >
            You can view latest Earning Radar summary in this page
          </Typography>
          <Typography
            className={multiClass([classes.note, sharedClasses.body1])}
          >
            *Reference times are displayed in timezone of the related MFC
          </Typography>
        </Box>
      </Paper>
      <Box component='div' className={classes.body}>
        <Box component='div' className={classes.filters}>
          <Box component='div' className={classes.locationFilter}>
            <MultiLocationAutocomplete
              label='Location Name'
              locations={locations}
              selectedIDs={selectedLocationIDs}
              onSetSelectedIDs={handleSelectLocations}
            />
          </Box>
        </Box>
        <EarningRadarTable earningRadarSummaries={filteredSummaries} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  description: {
    fontWeight: '400',
    fontSize: 14,
  },
  note: {
    color: sharedColors.gray5,
    marginTop: theme.spacing(1.5),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  locationFilter: {
    marginRight: theme.spacing(1.5),
  },
}));

export default EarningRadar;
