import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Slider, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { NumberRange } from '../../../interfaces/NumberRange';

interface NumberRangeInputProps {
  numberRange: NumberRange;
  onChange: (newNumberRange: NumberRange) => void;
  onDelete: () => void;
}

const NumberRangeInput = (props: NumberRangeInputProps) => {
  const classes = useStyles();

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      props.onChange({ min: newValue[0], max: newValue[1] });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.sliderContainer}>
        <Slider
          classes={{ thumb: classes.thumb }}
          value={[props.numberRange.min, props.numberRange.max]}
          onChange={handleSliderChange}
          valueLabelDisplay='auto'
          min={0}
          max={100}
          className={classes.slider}
        />
        {props.onDelete && (
          <IconButton
            size='small'
            color='error'
            onClick={props.onDelete}
            className={classes.deleteButton}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2),
    },
    sliderContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    slider: {
      flexGrow: 1,
      marginRight: theme.spacing(1),
    },
    thumb: {
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:nth-child(3)': {
        backgroundColor: '#fff',
      },
    },
    deleteButton: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);

export default NumberRangeInput;
