import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { PayRateType } from '../../../interfaces/PayRate';
import { InfoBox } from '../../InfoBox';
import { getUserPermissions, isPermitted } from '../../../variables/Users';
import { permissions as permissionsAtom } from '../../../atoms/Users';

type SelectPayTypeProps = {
  handleSelectRateType: (e: any) => void;
  payRateTypes: PayRateType[];
  selectedRateType: PayRateType;
};

export const selectPayTypeTitleTestId = 'selectPayTypeTitle';
export const selectPayTypeOptionTestIdPrefix = 'selectPayTypeOption';
export const selectPayTypeInfoBoxMessageTestId = 'selectPayTypeInfoBoxMessage';

export const SelectPayType = (props: SelectPayTypeProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);

  useEffect(() => {
    setPermissions(getUserPermissions());
  }, []);

  return (
    <Box component='div' className={classes.contentWrapper}>
      <Typography
        className={sharedClasses.subtitle2}
        data-testid={selectPayTypeTitleTestId}
      >
        Select New Rate Type
      </Typography>
      <Box component='div' className={classes.row}>
        <Box component='div' className={classes.rateTypeRadioGroup}>
          <FormControl component='fieldset'>
            <FormLabel>
              <Typography className={sharedClasses.caption}>
                Rate Type
              </Typography>
            </FormLabel>
            <RadioGroup
              value={props.selectedRateType.kind}
              onChange={props.handleSelectRateType}
            >
              {props.payRateTypes
                .filter((payRateType) => payRateType.isCreationAllowed)
                .map((payRateType) => (
                  <FormControlLabel
                    key={payRateType.kind}
                    value={payRateType.kind}
                    disabled={
                      !isPermitted(permissions, payRateType.editPermissions)
                    }
                    control={
                      <Radio
                        id={payRateType.kind}
                        color='primary'
                        data-testid={`${selectPayTypeOptionTestIdPrefix}_${payRateType.kind}`}
                      />
                    }
                    label={
                      <Typography className={sharedClasses.body1}>
                        {payRateType.name}
                      </Typography>
                    }
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <InfoBox
          title={props.selectedRateType.name}
          message={<>{props.selectedRateType.description}</>}
          severity='info'
          classes={classes}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginTop: theme.spacing(1.5),
    },
    rateTypeRadioGroup: {
      display: 'flex',
      flexGrow: 1,
    },
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginTop: 'auto',
      marginBottom: 'auto',
      backgroundColor: sharedColors.blue1,
    },
    infoBoxIcon: {
      color: sharedColors.blue4,
    },
    infoBoxTextWrapper: {
      display: 'flex',
      width: 275,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.blue7,
    },
    infoBoxMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '18px',
      color: sharedColors.blue7,
      marginTop: theme.spacing(1),
    },
    cardHeaderContainer: {
      padding: theme.spacing(1),
    },
    cardContentContainer: {
      padding: theme.spacing(1),
    },
  }),
);
