export enum PageType {
  BASE_PAY = 'BASE_PAY',
  SPECIAL_PAY = 'SPECIAL_PAY',
  ADJUSTMENT = 'ADJUSTMENT',
  RESTRICTED_ACCOUNTS = 'RESTRICTED_ACCOUNTS',
  CSV_EXPORT = 'CSV_EXPORT',
  PAY_HISTORY = 'PAY_HISTORY',
  DPP_TIME_RATES = 'DPP_TIME_RATES',
  FEATURE_TOGGLES = 'FEATURE_TOGGLES',
  TARGET_COMMISSION = 'TARGET_COMMISSION',
  PIPELINE_SETTINGS = 'PIPELINE_SETTINGS',
  MISSIONS = 'MISSIONS',
  HOLIDAY_ACCRUALS = 'HOLIDAY_ACCRUALS',
  HOLIDAY_REQUESTS = 'HOLIDAY_REQUESTS',
  EARNING_RADAR = 'EARNING_RADAR',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
}

export enum PageCategory {
  PRICING = 'PRICING',
  HOLIDAY_PAY = 'HOLIDAY_PAY',
}

export interface Page {
  pageType: PageType;
  pageCategory?: PageCategory;
  name: string;
  path: string;
  disabled: boolean;
  component: JSX.Element;
}
