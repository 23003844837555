import { ShiftEventSummary } from '../../../../interfaces/PayHistory/ShiftEventSummary';
import SingleTextNode from '../SingleTextNode';
import { formatDateTime } from '../../../../utilities/Dates';

interface ShiftEventSummaryNodeProps {
  id: string;
  shiftEventSummary: ShiftEventSummary;
  timezone: string;
}

const ShiftEventSummaryNode = (props: ShiftEventSummaryNodeProps) => {
  return (
    <SingleTextNode
      id={props.id}
      name={formatDateTime(props.shiftEventSummary.eventTime, props.timezone)}
      value={
        props.shiftEventSummary.description +
        (props.shiftEventSummary.reason
          ? ` (${props.shiftEventSummary.reason})`
          : '') +
        (props.shiftEventSummary.waitPayEnded ? ' - wait pay ended' : '')
      }
    />
  );
};

export default ShiftEventSummaryNode;
