import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { EngagedLocationContribution } from '../../../../interfaces/PayHistory/Prop22PayBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  engagedHoursWorkedWithRateNodeID,
  engagedLocationNameNodeID,
  engagedMilesWithRateNodeID,
} from '../../../../variables/PayHistory';
import EngagedHoursWorkedWithRateNode from './EngagedHoursWorkedWithRateNode';
import EngagedMilesWithRateNode from './EngagedMilesWithRateNode';

interface EngagedLocationNodeProps {
  id: string;
  name: string;
  engagedLocation: EngagedLocationContribution;
  currency: string;
}

const EngagedLocationNode = (props: EngagedLocationNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${props.name} {${Object.keys(
          props.engagedLocation,
        ).length.toString()}}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, engagedLocationNameNodeID])}
        name='Location Name'
        value={props.engagedLocation.locationName}
      />
      <EngagedHoursWorkedWithRateNode
        id={composeNodeID([props.id, engagedHoursWorkedWithRateNodeID])}
        hoursWorked={props.engagedLocation.engagedHoursWithRate}
      />
      <EngagedMilesWithRateNode
        id={composeNodeID([props.id, engagedMilesWithRateNodeID])}
        miles={props.engagedLocation.engagedMilesWithRate}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default EngagedLocationNode;
