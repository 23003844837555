import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';

import { isNumber } from '../../../utilities/Misc';
import { locations as locationsAtom } from '../../../atoms/Location';
import { PayRateOverviewProps, WeeklyBonus } from '../../../interfaces/PayRate';
import {
  getCurrencySymbolFromLocation,
  filterLocationById,
  getTimeZoneByLocationId,
} from '../../../utilities/Locations';
import { formatDateTime } from '../../../utilities/Dates';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';

export const WeeklyBonusOverview = (props: PayRateOverviewProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const weeklyBonus = props.payRate as WeeklyBonus;

  const location = filterLocationById(
    locations,
    parseInt(weeklyBonus.locationID, 10),
  );
  const timeZone =
    getTimeZoneByLocationId(locations, parseInt(weeklyBonus.locationID, 10)) ||
    'UTC';

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start Date
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(weeklyBonus.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <Typography
          className={multiClass([sharedClasses.overline, classes.fieldName])}
        >
          Orders
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          className={multiClass([sharedClasses.overline, classes.fieldName])}
        >
          Bonus Rate
        </Typography>
      </Grid>
      {[1, 2, 3, 4].map((index) => [
        <Grid item xs={3} key={`Tier ${index.toString()}`}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            {`Tier ${index.toString()}`}
          </Typography>
        </Grid>,
        <Grid item xs={3} key={`Tier${index.toString()}Orders`}>
          <Typography className={sharedClasses.body1}>
            <>
              {weeklyBonus[
                `tier${index.toString()}Orders` as keyof WeeklyBonus
              ] || '-'}
            </>
          </Typography>
        </Grid>,
        <Grid item xs={4} key={`Tier${index.toString()}Amount`}>
          <Typography className={sharedClasses.body1}>
            {isNumber(
              weeklyBonus[`tier${index.toString()}Amount` as keyof WeeklyBonus],
            )
              ? `${getCurrencySymbolFromLocation(location)}${(
                  weeklyBonus[
                    `tier${index.toString()}Amount` as keyof WeeklyBonus
                  ] as number
                ).toFixed(2)}`
              : '-'}
          </Typography>
        </Grid>,
      ])}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
