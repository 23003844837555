interface HolidayRate {
  startAt: Date;
  endAt?: Date;
  rate: number;
}

const holidayRateFromResponse = (responseData: any): HolidayRate => ({
  startAt: new Date(responseData.start_at),
  endAt: responseData.end_at ? new Date(responseData.end_at) : undefined,
  rate: responseData.rate,
});

interface PsstDailyEarning {
  earning: number;
  date: string;
}

const psstDailyEarningFromResponse = (responseData: any): PsstDailyEarning => ({
  earning: responseData.earning,
  date: responseData.date,
});

interface PsstRateCalculationDetails {
  psstDailyEarnings: PsstDailyEarning[];
  amountSum: number;
  dayCount: number;
  rate: number;
}

const psstRateCalculationDetailsFromResponse = (
  responseData: any,
): PsstRateCalculationDetails => ({
  psstDailyEarnings: (responseData.psst_daily_earnings ?? []).map(
    (earnings: any) => psstDailyEarningFromResponse(earnings),
  ),
  amountSum: responseData.amount_sum,
  dayCount: responseData.day_count,
  rate: responseData.rate,
});

export interface HolidayRateHistory {
  currency: string;
  holidayRates: HolidayRate[];
  activeRateCalculationDetails: PsstRateCalculationDetails;
}

export const holidayRateHistoryFromResponse = (
  responseData: any,
): HolidayRateHistory => ({
  currency: responseData.currency,
  holidayRates: (responseData.holiday_rates ?? []).map((rate: any) =>
    holidayRateFromResponse(rate),
  ),
  activeRateCalculationDetails: psstRateCalculationDetailsFromResponse(
    responseData.active_rate_calculation_details ?? {},
  ),
});
