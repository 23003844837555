import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import React, { useEffect, useState } from 'react';
import { flatten, isFinite } from 'lodash';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { metros as metrosAtom } from '../../atoms/Location';
import { MissionExperimentationMetrics } from '../../interfaces/Mission/MissionExperimentationResult';
import { multiClass } from '../../utilities/Extensions';
import { metroIDToName } from '../../utilities/Locations';
import StringField from '../StringField';
import { toFixedIfNecessary } from '../../utilities/Misc';

interface MissionExperimentationMetricsTableProps {
  metrics: MissionExperimentationMetrics;
}

const MissionExperimentationMetricsTable = (
  props: MissionExperimentationMetricsTableProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const metros = useRecoilValue(metrosAtom);

  const [headers, setHeaders] = useState(['Metric Name', 'Location']);

  useEffect(() => {
    setHeaders([
      'Metric Name',
      'Location',
      ...Array.from(
        new Set(
          flatten(
            Object.keys(props.metrics).map((location) =>
              flatten(
                Object.keys(props.metrics[location]).map((metricName) =>
                  Object.keys(props.metrics[location][metricName]),
                ),
              ),
            ),
          ),
        ),
      ),
    ]);
  }, [props.metrics]);

  return (
    <Box component='div' className={classes.body}>
      <Typography className={multiClass([sharedClasses.h5, classes.title])}>
        Experimentation Metrics
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <Typography className={classes.columnLabel}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {headers.length === 2 ? (
              <TableRow key='no-metrics'>
                <TableCell colSpan={2} align='center'>
                  <Typography
                    className={multiClass([
                      sharedClasses.subtitle2,
                      classes.noMetricText,
                    ])}
                  >
                    No metrics
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              [
                Object.keys(props.metrics)
                  .sort((a, b) => {
                    if (a === 'Total') {
                      return -1;
                    }
                    if (b === 'Total') {
                      return 1;
                    }

                    return a < b ? -1 : 1;
                  })
                  .map((metroID) => {
                    const metroName =
                      metroID === 'Total'
                        ? 'ALL'
                        : metroIDToName(metros, metroID);

                    const locationMetrics = props.metrics[metroID];
                    return Object.keys(locationMetrics).map((metricName) => (
                      <TableRow key={`${metroName}_${metricName}`}>
                        <TableCell>
                          <StringField value={metricName} />
                        </TableCell>
                        <TableCell>
                          <StringField value={metroName} />
                        </TableCell>
                        {headers.slice(2).map((headerName) => {
                          const metricValue =
                            locationMetrics[metricName][headerName];

                          return (
                            <TableCell>
                              <StringField
                                value={
                                  isFinite(metricValue)
                                    ? toFixedIfNecessary(metricValue, 3)
                                    : '-'
                                }
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ));
                  }),
              ]
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  body: {
    marginTop: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  noMetricText: {
    flexGrow: 1,
    textAlign: 'center',
    lineHeight: 2,
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

export default MissionExperimentationMetricsTable;
