import {
  MissionReward,
  missionRewardFromResponse,
  MissionRewardTierType,
} from './MissionReward';
import {
  MissionRequirements,
  missionRequirementsFromResponse,
} from './MissionRequirements';
import { Audit, auditFromResponse } from '../Audit';
import { MissionExperimentationMetrics } from './MissionExperimentationResult';

export enum MissionKind {
  PER_TRIP_BOOST = 'per_trip_boost',
  PER_ORDER_BOOST = 'per_order_boost',
  TRIP_BONUS = 'TRIP_BONUS',
  ORDER_BONUS = 'ORDER_BONUS',
}

export enum MissionOrderItem {
  ALCOHOL = 'alcohol',
}

const missionKindFromResponse = (
  missionCategoryKind: MissionCategoryKind,
  reward: MissionReward,
): MissionKind => {
  if (missionCategoryKind === MissionCategoryKind.PER_TRIP_BOOST) {
    return MissionKind.PER_TRIP_BOOST;
  }
  if (missionCategoryKind === MissionCategoryKind.PER_ORDER_BOOST) {
    return MissionKind.PER_ORDER_BOOST;
  }

  return reward.tierType === MissionRewardTierType.TRIP
    ? MissionKind.TRIP_BONUS
    : MissionKind.ORDER_BONUS;
};

export interface Mission {
  id: number;
  title: string;
  description?: string;
  type: MissionKind;
  isExperiment: boolean;
  countryCode: string;
  reward: MissionReward;
  startAtLocal: Date;
  endAtLocal?: Date;
  startAtOverall: Date;
  endAtOverall?: Date;
  maxDriverCount?: number;
  requirements: MissionRequirements;
  expectedCosts: number;
  actualCosts: number;
  experimentationMetrics?: MissionExperimentationMetrics;
  idempotencyKey?: string;
  audit: Audit;
}

export const missionFromResponse = (responseData: any): Mission => {
  const reward = missionRewardFromResponse(responseData.reward ?? {});

  return {
    id: responseData.id,
    title: responseData.title,
    description: responseData.description,
    type: missionKindFromResponse(responseData.type, reward),
    isExperiment: responseData.is_experiment,
    countryCode: responseData.country_code,
    reward,
    startAtLocal: new Date(responseData.start_at_local),
    endAtLocal: responseData.end_at_local
      ? new Date(responseData.end_at_local)
      : undefined,
    startAtOverall: new Date(responseData.start_at_overall),
    endAtOverall: responseData.end_at_overall
      ? new Date(responseData.end_at_overall)
      : undefined,
    maxDriverCount: responseData.max_driver_count,
    requirements: missionRequirementsFromResponse(
      responseData.requirements ?? {},
    ),
    expectedCosts: responseData.expected_costs ?? 0,
    actualCosts: responseData.actual_costs ?? 0,
    idempotencyKey: responseData.idempotency_key,
    experimentationMetrics: responseData.experimentation_metrics,
    audit: auditFromResponse(responseData.audit ?? {}),
  };
};

type InputGeneratorFn = (
  currentState: Mission,
  setter: (newState: Mission) => void,
  applyPadding?: boolean,
) => JSX.Element;
type TemplateGeneratorFn = (currentState: Mission) => Mission;
export type OutputTransformerFn = (currentState: Mission) => any;
type RewardValueExtractorFn = (currentState: Mission) => string | undefined;
type RewardDescriptionExtractorFn = (currentState: Mission) => JSX.Element;

export interface MissionType {
  kind: MissionKind;
  name: string;
  disabled: boolean;
  allowIndefiniteEndAt: boolean;
  inputGenerator: InputGeneratorFn;
  templateGenerator: TemplateGeneratorFn;
  iconGenerator: () => JSX.Element;
  outputTransformer: OutputTransformerFn;
  rewardValueExtractor: RewardValueExtractorFn;
  rewardDescriptionExtractor?: RewardDescriptionExtractorFn;
}

export interface MissionFormProps {
  currentState: Mission;
  setCurrentState: (newState: Mission) => void;
  applyPadding?: boolean;
}

export enum MissionCategoryKind {
  DO_X_GET_Y = 'do_x_get_y',
  PER_TRIP_BOOST = 'per_trip_boost',
  PER_ORDER_BOOST = 'per_order_boost',
}

export interface MissionCategory {
  kind: MissionCategoryKind;
  name: string;
  disabled: boolean;
}

export interface MissionsList {
  missions: Mission[];
  totalCount: number;
}
