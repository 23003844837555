import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { createRef, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { getMissionSegmentTemplate } from '../../../utilities/MissionSegment';
import Waiting from '../../Waiting';
import { multiClass } from '../../../utilities/Extensions';
import { requiredErrorMessage } from '../../../utilities/Constants';
import { Mission } from '../../../interfaces/Mission/Mission';
import { MissionSegmentationKind } from '../../../interfaces/Mission/MissionSegment';
import { createMissionSegment } from '../../../services/missions';
import { ConfirmDialog } from '../../NewPayRateDialog/ConfirmDialog';
import MustBeActiveAtInput from './MustBeActiveAtInput';
import DriverInput from './DriverInput';
import CountryInput from './CountryInput';
import LocationInput from './LocationInput';
import { NumberWithOperator } from '../../../interfaces/NumberWithOperator';
import NumberWithOperatorInput from './NumberWithOperatorInput';
import StratificationByDriverHoursInput from './StratificationByDriverHoursInput';
import CompletedFirstDeliveryInput from './CompletedFirstDeliveryInput';
import { formatLocalTimeV2 } from '../../../utilities/Dates';
import { NumberRange } from '../../../interfaces/NumberRange';
import NotActiveSinceInput from './NotActiveSinceInput';
import SegmentFilterTooltip from './SegmentFilterTooltip';

interface NewSegmentSidebarProps {
  mission: Mission | null;
  open: boolean;
  isExperiment: boolean;
  onClose: () => void;
  onDone: () => void;
}

const NewSegmentSidebar = (props: NewSegmentSidebarProps) => {
  const classes = useStyles();
  const numberWithOperatorClasses = useNumberWithOperatorStyles();
  const sharedClasses = useSharedStyles().classes;

  const validatorRef = createRef<ValidatorForm>();

  const [currentState, setCurrentState] = useState(
    getMissionSegmentTemplate(props.isExperiment),
  );
  const [invalidForm, setInvalidForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invitationCount, setInvitationCount] = useState<number | null>(null);
  const [segmentationTypes, setSegmentationTypes] = useState<
    MissionSegmentationKind[]
  >([]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidForm(!isValid);
    });
  }, [currentState]);

  useEffect(() => {
    if (props.mission) {
      setCurrentState(
        getMissionSegmentTemplate(props.isExperiment, props.mission),
      );
    }
  }, [props.mission]);

  const handleSubmit = () => {
    if (!isFiltersValid()) {
      toast.error('You must select at least 1 filter');
      return;
    }

    if (currentState.experimentation.isExperiment) {
      const experimentValidationResult = getExperimentValidationErrors();
      if (experimentValidationResult !== null) {
        toast.error(experimentValidationResult);
        return;
      }
    }

    if (props.mission) {
      setLoading(true);
      createMissionSegment(
        props.mission.id,
        currentState,
        segmentationTypes,
        true,
      )
        .then((result) => setInvitationCount(result.invitedDriverCount))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    }
  };

  const isFiltersValid = (): boolean => {
    return (
      !isEmpty(segmentationTypes) ||
      !!currentState.recentDriverHours ||
      !!currentState.acceptanceRate ||
      !!currentState.completionRate ||
      !!currentState.onTimeRate ||
      !!currentState.dnrRate ||
      !!currentState.scheduledAvailabilityRate ||
      !!currentState.completedOrderCount ||
      !!currentState.compositeScore ||
      !!currentState.completedFirstDelivery ||
      !!currentState.notActiveSince
    );
  };

  const getExperimentValidationErrors = (): string | null => {
    const byMetro = currentState.experimentation.stratification.byMetro;
    const byDriverHours =
      currentState.experimentation.stratification.byDriverHours;

    if (!byMetro && byDriverHours.length < 2) {
      return 'Either By Metro set or length of By Driver Hours should be >= 2 for experimentation requests.';
    }

    if (byMetro && byDriverHours.length === 1) {
      return 'For experimentation requests, the length of By Hours cannot equal to 1.';
    }

    for (let i = 0; i < byDriverHours.length; i += 1) {
      for (let j = i + 1; j < byDriverHours.length; j += 1) {
        if (
          byDriverHours[i].max > byDriverHours[j].min &&
          byDriverHours[j].max > byDriverHours[i].min
        ) {
          return 'By Driver Hours range should not overlap';
        }
      }
    }

    return null;
  };

  const handleCreate = () => {
    setInvitationCount(null);

    if (!isFiltersValid()) {
      toast.error('You must select at least 1 filter');
      return;
    }

    if (currentState.experimentation.isExperiment) {
      const experimentValidationResult = getExperimentValidationErrors();
      if (experimentValidationResult !== null) {
        toast.error(experimentValidationResult);
        return;
      }
    }

    if (props.mission) {
      setLoading(true);
      createMissionSegment(
        props.mission.id,
        currentState,
        segmentationTypes,
        false,
      )
        .then((result) => {
          if (result.scheduledFor) {
            toast.success(
              `Successfully scheduled mission segmentation for ${formatLocalTimeV2(
                result.scheduledFor,
              )} UTC`,
            );
          } else {
            toast.success(
              `Successfully invited ${result.invitedDriverCount.toString()} driver${
                result.invitedDriverCount > 1 ? 's' : ''
              } to the mission.`,
            );
          }
          props.onDone();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    }
  };

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, name: e.target.value ?? '' });
  };

  const handleToggleScheduledFor = () => {
    setCurrentState({
      ...currentState,
      scheduledForLocal: currentState.scheduledForLocal
        ? undefined
        : props.mission?.startAtLocal ?? new Date(),
    });
  };

  const handleSetScheduledFor = (newScheduledFor: Date | null) =>
    setCurrentState({
      ...currentState,
      scheduledForLocal: newScheduledFor ?? new Date(),
    });

  const handleToggleAlreadyActiveDrivers = () => {
    setCurrentState({
      ...currentState,
      includeAlreadyActiveDrivers: !currentState.includeAlreadyActiveDrivers,
    });
  };

  const handleToggleAutoAcceptInvites = () => {
    setCurrentState({
      ...currentState,
      autoAcceptInvitations: !currentState.autoAcceptInvitations,
    });
  };

  const handleToggleSendPushNotifications = () => {
    setCurrentState({
      ...currentState,
      sendPushNotifications: !currentState.sendPushNotifications,
    });
  };

  const handleToggleCompletedFirstDelivery = () => {
    setCurrentState({
      ...currentState,
      completedFirstDelivery:
        currentState.completedFirstDelivery === undefined ? false : undefined,
    });
  };

  const handleToggleRecentDriverHours = () => {
    setCurrentState({
      ...currentState,
      recentDriverHours: currentState.recentDriverHours
        ? undefined
        : { operator: '>', amount: 1 },
    });
  };

  const handleToggleAcceptanceRate = () => {
    setCurrentState({
      ...currentState,
      acceptanceRate: currentState.acceptanceRate
        ? undefined
        : { operator: '>=', amount: 50 },
    });
  };

  const handleToggleCompletionRate = () => {
    setCurrentState({
      ...currentState,
      completionRate: currentState.completionRate
        ? undefined
        : { operator: '>=', amount: 50 },
    });
  };

  const handleToggleOnTimeRate = () => {
    setCurrentState({
      ...currentState,
      onTimeRate: currentState.onTimeRate
        ? undefined
        : { operator: '>=', amount: 50 },
    });
  };

  const handleToggleDNRRate = () => {
    setCurrentState({
      ...currentState,
      dnrRate: currentState.dnrRate
        ? undefined
        : { operator: '<=', amount: 50 },
    });
  };

  const handleToggleScheduledAvailabilityRate = () => {
    setCurrentState({
      ...currentState,
      scheduledAvailabilityRate: currentState.scheduledAvailabilityRate
        ? undefined
        : { operator: '>=', amount: 50 },
    });
  };

  const handleToggleCompletedOrderCount = () => {
    setCurrentState({
      ...currentState,
      completedOrderCount: currentState.completedOrderCount
        ? undefined
        : { operator: '>=', amount: 20 },
    });
  };

  const handleToggleCompositeScore = () => {
    setCurrentState({
      ...currentState,
      compositeScore: currentState.compositeScore
        ? undefined
        : { operator: '>=', amount: 50 },
    });
  };

  const handleNotActiveSince = () => {
    setCurrentState({
      ...currentState,
      notActiveSince: currentState.notActiveSince
        ? undefined
        : moment().subtract(1, 'days').toDate(),
    });
  };

  const handleChangeDriverHours = (newDriverHours: NumberWithOperator) => {
    setCurrentState({ ...currentState, recentDriverHours: newDriverHours });
  };

  const handleChangeAcceptanceRate = (
    newAcceptanceRate: NumberWithOperator,
  ) => {
    setCurrentState({ ...currentState, acceptanceRate: newAcceptanceRate });
  };

  const handleChangeCompletionRate = (newRate: NumberWithOperator) => {
    setCurrentState({ ...currentState, completionRate: newRate });
  };

  const handleChangeOnTimeRate = (newRate: NumberWithOperator) => {
    setCurrentState({ ...currentState, onTimeRate: newRate });
  };

  const handleChangeDNRRate = (newRate: NumberWithOperator) => {
    setCurrentState({ ...currentState, dnrRate: newRate });
  };

  const handleChangeScheduledAvailabilityRate = (
    newRate: NumberWithOperator,
  ) => {
    setCurrentState({ ...currentState, scheduledAvailabilityRate: newRate });
  };

  const handleChangeCompletedOrderCount = (newCount: NumberWithOperator) => {
    setCurrentState({ ...currentState, completedOrderCount: newCount });
  };

  const handleChangeCompositeScore = (newScore: NumberWithOperator) => {
    setCurrentState({ ...currentState, compositeScore: newScore });
  };

  const handleToggleStratificationByMetro = () => {
    setCurrentState({
      ...currentState,
      experimentation: {
        ...currentState.experimentation,
        stratification: {
          ...currentState.experimentation.stratification,
          byMetro: !currentState.experimentation.stratification.byMetro,
        },
      },
    });
  };

  const handleToggleStratificationByDriverHours = () => {
    setCurrentState({
      ...currentState,
      experimentation: {
        ...currentState.experimentation,
        stratification: {
          ...currentState.experimentation.stratification,
          byDriverHours: isEmpty(
            currentState.experimentation.stratification.byDriverHours,
          )
            ? [{ min: 0, max: 10 }]
            : [],
        },
      },
    });
  };

  const handleChangeStratificationDriverHours = (
    newDriverHoursRanges: NumberRange[],
  ) => {
    setCurrentState({
      ...currentState,
      experimentation: {
        ...currentState.experimentation,
        stratification: {
          ...currentState.experimentation.stratification,
          byDriverHours: newDriverHoursRanges,
        },
      },
    });
  };

  const handleToggleSegmentationOption = (option: MissionSegmentationKind) => {
    if (segmentationTypes.includes(option)) {
      setSegmentationTypes(segmentationTypes.filter((type) => type !== option));
    } else {
      setSegmentationTypes([...segmentationTypes, option]);
    }
  };

  const invalidRequirements =
    (segmentationTypes.includes(MissionSegmentationKind.DRIVER) &&
      isEmpty(currentState.driverIDs)) ||
    (segmentationTypes.includes(MissionSegmentationKind.LOCATION) &&
      isEmpty(currentState.locationIDs) &&
      isEmpty(currentState.metroIDs)) ||
    (segmentationTypes.includes(MissionSegmentationKind.MUST_BE_ACTIVE_AT) &&
      isEmpty(currentState.mustBeActiveAt));

  return (
    <Drawer open={props.open} onClose={props.onClose} anchor='right'>
      <Waiting open={loading} />
      {invitationCount !== null && (
        <ConfirmDialog
          title='Are you sure?'
          content={`If you proceed, ${invitationCount.toString()} driver${
            invitationCount > 1 ? 's' : ''
          } will be invited to the mission.`}
          okText='Confirm'
          cancelText='Cancel'
          open
          onClose={() => setInvitationCount(null)}
          onConfirm={handleCreate}
          color='primary'
        />
      )}
      <ValidatorForm
        instantValidate
        onSubmit={currentState.scheduledForLocal ? handleCreate : handleSubmit}
        onError={() => setInvalidForm(true)}
        ref={validatorRef}
      >
        <Box component='div' className={classes.body}>
          <Typography className={multiClass([sharedClasses.h6, classes.title])}>
            {props.isExperiment ? 'New Experiment' : 'New Segment'}
          </Typography>
          <Divider />
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([
                classes.sectionLabel,
                sharedClasses.subtitle1,
              ])}
            >
              {props.isExperiment ? 'Experiment Name' : 'Segment Name'}
            </Typography>
            <TextValidator
              label={props.isExperiment ? 'Experiment Name' : 'Segment name'}
              variant='outlined'
              fullWidth
              name='name'
              value={currentState.name}
              onChange={handleChangeName}
              validators={props.isExperiment ? [] : ['required']}
              errorMessages={props.isExperiment ? [] : [requiredErrorMessage]}
              className={classes.nameDescriptionTextField}
            />
          </Box>
          <Divider />
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([
                classes.sectionLabel,
                sharedClasses.subtitle1,
              ])}
            >
              Schedule
            </Typography>
            <Box component='div' className={classes.wrappedRow}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  onChange={handleSetScheduledFor}
                  disabled={!currentState.scheduledForLocal}
                  value={
                    currentState.scheduledForLocal ??
                    props.mission?.startAtLocal ??
                    new Date()
                  }
                  minDate={new Date()}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps,
                  ) => (
                    <TextField {...params} size='small' label='Scheduled For' />
                  )}
                  className={classes.scheduledForDatePicker}
                />
              </LocalizationProvider>
              <Switch
                checked={!!currentState.scheduledForLocal}
                onChange={handleToggleScheduledFor}
                inputProps={{ 'aria-label': 'controlled' }}
                color='success'
              />
            </Box>
          </Box>
          <Divider />
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([
                classes.sectionLabel,
                sharedClasses.subtitle1,
              ])}
            >
              Filters
            </Typography>
            <SegmentFilterTooltip
              title={
                'Only DPs who have / have not completed an order will be included.\n' +
                'The order may be from any MFC.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentState.completedFirstDelivery !== undefined}
                    onChange={handleToggleCompletedFirstDelivery}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Completed First Delivery
                  </Typography>
                }
              />
              {currentState.completedFirstDelivery !== undefined && (
                <CompletedFirstDeliveryInput
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have met the hours requirement in the previous workweek will be included.\n' +
                'The hours may have been worked at any MFC.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.recentDriverHours}
                    onChange={handleToggleRecentDriverHours}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Recent Driver Hours
                  </Typography>
                }
              />
              {currentState.recentDriverHours && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.recentDriverHours}
                  onChange={handleChangeDriverHours}
                  label={'Hours'}
                  isOperatorSelectionDisabled={false}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have the required trip acceptance rate in the previous workweek will be included.\n' +
                'The rate is aggregated from trips at all MFCs.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.acceptanceRate}
                    onChange={handleToggleAcceptanceRate}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Trip Offer Acceptance Rate
                  </Typography>
                }
              />
              {currentState.acceptanceRate && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.acceptanceRate}
                  onChange={handleChangeAcceptanceRate}
                  label={'Trip Offer Acceptance Rate'}
                  endAdornment={'%'}
                  isOperatorSelectionDisabled={true}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have the required completion rate in the previous workweek will be included.\n' +
                'The rate is aggregated from all MFCs.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.completionRate}
                    onChange={handleToggleCompletionRate}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Completion Rate
                  </Typography>
                }
              />
              {currentState.completionRate && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.completionRate}
                  onChange={handleChangeCompletionRate}
                  label={'Completion Rate'}
                  endAdornment={'%'}
                  isOperatorSelectionDisabled={true}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have the required on time rate in the previous workweek will be included.\n' +
                'The rate is aggregated from all MFCs.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.onTimeRate}
                    onChange={handleToggleOnTimeRate}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    On Time Rate
                  </Typography>
                }
              />
              {currentState.onTimeRate && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.onTimeRate}
                  onChange={handleChangeOnTimeRate}
                  label={'On Time Rate'}
                  endAdornment={'%'}
                  isOperatorSelectionDisabled={true}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have the required DNR rate in the previous workweek will be included.\n' +
                'The rate is aggregated from all MFCs.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.dnrRate}
                    onChange={handleToggleDNRRate}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    DNR Rate
                  </Typography>
                }
              />
              {currentState.dnrRate && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.dnrRate}
                  onChange={handleChangeDNRRate}
                  label={'DNR Rate'}
                  endAdornment={'%'}
                  isOperatorSelectionDisabled={true}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have the required scheduled availability rate in the previous workweek will be included.\n' +
                'The rate is aggregated from all MFCs.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.scheduledAvailabilityRate}
                    onChange={handleToggleScheduledAvailabilityRate}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Scheduled Availability Rate
                  </Typography>
                }
              />
              {currentState.scheduledAvailabilityRate && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.scheduledAvailabilityRate}
                  onChange={handleChangeScheduledAvailabilityRate}
                  label={'Scheduled Availability Rate'}
                  endAdornment={'%'}
                  isOperatorSelectionDisabled={true}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <SegmentFilterTooltip
              title={
                'Only DPs who have completed the required number of orders in the previous workweek will be included.\n' +
                'The orders may be from any MFC.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.completedOrderCount}
                    onChange={handleToggleCompletedOrderCount}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Completed Order Count
                  </Typography>
                }
              />
              {currentState.completedOrderCount && (
                <NumberWithOperatorInput
                  numberWithOperator={currentState.completedOrderCount}
                  onChange={handleChangeCompletedOrderCount}
                  label={'Completed Order Count'}
                  isOperatorSelectionDisabled={true}
                  classes={numberWithOperatorClasses}
                />
              )}
            </SegmentFilterTooltip>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!currentState.compositeScore}
                  onChange={handleToggleCompositeScore}
                />
              }
              label={
                <Typography className={sharedClasses.subtitle2}>
                  Composite Score
                </Typography>
              }
            />
            {currentState.compositeScore && (
              <NumberWithOperatorInput
                numberWithOperator={currentState.compositeScore}
                onChange={handleChangeCompositeScore}
                label={'Composite Score'}
                isOperatorSelectionDisabled={true}
                classes={numberWithOperatorClasses}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={segmentationTypes.includes(
                    MissionSegmentationKind.DRIVER,
                  )}
                  onChange={() =>
                    handleToggleSegmentationOption(
                      MissionSegmentationKind.DRIVER,
                    )
                  }
                />
              }
              label={
                <Typography className={sharedClasses.subtitle2}>
                  DP IDs
                </Typography>
              }
            />
            {segmentationTypes.includes(MissionSegmentationKind.DRIVER) && (
              <DriverInput
                currentState={currentState}
                setCurrentState={setCurrentState}
              />
            )}
            <SegmentFilterTooltip
              title={
                'Only DPs who have not had a workday at any of the mission MFCs within the chosen time frame will be included.\n' +
                'They may have had a workday at a non-mission MFC within the timeframe.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!currentState.notActiveSince}
                    onChange={handleNotActiveSince}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Not Active Since
                  </Typography>
                }
              />
              {!!currentState.notActiveSince && (
                <NotActiveSinceInput
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                />
              )}
            </SegmentFilterTooltip>
            <Typography
              className={multiClass([
                sharedClasses.overline,
                classes.overlineTitle,
              ])}
            >
              Recently Active At
            </Typography>
            {segmentationTypes.includes(MissionSegmentationKind.COUNTRY) && (
              <CountryInput
                currentState={currentState}
                setCurrentState={setCurrentState}
              />
            )}
            <SegmentFilterTooltip
              title={
                'Only DPs who have an active workday or a workday that has finished within the last 6 weeks will be included.\n' +
                '\n'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={segmentationTypes.includes(
                      MissionSegmentationKind.LOCATION,
                    )}
                    onChange={() =>
                      handleToggleSegmentationOption(
                        MissionSegmentationKind.LOCATION,
                      )
                    }
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Locations/Metros
                  </Typography>
                }
              />
              {segmentationTypes.includes(MissionSegmentationKind.LOCATION) && (
                <LocationInput
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                />
              )}
            </SegmentFilterTooltip>
            <Typography
              className={multiClass([
                sharedClasses.overline,
                classes.overlineTitle,
              ])}
            >
              Currently Active At
            </Typography>
            <SegmentFilterTooltip
              title={
                'Only DPs who have an active workday at the chosen MFCs will be included.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={segmentationTypes.includes(
                      MissionSegmentationKind.MUST_BE_ACTIVE_AT,
                    )}
                    onChange={() =>
                      handleToggleSegmentationOption(
                        MissionSegmentationKind.MUST_BE_ACTIVE_AT,
                      )
                    }
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Locations
                  </Typography>
                }
              />
              {segmentationTypes.includes(
                MissionSegmentationKind.MUST_BE_ACTIVE_AT,
              ) && (
                <MustBeActiveAtInput
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                />
              )}
            </SegmentFilterTooltip>
          </Box>
          {props.isExperiment && [
            <Divider />,
            <Box component='div' className={classes.dialogContent}>
              <Typography
                className={multiClass([
                  classes.sectionLabel,
                  sharedClasses.subtitle1,
                ])}
              >
                Stratification
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      currentState.experimentation.stratification.byMetro
                    }
                    onChange={handleToggleStratificationByMetro}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    By Metro
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !isEmpty(
                        currentState.experimentation.stratification
                          .byDriverHours,
                      )
                    }
                    onChange={handleToggleStratificationByDriverHours}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    By Driver Hours
                  </Typography>
                }
              />
              {!isEmpty(
                currentState.experimentation.stratification.byDriverHours,
              ) && (
                <StratificationByDriverHoursInput
                  driverHours={
                    currentState.experimentation.stratification.byDriverHours
                  }
                  onChange={handleChangeStratificationDriverHours}
                />
              )}
            </Box>,
          ]}
          <Divider />
          <Box component='div' className={classes.dialogContent}>
            <Typography
              className={multiClass([
                classes.sectionLabel,
                sharedClasses.subtitle1,
              ])}
            >
              Options
            </Typography>
            <SegmentFilterTooltip
              title={
                'DPs who currently have an active workday at any MFC (even non-mission MFCs) will be excluded from the mission.'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentState.includeAlreadyActiveDrivers}
                    disabled={!isEmpty(currentState.mustBeActiveAt)}
                    onChange={handleToggleAlreadyActiveDrivers}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Include already active DPs
                  </Typography>
                }
              />
            </SegmentFilterTooltip>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentState.autoAcceptInvitations}
                  onChange={handleToggleAutoAcceptInvites}
                />
              }
              label={
                <Typography className={sharedClasses.subtitle2}>
                  Automatically accept invites
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentState.sendPushNotifications}
                  onChange={handleToggleSendPushNotifications}
                />
              }
              label={
                <Typography className={sharedClasses.subtitle2}>
                  Send push notifications
                </Typography>
              }
            />
          </Box>
          <Box component='div' className={classes.verticalSpacer} />
          <Box component='div' className={classes.actionsRow}>
            <Box component='div' className={classes.horizontalSpacer} />
            <Button
              variant='contained'
              color='inherit'
              onClick={props.onClose}
              className={multiClass([
                sharedClasses.buttonText,
                classes.actionButton,
              ])}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={invalidForm || invalidRequirements}
              type='submit'
              className={multiClass([
                sharedClasses.buttonText,
                classes.actionButton,
              ])}
            >
              Save
            </Button>
          </Box>
        </Box>
      </ValidatorForm>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    title: {
      color: sharedColors.gray6,
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3.5),
      background: sharedColors.gray2,
    },
    body: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(3.5),
    },
    sectionLabel: {
      marginBottom: theme.spacing(2.5),
    },
    nameDescriptionTextField: {
      minWidth: 428,
      marginBottom: theme.spacing(1.5),
    },
    wrappedRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    scheduledForDatePicker: {
      marginRight: theme.spacing(1.5),
    },
    actionsRow: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    actionButton: {
      marginLeft: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    verticalSpacer: {
      display: 'flex',
      flexGrow: 1,
    },
    overlineTitle: {
      color: sharedColors.gray5,
      marginTop: theme.spacing(1),
      fontSize: 12,
    },
  });
});

const useNumberWithOperatorStyles = makeStyles((theme) =>
  createStyles({
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    dropdown: {
      marginLeft: theme.spacing(5),
      width: 75,
      backgroundColor: sharedColors.white,
      marginRight: theme.spacing(1.5),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    deleteButton: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);

export default NewSegmentSidebar;
