export interface NumberWithOperator {
  amount: number;
  operator: string;
}

export const NumberWithOperatorOutputTransformer = (
  numberWithOperator: NumberWithOperator,
) => ({
  amount: numberWithOperator.amount,
  operator: numberWithOperator.operator,
});
