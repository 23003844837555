import {
  ActivePayRates,
  BatchNormalizationRules,
  BoostPay,
  FirstShiftBonus,
  GenericPayRate,
  OvertimePay,
  PayRate,
  PayRateKind,
  PayRateSchedule,
  PayRateStatus,
  PayRateType,
  WeeklyBonus,
} from '../interfaces/PayRate';

export const commissions: GenericPayRate[] = [
  {
    kind: PayRateKind.COMMISSION,
    id: 0,
    locationID: '1',
    startAt: new Date(2021, 9, 10),
    endAt: {
      time: undefined,
      valid: false,
    },
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 300,
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.COMMISSION,
    id: 1,
    locationID: '1',
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 350,
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.COMMISSION,
    id: 2,
    locationID: '14',
    startAt: new Date(2253, 8, 22),
    endAt: {
      valid: false,
    },
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 400,
    featureFlags: [],
    metadata: {},
  },
];

export const subsidies: GenericPayRate[] = [
  {
    kind: PayRateKind.SUBSIDY,
    id: 0,
    locationID: '1',
    startAt: new Date(2021, 9, 10),
    endAt: {
      time: new Date(),
      valid: true,
    },
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 300,
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.SUBSIDY,
    id: 1,
    locationID: '1',
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 350,
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.SUBSIDY,
    id: 2,
    locationID: '1',
    startAt: new Date(2253, 8, 22),
    endAt: {
      valid: false,
    },
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 400,
    featureFlags: [],
    metadata: {},
  },
];

export const engagedPayRates: GenericPayRate[] = [
  {
    kind: PayRateKind.ENGAGED,
    id: 0,
    locationID: '1',
    startAt: new Date(2021, 9, 10),
    endAt: {
      time: new Date(),
      valid: true,
    },
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 300,
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.ENGAGED,
    id: 1,
    locationID: '1',
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 350,
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.ENGAGED,
    id: 2,
    locationID: '1',
    startAt: new Date(2253, 8, 22),
    endAt: {
      valid: false,
    },
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'eren@gopuff.com',
    amount: 400,
    featureFlags: [],
    metadata: {},
  },
];

export const waitPays: PayRateSchedule[] = [
  {
    kind: PayRateKind.WAIT_PAY,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    spans: [
      {
        rate: 0.25,
        dayStart: 0,
        dayEnd: 7,
        timeStart: 0,
        timeEnd: 23,
      },
    ],
    locationID: '1',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
];

export const batchNormalizationRules: BatchNormalizationRules[] = [
  {
    kind: PayRateKind.BATCH_NORMALIZATION_RULES,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    percentagesPerBatchSizes: [
      {
        batchSize: 1,
        percentage: 10,
      },
      {
        batchSize: 2,
        percentage: 20,
      },
      {
        batchSize: 3,
        percentage: 30,
      },
      {
        batchSize: 4,
        percentage: 40,
      },
      {
        batchSize: 5,
        percentage: 50,
      },
    ],
    locationID: '1',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
];

export const weeklyBonuses: WeeklyBonus[] = [
  {
    kind: PayRateKind.WEEKLY_BONUS,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    tier1Orders: 300,
    tier1Amount: 1200,
    tier2Orders: 300,
    tier2Amount: 1200,
    tier3Orders: 300,
    tier3Amount: 1200,
    tier4Orders: 300,
    tier4Amount: 1200,
    locationID: '1',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
  {
    kind: PayRateKind.WEEKLY_BONUS,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    tier1Orders: 400,
    tier1Amount: 1200,
    tier2Orders: 300,
    tier2Amount: 1200,
    tier3Orders: 300,
    tier3Amount: 1200,
    tier4Orders: 300,
    tier4Amount: 1200,
    locationID: '11',
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
  },
];

export const firstShiftBonuses: FirstShiftBonus[] = [
  {
    kind: PayRateKind.FIRST_SHIFT_BONUS,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 300,
    minOrders: 2,
    minHours: 0,
    locationID: '11',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
  {
    kind: PayRateKind.FIRST_SHIFT_BONUS,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    amount: 300,
    minOrders: 2,
    minHours: 0,
    locationID: '1',
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
  },
  {
    kind: PayRateKind.FIRST_SHIFT_BONUS,
    id: 1,
    startAt: new Date(2156, 8, 22),
    endAt: {
      valid: false,
    },
    amount: 700,
    minOrders: 0,
    minHours: 20,
    locationID: '1',
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
];

export const specialPays: BoostPay[] = [
  {
    kind: PayRateKind.BOOST_PAY,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 300,
    name: 'Birthday Boost',
    locationID: '1',
    startTime: new Date(),
    endTime: new Date(),
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
  {
    kind: PayRateKind.BOOST_PAY,
    id: 1,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 900,
    name: 'Birthday Boost',
    locationID: '11',
    startTime: new Date('2021-10-22 12:16:10.728169 +00:00'),
    endTime: new Date('2021-10-23 00:16:10.728169 +00:00'),
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
  },
  {
    kind: PayRateKind.BOOST_PAY,
    id: 2,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 300,
    name: 'Birthday Boost',
    locationID: '11',
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'eren@gopuff.com',
    startTime: new Date('2021-10-22 12:16:10.728169 +00:00'),
    endTime: new Date('2021-10-23 12:16:10.728169 +00:00'),
  },
  {
    kind: PayRateKind.BOOST_PAY,
    id: 3,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 7, 9),
      valid: true,
    },
    amount: 400,
    name: 'Birthday Boost',
    locationID: '11',
    status: PayRateStatus.EXPIRED,
    startTime: new Date('2021-10-22 12:16:10.728169 +00:00'),
    endTime: new Date('2021-10-22 18:16:10.728169 +00:00'),
    lastUpdatedBy: 'eren@gopuff.com',
  },
];

export const invalidCommission: GenericPayRate = {
  kind: PayRateKind.COMMISSION,
  id: 1,
  locationID: '1',
  startAt: new Date(2016, 8, 22),
  endAt: {
    time: new Date(2015, 7, 9),
    valid: true,
  },
  status: PayRateStatus.EXPIRED,
  lastUpdatedBy: 'eren@gopuff.com',
  amount: 350,
  featureFlags: [],
  metadata: {},
};

export const overLappedExistingBoostPay = {
  kind: PayRateKind.BOOST_PAY,
  id: 0,
  start_at: new Date('2020-04-13T00:00:00.000+03:00'),
  end_at: {
    time: new Date('2020-04-15T00:00:00.000+03:00'),
    valid: true,
  },
  location_id: '1',
  status: PayRateStatus.ACTIVE,
  last_updated_by: 'eren@gopuff.com',
  metadata: {
    name: 'overlapping boost',
  },
};

export const overLappedNewBoostPay: BoostPay = {
  kind: PayRateKind.BOOST_PAY,
  id: 0,
  startAt: new Date('2020-04-14T00:00:00.000+03:00'),
  endAt: {
    time: new Date('2020-04-15T00:00:00.000+03:00'),
    valid: true,
  },
  amount: 200,
  locationID: '1',
  name: 'abc',
  startTime: new Date('2020-04-13T00:00:00.000+03:00'),
  endTime: new Date('2020-04-15T00:00:00.000+03:00'),
  status: PayRateStatus.EXPIRED,
  lastUpdatedBy: 'eren@gopuff.com',
};

export const hazardPays: GenericPayRate[] = [
  {
    kind: PayRateKind.HAZARD_PAY,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 300,
    locationID: '11',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'nick@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.HAZARD_PAY,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    amount: 400,
    locationID: '1',
    status: PayRateStatus.EXPIRED,
    lastUpdatedBy: 'yazan@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
];

export const tobaccoPays: GenericPayRate[] = [
  {
    kind: PayRateKind.TOBACCO_BONUS,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 100,
    locationID: '11',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'kevin@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.TOBACCO_BONUS,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    amount: 250,
    locationID: '1',
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'eren@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
];

export const alcoholPays: GenericPayRate[] = [
  {
    kind: PayRateKind.ALCOHOL_PAY,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 105,
    locationID: '11',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'ohan@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.ALCOHOL_PAY,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    amount: 252,
    locationID: '1',
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'yazan@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
];

export const tier2DeliveryPays: GenericPayRate[] = [
  {
    kind: PayRateKind.TIER2_DELIVERIES,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    amount: 600,
    locationID: '11',
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'karl@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
  {
    kind: PayRateKind.TIER2_DELIVERIES,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    amount: 700,
    locationID: '1',
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'okan@gopuff.com',
    featureFlags: [],
    metadata: {},
  },
];

export const overtimePays: OvertimePay[] = [
  {
    kind: PayRateKind.OVERTIME_PAY,
    id: 0,
    startAt: new Date(2021, 7, 9),
    endAt: {
      time: new Date(2021, 9, 9),
      valid: true,
    },
    locationID: '14',
    twelveHoursMultiplier: 1.5,
    fortyHoursMultiplier: 1.8,
    status: PayRateStatus.ACTIVE,
    lastUpdatedBy: 'samet@gopuff.com',
  },
  {
    kind: PayRateKind.OVERTIME_PAY,
    id: 1,
    startAt: new Date(2016, 8, 22),
    endAt: {
      time: new Date(2018, 7, 9),
      valid: true,
    },
    locationID: '1',
    eightHoursMultiplier: 1.2,
    twelveHoursMultiplier: 1.5,
    fortyHoursMultiplier: 2.0,
    status: PayRateStatus.FUTURE,
    lastUpdatedBy: 'eren@gopuff.com',
  },
];

export const getPayRateByLocationId = async (
  locationIds: string[],
  payRateType: PayRateType,
): Promise<PayRate[]> => {
  let payRates: PayRate[];
  switch (payRateType.kind) {
    case PayRateKind.WEEKLY_BONUS: {
      payRates = weeklyBonuses;
      break;
    }
    case PayRateKind.FIRST_SHIFT_BONUS: {
      payRates = firstShiftBonuses;
      break;
    }
    case PayRateKind.BOOST_PAY: {
      payRates = specialPays;
      break;
    }
    case PayRateKind.OVERTIME_PAY: {
      payRates = overtimePays;
      break;
    }
    case PayRateKind.TIER2_DELIVERIES: {
      payRates = tier2DeliveryPays;
      break;
    }
    case PayRateKind.HAZARD_PAY: {
      payRates = hazardPays;
      break;
    }
    case PayRateKind.TOBACCO_BONUS: {
      payRates = tobaccoPays;
      break;
    }
    case PayRateKind.ALCOHOL_PAY: {
      payRates = alcoholPays;
      break;
    }
    case PayRateKind.WAIT_PAY: {
      payRates = waitPays;
      break;
    }
    case PayRateKind.BATCH_NORMALIZATION_RULES: {
      payRates = batchNormalizationRules;
      break;
    }
    case PayRateKind.COMMISSION: {
      payRates = commissions;
      break;
    }
    case PayRateKind.SUBSIDY: {
      payRates = subsidies;
      break;
    }
    case PayRateKind.ENGAGED: {
      payRates = engagedPayRates;
      break;
    }

    default:
      payRates = [];
  }
  return payRates.filter((payRate) => locationIds.includes(payRate.locationID));
};

export const activePayRates: ActivePayRates = {
  rates: [
    {
      kind: PayRateKind.OVERTIME_PAY,
      id: 0,
      startAt: new Date(2021, 7, 9),
      endAt: {
        time: new Date(2021, 9, 9),
        valid: true,
      },
      locationID: '11',
      twelveHoursMultiplier: 1.5,
      fortyHoursMultiplier: 1.8,
      status: PayRateStatus.ACTIVE,
      lastUpdatedBy: 'eren@gopuff.com',
    } as PayRate,
  ],
  locations: ['11'],
};
