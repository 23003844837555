import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

type ConfirmDialogProps = {
  title: string;
  content: string;
  okText: string;
  cancelText: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  color: 'primary' | 'error';
};

export const confirmDialogTestId = 'confirmDialog';
export const confirmDialogConfirmButtonTestId = 'confirmDialogConfirmButton';
export const confirmDialogCancelButtonTestId = 'confirmDialogCancelButton';

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const handleClose = () => {
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle data-testid={confirmDialogTestId} id='alert-dialog-title'>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color={'inherit'}
            onClick={handleClose}
            data-testid={confirmDialogCancelButtonTestId}
          >
            {props.cancelText}
          </Button>
          <Button
            variant='contained'
            onClick={handleConfirm}
            autoFocus
            color={props.color}
            data-testid={confirmDialogConfirmButtonTestId}
          >
            {props.okText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
