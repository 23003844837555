import React from 'react';
import { Page, PageCategory, PageType } from '../interfaces/Page';
import { PayRates as PayRatesComponent } from '../components/PayRates/PayRates';
import { payRateTypes, specialPayRateTypes } from './PayRate';
import { isPermitted } from './Users';
import { Permission } from '../interfaces/Users';
import RestrictedAccounts from '../components/RestrictedAccounts/RestrictedAccounts';
import PayoutCsvExport from '../components/PayoutCsvExport';
import NotFound from '../components/NotFound';
import Adjustments from '../components/Adjustments/Adjustments/Adjustments';
import FeatureTogglePageRoot from '../components/FeatureToggle/FeatureTogglePageRoot';
import PayHistoryPageRoot from '../components/PayHistory/PayHistoryPageRoot';
import DppTimeRates from '../components/DppTimeRates/DppTimeRates';
import MissionPageRoot from '../components/Missions/MissionPageRoot';
import { TargetCommissions } from '../components/TargetCommissions/TargetCommissions';
import EarningRadar from '../components/EarningRadar/EarningRadar';
import HolidayAccrualPage from '../components/HolidayPay/HolidayAccrualPage';
import HolidayRequestPage from '../components/HolidayPay/HolidayRequestPage';
import { PushNotifications } from '../components/PushNotifications/PushNotifications';
import SettingsPage from '../components/Settings/SettingsPage';

export const featureTogglePageUrl = '/feature-toggles';
export const missionPageUrl = '/missions';
export const payHistoryPageUrl = '/pay-history';
export const targetCommissionUrl = '/target-commission';

export const getPagesListByPermission = (permissions: number): Page[] =>
  [
    {
      pageType: PageType.PAY_HISTORY,
      name: 'Pay History',
      path: payHistoryPageUrl,
      disabled: false,
      component: <PayHistoryPageRoot />,
    },
    {
      pageType: PageType.ADJUSTMENT,
      name: 'Adjustments',
      path: '/adjustments',
      disabled: false,
      component: <Adjustments />,
    },
    {
      pageType: PageType.BASE_PAY,
      pageCategory: PageCategory.PRICING,
      name: 'Pay Rates',
      path: '/pay-rates',
      disabled: false,
      component: (
        <PayRatesComponent
          categoryName='Pay Rate'
          payRateTypes={payRateTypes}
          title='Pay Rates'
          pageType={PageType.BASE_PAY}
        />
      ),
    },
    {
      pageType: PageType.SPECIAL_PAY,
      pageCategory: PageCategory.PRICING,
      name: 'Special Pay',
      path: '/special-pay-rates',
      disabled: false,
      component: (
        <PayRatesComponent
          categoryName='Special Pay Rate'
          payRateTypes={specialPayRateTypes}
          title={'Special Pay'}
          pageType={PageType.SPECIAL_PAY}
        />
      ),
    },
    {
      pageType: PageType.DPP_TIME_RATES,
      pageCategory: PageCategory.PRICING,
      name: 'DPP Time Rates',
      path: '/dpp-time-rates',
      disabled: !isPermitted(permissions, Permission.HAS_DPP_TIME_RATES_ACCESS),
      component: <DppTimeRates />,
    },
    {
      pageType: PageType.TARGET_COMMISSION,
      pageCategory: PageCategory.PRICING,
      name: 'DPP Target Commission',
      path: targetCommissionUrl,
      disabled: !isPermitted(permissions, Permission.SET_TARGET_COMMISSION),
      component: isPermitted(permissions, Permission.SET_TARGET_COMMISSION) ? (
        <TargetCommissions />
      ) : (
        <NotFound />
      ),
    },
    {
      pageType: PageType.PIPELINE_SETTINGS,
      pageCategory: PageCategory.PRICING,
      name: 'Pipeline Settings',
      path: '/pipeline-settings',
      disabled: false,
      component: <SettingsPage />,
    },
    {
      pageType: PageType.HOLIDAY_ACCRUALS,
      pageCategory: PageCategory.HOLIDAY_PAY,
      name: 'Holiday Accruals',
      path: '/holiday-accruals',
      disabled: false,
      component: <HolidayAccrualPage />,
    },
    {
      pageType: PageType.HOLIDAY_REQUESTS,
      pageCategory: PageCategory.HOLIDAY_PAY,
      name: 'Holiday Requests',
      path: '/holiday-requests',
      disabled: false,
      component: <HolidayRequestPage />,
    },
    {
      pageType: PageType.MISSIONS,
      name: 'GoMissions',
      path: missionPageUrl,
      disabled: false,
      component: <MissionPageRoot />,
    },
    {
      pageType: PageType.RESTRICTED_ACCOUNTS,
      name: 'Restricted Accounts',
      path: '/restricted-accounts',
      disabled: false,
      component: <RestrictedAccounts />,
    },
    {
      pageType: PageType.CSV_EXPORT,
      name: '1099 Accounting Export',
      path: '/payout-export',
      disabled: !isPermitted(permissions, Permission.EXPORT_PAYOUT),
      component: isPermitted(permissions, Permission.EXPORT_PAYOUT) ? (
        <PayoutCsvExport />
      ) : (
        <NotFound />
      ),
    },
    {
      pageType: PageType.FEATURE_TOGGLES,
      name: 'Feature Toggles',
      path: featureTogglePageUrl,
      disabled: !isPermitted(permissions, Permission.ALL),
      component: isPermitted(permissions, Permission.ALL) ? (
        <FeatureTogglePageRoot />
      ) : (
        <NotFound />
      ),
    },
    {
      pageType: PageType.EARNING_RADAR,
      name: 'Earning Radar',
      path: '/earning-radar',
      disabled: false,
      component: <EarningRadar />,
    },
    {
      pageType: PageType.PUSH_NOTIFICATIONS,
      name: 'Push Notifications',
      path: '/push-notifications',
      disabled: false,
      component: <PushNotifications />,
    },
  ].filter((page) => !page.disabled) as Page[];
