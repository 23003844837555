export interface ReimbursementPayBreakdown {
  name: string;
  amount: number;
}

export const reimbursementPayBreakdownFromResponse = (
  responseData: any,
): ReimbursementPayBreakdown => ({
  name: responseData.name ?? '',
  amount: responseData.amount ?? 0,
});
