import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  filteredLocationIDs as filteredLocationIDsAtom,
  locations as locationsAtom,
} from '../../../atoms/Location';
import {
  featureToggleHistoryMapToList,
  FeatureToggleHistoryWithIncludedValue,
} from '../../../interfaces/FeatureToggle';
import Waiting from '../../Waiting';
import { getFeatureToggleHistoryMap } from '../../../services/featureToggle';
import {
  listedFeatureToggleHistoryComparator,
  variableBasePayFeatureName,
} from '../../../variables/FeatureToggle';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { multiClass } from '../../../utilities/Extensions';
import {
  getTimeZoneByLocationId,
  locationIdToName,
} from '../../../utilities/Locations';
import { formatDateTime } from '../../../utilities/Dates';
import { PayRateStatus } from '../../../interfaces/PayRate';

export const variableBasePayHistoryTableTestId = 'variableBasePayHistoryTable';

type VariableBasePayStatusFieldProps = {
  status: PayRateStatus;
};
const VariableBasePayStatusField = (props: VariableBasePayStatusFieldProps) => {
  const classes = useStyles();

  const getClassNameByStatus = () => {
    switch (props.status) {
      case PayRateStatus.FUTURE:
        return classes.statusFutureChip;
      case PayRateStatus.ACTIVE:
        return classes.statusActiveChip;
      case PayRateStatus.EXPIRED:
        return classes.statusExpiredChip;
      default:
        return classes.statusExpiredChip;
    }
  };

  return (
    <Chip
      className={getClassNameByStatus()}
      label={
        <Typography id='status' className={classes.statusChipText}>
          {props.status}
        </Typography>
      }
    />
  );
};

type VariableBasePayHistoryTableProps = {
  allLocations: boolean;
};

export const VariableBasePayHistoryTable = (
  props: VariableBasePayHistoryTableProps,
) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const filteredLocationIDs = useRecoilValue(filteredLocationIDsAtom);
  const locations = useRecoilValue(locationsAtom);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [featureToggleHistoryList, setFeatureToggleHistoryList] = useState<
    FeatureToggleHistoryWithIncludedValue[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const locationIDsToCheck = props.allLocations
      ? ([] as string[])
      : filteredLocationIDs;

    if (locationIDsToCheck.length === 0 && !props.allLocations) {
      setFeatureToggleHistoryList([]);
      return;
    }

    setLoading(true);
    getFeatureToggleHistoryMap(
      variableBasePayFeatureName,
      locationIDsToCheck,
      props.allLocations,
    )
      .then((historyMap) => {
        setFeatureToggleHistoryList(
          featureToggleHistoryMapToList(historyMap).filter(
            (history) =>
              !props.allLocations || history.status === PayRateStatus.ACTIVE,
          ),
        );
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, [filteredLocationIDs, props.allLocations, locations]);

  const filteredHistoryList = featureToggleHistoryList
    .sort(listedFeatureToggleHistoryComparator)
    .filter(
      (history) =>
        props.allLocations ||
        filteredLocationIDs.includes(history.includedValue),
    )
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const shouldRender = filteredHistoryList.length > 0;

  return (
    <Box component='div' className={shouldRender ? classes.outerContainer : ''}>
      <Waiting open={loading} />
      {shouldRender && (
        <Box component='div'>
          <Typography
            className={multiClass([sharedClasses.h5, classes.title])}
            data-testid={variableBasePayHistoryTableTestId}
          >
            Variable Base Pay History
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table size='small'>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>
                    <Typography className={classes.columnLabel}>
                      Location Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.columnLabel}>
                      Start Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.columnLabel}>
                      End Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.columnLabel}>
                      Base Pay
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.columnLabel}>
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredHistoryList.map((row, i) => {
                  const timeZone =
                    getTimeZoneByLocationId(
                      locations,
                      parseInt(row.includedValue, 10),
                    ) || 'UTC';

                  const disabled = row.status === PayRateStatus.EXPIRED;

                  return (
                    <TableRow key={row.includedValue + i.toString(10)}>
                      <TableCell>
                        <Typography
                          className={multiClass([
                            disabled
                              ? classes.disabledStringField
                              : classes.enabledStringField,
                            classes.stringField,
                          ])}
                        >
                          {locationIdToName(
                            locations,
                            parseInt(row.includedValue, 10),
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={multiClass([
                            disabled
                              ? classes.disabledStringField
                              : classes.enabledStringField,
                            classes.stringField,
                          ])}
                        >
                          {formatDateTime(row.startAt, timeZone)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={multiClass([
                            disabled
                              ? classes.disabledStringField
                              : classes.enabledStringField,
                            classes.stringField,
                          ])}
                        >
                          {row.endAt
                            ? formatDateTime(row.endAt, timeZone)
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={multiClass([
                            disabled
                              ? classes.disabledStringField
                              : classes.enabledStringField,
                            classes.stringField,
                          ])}
                        >
                          Variable
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <VariableBasePayStatusField status={row.status} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              count={featureToggleHistoryList.length}
              page={page}
              onPageChange={(_, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
              rowsPerPageOptions={[4, 6, 8, 10]}
              component='div'
            />
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusFutureChip: {
      backgroundColor: sharedColors.blue7,
      color: sharedColors.blue2,
    },
    statusActiveChip: {
      backgroundColor: sharedColors.statusGreen,
      color: sharedColors.statusGreenLightest,
    },
    statusExpiredChip: {
      backgroundColor: sharedColors.statusRed,
      color: sharedColors.statusRedLightest,
    },
    statusChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: '#E3F2FD80',
    },
    tableHeader: {
      backgroundColor: sharedColors.blue1,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
    },
    disabledStringField: {
      color: theme.palette.text.disabled,
    },
    enabledStringField: {
      color: sharedColors.gray6,
    },
    stringField: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      flexDirection: 'row',
      display: 'flex',
    },
  }),
);
