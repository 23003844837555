import { flatten, isEmpty } from 'lodash';
import {
  TimeRatePipelineSettings,
  timeRatePipelineSettingsFromResponse,
  timeRatePipelineSettingsOutputTransformer,
} from '../interfaces/Settings/TimeRatePipelineSettings';
import { SettingsKey, SettingsType } from '../interfaces/Settings/Settings';
import api from './api';
import { PayRateStatus } from '../interfaces/PayRate';
import {
  BatchNormalizationSettings,
  batchNormalizationSettingsFromResponse,
  batchNormalizationSettingsOutputTransformer,
} from '../interfaces/Settings/BatchNormalizationSettings';

const settingsUrl = '/settings';

export const createTimeRatePipelineSettings = async (
  locationIDs: string[],
  settings: TimeRatePipelineSettings,
): Promise<void> =>
  api.post(settingsUrl, {
    type: 'time_rate',
    key: 'pipeline',
    settings: timeRatePipelineSettingsOutputTransformer(locationIDs, settings),
  });

export const uploadTimeRatePipelineSettings = async (
  settings: TimeRatePipelineSettings[],
): Promise<void> =>
  api.post(settingsUrl, {
    type: 'time_rate',
    key: 'pipeline',
    settings: flatten(
      settings.map((s) =>
        timeRatePipelineSettingsOutputTransformer([s.locationID], s),
      ),
    ),
  });

export const createBatchNormalizationSettings = async (
  locationIDs: string[],
  settings: BatchNormalizationSettings,
): Promise<void> =>
  api.post(settingsUrl, {
    type: 'time_rate',
    key: 'batch_normalization',
    settings: batchNormalizationSettingsOutputTransformer(
      locationIDs,
      settings,
    ),
  });

export const getBatchNormalizationSettings = async (
  locationIDs: string[],
  metroIDs: string[],
  allActive: boolean,
): Promise<BatchNormalizationSettings[]> => {
  const params: { [key: string]: any } = {
    type: SettingsType.TIME_RATE,
    key: SettingsKey.BATCH_NORMALIZATION,
  };
  if (!isEmpty(locationIDs)) {
    params['location-id'] = locationIDs;
  }
  if (!isEmpty(metroIDs)) {
    params['metro-id'] = metroIDs;
  }
  if (allActive) {
    params.status = PayRateStatus.ACTIVE;
  }

  const response = await api.get(settingsUrl, {
    params,
    paramsSerializer: { indexes: null },
  });

  return (response?.data?.data?.time_rate?.batch_normalization ?? []).map(
    (s: any) => batchNormalizationSettingsFromResponse(s),
  );
};

export const getTimeRatePipelineSettings = async (
  locationIDs: string[],
  metroIDs: string[],
  allActive: boolean,
): Promise<TimeRatePipelineSettings[]> => {
  const params: { [key: string]: any } = {
    type: SettingsType.TIME_RATE,
    key: SettingsKey.PIPELINE,
  };
  if (!isEmpty(locationIDs)) {
    params['location-id'] = locationIDs;
  }
  if (!isEmpty(metroIDs)) {
    params['metro-id'] = metroIDs;
  }
  if (allActive) {
    params.status = PayRateStatus.ACTIVE;
  }

  const response = await api.get(settingsUrl, {
    params,
    paramsSerializer: { indexes: null },
  });

  return (response?.data?.data?.time_rate?.pipeline ?? []).map((s: any) =>
    timeRatePipelineSettingsFromResponse(s),
  );
};

export const deleteSettingsByID = async (id: number): Promise<void> =>
  api.delete(`${settingsUrl}/${id.toString()}`);
