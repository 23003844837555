import {
  ShiftEventSummary,
  shiftEventSummaryFromResponse,
} from './ShiftEventSummary';

export interface ShiftWithEventsBreakdown {
  scheduledStart: Date;
  scheduledEnd: Date;
  actualStart: Date;
  actualEnd: Date;
  totalWaitSeconds: number;
  roundedTotalWaitMinutes: number;
  events: ShiftEventSummary[];
}

export const shiftWithEventsBreakdownFromResponse = (
  responseData: any,
): ShiftWithEventsBreakdown => ({
  scheduledStart: new Date(responseData.scheduled_start),
  scheduledEnd: new Date(responseData.scheduled_end),
  actualStart: new Date(responseData.actual_start),
  actualEnd: new Date(responseData.actual_end),
  totalWaitSeconds: responseData.total_wait_seconds ?? 0,
  roundedTotalWaitMinutes: responseData.rounded_total_wait_minutes ?? 0,
  events: (responseData.events ?? []).map((event: any) =>
    shiftEventSummaryFromResponse(event),
  ),
});
