import axios, { AxiosHeaders } from 'axios';
import { flatten, isArray } from 'lodash';
import oktaAuth from './okta';
import config from '../config';
import { ErrorWithMetadata } from '../interfaces/Error';

const api = axios.create({
  baseURL: config.DRIVER_PAYOUT_API,
});

export const errorMessageSeparator = '#errorMsg';

api.interceptors.request.use((request) => {
  const authState = oktaAuth.authStateManager.getAuthState();
  const oktaToken = authState?.accessToken?.accessToken;
  const controller = new AbortController();
  if (!oktaToken) {
    controller.abort();
  }

  (request.headers as AxiosHeaders).set('Authorization', `Bearer ${oktaToken}`);

  return {
    ...request,
    signal: controller.signal,
  };
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 409 && !!error?.response?.data) {
      const errorWithMetadata: ErrorWithMetadata = {
        name: 'Conflict',
        message: 'Status conflict',
        metadata: error.response.data,
      };
      throw errorWithMetadata;
    }
    if (isArray(error?.response?.data)) {
      const errors = flatten(
        error.response.data
          .filter((obj: any) => Object.keys(obj).includes('error'))
          .map((obj: any) => obj.error),
      );
      throw new Error(errors.join(errorMessageSeparator));
    } else if (error) {
      if (error.message === 'Request canceled') throw new Error(error.message);
      else
        throw new Error('Unknown API request error. Please contact support.');
    }
  },
);

export default api;
