import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import Waiting from '../Waiting';
import { TablePaginationOptions } from '../../interfaces/Common';
import {
  HolidayAccrualStatsResponse,
  HolidayType,
} from '../../interfaces/HolidayPay';
import { multiClass } from '../../utilities/Extensions';
import HolidayAccrualStatsTable from './HolidayAccrualStatsTable';
import { getHolidayAccrualStats } from '../../services/holidayPay';
import { getUserPermissions } from '../../variables/Users';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';
import { searchTextFieldTestId } from '../RestrictedAccounts/RestrictedAccounts';

const HolidayAccrualPage = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationOptions>({
    page: 1,
    rowsPerPage: 10,
  });
  const [holidayAccrualStats, setHolidayAccrualStats] =
    useState<HolidayAccrualStatsResponse>({
      data: [],
      pagination: { page: 1, rowsPerPage: 10, totalCount: 0 },
    });
  const [typedSearchedValue, setTypedSearchedValue] = useState('');
  const [submittedSearchedValue, setSubmittedSearchedValue] = useState('');

  const refreshAccrualStats = () => {
    setLoading(true);
    getHolidayAccrualStats(
      HolidayType.PSST,
      pagination,
      submittedSearchedValue === '' ? undefined : submittedSearchedValue,
    )
      .then((stats) => setHolidayAccrualStats(stats))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.HOLIDAY_ACCRUALS) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  useEffect(refreshAccrualStats, [pagination, submittedSearchedValue]);

  const handleChangeSearchedValue = (e: any) => {
    setTypedSearchedValue(e.target.value);
  };

  const handleSubmitSearchedValue = () => {
    if (typedSearchedValue !== submittedSearchedValue) {
      setPagination({ page: 1, rowsPerPage: pagination.rowsPerPage });
      setSubmittedSearchedValue(typedSearchedValue);
    }
  };

  const handleSearchedValueFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmitSearchedValue();
    }
  };

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Paper className={classes.header}>
        <Typography className={multiClass([sharedClasses.h2, classes.title])}>
          Holiday Accruals
        </Typography>
      </Paper>
      <Box component='div' className={classes.body}>
        <Box component='div' className={classes.filters}>
          <TextField
            variant='outlined'
            size='small'
            label='Search by DP ID'
            onChange={handleChangeSearchedValue}
            onKeyDown={handleSearchedValueFieldKeyDown}
            onBlur={handleSubmitSearchedValue}
            value={typedSearchedValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleSubmitSearchedValue}>
                    <SearchIcon className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': searchTextFieldTestId,
            }}
            className={classes.textField}
          />
        </Box>
        <HolidayAccrualStatsTable
          holidayAccrualStatsList={holidayAccrualStats.data}
          pagination={{
            ...pagination,
            totalCount: holidayAccrualStats.pagination.totalCount,
          }}
          onPagination={(newPagination) => setPagination(newPagination)}
          onRequestPayment={refreshAccrualStats}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexGrow: 1,
    },
    title: {
      color: sharedColors.gray7,
      flexGrow: 1,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    textField: {
      backgroundColor: sharedColors.white,
      width: '260px',
    },
    searchIcon: {
      color: sharedColors.gray4,
      cursor: 'pointer',
    },
  }),
);

export default HolidayAccrualPage;
