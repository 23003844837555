import api from './api';

import { TargetCommission } from '../interfaces/TargetCommissions';
import { validateCSV } from './Validators';

const targetCommissionURL = '/target-commission';
const targetCommissionResponseKey = 'target_commissions';

export const getTargetCommissionsByLocationId = async (
  locationIds: string[],
): Promise<TargetCommission[]> => {
  const response = await api.get(targetCommissionURL, {
    params: {
      'location-id': locationIds,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  const responseData = response.data[targetCommissionResponseKey];

  const targetCommissions = (responseData ?? []).map((receivedData: any) =>
    parseTargetCommissionFromResponse(receivedData),
  );

  return targetCommissions;
};

export const updateTargetCommission = async (
  targetCommission: TargetCommission,
): Promise<void> => {
  try {
    await api.patch(`${targetCommissionURL}/${targetCommission.id}`, {
      target_commission_unscheduled_edited:
        targetCommission.targetCommissionUnscheduledEdited,
    });
  } catch (error: any) {
    throw new Error(`Could not update target commission: ${error.message}`);
  }
};

export const getTargetCommissionCsvExport = async (): Promise<string> => {
  const url = `${targetCommissionURL}/export`;
  const response = await api.get(url, {
    params: {},
    paramsSerializer: {
      indexes: null,
    },
  });
  if (response.data.csv_content === null) {
    throw new Error(
      'Unexpected error: Server responded with an empty .csv file',
    );
  }
  return response.data.csv_content as string;
};

export const targetCommissionCSVUpload = async (file: File): Promise<void> => {
  try {
    validateCSV(file);
  } catch (error: any) {
    throw new Error(
      `Could not send csv request due to validation error: ${error.message}`,
    );
  }
  const bodyFormData = new FormData();
  bodyFormData.set('file', file, file.name);
  await api.patch(`${targetCommissionURL}/import`, bodyFormData);
};

const parseTargetCommissionFromResponse = (
  receivedData: any,
): TargetCommission => {
  return {
    targetCommissionUnscheduledEdited:
      receivedData.target_commission_unscheduled_edited
        ? Math.round(
            (receivedData.target_commission_unscheduled_edited +
              Number.EPSILON) *
              100,
          ) / 100
        : receivedData.target_commission_unscheduled_edited,
    targetCommissionUnscheduledRaw:
      Math.round(
        (receivedData.target_commission_unscheduled_raw + Number.EPSILON) * 100,
      ) / 100,
    id: receivedData.id,
    locationID: receivedData.location_id,
    startAt: new Date(receivedData.start_at),
    endAt: new Date(receivedData.end_at),
    lastUpdatedBy: receivedData.last_updated_by,
    status: receivedData.status,
    isWeekend: receivedData.is_weekend,
  };
};
