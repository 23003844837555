import { Order } from '../utilities/Sorting';

export interface SortableTableHeadCell<T> {
  id: string;
  label: string;
  sortable: boolean;
  extractor: (row: T) => JSX.Element;
}

export interface SortableTableEntry {
  id: string;
}

export interface TablePaginationOptions {
  page: number;
  rowsPerPage: number;
}

export interface TablePaginationResponse extends TablePaginationOptions {
  totalCount: number;
}

export const tablePaginationFromResponse = (
  responseData: any,
): TablePaginationResponse => ({
  page: responseData.page_number,
  rowsPerPage: responseData.page_size,
  totalCount: responseData.total_count,
});

export interface SortableTableOrder<T extends SortableTableEntry> {
  order: Order;
  orderBy: keyof T;
}

export interface UploadDialogTab {
  id: number;
  name: string;
}

export enum UploadDialogTabKind {
  MANUAL_ADDITION = 0,
  BULK_UPLOAD = 1,
}

export enum ModifierDialogTabKind {
  INSERT = 0,
  REMOVE = 1,
  UPDATE = 2,
}
