export interface MinMaxFares {
  minFareFirstOrder: number;
  minFareSubsequentOrder: number;
  maxFarePerOrder: number;
  minFareFirstOrderLowerBound: number;
  minFareFirstOrderUpperBound: number;
}

export const minMaxFaresFromResponse = (responseData: any): MinMaxFares => ({
  minFareFirstOrder: responseData.min_fare_first_order,
  minFareSubsequentOrder: responseData.min_fare_subsequent_order,
  maxFarePerOrder: responseData.max_fare_per_order,
  minFareFirstOrderLowerBound: responseData.min_fare_first_order_lower_bound,
  minFareFirstOrderUpperBound: responseData.min_fare_first_order_upper_bound,
});
