import { atom } from 'recoil';

export const selectedPage = atom({
  key: 'selectedPage',
  default: 0,
});

export const appBarHeight = atom({
  key: 'appBarHeight',
  default: 0,
});
