import { useRecoilState } from 'recoil';
import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { selectedPage as selectedPageAtom } from '../../atoms/PageState';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { getUserPermissions, isPermitted } from '../../variables/Users';
import { getPagesListByPermission } from '../../variables/Pages';
import { PageType } from '../../interfaces/Page';
import Missions from './Missions';
import MissionDetails from './MissionDetails';
import NotFound from '../NotFound';
import NewMissionPage from './NewMission/NewMissionPage';
import { Permission } from '../../interfaces/Users';

const MissionPageRoot = () => {
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom);

  useEffect(() => {
    setPermissions(getUserPermissions());
  }, []);

  useEffect(() => {
    const pages = getPagesListByPermission(permissions);
    let index = -1;
    for (let i = 0; i < pages.length; i += 1) {
      if (pages[i].pageType === PageType.MISSIONS) {
        index = i;
        break;
      }
    }

    if (selectedPage !== index) {
      setSelectedPage(index);
    }
  }, [permissions]);

  return (
    <Routes>
      <Route path={'/'} element={<Missions />}></Route>
      {isPermitted(permissions, Permission.SET_MISSIONS) && (
        <Route path={'/new'} element={<NewMissionPage />}></Route>
      )}
      <Route path={'/details/:id'} element={<MissionDetails />}></Route>
      <Route path='*' element={<NotFound />}></Route>
    </Routes>
  );
};

export default MissionPageRoot;
