import { ManualRestrictedAccountToAdd } from '../interfaces/RestrictedAccount';

export const areSameIndexesNullOrNotNull = (
  tierOrdersArray: (number | undefined)[],
  tierAmountsArray: (number | undefined)[],
): boolean => {
  let isValid = true;
  tierOrdersArray.forEach((tierOrder, index) => {
    if (
      (Number.isNaN(tierOrder) && !Number.isNaN(tierAmountsArray[index])) ||
      (!Number.isNaN(tierOrder) && Number.isNaN(tierAmountsArray[index])) ||
      (tierOrder === 0 && tierAmountsArray[index] !== 0) ||
      (tierOrder !== 0 && tierAmountsArray[index] === 0)
    ) {
      isValid = false;
    }
  });
  return isValid;
};

export const isArraySorted = (tierArray: (number | undefined)[]): boolean => {
  let max = -1;
  // eslint-disable-next-line no-restricted-syntax
  for (const tier of tierArray) {
    if (Number.isNaN(tier) || tier === undefined || tier < max) {
      return false;
    }
    max = tier;
  }
  return true;
};

export const validateRestrictedAccount = (
  restrictedAccount: ManualRestrictedAccountToAdd,
) => {
  if (!restrictedAccount) {
    throw new Error('restricted account should not be null');
  }
  if (!restrictedAccount.DriverID || !restrictedAccount.RestrictionType) {
    throw new Error('required field is null for adjustment');
  }
};

export const validateCSV = (file: File | undefined) => {
  if (!file) {
    throw new Error('file should not be null');
  }
};
