export const weeklyNodePrefix = 'weekly';
export const dailyNodePrefix = 'daily';
export const orderNodePrefix = 'order';
export const tripNodePrefix = 'trip';

export const weeklyBreakdownNodeID = 'weekly-breakdown';
export const dailyBreakdownNodeID = 'daily-breakdown';

export const timezoneNodeID = 'timezone';
export const firstShiftNodeID = 'first-shift';
export const overtimePayNodeID = 'overtime-pay';
export const totalAdjustmentNodeID = 'total-adjustment';
export const totalMinGuaranteeAdjustmentNodeID =
  'total-min-guarantee-adjustment';

export const weeklyBonusNodeID = 'weekly-bonus';
export const applicableWeeklyBonusLocationNodeID =
  'applicable-weekly-bonus-location';
export const allWeeklyBonusLocationsNodeID = 'all-weekly-bonus-locations';
export const tierXOrdersNodeIDGenerator = (x: number): string =>
  `tier${x.toString()}-orders`;
export const tierXAmountNodeIDGenerator = (x: number): string =>
  `tier${x.toString()}-amount`;
export const weeklyBonusLocationNameNodeID = 'weekly-bonus-location-name';
export const weeklyBonusDeliveryCountNodeID = 'weekly-bonus-delivery-count';

export const prop22PayNodeID = 'prop22-pay';
export const engagedLocationsNodeID = 'engaged-locations';
export const totalEarningsFloorNodeID = 'total-earnings-floor';
export const engagedLocationNodeID = 'engaged-location';
export const engagedLocationNameNodeID = 'engaged-location-name';
export const engagedHoursNodeID = 'engaged-hours';
export const engagedHoursWorkedWithRateNodeID = 'engaged-hours-worked';
export const engagedHoursWorkedAmountNodeID = 'engaged-hours-worked-amount';
export const engagedHoursWorkedRateNodeID = 'engaged-hours-worked-rate';
export const engagedMilesNodeID = 'engaged-miles';
export const engagedMilesWithRateNodeID = 'engaged-miles-with-rate';
export const engagedMilesAmountNodeID = 'engaged-miles-amount';
export const engagedMilesRateNodeID = 'engaged-miles-rate';
export const engagedDistanceRateNodeID = 'engaged-distance-rate';

export const orderCountNodeID = 'order-count';
export const scheduledOrderCountNodeID = 'scheduled-order-count';
export const unscheduledOrderCountNodeID = 'unscheduled-order-count';
export const totalAmountNodeID = 'total-amount';
export const hoursNodeID = 'hours';

export const adjustmentsNodeID = 'adjustments';
export const adjustmentTypeNodeID = 'adjustment-type';
export const adjustmentSubtypeNodeID = 'adjustment-subtype';
export const adjustmentNoteNodeID = 'adjustment-note';

export const minGuaranteeAdjustmentsNodeID = 'min-guarantee-adjustments';
export const minGuaranteeAdjustmentLocationNodeID =
  'min-guarantee-adjustment-location';
export const shiftsNodeID = 'shifts';
export const shiftsScheduledStartNodeID = 'shift-scheduled-start';
export const shiftsScheduledEndNodeID = 'shift-scheduled-end';
export const shiftsStartTimeNodeID = 'shift-start-time';
export const shiftsEndTimeNodeID = 'shift-end-time';
export const shiftHoursWorkedNodeID = 'shift-hours-worked';
export const subsidizableHoursWorkedNodeID = 'subsidizable-hours-worked';
export const subsidizableHoursWorkedAmountNodeID =
  'subsidizable-hours-worked-amount';
export const subsidizableHoursWorkedRateNodeID =
  'subsidizable-hours-worked-rate';
export const minGuaranteeRateNodeID = 'min-guarantee-rate';
export const scheduledHoursNodeID = 'scheduled-hours';
export const subsidyVersionNodeID = 'subsidy-version';

export const basePayNodeID = 'base-pay';
export const doXGetYNodeID = 'do-x-get-y';
export const boostPayNodeID = 'boost-pay';
export const hazardPayNodeID = 'hazard-pay';
export const tier2DeliveryPayNodeID = 'tier2-delivery-pay';
export const tobaccoPayNodeID = 'tobacco-pay';
export const returnCocPayNodeID = 'return-coc-pay';
export const seattleEngagedPayNodeID = 'seattle-engaged-pay';
export const tipsNodeID = 'tips';
export const minGuaranteeAdjustmentNodeID = 'min-guarantee-adjustment';
export const amountNodeID = 'amount';

export const ordersNodeID = 'orders';
export const orderIDNodeID = 'order-id';
export const orderLocationNameNodeID = 'order-location-name';
export const orderStartDeliveryAtNodeID = 'order-start-delivery-at';
export const orderEndAtNodeID = 'order-end-at';
export const orderIsCancelledNodeID = 'order-is-cancelled';
export const orderIsScheduledNodeID = 'order-is-scheduled';

export const tripsNodeID = 'trips';
export const tripIDNodeID = 'trip-id';
export const tripLocationNameNodeID = 'trip-location-name';
export const tripStartAtNodeID = 'trip-start-at';
export const tripEndAtNodeID = 'trip-end-at';

export const tripTravelMetricsNodeID = 'trip-travel-metrics';
export const estimatedTripTravelMetricsNodeID = 'estimated-trip-travel-metrics';
export const estimatedTripTravelDistanceNodeID =
  'estimated-trip-travel-distance';
export const estimatedTripTravelTimeMinutesNodeID =
  'estimated-trip-travel-time-minutes';
export const engagedTripTravelMetricsNodeID = 'engaged-trip-travel-metrics';
export const engagedTripTravelTimeMinutesNodeID = 'engaged-travel-time-minutes';
export const engagedTripTravelDistanceNodeID = 'engaged-travel-distance-miles';

export const basePayBreakdownNodeID = 'base-pay-breakdown';
export const realTimeAdjustmentNodeID = 'real-time-adjustment';
export const isVariableBasePayNodeID = 'is-variable-base-pay';

export const boostSummariesNodeID = 'boost-summaries';
export const boostSummaryNodeID = 'boost-summary';
export const boostPayStartAtNodeID = 'boost-start-at';
export const boostPayEndAtNodeID = 'boost-end-at';
export const boostPayNameNodeID = 'boost-name';
export const boostPayAmountNodeID = 'boost-amount';

export const returnCocPaySummariesNodeID = 'return-coc-pay-summaries';
export const returnCocPaySummaryNodeID = 'return-coc-pay-summary';
export const returnCocPayReturnLocationCountNodeID =
  'return-coc-pay-return-location-count';
export const returnCocPayAmountNodeID = 'return-coc-pay-amount';

export const engagedSubsidyPaysNodeID = 'engaged-subsidy-pays';
export const totalEngagedSubsidyPayNodeID = 'total-engaged-subsidy-pay';
export const engagedSubsidyRateNodeID = 'engaged-subsidy-rate';
export const engagedWeeklyBonusPayNodeID = 'engaged-weekly-bonus-pay';

export const totalEngagedDistancePayNodeID = 'total-engaged-distance-pay';
export const engagedDistancePaysNodeID = 'engaged-distance-pays';

export const shiftsWithEventsNodeID = 'shifts-with-events';
export const shiftWithEventsActualStartNodeID =
  'shift-with-events-actual-start';
export const shiftWithEventsActualEndNodeID = 'shift-with-events-actual-end';
export const totalWaitSecondsNodeID = 'total-wait-seconds';
export const roundedTotalWaitMinutesNodeID = 'rounded-total-wait-minutes';

export const shiftEventsNodeID = 'shift-events';

export const waitPayBreakdownsNodeID = 'wait-pay-breakdowns';
export const waitPayMinGuaranteeMinuteRateNodeID =
  'wait-pay-min-guarantee-minute-rate';
export const waitPayIsCalculatedWeeklyNodeID = 'wait-pay-is-calculated-weekly';
export const shiftBasedWaitPayWeeklyBreakdownsNodeID =
  'shift-based-wait-pay-weekly-breakdowns';

export const missionRewardSummaryTierCountNodeID =
  'mission-reward-summary-tier-count';
export const missionRewardSummaryTierPayNodeID =
  'mission-reward-summary-tier-pay';
export const missionRewardSummaryTierTypeNodeID =
  'mission-reward-summary-tier-type';
export const missionRewardSummaryTiersNodeID = 'mission-reward-summary-tiers';
export const missionRewardSummaryPayNodeID = 'mission-reward-summary-pay';
export const missionProgressSummaryOrderNodeID =
  'mission-progress-summary-order';
export const missionProgressSummaryTripNodeID = 'mission-progress-summary-trip';
export const missionNameNodeID = 'mission-name';
export const missionTypeNodeID = 'mission-type';
export const missionInvitedAtNodeID = 'mission-invited-at';
export const missionAcceptedAtNodeID = 'mission-accepted-at';
export const missionStartAtLocalNodeID = 'mission-start-at-local';
export const missionEndAtLocalNodeID = 'mission-end-at-local';
export const missionOrdersMustContainNodeID = 'mission-orders-must-contain';
export const missionRewardNodeID = 'mission-reward';
export const missionProgressNodeID = 'mission-progress';
export const missionBreakdownsNodeID = 'mission-breakdowns';

export const holidayPayBreakdownsNodeID = 'holiday-pay-breakdowns';
export const holidayPayAccruedDaysNodeID = 'holiday-pay-accrued-days';
export const holidayPayRateNodeID = 'holiday-pay-rate';

export const reimbursementPayNodeID = 'reimbursement-pay';
export const reimbursementPaysNodeID = 'reimbursement-pays';

export const composeNodeID = (terms: string[]): string => terms.join('_');
