import { AppliedRate, appliedRateFromResponse } from './AppliedRate';

export interface EngagedLocationContribution {
  locationName: string;
  engagedHoursWithRate: AppliedRate[];
  engagedMilesWithRate: AppliedRate[];
}

const engagedLocationContributionFromResponse = (
  responseData: any,
): EngagedLocationContribution => ({
  locationName: responseData.location_name ?? '',
  engagedHoursWithRate: responseData.engaged_hours_with_rate.map(
    (hoursWorked: any) => appliedRateFromResponse(hoursWorked),
  ),
  engagedMilesWithRate: responseData.engaged_miles_with_rate.map(
    (milesWorked: any) => appliedRateFromResponse(milesWorked),
  ),
});

export interface Prop22PayBreakdown {
  engagedLocationContributions: EngagedLocationContribution[];
  totalEarningsFloor: number;
  basePay: number;
  doXGetY: number;
  returnPay: number;
  minimumGuaranteeAdjustment: number;
  boostPay: number;
  hazardPay: number;
  tier2DeliveryPay: number;
  tobaccoPay: number;
  weeklyBonus: number;
  amount: number;
}

export const prop22PayBreakdownFromResponse = (
  responseData: any,
): Prop22PayBreakdown => ({
  engagedLocationContributions: responseData.engaged_location_contributions.map(
    (contribution: any) =>
      engagedLocationContributionFromResponse(contribution),
  ),
  totalEarningsFloor: responseData.total_earnings_floor ?? 0,
  basePay: responseData.base_pay ?? 0,
  doXGetY: responseData.do_x_get_y ?? 0,
  returnPay: responseData.return_pay ?? 0,
  minimumGuaranteeAdjustment: responseData.minimum_guarantee_adjustment ?? 0,
  boostPay: responseData.boost_pay ?? 0,
  hazardPay: responseData.hazard_pay ?? 0,
  tier2DeliveryPay: responseData.tier2_delivery_pay ?? 0,
  tobaccoPay: responseData.tobacco_pay ?? 0,
  weeklyBonus: responseData.weekly_bonus ?? 0,
  amount: responseData.amount ?? 0,
});
