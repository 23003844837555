import {
  MissionRewardTierSummary,
  missionRewardTierSummaryFromResponse,
} from './MissionRewardTierSummary';

export interface MissionRewardSummary {
  pay?: number;
  tierType?: string;
  tiers: MissionRewardTierSummary[];
}

export const missionRewardSummaryFromResponse = (
  responseData: any,
): MissionRewardSummary => ({
  pay: responseData.pay,
  tierType: responseData.tier_type,
  tiers: (responseData.tiers ?? []).map((tier: any) =>
    missionRewardTierSummaryFromResponse(tier),
  ),
});
