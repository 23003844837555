import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LiquorIcon from '@mui/icons-material/Liquor';
import SaveIcon from '@mui/icons-material/Save';
import ScienceIcon from '@mui/icons-material/Science';
import { cloneDeep, isEmpty, isNumber } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import getSymbolFromCurrency from 'currency-symbol-map';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../atoms/Location';
import {
  deleteMission,
  getMissionByID,
  getMissionSegments,
  updateMission,
} from '../../services/missions';
import { Mission, MissionOrderItem } from '../../interfaces/Mission/Mission';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { missionTypes } from '../../variables/Mission';
import { formatLocalTime } from '../../utilities/Dates';
import { getGeographicRequirementValue } from '../../utilities/Mission';
import { isPermitted } from '../../variables/Users';
import { Permission } from '../../interfaces/Users';
import { permissions as permissionsAtom } from '../../atoms/Users';
import { getLocations, getMetros } from '../../services/locations';
import NewSegmentSidebar from './NewSegment/NewSegmentSidebar';
import { payRateStatusFromTimeRange } from '../../utilities/PayRates';
import { PayRateStatus } from '../../interfaces/PayRate';
import { MissionSegment } from '../../interfaces/Mission/MissionSegment';
import MissionSegmentTable from './MissionSegmentTable';
import MissionExperimentationMetricsTable from './MissionExperimentationMetricsTable';

const MissionDetails = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();

  const permissions = useRecoilValue(permissionsAtom);
  const setLocations = useSetRecoilState(locationsAtom);
  const setMetros = useSetRecoilState(metrosAtom);

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingMetros, setLoadingMetros] = useState(false);
  const [loadingSegments, setLoadingSegments] = useState(false);
  const [updatingMission, setUpdatingMission] = useState(false);
  const [mission, setMission] = useState<Mission | null>(null);
  const [initialMission, setInitialMission] = useState<Mission | null>(null);
  const [missionSegments, setMissionSegments] = useState<MissionSegment[]>([]);
  const [showNewSegmentDrawer, setShowNewSegmentDrawer] = useState(false);
  const [showNewExperimentDrawer, setShowNewExperimentDrawer] = useState(false);

  const [hoveringTitle, setHoveringTitle] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [hoveringDescription, setHoveringDescription] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [hoveringStartAt, setHoveringStartAt] = useState(false);
  const [editingStartAt, setEditingStartAt] = useState(false);
  const [hoveringEndAt, setHoveringEndAt] = useState(false);
  const [editingEndAt, setEditingEndAt] = useState(false);
  const [hoveringReward, setHoveringReward] = useState(false);
  const [editingReward, setEditingReward] = useState(false);

  const { id } = useParams<{ id: string }>();
  const missionID = parseInt(id!, 10);

  const type = missionTypes.find(
    (missionType) => missionType.kind === mission?.type,
  );

  useEffect(() => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));

    setLoadingMetros(true);
    getMetros()
      .then((fetchedMetros) => setMetros(fetchedMetros))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingMetros(false));
  }, []);

  const refreshMission = () => {
    setLoadingDetails(true);
    getMissionByID(missionID)
      .then((fetchedMission) => {
        setMission(fetchedMission);
        setInitialMission(cloneDeep(fetchedMission));
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingDetails(false));

    setLoadingSegments(true);
    getMissionSegments(missionID)
      .then((fetchedSegments) => setMissionSegments(fetchedSegments))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSegments(false));
  };

  useEffect(refreshMission, [id]);

  const handleSetStartAt = (newStartAt: Date | null) => {
    if (mission) {
      setMission({ ...mission, startAtLocal: newStartAt ?? new Date() });
    }
  };

  const handleSetEndAt = (newEndAt: Date | null) => {
    if (mission) {
      setMission({ ...mission, endAtLocal: newEndAt ?? new Date() });
    }
  };

  const getDetailRows = (): (JSX.Element | undefined)[] => {
    if (!mission) {
      return [];
    }

    return [
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([sharedClasses.body1, classes.fieldName])}
        >
          Type:
        </Typography>
        <Typography className={sharedClasses.body1}>
          {type?.name ?? 'Unknown'}
        </Typography>
      </Box>,
      editingStartAt ? (
        <Box component='div' className={classes.row}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              onChange={handleSetStartAt}
              value={mission.startAtLocal}
              minDate={new Date()}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} size='small' label='Start At' />}
            />
          </LocalizationProvider>
          <IconButton
            color='primary'
            size='small'
            onClick={() => setEditingStartAt(false)}
            className={classes.verticalCenter}
          >
            <CheckIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          component='div'
          onClick={() => {
            setEditingStartAt(isEditable);
            setHoveringStartAt(false);
          }}
          onMouseEnter={() => setHoveringStartAt(true)}
          onMouseLeave={() => setHoveringStartAt(false)}
          className={classes.row}
        >
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Start At:
          </Typography>
          <Typography
            className={multiClass([
              classes.verticalCenter,
              sharedClasses.body1,
            ])}
          >
            {formatLocalTime(mission.startAtLocal)}
          </Typography>
          <EditIcon
            fontSize='small'
            className={multiClass(
              hoveringStartAt && isEditable
                ? [classes.editIcon]
                : [classes.editIcon, classes.invisible],
            )}
          />
        </Box>
      ),
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([sharedClasses.body1, classes.fieldName])}
        >
          Last Updated At:
        </Typography>
        <Typography className={sharedClasses.body1}>
          {formatLocalTime(
            mission.audit.lastUpdatedAt ?? mission.audit.createdAt,
          )}
        </Typography>
      </Box>,
      editingReward ? (
        <Box
          component='div'
          className={multiClass([classes.row, classes.rewardForm])}
        >
          <ValidatorForm onSubmit={() => {}}>
            {type?.inputGenerator(mission, setMission, false)}
          </ValidatorForm>
          <IconButton
            color='primary'
            size='small'
            onClick={() => setEditingReward(false)}
            className={classes.verticalCenter}
          >
            <CheckIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          component='div'
          onClick={() => {
            setEditingReward(isEditable);
            setHoveringReward(false);
          }}
          onMouseEnter={() => setHoveringReward(true)}
          onMouseLeave={() => setHoveringReward(false)}
          className={classes.row}
        >
          <Typography
            className={multiClass([
              sharedClasses.body1,
              classes.fieldName,
              classes.verticalCenter,
            ])}
          >
            Reward:
          </Typography>
          {type?.rewardDescriptionExtractor ? (
            type.rewardDescriptionExtractor(mission)
          ) : (
            <Typography
              className={multiClass([
                sharedClasses.body1,
                classes.verticalCenter,
              ])}
            >
              {type?.rewardValueExtractor(mission) ?? '-'}
            </Typography>
          )}
          <EditIcon
            fontSize='small'
            className={multiClass(
              hoveringReward && isEditable
                ? [classes.editIcon]
                : [classes.editIcon, classes.invisible],
            )}
          />
        </Box>
      ),
      editingEndAt ? (
        <Box component='div' className={classes.row}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              disabled={!mission.endAtLocal}
              onChange={handleSetEndAt}
              value={mission.endAtLocal ?? mission.startAtLocal}
              minDate={mission.startAtLocal}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} size='small' label='End At' />}
            />
          </LocalizationProvider>
          <IconButton
            color='primary'
            size='small'
            onClick={() => setEditingEndAt(false)}
            className={classes.verticalCenter}
          >
            <CheckIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          component='div'
          onClick={() => {
            setEditingEndAt(isEditable);
            setHoveringEndAt(false);
          }}
          onMouseEnter={() => setHoveringEndAt(true)}
          onMouseLeave={() => setHoveringEndAt(false)}
          className={classes.row}
        >
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            End At:
          </Typography>
          <Typography
            className={multiClass([
              classes.verticalCenter,
              sharedClasses.body1,
            ])}
          >
            {mission.endAtLocal ? formatLocalTime(mission.endAtLocal) : '-'}
          </Typography>
          <EditIcon
            fontSize='small'
            className={multiClass(
              hoveringEndAt && isEditable
                ? [classes.editIcon]
                : [classes.editIcon, classes.invisible],
            )}
          />
        </Box>
      ),
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([sharedClasses.body1, classes.fieldName])}
        >
          Last Updated By:
        </Typography>
        <Typography className={sharedClasses.body1}>
          {mission.audit.lastUpdatedBy ?? mission.audit.createdBy}
        </Typography>
      </Box>,
      isNumber(mission.maxDriverCount) ? (
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([sharedClasses.body1, classes.fieldName])}
          >
            Max DP Count:
          </Typography>
          <Typography className={sharedClasses.body1}>
            {mission.maxDriverCount}
          </Typography>
        </Box>
      ) : undefined,
      locationsValue ? (
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([
              sharedClasses.body1,
              classes.fieldName,
              classes.verticalCenter,
            ])}
          >
            Locations:
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.body1,
              classes.verticalCenter,
            ])}
          >
            {locationsValue}
          </Typography>
        </Box>
      ) : undefined,
      metrosValue ? (
        <Box component='div' className={classes.row}>
          <Typography
            className={multiClass([
              sharedClasses.body1,
              classes.fieldName,
              classes.verticalCenter,
            ])}
          >
            Metros:
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.body1,
              classes.verticalCenter,
            ])}
          >
            {metrosValue}
          </Typography>
        </Box>
      ) : undefined,
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([sharedClasses.body1, classes.fieldName])}
        >
          Costs (Expected/Actual):
        </Typography>
        <Typography className={multiClass([sharedClasses.body1, classes.bold])}>
          {`${
            getSymbolFromCurrency(currency) +
            (mission.expectedCosts / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }/${getSymbolFromCurrency(currency)}${(
            mission.actualCosts / 100
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
        </Typography>
      </Box>,
    ].filter((element) => !!element);
  };

  const handleGoBack = () => {
    if (navigate) {
      navigate(-1);
    }
  };

  const handleClickNewSegment = () => {
    setShowNewSegmentDrawer(true);
  };

  const handleClickNewExperiment = () => {
    setShowNewExperimentDrawer(true);
  };

  const handleAddNewSegment = () => {
    setShowNewSegmentDrawer(false);
    setShowNewExperimentDrawer(false);
    refreshMission();
  };

  const handleDelete = () => {
    if (mission) {
      setLoadingDetails(true);
      deleteMission(missionID)
        .then(handleGoBack)
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingDetails(false));
    }
  };

  const handleUpdate = () => {
    if (mission && type) {
      setUpdatingMission(true);
      updateMission(mission, type)
        .then(() => {
          toast.success('Successfully updated the mission.');
          refreshMission();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setUpdatingMission(false));
    }
  };

  const handleChangeName = (e: any) => {
    if (mission) {
      setMission({ ...mission, title: e.target?.value ?? '' });
    }
  };

  const handleChangeDescription = (e: any) => {
    if (mission) {
      setMission({ ...mission, description: e.target?.value });
    }
  };

  const locationsValue = getGeographicRequirementValue(
    mission?.requirements?.locations ?? [],
    false,
    mission?.requirements?.country,
  );

  const metrosValue = getGeographicRequirementValue(
    mission?.requirements?.metros ?? [],
    false,
    mission?.requirements?.country,
  );

  const currency = mission?.reward?.currency ?? 'USD';

  const status = mission
    ? payRateStatusFromTimeRange(mission.startAtOverall, mission.endAtOverall)
    : PayRateStatus.EXPIRED;

  const isEditable =
    isPermitted(permissions, Permission.SET_MISSIONS) &&
    status === PayRateStatus.FUTURE;

  const madeChanges =
    initialMission &&
    mission &&
    JSON.stringify(initialMission) !== JSON.stringify(mission);

  const loading =
    loadingDetails ||
    loadingLocations ||
    loadingMetros ||
    loadingSegments ||
    updatingMission;

  return mission ? (
    <Box component='div'>
      <Waiting open={loading} />
      <NewSegmentSidebar
        mission={mission}
        open={showNewSegmentDrawer}
        isExperiment={false}
        onClose={() => setShowNewSegmentDrawer(false)}
        onDone={handleAddNewSegment}
      />
      <NewSegmentSidebar
        mission={mission}
        open={showNewExperimentDrawer}
        isExperiment
        onClose={() => setShowNewExperimentDrawer(false)}
        onDone={handleAddNewSegment}
      />
      <Paper className={classes.header}>
        <Box component='div' className={classes.titleContainer}>
          <IconButton
            onClick={handleGoBack}
            className={sharedClasses.iconColor}
          >
            <ChevronLeftIcon fontSize='large' />
          </IconButton>
          <Typography
            className={multiClass([classes.verticalCenter, sharedClasses.h2])}
          >
            Mission Details
          </Typography>
          <Box component='div' className={classes.horizontalSpacer} />
          <Button
            color='primary'
            variant='contained'
            className={multiClass([
              classes.verticalCenter,
              sharedClasses.buttonText,
              classes.newSegmentButton,
            ])}
            disabled={
              !isPermitted(permissions, Permission.SET_MISSIONS) ||
              status === PayRateStatus.EXPIRED
            }
            onClick={handleClickNewSegment}
          >
            + New Segment
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={multiClass([
              classes.verticalCenter,
              sharedClasses.buttonText,
              classes.newSegmentButton,
            ])}
            startIcon={<ScienceIcon />}
            disabled={
              !isPermitted(permissions, Permission.SET_MISSIONS) ||
              status === PayRateStatus.EXPIRED
            }
            onClick={handleClickNewExperiment}
          >
            New Experiment
          </Button>
          {status !== PayRateStatus.EXPIRED && (
            <Button
              variant='contained'
              color='error'
              className={multiClass([
                classes.verticalCenter,
                sharedClasses.buttonText,
                classes.updateButton,
              ])}
              disabled={!isPermitted(permissions, Permission.SET_MISSIONS)}
              onClick={handleDelete}
            >
              {status === PayRateStatus.FUTURE || isEmpty(missionSegments)
                ? 'Delete'
                : 'Expire'}
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            startIcon={<SaveIcon />}
            className={multiClass([
              classes.verticalCenter,
              sharedClasses.buttonText,
              classes.updateButton,
            ])}
            disabled={
              !isPermitted(permissions, Permission.SET_MISSIONS) || !madeChanges
            }
            onClick={handleUpdate}
          >
            Save
          </Button>
        </Box>
      </Paper>
      <Box component='div' className={classes.body}>
        {editingTitle ? (
          <TextField
            label='Mission name'
            variant='outlined'
            fullWidth
            name='name'
            autoFocus
            value={mission.title}
            onChange={handleChangeName}
            onBlur={() => setEditingTitle(false)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.nameDescriptionTextField}
          />
        ) : (
          <Box
            component='div'
            onClick={() => {
              setEditingTitle(isEditable);
              setHoveringTitle(false);
            }}
            onMouseEnter={() => setHoveringTitle(true)}
            onMouseLeave={() => setHoveringTitle(false)}
            className={classes.row}
          >
            <Typography
              className={multiClass([sharedClasses.h3, classes.title])}
            >
              {mission.title}
            </Typography>
            {hoveringTitle && isEditable && (
              <EditIcon className={classes.editIcon} />
            )}
            {mission.isExperiment && (
              <Chip
                label='Experiment'
                color='primary'
                icon={<ScienceIcon fontSize='small' />}
                className={classes.chip}
              />
            )}
            {mission.requirements.ordersMustContain.includes(
              MissionOrderItem.ALCOHOL,
            ) && (
              <Chip
                label='Alcohol Only'
                color='primary'
                icon={<LiquorIcon fontSize='small' />}
                className={classes.chip}
              />
            )}
          </Box>
        )}
        {editingDescription ? (
          <TextField
            label='Mission description'
            variant='outlined'
            fullWidth
            multiline
            autoFocus
            value={mission.description ?? ''}
            onChange={handleChangeDescription}
            onBlur={() => setEditingDescription(false)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.nameDescriptionTextField}
          />
        ) : (
          <Box
            component='div'
            onClick={() => {
              setEditingDescription(isEditable);
              setHoveringDescription(false);
            }}
            onMouseEnter={() => setHoveringDescription(true)}
            onMouseLeave={() => setHoveringDescription(false)}
            className={classes.row}
          >
            <Box component='div' className={classes.column}>
              {(mission.description ?? '-').split('\n').map((line) => (
                <Typography
                  className={multiClass([
                    sharedClasses.body1,
                    classes.description,
                  ])}
                >
                  {line}
                </Typography>
              ))}
            </Box>
            {hoveringDescription && isEditable && (
              <EditIcon fontSize='small' className={classes.editIcon} />
            )}
          </Box>
        )}
        <Box component='div' className={classes.verticalSpacer} />
        <Grid container spacing={0.5} className={classes.grid}>
          {getDetailRows().map((row) => (
            <Grid item xs={4} className={classes.grid}>
              {row}
            </Grid>
          ))}
        </Grid>
        <MissionSegmentTable
          missionID={mission?.id}
          currency={currency}
          segments={missionSegments}
        />
        {mission?.isExperiment && !!mission?.experimentationMetrics && (
          <MissionExperimentationMetricsTable
            metrics={mission.experimentationMetrics}
          />
        )}
      </Box>
    </Box>
  ) : (
    <Waiting open={loading} />
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(3.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },
  title: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  chip: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(1.5),
    fontWeight: 'bold',
  },
  editIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(1.5),
    color: sharedColors.gray4,
  },
  description: {
    marginTop: theme.spacing(0.75),
    fontStyle: 'italic',
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  newSegmentButton: {
    textTransform: 'none',
    marginLeft: theme.spacing(1.5),
  },
  updateButton: {
    textTransform: 'none',
    marginLeft: theme.spacing(1.5),
  },
  horizontalSpacer: {
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  invisible: {
    visibility: 'hidden',
  },
  grid: {
    width: 'fit-content',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  fieldName: {
    color: sharedColors.gray5,
    marginRight: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  infoIcon: {
    padding: 0,
    marginLeft: theme.spacing(0.75),
  },
  verticalSpacer: {
    marginTop: theme.spacing(1),
  },
  nameDescriptionTextField: {
    width: 428,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1.5),
  },
  rewardForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  messageTemplateButton: {
    padding: 0,
    paddingTop: theme.spacing(0.25),
    marginTop: 'auto',
    marginBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(0.25),
    minHeight: 0,
    minWidth: 0,
  },
}));

export default MissionDetails;
