import { Box, DialogContent, FormControl } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import React from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import { sharedColors } from '../utilities/Styles';
import { csvMimeTypes } from '../utilities/Csv';

interface UploadCSVProps {
  setParentFile: (file: File) => void;
}

const UploadCSV = (props: UploadCSVProps) => {
  const { classes } = useStyles();

  const handleFile = (file: File[]) => {
    props.setParentFile(file[0]);
  };

  return (
    <Box component='div' className={classes.contentWrapper}>
      <DialogContent>
        <FormControl component='fieldset' className={classes.contentGrid}>
          <DropzoneArea
            dropzoneClass={classes.dropzone}
            acceptedFiles={[csvMimeTypes]}
            filesLimit={1}
            showFileNames
            onChange={handleFile}
          />
        </FormControl>
      </DialogContent>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  contentGrid: {
    display: 'flex',
    flexGrow: 1,
  },
  dropzone: {
    position: 'relative',
    padding: theme.spacing(2),
    minHeight: '100px',
    backgroundColor: sharedColors.gray2,
    border: 'dashed',
    borderColor: sharedColors.gray3,
    cursor: 'pointer',
  },
}));

export default UploadCSV;
