import { Button } from '@mui/material';
import { toast } from 'material-react-toastify';
import IconToastContainer from '../components/IconToastContainer';

interface ToastButtonProps {
  closeToast: () => void;
}

const undoButton = (
  color: string,
  onClick: () => void,
): ((props: ToastButtonProps) => JSX.Element) => {
  return (props: ToastButtonProps) => {
    const onUndoClick = () => {
      onClick();
      props.closeToast();
    };
    return (
      <Button sx={{ color }} onClick={onUndoClick}>
        UNDO
      </Button>
    );
  };
};

/**
 * Executes the callback after the timeout. Returns a function that cancels the callback if called before the timeout.
 */
const delayWithCancel = (
  timeout: number,
  callback: () => void,
): (() => void) => {
  const controller = new AbortController();

  new Promise((resolve, reject) => {
    setTimeout(resolve, timeout);
    controller.signal.addEventListener('abort', () => reject());
  })
    .then(() => callback())
    .catch(() => {});

  return () => controller.abort();
};

/**
 * Renders a toast with the given message and an "undo" button. afterClose is called when the toast closes, unless "undo" is
 * clicked.
 */
export const undoToast = (
  text: string,
  type: 'info' | 'success' | 'warning' | 'error' = 'success',
  afterClose: () => void,
  delay?: number,
) => {
  const autoClose = delay || 5000;
  const color = type === 'warning' ? 'black' : 'white';

  const cancel = delayWithCancel(autoClose, afterClose);
  const closeButton = undoButton(color, cancel);

  toast(<IconToastContainer text={text} type={type} color={color} />, {
    type,
    closeButton,
    autoClose,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    closeOnClick: false,
  });
};
