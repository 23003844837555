import { makeStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import Waiting from '../Waiting';
import { FeatureToggleConfigWithActiveInclusionCount } from '../../interfaces/FeatureToggle';
import { getFeatureToggleConfigsWithActiveInclusionCount } from '../../services/featureToggle';
import { featureTogglePageUrl } from '../../variables/Pages';

const FeatureToggleConfigs = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [featureToggleConfigs, setFeatureToggleConfigs] = useState(
    [] as FeatureToggleConfigWithActiveInclusionCount[],
  );

  useEffect(() => {
    setLoading(true);
    getFeatureToggleConfigsWithActiveInclusionCount()
      .then((fetchedConfigs) => setFeatureToggleConfigs(fetchedConfigs))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, []);

  const handleShowDetails = (featureName: string) => {
    if (navigate) {
      navigate(`${featureTogglePageUrl}/${featureName}`);
    }
  };

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Paper className={classes.header}>
        <Box component='div' className={classes.textContainer}>
          <Typography className={sharedClasses.h2}>Feature Toggles</Typography>
        </Box>
      </Paper>
      <Box component='div' className={classes.body}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size='small'>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell key='name'>
                  <Typography className={classes.columnLabel}>Name</Typography>
                </TableCell>
                <TableCell key='inclusion-rate'>
                  <Typography className={classes.columnLabel}>
                    Inclusion Rate
                  </Typography>
                </TableCell>
                <TableCell key='type'>
                  <Typography className={classes.columnLabel}>Type</Typography>
                </TableCell>
                <TableCell key='dependency'>
                  <Typography className={classes.columnLabel}>
                    Dependency
                  </Typography>
                </TableCell>
                <TableCell key='active-inclusion-count'>
                  <Typography className={classes.columnLabel}>
                    Active Inclusion Count
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureToggleConfigs.map((config) => (
                <TableRow
                  key={config.name}
                  onClick={() => handleShowDetails(config.name)}
                  className={classes.editableRow}
                >
                  <TableCell key={`${config.name}-name`}>
                    <Typography>{config.name}</Typography>
                  </TableCell>
                  <TableCell key={`${config.name}-inclusion-rate`}>
                    <Typography>{`${config.inclusionRate.toFixed(
                      2,
                    )}%`}</Typography>
                  </TableCell>
                  <TableCell key={`${config.name}-type`}>
                    <Typography>{config.type}</Typography>
                  </TableCell>
                  <TableCell key={`${config.name}-dependency`}>
                    <Typography>{config.dependency ?? '-'}</Typography>
                  </TableCell>
                  <TableCell key={`${config.name}-active-inclusion-count`}>
                    <Typography>{config.activeInclusionCount}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  textContainer: {
    display: 'flex',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  editableRow: {
    cursor: 'pointer',
  },
}));

export default FeatureToggleConfigs;
