import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { cloneDeep, isEmpty } from 'lodash';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import Waiting from '../Waiting';
import UploadCSV from '../UploadCSV';
import {
  MissionToUpload,
  MissionUploadState,
  parseMissionsToUpload,
} from '../../interfaces/Mission/MissionToUpload';
import { BulkErrorDialog } from '../BulkErrorDialog';
import { missionTypes } from '../../variables/Mission';
import {
  createMission,
  createMissionSegment,
  deleteMission,
} from '../../services/missions';
import { errorMessageSeparator } from '../../services/api';
import { MissionSegmentationKind } from '../../interfaces/Mission/MissionSegment';
import { downloadCsvTemplate } from '../../utilities/Csv';
import { goMissionsCsvTemplate } from '../../variables/CsvTemplate';

interface UploadMissionsDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: () => void;
}

const UploadMissionsDialog = (props: UploadMissionsDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [missionsToUpload, setMissionsToUpload] = useState<MissionToUpload[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = (e.target?.result ?? '') as string;
        const csvRows = text
          .split('\n')
          .slice(1)
          .filter((line) => line.trim().length > 0)
          .map((line) => line.split(',').map((cell) => cell.trim()));

        try {
          setMissionsToUpload(parseMissionsToUpload(csvRows));
        } catch (err: any) {
          toast.error(err?.message ?? 'Could not read the input file');
          setMissionsToUpload([]);
        }
      };
      reader.onerror = () => toast.error('Could not read the input file');

      reader.readAsText(file);
    } else {
      setMissionsToUpload([]);
    }
  }, [file]);

  const getMissionUploadStatus = (missionToUpload: MissionToUpload): string => {
    switch (missionToUpload.state) {
      case MissionUploadState.DRAFT:
        return '-';
      case MissionUploadState.UPLOADING_MISSION:
        return '🔄 Uploading';
      case MissionUploadState.UPLOAD_MISSION_FAILED:
        return '❌ Failed';
      case MissionUploadState.UPLOADING_SEGMENT:
      case MissionUploadState.UPLOAD_SEGMENT_FAILED:
      case MissionUploadState.DONE:
        return '✅ Done';
      case MissionUploadState.ROLLED_BACK:
        return '⏪ Rolled back';
      case MissionUploadState.ALREADY_EXISTS:
        return '🟠 Already created';
      default:
        return '-';
    }
  };

  const getSegmentUploadStatus = (missionToUpload: MissionToUpload): string => {
    switch (missionToUpload.state) {
      case MissionUploadState.DRAFT:
      case MissionUploadState.UPLOADING_MISSION:
      case MissionUploadState.ALREADY_EXISTS:
        return '-';
      case MissionUploadState.UPLOAD_MISSION_FAILED:
      case MissionUploadState.UPLOAD_SEGMENT_FAILED:
      case MissionUploadState.ROLLED_BACK:
        return '❌ Failed';
      case MissionUploadState.UPLOADING_SEGMENT:
        return '🔄 Uploading';
      case MissionUploadState.DONE:
        return '✅ Done';
      default:
        return '-';
    }
  };

  const uploadMissions = async () => {
    const newMissions = cloneDeep(missionsToUpload);
    const newErrorMessages: string[] = [];
    for (let i = 0; i < missionsToUpload.length; i += 1) {
      const missionType = missionTypes.find(
        (type) => type.kind === newMissions[i].type,
      );
      if (missionType) {
        try {
          // eslint-disable-next-line no-await-in-loop
          newMissions[i].id = await createMission(
            missionsToUpload[i],
            missionType,
          );
          newMissions[i].state = MissionUploadState.UPLOADING_SEGMENT;
        } catch (err: any) {
          if (err.message?.includes('Status conflict')) {
            newMissions[i].state = MissionUploadState.ALREADY_EXISTS;
          } else {
            const newErrorMessage = `Error while uploading mission '${newMissions[i].title}'. Reason: ${err.message}`;
            newErrorMessages.push(newErrorMessage);
            newMissions[i].state = MissionUploadState.UPLOAD_MISSION_FAILED;
            newMissions[i].errorMessage = newErrorMessage;
          }
        }

        setMissionsToUpload(newMissions);
        if (newMissions[i].state === MissionUploadState.UPLOADING_SEGMENT) {
          newMissions[i].state = MissionUploadState.DONE;
          for (let j = 0; j < newMissions[i].segmentsToCreate.length; j += 1) {
            try {
              // eslint-disable-next-line no-await-in-loop
              await createMissionSegment(
                newMissions[i].id,
                newMissions[i].segmentsToCreate[j],
                [
                  MissionSegmentationKind.COUNTRY,
                  MissionSegmentationKind.LOCATION,
                  MissionSegmentationKind.DRIVER,
                  MissionSegmentationKind.MUST_BE_ACTIVE_AT,
                ],
                false,
              );
            } catch (err: any) {
              const newErrorMessage = `Error while uploading segment ${(
                j + 1
              ).toString()} for mission '${newMissions[i].title}'. Reason: ${
                err.message
              }`;
              newErrorMessages.push(newErrorMessage);
              newMissions[i].state = MissionUploadState.UPLOAD_SEGMENT_FAILED;
              newMissions[i].errorMessage = newErrorMessage;
              break;
            }
          }

          setMissionsToUpload(newMissions);
        }

        if (newMissions[i].state === MissionUploadState.UPLOAD_SEGMENT_FAILED) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await deleteMission(newMissions[i].id);
            newMissions[i].state = MissionUploadState.ROLLED_BACK;
          } catch (err: any) {
            const newErrorMessage = `Error while rolling back mission '${newMissions[i].title}'. Reason: ${err.message}`;
            newErrorMessages.push(newErrorMessage);
          }
        }
      }
    }

    if (!isEmpty(newErrorMessages)) {
      throw new Error(newErrorMessages.join(errorMessageSeparator));
    }
  };

  const handleUpload = () => {
    setLoading(true);
    uploadMissions()
      .catch((err) => setErrorMessage(err.message))
      .finally(() => {
        setLoading(false);
        props.onUpload();
      });
  };

  const handleOpenMissionInNewTab = (missionToUpload: MissionToUpload) => {
    window.open(
      `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }/details/${missionToUpload.id.toString()}`,
      '_blank',
      'noreferrer',
    );
  };

  const handleClickRow = (missionToUpload: MissionToUpload) => {
    switch (missionToUpload.state) {
      case MissionUploadState.ROLLED_BACK:
      case MissionUploadState.UPLOAD_MISSION_FAILED: {
        setErrorMessage(missionToUpload.errorMessage);
        break;
      }
      case MissionUploadState.UPLOAD_SEGMENT_FAILED: {
        setErrorMessage(missionToUpload.errorMessage);
        handleOpenMissionInNewTab(missionToUpload);
        break;
      }
      case MissionUploadState.UPLOADING_SEGMENT:
      case MissionUploadState.DONE: {
        handleOpenMissionInNewTab(missionToUpload);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Dialog open={props.open} onClose={loading ? undefined : props.onClose}>
      <Waiting open={loading} />
      <BulkErrorDialog
        open={!!errorMessage}
        message={errorMessage ?? ''}
        onClose={() => setErrorMessage('')}
      />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Upload Missions
      </DialogTitle>
      <DialogContent>
        <UploadCSV setParentFile={setFile} />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size='small'>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell key='name'>
                  <Typography className={classes.columnLabel}>
                    Mission Name
                  </Typography>
                </TableCell>
                <TableCell key='created'>
                  <Typography className={classes.columnLabel}>
                    Created
                  </Typography>
                </TableCell>
                <TableCell key='invited'>
                  <Typography className={classes.columnLabel}>
                    DPs Invited
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(missionsToUpload) ? (
                <TableRow key='no-missions'>
                  <TableCell colSpan={3} align='center'>
                    <Typography
                      className={multiClass([
                        sharedClasses.subtitle2,
                        classes.noSegmentText,
                      ])}
                    >
                      No missions to upload
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                missionsToUpload.map((missionToUpload, i) => {
                  const isClickable =
                    missionToUpload.state !== MissionUploadState.DRAFT &&
                    missionToUpload.state !== MissionUploadState.ALREADY_EXISTS;

                  return (
                    <TableRow
                      key={i.toString()}
                      onClick={() => handleClickRow(missionToUpload)}
                      className={isClickable ? classes.clickableRow : undefined}
                    >
                      <TableCell key={`${i.toString()}_name`}>
                        {missionToUpload.title}
                      </TableCell>
                      <TableCell key={`${i.toString()}_created`}>
                        {getMissionUploadStatus(missionToUpload)}
                      </TableCell>
                      <TableCell key={`${i.toString()}_invited`}>
                        {getSegmentUploadStatus(missionToUpload)}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box component='div' className={classes.dialogActionsSpacer}>
          <Button
            onClick={() => downloadCsvTemplate(goMissionsCsvTemplate)}
            className={sharedClasses.buttonText}
          >
            Download CSV Template for Missions
          </Button>
        </Box>
        <Button
          variant='contained'
          color='inherit'
          disabled={loading}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          disabled={isEmpty(missionsToUpload) || loading}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  dialogActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  noSegmentText: {
    flexGrow: 1,
    textAlign: 'center',
    lineHeight: 2,
  },
  clickableRow: {
    cursor: 'pointer',
  },
}));

export default UploadMissionsDialog;
