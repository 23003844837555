import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import {
  MissionSegmentationKind,
  MissionSegmentToCreate,
  OutputTransformerFn,
} from '../interfaces/Mission/MissionSegment';
import { Location } from '../interfaces/Location';
import { filterLocationById } from './Locations';
import { Mission } from '../interfaces/Mission/Mission';
import { NumberWithOperatorOutputTransformer } from '../interfaces/NumberWithOperator';
import { missionSegmentToCreateExperimentationOutputTransformer } from '../interfaces/Mission/MissionSegmentToCreateExperimentation';
import { convertLocalToDeliveryZone } from './Dates';

export const getMissionSegmentTemplate = (
  isExperiment: boolean,
  mission?: Mission,
): MissionSegmentToCreate => ({
  name: '',
  includeAlreadyActiveDrivers: false,
  countryCode: mission?.countryCode ?? 'US',
  metroIDs: (mission?.requirements?.metros ?? []).map((metro) => metro.id),
  locationIDs: (mission?.requirements?.locations ?? []).map(
    (location) => location.id,
  ),
  driverIDs: [],
  mustBeActiveAt: [],
  completedFirstDelivery: undefined,
  autoAcceptInvitations: false,
  sendPushNotifications: true,
  experimentation: {
    isExperiment,
    stratification: {
      byMetro: false,
      byDriverHours: [],
    },
  },
});

export const defaultMissionSegmentOutputTransformer: OutputTransformerFn = (
  currentState,
  isSandbox,
  segmentationTypes,
) => {
  const scheduledForLocal = currentState.scheduledForLocal
    ? DateTime.fromJSDate(currentState.scheduledForLocal)
    : undefined;

  const notActiveSince = currentState.notActiveSince
    ? DateTime.fromJSDate(currentState.notActiveSince)
    : undefined;

  return {
    title: currentState.name,
    scheduled_for_local: scheduledForLocal
      ? convertLocalToDeliveryZone(
          'UTC',
          scheduledForLocal,
          scheduledForLocal.hour,
          scheduledForLocal.minute,
        ).toISO()
      : undefined,
    include_already_active_drivers: currentState.includeAlreadyActiveDrivers,
    is_sandbox: isSandbox,
    completed_first_delivery: currentState.completedFirstDelivery,
    auto_accept_invitations: currentState.autoAcceptInvitations,
    send_push_notifications: currentState.sendPushNotifications,
    recent_driver_hours: currentState.recentDriverHours
      ? NumberWithOperatorOutputTransformer(currentState.recentDriverHours)
      : undefined,
    acceptance_rate: currentState.acceptanceRate
      ? NumberWithOperatorOutputTransformer(currentState.acceptanceRate)
      : undefined,
    completion_rate: currentState.completionRate
      ? NumberWithOperatorOutputTransformer(currentState.completionRate)
      : undefined,
    on_time_rate: currentState.onTimeRate
      ? NumberWithOperatorOutputTransformer(currentState.onTimeRate)
      : undefined,
    dnr_rate: currentState.dnrRate
      ? NumberWithOperatorOutputTransformer(currentState.dnrRate)
      : undefined,
    scheduled_availability_rate: currentState.scheduledAvailabilityRate
      ? NumberWithOperatorOutputTransformer(
          currentState.scheduledAvailabilityRate,
        )
      : undefined,
    completed_order_count: currentState.completedOrderCount
      ? NumberWithOperatorOutputTransformer(currentState.completedOrderCount)
      : undefined,
    composite_score: currentState.compositeScore
      ? NumberWithOperatorOutputTransformer(currentState.compositeScore)
      : undefined,
    driver_ids: segmentationTypes.includes(MissionSegmentationKind.DRIVER)
      ? currentState.driverIDs
      : [],
    recently_active_at:
      segmentationTypes.includes(MissionSegmentationKind.COUNTRY) ||
      segmentationTypes.includes(MissionSegmentationKind.LOCATION)
        ? {
            country_code: segmentationTypes.includes(
              MissionSegmentationKind.COUNTRY,
            )
              ? currentState.countryCode
              : undefined,
            metro_ids: segmentationTypes.includes(
              MissionSegmentationKind.LOCATION,
            )
              ? currentState.metroIDs
              : undefined,
            location_ids: segmentationTypes.includes(
              MissionSegmentationKind.LOCATION,
            )
              ? currentState.locationIDs
              : undefined,
          }
        : undefined,
    currently_active_at: segmentationTypes.includes(
      MissionSegmentationKind.MUST_BE_ACTIVE_AT,
    )
      ? { location_ids: currentState.mustBeActiveAt }
      : undefined,
    not_active_since: notActiveSince
      ? convertLocalToDeliveryZone(
          'UTC',
          notActiveSince,
          notActiveSince.hour,
          notActiveSince.minute,
        ).toISO()
      : undefined,
    experimentation: missionSegmentToCreateExperimentationOutputTransformer(
      currentState.experimentation,
    ),
  };
};

export const getShortenedLocationFieldValue = (
  locationIDs: string[],
  locations: Location[],
): string => {
  const firstLocation = filterLocationById(
    locations,
    parseInt(locationIDs[0], 10),
  );

  if (!firstLocation) {
    return `${locationIDs.length.toString()} location(s)`;
  }

  return (
    firstLocation.name +
    (locationIDs.length > 1 ? ` + ${(locationIDs.length - 1).toString()}` : '')
  );
};

export const getShortenedRecentlyActiveAtFieldValue = (
  recentlyActiveAtNames: string[],
): string => {
  if (isEmpty(recentlyActiveAtNames)) {
    return '-';
  }

  return (
    recentlyActiveAtNames[0] +
    (recentlyActiveAtNames.length > 1
      ? ` + ${(recentlyActiveAtNames.length - 1).toString()}`
      : '')
  );
};
