import { isArray } from 'lodash';
import {
  PushNotificationToCreate,
  pushNotificationToCreateOutputTransformer,
} from '../interfaces/PushNotification/PushNotificationToCreate';
import api from './api';
import { TablePaginationOptions } from '../interfaces/Common';
import {
  pushNotificationFromResponse,
  PushNotificationsList,
} from '../interfaces/PushNotification/PushNotification';

const pushNotificationsUrl = '/push-notifications';

export const createPushNotification = async (
  pushNotificationToCreate: PushNotificationToCreate,
): Promise<string[]> => {
  const pushNotificationToPost = pushNotificationToCreateOutputTransformer(
    pushNotificationToCreate,
  );

  try {
    await api.post(pushNotificationsUrl, pushNotificationToPost);
  } catch (err: any) {
    if (isArray(err?.metadata?.blocking_driver_ids)) {
      return err.metadata.blocking_driver_ids;
    }
    throw err;
  }

  return [];
};

export const getPushNotifications = async (
  pagination: TablePaginationOptions,
): Promise<PushNotificationsList> => {
  const response = await api.get(pushNotificationsUrl, {
    params: { page: pagination.page, 'page-size': pagination.rowsPerPage },
  });

  const responseData = response?.data;
  if (!isArray(responseData?.data)) {
    throw new Error(
      'Could not get push notifications: Response data is not an array',
    );
  }

  const totalCount = responseData?.pagination?.total_count;
  if (totalCount === undefined) {
    throw new Error(
      'Could not get total push notification count: Pagination is unavailable',
    );
  }

  return {
    pushNotifications: responseData.data.map((notification: any) =>
      pushNotificationFromResponse(notification),
    ),
    totalCount,
  };
};
