import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSharedStyles } from '../../../../utilities/Styles';
import { EngagedDistancePayBreakdown } from '../../../../interfaces/PayHistory/EngagedDistancePayBreakdown';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  amountNodeID,
  composeNodeID,
  engagedDistanceRateNodeID,
  engagedLocationNameNodeID,
  engagedMilesNodeID,
} from '../../../../variables/PayHistory';
import SinglePayoutNode from '../SinglePayoutNode';

interface EngagedDistancePayNodeProps {
  id: string;
  name: string;
  engagedDistancePayBreakdown: EngagedDistancePayBreakdown;
  currency: string;
}

const EngagedDistancePayNode = (props: EngagedDistancePayNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${props.name}{${Object.keys(
          props.engagedDistancePayBreakdown,
        ).length.toString()}}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, engagedLocationNameNodeID])}
        name='Location Name'
        value={props.engagedDistancePayBreakdown.locationName}
      />
      <SingleTextNode
        id={composeNodeID([props.id, engagedMilesNodeID])}
        name='Engagd Miles'
        value={props.engagedDistancePayBreakdown.engagedMiles.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, engagedDistanceRateNodeID])}
        name='Engaged Distance Rate'
        amount={props.engagedDistancePayBreakdown.engagedDistanceRate}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, amountNodeID])}
        name='Amount'
        amount={props.engagedDistancePayBreakdown.amount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default EngagedDistancePayNode;
