import React from 'react';
import { Theme, Typography, Box } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { PayRateFormProps, FirstShiftBonus } from '../../../interfaces/PayRate';
import { useSharedStyles } from '../../../utilities/Styles';
import { generateNumberInput } from '../../../utilities/PayRates';

export const FirstShiftBonusForm = (props: PayRateFormProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;
  return (
    <Box component='div' className={classes.formWrapper}>
      <Typography className={sharedClasses.subtitle2}>
        Set bonus rate and bonus thresholds for minimum orders/hours worked
      </Typography>
      <Box component='div' className={classes.row}>
        <Box component='div' className={classes.numberField}>
          {generateNumberInput<FirstShiftBonus>(
            true,
            true,
            props.currentState as FirstShiftBonus,
            props.setter,
            'amount',
            props.isDisabled,
            'Bonus Rate',
          )}
        </Box>
        <Box component='div' className={classes.numberField}>
          {generateNumberInput<FirstShiftBonus>(
            true,
            false,
            props.currentState as FirstShiftBonus,
            props.setter,
            'minOrders',
            props.isDisabled,
            'Minimum Orders',
          )}
        </Box>
        <Box component='div' className={classes.numberField}>
          {generateNumberInput<FirstShiftBonus>(
            true,
            false,
            props.currentState as FirstShiftBonus,
            props.setter,
            'minHours',
            props.isDisabled,
            'Minimum Hours',
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      marginTop: theme.spacing(1.5),
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
  }),
);
