import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AdjustmentSubtype } from '../../../interfaces/AdjustmentSubtype';
import {
  createAdjustmentSubtype,
  deleteAdjustmentSubtype,
  getAdjustmentCategoriesAndSubtypes,
} from '../../../services/adjustmentSubtypes';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import Waiting from '../../Waiting';
import AdjustmentSubtypeList from './AdjustmentSubtypeList';
import NewAdjustmentSubtype from './NewAdjustmentSubtype';

export const subtypeSettingsDialogTestId = 'subtype-settings-dialog';

interface SubtypeSettingsDialogueProps {
  open: boolean;
  onClose: (didUpdate: boolean) => void;
}

const SubtypeSettingsDialog = (props: SubtypeSettingsDialogueProps) => {
  const sharedClasses = useSharedStyles().classes;
  const { classes } = useStyles();

  const [loading, setLoading] = useState(true);
  const [subtypes, setSubtypes] = useState<AdjustmentSubtype[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    if (props.open) {
      setLoading(true);
      getAdjustmentCategoriesAndSubtypes()
        .then((categoriesAndSubtypes) => {
          setSubtypes(categoriesAndSubtypes.subtypes);
          setCategories(categoriesAndSubtypes.categories);
        })
        .catch((error) => {
          toast.error(`Could not get adjustment subtypes: ${error.message}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.open]);

  // Setup a queue of api calls for when the user hit's "Save"
  const [apiCalls, setApiCalls] = useState<(() => Promise<void>)[]>([]);
  const enqueueDelete = (subtype: AdjustmentSubtype): void => {
    setApiCalls(apiCalls.concat(() => deleteAdjustmentSubtype(subtype.id)));
  };
  const handleCancel = () => {
    props.onClose(false);
    setApiCalls([]);
  };
  const handleSave = () => {
    if (apiCalls.length > 0) {
      setLoading(true);
      Promise.all(apiCalls.map((apiCall) => apiCall()))
        .then(() => {
          toast.success(`Changes saved successfully`);
        })
        .catch((error) => {
          toast.error(`Error saving some or all changes: ${error.message}`);
        })
        .finally(() => {
          setLoading(false);
          props.onClose(true);
          setApiCalls([]);
        });
    } else {
      props.onClose(false);
    }
  };

  const addSubtype = (subtype: AdjustmentSubtype) => {
    const newSubtypes = subtypes.concat(subtype);
    setSubtypes(newSubtypes);
    setApiCalls(
      apiCalls.concat(async () => {
        await createAdjustmentSubtype(
          subtype.adjustmentType,
          subtype.description,
          subtype.requiresDate,
          subtype.requiresTextInput,
          subtype.thresholdAmount,
        );
      }),
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleCancel}
      fullWidth={true}
      data-testid={subtypeSettingsDialogTestId}
    >
      <Waiting open={loading} />

      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Settings
      </DialogTitle>

      <DialogContent>
        <Typography className={sharedClasses.subtitle1}>
          Add New Adjustment Type
        </Typography>
        <NewAdjustmentSubtype
          addSubtype={addSubtype}
          adjustmentCategories={categories}
        />

        <Typography className={sharedClasses.subtitle1}>
          Edit Adjustment Types
        </Typography>
        <AdjustmentSubtypeList subtypes={subtypes} onDelete={enqueueDelete} />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleCancel}
          variant='contained'
          color={'inherit'}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant='contained'
          color='primary'
          type='submit'
          disabled={apiCalls.length === 0}
          className={multiClass([sharedClasses.buttonText, classes.saveButton])}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()(() => ({
  title: {
    color: sharedColors.gray6,
    paddingBottom: '20px',
  },
  saveButton: {
    background: '#1976D2',
  },
}));

export default SubtypeSettingsDialog;
