import moment from 'moment-timezone';
import { DateTime } from 'luxon';

export const getCurrentWorkweekInTimezone = (timezone: string): Workweek => {
  const now = DateTime.now().setZone(timezone);
  return now
    .set({ weekday: 1, hour: 5, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
};

export const getWorkweekMonday = (date: Date): Date => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

export type Workweek = Date;

export const workweekFromDate = (date: Date): Workweek => {
  return getWorkweekMonday(date);
};

export const previousWorkweekOf = (workweek: Workweek): Workweek => {
  const oneWeekAgo = DateTime.fromJSDate(workweek).minus({ day: 7 }).toJSDate();
  return workweekFromDate(oneWeekAgo);
};

export const getEarliestSelectableWorkweekForAdjustment = (): Workweek => {
  const nowInUtc = moment().utc();
  if (
    nowInUtc.isoWeekday() > 2 ||
    (nowInUtc.isoWeekday() === 2 && nowInUtc.hour() > 14)
  ) {
    return nowInUtc.isoWeekday(-5).toDate();
  }
  return nowInUtc.isoWeekday(-12).toDate();
};

export const getCurrentWorkweek = (timezone?: string): Workweek => {
  return workweekFromDate(timezone ? moment.tz(timezone).toDate() : new Date());
};

const workweekApiFormat = 'DD-MM-YYYY';
const workweekISOFormat = 'YYYY-MM-DD';

export const workweekApiString = (workweek: Workweek): string => {
  return moment(workweek).format(workweekApiFormat);
};

export const workweekISOString = (workweek: Workweek): string => {
  return moment(workweek).format(workweekISOFormat);
};
export const workweekRangeString = (workweek: Workweek): string => {
  const lastDay = DateTime.fromJSDate(workweek).plus({ day: 6 }).toJSDate();
  let prefix = moment(workweek).format('MMMM D');
  if (lastDay.getFullYear() !== workweek.getFullYear()) {
    prefix += `, ${moment(workweek).format('YYYY')}`;
  }
  const postFix = moment(lastDay).format('MMMM D, YYYY');
  return `${prefix} - ${postFix}`;
};

export const workweekRangeStringWithoutYear = (workweek: Workweek): string => {
  const lastDay = DateTime.fromJSDate(workweek).plus({ day: 6 }).toJSDate();
  const prefix = moment(workweek).format('MMMM D');
  const postFix = moment(lastDay).format('MMMM D');
  return `${prefix} - ${postFix}`;
};

export const parseWorkweekApiString = (wwStr: string): Workweek => {
  return moment(wwStr, workweekApiFormat).toDate();
};

export const parseWorkweekISOString = (wwStr: string): Workweek => {
  return moment(wwStr, workweekISOFormat).toDate();
};
