export interface ShiftBreakdown {
  scheduledStart: Date;
  scheduledEnd: Date;
  actualStart: Date;
  actualEnd: Date;
  actualScheduledHoursWorked: number;
}

export const shiftBreakdownFromResponse = (
  responseData: any,
): ShiftBreakdown => ({
  scheduledStart: new Date(responseData.scheduled_start),
  scheduledEnd: new Date(responseData.scheduled_end),
  actualStart: new Date(responseData.actual_start),
  actualEnd: new Date(responseData.actual_end),
  actualScheduledHoursWorked: responseData.actual_scheduled_hours_worked,
});
