import { Box, Paper, Theme, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { selectPayTypeInfoBoxMessageTestId } from '../../Steps/SelectPayType';
import { Location } from '../../../../interfaces/Location';
import { sharedColors } from '../../../../utilities/Styles';

interface EngagedPayInfoBoxProps {
  selectedLocation: Location;
}

const EngagedPayInfoBox = (props: EngagedPayInfoBoxProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.infoBoxContainer}>
      <InfoOutlinedIcon fontSize='medium' className={classes.infoBoxIcon} />
      <Box component='div' className={classes.infoBoxTextWrapper}>
        <Typography className={classes.infoBoxTitle}>
          Notice for Locations in California
        </Typography>
        <Typography
          className={classes.infoBoxMessage}
          data-testid={selectPayTypeInfoBoxMessageTestId}
        >
          Delivery partners working in California qualify for Prop 22, which
          guarantees a minimum earnings based on their
          <Box className={classes.infoBoxBoldMessage}>engaged hours</Box>and
          <Box className={classes.infoBoxBoldMessage}>engaged mileage</Box>.
          Note that this is different from Minimum Guarantee, which is an
          earnings guarantee for scheduled hours only, and is calculated on a
          weekly basis averaging across all scheduled hours for a location.
        </Typography>
        <Typography className={classes.infoBoxSecondaryMessage}>
          <Box className={classes.infoBoxBoldMessage}>
            The Prop 22 Engaged Rate
          </Box>
          below should be at least 120% of the
          <Box className={classes.infoBoxBoldMessage}>local minimum wage</Box>in
          <Box className={classes.infoBoxBoldMessage}>
            {props.selectedLocation.name}
          </Box>
          .
        </Typography>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      backgroundColor: sharedColors.statusYellowLightest,
    },
    infoBoxIcon: {
      color: sharedColors.statusYellow,
    },
    infoBoxTextWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.7),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.statusYellowDark,
    },
    infoBoxBoldMessage: {
      fontWeight: 'bold',
      display: 'inline',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    infoBoxMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '18px',
      color: sharedColors.statusYellowDark,
      marginTop: theme.spacing(1),
    },
    infoBoxSecondaryMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '18px',
      color: sharedColors.statusYellowDark,
      marginTop: theme.spacing(1),
    },
  }),
);

export default EngagedPayInfoBox;
