export interface BoostPaySummary {
  startAt: Date;
  endAt: Date;
  name: string;
  amount: number;
}

export const boostPaySummaryFromResponse = (
  responseData: any,
): BoostPaySummary => ({
  startAt: new Date(responseData.start_at),
  endAt: new Date(responseData.end_at),
  name: responseData.name ?? '',
  amount: responseData.amount ?? 0,
});
