import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { BoostPaySummary } from '../../../../interfaces/PayHistory/BoostPaySummary';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  boostPayAmountNodeID,
  boostPayEndAtNodeID,
  boostPayNameNodeID,
  boostPayStartAtNodeID,
  composeNodeID,
} from '../../../../variables/PayHistory';
import { formatDateTime } from '../../../../utilities/Dates';
import SinglePayoutNode from '../SinglePayoutNode';

interface BoostPayNodeProps {
  boostPaySummary: BoostPaySummary;
  currency: string;
  id: string;
  name: string;
  timezone: string;
}

const BoostPayNode = (props: BoostPayNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${props.name} {${Object.keys(
          props.boostPaySummary,
        ).length.toString()}}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, boostPayStartAtNodeID])}
        name='Start At'
        value={formatDateTime(props.boostPaySummary.startAt, props.timezone)}
      />
      <SingleTextNode
        id={composeNodeID([props.id, boostPayEndAtNodeID])}
        name='End At'
        value={formatDateTime(props.boostPaySummary.endAt, props.timezone)}
      />
      <SingleTextNode
        id={composeNodeID([props.id, boostPayNameNodeID])}
        name='Name'
        value={props.boostPaySummary.name}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, boostPayAmountNodeID])}
        name='Amount'
        amount={props.boostPaySummary.amount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default BoostPayNode;
