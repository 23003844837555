import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { isFinite } from 'lodash';
import { useSharedStyles } from '../../../../utilities/Styles';
import { TripBreakdown } from '../../../../interfaces/PayHistory/TripBreakdown';
import {
  basePayBreakdownNodeID,
  basePayNodeID,
  boostPayNodeID,
  composeNodeID,
  hazardPayNodeID,
  ordersNodeID,
  returnCocPayNodeID,
  seattleEngagedPayNodeID,
  tier2DeliveryPayNodeID,
  tipsNodeID,
  tobaccoPayNodeID,
  totalAmountNodeID,
  tripEndAtNodeID,
  tripIDNodeID,
  tripLocationNameNodeID,
  tripNodePrefix,
  tripStartAtNodeID,
  tripTravelMetricsNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import { formatDateTime } from '../../../../utilities/Dates';
import OrderBreakdownNode from '../OrderBreakdown/OrderBreakdownNode';
import SinglePayoutNode from '../SinglePayoutNode';
import TripTravelMetricsNode from './TripTravelMetricsNode';
import BasePayBreakdownNode from '../OrderBreakdown/BasePayBreakdownNode';

interface TripBreakdownNodeProps {
  tripBreakdown: TripBreakdown;
  currency: string;
  index: number;
  timezone: string;
}

const TripBreakdownNode = (props: TripBreakdownNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={composeNodeID([tripNodePrefix, props.index.toString()])}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${props.index.toString()} {${Object.keys(
          props.tripBreakdown,
        ).length.toString()}}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tripIDNodeID,
        ])}
        name='Trip ID'
        value={props.tripBreakdown.tripID}
      />
      <SingleTextNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tripLocationNameNodeID,
        ])}
        name='Location Name'
        value={props.tripBreakdown.locationName}
      />
      <SingleTextNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tripStartAtNodeID,
        ])}
        name='Start At'
        value={formatDateTime(props.tripBreakdown.startAt, props.timezone)}
      />
      <SingleTextNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tripEndAtNodeID,
        ])}
        name='End At'
        value={formatDateTime(props.tripBreakdown.endAt, props.timezone)}
      />
      <TreeItem
        nodeId={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          ordersNodeID,
        ])}
        label={
          <Typography
            className={multiClass([sharedClasses.body1, classes.label])}
          >
            {`Order Breakdowns [${props.tripBreakdown.orderBreakdowns.length.toString()}]`}
          </Typography>
        }
      >
        {props.tripBreakdown.orderBreakdowns.map((orderBreakdown, i) => (
          <OrderBreakdownNode
            orderBreakdown={orderBreakdown}
            currency={props.currency}
            index={i}
            timezone={props.timezone}
            prefix={composeNodeID([
              tripNodePrefix,
              props.index.toString(),
              ordersNodeID,
              i.toString(),
            ])}
            isTripOrder
          />
        ))}
      </TreeItem>
      <TripTravelMetricsNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tripTravelMetricsNodeID,
        ])}
        tripTravelMetrics={props.tripBreakdown.tripTravelMetrics}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          basePayNodeID,
        ])}
        name='Base Pay'
        amount={props.tripBreakdown.basePay}
        currency={props.currency}
      />
      <BasePayBreakdownNode
        basePayBreakdown={props.tripBreakdown.basePayBreakdown}
        currency={props.currency}
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          basePayBreakdownNodeID,
        ])}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          boostPayNodeID,
        ])}
        name='Boost Pay'
        amount={props.tripBreakdown.boostPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          hazardPayNodeID,
        ])}
        name='Hazard Pay'
        amount={props.tripBreakdown.hazardPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tier2DeliveryPayNodeID,
        ])}
        name='Tier 2 Delivery Pay'
        amount={props.tripBreakdown.tier2DeliveryPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          tobaccoPayNodeID,
        ])}
        name='Tobacco Pay'
        amount={props.tripBreakdown.tobaccoPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          returnCocPayNodeID,
        ])}
        name='Return Pay'
        amount={props.tripBreakdown.returnCocPay}
        currency={props.currency}
      />
      {isFinite(props.tripBreakdown.seattleEngagedPay) && (
        <SinglePayoutNode
          id={composeNodeID([
            tripNodePrefix,
            props.index.toString(),
            seattleEngagedPayNodeID,
          ])}
          name='Engaged Pay'
          amount={props.tripBreakdown.seattleEngagedPay!}
          currency={props.currency}
        />
      )}
      <SinglePayoutNode
        id={composeNodeID([tripNodePrefix, props.index.toString(), tipsNodeID])}
        name='Tips'
        amount={props.tripBreakdown.tips}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([
          tripNodePrefix,
          props.index.toString(),
          totalAmountNodeID,
        ])}
        name='Total Amount'
        amount={props.tripBreakdown.totalAmount}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default TripBreakdownNode;
