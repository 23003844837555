import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { EngagedDistancePayBreakdown } from '../../../../interfaces/PayHistory/EngagedDistancePayBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  engagedDistancePaysNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import EngagedDistancePayNode from './EngagedDistancePayNode';

interface EngagedDistancePayListNodeProps {
  engagedDistancePayBreakdowns: EngagedDistancePayBreakdown[];
  currency: string;
}

const EngagedDistancePayListNode = (props: EngagedDistancePayListNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return props.engagedDistancePayBreakdowns.length > 0 ? (
    <TreeItem
      nodeId={engagedDistancePaysNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Engaged Distance Pay Breakdowns [${props.engagedDistancePayBreakdowns.length.toString()}]`}
        </Typography>
      }
    >
      {props.engagedDistancePayBreakdowns.map(
        (engagedDistancePayBreakdown, i) => (
          <EngagedDistancePayNode
            id={composeNodeID([engagedDistancePaysNodeID, i.toString()])}
            name={i.toString()}
            engagedDistancePayBreakdown={engagedDistancePayBreakdown}
            currency={props.currency}
          />
        ),
      )}
    </TreeItem>
  ) : null;
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default EngagedDistancePayListNode;
