import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { toast } from 'material-react-toastify';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  FeatureToggleInclusionListEntry,
  InclusionValueUpdateOptions,
} from '../../interfaces/FeatureToggle';
import { getDefaultInclusionValueUpdateOptions } from '../../variables/FeatureToggle';
import {
  convertLocalToDeliveryZone,
  convertToLocalZone,
} from '../../utilities/Dates';
import Waiting from '../Waiting';
import { multiClass } from '../../utilities/Extensions';
import { updateFutureFeatureToggle } from '../../services/featureToggle';
import ConfirmInclusionListModificationDialog from './ConfirmInclusionListModificationDialog';
import { ModifierDialogTabKind } from '../../interfaces/Common';

interface UpdateInclusionValueDialogProps {
  featureName: string;
  isLocationType: boolean;
  inclusionEntry: FeatureToggleInclusionListEntry | null;
  onClose: () => void;
  onDone: () => void;
}

const UpdateInclusionValueDialog = (props: UpdateInclusionValueDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [loading, setLoading] = useState(false);
  const [updateOptions, setUpdateOptions] =
    useState<InclusionValueUpdateOptions>(
      getDefaultInclusionValueUpdateOptions(),
    );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    let newUpdateOptions = getDefaultInclusionValueUpdateOptions();
    if (props.inclusionEntry) {
      let timezone = 'UTC';
      if (props.isLocationType && props.inclusionEntry.timezone) {
        timezone = props.inclusionEntry.timezone!;
      }

      const startAtLocal = DateTime.fromJSDate(
        props.inclusionEntry.startAt,
      ).setZone(timezone);

      newUpdateOptions = {
        ...newUpdateOptions,
        startAt: convertToLocalZone(
          startAtLocal,
          startAtLocal.hour,
          startAtLocal.minute,
          startAtLocal.second,
          startAtLocal.millisecond,
        ).toJSDate(),
        isLocalTime: props.isLocationType && !!props.inclusionEntry.timezone,
      };

      if (props.inclusionEntry.endAt) {
        const endAtLocal = DateTime.fromJSDate(
          props.inclusionEntry.endAt,
        ).setZone(timezone);

        newUpdateOptions = {
          ...newUpdateOptions,
          endAt: convertToLocalZone(
            endAtLocal,
            endAtLocal.hour,
            endAtLocal.minute,
            endAtLocal.second,
            endAtLocal.millisecond,
          ).toJSDate(),
          setEndAt: true,
        };
      } else {
        newUpdateOptions = {
          ...newUpdateOptions,
          endAt: DateTime.fromJSDate(newUpdateOptions.startAt)
            .plus({ hour: 1 })
            .toJSDate(),
        };
      }
    }

    setUpdateOptions(newUpdateOptions);
  }, [props.inclusionEntry]);

  const handleSetStartAt = (newStartAt: Date | null) =>
    setUpdateOptions({
      ...updateOptions,
      startAt: newStartAt ?? new Date(),
    });

  const handleSetEndAt = (newEndAt: Date | null) =>
    setUpdateOptions({
      ...updateOptions,
      endAt: newEndAt ?? new Date(),
    });

  const handleToggleEndAtSwitch = () =>
    setUpdateOptions({
      ...updateOptions,
      setEndAt: !updateOptions.setEndAt,
    });

  const handleSetTimezone = (e: any) =>
    setUpdateOptions({
      ...updateOptions,
      isLocalTime: e.target.value === 'local',
    });

  const handleUpdate = () => {
    if (!props.inclusionEntry) {
      return;
    }

    const timezone =
      !!props.inclusionEntry.timezone && updateOptions.isLocalTime
        ? props.inclusionEntry.timezone
        : 'UTC';

    const startAt = DateTime.fromJSDate(updateOptions.startAt);
    const endAt = DateTime.fromJSDate(updateOptions.endAt);

    setShowConfirmationDialog(false);
    setLoading(true);
    updateFutureFeatureToggle({
      featureName: props.featureName,
      includedValue: props.inclusionEntry.includedValue,
      currentStartAt: props.inclusionEntry.startAt,
      newStartAt: convertLocalToDeliveryZone(
        timezone,
        startAt,
        startAt.hour,
        startAt.minute,
      ).toJSDate(),
      newEndAt: updateOptions.setEndAt
        ? convertLocalToDeliveryZone(
            timezone,
            endAt,
            endAt.hour,
            endAt.minute,
          ).toJSDate()
        : undefined,
    })
      .then(() => {
        toast.success('Successfully updated the inclusion value');
        props.onDone();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={!!props.inclusionEntry} onClose={props.onClose}>
      <Waiting open={loading} />
      {props.inclusionEntry && (
        <ConfirmInclusionListModificationDialog
          open={showConfirmationDialog}
          includedValues={[props.inclusionEntry.includedValue]}
          modificationKind={ModifierDialogTabKind.UPDATE}
          onClose={() => setShowConfirmationDialog(false)}
          onConfirm={handleUpdate}
        />
      )}
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        {`Update Inclusion Value ${props.inclusionEntry?.includedValue ?? ''}`}
      </DialogTitle>
      <DialogContent>
        <Box component='div' className={classes.body}>
          <Box component='div' className={classes.dateTimePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                onChange={handleSetStartAt}
                value={updateOptions.startAt}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => <TextField {...params} size='small' label='Start At' />}
              />
            </LocalizationProvider>
          </Box>
          <Box component='div' className={classes.row}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                onChange={handleSetEndAt}
                value={updateOptions.endAt}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => <TextField {...params} size='small' label='End At' />}
                disabled={!updateOptions.setEndAt}
              />
            </LocalizationProvider>
            <Switch
              checked={updateOptions.setEndAt}
              onChange={handleToggleEndAtSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
              color={'success'}
            />
          </Box>
          <FormControl
            component='fieldset'
            className={classes.timezoneRadioGroup}
          >
            <FormLabel>
              <Typography className={sharedClasses.caption}>
                Timezone
              </Typography>
            </FormLabel>
            <RadioGroup
              name='timezone-radio-group'
              value={updateOptions.isLocalTime ? 'local' : 'utc'}
              onChange={handleSetTimezone}
            >
              <FormControlLabel
                key='utc'
                value='utc'
                control={<Radio />}
                label={
                  <Typography className={sharedClasses.body1}>UTC</Typography>
                }
              />
              {props.isLocationType && !!props.inclusionEntry?.timezone && (
                <FormControlLabel
                  key='local'
                  value='local'
                  control={<Radio />}
                  label={
                    <Typography className={sharedClasses.body1}>
                      MFC Local
                    </Typography>
                  }
                />
              )}
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box component='div' className={classes.dialogActionsSpacer} />
        <Button
          variant='contained'
          color={'inherit'}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setShowConfirmationDialog(true)}
          className={sharedClasses.buttonText}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  tabLabel: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  textField: {
    marginTop: theme.spacing(1.5),
  },
  body: {
    marginTop: theme.spacing(1.5),
  },
  dateTimePicker: {
    marginBottom: theme.spacing(1.5),
  },
  row: {
    marginTop: theme.spacing(1.5),
    flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  endAtSwitch: {
    marginLeft: theme.spacing(1.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  timezoneRadioGroup: {
    marginTop: theme.spacing(1.5),
  },
}));

export default UpdateInclusionValueDialog;
