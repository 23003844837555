export enum UserType {
  ADMINISTRATOR = 'administrator',
  ADD_ADJUSTMENTS = 'addAdjustments',
  ADJUSTMENT_ADMIN = 'adjustmentAdmin',
  ADJUSTMENT_MANAGER = 'adjustmentManager',
  SET_BASE_PAY = 'setBasePay',
  SET_SPECIAL_PAY = 'setSpecialPay',
  STRIPE_MANAGEMENT = 'stripeManagement',
  EXPORT_PAYOUT = 'exportPayout',
  SET_BOOST_PAY = 'setBoostPay',
  HAS_DPP_TIME_RATES_ACCESS = 'hasDppTimeRatesAccess',
  SET_DPP_RULE = 'setDppRule',
  SET_TARGET_COMMISSION = 'setTargetCommission',
}

export enum Permission {
  ALL = ~0,
  SET_BASE_RATE = 1 << 0,
  SET_WEEKLY_BONUSES = 1 << 1,
  SET_SPECIAL_EVENTS = 1 << 2,
  SET_ADJUSTMENTS = 1 << 3,
  SET_LIMITS = 1 << 4,
  RESTRICT_ACCOUNTS = 1 << 5,
  EXPORT_PAYOUT = 1 << 6,
  SET_ADJUSTMENT_TYPES = 1 << 7,
  SET_ADJUSTMENT_APPROVALS = 1 << 8,
  UPLOAD_BULK_ADJUSTMENTS = 1 << 9,
  SET_BOOST_PAY = 1 << 10,
  HAS_DPP_TIME_RATES_ACCESS = 1 << 11,
  SET_DPP_RULE = 1 << 12,
  SET_MISSIONS = 1 << 13,
  SET_TARGET_COMMISSION = 1 << 14,
  SEND_PUSH_NOTIFICATIONS = 1 << 15,
}
