import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';
import {
  getCurrentWorkweek,
  parseWorkweekISOString,
  previousWorkweekOf,
  workweekISOString,
} from '../../utilities/Workweek';
import {
  DriverSupportSummary,
  WorkweekToSummaryMap,
} from '../../interfaces/PayHistory/DriverSupportSummary';
import Waiting from '../Waiting';
import { getDriverSupportSummary } from '../../services/driverSupportSummary';
import DriverSupportInfo from './DriverSupportInfo';
import WeeklyPayoutAccordion from './WeeklyPayoutAccordion';
import { payHistoryPageUrl } from '../../variables/Pages';
import { resetStripeAccount } from '../../services/driverAccount';
import { ConfirmDialog } from '../NewPayRateDialog/ConfirmDialog';

export const payHistoryContainerTestId = 'payHistoryContainer';

// Release date of pay v2
const minimumWorkweek = '2022-03-28';
const workweekCountForOnePage = 10;

const PayHistory = () => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [summaryMap, setSummaryMap] = useState<WorkweekToSummaryMap>({});
  const [showConfirmResetDialog, setShowConfirmResetDialog] = useState(false);
  const [currentWorkweekSummary, setCurrentWorkweekSummary] =
    useState<DriverSupportSummary | null>(null);

  const { submittedValue } = useParams<{ submittedValue?: string }>();

  const currentWorkweek = workweekISOString(getCurrentWorkweek());

  const searchDriverSummary = () => {
    setSummaryMap({});
    setCurrentWorkweekSummary(null);
    if (!submittedValue) {
      return;
    }
    setLoading(true);
    getDriverSupportSummary(submittedValue, currentWorkweek)
      .then((summary) => {
        const newSummaryMap: WorkweekToSummaryMap = {
          [currentWorkweek]: summary,
        };
        let ww = previousWorkweekOf(parseWorkweekISOString(currentWorkweek));
        for (let i = 0; i < workweekCountForOnePage - 1; i += 1) {
          newSummaryMap[workweekISOString(ww)] = null;
          ww = previousWorkweekOf(ww);
        }
        setSummaryMap(newSummaryMap);
        setCurrentWorkweekSummary(summary);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    searchDriverSummary();
    setSearchedValue(submittedValue ?? '');
  }, [submittedValue]);

  const handleSubmit = () => {
    if (navigate) {
      navigate(
        searchedValue === ''
          ? payHistoryPageUrl
          : `${payHistoryPageUrl}/${searchedValue}`,
      );
    }
  };

  const handleTextFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChangeSearchedValue = (e: any) => {
    setSearchedValue(e.target.value);
  };

  const handleClickAccordion = (workweek: string) => {
    if (summaryMap[workweek]) {
      const newSummaryMap = { ...summaryMap };
      newSummaryMap[workweek] = null;
      setSummaryMap(newSummaryMap);
    } else {
      setLoading(true);
      getDriverSupportSummary(currentWorkweekSummary!.driverId, workweek)
        .then((summary) => {
          const newSummaryMap = { ...summaryMap };
          newSummaryMap[workweek] = summary;
          setSummaryMap(newSummaryMap);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    }
  };

  const handleLoadMore = () => {
    const lastWorkweekAsStr =
      _.last(Object.keys(summaryMap)) ?? currentWorkweek;
    let ww = previousWorkweekOf(parseWorkweekISOString(lastWorkweekAsStr));
    const newSummaryMap = { ...summaryMap };
    for (let i = 0; i < workweekCountForOnePage; i += 1) {
      const wwAsStr = workweekISOString(ww);
      if (wwAsStr < minimumWorkweek) {
        break;
      }
      newSummaryMap[wwAsStr] = null;
      ww = previousWorkweekOf(ww);
    }
    setSummaryMap(newSummaryMap);
  };

  const handleResetStripe = () => {
    if (currentWorkweekSummary?.stripeAccountID) {
      setLoading(true);
      setShowConfirmResetDialog(false);
      resetStripeAccount(currentWorkweekSummary.stripeAccountID)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Box component='div' data-testid={payHistoryContainerTestId}>
      <Waiting open={loading} />
      <ConfirmDialog
        title='Are you sure?'
        content='If you proceed, Stripe account of the DP will be reset.'
        okText='Reset'
        cancelText='Cancel'
        open={showConfirmResetDialog}
        onClose={() => setShowConfirmResetDialog(false)}
        onConfirm={handleResetStripe}
        color='error'
      />
      <Paper className={classes.header}>
        <Box component='div' className={classes.textContainer}>
          <Typography className={multiClass([sharedClasses.h2, classes.title])}>
            Pay History
          </Typography>
          <Typography
            className={multiClass([
              sharedClasses.subtitle1,
              classes.description,
            ])}
          >
            Review a delivery partner’s pay history by inputting their user ID
            or email address
            <br />
            in the search field.
          </Typography>
        </Box>
        <Box className={classes.spacer} />
        <TextField
          variant='outlined'
          size='small'
          label='Search by ID or Email'
          onChange={handleChangeSearchedValue}
          onKeyDown={handleTextFieldKeyDown}
          value={searchedValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon onClick={handleSubmit} />
              </InputAdornment>
            ),
          }}
          className={classes.textField}
        />
      </Paper>
      {currentWorkweekSummary && (
        <DriverSupportInfo
          driverSupportSummary={currentWorkweekSummary!}
          onResetStripe={() => setShowConfirmResetDialog(true)}
        />
      )}
      {currentWorkweekSummary && [
        ...Object.keys(summaryMap).map((workweek) => (
          <WeeklyPayoutAccordion
            workweek={workweek}
            driverSupportSummary={summaryMap[workweek]}
            onClick={() => handleClickAccordion(workweek)}
          />
        )),
        !Object.keys(summaryMap).includes(minimumWorkweek) && (
          <Box component='div' className={classes.loadMoreWrapper}>
            <Button onClick={handleLoadMore} className={classes.loadMoreButton}>
              Load more
            </Button>
          </Box>
        ),
      ]}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingBottom: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1.5),
  },
  description: {
    fontWeight: '400',
    fontSize: 14,
  },
  note: {
    color: sharedColors.gray5,
    marginTop: theme.spacing(1.5),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  textField: {
    backgroundColor: sharedColors.white,
    width: 360,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  loadMoreWrapper: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'center',
  },
  loadMoreButton: {
    textTransform: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default PayHistory;
