export interface Audit {
  createdAt: Date;
  createdBy: string;
  lastUpdatedAt?: Date;
  lastUpdatedBy?: string;
}

export const auditFromResponse = (responseData: any): Audit => ({
  createdAt: new Date(responseData.created_at),
  createdBy: responseData.created_by,
  lastUpdatedAt: responseData.last_updated_at
    ? new Date(responseData.last_updated_at)
    : undefined,
  lastUpdatedBy: responseData.last_updated_by,
});
