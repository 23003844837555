import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { cloneDeep, isEmpty } from 'lodash';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { locations as locationsAtom } from '../../../atoms/Location';
import {
  filterLocationById,
  locationIdToName,
} from '../../../utilities/Locations';
import { easternTimezone, formatDateTime } from '../../../utilities/Dates';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';
import Waiting from '../../Waiting';
import { PayRateStatus } from '../../../interfaces/PayRate';
import EditTripFareLimitsDialog from '../../TripFareLimits/EditTripFareLimitsDialog';
import {
  TripFareConstants,
  TripFareLimits,
} from '../../../interfaces/TripFareLimits';
import {
  getTripFareConstants,
  getTripFareLimits,
  updateTripFareLimits,
} from '../../../services/tripFareLimits';
import { payRateStatusFromTimeRange } from '../../../utilities/PayRates';
import StatusField from '../../StatusField';
import StringField from '../../StringField';

interface TripFareLimitsTableProps {
  selectedLocationIDs: string[];
  getAllActive: boolean;
  setTripFareLimitsExists: (tripFareLimitsExists: boolean) => void;
}

const TripFareLimitsTable = (props: TripFareLimitsTableProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [tripFareConstants, setTripFareConstants] = useState<
    TripFareConstants | undefined
  >(undefined);
  const [tripFareLimits, setTripFareLimits] = useState([] as TripFareLimits[]);
  const [tripFareLimitsToEdit, setTripFareLimitsToEdit] = useState<
    TripFareLimits | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const refreshTripFareLimits = () => {
    setPage(0);

    if (!props.getAllActive && isEmpty(props.selectedLocationIDs)) {
      setTripFareLimits([]);
      props.setTripFareLimitsExists(false);
      return;
    }

    getTripFareLimits(props.getAllActive ? [] : props.selectedLocationIDs)
      .then((fetchedTripFareLimits) => {
        setTripFareLimits(fetchedTripFareLimits);
        props.setTripFareLimitsExists(!isEmpty(fetchedTripFareLimits));
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getTripFareConstants()
      .then((constants) => setTripFareConstants(constants))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    refreshTripFareLimits();
  }, [props.getAllActive, props.selectedLocationIDs]);

  const handleEditTripFareLimits = (newFareLimits: TripFareLimits) => {
    const showSuccessMessage = () => {
      if (tripFareLimitsToEdit) {
        const locationName = locationIdToName(
          locations,
          parseInt(tripFareLimitsToEdit.locationID, 10),
        );
        toast.success(
          `Successfully updated fare limits for ${locationName}. \nNote: It may take up to 10 mins for changes to go into effect.`,
        );
      } else {
        toast.success(
          `Successfully updated fare limits. \nNote: It may take up to 10 mins for changes to go into effect.`,
        );
      }
    };

    setUpdating(true);
    updateTripFareLimits(newFareLimits)
      .then(() => {
        showSuccessMessage();
        refreshTripFareLimits();
        setTripFareLimitsToEdit(undefined);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setUpdating(false));
  };

  return isEmpty(tripFareLimits) ? (
    <Waiting open={loading || updating} />
  ) : (
    <Box component='div' className={classes.outerContainer}>
      {!!tripFareConstants && (
        <EditTripFareLimitsDialog
          tripFareLimitsToEdit={tripFareLimitsToEdit}
          tripFareConstants={tripFareConstants}
          onClose={() => setTripFareLimitsToEdit(undefined)}
          onEdit={handleEditTripFareLimits}
          loading={loading || updating}
        />
      )}
      <Waiting open={loading || updating} />
      <Typography className={sharedClasses.h5}>
        Trip Fare Limits (DPP)
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell key='location-id'>
                <Typography className={classes.columnLabel}>
                  Location ID
                </Typography>
              </TableCell>
              <TableCell key='start-at'>
                <Typography className={classes.columnLabel}>
                  Start At
                </Typography>
              </TableCell>
              <TableCell key='end-at'>
                <Typography className={classes.columnLabel}>End At</Typography>
              </TableCell>
              <TableCell key='first-order-min-fare'>
                <Typography className={classes.columnLabel}>
                  Min fare/first order
                </Typography>
              </TableCell>
              <TableCell key='non-first-order-min-fare'>
                <Typography className={classes.columnLabel}>
                  Min fare/subsequent
                </Typography>
              </TableCell>
              <TableCell key='first-order-max-fare'>
                <Typography className={classes.columnLabel}>
                  Max fare/first order
                </Typography>
              </TableCell>
              <TableCell key='non-first-order-max-fare'>
                <Typography className={classes.columnLabel}>
                  Max fare/subsequent
                </Typography>
              </TableCell>
              <TableCell key='last-updated-by'>
                <Typography className={classes.columnLabel}>
                  Last Updated By
                </Typography>
              </TableCell>
              <TableCell key='status'>
                <Typography className={classes.columnLabel}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tripFareLimits
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row) => {
                const location = filterLocationById(
                  locations,
                  parseInt(row.locationID, 10),
                );

                const timezone = location?.timeZone ?? easternTimezone;

                const status = payRateStatusFromTimeRange(
                  row.startAt,
                  row.endAt,
                );
                const isEditable = status === PayRateStatus.ACTIVE;
                const isDisabled = status === PayRateStatus.EXPIRED;

                return (
                  <TableRow
                    key={row.id}
                    onClick={() =>
                      isEditable
                        ? setTripFareLimitsToEdit(cloneDeep(row))
                        : undefined
                    }
                    className={isEditable ? classes.editableRow : undefined}
                  >
                    <TableCell key={`${row.id}-location-id`}>
                      <StringField
                        value={location?.name ?? '-'}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-start-at`}>
                      <StringField
                        value={formatDateTime(row.startAt, timezone)}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-end-at`}>
                      <StringField
                        value={
                          row.endAt ? formatDateTime(row.endAt, timezone) : '-'
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-first-order-min-fare`}>
                      <StringField
                        value={parseCentsAsCurrencyString(
                          row.firstOrderMinFare,
                          location?.currency,
                        )}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-non-first-order-min-fare`}>
                      <StringField
                        value={parseCentsAsCurrencyString(
                          row.subsequentOrderMinFare,
                          location?.currency,
                        )}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-first-order-max-fare`}>
                      <StringField
                        value={parseCentsAsCurrencyString(
                          row.firstOrderMaxFare,
                          location?.currency,
                        )}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-non-first-order-max-fare`}>
                      <StringField
                        value={parseCentsAsCurrencyString(
                          row.subsequentOrderMaxFare,
                          location?.currency,
                        )}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-last-updated-by`}>
                      <StringField
                        value={row.lastUpdatedBy}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell key={`${row.id}-status`}>
                      <StatusField status={status} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          count={tripFareLimits.length}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[4, 6, 8, 10]}
          component={'div'}
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(6),
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  stringField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    flexDirection: 'row',
    display: 'flex',
  },
  editableRow: {
    cursor: 'pointer',
  },
}));

export default TripFareLimitsTable;
