import React from 'react';
import { cloneDeep } from 'lodash';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  MissionMetrics,
  MissionMetricType,
} from '../../../../interfaces/Mission/MissionMetric';
import { possibleMetricTypes } from '../../../../variables/Mission';
import { sharedColors, useSharedStyles } from '../../../../utilities/Styles';
import { NumberWithOperator } from '../../../../interfaces/NumberWithOperator';
import NumberWithOperatorInput from '../../NewSegment/NumberWithOperatorInput';

interface CompletionCriteriaFormProps {
  metrics: MissionMetrics;
  onChange: (newMetrics: MissionMetrics) => void;
  disabled: boolean;
}

const CompletionCriteriaForm = (props: CompletionCriteriaFormProps) => {
  const classes = useStyles();
  const numberWithOperatorClasses = useNumberWithOperatorStyles();
  const sharedClasses = useSharedStyles().classes;

  const getMetricName = (metricType: MissionMetricType): string => {
    switch (metricType) {
      case MissionMetricType.ACCEPTANCE:
        return 'Acceptance Rate';
      case MissionMetricType.COMPLETION:
        return 'Completion Rate';
      case MissionMetricType.DID_NOT_RECEIVE:
        return 'DNR Rate';
      case MissionMetricType.ON_TIME:
        return 'On Time Rate';
      default:
        return metricType;
    }
  };

  const getMetricOperator = (metricType: MissionMetricType): string => {
    switch (metricType) {
      case MissionMetricType.DID_NOT_RECEIVE:
        return '<=';
      default:
        return '>=';
    }
  };

  const handleToggleMetric = (metricType: MissionMetricType) => {
    if (props.metrics[metricType]) {
      const newMetrics = cloneDeep(props.metrics);
      delete newMetrics[metricType];

      props.onChange(newMetrics);
    } else {
      props.onChange({
        ...props.metrics,
        [metricType]: {
          amount: 70,
          operator: getMetricOperator(metricType),
        },
      });
    }
  };

  const handleChangeMetricValue = (
    metricType: MissionMetricType,
    newValue: NumberWithOperator,
  ) => {
    props.onChange({
      ...props.metrics,
      [metricType]: newValue,
    });
  };

  return (
    <Box component='div' className={classes.wrappedRow}>
      {possibleMetricTypes.map((metricType) => {
        const isPresent = !!props.metrics[metricType];

        return (
          <Box component='div' className={classes.metricColumn}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={props.disabled}
                  checked={isPresent}
                  onChange={() => handleToggleMetric(metricType)}
                />
              }
              label={
                <Typography className={sharedClasses.subtitle2}>
                  {getMetricName(metricType)}
                </Typography>
              }
            />
            {isPresent && (
              <NumberWithOperatorInput
                numberWithOperator={props.metrics[metricType]!}
                onChange={(newValue) =>
                  handleChangeMetricValue(metricType, newValue)
                }
                label=''
                endAdornment='%'
                isOperatorSelectionDisabled
                classes={numberWithOperatorClasses}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    wrappedRow: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    metricColumn: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(1.5),
    },
  });
});

const useNumberWithOperatorStyles = makeStyles((theme) =>
  createStyles({
    body: {
      backgroundColor: sharedColors.gray1,
    },
    dropdown: {
      width: 75,
      backgroundColor: sharedColors.white,
      marginRight: theme.spacing(1.5),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    deleteButton: {
      marginLeft: theme.spacing(1.5),
    },
    numberInput: {
      width: 100,
    },
  }),
);

export default CompletionCriteriaForm;
