import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { MissionRewardTierSummary } from '../../../../interfaces/PayHistory/MissionRewardTierSummary';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  missionRewardSummaryTierCountNodeID,
  missionRewardSummaryTierPayNodeID,
} from '../../../../variables/PayHistory';
import SinglePayoutNode from '../SinglePayoutNode';

interface MissionRewardTierSummaryNodeProps {
  id: string;
  name: string;
  missionRewardTierSummary: MissionRewardTierSummary;
  currency: string;
}

const MissionRewardTierSummaryNode = (
  props: MissionRewardTierSummaryNodeProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.name} {${Object.keys(
            props.missionRewardTierSummary,
          ).length.toString()}}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, missionRewardSummaryTierCountNodeID])}
        name='Count'
        value={props.missionRewardTierSummary.count.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, missionRewardSummaryTierPayNodeID])}
        name='Pay'
        amount={props.missionRewardTierSummary.pay}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MissionRewardTierSummaryNode;
