import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { toast } from 'material-react-toastify';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { HolidayAccrualStats, HolidayType } from '../../interfaces/HolidayPay';
import {
  TablePaginationOptions,
  TablePaginationResponse,
} from '../../interfaces/Common';
import { multiClass } from '../../utilities/Extensions';
import StringField from '../StringField';
import HolidayTypeChip from './HolidayTypeChip';
import HolidayRequestDialog from './HolidayRequestDialog';
import Waiting from '../Waiting';
import {
  getHolidayAccrualHistory,
  getHolidayRateHistory,
} from '../../services/holidayPay';
import { parseCSVFile } from '../../utilities/Csv';
import { formatCsvLocalTime, formatLocalTime } from '../../utilities/Dates';
import { AccrualEvent } from '../../interfaces/HolidayAccrualHistory';

interface HolidayAccrualStatsTableProps {
  holidayAccrualStatsList: HolidayAccrualStats[];
  pagination: TablePaginationResponse;
  onPagination: (newPagination: TablePaginationOptions) => void;
  onRequestPayment: () => void;
}

const HolidayAccrualStatsTable = (props: HolidayAccrualStatsTableProps) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [clickedDriverID, setClickedDriverID] = useState<string | undefined>(
    undefined,
  );
  const [driverIDToRequest, setDriverIDToRequest] = useState<
    string | undefined
  >(undefined);
  const [availableDays, setAvailableDays] = useState(0);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  const handleClickRequestPayment = () => {
    setDriverIDToRequest(clickedDriverID);
  };

  const handleExportAccruals = () => {
    if (!clickedDriverID) {
      return;
    }
    setLoading(true);
    setPopoverAnchor(null);
    getHolidayAccrualHistory(HolidayType.PSST, clickedDriverID)
      .then((histories) => {
        const url = parseCSVFile(
          [
            {
              label: 'Event Type',
              value: 'eventType',
            },
            {
              label: 'Event Time',
              value: 'eventTime',
            },
            {
              label: 'Accrued Days',
              value: 'accruedDays',
            },
            {
              label: 'Accrued Days (Before)',
              value: 'accruedDaysBefore',
            },
            {
              label: 'Accrued Days (After)',
              value: 'accruedDaysAfter',
            },
            {
              label: 'Description',
              value: 'description',
            },
          ],
          histories.map((history) => {
            let description = '';
            switch (history.eventType) {
              case AccrualEvent.EARNED: {
                description = `DP earned ${history.accruedDays.toFixed(
                  3,
                )} accrual for date "${history.accrualWorkday}"`;
                break;
              }
              case AccrualEvent.USED: {
                description = `DP used ${Math.abs(history.accruedDays).toFixed(
                  3,
                )} days of accrual and was paid ${getSymbolFromCurrency(
                  history.currency ?? 'USD',
                )}${((history.amountPaid ?? 0) / 100).toFixed(2)}`;
                break;
              }
              case AccrualEvent.EXPIRED: {
                description = `${Math.abs(history.accruedDays).toFixed(
                  3,
                )} days of accrual is expired`;
                break;
              }
              default:
                break;
            }

            return {
              eventType:
                history.eventType.slice(0, 1).toUpperCase() +
                history.eventType.slice(1),
              eventTime: formatLocalTime(history.eventTime),
              accruedDays: history.accruedDays.toFixed(3),
              accruedDaysBefore: history.accruedDaysBefore.toFixed(3),
              accruedDaysAfter: history.accruedDaysAfter.toFixed(3),
              description,
            };
          }),
        );

        const link = document.createElement('a');
        link.download = `holiday_accrual_history_${clickedDriverID}`;
        link.href = url;
        link.click();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setClickedDriverID(undefined);
        setLoading(false);
      });
  };

  const handleExportRates = () => {
    if (!clickedDriverID) {
      return;
    }
    setLoading(true);
    setPopoverAnchor(null);
    getHolidayRateHistory(HolidayType.PSST, clickedDriverID)
      .then((history) => {
        const currencySymbol = getSymbolFromCurrency(history.currency);

        const csvCells = [
          ['Rate History'],
          [],
          ['Start', 'End', 'Rate'],
          ...history.holidayRates.map((holidayRate) => [
            formatCsvLocalTime(holidayRate.startAt),
            holidayRate.endAt ? formatCsvLocalTime(holidayRate.endAt) : '',
            currencySymbol + (holidayRate.rate / 100).toFixed(2),
          ]),
          [],
          ['Active Rate Calculation Details'],
          [],
          ['Date, Earning'],
          ...history.activeRateCalculationDetails.psstDailyEarnings.map(
            (earnings) => [
              earnings.date,
              currencySymbol + (earnings.earning / 100).toFixed(2),
            ],
          ),
          ['Day Count', 'Amount Sum', 'Rate'],
          [
            history.activeRateCalculationDetails.dayCount,
            currencySymbol +
              (history.activeRateCalculationDetails.amountSum / 100).toFixed(2),
            currencySymbol +
              (history.activeRateCalculationDetails.rate / 100).toFixed(2),
          ],
        ];

        const csvContent = csvCells.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `psst_rate_history_${clickedDriverID}.csv`);
        a.click();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setClickedDriverID(undefined);
        setLoading(false);
      });
  };

  const handleClickActions = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: HolidayAccrualStats,
  ) => {
    setPopoverAnchor(event.currentTarget);
    setClickedDriverID(row.driverID);
    setAvailableDays(row.accruedDays);
  };

  const handleCloseRequestDialog = () => {
    setClickedDriverID(undefined);
    setDriverIDToRequest(undefined);
    setPopoverAnchor(null);
    setAvailableDays(0);
  };

  const handleRequestPayment = () => {
    toast.success('Successfully requested holiday payment');
    handleCloseRequestDialog();
    props.onRequestPayment();
  };

  return (
    <Box component='div' className={classes.outerContainer}>
      <HolidayRequestDialog
        driverID={driverIDToRequest}
        accruedDays={availableDays}
        onCreate={handleRequestPayment}
        onClose={handleCloseRequestDialog}
      />
      <Waiting open={loading} />
      <Typography className={multiClass([sharedClasses.h5, classes.title])}>
        Holiday Accruals
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>DP ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Holiday Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Accrued Days
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>Rate</Typography>
              </TableCell>
              <TableCell key='action' />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.holidayAccrualStatsList.map((stats, i) => (
              <TableRow key={i}>
                <TableCell>
                  <StringField value={stats.driverID} />
                </TableCell>
                <TableCell>
                  <HolidayTypeChip type={stats.holidayType} />
                </TableCell>
                <TableCell>
                  <StringField value={stats.accruedDays.toFixed(2)} />
                </TableCell>
                <TableCell>
                  <StringField
                    value={
                      stats.rate
                        ? getSymbolFromCurrency(stats.currency) +
                          (stats.rate / 100).toFixed(2)
                        : '-'
                    }
                  />
                </TableCell>
                <TableCell align='right'>
                  <IconButton onClick={(e) => handleClickActions(e, stats)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={props.pagination.totalCount}
          page={props.pagination.page - 1}
          onPageChange={(_, newPage) =>
            props.onPagination({ ...props.pagination, page: newPage + 1 })
          }
          rowsPerPage={props.pagination.rowsPerPage}
          onRowsPerPageChange={(e) =>
            props.onPagination({
              page: 1,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
        />
      </TableContainer>
      <Popover
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popoverContainer}>
          <Button
            color='primary'
            disabled={availableDays < 1}
            startIcon={<AddIcon />}
            onClick={handleClickRequestPayment}
            className={multiClass([
              sharedClasses.buttonText,
              classes.popoverButton,
            ])}
          >
            Request Payment
          </Button>
          <Button
            color='primary'
            startIcon={<DownloadIcon />}
            onClick={handleExportAccruals}
            className={multiClass([
              sharedClasses.buttonText,
              classes.popoverButton,
            ])}
          >
            Export Accrual History
          </Button>
          <Button
            color='primary'
            startIcon={<DownloadIcon />}
            onClick={handleExportRates}
            className={multiClass([
              sharedClasses.buttonText,
              classes.popoverButton,
            ])}
          >
            Export Rate History
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(5),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    popoverContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    popoverButton: {
      padding: theme.spacing(1.5),
      textTransform: 'none',
      justifyContent: 'flex-start',
    },
  }),
);

export default HolidayAccrualStatsTable;
