import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { multiClass } from '../../utilities/Extensions';

interface KeyStatsProps {
  name: string;
  value: string;
  onHover: () => void;
}

const KeyStats = (props: KeyStatsProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <Box
      component='div'
      onMouseEnter={props.onHover}
      className={classes.mainContainer}
    >
      <Typography className={classes.value}>{props.value}</Typography>
      <Typography className={multiClass([sharedClasses.body2, classes.name])}>
        {props.name}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    textAlign: 'center',
  },
  value: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '21px',
    lineHeight: '25px',
    color: sharedColors.gray6,
  },
  name: {
    marginTop: theme.spacing(-0.25),
    color: sharedColors.gray5,
  },
}));

export default KeyStats;
