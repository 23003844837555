import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { MinGuaranteeAdjustmentBreakdown } from '../../../../interfaces/PayHistory/MinGuaranteeAdjustmentBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  minGuaranteeAdjustmentNodeID,
  minGuaranteeAdjustmentsNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import MinGuaranteeAdjustmentNode from './MinGuaranteeAdjustmentNode';

interface MinGuaranteeAdjustmentListNodeProps {
  minGuaranteeAdjustments: MinGuaranteeAdjustmentBreakdown[];
  currency: string;
  timezone: string;
}

const MinGuaranteeAdjustmentListNode = (
  props: MinGuaranteeAdjustmentListNodeProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={minGuaranteeAdjustmentsNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Minimum Guarantee Adjustment Breakdowns [${props.minGuaranteeAdjustments.length.toString()}]`}
        </Typography>
      }
    >
      {props.minGuaranteeAdjustments.map((minGuaranteeAdjustment, i) => (
        <MinGuaranteeAdjustmentNode
          id={composeNodeID([minGuaranteeAdjustmentNodeID, i.toString()])}
          index={i}
          minGuaranteeAdjustment={minGuaranteeAdjustment}
          currency={props.currency}
          timezone={props.timezone}
        />
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default MinGuaranteeAdjustmentListNode;
