import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { ReimbursementPayBreakdown } from '../../../../interfaces/PayHistory/ReimbursementPayBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  reimbursementPaysNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import SinglePayoutNode from '../SinglePayoutNode';

interface ReimbursementPaysListNodeProps {
  reimbursementPayBreakdowns: ReimbursementPayBreakdown[];
  currency: string;
}

const ReimbursementPaysListNode = (props: ReimbursementPaysListNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={reimbursementPaysNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`Reimbursement Pay Breakdowns [${props.reimbursementPayBreakdowns.length.toString()}]`}
        </Typography>
      }
    >
      {props.reimbursementPayBreakdowns.map((breakdown, i) => (
        <SinglePayoutNode
          id={composeNodeID([reimbursementPaysNodeID, i.toString()])}
          name={breakdown.name}
          amount={breakdown.amount}
          currency={props.currency}
        />
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default ReimbursementPaysListNode;
