import { DateTime } from 'luxon';
import { tablePaginationFromResponse, TablePaginationResponse } from './Common';

export enum HolidayType {
  PSST = 1,
}

export interface HolidayAccrualStats {
  driverID: string;
  holidayType: HolidayType;
  accruedDays: number;
  rate?: number;
  currency: string;
}

export const holidayAccrualStatsFromResponse = (
  responseData: any,
): HolidayAccrualStats => ({
  driverID: responseData.driver_id,
  holidayType: responseData.holiday_type_id,
  accruedDays: responseData.accrued_days,
  rate: responseData.rate,
  currency: responseData.currency,
});

export interface HolidayAccrualStatsResponse {
  data: HolidayAccrualStats[];
  pagination: TablePaginationResponse;
}

export const holidayAccrualStatsResponseFromResponse = (
  responseData: any,
): HolidayAccrualStatsResponse => ({
  data: (responseData.data ?? []).map((stats: any) =>
    holidayAccrualStatsFromResponse(stats),
  ),
  pagination: tablePaginationFromResponse(responseData.pagination ?? {}),
});

export interface HolidayRequest {
  driverID: string;
  holidayType: HolidayType;
  createdAt: Date;
  holidayStart: Date;
  holidayEnd: Date;
  accruedDays: number;
  rate: number;
  amountPaid: number;
  currency: string;
}

export const holidayRequestFromResponse = (
  responseData: any,
): HolidayRequest => ({
  driverID: responseData.driver_id,
  holidayType: responseData.holiday_type_id,
  createdAt: new Date(responseData.created_at),
  holidayStart: new Date(responseData.holiday_start),
  holidayEnd: new Date(responseData.holiday_end),
  accruedDays: responseData.accrued_days,
  rate: responseData.rate,
  amountPaid: responseData.amount_paid,
  currency: responseData.currency ?? 'USD',
});

export interface HolidayRequestsResponse {
  data: HolidayRequest[];
  pagination: TablePaginationResponse;
}

export const holidayRequestsResponseFromResponse = (
  responseData: any,
): HolidayRequestsResponse => ({
  data: (responseData.data ?? []).map((requests: any) =>
    holidayRequestFromResponse(requests),
  ),
  pagination: tablePaginationFromResponse(responseData.pagination ?? {}),
});

export interface HolidayRequestToCreate {
  driverID: string;
  holidayType: HolidayType;
  accruedDaysToGetPaid: number;
  startDate: Date;
}

export const holidayRequestToCreateOutputTransformer = (
  holidayRequestToCreate: HolidayRequestToCreate,
) => ({
  driver_id: holidayRequestToCreate.driverID,
  holiday_type_id: holidayRequestToCreate.holidayType,
  accrued_days_to_get_paid: holidayRequestToCreate.accruedDaysToGetPaid,
  start_date: DateTime.fromJSDate(holidayRequestToCreate.startDate).toFormat(
    'yyyy-MM-dd',
  ),
});
