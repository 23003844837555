import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import Waiting from '../Waiting';
import UploadCSV from '../UploadCSV';
import { BulkErrorDialog } from '../BulkErrorDialog';
import { targetCommissionCSVUpload } from '../../services/targetCommissions';
import { multiClass } from '../../utilities/Extensions';
import { downloadCsvTemplate } from '../../utilities/Csv';
import { targetCommissionCsvTemplate } from '../../variables/CsvTemplate';

interface TargetCommissionCSVUploadDialogProps {
  open: boolean;
  onClose: () => void;
}

const TargetCommissionCSVUploadDialog = (
  props: TargetCommissionCSVUploadDialogProps,
) => {
  const { classes } = useStyles();

  const [bulkErrorMsg, setBulkErrorMsg] = useState<string | undefined>(
    undefined,
  );

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    setBulkErrorMsg(undefined);
  }, [file]);

  const sharedClasses = useSharedStyles().classes;

  const uploadFile = () => {
    if (file === undefined) {
      return;
    }

    setLoading(true);

    targetCommissionCSVUpload(file)
      .then(() => {
        toast.success(`Target commissions csv upload successful!`);
        props.onClose();
      })
      .catch((err) => {
        toast.error('Target commissions csv upload failed.');
        setBulkErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <Waiting open={loading} />
      <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
        Upload Time Rates
      </DialogTitle>
      <Box component='div'>
        <UploadCSV setParentFile={setFile} />
        <BulkErrorDialog
          open={bulkErrorMsg !== undefined}
          message={bulkErrorMsg || ''}
          onClose={() => setBulkErrorMsg(undefined)}
        />
      </Box>
      <DialogActions className={classes.dialogActions}>
        <Box component='div' className={classes.dialogActionsSpacer}>
          <Button
            onClick={() => downloadCsvTemplate(targetCommissionCsvTemplate)}
            className={sharedClasses.buttonText}
          >
            Download CSV Template for Target Commissions
          </Button>
        </Box>
        <Button
          variant='contained'
          color={'inherit'}
          onClick={props.onClose}
          className={sharedClasses.buttonText}
        >
          Cancel
        </Button>
        <Button
          onClick={uploadFile}
          variant='contained'
          color='primary'
          type='submit'
          className={sharedClasses.buttonText}
          disabled={file === undefined}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  tabsContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    background: sharedColors.gray1,
  },
  tabLabel: {
    textTransform: 'none',
    fontWeight: 500,
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
  dialogActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
}));

export default TargetCommissionCSVUploadDialog;
