import { isArray } from 'lodash';
import {
  EarningRadarSummary,
  earningRadarSummaryFromResponse,
} from '../interfaces/EarningRadar';
import api from './api';

const earningRadarUrl = '/earning-radar';

export const getEarningRadarSummaries = async (): Promise<
  EarningRadarSummary[]
> => {
  const response = await api.get(earningRadarUrl);

  const responseData = response?.data;
  if (!isArray(responseData?.data)) {
    throw new Error(
      'Could not get Earning Radar summaries: Response data is not an array',
    );
  }

  return responseData.data.map((summary: any) =>
    earningRadarSummaryFromResponse(summary),
  );
};
