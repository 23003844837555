import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { Prop22PayBreakdown } from '../../../../interfaces/PayHistory/Prop22PayBreakdown';
import SingleTextNode from '../SingleTextNode';
import {
  amountNodeID,
  basePayNodeID,
  boostPayNodeID,
  composeNodeID,
  doXGetYNodeID,
  engagedLocationNodeID,
  engagedLocationsNodeID,
  hazardPayNodeID,
  minGuaranteeAdjustmentNodeID,
  prop22PayNodeID,
  returnCocPayNodeID,
  tier2DeliveryPayNodeID,
  tobaccoPayNodeID,
  totalEarningsFloorNodeID,
  weeklyBonusNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import EngagedLocationNode from './EngagedLocationNode';
import SinglePayoutNode from '../SinglePayoutNode';

interface Prop22PayNodeProps {
  prop22PayBreakdown?: Prop22PayBreakdown;
  currency: string;
}

const Prop22PayNode = (props: Prop22PayNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return props.prop22PayBreakdown ? (
    <TreeItem
      nodeId={prop22PayNodeID}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`Prop 22 Pay Breakdown {${Object.keys(
          props.prop22PayBreakdown,
        ).length.toString()}}`}</Typography>
      }
    >
      <TreeItem
        nodeId={composeNodeID([prop22PayNodeID, engagedLocationsNodeID])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {`Engaged Location Contributions [${props.prop22PayBreakdown.engagedLocationContributions.length.toString()}]`}
          </Typography>
        }
      >
        {props.prop22PayBreakdown.engagedLocationContributions.map(
          (location, i) => (
            <EngagedLocationNode
              id={composeNodeID([
                prop22PayNodeID,
                engagedLocationNodeID,
                i.toString(),
              ])}
              name={i.toString()}
              engagedLocation={location}
              currency={props.currency}
            />
          ),
        )}
      </TreeItem>
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, totalEarningsFloorNodeID])}
        name='Total Earnings Floor'
        amount={props.prop22PayBreakdown.totalEarningsFloor}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, basePayNodeID])}
        name='Base Pay'
        amount={props.prop22PayBreakdown.basePay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, doXGetYNodeID])}
        name='Do X Get Y'
        amount={props.prop22PayBreakdown.doXGetY}
        currency={props.currency}
      />{' '}
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, returnCocPayNodeID])}
        name='Return Pay'
        amount={props.prop22PayBreakdown.returnPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, minGuaranteeAdjustmentNodeID])}
        name='Minimum Guarantee Adjustment'
        amount={props.prop22PayBreakdown.minimumGuaranteeAdjustment}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, boostPayNodeID])}
        name='Boost Pay'
        amount={props.prop22PayBreakdown.boostPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, hazardPayNodeID])}
        name='Hazard Pay'
        amount={props.prop22PayBreakdown.hazardPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, tier2DeliveryPayNodeID])}
        name='Tier 2 Delivery Pay'
        amount={props.prop22PayBreakdown.tier2DeliveryPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, tobaccoPayNodeID])}
        name='Tobacco Pay'
        amount={props.prop22PayBreakdown.tobaccoPay}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, weeklyBonusNodeID])}
        name='Weekly Bonus'
        amount={props.prop22PayBreakdown.weeklyBonus}
        currency={props.currency}
      />
      <SinglePayoutNode
        id={composeNodeID([prop22PayNodeID, amountNodeID])}
        name='Amount'
        amount={props.prop22PayBreakdown.amount}
        currency={props.currency}
      />
    </TreeItem>
  ) : (
    <SingleTextNode
      id={prop22PayNodeID}
      name='Prop 22 Pay Breakdown'
      value='null'
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default Prop22PayNode;
