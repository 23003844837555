import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';
import { AdjustmentSubtype } from '../../../interfaces/AdjustmentSubtype';
import { parseCurrencyStringAsCents } from '../../../utilities/Currency';

interface NewAdjustmentSubtypeProps {
  adjustmentCategories: string[];
  addSubtype: (subtype: AdjustmentSubtype) => void;
}

export const newSubtypeSentinelId = (description: string) =>
  `new-subtype-${description}`;

const NewAdjustmentSubtype = (props: NewAdjustmentSubtypeProps) => {
  const { classes } = useStyles();

  const [description, setDescription] = useState('');
  const [adjustmentType, setAdjustmentType] = useState('Other');
  const [requiresDate, setRequiresDate] = useState(false);
  const [requiresTextInput, setRequiresTextInput] = useState(false);
  const [threshold, setThreshold] = useState('250.00');

  const onAdd = () => {
    const thresholdAmount = parseCurrencyStringAsCents(threshold);
    if (thresholdAmount <= 0) {
      // Also captures invalid input
      toast.error(
        `'${threshold}' is invalid. Threshold must be a positive dollar amount`,
      );
      return;
    }

    props.addSubtype({
      id: newSubtypeSentinelId(description),
      adjustmentType,
      description,
      requiresDate,
      requiresTextInput,
      thresholdAmount,
      textInputLabel: '',
    });

    setDescription('');
    setAdjustmentType('Other');
    setRequiresDate(false);
    setRequiresTextInput(false);
    setThreshold('250.00');
  };

  return (
    <ValidatorForm onSubmit={onAdd}>
      <List>
        <ListItem key='description-input'>
          <TextField
            label='Enter new adjustment type description'
            className={classes.descriptionInput}
            fullWidth={true}
            size='small'
            onChange={(event) => setDescription(event.target.value)}
            value={description}
            key='description-input'
          />
          <Button
            type='submit'
            variant='outlined'
            className={classes.addButton}
            key='add-input-button'
          >
            Add
          </Button>
        </ListItem>
        <ListItem key='adjustment-type-data-input'>
          <FormControl
            fullWidth={true}
            className={classes.leftInput}
            key='type-data-form'
          >
            <InputLabel htmlFor='adjustment-type-selector'>
              Adjustment Category
            </InputLabel>
            {}
            <Select
              label='Adjustment Category'
              variant='outlined'
              fullWidth={true}
              labelId='adjustment-type-selector'
              value={adjustmentType}
              onChange={(event) => setAdjustmentType(event.target.value)}
              size='small'
            >
              {props.adjustmentCategories.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl
            fullWidth={true}
            className={classes.rightInput}
            key='threshold-input-form'
          >
            <InputLabel htmlFor='outlined-threshold-amount'>
              Enter Threshold Amount
            </InputLabel>
            <OutlinedInput
              id='outlined-threshold-amount'
              label='Enter Threshold Amount'
              size='small'
              onChange={(event) => setThreshold(event.target.value)}
              value={threshold}
            />
          </FormControl>
        </ListItem>
        <ListItem key='date-input'>
          <FormControlLabel
            control={
              <Switch
                onChange={(event) => {
                  setRequiresDate(event.target.checked);
                  setRequiresTextInput(false);
                }}
                checked={requiresDate}
              />
            }
            label='Requires Date'
            key='date-input-form'
          />
        </ListItem>
        <ListItem key='text-input'>
          <FormControlLabel
            control={
              <Switch
                onChange={(event) => {
                  setRequiresTextInput(event.target.checked);
                  setRequiresDate(false);
                }}
                checked={requiresTextInput}
              />
            }
            label='Requires Additional Input'
            key='text-input-form'
          />
        </ListItem>
      </List>
    </ValidatorForm>
  );
};

const useStyles = makeStyles()(() => ({
  descriptionInput: {
    paddingRight: '10px',
  },
  addButton: {
    textTransform: 'capitalize',
  },
  leftInput: {
    paddingRight: '5px',
  },
  rightInput: {
    paddingLeft: '5px',
  },
}));

export default NewAdjustmentSubtype;
