import { makeStyles } from 'tss-react/mui';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { multiClass } from '../../utilities/Extensions';
import { useSharedStyles } from '../../utilities/Styles';
import { InclusionListExpirationOptions } from '../../interfaces/FeatureToggle';

interface ExpireFeatureToggleFormProps {
  isLocationType: boolean;
  options: InclusionListExpirationOptions;
  onOptionsChange: (newOptions: InclusionListExpirationOptions) => void;
}

const ExpireFeatureToggleForm = (props: ExpireFeatureToggleFormProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const handleToggleExpireNowSwitch = () =>
    props.onOptionsChange({
      ...props.options,
      expireNow: !props.options.expireNow,
    });

  const handleSetExpireAt = (newExpireAt: Date | null) =>
    props.onOptionsChange({
      ...props.options,
      expireAt: newExpireAt ?? new Date(),
    });

  const handleSetTimezone = (e: any) =>
    props.onOptionsChange({
      ...props.options,
      isLocalTime: e.target.value === 'local',
    });

  return (
    <Box component='div' className={classes.body}>
      <Box component='div'>
        <FormControlLabel
          control={
            <Switch
              checked={props.options.expireNow}
              onChange={handleToggleExpireNowSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
              color={'success'}
            />
          }
          label={
            <Typography
              className={multiClass([
                sharedClasses.body1,
                classes.expireNowLabel,
              ])}
            >
              Expire immediately:
            </Typography>
          }
          labelPlacement='start'
          className={classes.expireNowSwitch}
        />
      </Box>
      {!props.options.expireNow && (
        <Box component='div'>
          <Box component='div' className={classes.dateTimePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                onChange={handleSetExpireAt}
                value={props.options.expireAt}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps,
                ) => <TextField {...params} size='small' label='Expire At' />}
              />
            </LocalizationProvider>
          </Box>
          <FormControl
            component='fieldset'
            className={classes.timezoneRadioGroup}
          >
            <FormLabel>
              <Typography className={sharedClasses.caption}>
                Timezone
              </Typography>
            </FormLabel>
            <RadioGroup
              name='timezone-radio-group'
              value={props.options.isLocalTime ? 'local' : 'utc'}
              onChange={handleSetTimezone}
            >
              <FormControlLabel
                key='utc'
                value='utc'
                control={<Radio />}
                label={
                  <Typography className={sharedClasses.body1}>UTC</Typography>
                }
              />
              {props.isLocationType && (
                <FormControlLabel
                  key='local'
                  value='local'
                  control={<Radio />}
                  label={
                    <Typography className={sharedClasses.body1}>
                      MFC Local
                    </Typography>
                  }
                />
              )}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  body: {
    marginTop: theme.spacing(0.5),
  },
  dateTimePicker: {
    marginTop: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(1.5),
    flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  expireNowLabel: {
    marginRight: theme.spacing(0.5),
  },
  expireNowSwitch: {
    marginLeft: 0,
  },
  endAtSwitch: {
    marginLeft: theme.spacing(1.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  timezoneRadioGroup: {
    marginTop: theme.spacing(1.5),
  },
}));

export default ExpireFeatureToggleForm;
