import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSharedStyles } from '../../../../utilities/Styles';
import { ShiftWithEventsBreakdown } from '../../../../interfaces/PayHistory/ShiftWithEventsBreakdown';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import {
  composeNodeID,
  roundedTotalWaitMinutesNodeID,
  shiftEventsNodeID,
  shiftWithEventsActualEndNodeID,
  shiftWithEventsActualStartNodeID,
  totalWaitSecondsNodeID,
} from '../../../../variables/PayHistory';
import { formatDateTime } from '../../../../utilities/Dates';
import ShiftEventSummaryNode from './ShiftEventSummaryNode';

interface ShiftWithEventsBreakdownNodeProps {
  id: string;
  shiftWithEventsBreakdown: ShiftWithEventsBreakdown;
  timezone: string;
}

const ShiftWithEventsBreakdownNode = (
  props: ShiftWithEventsBreakdownNodeProps,
) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >{`${formatDateTime(
          props.shiftWithEventsBreakdown.scheduledStart,
          props.timezone,
        )} - ${formatDateTime(
          props.shiftWithEventsBreakdown.scheduledEnd,
          props.timezone,
        )}`}</Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, shiftWithEventsActualStartNodeID])}
        name='Actual Start'
        value={formatDateTime(
          props.shiftWithEventsBreakdown.actualStart,
          props.timezone,
        )}
      />
      <SingleTextNode
        id={composeNodeID([props.id, shiftWithEventsActualEndNodeID])}
        name='Actual End'
        value={formatDateTime(
          props.shiftWithEventsBreakdown.actualEnd,
          props.timezone,
        )}
      />
      <SingleTextNode
        id={composeNodeID([props.id, totalWaitSecondsNodeID])}
        name='Total Wait Seconds'
        value={props.shiftWithEventsBreakdown.totalWaitSeconds.toString()}
      />
      <SingleTextNode
        id={composeNodeID([props.id, roundedTotalWaitMinutesNodeID])}
        name='Rounded Total Wait Minutes'
        value={props.shiftWithEventsBreakdown.roundedTotalWaitMinutes.toString()}
      />
      <TreeItem
        nodeId={composeNodeID([props.id, shiftEventsNodeID])}
        label={
          <Typography
            className={multiClass([classes.label, sharedClasses.body1])}
          >
            {`Events [${props.shiftWithEventsBreakdown.events.length.toString()}]`}
          </Typography>
        }
      >
        {props.shiftWithEventsBreakdown.events.map((event, i) => (
          <ShiftEventSummaryNode
            id={composeNodeID([props.id, shiftEventsNodeID, i.toString()])}
            shiftEventSummary={event}
            timezone={props.timezone}
          />
        ))}
      </TreeItem>
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default ShiftWithEventsBreakdownNode;
