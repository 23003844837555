export interface ShiftEventSummary {
  eventTime: Date;
  description: string;
  orderID?: string;
  reason?: string;
  waitPayEnded: boolean;
}

export const shiftEventSummaryFromResponse = (
  responseData: any,
): ShiftEventSummary => ({
  eventTime: new Date(responseData.event_time),
  description: responseData.description ?? '',
  orderID: responseData.order_id,
  reason: responseData.reason,
  waitPayEnded: responseData.wait_pay_ended ?? false,
});
