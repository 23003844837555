import { useRecoilValue } from 'recoil';
import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

import { TextValidator } from 'react-material-ui-form-validator';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import {
  filterLocationById,
  getTimeZoneByLocationId,
  getCurrencySymbolFromLocation,
} from '../../utilities/Locations';

import { locations as locationsAtom } from '../../atoms/Location';

import { multiClass } from '../../utilities/Extensions';
import { formatDateTime } from '../../utilities/Dates';
import { TargetCommission } from '../../interfaces/TargetCommissions';
import {
  isPositiveErrorMessage,
  requiredErrorMessage,
} from '../../utilities/Constants';

type SetTargetCommissionRulesProps = {
  currentState: TargetCommission;
  setter: (editedCommission: number) => void;
};

export const SetTargetCommissionRules = (
  props: SetTargetCommissionRulesProps,
) => {
  const validators = ['required', 'isPositive'];
  const errorMessages = [requiredErrorMessage, isPositiveErrorMessage];

  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;
  const [plainTextValue, setPlainTextValue] = useState(
    props.currentState.targetCommissionUnscheduledEdited ?? '',
  );
  const locations = useRecoilValue(locationsAtom);

  const location = filterLocationById(
    locations,
    parseInt(props.currentState.locationID, 10),
  );

  const timeZone =
    getTimeZoneByLocationId(
      locations,
      parseInt(props.currentState.locationID, 10),
    ) || 'UTC';

  const handleChange = (e: any) => {
    props.setter(parseFloat(e.target.value));
    setPlainTextValue(e.target.value);
  };

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid key='locationId' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Location Name
          </Typography>
          <Typography className={sharedClasses.body1}>
            {location?.name || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid key='startDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Start Time(MFC Local)
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(props.currentState.startAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='endDate' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            End Time(MFC Local)
          </Typography>
          <Typography className={sharedClasses.body1}>
            {formatDateTime(props.currentState.endAt, timeZone)}
          </Typography>
        </Box>
      </Grid>
      <Grid key='targetCommissionRaw' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Suggested Commission
          </Typography>
          <Typography className={sharedClasses.body1}>
            {`${getCurrencySymbolFromLocation(location)}${
              props.currentState.targetCommissionUnscheduledRaw
            }`}
          </Typography>
        </Box>
      </Grid>
      <Grid key='targetCommissionEdited' item xs={12}>
        <Box component='div' className={classes.fieldWrapper}>
          <Typography
            className={multiClass([sharedClasses.overline, classes.fieldName])}
          >
            Edited Commission
          </Typography>
          <TextValidator
            size='small'
            variant='outlined'
            type='number'
            value={plainTextValue}
            onChange={handleChange}
            style={{
              display: 'flex',
              flexGrow: 1,
            }}
            InputProps={{
              endAdornment: (
                <Typography>
                  {getCurrencySymbolFromLocation(location)}
                </Typography>
              ),
            }}
            validators={validators}
            errorMessages={errorMessages}
            name={'Target Commission Edited'}
            disabled={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginTop: theme.spacing(1.5),
    },
    locationAutocomplete: {
      width: 220,
    },
    multiLocationAutocomplete: {
      width: 420,
    },
    datePicker: {
      width: 180,
      marginLeft: theme.spacing(1),
    },
    tooltipIcon: {
      color: sharedColors.blue4,
      marginLeft: theme.spacing(1),
    },
    infoBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 'fit-content',
      padding: theme.spacing(2),
      marginTop: 'auto',
      marginBottom: '16px',
      backgroundColor: sharedColors.yellowLightest,
    },
    infoBoxIcon: {
      color: sharedColors.infoYellow,
    },
    infoBoxTextWrapper: {
      display: 'flex',
      width: 400,
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    infoBoxTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',
      color: sharedColors.yellowDark,
    },
    infoBoxMessage: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: sharedColors.yellowDark,
      marginTop: theme.spacing(1),
    },
    eventNameTextField: {
      width: 300,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    fieldName: {
      color: sharedColors.gray4,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(0.2),
    },
    grid: {
      flexGrow: 1,
    },
  }),
);
