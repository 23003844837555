import { DateTime } from 'luxon';
import api from './api';
import { PayoutCsvExportType } from '../interfaces/PayoutCsvExport';

export const getPayoutCsvExport = async (
  month: number,
  year: number,
  payoutProvider: string,
  exportType: PayoutCsvExportType,
): Promise<string> => {
  const url = '/admin/payout/export';
  const response = await api.get(url, {
    params: {
      month: month + 1,
      year,
      'payout-provider': payoutProvider,
      version: exportType,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  if (response.data.csv_content === null) {
    throw new Error(
      'Unexpected error: Server responded with an empty .csv file',
    );
  }
  return response.data.csv_content as string;
};

export const getAccruedAmountCsvExport = async (
  dateTime: DateTime,
): Promise<string> => {
  const url = '/admin/payout/accrued-amount-export';
  const response = await api.get(url, {
    params: {
      queried_time: dateTime.toISO(),
    },
  });
  if (response.data.csv_content === null) {
    throw new Error(
      'Unexpected error: Server responded with an empty .csv file',
    );
  }
  return response.data.csv_content as string;
};
