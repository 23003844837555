import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AdjustmentEntity } from '../../../interfaces/Adjustment';
import { parseCentsAsCurrencyString } from '../../../utilities/Currency';
import { formatDateAmericanReadable } from '../../../utilities/Dates';
import { useSharedStyles } from '../../../utilities/Styles';
import AdjustmentApprovalDialog from './AdjustmentApprovalDialog';

export const pendingAdjustmentDataTestId = `pending-adjustment-data-test-id`;
export const pendingAdjustmentReviewDataTestId = `pending-adjustment-review-data-test-id`;

interface PendingAdjustmentProps {
  adjustment: AdjustmentEntity;
  onApprove: () => void;
  undoDelay?: number;
}

const PendingAdjustment = (props: PendingAdjustmentProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const adj = props.adjustment;

  const [showReviewDialog, setShowReviewDialog] = useState(false);

  return (
    <Box
      data-testid={pendingAdjustmentDataTestId}
      className={classes.container}
    >
      <Grid container={true} className={classes.gridContainer}>
        <Grid item={true} xs={12}>
          <Typography>{adj.createdBy}</Typography>
          <Typography variant='caption'>
            {formatDateAmericanReadable(adj.createdAt)}
          </Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography variant='overline'>Amount</Typography>
          <Typography>{parseCentsAsCurrencyString(adj.amount)}</Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography variant='overline'>Adjustment Type</Typography>
          <Typography>{adj.adjustmentType}</Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography variant='overline'>Driver ID</Typography>
          <Typography>{adj.driverId}</Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Button
            sx={{ margin: '5px' }}
            variant='outlined'
            color='primary'
            className={sharedClasses.buttonText}
            onClick={() => setShowReviewDialog(true)}
            data-testid={pendingAdjustmentReviewDataTestId}
          >
            Review
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} variant='fullWidth' />
      <AdjustmentApprovalDialog
        onClose={() => setShowReviewDialog(false)}
        open={showReviewDialog}
        {...props}
      />
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  gridContainer: {
    marginLeft: '20px',
  },
  container: {
    maxWidth: '460px',
  },
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '10px',
  },
}));

export default PendingAdjustment;
