import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import { SingleHolidayPayBreakdown } from '../../../../interfaces/PayHistory/HolidayPayBreakdown';
import { useSharedStyles } from '../../../../utilities/Styles';
import {
  composeNodeID,
  holidayPayAccruedDaysNodeID,
  holidayPayRateNodeID,
} from '../../../../variables/PayHistory';
import { multiClass } from '../../../../utilities/Extensions';
import SingleTextNode from '../SingleTextNode';
import SinglePayoutNode from '../SinglePayoutNode';

interface HolidayPayBreakdownNodeProps {
  id: string;
  name: string;
  singleHolidayPayBreakdown: SingleHolidayPayBreakdown;
  currency: string;
}

const HolidayPayBreakdownNode = (props: HolidayPayBreakdownNodeProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  return (
    <TreeItem
      nodeId={props.id}
      label={
        <Typography
          className={multiClass([classes.label, sharedClasses.body1])}
        >
          {`${props.name}`}
        </Typography>
      }
    >
      <SingleTextNode
        id={composeNodeID([props.id, holidayPayAccruedDaysNodeID])}
        name='Accrued Days'
        value={props.singleHolidayPayBreakdown.accruedDays.toString()}
      />
      <SinglePayoutNode
        id={composeNodeID([props.id, holidayPayRateNodeID])}
        name='Rate'
        amount={props.singleHolidayPayBreakdown.rate}
        currency={props.currency}
      />
    </TreeItem>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
}));

export default HolidayPayBreakdownNode;
