import { isEmpty } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';
import { Metro } from '../interfaces/Location';
import { compareMetrosAlphabetically } from '../utilities/Locations';

interface MultiMetroAutocompleteProps {
  label: string;
  metros: Metro[];
  selectedIDs: string[];
  onSetSelectedIDs: (ids: string[]) => void;
  disabled?: boolean;
  error?: boolean;
  width?: number;
}

const MultiMetroAutocomplete = (props: MultiMetroAutocompleteProps) => {
  const getCountryLabel = (metro: Metro) =>
    metro.countryCode === 'GB' ? 'UK' : 'US';

  const handleSelect = (_: any, selectedMetros: Metro[]) => {
    if (isEmpty(selectedMetros)) {
      props.onSetSelectedIDs([]);
      return;
    }
    props.onSetSelectedIDs(selectedMetros.map((metro) => metro.id));
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      multiple
      disableCloseOnSelect
      limitTags={1}
      size='small'
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant='outlined'
          sx={{ width: props.width ?? 350 }}
          error={props.error}
        />
      )}
      options={[...props.metros].sort(compareMetrosAlphabetically)}
      groupBy={getCountryLabel}
      getOptionLabel={(metro) => metro.name ?? metro.id}
      onChange={handleSelect}
      value={props.metros.filter((metro) =>
        props.selectedIDs.includes(metro.id),
      )}
    />
  );
};

export default MultiMetroAutocomplete;
