import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'material-react-toastify';
import { useRecoilValue } from 'recoil';
import { restrictionTypes as restrictionTypesAtom } from '../../../atoms/RestrictedAccounts';
import Waiting from '../../Waiting';
import { BulkErrorDialog } from '../../BulkErrorDialog';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { multiClass } from '../../../utilities/Extensions';
import {
  UploadDialogTab,
  UploadDialogTabKind,
} from '../../../interfaces/Common';
import { InfoBox } from '../../InfoBox';
import UploadCSV from '../../UploadCSV';
import { ManualRestrictedAccountToAdd } from '../../../interfaces/RestrictedAccount';
import { defaultRestrictedAccountToAdd } from '../../../variables/RestrictedAccount';
import NewManualRestrictedAccount from './NewManualRestrictedAccount';
import {
  addRestrictedAccount,
  uploadRestrictedAccountCSV,
} from '../../../services/restrictedAccounts';
import { downloadCsvTemplate } from '../../../utilities/Csv';
import { restrictedAccountCsvTemplate } from '../../../variables/CsvTemplate';

export const manualAdditionTabName = 'Manual Addition';
export const csvUploadTabName = 'CSV Upload';
export const testCsvDownloadLinkId = 'csvDownloadLink';
export const title = 'Add New Restricted Account';

const tabs: UploadDialogTab[] = [
  {
    id: UploadDialogTabKind.MANUAL_ADDITION,
    name: manualAdditionTabName,
  },
  {
    id: UploadDialogTabKind.BULK_UPLOAD,
    name: csvUploadTabName,
  },
];

interface AddRestrictedAccountDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
}

const AddRestrictedAccountDialog = (props: AddRestrictedAccountDialogProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const restrictionTypes = useRecoilValue(restrictionTypesAtom);

  const [bulkErrorMsg, setBulkErrorMsg] = useState<string | undefined>(
    undefined,
  );
  const [showBulkError, setShowBulkError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<UploadDialogTabKind>(
    UploadDialogTabKind.BULK_UPLOAD,
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [file, setCurrentFile] = useState<File>();

  const [manualAccountToAdd, setManualAccountToAdd] =
    useState<ManualRestrictedAccountToAdd>(
      defaultRestrictedAccountToAdd(restrictionTypes),
    );

  const validatorRef = createRef<ValidatorForm>();

  const resetState = () => {
    setShowBulkError(false);
    setBulkErrorMsg(undefined);
    setManualAccountToAdd(defaultRestrictedAccountToAdd(restrictionTypes));
    setIsDisabled(true);
    setSelectedTab(UploadDialogTabKind.BULK_UPLOAD);
  };

  useEffect(resetState, [props.open]);

  useEffect(() => {
    setBulkErrorMsg(undefined);
  }, [file]);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setIsDisabled(!isValid);
    });
  }, [manualAccountToAdd]);

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: UploadDialogTabKind,
  ) => {
    setSelectedTab(newValue);
    setIsDisabled(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (selectedTab === UploadDialogTabKind.BULK_UPLOAD) {
      uploadRestrictedAccountCSV(file)
        .then(() => {
          toast.success('Bulk accounts successfully added.');
          props.onAdd();
          resetState();
        })
        .catch((error) => {
          toast.error('Bulk accounts upload failed');
          setBulkErrorMsg(error.message);
        })
        .finally(() => setLoading(false));
    } else {
      addRestrictedAccount(manualAccountToAdd)
        .then(() => {
          toast.success('Manual account successfully added.');
          props.onAdd();
          resetState();
        })
        .catch((error) => toast.error(error.message))
        .finally(() => setLoading(false));
    }
  };

  const handleValidationError = () => {
    setIsDisabled(true);
  };

  const DownloadLink = () => {
    if (selectedTab === UploadDialogTabKind.BULK_UPLOAD) {
      return (
        <Box>
          <Button
            onClick={() => downloadCsvTemplate(restrictedAccountCsvTemplate)}
            data-testid={testCsvDownloadLinkId}
            className={classes.downloadLink}
          >
            Download CSV Template for Restricted Accounts
          </Button>
        </Box>
      );
    }
    return <Box />;
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <Waiting open={loading} />
      <BulkErrorDialog
        open={showBulkError}
        message={bulkErrorMsg || ''}
        onClose={() => setShowBulkError(false)}
      />

      <ValidatorForm
        onSubmit={handleSubmit}
        onError={handleValidationError}
        ref={validatorRef}
        instantValidate={true}
      >
        <DialogTitle className={multiClass([sharedClasses.h6, classes.title])}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Box className={classes.tabsContainer}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              {tabs.map((tab) => (
                <Tab
                  label={
                    <Typography className={classes.tabLabel}>
                      {tab.name}
                    </Typography>
                  }
                  key={tab.name}
                />
              ))}
            </Tabs>
          </Box>

          {tabs
            .filter((tab) => tab.id === selectedTab)
            .map((tab) => (
              <Box
                role='tabpanel'
                id={`simple-tabpanel-${tab.id}`}
                aria-labelledby={`simple-tab-${tab.id}`}
                key={tab.id}
              >
                {selectedTab === tab.id && (
                  <Box sx={{ p: 3 }}>
                    {tab.name === csvUploadTabName ? (
                      <UploadCSV setParentFile={setCurrentFile} />
                    ) : (
                      <NewManualRestrictedAccount
                        setParentAccount={setManualAccountToAdd}
                      />
                    )}
                  </Box>
                )}
              </Box>
            ))}

          {selectedTab === UploadDialogTabKind.BULK_UPLOAD && bulkErrorMsg && (
            <InfoBox
              title={
                'Error(s) occurred while uploading bulk restricted accounts.'
              }
              message={
                <Typography
                  className={classes.infoBoxMessage}
                  onClick={() => setShowBulkError(true)}
                >
                  Click here to see details
                </Typography>
              }
              severity='error'
              classes={classes}
            />
          )}
        </DialogContent>
        <DialogActions>
          <DownloadLink />
          <Box component='div' className={classes.dialogActionsSpacer} />
          <Button
            variant='contained'
            color={'inherit'}
            onClick={props.onClose}
            className={sharedClasses.buttonText}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={sharedClasses.buttonText}
            disabled={
              selectedTab === UploadDialogTabKind.BULK_UPLOAD
                ? file === undefined
                : isDisabled
            }
          >
            Add
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    color: sharedColors.gray6,
  },
  tabsContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    background: sharedColors.gray1,
  },
  tabLabel: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  downloadLink: {
    paddingLeft: theme.spacing(2),
    textTransform: 'none',
  },
  infoBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: 'fit-content',
    padding: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: sharedColors.statusRedLightest,
  },
  infoBoxIcon: {
    color: sharedColors.statusRed,
  },
  infoBoxTextWrapper: {
    display: 'flex',
    width: 400,
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  infoBoxTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '15px',
    color: sharedColors.black,
  },
  infoBoxMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: sharedColors.black,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  dialogActionsSpacer: {
    flexGrow: 1,
    display: 'flex',
  },
}));

export default AddRestrictedAccountDialog;
